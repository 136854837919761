import React from 'react';
import PopUpContainer from '@components/PopUpContainer';
import './style.css';

function SelectionPopUpView(props) {

	return (
		<PopUpContainer
           title={props.title}
           isSearchEnabled={props.isSearchEnabled}
           searchPlaceholder={props.searchPlaceholder}
           isButtonsEnabled={true}
           style={{width:props.width, height:props.height}}
           handleSearch={props.handleSearch}
           handleCancel={props.handleCancel}
           handleSave={props.handleSave}
        >
            <div className='d-flex flex-column gap-1'>
               { props.data?.map((item, index)=>{
                   if(item[props.optionKey]?.toLowerCase().includes(props.searchText?.toLowerCase()))
                    {
                        return (<div className='d-flex gap-2 flex-row' key={index}>
                            <input type={props.selectionType === 'single' ? 'radio': 'checkbox'} 
                            value={item[props.optionKey]} 
                            onChange={(event)=>{props.handleSelect(item)}} 
                            checked={
                                props.selectedItems.some(selected => {
                                    return selected[props.selectedItemsKey] === item[props.dataKey]
                                })
                                }/> 
                               {item[props.optionKey].charAt(0).toUpperCase() + item[props.optionKey].slice(1)}
                               </div>)
                    }}) 
                }
            </div>
        </PopUpContainer>
	)
}

export default SelectionPopUpView;
