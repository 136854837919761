export const updateAllCategories = (value) => {
	return {
		type: 'UPDATE_ALL_CATEGORIES',
		payload: {
			allCategories: value,
		},
	};
};

export const updateTotalNumOfCategories = (value) => {
	return {
		type: 'UPDATE_TOTAL_NUMBER_OF_CATEGORIES',
		payload: {
			totalNumberOfCategories: value,
		},
	};
};

export const updateCategorySet = (value) => {
	return {
		type: 'UPDATE_CATEGORY_SET',
		payload: {
			categorySet: value,
		},
	};
};

export const updateCategoryDetails = (value) => {
	return {
		type: 'UPDATE_CATEGORY_DETAILS',
		payload: {
			category: value,
		},
	};
};

export const updateCategoryTitle = (value) => {
	return {
		type: 'UPDATE_CATEGORY_TITLE',
		payload: {
			title: value,
		},
	};
};

export const updateCategoryDescription = (value) => {
	return {
		type: 'UPDATE_CATEGORY_DESCRIPTION',
		payload: {
			description: value,
		},
	};
};

export const updatePointers = (value) => {
	return {
		type: 'UPDATE_CATEGORY_POINTERS',
		payload: {
			pointers: value,
		},
	};
};

export const updateCategoryVideoUrl = (value) => {
	return {
		type: 'UPDATE_CATEGORY_VIDEO_URL',
		payload: {
			videoUrl: value,
		},
	};
};

export const updateCategoryIsPublic = (value) => {
	return {
		type: 'UPDATE_CATEGORY_IS_PUBLIC',
		payload: {
			isPublic: value,
		},
	};
};

export const updateSelectedCourses = (value) => {
	return {
		type: 'UPDATE_SELECTED_COURSES',
		payload: {
			selectedCourses: value,
		},
	};
};

export const resetCategoryReducer = (value) => {
	return {
		type: 'RESET_CATEGORY_REDUCER',
	};
};
export const updatePageNo = (value) => {
	return {
		type: 'UPDATE_CATEGORY_PAGE_NUMBER',
		payload: {
			pageNo: value,
		},
	};
};

export const updateFilterInformation = (value) => {
	return {
		type: 'UPDATE_CATEGORY_FILTER_INFORMATION',
		payload: {
			filterInformation: value,
		},
	};
};
