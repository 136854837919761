
import HotLeadIcon from '@assets/leadsIcon/hotLead.png';
import ColdLeadIcon from '@assets/leadsIcon/coldLead.png';
import HotProspectiveIcon from '@assets/leadsIcon/hotProspective.png';
import ColdProspectiveIcon from '@assets/leadsIcon/coldProspective.png';
import IrrelevantIcon from '@assets/leadsIcon/irrelevant.png';
import MoreCoursesIcon from '@assets/leadsIcon/moreCourses.png';
import SeemsNotInterestedIcon from '@assets/leadsIcon/seemsNotInterested.png';
import LessQualifiedIcon from '@assets/leadsIcon/lessQualified.png';
import WaitingTimeIcon from '@assets/leadsIcon/waitingTime.png';
import NonMedicoIcon from '@assets/leadsIcon/nonMedico.png';
import OutOfBudgetIcon from '@assets/leadsIcon/outOfBudget.png';
import NotInterestedIcon from '@assets/leadsIcon/notInterested.png';
import LostToCompetitorIcon from '@assets/leadsIcon/lostToCompetitor.png';
import ClosedIcon from '@assets/leadsIcon/closed.png';
import RequirementsNotMetIcon from '@assets/leadsIcon/requirementsNotMet.png';
// currentFolderName => unClaimedLeads => myLeads => lost => irrelevant => lostClosed => successfullyClosed => other folder created by superadmin
export const defaultFolders = [
    {
        "_id": "myLeads",
        "title": "My Leads",
        "titleTag1" : {
            "text" : 203,
            "bgColor" : "#008000",
            "fontColor" : "white"
        },
        "isActive": true
    },
    {
        "_id": "unClaimedLeads",
        "title": "Unclaimed Leads",
        "titleTag1" : {
            "text" : 103,
            "bgColor" : "#EB4242",
            "fontColor" : "white"
        },
        "isActive": false
    },
    {
        "_id": "lost",
        "title": "Lost",
        "titleTag1" : {
            "text" : 303,
            "bgColor" : "#B6B6B6",
            "fontColor" : "white"
        },
        "isActive": false
    },
    {
        "_id": "archive",
        "title": "Archive",
        "titleTag1" : {
            "text" : 203,
            "bgColor" : "#B6B6B6",
            "fontColor" : "white"
        },
        "isActive": false
    },
    {
        "_id": "successfullyClosed",
        "title": "Closed",
        "titleTag1" : {
            "text" : 203,
            "bgColor" : "#B6B6B6",
            "fontColor" : "white"
        },
        "isActive": false
    },
    {
        "_id": "waiting",
        "title": "Waiting",
        "titleTag1" : {
            "text" : 203,
            "bgColor" : "#B6B6B6",
            "fontColor" : "white"
        },
        "isActive": false
    },
    {
        "_id": "irrelevant",
        "title": "Irrelevant",
        "titleTag1" : {
            "text" : 203,
            "bgColor" : "#B6B6B6",
            "fontColor" : "white"
        },
        "isActive": false
    }
   
]

export const statusList={
    'hotLead': {
        text: 'Hot lead',
        icon: HotLeadIcon
    },
    'coldLead': {
        text: 'Cold lead',
        icon: ColdLeadIcon
    },
    'hotProspective': {
        text: 'Hot prospective',
        icon: HotProspectiveIcon
    },
    'coldProspective':{
        text: 'Cold prospective',
        icon: ColdProspectiveIcon
    },
    'moreCourses':{
        text: 'More courses',
        icon: MoreCoursesIcon 
    },
    'seemsNotInterested':{
        text: 'Seems not interested',
        icon: SeemsNotInterestedIcon
    },
    'lessQualified':{
        text: 'Less qualified',
        icon: LessQualifiedIcon
    },
    'waitingTime':{
        text: 'Waiting time',
        icon: WaitingTimeIcon
    },
    'irrelevant':{
        text: 'Irrelevant',
        icon: IrrelevantIcon,
    },
    'nonMedico':{
        text: 'Non medico',
        icon: NonMedicoIcon
    },
    'outOfBudget':{
        text:'Out of budget',
        icon: OutOfBudgetIcon
    },
    'notInterested':{
        text: 'Not Interested',
        icon: NotInterestedIcon
    },
    'lostToCompetitor':{
        text: 'Lost to competitor',
        icon: LostToCompetitorIcon
    },
    'requirementsNotMet':{
        text: 'Requirements not met',
        icon: RequirementsNotMetIcon
    },
    'closed':{
        text: 'Closed',
        icon: ClosedIcon
    }
}