export const updateHomePage = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateHomePage`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from updateHomepageData : updateHomePage()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getAllPublicCourses = async (authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getAllPublicCourses`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from updateHomepageData : getAllPublicCourses()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getAllPublicOffers = async (authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getAllPublicOffers`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from updateHomepageData : getAllPublicOffers()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getLatestProducts = async (authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getLatestProducts`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from updateHomepageData : getLatestProducts()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
