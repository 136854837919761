import React from "react";

import OfferHomeView from './view';

import { useLocation } from 'react-router-dom';

import InnerSidebarNavigation from '@components/InnerSidebarNavigation'
import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';

function OfferHome(){
    const location = useLocation();
    
    //Failsafe if no data received from navigation
    let navigationData = [{
        "title": "Back to Offers",
        "route": "/offer",
        "icon": GoBackArrow
    }];
    if (location.state && location.state.finalNavigationData) {
        navigationData = location.state.finalNavigationData;
    }
    
    return (
        <>
            <InnerSidebarNavigation
                navigationData={navigationData} />
            <OfferHomeView />
        </>
    )
}

export default OfferHome;