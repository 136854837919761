import React from 'react';
import SelectInputView from './view';

function SelectInput(props) {

    return(
    <SelectInputView 
      props ={props}
    />)
}

export default SelectInput