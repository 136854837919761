import { decryptText } from '@library/enc-dec';

export const getPurchasesByUserId = async (userId, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getPurchasesByUserId?userId=${userId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from UserPages services : getPurchasesByUserId()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const addOrder = async (orderDetails) => {
	try {
		let authToken = decryptText(localStorage.getItem('aEmediat'));
		return await fetch(`${process.env.REACT_APP_BASE_URL}/addOrder`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(orderDetails),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from UserPages => addOrder services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const addToPurchases = async (payload) => {
	try {
		let authToken = decryptText(localStorage.getItem('aEmediat'));
		return await fetch(`${process.env.REACT_APP_BASE_URL}/addToPurchases`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from UserPages => addOrder services', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getOrderById = async (orderId, userId, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getOrderById/${orderId}/${userId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Checkout => getOrderById()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updatePurchasedCourseStatus = async (payload, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/updatePurchasedCourseStatus`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
				body: JSON.stringify(payload),
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from UserPages services : getHistoryVideos()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};


export const updatePurchasedSeriesStatus = async (payload, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/updatePurchasedSeriesStatus`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
				body: JSON.stringify(payload),
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from UserPages services : updatePurchaseSeriesStatus()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateExamStatus = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateExamStatus`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from UserPages services : updateExamStatus()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
