const initialState = {
	allTags: []
	
};

const TagsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_TAGS':
			return {
				...state,
				allTags: [...action.payload.allTags] ,
			};
		default:
			return state;
	}
};

export default TagsReducer;
