import React, { useEffect, useState, useRef  } from 'react';
import PopUpContainer from '@components/PopUpContainer';
import moment from 'moment';
import { getEventElementPosition} from '@helpers/common.js';
import { decryptText } from '@library/enc-dec';
import * as TestServices from '@services/Tests';
import SelectInput from '@components/SelectInput';
import SelectionPopUp from '@components/SelectionPopUp';
import PopUp from '@components/PopUp'

function ResultStatusPopUp(props) {
	const [showDetailedResult, setShowDetailedResult]=useState(true);
	const [isHideResultIn, setIsHideResultIn]=useState(false);
	const [days, setDays]=useState('');
    const [allTest, setAllTests]=useState([]);
    const [selectedTests, setSelectedTests]=useState([]);
	const [isPopUpVisible, setIsPopUpVisible] = useState(false);
	const [popUpPosition, setPopUpPosition] = useState({});

	useEffect(()=>{
		getAllTests()
	},[props.isTest])

	const getAllTests = async()=>{
		try{
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const payload={
				pageNo: 0,
				limit: 999999999
			}
			const response = await TestServices.getAllTestQuestionBanks(payload, authToken);
			setAllTests(response.data)		
		}
		catch(err){
          console.log("Error coming while getting tests", err)
		}
	}

	const handleSave=()=>{
		let showDate =  moment().toISOString() 
		let hideDate = showDetailedResult? 
		isHideResultIn ? moment().add(days*24, 'hours').startOf('day').toISOString()  :moment().add(24, 'hours').startOf('day').toISOString()
		                : moment().toISOString()
		 props.handleSave({showDate, hideDate, "testExamIds": selectedTests.map(obj=>obj._id)})
	 }

	// To close the popup container
	const handlePopUpOpen = (event) => {
		handlePopUpClose(event)
		setIsPopUpVisible(true)
		let position = getEventElementPosition(event);
		// setPopUpPosition(position);
		event.stopPropagation();
	};
	const handlePopUpClose = (event) => {
		setIsPopUpVisible(false)
		if(event)
		{
			event.stopPropagation()
		}
	};
	// To handleSave buttons on the popup
	const handlePopUpSave = async (data, event) => {
		setSelectedTests(data)
		handlePopUpClose(event);		
	};

	return (
		<PopUpContainer
			title={""}
			isSearchEnabled={false}
			searchPlaceholder={""}
			isButtonsEnabled={true}
			style={{width:'auto', height: 'auto'}}
			handleSearch={()=>{}}
			handleCancel={props.handleCancel}
			handleSave={handleSave}
			>
				<div className='d-flex flex-column gap-2' style={{maxWidth:'400px'}}>
					{props.isTest && 
								<SelectInput
									title={null}
									required={false}
									inputText={selectedTests.length>0? 
										 selectedTests.map(obj=> `${obj.title},`)
										:"Select test(s)"}
									onClick={handlePopUpOpen}
								/>}
						<div className='d-flex gap-2 align-items-center'>	
							<input type='radio' checked={showDetailedResult} onChange={(e)=>{setShowDetailedResult(true)}}/>
							 Show detailed result
							 {showDetailedResult && 
							 <div className='d-flex gap-2 align-items-center ms-auto'>
							    <input type='checkbox' checked={isHideResultIn} onChange={(e)=>{setIsHideResultIn(!isHideResultIn)}}/>
								hide result in
								<input type='number' className='form-control form-control-sm' style={{width:'50px'}}
								value={days} onChange={(e)=>setDays(e.target.value)}/>
								days
							 </div>}
						</div>
						<div className='d-flex gap-2 align-items-center'>
							<input type='radio' checked={!showDetailedResult} onChange={(e)=>{setShowDetailedResult(false)}}/>
							 Hide detailed result
						</div>
						</div>

						{isPopUpVisible &&
						       <PopUp visible={isPopUpVisible}
							   top={popUpPosition.top}
							   left={popUpPosition.left}
							   handleOutSideClick={handlePopUpClose}
							   > <SelectionPopUp 
									data = {allTest}
									optionKey= {"title"}
									title={"Select tests"}
									searchPlaceholder={""}
									isSearchEnabled={false}
									height= {"auto"}
									width= {"auto"}
									selectedItems={[]}
									dataKey={"_id"}
									selectedItemsKey={"_id"}
									selectionType={"multiple"}
									handleCancel ={handlePopUpClose}
									handleSave={handlePopUpSave}/>
							</PopUp>}
				</PopUpContainer>
	);
}

export default ResultStatusPopUp;
