import React from "react";
import {colors} from '@assets/colors.js'

function IndeterminateCheckbox({ indeterminate, isColorEnabled,id, ...rest }) {

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return <input type="checkbox" ref={ref} {...rest} id={id} style={isColorEnabled? {accentColor:colors[id]}:{}}/>;
}

export default IndeterminateCheckbox;