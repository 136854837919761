import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';
import ProfileIcon from '@assets/common/profile.png';
import AnalyticsIcon from '@assets/common/analyticsIcon.png';
import LeadsIcon from '@assets/sidebarNavigation/leads.png';
import CustomerCareIcon from '@assets/sidebarNavigation/customerCare.png';
import BssIcon from '@assets/sidebarNavigation/bss.png';


export const userApiData = {
    "success" : true,
    "data" : [
        {
            _id : "1",
            profileImage : "",
            name : "Mahdi Hassan Lalani",
            category : "cat1",
            course : "cgo",
            contactNumber : 7977793596,
            whatsAppNumber  : 7977793596,
            accCreated : "2022-08-07T23:17:35Z",
            dateOfPurchase : "2023-08-07T23:17:35Z",
            examStatus : 'passed',
            showResult : true,
            passPercentage : 70
        },
        {
            _id : "2",
            profileImage : "",
            name : "2",
            category : "cat2",
            course : "cch",
            contactNumber : 7977793596,
            whatsAppNumber  : 7977793596,
            accCreated : "2022-07-07T23:17:35Z",
            dateOfPurchase : "2023-07-07T23:17:35Z",
            examStatus : 'failed',
            showResult : false,
            passPercentage : 70
        },
        {
            _id : "3",
            profileImage : "",
            name : "3",
            category : "cat3",
            course : "cgo",
            contactNumber : 7977793596,
            whatsAppNumber  : 7977793596,
            accCreated : "2022-06-07T23:17:35Z",
            dateOfPurchase : "2023-06-07T23:17:35Z",
            examStatus : 'unlock',
            showResult : true,
            passPercentage : 70
        },
        {
            _id : "4",
            profileImage : "",
            name : "4",
            category : "cat1",
            course : "cgo",
            contactNumber : 7977793596,
            whatsAppNumber  : 7977793596,
            accCreated : "2022-05-07T23:17:35Z",
            dateOfPurchase : "2023-05-07T23:17:35Z",
            examStatus : 'unlock',
            showResult : true,
            passPercentage : null
        },
        {
            _id : "5",
            profileImage : "",
            name : "5",
            category : "cat1",
            course : "cch",
            contactNumber : 7977793596,
            whatsAppNumber  : 7977793596,
            accCreated : "2022-05-07T23:17:35Z",
            dateOfPurchase : "2023-05-07T23:17:35Z",
            examStatus : 'videos yet to be completed',
            showResult : true,
            passPercentage : null
        },
    ]
};


//Object to handle naviagtion
export const navigationData = [
    {
        "title" : "Back to Users",
        "route" : "/users",
        "default" : false,        
        "icon" : GoBackArrow
    },
    {
        "title" : "Profile",
        "component" : "UserProfile",
        "default" : false,        
        "icon" : ProfileIcon
    },
    {
        "title" : "Activity",
        "component" : "UserActivity",
        "default" : false,        
        "icon" : AnalyticsIcon
    },
    {
        "title" : "Lead Details",
        "component" : "UserLead",
        "default" : false,        
        "icon" : LeadsIcon
    },
    {
        "title" : "Customer care",
        "component" : "UserCustomerCare",
        "default" : false,        
        "icon" : CustomerCareIcon
    },
    {
        "title" : "BSS Forms",
        "component" : "UserBSSForm",
        "default" : false,        
        "icon" : BssIcon
    },
]
