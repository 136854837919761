import React from "react";
import './home.css'

import ErrorView from '@components/ErrorView';

function HomeView(){
    return(
        <>
            <h1 style={{marginLeft:'240px', marginTop: '40px'}}> Home Page </h1>   
            <ErrorView
              text ={"To add videos and courses go to the Content Tab"}
            />         
        </>
    )
}

export default HomeView;