import React from 'react';
import moment from 'moment';
import './style.css';

import DeleteIcon from '@assets/common/deleteIcon.png';

import EditPencilIcon from '@assets/common/editPencilIcon.png';
import CoursePageTagIcon from '@assets/common/coursePageTagIcon.png';
import CoursePageTabsIcon from '@assets/common/coursePageTabsIcon.png';
import CoursePageVideoIcon from '@assets/common/coursePageVideoIcon.png';
import AnalyticsIcon from '@assets/common/analyticsIcon.png';

import ExpandIcon from '@assets/userDashboard/expand.png';
import SettingIcon from '@assets/userDashboard/setting.png';

import BasicTableGrid from '@components/BasicTableGrid';
import PopUp from '@components/PopUp';
import ButtonBars from '@components/ButtonBars';
import EditTitlePopUp from '@components/Courses/EditTitlePopUp';
import EditMnemonicPopUp from '@components/Courses/EditMnemonicPopUp';
import EditVisibilityPopUp from '@components/Courses/EditVisibilityPopUp';
import SelectSeriesPopUp from '@components/Courses/SelectSeriesPopUp';
import EditExamPopUp from '@components/Courses/EditExamPopUp';
import DateAndUser from '@components/DateAndUser';
import Search from '@components/Search';

function CoursesView(props) {
	return (
		<div ref={props.fullscreenElementRef} className='bg-white'
		style={{ maxWidth: '100%', width:'100%', flexGrow: 1,
		backgroundColor: '#ffffff', display:'flex', flexDirection: 'column'
		}}
		>
		 
			<div className='d-flex align-items-center w-100 justify-content-between pe-5'>
					<ButtonBars buttons={[{type: 'simple',
									text: 'New Course',
									onClick: (event) => {
										props.handleRedirectToHome(event, 'none', 'none');
									}
								},
								{
									type: 'simple',
									text: 'Visibility',
									onClick: (event) => {
										props.handleVisibilityClick(event, 'bulk', '');
									},
									disabled: props.disableBulkActionBtn,
								}]}/>
					<div className='d-flex gap-2 controlsRight pe-5'>
						<button 
						onClick={props.toggleFullscreen}
						>
							<img src={ExpandIcon}></img>
							<br></br>
							Expand
						</button>
						<button onClick={props.handleSettingClick}>
							<img src={SettingIcon}></img>
							<br></br>
							Setting
						</button>
						<button
						       onClick={props.handleDeleteBulk}
						       style={{ cursor: props.disableBulkActionBtn ? 'not-allowed' : 'pointer' }}
						        disabled={props.disableBulkActionBtn}
						        >
						        <img src={DeleteIcon} alt='DeleteIcon' />
								<br></br>
								Delete
					    </button>
					</div>
			</div>

			<BasicTableGrid 
				gridId='courses'
				data={props.deferedData} // Data to be displayed
				fixedCols={2} // How many columns are fixed and not scrolling
				initialSize={[35,440,150,117,150,145,224,175,141,226]}
				initialSorting={[{"id": props.sortBy === 'createdAt'? "Date Added":"Last modified date","desc": props.isSorted}]}
				handleSelect={(selectedRows) => {
					props.handleCheckBoxes(selectedRows);
				}}
				length={20}
				pagination={props.pagination}
				setPagination={props.setPagination}
				onSortChange={(data) => props.handleSort(data)
				}
				columnsOptionPopUpRef={props.columnsOptionPopUpRef}
				columns={[
				{header: 'Display Name',
					cell: (data) => {
					const course = data.row.original;
					return (
						<div className="d-flex gap-2">
							{props.showThumbnail === true ? <div className="thumbnailContainer" 
								   style={{
									 backgroundColor: course.cardBackgroundColor,
									 backgroundImage:`url(${course.rectangleThumbnailUrl})`,
									 cursor:'pointer'
									}}
									onDoubleClick = {(event)=>{props.handleTitleClick(event, course)}}
								   >
								</div>
							:
							 ''
							}
							<div className="d-flex flex-column justify-content-center gap-2 ms-2">
								<div >
									{course?.title} 
								</div>
								<div className='hiddenDiv d-flex gap-2'>
									<img
										onClick={(event) => {
											props.handleRedirectToHome(event, course._id, 'CourseDetails');
										}}
										src={EditPencilIcon}
										alt='EditPencilIcon'
									/>
									<img
										onClick={(event) => {
											props.handleRedirectToHome(event, course._id, 'CoursePricing');
										}}
										src={CoursePageTagIcon}
										alt='CoursePageTagIcon'
									/>
									<img
										onClick={(event) => {
											props.handleRedirectToHome(event, course._id, 'CourseModules');
										}}
										src={CoursePageTabsIcon}
										alt='CoursePageTabsIcon'
									/>
									<img
										onClick={(event) => {
											props.handleRedirectToHome(event, course._id, 'CourseAnalytics');
										}}
										src={AnalyticsIcon}
										alt='AnalyticsIcon'
									/>
									<img src={CoursePageVideoIcon} alt='CoursePageVideoIcon' />
								</div>
							</div>
							
						</div>
					);
					},
				},
				{header: 'Abbreviation', 
				cell : (data)=>{
					return <div onDoubleClick={(event)=>props.handleAbbreviationClick(event,data.row.original)}>
						      {data.row.original.mnemonic}
							</div>
				}},
				{header: 'Visibility', cell: (data)=>{
					const course = data.row.original
					return <div className="VisibilityStatus px-2 py-1" 
					            style={{backgroundColor: course.visibility === 'public'? '#009C5A'
								                             : course.visibility === 'private'? '#FF6666': '#7EC905'}}
								onDoubleClick={(event)=>props.handleVisibilityClick(event, 'single', course)}>
                             {course.visibility === 'public'? 'Public' 
							      : course.visibility === 'private'? 'Private' : 'Unlisted'}
					       </div>
				}},
				{header: 'Content', 
				 cell:(data)=>{
					const course = data.row.original;
					return(<div className="w-100 d-flex justify-content-around gap-2"
					        onDoubleClick={(event)=>props.handleSeriesClick(event,data.row.original)}>
							<div className="seriesName">
								{course.content?.map((content)=> content.mnemonic+", ")}
								</div>
							<div className="seriesCount">{course.content?.length}</div>
						</div>)
				}},
				{header: 'Exam',
				cell : (data)=>{
					return <div onDoubleClick={(event)=>props.handleExamClick(event,data.row.original)}>
						      {data.row.original.examName}
							</div>
				}},
				{header: 'Date Added', accessorFn: (row) => {},
					cell: (data) => {
						
					const info = data.row.original;
						return <DateAndUser
						date = {info.createdAt}
						image = {info.createdUserDetails?.displayPhoto}
						imageText = {info.createdUserDetails?.displayName}/>
					},
			    },
				{header: 'Current active users', cell:(data)=>data.row.original.activeUsers},
				{header: 'Users till date', cell:(data)=>data.row.original.usersTilldate},
				{header: 'Last modified date', accessorFn: (row) => {},
				cell: (data) => {
					const info = data.row.original;
					return (<DateAndUser
						date = {info.updatedAt}
						image = {info.lastModifiedUserDetails?.displayPhoto}
						imageText = {info.lastModifiedUserDetails?.displayName}/>
					) 
				},
			    }
				]}
				/>

			<Search
			    value={props.searchText}
				placeholder = {"Search courses"}
				onSearch ={(data)=>props.handleSearch(data)}
			>
			<div>
				No Filters Added
			</div>
			</Search>
            {/* All pop ups */}
			{/* Change to upload the thumbnail sepearatly and send text urls and not files*/}
			<PopUp
				visible={props.isEditTitlePopUpVisible}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left}
				handleOutSideClick={props.handlePopUpClose}>
				<EditTitlePopUp
					courseId={props.selectedCourse._id}
					displayName={props.selectedCourse.title}
					internalName={props.selectedCourse.internalTitle}
					rectangleThumbnailUrl={props.selectedCourse.rectangleThumbnailUrl}
					squareThumbnailUrl={props.selectedCourse.squareThumbnailUrl}
					handleClose={props.handlePopUpClose}
					handleSave={props.handlePopUpSave}
				/>
			</PopUp>		
			<PopUp
				visible={props.isEditVisibilityPopUpVisible}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left}
				handleOutSideClick={props.handlePopUpClose}>
				<EditVisibilityPopUp
					courses={props.listOfCheckedCourses}
					handleClose={props.handlePopUpClose}
					handleSave={props.handlePopUpSave}
				/>
			</PopUp>
			<PopUp
				visible={props.isEditMnemonicPopUpVisible}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left}
				handleOutSideClick={props.handlePopUpClose}>
				<EditMnemonicPopUp
				    courseId = {props.selectedCourse._id}
					mnemonic={props.selectedCourse.mnemonic}
					handleClose={props.handlePopUpClose}
					handleSave={props.handlePopUpSave}
				/>
			</PopUp>
			<PopUp
				visible={props.isEditSeriesPopUpVisible}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left}
				handleOutSideClick={props.handlePopUpClose}>
				<SelectSeriesPopUp
				    courseId = {props.selectedCourse._id}
					searchPlaceholder={'Search for series'}
					selectedSeries={props.selectedCourse.seriesList}
					allSeries={props.allSeries}
					handleClose={props.handlePopUpClose}
					handleSave={props.handlePopUpSave}
				/>
			</PopUp>
			<PopUp
				visible={props.isEditExamPopUpVisible}
				top={props.popUpPosition.top+30}
				left={props.popUpPosition.left -300}
				handleOutSideClick={props.handlePopUpClose}>
				<EditExamPopUp
				    courseId = {props.selectedCourse._id}
					selectedExam={props.selectedCourse?.examId}
                    allExams={props.allExams}
					handleClose={props.handlePopUpClose}
					handleSave={props.handlePopUpSave}
				/>
			</PopUp>
			<PopUp
				visible={props.isSettingPopUpVisible}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left}
				handleOutSideClick={props.handlePopUpClose}>
				<div>
                    <div className="settingOption" onClick={props.showThumbnailToggle}>Show Thumbnail</div>
                    <div className="settingOption" onClick={props.duplicateCourse}>Duplicate course</div>
				</div>
			</PopUp>
			
		</div>
	);
}

export default CoursesView;
