import React,{useState} from 'react';
import PopUpContainer from '@components/PopUpContainer';
import TextInputV2 from '@components/TextInputV2';
import './style.css'
import { decryptText } from '@library/enc-dec';
import * as LeadsServices from '@services/Leads';
import Swal from 'sweetalert2';

const AddLeadPopUp=(props)=>{
    const[phoneNumber, setPhoneNumber] =useState('');
    const[countryCode, setCountryCode] =useState('');
    const[email, setEmail] =useState('');

    const handlePhoneNumber=(number)=>{
        if(!isNaN(number) && number.length<11)
        {
            setPhoneNumber(number)
        }
    }

    const createLead= async()=>{
        let authToken = decryptText(localStorage.getItem('aEmediat'));
		const payload ={
			contactNumber: phoneNumber, 
			email: email};

		const response = await LeadsServices.createLead(payload, authToken);
		if(response.success)
        {
            props.handleSave();
            Swal.fire({icon: "success",title: response.message,showConfirmButton: false,timer: 2500});
        }else{
            Swal.fire({icon: "error",title: response.message,showConfirmButton: false,timer: 2500});
        }
    }

    return <PopUpContainer
            title={'User details'}
            isSearchEnabled={false}
            style={{width:'427px', height:'218px'}}
            isButtonsEnabled={true}
            handleCancel ={props.handleCancel}
            handleSave={createLead}
            blackButtonText={'Create'}
            >
            <div className='d-flex flex-column gap-2 w-100'>
                <TextInputV2
                    placeholder='Phone number'
                    value={phoneNumber}
                    onChange={(event)=>handlePhoneNumber(event.target.value)}
                    size={'small'}
                />
                <div className='d-flex ms-auto me-auto'>Or</div>
                <TextInputV2
                    placeholder='Email ID'
                    value={email}
                    onChange={(event)=>setEmail(event.target.value)}
                    size={'small'}
                />
                <div className='hr'></div>
            </div>
        </PopUpContainer> 
}

export default AddLeadPopUp