import React from 'react';
import { Link } from 'react-router-dom';

import RoundedWhitePlusIcon from '@assets/common/roundedWhitePlusIcon.png';
import './faqDetails.css';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import DnD from '@components/DnD';

import FaqQuestion from '@components/FaqQuestion';
import TextInputV2 from '@components/TextInputV2';
import SaveBtn from '@components/SaveBtn'

function FaqDetailsView(props) {
	return (
		<div className='rightHandRightPageMainContainer'>
			<div className='marginLeftContainer' style={{maxWidth:'800px'}}>
				<div className='topContainer'>
					<h4 className='heading'>FAQs Details</h4>
					<div>
						<Link
							style={{ textDecoration: 'none' }}
							className='cancelBtn'
							to='/content?tab=faq'>
							Cancel
						</Link>
						<SaveBtn onClick={props.handleSave}
							  text={"Save"}
							  altText={"Saving..."}
							  disabled={props.isFormSaving}
							/>
					</div>
				</div>

				{/* START : Error container */}
				{props.formValuesEmpty && (
					<div className='incompleteFieldsError'>
						<span>All input fields are required</span>
					</div>
				)}
				{/* END : Error container */}

				<br />

				{/* FAQ Set input */}
				<TextInputV2 id ={"faqTitle"} placeholder ={"FAQ Set Name"} required ={true}
                    value={props.faqSetTitle}
                    onChange={props.handleFaqSetTitle}
                />
				<DragDropContext onDragEnd={props.onDragEnd}>
					<Droppable droppableId='dataSet'>
						{(provided) => (
							<div ref={provided.innerRef} {...provided.droppableProps}>
								{props.questionsSet.map((question, index) => (
									<>
										<DnD
											element={question}
											index={index}
											newComponent={
												<FaqQuestion
													questionContent={question}
													onDeleteRequest={props.handleQuestionDelete}
													onValueChange={props.handleQuestionUpdate}
												/>
											}
										/>
									</>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				{/* START : DnD code */}
				{/* <DnD dataSet={props.questionsSet} newComponent={<div></div>} index={0} /> */}
				{/* END : DnD code */}

				<br />

				<center style={{paddingBottom:'10%'}}>
					<button onClick={props.handleAddFaqQuestion} className='addQuestionBtn'>
						<img
							className='roundedWhitePlusIcon'
							src={RoundedWhitePlusIcon}
							alt='RoundedWhitePlusIcon'
						/>
						Add question
					</button>
				</center>
			</div>
		</div>
	);
}

export default FaqDetailsView;
