import React, { useEffect, useState, useRef } from "react";
import ContentView from "./view";
import { useParams, useNavigate } from "react-router-dom";

import { getEventElementPosition } from "@helpers/common.js";
import * as SeriesServices from "@services/Series";
import { decryptText } from "@library/enc-dec";

import ClipboardJS from "clipboard";
import { navigationData } from "./data";

import ErrorView from "@components/ErrorView";
import Swal from "sweetalert2";

function Content() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [seriesContent, setSeriesContent] = useState([]);
  const [sectionIndex, setSectionIndex] = useState("");
  const [videoIndex, setVideoIndex] = useState("");

  const [popUp, setPopUp] = useState("");
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({});

  const [newVideoUrl, setNewVideoUrl] = useState("");
  const [isFormSaving, setIsFormSaving] = useState(false);

  useEffect(() => {
    getSeriesSections();
  }, []);

  const getSeriesSections = async () => {
    try {
      if (id) {
        let authToken = decryptText(localStorage.getItem("aEmediat"));
        let response = await SeriesServices.getSeriesSections(id, authToken);
        if (response.success) {
          setSeriesContent(response.data);
          return;
        }
      }
      // New Course will add
      // setCourseDetails(newEmptySeries);
    } catch (err) {
      console.log("Error coming while fetching course details", err);
    }
  };

  const handlePopUpOpen = (event, popUpName, sectionIndex, videoIndex) => {
    handlePopUpClose();
    setPopUp(popUpName);
    setIsPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
    setSectionIndex(sectionIndex);
    setVideoIndex(videoIndex);
  };
  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
    setPopUp("");
  };
  const handlePopUpSave = async () => {
    handlePopUpClose();
  };

  const handleChange = async (value, field, index) => {
    var updatedseriesContent = [...seriesContent];
    if (field == "thumbnailUrl") {
      value = value[0];
      handlePopUpClose();
    }
    updatedseriesContent[index][field] = value;

    setSeriesContent(updatedseriesContent);
  };

  const handleAddSection = (index) => {
    var updatedSeriesContent = [...seriesContent];
    const section = {
      _id: String(index + 1),
      sectionId: "",
      sectionTitle: "",
      videos: [],
    };

    if (index == -1) {
      updatedSeriesContent = [section];
    } else {
      updatedSeriesContent.splice(index + 1, 0, section);
    }
    setSeriesContent(updatedSeriesContent);
  };

  const deleteSection = () => {
    handlePopUpClose();
    var updatedSeriesContent = [...seriesContent];
    updatedSeriesContent.splice(sectionIndex, 1);
    setSeriesContent(updatedSeriesContent);
  };
  const deleteVideo = (index, videoIndex) => {
    var updatedSeriesContent = [...seriesContent];
    updatedSeriesContent[index].videos.splice(videoIndex, 1);
    setSeriesContent(updatedSeriesContent);
  };

  const addNewVideo = async () => {
    handlePopUpClose();
    try {
      // https://emediskill.com/learn/v/6489bc1cea4fd0205e764321
      let videoDocId = newVideoUrl.split("/");
      videoDocId = videoDocId[videoDocId.length - 1];
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let response = await SeriesServices.getVideoById(videoDocId, authToken);

      if (response.data) {
        var updatedSeriesContent = [...seriesContent];
        updatedSeriesContent[sectionIndex].videos.splice(
          videoIndex,
          0,
          response.data
        );
        setSeriesContent(updatedSeriesContent);
        return;
      }
      // handleDeleteVideo(moduleId, 'dummy-video-id');
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (err) {
      console.log("Error coming while adding video", err);
    }
  };

  const onSectionDragEnd = (result) => {
    if (!result.destination) return;
    var updatedSeriesContent = [...seriesContent];
    const [reorderedItem] = updatedSeriesContent.splice(result.source.index, 1);
    updatedSeriesContent.splice(result.destination.index, 0, reorderedItem);
    setSeriesContent(updatedSeriesContent);
  };
  const onVideoDragEnd = (result, index) => {
    if (!result.destination) return;
    var updatedSeriesContent = [...seriesContent];
    const [reorderedItem] = updatedSeriesContent[index].videos.splice(
      result.source.index,
      1
    );
    updatedSeriesContent[index].videos.splice(
      result.destination.index,
      0,
      reorderedItem
    );
    setSeriesContent(updatedSeriesContent);
  };

  const handleSave = async () => {
    try {
      setIsFormSaving(true);
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        seriesId: id,
        sections: [],
      };

      seriesContent.forEach((section) => {
        let content = {
          sectionTitle: section.sectionTitle,
          videoDocIds: section.videos.map((video) => video._id),
        };
        payload.sections.push(content);
      });

      const response = await SeriesServices.updateSeriesSections(
        payload,
        authToken
      );
      if (response.success) {
        setIsFormSaving(false);
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }

      setIsFormSaving(false);
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    } catch (err) {}
  };

  const handleCopyClick = (textToCopy) => {
    const clipboard = new ClipboardJS(".clipboard", {
      text: () => textToCopy,
    });

    clipboard.on("success", (e) => {
      // setAlertVisible(true);
      // setTimeout(() => {
      // 	setAlertVisible(false);
      // }, 1000);
      e.clearSelection();
      clipboard.destroy();
    });

    clipboard.on("error", (e) => {
      console.error("Copy failed:", e.action);
      clipboard.destroy();
    });
  };

  const handleRedirectToVideo = (index, videoDocId) => {
    var defaultComponent = "";
    if (index === 0) {
      defaultComponent = "VideoDetails";
    } else if (index === 1) {
      defaultComponent = "VideoComments";
    } else if (index === 2) {
      defaultComponent = "VideoAnalytics";
    } else if (index === 3) {
      var x =
        `${process.env.REACT_APP_E_MEDISKILL_WEB_APP}` +
        "/video/v/" +
        videoDocId;
      window.open(x, "_blank");
      return;
    } else if (index === 4) {
      var x =
        `${process.env.REACT_APP_E_MEDISKILL_WEB_APP}` +
        "/video/v/" +
        videoDocId;
      handleCopyClick(x);
      return;
    }
    //Handle setting default page while navigating
    let finalNavigationData = [];
    navigationData[0].route = navigationData[0].route + "/" + id;
    finalNavigationData = navigationData;

    if (defaultComponent !== "none") {
      finalNavigationData = setDefaultNavigation(defaultComponent);
    } else {
      // VAR x ONLY FOR TESTING
      // let x = 'VideoComments';
      // finalNavigationData = setDefaultNavigation(x);
      finalNavigationData = setDefaultNavigation("VideoDetails");
    }

    if (videoDocId === "none") {
      navigate("/videos", { state: { finalNavigationData } });
    } else {
      navigate("/videos/" + videoDocId, { state: { finalNavigationData } });
    }
  };

  const setDefaultNavigation = (defaultComponent) => {
    return navigationData.map((item) => {
      if (item.component === defaultComponent) {
        return { ...item, default: true };
      }
      return item;
    });
  };

  return (
    <>
      {id === undefined ? (
        <ErrorView
          text={"Please Select or Add a series before adding content."}
        ></ErrorView>
      ) : (
        <ContentView
          id={id}
          seriesContent={seriesContent}
          handleChange={handleChange}
          handleSave={handleSave}
          popUp={popUp}
          popUpPosition={popUpPosition}
          isPopUpVisible={isPopUpVisible}
          handlePopUpClose={handlePopUpClose}
          handlePopUpOpen={handlePopUpOpen}
          handlePopUpSave={handlePopUpSave}
          isFormSaving={isFormSaving}
          handleAddSection={handleAddSection}
          handleRedirectToVideo={handleRedirectToVideo}
          newVideoUrl={newVideoUrl}
          setNewVideoUrl={setNewVideoUrl}
          addNewVideo={addNewVideo}
          deleteSection={deleteSection}
          deleteVideo={deleteVideo}
          onSectionDragEnd={onSectionDragEnd}
          onVideoDragEnd={onVideoDragEnd}
        />
      )}
    </>
  );
}

export default Content;
