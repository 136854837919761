import { useEffect, useState } from "react";

import * as UserServices from "@services/Users";
import * as LeadsServices from "@services/Leads";
import Swal from "sweetalert2";
import { decryptText } from "@library/enc-dec";
import TextInputWithMentions from "@components/TextInputWithMentions";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as CommonActions from "@redux/actions/Common";

function AddLeadCommentPopUp(props) {
  const [comment, setComment] = useState("");
  const [taggedUsers, setTaggedUser] = useState([]);
  const [allAdminUsers, setAllAdminUsers] = useState([]);

  useEffect(() => {
    if (props.allTaggableUsers.length > 0) {
      setAllAdminUsers(props.allTaggableUsers);
    } else {
      getAllAdminUsers();
    }
  }, []);

  const getAllAdminUsers = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        pageNo: 0,
        limit: 999999,
        isSorted: "true",
        sortBy: "firstName",
        searchText: "",
      };
      var response = await UserServices.getAllAdmins(payload, authToken);
      if (response.success) {
        response.data = response.data.map((user) => {
          return {
            _id: user._id,
            name: user.fullName,
            fullName: user.fullName,
            displayPhoto: user.displayPhoto,
          };
        });
        setAllAdminUsers(response.data);
        props.updateAllTaggableUsers(response.data);
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while getting all BSS Forms", err);
    }
  };

  const handleChangeComment = (text, userList) => {
    setComment(text);
    setTaggedUser(userList);
  };
  const handleSave = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const payload = {
      leadId: props.leadId,
      comment: comment,
      taggedUsers: taggedUsers.map((user) => user._id),
    };

    const response = await LeadsServices.addCommentOnLead(payload, authToken);
    if (response.success) {
      Swal.fire({
        icon: "success",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
      props.handleSave();
    } else {
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  return (
    <div className="queryCommentContainer">
      <TextInputWithMentions
        userList={[...allAdminUsers]} // List of tagable users
        value={""}
        onChange={handleChangeComment}
      />
      <div className="btnContainer">
        <button className="cancleBtn" onClick={() => props.handleCancel()}>
          Cancel
        </button>
        <button className="saveBtn" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    allTaggableUsers: state.common.allTaggableUsers,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAllTaggableUsers: CommonActions.updateAllTaggableUsers,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddLeadCommentPopUp);
