import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import TestQuestionsView from './view';

import { produce } from 'immer';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { decryptText } from '@library/enc-dec';

import * as TestServices from '@services/Tests';
import * as TestActions from '@redux/actions/Test';
import Swal from 'sweetalert2';


function TestQuestions(props) {
	const navigate = useNavigate();
	const dataFetchedRef = useRef(false);
	const { id } = useParams();

	const [formValuesEmpty, setFormValuesEmpty] = useState(false);
	const [questionsSet, setQuestionsSet] = useState([]);

	const[errorMessage, setErrorMessage] = useState("")
	const [isFormSaving, setIsFormSaving] = useState(false)
	const [csv, setCsv] = useState('')

	useEffect(() => {
		window.scrollTo(0, 0);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;

		if (!id) {
			navigate('/test');
			return;
		}
		getQuestionBank();
		return () => {
			setQuestionsSet([]);
			convertQuestionBankToCSV([])
			props.resetTestsReducer();
		};
	}, []);

	const getQuestionBank = async () => {
		try {
			const questionBankId = id;

			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const response = await TestServices.getTestQuestionBankById(
				questionBankId,
				authToken
			);
			if (response.success) {
				setQuestionsSet(response.data.questionsSet);
				convertQuestionBankToCSV(response.data.questionsSet)
				props.updateTestDetails(response.data);
			}
		} catch (err) {
			console.log('Error coming while geting question bank', err);
		}
	};

	// Handling questions
	const handleAddQuestion = (event, index) => {
		const questionContent = {
			_id: `question-${questionsSet.length + 1}`,
			question: '',
			options: [],
			answer: '',
			thumbnailUrl:''
		};

		var newQuestionSet = [...questionsSet]
		newQuestionSet.splice(index, 0, questionContent)
		setQuestionsSet(newQuestionSet);
	};

	const handleQuestionUpdate = async (question, index) => {
		let copyQuestionsSet = [...questionsSet];
		let updatedQuestionsSet = copyQuestionsSet.map((item) => {
			if (item._id === question._id) {
				return question;
			}
			return item;
		});
		setQuestionsSet(updatedQuestionsSet);
		convertQuestionBankToCSV(updatedQuestionsSet)
	};

	const handleQuestionDelete = (index) => {
		let updatedQuestionSet = produce(questionsSet, (draft) => {
			draft = draft.splice(index, 1);
		});
		setQuestionsSet(updatedQuestionSet);
		convertQuestionBankToCSV(updatedQuestionSet)
	};

	const onDragEnd = (result) => {
		if (!result.destination) return;

		let updatedQuestionSet = produce(questionsSet, (draft) => {
			const [reorderedItem] = draft.splice(result.source.index, 1);
			draft.splice(result.destination.index, 0, reorderedItem);
		});

		setQuestionsSet(updatedQuestionSet);
		convertQuestionBankToCSV(updatedQuestionSet)
	};

	const handleSave = () => {
		const { title, displayQuestions, duration, passPercentage } = props;
		const { failedMessage, passedMessage, instructions} = props;
		if (!title )
		{
			setFormValuesEmpty(true);
			setErrorMessage("Title field is empty")
			return
		}
		if (!displayQuestions )
		{
			setFormValuesEmpty(true);
			setErrorMessage("Display Questions field is empty")
			return
		}
		if (!duration )
		{
			setFormValuesEmpty(true);
			setErrorMessage("Duration field is empty")
			return
		}
		if (!passPercentage )
		{
			setFormValuesEmpty(true);
			setErrorMessage("Pass Percentage field is empty")
			return
		}
		if (!instructions )
		{
			setFormValuesEmpty(true);
			setErrorMessage("Instruction field is empty")
			return
		}
		if (!passedMessage)
		{
			setFormValuesEmpty(true);
			setErrorMessage("Passed Message field is empty")
			return
		}
		if (!failedMessage)
		{
			setFormValuesEmpty(true);
			setErrorMessage("Failed Message field is empty")
			return
		}
		if (!isValidQuestionSet()		)
		{
			setFormValuesEmpty(true);
			setErrorMessage("Question Set is not valid")
			return
		}
		
		if(Number(displayQuestions) > questionsSet.length)
		{
			setFormValuesEmpty(true);
			setErrorMessage("Please add at least "+displayQuestions+" questions. Or Change number of display questions in details tab")
			return
		}	
		else {

			setFormValuesEmpty(false);

			if (id) {
				handleOldQuestionBankUpdate(id);
				return;
			}
		}
	};

	const isValidQuestionSet = () => {
		//false = isses, true = no Issues
		if (questionsSet.length !== 0) {
			for (const question of questionsSet) {
				if (
					!question.question ||
					!question.answer ||
					!question.options.length === 0
				) {
					return false;
				}
			}
			return true;
		} else {
			return false;
		}
	};

	const handleOldQuestionBankUpdate = async (questionBankId) => {
		try {
			setIsFormSaving(true)
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			let payload = {
				questionBankId: questionBankId,
				title: props.title,
				duration: props.duration,
				passPercentage: props.passPercentage,
				displayQuestions: props.displayQuestions,
				thumbnailUrl:  props.thumbnailUrl,
				declarationResultInDays: props.declarationResultInDays,
				hideResultInDays: props.hideResultInDays,
				instructions: props.instructions,
				passedMessage: props.passedMessage,
				failedMessage: props.failedMessage,
				inSequence: props.inSequence,
				questionsSet: questionsSet.map((question) => {
					return {
						question: question.question,
						options: question.options,
						answer: question.answer,
						thumbnailUrl: question.thumbnailUrl
					};
				}),
			};

			const response = await TestServices.updateTestQuestionBank(payload, authToken);
			if(response.success){
				Swal.fire({icon: "success", title: response.message, showConfirmButton: false,timer: 2500});		
			}else{
				Swal.fire({icon: "error", title: response.message, showConfirmButton: false,timer: 2500});
			}
			setIsFormSaving(false)
		} catch (err) {
			console.log('Error coming while updating question bank', err);
		}
	};

	const duration = props.duration.hours * 60 + props.duration.minutes;

	
	const handleCsvChange=(csvData)=>{
		const dataArray = csvData.trim().split('\n').map((line, index) => {
			const [question, thumbnailUrl, option1, option2, option3, option4, answer] = line.split(', ');
			return {
			  question,
			  thumbnailUrl,
			  options: [option1, option2, option3, option4],
			  answer,
			  _id: String(index)
			};
		  });

		setQuestionsSet(dataArray)
	}

	const convertQuestionBankToCSV=(data)=>{
		data = data.map(question => {
				const questionFields = [
				question.question || '',
				question.thumbnailUrl || '',
				...question.options.map(option => option || ''), // Replace null or undefined options with an empty string
				question.answer || ''
				];

				return questionFields.join(', ');
			})
			.join('\n');
	   setCsv(data)
	}

	return (
		<>
			<TestQuestionsView
				title={props.title}
				displayQuestions={props.displayQuestions}
				duration={duration}
				
				questionsSet={questionsSet}
				formValuesEmpty={formValuesEmpty}
				// Page handlers
				
				
				// helpers flag
				handleSave={handleSave}
				//START : Usestate & fn for questions

				handleAddQuestion={handleAddQuestion}
				//START : Usestate & fn for questions

				//START : Usestate & fn for DnD functionality
				onDragEnd={onDragEnd}
				//END : Usestate & fn for DnD functionality
				handleQuestionDelete={handleQuestionDelete}
				handleQuestionUpdate={handleQuestionUpdate}

				errorMessage ={errorMessage}
				isFormSaving={isFormSaving}
				csv={csv}
				handleCsvChange={handleCsvChange}
			/>
		</>
	);
}
const mapStateToProps = (state) => {
	return {
		allTests: state.test.allTests,
		testsSet: state.test.testsSet,
		title: state.test.title,
		duration: state.test.duration,
		displayQuestions: state.test.displayQuestions,
		passPercentage: state.test.passPercentage,
		thumbnailUrl: state.test.thumbnailUrl,
		declarationResultInDays: state.test.declarationResultInDays,
		hideResultInDays: state.test.hideResultInDays,
		instructions: state.test.instructions,
		passedMessage: state.test.passedMessage,
		failedMessage: state.test.failedMessage,
		inSequence: state.test.inSequence,
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateAllTests: TestActions.updateAllTests,
			updateTotalNumOfTests: TestActions.updateTotalNumOfTests,
			updateTestsSet: TestActions.updateTestsSet,
			updateTestDetails: TestActions.updateTestDetails,
			updateTestTitle: TestActions.updateTestTitle,
			updateTestDuration: TestActions.updateTestDuration,
			updateTestPassPercentage: TestActions.updateTestPassPercentage,
			updateDisplayQuestions: TestActions.updateDisplayQuestions,
			updateTestThumbnailUrl: TestActions.updateTestThumbnailUrl,
			updateResultInDays: TestActions.updateResultInDays,
			updateHideResultInDays: TestActions.updateHideResultInDays,
			updateTestInstructions: TestActions.updateTestInstructions,
			updateTestPassedMessage: TestActions.updateTestPassedMessage,
			updateTestFailedMessage: TestActions.updateTestFailedMessage,
			updateTestQuestionsInSequence: TestActions.updateTestQuestionsInSequence,
			resetTestsReducer: TestActions.resetTestsReducer,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(TestQuestions);
