import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';
import EditPencilIcon from '@assets/common/editPencilIcon.png';

import AnalyticsIcon from '@assets/common/analyticsIcon.png';


//Object to handle naviagtion
export const navigationData = [
    {
        "title" : "Back to Offers",
        "route" : "/offers",
        "default" : false,        
        "icon" : GoBackArrow
    },
    {
        "title" : "Details",
        "component" : "OfferDetails",
        "default" : false,        
        "icon" : EditPencilIcon
    },
    {
        "title" : "Analytics",
        "component" : "OfferAnalytics",
        "default" : false,        
        "icon" : AnalyticsIcon
    },
]
