export const listOfCategoryApi = [
	{
		_id: 1,
		categoryName: 'cat1',
	},
	{
		_id: 2,
		categoryName: 'cat2',
	},
	{
		_id: 3,
		categoryName: 'cat3',
	},
	{
		_id: 4,
		categoryName: 'cat4',
	},
];

export const listOfCoursesApi = [
	{
		_id: 1,
		courseName: 'c1',
		thumbnailUrl: '',
	},
	{
		_id: 2,
		courseName: 'c2',
		thumbnailUrl: '',
	},
	{
		_id: 3,
		courseName: 'c3',
		thumbnailUrl: '',
	},
	{
		_id: 4,
		courseName: 'c4',
		thumbnailUrl: '',
	},
];

export const listofPaymentModesApi = ['razorpay', 'free'];
