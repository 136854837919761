import React from 'react';

import ProfileImgPlaceholder from '@assets/comments/profileImgPlaceholder.png';
import UnFillHeartIcon from '@assets/comments/unFillHeartIcon.png';
import ShareIcon from '@assets/comments/shareIcon.png';

import numeral from 'numeral';
import moment from 'moment';

import './replySection.css';

function ReplySectionView(props) {
	return (
		<>
			{props.commentRepliesData && props.commentRepliesData.length > 0 && (
				<>
					<div
						className='accordion commentsAccordion'
						style={{ width: '179%' }}
						id={'commentsReplyAccordion_' + props.currentCommentId}>
						<div
							id={'commentRepliesCollapse_' + props.currentCommentId}
							className='accordion-collapse collapse'
							data-bs-parent={'#commentsReplyAccordion_' + props.currentCommentId}>
							<div className='row accordion-body' style={{ position: 'relative' }}>
								{props.commentRepliesData.map((replyData, replyIndex) => (
									<>
										<div style={{ display: 'flex' }}>
											<div className='col-profileImgPlaceholder'>
												<img
													src={
														replyData.displayPhoto
															? replyData.displayPhoto
															: ProfileImgPlaceholder
													}
													alt='Profile Placeholder'
													style={{ width: '30px' }}
												/>
											</div>

											<div
												className='col-commentContainer'
												style={{
													width: '100%',
													marginBottom: '10px',
													paddingLeft: '10px',
												}}>
												<p className='commenterName'>
													{replyData.username}
													<span className='commentTime'>
														{moment(replyData.createdAt).fromNow()}
														{/* {replyData.commentDate} */}
													</span>
												</p>

												<p className='comment'>
													<div dangerouslySetInnerHTML={{ __html: replyData.reply }} />
													{/* {replyData.reply} */}
												</p>

												<div className='commentInteractionContainer'>
													<button
														className='commentReplyBtn'
														data-reply_container_id={replyData._id}
														data-has_reply_container={'has_' + replyData._id}
														onClick={(event) => {
															props.handleCommentReply(
																event,
																replyData.commentId,
																'replyOnReply'
															);
														}}>
														Reply
													</button>

													<p
														className='commentLikeContainer'
														onClick={() => props.handleReplyLike(replyData)}>
														{replyData.isLiked ? (
															<i className='fa-solid fa-thumbs-up'></i>
														) : (
															<i className='fa-regular fa-thumbs-up'></i>
														)}
														<span>{numeral(replyData.likesCount).format('0.0a')}</span>
													</p>

													<p
														className='commentDislikeContainer'
														onClick={() => props.handleReplyDislike(replyData)}>
														{replyData.isDisliked ? (
															<i className='fa-solid fa-thumbs-down fa-flip-horizontal'></i>
														) : (
															<i className='fa-regular fa-thumbs-down fa-flip-horizontal'></i>
														)}
													</p>

													{/* <img
														className='favIcon'
														src={UnFillHeartIcon}
														alt='UnFillHeartIcon'
													/> */}

													<img className='shareIcon' src={ShareIcon} alt='ShareIcon' />
												</div>
											</div>

											<div className='col-commentActionContainer'>
												{(replyData.status === 'published' ||
													replyData.status === 'rejected') && (
													<button
														onClick={(event) => {
															props.commentAction('heldForReview', replyData._id);
														}}
														className='commentActionBtn'>
														Held for review
													</button>
												)}

												{replyData.status === 'heldForReview' && (
													<button
														onClick={(event) => {
															props.commentAction('published', replyData._id);
														}}
														className='commentActionBtn'>
														Approve
													</button>
												)}

												{(replyData.status === 'heldForReview' ||
													replyData.status === 'published') && (
													<button
														onClick={(event) => {
															props.commentAction('rejected', replyData._id);
														}}
														className='commentActionBtn'
														style={{ marginLeft: '15px' }}>
														Reject
													</button>
												)}
											</div>
										</div>
									</>
								))}
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}

export default ReplySectionView;
