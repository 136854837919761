import { useEffect, useState, useRef } from "react";
import { useLocation, defer, useParams } from "react-router-dom";

import ErrorView from "@components/ErrorView";
import LeadDetailsView from "./view";

import { decryptText } from "@library/enc-dec";
import { checkLoginAndRole } from "@helpers/Login/";
import { getEventElementPosition, toggleFullscreen } from "@helpers/common.js";

import * as LeadServices from "@services/Leads";
import * as LeadsServices from "@services/Leads";
import Swal from "sweetalert2";

function LeadDetails() {
  const location = useLocation();
  const { id } = useParams();

  // START : popup stats
  const [popUp, setPopUp] = useState("");
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({});
  // END : popup stats

  const [deferredLeadDetails, setDeferredLeadDetails] = useState({});
  const [leadActivityData, setLeadActivityData] = useState([]);
  const [leadId, setLeadId] = useState("");
  const [lead, setLead] = useState("");

  useEffect(() => {
    checkLoginAndRole("quries");
    if (id) {
      getLeadDetails();
    }
  }, []);

  const getLeadDetails = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const response = await LeadServices.getLeadActivities(id, authToken);
    if (response.success) {
      setLeadActivityData(response.data.activities);
      setLead(response.data);
      setLeadId(response.data.leadId);
      var deferredResponse = defer({
        res: getActivitiesPromise(response.data),
      });
      setDeferredLeadDetails(deferredResponse);
    } else {
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  function getActivitiesPromise(data) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          currentDocCount: 1,
          data: [data],
          pageLimit: 0,
          totalDocCount: 1,
        });
      }, 10); // Resolving after 1 second
    });
  }

  //START : Popup fns
  const handlePopUpOpen = (event, popUpName) => {
    handlePopUpClose();
    setPopUp(popUpName);
    setIsPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };
  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
  };
  const handlePopUpSave = (data) => {
    if (
      [
        "addCallLog",
        "addToWaiting",
        "moveTo",
        "addComment",
        "transferTo",
      ].includes(popUp)
    ) {
      getLeadDetails();
    }
    handlePopUpClose();
  };

  const handleClaimLead = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const payload = {
      leadIds: [leadId],
    };
    let response = await LeadsServices.claimTheLeads(payload, authToken);
    if (response.success) {
      Swal.fire({
        icon: "success",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
      getLeadDetails();
    } else {
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };
  const handleReleaseLead = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const payload = {
      leadIds: [leadId],
    };
    let response = await LeadsServices.moveToRelease(payload, authToken);
    if (response.success) {
      Swal.fire({
        icon: "success",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
      getLeadDetails();
    } else {
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  const moveToArchive = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));

    const payload = {
      leadList: [
        {
          leadId: leadId,
          movedAt: null,
          moveToFolderName: "archive",
          currentFolderName: lead.currentFolderName,
          readableMoveToFolderName: "Archive",
          readableCurrentFolderName: lead.currentFolderName,
        },
      ],
    };

    let response = await LeadsServices.leadMoveToFolder(payload, authToken);

    if (response.success) {
      Swal.fire({
        icon: "success",
        title: "Moved to archive",
        showConfirmButton: false,
        timer: 2500,
      });
      getLeadDetails();
    } else {
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  return (
    <>
      {id ? (
        <LeadDetailsView
          // START : states for popup
          popUp={popUp}
          isPopUpVisible={isPopUpVisible}
          popUpPosition={popUpPosition}
          handlePopUpClose={handlePopUpClose}
          handlePopUpOpen={handlePopUpOpen}
          handlePopUpSave={handlePopUpSave}
          // END : states for popup

          leadId={leadId}
          deferredLeadDetails={deferredLeadDetails}
          leadActivityData={leadActivityData}
          moveToArchive={moveToArchive}
          handleClaimLead={handleClaimLead}
          handleReleaseLead={handleReleaseLead}
        />
      ) : (
        <ErrorView text={"Please Select/Create User "} />
      )}
    </>
  );
}

export default LeadDetails;
