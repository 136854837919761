import React from "react";

import './courseCategoryHome.css';

function CourseCategoryHomeView(){
    return(
        <>
            
        </>
    )
}

export default CourseCategoryHomeView;