import { createRoot } from 'react-dom/client';
import ProfileImgPlaceholder from '@assets/comments/profileImgPlaceholder.png';
import CommentReplyInput from '@components/CommentReplyInput';

export const handleCommentReply = (
	event,
	commentId,
	replyType,
	userDetails,
	handleAddReply
) => {
	let replyContainerId = event.target.dataset.reply_container_id;
	let hasReplyContainer = event.target.dataset.has_reply_container;

	if (hasReplyContainer !== 'true') {
		event.target.dataset.has_reply_container = 'true';
		let parentElement = event.target.parentNode;
		let commentReplyInputContainer = document.createElement('div');
		parentElement.insertAdjacentElement('afterend', commentReplyInputContainer);
		createRoot(commentReplyInputContainer).render(
			<CommentReplyInput
				replyContainerId={replyContainerId}
				ProfileImgPlaceholder={
					userDetails?.displayPhoto ? userDetails.displayPhoto : ProfileImgPlaceholder
				}
				handleAddReply={handleAddReply}
				replyType={replyType}
				commentId={commentId}
			/>
		);
	} else {
		if (document.getElementById('replyContainerId_' + replyContainerId)) {
			let replyContainer = document.getElementById(
				'replyContainerId_' + replyContainerId
			);
			replyContainer.style.display = 'block';
		}
	}
};
