import React from 'react';
import { Link } from 'react-router-dom';

import './style.css';

import DownArrow from '@assets/common/downArrow.png';
import UpArrow from '@assets/common/upArrow.png';
import BulletSelector from '@components/BulletSelector';
import ThumbnailPopupV2 from '@components/ThumbnailPopupV2';


//Imports for RichEditor
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SaveBtn from '@components/SaveBtn'
import TextInputV2 from '@components/TextInputV2';

function TestDetailsView(props) {
	//START : Vars for RichEditor
	const toolbarOptions = [
		{ header: [1, 2, 3, false] },
		{ font: [] },
		// { 'size': ['small', false, 'large', 'huge'] },
		'bold',
		'italic',
		'underline',
		'strike',
		{ script: 'sub' },
		{ script: 'super' },
		{ list: 'bullet' },
	];

	const formats = [
		'header',
		'font',
		'size',
		'bold',
		'italic',
		'underline',
		'strike',
		'script',
		'list', 
		'bullet', 
		'indent',
	];

	return (
		<>
			<div className='rightHandRightPageMainContainer d-flex flex-column' 
				style={{
					width:'calc(100%-230px)', 
					maxWidth:'calc(100%-230px)',
					maxWidth :'100%!important'
				}}>

				<div className='topContainer w-100' style={{borderBottom: '1px solid #ddd'}}>
						<h4 className='heading'>Test Details</h4>
						<div>
							<Link
								style={{ textDecoration: 'none' }}
								className='cancelBtn'
								to='/content?tab=tests'>
								Cancel
							</Link>

							<SaveBtn onClick={props.handleSave}
							  text={"Save"}
							  altText={"Saving..."}
							  disabled={props.isFormSaving}
							/>
						</div>
						
                
					{/* START : Error container */}
					{props.formValuesEmpty && (
						<div className='incompleteFieldsError'>
							<span>{props.errorMessage}</span>
						</div>
					)}
					{/* END : Error container */}
				</div>

                <div className='ps-4 py-4' style={{flexGrow: 1, overflow: 'scroll'}}>
				<div style={{maxWidth: '936px'}} className='d-flex flex-column gap-3'>
					{/* Test name */}
					<TextInputV2 placeholder={"Set name"}
						value={props.title}
						required={true}
						onChange={(event)=>{props.handleTestTitle(event)}}/> 

					{/* Number of questions */}
					<div className='noOfQuestionsContainer' style={{marginBottom:'10px' }}>
						<TextInputV2  placeholder={"Display questions"}
							value={props.displayQuestions}
							required={true}
							onChange={props.handleDisplayQuestions}
						/> 
						<div className='upDownNoOfQuestionArrowContainer'>
							<img
								onClick={props.handleIncreaseDisplayQuestions}
								className='upArrow'
								src={UpArrow}
								alt='UpArrow'/>
							<img
								onClick={props.handleDecreaseDisplayQuestions}
								className='downArrow'
								src={DownArrow}
								alt='DownArrow'/>
						</div>
					</div>

					
						<div className='col-6 mt-5'>
							<div className='maxDurationContainer'>
								<TextInputV2  placeholder={"Test duration"}
									value={props.duration}
									required={true}
									onChange={props.handleDuration}
								/> 
							</div>

							{/* Thumbnail */}
							<h4 className='subHeading' style={{ marginTop: '27px' }}>
								Thumbnail
							</h4>
							<ThumbnailPopupV2
								thumbnailUrl={props.testThumbnail}
								onValueChange={props.handleThumbnailChange}
							/>
						</div>
						<TextInputV2 
									placeholder={"Pass percentage %"}
									value={props.passPercentage}
									required={false}
									onChange={props.handlePassPercentage}
								/> 
						<div className='col-12'>
							{/* START : Show results input div */}
							<div className='form-check' style={{ marginTop: '10px' }}>
								<input
									checked={props.isDeclareResultImmediately}
									onChange={(event) => {
										props.handleIsDeclareResultImmediately(event, 'immediately');
									}}
									className='form-check-input'
									type='radio'
									name='declareResult'
									id='declareResultImmediatelyRadioBtn'
								/>
								<label htmlFor='declareResultImmediatelyRadioBtn'>
									Declare detailed result immediately
								</label>
							</div>

							<div className='form-check' style={{ marginTop: '20px', marginBottom: '20px' }}>
								<input
									checked={props.isDeclareResultImmediately ? false : true}
									onChange={(event) => {
										props.handleIsDeclareResultImmediately(event, 'notImmediately');
									}}
									className='form-check-input'
									type='radio'
									name='declareResult'
									id='declareResultInXDaysRadioBtn'
								/>
								<label
									style={{ display: 'flex' }}
									htmlFor='declareResultInXDaysRadioBtn'>
									Declare detailed result in
									<input
										value={props.declareResultInDays}
										onChange={(event) => {
											props.handleDeclaredResultInDays(event);
										}}
										style={{
											padding: '7px',
											height: '25px',
											width: '124px',
											margin: '0px 10px',
										}}
										type='number'
										className='form-control inputBox'
									/>
									days
								</label>
							</div>

							{/* END : Show results input div */}

							<hr style={{ marginLeft: '-12px' }} />

							{/* START : Hide result container */}
							<div className='form-check' style={{ marginBottom: '30px' , marginTop:'20px'}}>
								<input
									checked={props.isHideResult}
									value={props.isHideResult}
									onChange={props.handleIsHideResult}
									className='form-check-input'
									type='checkbox'
									id='hideResult'
								/>
								<label style={{ display: 'flex' }} htmlFor='hideResult'>
									For
									<input
										value={props.hideResultInDays}
										onChange={(event) => {
											props.handleHideResultInDays(event);
										}}
										style={{
											padding: '7px',
											height: '25px',
											width: '124px',
											margin: '0px 10px',
										}}
										type='number'
										className='form-control inputBox'
									/>
									days
								</label>
							</div>
							{/* END : Hide result container */}
						</div>
					
				

				{/* START : Instruction Paragraph */}
					<div className='testPointerParaContainer' style={{marginTop:'10px' }}>
						<h4 className='subHeading'>Instruction Paragraph</h4>
						<BulletSelector
							icon={props.instructions.icon}
							onValueChange={props.handleBulletIconChange}
						/>
					</div>

					{/* START : Code for RichEditor */}
					<ReactQuill
						ref={props.quillInstructionRef}
						value={props.instructions.pointer}
						onChange={props.handleInstructions}
						modules={{
							toolbar: toolbarOptions,
						}}
						formats={formats}
					/>
					{/* END : Code for RichEditor */}
				
				{/* END : Instruction Paragraph */}


				{/* START : Passed Test Message */}
					<div className='testPointerParaContainer'>
						<h4 className='subHeading'>Passed Test Message</h4>
					</div>

					{/* START : Code for RichEditor */}
					<ReactQuill
						ref={props.quillPassTestMessageRef}
						value={props.passedMessage}
						onChange={props.handlePassMessage}
						modules={{
							toolbar: toolbarOptions,
						}}
						formats={formats}
					/>
					{/* END : Code for RichEditor */}
				
				{/* END : Passed Test Message */}


				{/* START : Failed Test Message */}
					<div className='testPointerParaContainer'>
						<h4 className='subHeading'>Failed Test Message</h4>
					</div>

					{/* START : Code for RichEditor */}
					<ReactQuill
						ref={props.quillFailedTestMessageRef}
						value={props.failedMessage}
						onChange={props.handleFailedMessage}
						modules={{
							toolbar: toolbarOptions,
						}}
						formats={formats}
					/>
					{/* END : Code for RichEditor */}
					
				{/* END : Failed Test Message */}

				{/* START : Checkbox to Shuffle Questions */}
				<div className='marginLeftContainer form-check' style={{marginTop:'10px', marginBottom:'10%' }}>
					<input
						checked={props.isShuffleQuestions}
						onChange={(event) => {
							props.handleIsShuffleQuestions(event);
						}}
						className='form-check-input'
						type='checkbox'
						id='shuffleQuestionsCheckBox'
					/>
					<label htmlFor='shuffleQuestionsCheckBox'>Shuffle Questions</label>
				</div>
				{/* END : Checkbox to Shuffle Questions */}
				</div>
				</div>
					

			</div>
		</>
	);
}

export default TestDetailsView;
