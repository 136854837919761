export const updateAllCourses = (value) => {
	return {
		type: 'UPDATE_ALL_COURSES',
		payload: {
			allCourses: value,
		},
	};
};

export const updateTotalNumOfCourses = (value) => {
	return {
		type: 'UPDATE_TOTAL_NUMBER_OF_COURSES',
		payload: {
			totalNumberOfCourses: value,
		},
	};
};

export const updateCourseSet = (value) => {
	return {
		type: 'UPDATE_COURSE_SET',
		payload: {
			courseSet: value,
		},
	};
};

export const resetCoursesReducer = (value) => {
	return {
		type: 'RESET_COURSES_REDUCER',
	};
};
export const updatePageNoCourses = (value) => {
	return {
		type: 'UPDATE_COURSE_PAGE_NUMBER',
		payload: {
			pageNoCourses: value,
		},
	};
};
export const updateIsSorted = (value) => {
	return {
		type: 'UPDATE_COURSE_IS_SORTED',
		payload: {
			isSorted: value,
		},
	};
};

export const updateFilterInformation = (value) => {
	return {
		type: 'UPDATE_COURSE_FILTER_INFORMATION',
		payload: {
			filterInformation: value,
		},
	};
};
