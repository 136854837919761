import React from 'react';

import './style.css';

function EditVisibilityPopUpView(props) {
	return (
		<div className="editVisibilityDiv">
			<span><h6>Set Course(s) As</h6></span>

			{props.error && props.error !== '' && (
				<div className='alert alert-danger w-50'>
					{props.error}
				</div>
			)}
            <div>
				<input type='radio' className='colorRadio' name='visibility'
					checked={props.visibility === 'private'}
					onChange={(event)=>props.setVisibility('private')}
				/> Private <br/> <br/>
				<input type='radio' className='colorRadio' name='visibility'
					checked={props.visibility === 'public'}
					onChange={(event)=>props.setVisibility('public')}
					/> Public <br/> <br/>
				<input type='radio' className='colorRadio' name='visibility'
					checked={props.visibility === 'unlisted'}
					onChange={(event)=>props.setVisibility('unlisted')}
				/> Unlisted <br/> 
			</div>
			<div className="hr"/>

            <div className="d-flex justify-content-end gap-1">
				<button onClick={props.handleCloseBtn} className='closeBtn'>
				Cancel
				</button>
				<button onClick={props.handleSaveBtn} className='saveBtn'>
					Save
				</button>
            </div>



		</div>
	);
}

export default EditVisibilityPopUpView;
