import React from "react";

import './errorView.css'

function ErrorView(props){

    return(
      <div className="leftMarginContainer">
        <h3>
            {props.text}
        </h3>
      </div>
    )
}

export default ErrorView;