import React from "react";

import "./offerCard.css";

import WatchIcon from "@assets/common/stopWatch.png";
import NotifyIcon from "@assets/common/notify.png";

function OfferCardView(props) {
  props = props.props;

  const handleRichTextEditorCourseDiscription = (htmlStr, icon) => {
    if (icon !== null) {
      const infoIcon = "<img className='infoIcon' src='" + icon + "'/>";
      let newHtmlStr = htmlStr;
      if(newHtmlStr==='undefined')
        {
          return htmlStr
        }
      return newHtmlStr?.replace(
        /<li>/g,
        '<li style="background-image: url(' +
          icon +
          '); background-repeat: no-repeat; background-size: 20px 20px;">'
      );
    }
    return htmlStr;
  };
  return (
    <div className="d-lg-block" id="productCard">
      <div
        className="productImgContainer"
        style={{
          background: `radial-gradient(circle farthest-side at 70% 65%, #ffffff, ${props.color})`,
        }}
      >
        {props.thumbnailUrl && props.thumbnailUrl != "{}" ? (
          <img src={props.thumbnailUrl} className="w-100" alt="productCard" />
        ) : (
          <div className="empty-image">
            <p style={{ marginTop: "-150px", color: "#252525" }}>
              No image available
            </p>
          </div>
        )}
      </div>
      <div className="productCardDetails">
        <div className="productCardDetailsInfo">
          {props.courseType === "paid" &&
            (props.offerExpiry?.days > 0 ||
              props.offerExpiry?.hours > 0 ||
              props.offerExpiry?.minutes > 0) && (
              <span className="timeDisplay">
                <img src={WatchIcon} className="productCardIcon"></img>
                &nbsp;&nbsp; Deal ending in &nbsp;
                <b>{`${props.offerExpiry.days}d ${props.offerExpiry.hours}h ${props.offerExpiry.minutes}m `}</b>
              </span>
            )}
          <span className="bulletSpan">
            <div>&nbsp;</div> &nbsp;{props.cardTitle}
          </span>
          <p>{props.description}</p>
          {/* <span style={{ color: '#606060' }}>{props.cardTitle}</span> */}
          <div className="subPointers">
            {props.pointers && (
              // <p className='pointer'>
              // 	<img src={props.pointers.icon} alt='icon' />
              // 	<span>{props.pointers.pointer}</span>
              // </p>
              <div
                id="courseContentDescriptionContainer"
                dangerouslySetInnerHTML={{
                  __html: handleRichTextEditorCourseDiscription(
                    props.pointers?.pointer,
                    props.pointers?.icon
                  ),
                }}
              />
            )}
          </div>
        </div>

        <div className="productStaticIcons text-center">
          <div className="row gap-2 p-2">
            {props.cardIcons &&
              props.cardIcons.map((icon, index) => (
                <p className="col-2 mb-0" key={index}>
                  <img src={icon.icon} alt="icon" />
                  <span>{icon.pointer}</span>
                </p>
              ))}
          </div>
        </div>

        <div className="productCardbottom">
          {props.courseType === "paid" ? (
            <>
              <div className="d-flex gap-3 m-2">
                <h3>₹{props.price + "/-"}</h3>
                <span className="strikediag withpadding text-muted">
                  {props.oldPrice + "/-"}
                </span>
                <b className="text-danger">
                  {Math.round(
                    ((props.oldPrice - props.price) / props.oldPrice) * 100
                  ) + "%"}
                </b>
              </div>
              <button
                className="card-button darkButton"
                onClick={props.handleAvailableOfferBtnClick}
              >
                View Available Offers
              </button>
              <button
                className="card-button yellowButton"
                onClick={props.addToCartBtnClick}
              >
                Add to cart
              </button>
            </>
          ) : props.courseType === "free" ? (
            <>
              {/* <div className='text-end text-danger bold'>
								<b>LIMITED TIME ONLY*</b>
							</div> */}
              <button
                className="card-button greenButton"
                onClick={props.handleMyLearningBtnClick}
              >
                Add to My Learning
              </button>
            </>
          ) : (
            <>
              <center>
                <h2>Coming Soon</h2>
              </center>
              <button
                className="card-button blackButton"
                onClick={props.handleNotifyBtnClick}
              >
                <img src={NotifyIcon} style={{ height: "15px" }} /> Notify Me
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default OfferCardView;
