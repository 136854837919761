export const handleRangeOfDate_fn = (timePeriod, commentsData) => {
	let fromDate, toDate;
	const now = new Date();
	let x = generateDate(timePeriod)
	fromDate = x.fromDate
	toDate = x.toDate

	const sortedData = commentsData.data.filter((comment) => {
		const commentDate = new Date(comment.createdAt);
		return commentDate >= fromDate && commentDate <= toDate;
	});

	if (sortedData.length > 0) {
		let sortedComments = {};
		sortedComments.success = true;
		sortedComments.data = sortedData;

		return {
			success: true,
			sortedData: sortedComments,
		};
	} else {
		return {
			success: false,
			message: 'No comments for the selected date',
		};
	}

};


export const handleDateSorted_fn = (fromDate, toDate, commentsData) => {
	const fromDate_converted = new Date(fromDate);
	const toDate_converted = new Date(toDate);

	if (isNaN(fromDate_converted) || isNaN(toDate_converted)) {
		return {
			success: false,
			message: 'Invalid date format',
		};
	} else if (fromDate_converted > toDate_converted) {
		return {
			success: false,
			message: 'Invalid date range',
		};
	} else {
		const filteredData = commentsData.data.filter((comment) => {
			const commentDate = new Date(comment.createdAt);
			return commentDate >= fromDate_converted && commentDate <= toDate_converted;
		});

		const sortedData = filteredData.sort(
			(a, b) => new Date(b.date) - new Date(a.date)
		);

		if (sortedData.length > 0) {
			let sortedComments = {};
			sortedComments.success = true;
			sortedComments.data = sortedData;

			return {
				success: true,
				sortedData: sortedComments,
			};
		} else {
			return {
				success: false,
				message: 'No comments for the selected filter hence, show complete data',
			};
		}
	}
};


export const convertData = (inputDate) => {
	// Given date string
	const dateString = inputDate;

	// Convert to Date object
	const dateObj = new Date(dateString);

	// Get individual components
	const year = dateObj.getFullYear();
	const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
	const day = String(dateObj.getDate()).padStart(2, '0');

	let x = `${year}-${month}-${day}`
	return x;
}


export const generateDate = (timePeriod) => {
	let fromDate = ''
	let toDate = '';
	const now = new Date();

	if (timePeriod === 'today') {
		fromDate = new Date(
			now.getFullYear(),
			now.getMonth(),
			now.getDate(),
			0,
			0,
			0
		);
		toDate = new Date(
			now.getFullYear(),
			now.getMonth(),
			now.getDate(),
			23,
			59,
			59
		);
	}
	else if (timePeriod === 'thisMonth') {
		fromDate = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0);
		toDate = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59);
	} else if (timePeriod === 'thisWeek') {
		const firstDayOfWeek = now.getDate() - now.getDay();
		const lastDayOfWeek = firstDayOfWeek + 6;

		fromDate = new Date(
			now.getFullYear(),
			now.getMonth(),
			firstDayOfWeek,
			0,
			0,
			0
		);
		toDate = new Date(
			now.getFullYear(),
			now.getMonth(),
			lastDayOfWeek,
			23,
			59,
			59
		);
	} else if (timePeriod === 'thisYear') {
		fromDate = new Date(now.getFullYear(), 0, 1, 0, 0, 0);
		toDate = new Date(now.getFullYear(), 11, 31, 23, 59, 59);
	}

	return {
		fromDate: fromDate,
		toDate: toDate,
	}


}