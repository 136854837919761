import React, { useState, useEffect } from "react";
import ImageInputView from "./view";
import * as AwsImageGalleryServices from "@services/AWS/ImageGallery";

function ImageInput(props) {
  const [fileList, setFileList] = React.useState([]);

  const [isHovered, setIsHovered] = useState(false);

  const [imageSearch, setImageSearch] = useState("");
  const [originalAwsListOfImages, setOriginalAwsListOfImages] = useState([]);
  const [awsListOfImages, setAwsListOfImages] = useState([]);
  const [selectedAwsImgIndex, setSelectedAwsImgIndex] = useState([]);
  const [paginationStartIndex, setPaginationStartIndex] = useState(0);
  const [paginationEndIndex, setPaginationEndIndex] = useState(20);
  const defaultPageSize = 20;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getImagesFromAws();
  }, []);

  const getImagesFromAws = async () => {
    let folderName = props.folderName
    // let response = await AwsImageGalleryServices.getImagesFromAwsBucket(folderName, imageSearch);
    let response = await AwsImageGalleryServices.getImagesFromAwsBucket(
      folderName
    );

    if (response.success) {
      setOriginalAwsListOfImages(response.listOfImages);
      setAwsListOfImages(response.listOfImages);
      setIsLoading(false);
    }
  };
  const searchImageFromAwsImages = () => {
    setPaginationStartIndex(0);
    setPaginationEndIndex(20);
    setSelectedAwsImgIndex([]);
    setAwsListOfImages(
      originalAwsListOfImages.filter((str) =>
        str.toLowerCase().includes(imageSearch.toLowerCase())
      )
    );
  };

  const handleSearchChange = (event, action) => {
    if (action === "onChange") {
      let val = event.target.value;
      setImageSearch(val);
    } else if (action === "onKeyDown" && event.key === "Enter") {
      searchImageFromAwsImages();
    }
  };

  const handleAwsImageSelected = (imageIndex) => {
    setSelectedAwsImgIndex((prevArray) => {
      if (prevArray.includes(imageIndex)) {
        return prevArray.filter((element) => element !== imageIndex);
      } else {
        return [...prevArray, imageIndex];
      }
    });
  };

  const handlePagination = (action) => {
    if (action === "prev") {
      setPaginationStartIndex(paginationStartIndex - defaultPageSize);
      setPaginationEndIndex(paginationEndIndex - defaultPageSize);
    } else {
      setPaginationStartIndex(paginationStartIndex + defaultPageSize);
      setPaginationEndIndex(paginationEndIndex + defaultPageSize);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    if (event.dataTransfer?.types?.includes("Files") || fileList.length === 0) {
      setIsHovered(true);
    }
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsHovered(false);
  };

  const addToFileList = (imageList) => {
    const updatedFileList = [...fileList];
    Object.keys(imageList).forEach((key) => {
      updatedFileList.push(imageList[key]);
    });
    setFileList(updatedFileList);
  };

  const handleAdd = (event, indexList) => {
    if(indexList)
    {
      const imageArray = indexList.map((index) => `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${awsListOfImages[index]}`);
      props.handleAdd(event, imageArray);
    }else{
      props.handleAdd(event, fileList);
    }
  };

  const handleRemoveImage = (event, index) => {
    const updatedFileList = fileList.filter((file, i) => i !== index);
    setFileList(updatedFileList);
    event.stopPropagation();
  };

  return (
    <ImageInputView
      fileList={fileList}
      handleDragOver={handleDragOver}
      handleDragLeave={handleDragLeave}
      isHovered={isHovered}
      onFileSubmit={addToFileList}
      handleClose={props.handleClose}
      handleAdd={handleAdd}
      handleRemoveImage={handleRemoveImage}
      handleSearchChange={handleSearchChange}
      awsListOfImages={awsListOfImages}
      selectedAwsImgIndex={selectedAwsImgIndex}
      handleAwsImageSelected={handleAwsImageSelected}
      paginationStartIndex={paginationStartIndex}
      paginationEndIndex={paginationEndIndex}
      handlePagination={handlePagination}
      isLoading={isLoading}
    />
  );
}

export default ImageInput;
