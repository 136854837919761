export const getAllArticle = async (payload,authToken) => {
	try {
		const {pageNo, limit, isSorted, sortBy, searchText} =payload
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getAllArticle?pageNo=${pageNo}&limit=${limit}${searchText?'&searchText='+searchText: ''}${sortBy? '&sortBy='+sortBy: ''}${isSorted? '&isSorted='+isSorted: ''}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Courses : Get All Article', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getArticle = async (articleId) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getArticle`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				articleId: articleId,
			}),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Courses : Get Article ', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const addArticle = async (payload, authToken) => {
	try {
		let formData = new FormData();
		for (let key in payload) {
			if (key === 'sectionOneImage' || key === 'sectionTwoImage') {
				formData.append(key, payload[key]);
			} else {
				formData.append(key, JSON.stringify(payload[key]));
			}
		}

		return await fetch(`${process.env.REACT_APP_BASE_URL}/addArticle`, {
			method: 'POST',
			headers: {
				Authorization: authToken,
			},
			body: formData,
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Courses : Get Article ', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const deleteArticles = async (authToken, articleList) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/deleteArticles`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify({
				articleList: articleList,
			}),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Courses :Delete Articles ', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateArticle = async (payload, authToken) => {
	try {
		let formData = new FormData();
		for (let key in payload) {
			if (key === 'sectionOneImage' || key === 'sectionTwoImage') {
				formData.append(key, payload[key]);
			} else {
				formData.append(key, JSON.stringify(payload[key]));
			}
		}
		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateArticle`, {
			method: 'POST',
			headers: {
				Authorization: authToken,
			},
			body: formData,
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Courses : updateArticle()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
