import { memo, useEffect, useState, useMemo } from "react";

import VerifiedIcon from "@assets/form/complete.png";
import PendingIcon from "@assets/form/pending.png";
import InputIcon from "@assets/form/input.png";
import RejectedIcon from "@assets/form/rejected.png";
import InProgressIcon from "@assets/form/inprogress.png";

import "./style.css";

function FormFieldStatus(props) {
  const [text, setText] = useState("");
  const [icon, setIcon] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [textAndBorderColor, setTextAndBorderColor] = useState("");

  useMemo(() => {
    if (props.status === "verified") {
      setText("Verified");
      setIcon(VerifiedIcon);
      setBgColor("#f0faf0");
      setTextAndBorderColor("#139528");
    } else if (props.status === "completed") {
      setText("Completed");
      setIcon(VerifiedIcon);
      setBgColor("#f0faf0");
      setTextAndBorderColor("#139528");
    } else if (props.status === "pending") {
      setText("Pending");
      setIcon(PendingIcon);
      setBgColor("#ffffcc");
      setTextAndBorderColor("#F44336");
    } else if (props.status === "inprogress") {
      setText("In Progress");
      setIcon(InProgressIcon);
      setBgColor("#f0faf0");
      setTextAndBorderColor("#03A9F4");
    } else if (props.status === "rejected") {
      setText("Rejected");
      setIcon(RejectedIcon);
      setBgColor("#FFFFFF");
      setTextAndBorderColor("#D32F2F");
    } else if (props.status === "inputRequired") {
      setText("Input required");
      setIcon(InputIcon);
      setBgColor("#c2c2c2");
      setTextAndBorderColor("#626262");
    } else {
      setText("");
      setIcon("");
      setBgColor("");
      setTextAndBorderColor("");
    }
  }, [props.status]);

  return (
    <div
      className="formFieldStatus rounded px-2 py-1"
      style={{
        backgroundColor: bgColor,
        color: textAndBorderColor,
        borderColor: textAndBorderColor,
      }}
    >
      <img
        src={icon}
        style={{
          width: "13px",
          height: "13px",
        }}
      />
      <div>{text}</div>
    </div>
  );
}

export default memo(FormFieldStatus);
