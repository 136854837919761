import React from "react";

import "./style.css";

import SelectInput from "@components/SelectInput";
import PopUp from "@components/PopUp";
import SelectionPopUp from "@components/SelectionPopUp";

function ProductPageView(props) {
  return (
    <div className="d-flex gap-3">
      <div
        style={{ width: "700px", marginLeft: "25px" }}
        className="courseDetails mt-4 d-flex gap-3 flex-column"
      >
        <div className="d-flex gap-2 align-items-center">
          <input
            type="checkbox"
            checked={
              props.courseDetails?.bssCourseId != null &&
              props.courseDetails?.bssInstituteId !== null
            }
            onClick={(event) =>
              props.handleChange(event.target.checked, "bssFormCheck")
            }
          ></input>
          <h4 className="subHeading mb-0">
            Make BSS form for this course <span className="text-danger">*</span>
          </h4>
        </div>
        {props.courseDetails?.bssCourseId != null &&
          props.courseDetails?.bssInstituteId !== null && (
            <>
              <SelectInput
                title={"Select BSS Institute"}
                required={true}
                inputText={
                  props.allBSSInstitutes.some(
                    (item) => item._id === props.courseDetails.bssInstituteId
                  )
                    ? props.allBSSInstitutes.find(
                        (item) =>
                          item._id === props.courseDetails.bssInstituteId
                      ).name
                    : "Select BSS Institute"
                }
                onClick={(event) =>
                  props.handleOpenPopUp(event, "bssInstituteId")
                }
              />

              <SelectInput
                title={"Select BSS Course"}
                required={true}
                inputText={
                  props.allBSSCourses.some(
                    (item) => item._id === props.courseDetails.bssCourseId
                  )
                    ? props.allBSSCourses.find(
                        (item) => item._id === props.courseDetails.bssCourseId
                      ).title
                    : "Select BSS Course"
                }
                onClick={(event) => props.handleOpenPopUp(event, "bssCourseId")}
              />
            </>
          )}
      </div>

      <PopUp
        visible={props.isSelectBSSInstitutePopUpVisible}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <SelectionPopUp
          data={props.allBSSInstitutes}
          optionKey={"name"}
          title={"Select BSS Institute"}
          searchPlaceholder={""}
          isSearchEnabled={false}
          height={"250px"}
          width={"auto"}
          selectedItems={props.allBSSInstitutes.filter(
            (item) => item._id === props.courseDetails.bssInstituteId
          )}
          dataKey={"_id"}
          selectedItemsKey={"_id"}
          selectionType={"single"}
          handleCancel={props.handlePopUpClose}
          handleSave={(value) => props.handlePopUpSave(value, "bssInstituteId")}
        />
      </PopUp>

      <PopUp
        visible={props.isSelectBSSCoursePopUpVisible}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <SelectionPopUp
          data={props.allBSSCourses}
          optionKey={"title"}
          title={"Select BSS Course"}
          searchPlaceholder={""}
          isSearchEnabled={false}
          height={"250px"}
          width={"auto"}
          selectedItems={props.allBSSCourses.filter(
            (item) => item._id === props.courseDetails.bssCourseId
          )}
          dataKey={"_id"}
          selectedItemsKey={"_id"}
          selectionType={"single"}
          handleCancel={props.handlePopUpClose}
          handleSave={(value) => props.handlePopUpSave(value, "bssCourseId")}
        />
      </PopUp>
    </div>
  );
}

export default ProductPageView;
