import React, { useEffect, useState } from 'react';

import NewDegreePopUpView from './view';

function NewDegreePopUp(props) {
    const[title, setTitle] = useState(props.title);

	const handleChange=(value, key)=>{
	   	if(key === "title")
		{
			setTitle(value)
		}
	}

	const handleCancel=()=>{
		props.handleCancel()
	}
	const handleSave=()=>{
		props.handleSave({title: title})
	}
	
	return (
		<NewDegreePopUpView
		   props = {props}	
           title = {title}
		   handleChange={handleChange}
		   handleCancel ={handleCancel}
		   handleSave={handleSave}
			/>
	);
}

export default NewDegreePopUp;
