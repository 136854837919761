import React, { useState, useEffect, useRef } from 'react';
import PhoneNumberView from './view';

import ClipboardJS from 'clipboard';

var defaultCellStyle = null  
function PhoneNumber(props){  
    const [isAlertVisible, setIsAlertVisible] = useState(false)
	const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const cellRef = useRef(null);

    const [number, setNumber]=useState();
    const [countryCode, setCountryCode]=useState();

    useEffect(()=>{
        setNumber(props.number)
        setCountryCode(props.countryCode)
    },[props.countryCode, props.number])

    const handleCopyClick =()=>{
       var textToCopy = '' 
       if(props.countryCode === '+91')
       {
        textToCopy = props.number
       }
       else{
        textToCopy =  props.countryCode?.slice(1) + props.number
        }

       const clipboard = new ClipboardJS('.clipboard', {
        text: () => textToCopy,
        });

        clipboard.on('success', (e) => {
			setIsAlertVisible(true);
			setTimeout(() => {
				setIsAlertVisible(false);
			}, 1000);
			e.clearSelection();
			clipboard.destroy();
		});

		clipboard.on('error', (e) => {
			clipboard.destroy();
		});
    }
    // To close the popup container
	const handlePopUpOpen = (event) => {
        if(!props.userId)
        {
            return;
        }
		handlePopUpClose()
        
        var cell = event.target.parentElement.parentElement.parentElement;
        cellRef.current = cell;
        defaultCellStyle = cell.style;
        // cell.style.zIndex = '1';
        cell.style.overflow = 'visible';
        cell.style.width = 'fit-content';
        cell.style.position = 'relative';
        cell.style.backgroundColor = '#ffffff';
        cell.style.minWidth = '100%';
        cell.style.borderRight = '1px solid #e9e9e9';
        cell.backgroundColor ='red'

		setIsPopUpVisible(true)
		event.stopPropagation();
	};
	const handlePopUpClose = () => {
        if(defaultCellStyle && cellRef.current){
        cellRef.current.style=defaultCellStyle;
        }
		setIsPopUpVisible(false)
	};
	// To handleSave buttons on the popup
	const handlePopUpSave = async (data) => {
		handlePopUpClose();
        setNumber(data.number)
        setCountryCode(data.countryCode)
        props.onChange({'number': data.number, 'countryCode':data.countryCode})
	};


    return(
        <PhoneNumberView
           props ={props}
           number={number}
           countryCode={countryCode}
           isAlertVisible = {isAlertVisible}
           isPopUpVisible = {isPopUpVisible}
           handlePopUpOpen ={handlePopUpOpen}
           handlePopUpClose={handlePopUpClose}
           handlePopUpSave={handlePopUpSave}
           handleCopyClick ={handleCopyClick}

        />
    )
}

export default PhoneNumber;