import React from "react";

import "./style.css";
import moment from "moment";
import BasicTableGrid from "@components/BasicTableGrid";
import TextAndLinks from "@components/TextAndLinks";
import DateAndUser from "@components/DateAndUser";
import DynamicTabs from "@components/DynamicTabs";
import ButtonBars from "@components/ButtonBars";
import PhoneNumber from "@components/PhoneNumber";
import PopUp from "@components/PopUp";
import Search from "@components/Search";

import ChangeCoursePopUp from "@components/BSS/ChangeCoursePopUp";
import ChangeExamYearPopUp from "@components/BSS/ChangeExamYearPopUp";
import ChangeInstitutePopUp from "@components/BSS/ChangeInstitutePopUp";
import SetCourseDurationPopUp from "@components/BSS/SetCourseDurationPopUp";
import MoveToPopUp from "@components/Users/MoveToPopUp";

import ExpandIcon from "@assets/userDashboard/expand.png";
import SettingIcon from "@assets/userDashboard/setting.png";
import ColumnsIcon from "@assets/userDashboard/columns.png";
import FolderIcon from "@assets/userDashboard/folder.png";
import PlusIcon from "@assets/common/plusIcon.png";

import CalenderIcon from "@assets/common/calender.png";
import BlueCalenderIcon from "@assets/common/blueCalender.png";
import ChangeIcon from "@assets/common/change.png";
import BlueDropDownIcon from "@assets/common/blueDropDown.png";
import DropDownIcon from "@assets/common/dropDownArrowIcon.png";
import PDFIcon from "@assets/common/pdf.png";
import ImportIcon from "@assets/common/download.png";
import BSSForm from "../BSS Form";
import PopUpContainer from "@components/PopUpContainer";
import Tags from "@components/Tags";
import SelectionPopUp from "@components/SelectionPopUp";

function BSSView(props) {
  return (
    <div
      className="BSSPageMainContainer bg-white"
      ref={props.fullscreenElementRef}
    >
      <Search
        value={props.searchText}
        placeholder={"Search BSS Form"}
        onSearch={(event) => {
          props.handleSearch(event);
        }}
      ></Search>

      <h4 className="mb-3">BSS Form</h4>
      <DynamicTabs
        allTabs={props.folders}
        activeTab={props.activeTab}
        onTabClick={props.handleTabClick}
      />
      <div className="d-flex align-items-center w-100 justify-content-between">
        <ButtonBars
          buttons={[
            {
              type: "round",
              text: "+",
              onClick: (event) => {
                props.handlePopUpOpen(event, "createNew");
              },
            },
            {
              text: "Download Form(s)",
              iconStart: "",
              disabled: props.disableBulkActionBtn,
              onClick: (event) => {
                props.handlePdf("bulk");
              },
            },
            {
              text: "Course Name",
              iconStart: ChangeIcon,
              iconEnd: BlueDropDownIcon,
              disabled: props.disableBulkActionBtn,
              onClick: (event) => {
                props.handlePopUpOpen(event, "add/changeCourse");
              },
            },
            {
              text: "Change Exam Year",
              iconEnd: BlueDropDownIcon,
              disabled: props.disableBulkActionBtn,
              onClick: (event) => {
                props.handlePopUpOpen(event, "changeExamYear");
              },
            },
            {
              text: "Date of Form",
              iconStart: BlueCalenderIcon,
              disabled: props.disableBulkActionBtn,
              onClick: (event) => {
                props.handlePopUpOpen(event, "dateOfForm");
              },
            },
            {
              text: "Course Duration",
              iconEnd: BlueDropDownIcon,
              disabled: props.disableBulkActionBtn,
              onClick: (event) => {
                props.handlePopUpOpen(event, "courseDuration");
              },
            },
            {
              text: "Change Institute",
              iconEnd: BlueDropDownIcon,
              disabled: props.disableBulkActionBtn,
              onClick: (event) => {
                props.handlePopUpOpen(event, "changeInstitute");
              },
            },
            {
              text: "Move To",
              iconEnd: BlueDropDownIcon,
              disabled: props.disableBulkActionBtn,
              onClick: (event) => {
                props.handlePopUpOpen(event, "moveTo");
              },
            },
          ]}
        />
        <div className="d-flex gap-2 controlsRight me-3">
          <button onClick={props.toggleFullscreen}>
            <img src={ExpandIcon}></img>
            <br></br>
            Expand
          </button>
          <button onClick={(event) => props.handlePopUpOpen(event, "setting")}>
            <img src={SettingIcon}></img>
            <br></br>
            Setting
          </button>
          <button
            onClick={props.moveToArchive}
            disabled={props.disableBulkActionBtn}
          >
            <img src={FolderIcon}></img>
            <br></br>
            Archive
          </button>
          <button onClick={props.handleColumnBtnClick}>
            <img src={ColumnsIcon}></img>
            <br></br>
            Columns
          </button>
        </div>
      </div>

      <div
        ref={props.BSSFormRef}
        style={{ zIndex: "0", position: "static", display: "none" }}
      >
        {props.pdfData ? (
          <BSSForm data={props.pdfData} ref={props.BSSFormRef} />
        ) : (
          ""
        )}
      </div>

      <BasicTableGrid
        gridId="BSSMain"
        data={props.deferredData} // Data to be displayed
        fixedCols={2} // How many columns are fixed and not scrolling
        initialSize={[
          35, 187, 167, 65, 118, 122, 156, 59, 55, 133, 80, 189, 211, 275, 175,
          314, 337, 124, 161, 100, 150, 103, 177, 71, 183,
        ]}
        initialSorting={[{ id: "Date Added", desc: true }]}
        handleSelect={(selectedRows) => {
          props.handleCheckBoxes(selectedRows);
        }}
        length={20}
        onSortChange={props.handleSort}
        columnsOptionPopUpRef={props.columnsOptionPopUpRef}
        columns={[
          {
            header: "Name on marksheet",
            cell: (data) => {
              const info = data.row.original;
              return (
                <div className="d-flex gap-2 w-100 align-items-center">
                  {props.isThumbnailVisible ? (
                    <div
                      className="thumbnailContainer"
                      style={{
                        backgroundImage: `url(${info.passportPhoto})`,
                        height: "30px",
                        width: "30px",
                        borderRadius: "15px",
                      }}
                    ></div>
                  ) : null}
                  {info.fullName}
                </div>
              );
            },
          },
          {
            header: "Phone Number",
            cell: (data) => {
              var info = data.row.original;
              return (
                <PhoneNumber
                  userId={info.userId}
                  countryCode={info.countryCode}
                  number={info.contactNumber}
                  type="phone"
                  onChange={(contactInfo) => {
                    props.handleChange("phoneNumber", contactInfo, info.userId);
                  }}
                />
              );
            },
          },
          {
            header: "Form",
            cell: (data) => {
              var info = data.row.original;
              return (
                <img
                  src={PDFIcon}
                  onClick={(event) => props.handlePdf(info)}
                  style={{ height: "25px" }}
                />
              );
            },
          },
          {
            header: "Tags",
            cell: (data) => {
              const info = data.row.original;
              const tags = data.row.original.tags;
              const userManualTags = data.row.original.userManualTags;
              if (tags) {
                tags.month =
                  tags.month === "yes" || tags.month === moment(info.createdAt).format("MMM")
                    ? moment(info.createdAt).format("MMM")
                    : "no";
                tags.year =
                  tags.year === "yes" || tags.year === moment(info.createdAt).format("yyyy")
                    ? moment(info.createdAt).format("yyyy")
                    : "no";
                tags.status = tags.status === "yes" ? "Get status" : "no";

                return (
                  <Tags
                    userId={info.userId}
                    automaticTags={tags} // [{key:value}]
                    manualTags={userManualTags}
                    onChange={(manualTags) => {
                      props.handleChange("manualTags", manualTags, info.userId);
                    }}
                  />
                );
              }
            },
          },
          {
            header: "Course name",
            cell: (data) => {
              return data.row.original.bssCourseDetails?.title;
            },
          },
          {
            header: "Date Created",
            accessorFn: (row) => {},
            cell: (data) => {
              const info = data.row.original;
              return (
                <DateAndUser
                  date={info.createdAt}
                  image={info.createdUserDetails?.displayPhoto}
                  imageText={info.createdUserDetails?.displayName}
                />
              );
            },
          },
          {
            header: "From",
            cell: (data) => {
              return data.row.original.bssCourseDetails?.fromYear;
            },
          },
          {
            header: "To",
            cell: (data) => {
              return data.row.original.bssCourseDetails?.toYear;
            },
          },
          {
            header: "Course Duration",
            cell: (data) => {
              return (
                <div
                  className="d-flex w-100"
                  onClick={(event) => {
                    props.handlePopUpOpen(
                      event,
                      "courseDuration",
                      data.row.original.purchaseId
                    );
                  }}
                >
                  {data.row.original.bssCourseDetails?.duration}
                  <img
                    src={DropDownIcon}
                    style={{ height: "7px", marginLeft: "auto" }}
                  />
                </div>
              );
            },
          },
          {
            header: "Institute",
            cell: (data) => {
              return data.row.original.bssInstituteDetails?.name;
            },
          },
          {
            header: "Name of father/guardian",
            cell: (data) => {
              return data.row.original.guardianName;
            },
          },
          {
            header: "Permanent Address",
            cell: (data) => {
              var info = data.row.original;
              return (
                info.flatHouseNumber +
                ", " +
                info.buildingName +
                ", " +
                info.colonyName +
                ", " +
                info.areaName +
                ", " +
                info.landmark +
                ", " +
                info.city +
                ", " +
                info.state +
                ", " +
                info.country
              );
            },
          },
          {
            header: "Address for communication",
            cell: (data) => {
              var info = data.row.original;
              return (
                info.flatHouseNumber +
                ", " +
                info.buildingName +
                ", " +
                info.colonyName +
                ", " +
                info.areaName +
                ", " +
                info.landmark +
                ", " +
                info.city +
                ", " +
                info.state +
                ", " +
                info.country
              );
            },
          },
          {
            header: "Undergraduate degree",
            cell: (data) => {
              return (
                <div
                  className="d-flex w-100"
                  onClick={(event) => {
                    props.handlePopUpOpen(
                      event,
                      "underGraduateDegree",
                      data.row.original._id
                    );
                  }}
                >
                  {data.row.original.underGraduateDegree}
                  <img
                    src={DropDownIcon}
                    style={{ height: "7px", marginLeft: "auto" }}
                  />
                </div>
              );
            },
          },
          {
            header: "Name of Institute of Undergraduate Degree",
            cell: (data) => {
              return data.row.original.underGraduateInstituteName;
            },
          },
          {
            header: "Address of Institute of Undergraduate Degree",
            cell: (data) => {
              return data.row.original.underGraduateInstituteAddress;
            },
          },
          {
            header: "Year of passing",
            cell: (data) => {
              return data.row.original.passingYear;
            },
          },
          {
            header: "Registration number",
            cell: (data) => {
              return data.row.original.registrationNumber;
            },
          },
          {
            header: "Blood group",
            cell: (data) => {
              return data.row.original.bloodGroup;
            },
          },
          {
            header: "Place of signature",
            cell: (data) => {
              return data.row.original.placeOfSignature;
            },
          },
          {
            header: "Signature",
            cell: (data) => {
              return (
                <img src={data.row.original.signature} className="signature" />
              );
            },
          },
          {
            header: "Date of Birth",
            cell: (data) => {
              const info = data.row.original;
              return <DateAndUser date={info.dob} />;
            },
          },
          {
            header: "Gender",
            cell: (data) => {
              return data.row.original.gender;
            },
          },
          {
            header: "Date on form",
            cell: (data) => {
              const info = data.row.original;
              return <DateAndUser date={info.dateOfSignature} />;
            },
          },
        ]}
      />
      <PopUp
        visible={props.isPopUpVisible && props.popUp == "createNew"}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <div handleClose={props.handlePopUpClose} className="createUserDiv">
          {/* <div
						onClick={(event) => {
							// props.handleRedirectToProfilePage(event, 'none', 'none');
							props.handleChange()
						}}>
						<img src={PlusIcon} style={{ height: '15px' }}></img>
						<span>Create BSS Form</span>
					</div> */}
          <div
            onClick={() => {
              props.excelFileInputRef.current.click();
            }}
          >
            <img src={ImportIcon} style={{ height: "15px" }}></img>
            <span>Import form from Excel</span>
            <input
              type="file"
              accept=".xlsx, .xls"
              ref={props.excelFileInputRef}
              onChange={props.handleExcelInput}
              hidden
            ></input>
          </div>
        </div>
      </PopUp>
      <PopUp
        visible={props.isPopUpVisible && props.popUp == "add/changeCourse"}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <ChangeCoursePopUp
          allCourses={props.allCourses}
          handleCancel={props.handlePopUpClose}
          handleSave={(course) => {
            props.handlePopUpSave(course);
          }}
        />
      </PopUp>
      <PopUp
        visible={props.isPopUpVisible && props.popUp == "changeExamYear"}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <ChangeExamYearPopUp
          handleCancel={props.handlePopUpClose}
          handleSave={(course) => {
            props.handlePopUpSave(course);
          }}
        />
      </PopUp>
      <PopUp
        visible={props.isPopUpVisible && props.popUp === "dateOfForm"}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <PopUpContainer
          title={"Change date of form"}
          isSearchEnabled={false}
          searchPlaceholder={""}
          isButtonsEnabled={true}
          style={{ width: "250px", height: "155px" }}
          handleSearch={() => {}}
          handleCancel={props.handlePopUpClose}
          handleSave={() => props.handlePopUpSave(props.formDate)}
        >
          <div className="w-100">
            <input
              type="date"
              value={props.formDate}
              onChange={(event) => props.handleFormDate(event.target.value)}
              className="bssDateOfFormInput"
            />
          </div>
        </PopUpContainer>
      </PopUp>
      <PopUp
        visible={props.isPopUpVisible && props.popUp == "courseDuration"}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <SetCourseDurationPopUp
          handleCancel={props.handlePopUpClose}
          handleSave={(courseDuration) => {
            props.handlePopUpSave(courseDuration);
          }}
        />
      </PopUp>
      <PopUp
        visible={props.isPopUpVisible && props.popUp == "changeInstitute"}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <ChangeInstitutePopUp
          allInstitute={props.allInstitute}
          handleCancel={props.handlePopUpClose}
          handleSave={(institute) => {
            props.handlePopUpSave(institute);
          }}
        />
      </PopUp>
      <PopUp
        visible={props.isPopUpVisible && props.popUp == "setting"}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left - 100}
        handleOutSideClick={props.handlePopUpClose}
      >
        <div
          handleClose={props.handlePopUpClose}
          className="createUserDiv"
          style={{ width: "200px" }}
        >
          <div
            onClick={props.cancelBssForm}
            disabled={props.disableBulkActionBtn}
          >
            <span>Cancel form request</span>
          </div>
          <div
            onClick={(event) => props.handlePopUpOpen(event, "dateOfForm")}
            disabled={props.disableBulkActionBtn}
          >
            <span>Change date on form</span>
            <img
              src={CalenderIcon}
              style={{ height: "15px", marginLeft: "20px" }}
            ></img>
          </div>
          <div onClick={props.showThumbnails}>
            <span>Show/Hide thumbnails</span>
          </div>
          <div onClick={props.handleUndo}>
            <span>Delete last bulk action</span>
          </div>
        </div>
      </PopUp>
      <PopUp
        visible={props.isPopUpVisible && props.popUp === "moveTo"}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <MoveToPopUp
          handleCancel={props.handlePopUpClose}
          handleSave={props.handlePopUpSave}
          folders={props.folders.slice(1)}
          pageType={"bss"}
        />
      </PopUp>

      <PopUp
        visible={props.isPopUpVisible && props.popUp === "underGraduateDegree"}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <SelectionPopUp
          data={props.allUnderGraduateDegrees}
          optionKey={"title"}
          title={"Set Undergraduate Degree to"}
          searchPlaceholder={""}
          isSearchEnabled={true}
          height={"250px"}
          width={"auto"}
          selectedItems={[]}
          dataKey={"_id"}
          selectedItemsKey={"_id"}
          selectionType={"single"}
          handleCancel={props.handlePopUpClose}
          handleSave={(value) => props.handlePopUpSave(value, "bssCourseId")}
        />
      </PopUp>
    </div>
  );
}

export default BSSView;
