import {useParams} from 'react-router-dom';

import BSS from '@pages/BSS/Main';
import ErrorView from '@components/ErrorView';

function UserBSS() {
	
	const { id } = useParams();
	
	return (
	  <>
	   {id?<BSS userId={id}/> : <ErrorView text={'Please Select/Create User '} />}
	  </>
	);
}

export default UserBSS;
