export var excelSheetHeaders =
    ['fullName',
    'countryCode',
    'contactNumber',
    'bssCourseName',
    'fromYear',
    'toYear',
    'duration',
    'instituteName',
    'instituteApprovalCode',
    'instituteAddress',
    'instituteState',
    'instituteDistrict',
    'institutePinCode',
    'guardianName',
    'flatHouseNumber',
    'buildingName',
    'colonyName',
    'areaName',
    'landMark',
    'country',
    'pincode',
    'state',
    'city',
    'underGraduateDegree',
    'underGraduateInstituteName',
    'underGraduateInstituteAddress',
    'passingYear',
    'registrationNumber',
    'bloodGroup',
    'placeOfSignature',
    'signature',
    'dob',
    'gender',
    'dateOfSignature',
    'panCard',
    'passportPhoto',
    'marksheetOrDegreeCert',
    'batch',
    'otherDocs',
]