import GoBackArrow from "@assets/sidebarNavigation/goBackArrow.png";
import EditPencilIcon from "@assets/common/editPencilIcon.png";
import ModulesIcon from "@assets/common/coursePageTabsIcon.png";

export const newEmptySeries = {
  _id: "",
  title: "",
  displayName: "",
  InternalName: "",
  visibility: "",
  examId: "",
  seriesWatchedPercentage: 0,
  exemptedDays: 0,
  tags: {
    year: null,
    main: null,
    secondary: null,
    month: null,
    status: null,
  },
  eligibleExams: [],
  eligibleCertificates: [],
  allowToCart: false,
  cardTitle: "",
  cardDescription: "",
  cardPointers: {
    icon: "",
    pointer: "",
  },
  cardIcons: [],
  cardBackgroundColor: "#f9f9f9",
  squareThumbnailUrl: "{}",
  rectangleThumbnailUrl: "{}",
  coverTitle: "",
  coverDescription: null,
  coverPointers: {
    icon: "",
    pointer: "",
  },
  infoParagraph: null,
  certificateThumbnailUrl: null,
  roadmapType: null,
  previewVideoLink: null,
  faqsId: null,
  categories: [],
  lastModifiedBy: "",
  isLatest: false,
  reviews: [],
  createdAt: "",
  updatedAt: "",
  __v: 0,
  isPurchasedCourse: false,
};

export const navigationData = [
  {
    title: "Back to Content",
    route: "/content?tab=series",
    default: false,
    icon: GoBackArrow,
  },
  {
    title: "Details",
    component: "SeriesDetails",
    default: true,
    icon: EditPencilIcon,
  },
  {
    title: "Content",
    component: "SeriesContent",
    default: false,
    icon: ModulesIcon,
  },
];
