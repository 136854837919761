export const updateUserDetails = (value) => {
  return {
    type: "UPDATE_USER_DETAILS",
    payload: {
      userDetails: value,
    },
  };
};

export const resetUsersReducer = () => {
  return {
    type: "RESET_USERS_REDUCER",
  };
};

export const updateFilters = (value) => {
  return {
    type: "UPDATE_USER_FILTERS",
    payload: {
      filters: value,
    },
  };
};

export const updateSelectedPurchaseIds = (value) => {
  return {
    type: "UPDATE_SELECTED_PURCHASE_IDS",
    payload: {
      selectedPurchaseIds: value,
    },
  };
};
