import React from 'react';

import './style.css';
import TextInputV2 from '@components/TextInputV2';
import BulletSelector from '@components/BulletSelector';
import OfferCard from '@components/OfferCard';
//Imports for RichEditor
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import ColorPicker from '@assets/common/colorPicker.png'
import PlusIcon from '@assets/common/plusIconGrey.png';
import ImageInput from '@components/ImageInput';
import PopUp from '@components/PopUp';


//START : Vars for RichEditor
const toolbarOptions = [
    { header: [1, 2, 3, false] },
    { font: [] },
    // { 'size': ['small', false, 'large', 'huge'] },
    'bold',
    'italic',
    'underline',
    'strike',
    { script: 'sub' },
    { script: 'super' },
    { list: 'bullet' },
];

const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'script',
    'list',
    'bullet',
    'indent',
];
//END : Vars for RichEditor

function CardView(props) {
    return(
        <div className="d-flex gap-3">
        <div style={{width:'700px', marginLeft: '25px'}} className="courseDetails mt-4 d-flex gap-3 flex-column">
            <TextInputV2 id ={"cardTitle"} placeholder ={"Card Title"} required ={true}
               value={props.courseDetails.cardTitle}
               onChange={(event)=>props.handleChange(event, 'cardTitle')}
             />
            <div className="form-floating" >
                <textarea className="form-control" placeholder="" id="cardDesc" style={{height:'158px'}}   
                    value={props.courseDetails.cardDescription}
                    onChange={(event)=>props.handleChange(event, 'cardDescription')}
               ></textarea>
                <label htmlFor="cardDesc">Card Description <span>*</span></label>
            </div>
            {/* START : Card bullets pointers container */}
            <h4
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    marginTop: '16px',
                }}
                className='subHeading'>
                <span style={{ marginRight: '10px' }}>Card Pointers</span>
                <BulletSelector
                    icon={
                        props.courseDetails?.cardPointers
                            ? props.courseDetails?.cardPointers?.icon
                            : ''
                    }
                    onValueChange={(bulletIcon) =>
                        props.handleChange(bulletIcon, 'cardPointers')
                    }
                />
            </h4>

            {/* START : Code for RichEditor */}
            <ReactQuill
                ref={props.cardPointerEditorRef}
                value={
                    props.courseDetails.cardPointers
                        ? props.courseDetails.cardPointers?.pointer
                        : ''
                }
                onChange={props?.handleCardPointers}
                onKeyDown={props.handleEditorTextEdit}
                
                modules={{
                    toolbar: toolbarOptions,
                }}
                formats={formats}
                style={{ marginBottom: '20px' }}
            />
            {/* END : Code for RichEditor */}

            {/* START : Display Icon */}
            <div className="mt-4">
                <h4 className='subHeading'>
                    Display Icon<span className="text-danger">*</span>
                    <span
                        style={{
                            marginLeft: '10px',
                            fontSize: '10px',
                        }}>
                        Select maximum 4
                    </span></h4>
                <div className='displayIconContainer'> 
                    {props.displayIcons?.map((displayIcon, index)=>{
                        return <div className='displayIconInnerContainer' key={index}>
                            <label >
                                <img src={displayIcon.assetUrl} />
                                {displayIcon.title}
                            </label>
                            <input
                                type='checkbox'
                                checked={props.courseDetails?.cardIcons?.some(icon => icon.pointer === displayIcon.title)}
                                className='form-check-input'
                                onChange={(event) =>{props.handleDisplayIcon(event, displayIcon)}
                                }
                            />
                        </div>
                    })}
                </div>
                <button className="btn btn-dark btn-sm mt-2"
                 onClick={props.clearIcons}
                >Clear Icons</button>
            </div>

            <div className="mt-2">
                <h4 className='subHeading'>Radial background color<span className="text-danger">*</span></h4>
                <div className = 'colorPicSection  gap-2' ref= {props.colorPickerRef}>
                    <div className ="colorInfo greyBorder">
                        <div className=" greyBorder colorPicked" style={{backgroundColor : props.courseDetails.cardBackgroundColor}}></div>
                            {props.courseDetails.cardBackgroundColor}
                            <input type="color" value={props.courseDetails.cardBackgroundColor} style={{visibility:"hidden", with:'0px'}}
                            id='cardColorPicker'
                            onChange={(event)=>{ 
                                props.handleChange(event, 'cardBackgroundColor')
                            }}
                            ></input>
                    </div>
                    <div className= 'colorPicker greyBorder'>
                        <img src={ColorPicker}/>
                    </div>
                   
                </div>
            </div>

            <div className="mt-4">
                <h4 className='subHeading'>
                    Thumbnail<span className="text-danger">*</span>
                </h4>
                <div className="imageCarousel">
                    <div className="addCarouselImage" 
                         style={{backgroundColor : props.courseDetails.cardBackgroundColor,
                            backgroundImage: props.courseDetails?.squareThumbnailUrl?
                            typeof props.courseDetails.squareThumbnailUrl === 'string' ? 
                                             `url(${props.courseDetails.squareThumbnailUrl})`:
                                             `url(${URL.createObjectURL(props.courseDetails.squareThumbnailUrl)})`
                                             : `url(${PlusIcon})`, 
                            height:'81px', width: '81px',
                            backgroundSize: props.courseDetails?.squareThumbnailUrl? 'contain' : '',
                        }}
                        onClick={(event)=>props.handleChange(event, 'squareThumbnailUrl')}
                    ></div>
                    <div className="addCarouselImage" 
                         style={{backgroundColor : props.courseDetails.cardBackgroundColor,
                            backgroundImage: props.courseDetails?.rectangleThumbnailUrl?
                                            typeof props.courseDetails.rectangleThumbnailUrl === 'string' ? 
                                             `url(${props.courseDetails.rectangleThumbnailUrl})`:
                                             `url(${URL.createObjectURL(props.courseDetails.rectangleThumbnailUrl)})`
                                             : `url(${PlusIcon})`,
                            height:'81px', width: '143px',
                            backgroundSize: props.courseDetails?.rectangleThumbnailUrl? 'contain' : '',}}
                        
                        onClick={(event)=>props.handleChange(event, 'rectangleThumbnailUrl')}
                    >
                    </div>
        </div>
            </div>
        </div>

        <div className='d-flex justify-content-center p-4'>
            <OfferCard
                            color={props.courseDetails.cardBackgroundColor}
                            courseType={props.courseDetails?.courseType}
                            thumbnailUrl={
                                 props.courseDetails?.rectangleThumbnailUrl? 
                                 typeof props.courseDetails.rectangleThumbnailUrl === 'string' ? 
                                 props.courseDetails.rectangleThumbnailUrl:
                                 URL.createObjectURL(props.courseDetails.rectangleThumbnailUrl) : null
                                }
                            validityText={props.courseDetails?.validityText}
                            cardTitle={props.courseDetails?.cardTitle}
                            description={props.courseDetails?.cardDescription}
                            pointers={props.courseDetails?.cardPointers}
                            offerExpiry={props.courseDetails?.offerExpiry} //{props.courseDetails.validitydays}
                            price={
                                props.courseDetails?.prices
                                    ? props.courseDetails?.prices[0]?.offerPrice
                                    : ''
                            }
                            oldPrice={
                                props.courseDetails?.prices
                                    ? props.courseDetails?.prices[0]?.crossedPrice
                                    : ''
                            }
                            cardIcons={props.courseDetails?.cardIcons}
                        />

        </div>
            <PopUp
                visible={props.isPopUpVisible}
                top={props.popUpPosition.top}
                left={props.popUpPosition.left}
                handleOutSideClick={props.handlePopUpClose}>
                <div className="full-page-overlay">
                    <ImageInput
                    handleClose={props.handlePopUpClose}
                    handleAdd={(event, files)=>props.handleChange(files,'imageUpload')} 
                    />
                </div>
                    
            </PopUp>
        </div>
    )
}

export default CardView;