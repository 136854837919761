import React from 'react';

import './style.css';
import BasicTableGrid from '@components/BasicTableGrid';
import ButtonBars from '@components/ButtonBars';
import PopUp from '@components/PopUp';
import Search from '@components/Search';

import SelectionPopUp from '@components/SelectionPopUp';
import PopUpContainer from '@components/PopUpContainer';
import TextInputV2 from '@components/TextInputV2';

import ExpandIcon from '@assets/userDashboard/expand.png';
import ColumnsIcon from '@assets/userDashboard/columns.png';

import SettingIcon from '@assets/userDashboard/setting.png';

import ColorPicker from '@components/ColorPicker'

function CCLabelView(props){

    return(
        <div className='AdminPageMainContainer bg-white' ref={props.fullscreenElementRef}>
            <Search
				value={props.searchText}
				placeholder = {"Search CC labels"}
				onSearch={(event)=>{props.handleSearch(event)}}
				/>
            <div className='d-flex align-items-center w-100 justify-content-between'>
					<ButtonBars
						buttons={[{type: 'round',
                                    text: '+',
                                    onClick: (event) => {
                                        props.handlePopUpOpen(event, "createNew", null);
                                    },
                                },
						]}
					/>
					<div className='d-flex gap-2 controlsRight me-3'>
						<button onClick={props.toggleFullscreen}>
							<img src={ExpandIcon}></img>
							<br></br>
							Expand
						</button>
						{/* <button onClick={(event)=>props.handlePopUpOpen(event, "setting", null)}>
							<img src={SettingIcon}></img>
							<br></br>
							Settings
						</button> */}
						<button onClick={props.handleColumnBtnClick}>
							<img src={ColumnsIcon}></img>
							<br></br>
							Columns
						</button>
					</div>
            </div>

            <BasicTableGrid 
				gridId='CCLabels'
				data={props.deferredData} // Data to be displayed
				fixedCols={2} // How many columns are fixed and not scrolling
				initialSize={[35,200,217,191,941]}
				initialSorting={[{"id": "Date/Time","desc": true}]}
				handleSelect={(selectedRows) => {props.handleCheckBoxes(selectedRows);}}
				length={20}
				onSortChange={(data) => {}
                    // props.handleSort(data)
				}
				columnsOptionPopUpRef={props.columnsOptionPopUpRef}
				columns={[
					{header: 'Name', 
					cell : (data)=>{
						return (<div style={{cursor:'pointer'}}
									onDoubleClick={(event)=>{props.handlePopUpOpen(event, 'createNew', data.row.original)}}>
									{data.row.original.title}
									</div>)
					}},
					{header: 'Text color', cell : (data)=>{
						return <div className='d-flex gap-2 align-items-center'> 
								<div className='colorDiv' style={{backgroundColor: data.row.original.textColor}}></div> 
								{data.row.original.textColor}
								</div>
					
					}},
					{header: 'Background color', cell : (data)=>{
						return <div className='d-flex gap-2 align-items-center'> 
						<div className='colorDiv' style={{backgroundColor: data.row.original.backgroundColor}}></div> 
						{data.row.original.backgroundColor}
						</div>
					}},
					{header: 'Visibility', 
					cell : (data)=>{
					  return (<div style={{cursor:'pointer'}}
					  onDoubleClick={(event)=>{props.handlePopUpOpen(event, 'visibility', data.row.original)}}>
						  {data.row.original.visibility}
						  </div>)
				  }},
				]}
				/>

            <PopUp
				visible={props.isPopUpVisible && props.popUp=='createNew'}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left}
				handleOutSideClick={props.handlePopUpClose}>
				<PopUpContainer
				 isSearchEnabled={false}
				 style={{width:'327px', height:'266px'}}
				 isButtonsEnabled={true}
				 handleCancel ={props.handlePopUpClose}
				 handleSave={()=>{props.handlePopUpSave()}}>
					<div className='d-flex flex-column gap-2'>
						<TextInputV2
						height={'small'}
						placeholder={"Tag name"}
						value={props.tagName}
						required={true}
						onChange={(event)=>{props.setTagName(event.target.value)}}
						/>
						<div className='d-flex gap-2 w-100'>
							<ColorPicker id={'textColor'} 
								color={props.textColor}
								onChange={props.setTextColor}
								/>
							<div className='flex-grow-1'>
								<TextInputV2
									height={'small'}
									placeholder={"Text color HEX value"}
									value={props.textColor}
									required={true}
									onChange={(event)=>{props.setTextColor(event.target.value)}}
									/>
							</div>
						</div>
						<div className='d-flex gap-2 w-100'>
							<ColorPicker id={'bgColor'} 
								color={props.backgroundColor}
								onChange={props.setBackgroundColor}/>
							<div className='flex-grow-1'>
								<TextInputV2
									height={'small'}
									placeholder={"Background color HEX value"}
									value={props.backgroundColor}
									required={true}
									onChange={(event)=>{props.setBackgroundColor(event.target.value)}}
									/>
							</div>
						</div>
					</div>
				</PopUpContainer>
			</PopUp>
			<PopUp
				visible={props.isPopUpVisible && props.popUp=='visibility'}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left}
				handleOutSideClick={props.handlePopUpClose}>
				<SelectionPopUp 
				  data = {[{"_id":1, "visibility" : 'active'}, {"_id":2, "visibility" : 'disabled'}]}
				  optionKey= {"visibility"}
				  title={"Visibility"}
				  isSearchEnabled={false}
				  height= {"140px"}
				  width= {"200px"}
				  handleCancel ={props.handlePopUpClose}
				  handleSave={(visibility)=>{props.handlePopUpSave(visibility)}}/>
			</PopUp>
			<PopUp
				visible={props.isPopUpVisible && props.popUp=='setting'}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left-100}
				handleOutSideClick={props.handlePopUpClose}>
				<div>
                    <div className="settingOption" onClick={()=>props.handlePopUpSave()}>Delete</div>
				</div>
			</PopUp>
			
        </div>
    )
}

export default CCLabelView