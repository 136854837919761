import React from "react";

import './userHome.css';

function UserHomeView(){
    return(
        <>
            {/* <h1 style={{marginLeft:'240px'}}>UserHomeView</h1> */}
        </>
    )
}

export default UserHomeView;