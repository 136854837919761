import React, { useState, useEffect, useRef } from "react";
import {
  defer,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import AdminUsersView from "./view";
import * as UserServices from "@services/Users";
import * as FolderServices from "@services/Folders";
import Swal from "sweetalert2";
import { decryptText } from "@library/enc-dec";
import { getEventElementPosition, toggleFullscreen } from "@helpers/common.js";
import { navigationData } from "./data";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as AdminUsersActions from "@redux/actions/AdminUsers";
import { produce } from "immer";

var listOfCheckedRows = [];

function AdminUsers(props) {
  const fullscreenElementRef = useRef(null);
  const columnsOptionPopUpRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        columnsOptionPopUpRef.current &&
        !columnsOptionPopUpRef.current.contains(event.target)
      ) {
        columnsOptionPopUpRef.current.style.display = "none";
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const [deferredData, setDeferredData] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const limit = Number(queryParams.get("limit"));
  const page = Number(queryParams.get("page"));

  const [disableBulkActionBtn, setDisableBulkActionBtn] = useState(true);

  const [popUp, setPopUp] = useState("");
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({});
  const [allFolders, setAllFolders] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getAllFolders();
    setSearchParams({
      page: props.filters.page,
      limit: props.filters.limit,
    });
  }, []);

  useEffect(() => {
    getAllAdminUsers();
  }, [props.filters]);

  const getAllAdminUsers = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        pageNo: props.filters.page,
        limit: props.filters.limit,
        isSorted: props.filters.isSorted,
        sortBy: props.filters.sortBy,
        searchText: props.filters.searchText,
      };

      var deferredResponse = defer({
        res: UserServices.getAllAdmins(payload, authToken),
      });

      setDeferredData(deferredResponse);
      deferredResponse.data.res
        .then((response) => {
          // props.updateAllBSSForm(response);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: error.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    } catch (err) {
      console.log("Error coming while getting all BSS Forms", err);
    }
  };

  const getAllFolders = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        pageNo: "0",
        limit: "1000000",
        isSorted: "true",
        sortBy: "updatedAt",
        searchText: "",
        visibility: "active",
      };
      var response = await FolderServices.getAllFolders(payload, authToken);
      if (response.success) {
        setAllFolders(response.data);
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while getting all Folders", err);
    }
  };

  const handleSearch = (data) => {
    let updatedFilters = produce(props.filters, (draft) => {
      draft.page = 0;
      draft.searchText = data.text;
    });
    props.updateFilters(updatedFilters);
  };

  //Fn to check and uncheck all checkboxes
  const handleCheckBoxes = (selectedRows) => {
    listOfCheckedRows = selectedRows;
    setDisableBulkActionBtn(selectedRows.length > 0 ? false : true);
  };

  // To sort
  const handleSort = (data) => {
    const mapping = { "Acc Created": "createdAt" };
    var id = data[0]?.id ? mapping[data[0].id] : "";
    let updatedFilters = produce(props.filters, (draft) => {
      draft.sortBy = id;
      draft.isSorted = data[0]?.desc ? data[0].desc : false;
    });
    props.updateFilters(updatedFilters);
  };

  const handleColumnBtnClick = (event) => {
    handlePopUpClose();
    columnsOptionPopUpRef.current.style.display = "block";
    var position = getEventElementPosition(event);
    columnsOptionPopUpRef.current.style.top = position.top + "px";
    columnsOptionPopUpRef.current.style.left = position.left - 160 + "px";
    event.stopPropagation();
  };

  // To close the popup container
  const handlePopUpOpen = (event, popUpName, data) => {
    setSelectedRow(data);
    handlePopUpClose();
    setPopUp(popUpName);
    setIsPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };
  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
    columnsOptionPopUpRef.current.style.display = "none";
  };
  // To handleSave buttons on the popup
  const handlePopUpSave = async (data) => {
    if (popUp === "pageRights") {
      data = data.map((right) => right.name);
      updateUserRights(data);
    } else if (
      popUp === "userRights" ||
      popUp === "bssRights" ||
      popUp === "leadsRights" ||
      popUp === "customerCareRights"
    ) {
      data = data.map((right) => right._id);
      updateUserRights(data);
    } else if (popUp === "role") {
      updateUserRole(data[0].type);
    }
    handlePopUpClose();
    getAllAdminUsers();
  };

  // START : Code for navigation
  const navigate = useNavigate();

  const setDefaultNavigation = (defaultComponent) => {
    return navigationData.map((item) => {
      if (item.component === defaultComponent) {
        return { ...item, default: true };
      }
      return item;
    });
  };

  const handleRedirectToProfilePage = (
    event,
    userDocId,
    defaultComponent,
    userData
  ) => {
    //Handle setting default page while navigating
    let finalNavigationData = navigationData;
    if (defaultComponent !== "none") {
      finalNavigationData = setDefaultNavigation(defaultComponent);
    } else {
      // VAR x ONLY FOR TESTING
      // let x = 'VideoComments';
      // finalNavigationData = setDefaultNavigation(x);
      finalNavigationData = setDefaultNavigation("AdminUserProfile");
    }

    if (userDocId === "none") {
      navigate("/adminUser", { state: { finalNavigationData } });
    } else {
      navigate("/adminUser/" + userDocId, { state: { finalNavigationData } });
    }
  };

  const updateUserRights = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        userId: selectedRow._id,
        rightsType: popUp,
        rightsList: data,
      };

      var response = await UserServices.updateUserRights(payload, authToken);
      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while updating UserRights", err);
    }
  };
  const updateUserRole = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        userId: selectedRow._id,
        roleCode: "1",
        role: data,
      };
      var response = await UserServices.updateUserRole(payload, authToken);
      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while updating UserRights", err);
    }
  };

  return (
    <>
      <AdminUsersView
        deferredData={deferredData}
        disableBulkActionBtn={disableBulkActionBtn}
        handleSearch={handleSearch}
        searchText={props.filters.searchText}
        handleCheckBoxes={handleCheckBoxes}
        handleSort={handleSort}
        // Fullscreen
        fullscreenElementRef={fullscreenElementRef}
        toggleFullscreen={() => toggleFullscreen(fullscreenElementRef)}
        //ColumnButton and popup
        columnsOptionPopUpRef={columnsOptionPopUpRef}
        handleColumnBtnClick={handleColumnBtnClick}
        // Start: Control Pop Up
        popUp={popUp}
        popUpPosition={popUpPosition}
        isPopUpVisible={isPopUpVisible}
        handlePopUpClose={handlePopUpClose}
        handlePopUpOpen={handlePopUpOpen}
        handlePopUpSave={handlePopUpSave}
        allFolders={allFolders}
        selectedRow={selectedRow}
        handleRedirectToProfilePage={handleRedirectToProfilePage}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    filters: state.adminUser.filters,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateFilters: AdminUsersActions.updateFilters,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
