import React, { useEffect, useState } from "react";
import SidebarNavigationView from './view';

import { allComponents } from './listOfAllComponents';


function SidebarNavigation(props) {


  //START : Handling dynamic component change
  const [currentComponentName, setCurrentComponentName] = useState(null);
  
  useEffect(() => {
    const objWithDefaultTrue = props.navigationData.find(obj => obj.default === true);
    if (objWithDefaultTrue && objWithDefaultTrue.component) {
      changeComponent('', objWithDefaultTrue.component);
    }
  }, [props.navigationData]);


  const changeComponent = (event, componentName) => {
    if (allComponents[componentName]) {
      setCurrentComponentName(componentName);
    }
  };

  const CurrentComponent = currentComponentName
    ? allComponents[currentComponentName]
    : null;
  //END : Handling dynamic component change


  return (
    <>
      <SidebarNavigationView
        changeComponent={changeComponent}
        navigationData={props.navigationData}
      />

      {CurrentComponent && <CurrentComponent />}
    </>
  );
}



export default SidebarNavigation;