import React, { useEffect, useState } from 'react';
import PopUpContainer from '@components/PopUpContainer';
import './style.css';

import TextInputV2 from '@components/TextInputV2';

function NewFolderPopUpView(props) {

	return (
		<PopUpContainer
           title={"Create/Update Folder"}
           isSearchEnabled={false}
           searchPlaceholder={""}
           isButtonsEnabled={true}
           style={{width:'378px', height:'283px'}}
           handleSearch={props.handleSearch}
           handleCancel={props.handleCancel}
           handleSave={props.handleSave}
        >
            <div className='d-flex flex-column gap-1 w-100'>
                <TextInputV2  size={'small'}
                    placeholder={"Folder name"}
                    value={props.title}
                    required={true}
                    onChange={(event)=>{props.handleChange(event.target.value, 'title')}}
                />         
                <div>Folder page:</div>
                <div className='d-flex flex-column ps-2 pt-1'>       
                    <div><input type='radio' value={props.page} onChange={(event)=>{props.handleChange('user', 'page')}} checked={props.page === 'user'}/> User</div>
                    <div><input type='radio' value={props.page} onChange={(event)=>{props.handleChange('accounts', 'page')}} checked={props.page === 'accounts'}/> Accounts</div>
                    <div><input type='radio' value={props.page} onChange={(event)=>{props.handleChange('bss', 'page')}} checked={props.page === 'bss'}/> BSS</div>
                    {/* <div><input type='radio' value={props.page} onChange={(event)=>{props.handleChange('customerCare', 'page')}} checked={props.page === 'customerCare'}/> Customer care</div> */}
                    <div><input type='radio' value={props.page} onChange={(event)=>{props.handleChange('leads', 'page')}} checked={props.page === 'leads'}/> Leads</div>
                    {/* <div><input type='radio' value={props.page} onChange={(event)=>{props.handleChange('certificate', 'page')}} checked={props.page === 'certificate'}/> Certificates</div> */}
                </div>
            </div>
        </PopUpContainer>
	)
}

export default NewFolderPopUpView;
