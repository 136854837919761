import { useState, useEffect, useRef } from 'react';
import { useNavigate, defer, useLocation} from 'react-router-dom';
import MailContentView from './view';
import { navigationData } from './data';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {getEventElementPosition, toggleFullscreen} from '@helpers/common.js';

import { decryptText } from '@library/enc-dec';
import * as EmailTemplateServices from '@services/EmailTemplate';
import * as ArticleActions from '@redux/actions/Articles';
import Swal from 'sweetalert2';

import { produce } from 'immer';
var listOfCheckedRows=[]

function MailContent(props) {
	const fullscreenElementRef = useRef(null);
	const columnsOptionPopUpRef = useRef(null);
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const limit = Number(queryParams.get('limit'));
	const page = Number(queryParams.get('page'));
	const [deferredData, setDeferredData] = useState({});
	const [isSorted, setIsSorted] = useState(true);
	const [sortBy, setSortBy] = useState('createdAt');

	const [isBulkSelected, setIsBulkSelected] = useState(false);
	
	const [alertVisible, setAlertVisible] = useState(false);
	const [allEmailTemplates, setAllEmailTemplates] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [isPopUpVisible, setIsPopUpVisible] = useState(false);
	const [popUpPosition, setPopUpPosition] = useState({ top: 0, left: 0 });
	const [clickedRow, setClickedRow] = useState({});

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				columnsOptionPopUpRef.current &&
				!columnsOptionPopUpRef.current.contains(event.target)
			) {
				columnsOptionPopUpRef.current.style.display = 'none';
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if(limit>0)
		{
			getAllEmailTemplates();
	}
	}, [page, limit, isSorted, sortBy]);

	// Get All Courses to be shown in the drop-down
	const getAllEmailTemplates = async () => {
		try {
        	let authToken = decryptText(localStorage.getItem('aEmediat'));

			const payload ={
				pageNo: page, 
				limit: limit, 
				sortBy: sortBy, 
				isSorted: isSorted, 
				searchText: searchText}

			var deferredResponse = defer({res: EmailTemplateServices.getAllEmailTemplates(payload, authToken)});

			setDeferredData(deferredResponse);

			deferredResponse.data.res.then(response => {
				setAllEmailTemplates(response);
			})
			.catch(error => {
				alert(error.message);
			});

		} catch (err) {
			console.log('Error coming while fetching all articles', err);
		}
	};


	const handleLinksClick = (index, row) => {
		if(index==0)
		{
			handleRedirectToHome(row._id)
		}
		
	};
	const handleRedirectToHome = ( docId) => {
		//Handle setting default page while navigating
		let finalNavigationData = [];
		finalNavigationData = navigationData;

		if (docId === 'none') {
			navigate('/DynamicMail', { state: { finalNavigationData } });
			props.resetArticle();
		} else {
			navigate(docId? '/DynamicMail/' + docId : '/DynamicMail/', { state: { finalNavigationData } });
			props.resetArticle();
		}
	};

	const handleSort = (data) => {
		const mapping = {'Date created': 'createdAt'}
		var id = data[0]?.id ? mapping[data[0].id] : ''
		setSortBy(id)
		setIsSorted(data[0]?.desc ? data[0].desc : false)
	};

	//Fn to check and uncheck all checkboxes
	const handleCheckBoxes = (selectedRows) => {
		listOfCheckedRows = selectedRows;
		setIsBulkSelected( selectedRows.length>0 ? false : true)
	};

	const deleteEmailTemplate = async () => {
		try {
			
  			let authToken = decryptText(localStorage.getItem('aEmediat'));
			let response = await EmailTemplateServices.deleteEmailTemplate(
				listOfCheckedRows.map(row=>row._id), authToken
			);
			if (response.success) {
				alert('Email Template Deleted!');
				setAllEmailTemplates([]);
				getAllEmailTemplates();
			}
		} catch (err) {
			console.log('Error in deleting the articles.', err);
		}
	};

    const handleColumnBtnClick = (event) => {
		// handlePopUpClose();
		columnsOptionPopUpRef.current.style.display = 'block';
		var position = getEventElementPosition(event);
		columnsOptionPopUpRef.current.style.top = position.top + 'px';
		columnsOptionPopUpRef.current.style.left = position.left - 160 + 'px';
		event.stopPropagation();
	};

	const handleSearch = (event) => {
		setSearchText(event.target.value);
		if (event.code === 'Enter' || event.key === 'Enter') {
			getAllEmailTemplates();
		}
	};
	const handleSearchInput = (event) => {
		setSearchText(event.target.value);
		if(event.target.value === ''){
			getAllEmailTemplates();
		}
	};
	const handlePopUpClose = () => {
		setIsPopUpVisible(false);
	};
	// Open popUps
	const handleOpenPopUp = (event, data) => {
		handlePopUpClose();
        let position = getEventElementPosition(event);
		setIsPopUpVisible(true)
		setPopUpPosition(position);
		event.stopPropagation();
		setClickedRow(data)
    }

	const handlePopUpSave = (data) => {
		handlePopUpClose();
		getAllEmailTemplates();
	};

	return (
		<>
			<MailContentView
			    deferredData={deferredData}
				allEmailTemplates={allEmailTemplates}
				handleSort={handleSort}
				isBulkSelected={isBulkSelected}
				handleCheckBoxes={(id, type) => handleCheckBoxes(id, type)}
				handleDeleteBulk={() => deleteEmailTemplate()}
				handleRedirectToHome={handleRedirectToHome}
				alertVisible={alertVisible}

				
				// Fullscreen
				fullscreenElementRef={fullscreenElementRef}
				toggleFullscreen={()=>toggleFullscreen(fullscreenElementRef)}
				//ColumnButton and popup
				columnsOptionPopUpRef={columnsOptionPopUpRef}
				handleColumnBtnClick={handleColumnBtnClick}
				handleLinksClick={handleLinksClick}

				handleSearch={handleSearch}
				handleSearchInput={handleSearchInput}

				isPopUpVisible={isPopUpVisible}
				popUpPosition={popUpPosition}
				handlePopUpClose={handlePopUpClose}
				handleOpenPopUp={handleOpenPopUp}
				handlePopUpSave={handlePopUpSave}
				clickedRow={clickedRow}
			/>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		allEmailTemplates: state.articles.allEmailTemplates,
		articlesSet: state.accounts.ordersSet,
		numOfArticles: state.accounts.numOfArticles,
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateAllArticles: ArticleActions.updateAllArticles,
			updateTotalNumOfArticles: ArticleActions.updateTotalNumOfArticles,
			updateArticlesSet: ArticleActions.updateArticlesSet,
			resetArticle: ArticleActions.resetArticle,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(MailContent);
