import React from "react";

import './offerHome.css';

function OfferHomeView(){
    return(
        <>
            {/* <h1 style={{marginLeft:'240px'}}>OfferHomeView</h1> */}
        </>
    )
}

export default OfferHomeView;