import React, { useEffect, useState } from 'react';

import PopUpContainer from '@components/PopUpContainer';

import ChangeExamYearPopUpView from './view';

function ChangeExamYearPopUp(props) {
    const[from, setFrom] = useState('');
    const[to, setTo] = useState('');

	const handleFrom=(value)=>{
		if(value.length<5){
			setFrom(value)
	}}

	const handleTo=(value)=>{
		if(value.length<5){
			setTo(value)
	}}

	const handleCancel=()=>{
		props.handleCancel()
	}
	const handleSave=()=>{
		props.handleSave({from: from, to: to})
	}
	
	return (
		<ChangeExamYearPopUpView
		   props = {props}	
		   from = {from}	
		   handleFrom = {handleFrom}
		   to={to}
		   handleTo={handleTo}
		   handleCancel ={handleCancel}
		   handleSave={handleSave}
			/>
	);
}

export default ChangeExamYearPopUp;
