import React from 'react';

import './style.css';

function TextInputView(props) {
    return(
        <div className={props.props.size==='small'? "form-floating halfSizeInput":"form-floating" }>
        <input type="text" className="form-control" id={props.props.id} placeholder={props.props.placeholder} required={props.props.required}
           value={props.props.value ? props.props.value: ''}
           onChange={props.props.onChange}
        />
        <label htmlFor={props.props.id}>
              {props.props.placeholder} 
              {props.props.required ? <span className="text-danger"> *</span> : null}
        </label>
        </div>
    )
}

export default TextInputView;