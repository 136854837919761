import React from "react";
import moment from "moment";

import PlusIcon from "@assets/common/plusIcon.png";
import PDFICon from "@assets/common/pdf.png";
import DropDownArrowIcon from "@assets/common/dropDownArrowIcon.png";
import CourseCardPlaceHolder from "@assets/common/courseCardPlaceHolder.png";
import PopUp from "@components/PopUp";
import ResultStatusPopUp from "@components/Users/ResultStatusPopUp";
import EyeIcon from "@assets/common/eye2.png";
import ExamResult from "@components/ExamResult";
import ReactModal from "react-modal";

import "./style.css";

function PurchasesView(props) {
  return (
    <>
      <div
        className="accordion userPurchasesMainContainer"
        id="purchaseAccordion"
      >
        {props.userPurchasesData && props.userPurchasesData.success && (
          <>
            {props.userPurchasesData.data.map((data, index) => (
              <>
                <div
                  key={index}
                  className="accordion-item userPurchasesAccContainer"
                >
                  <div
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={"#purchase_" + index}
                  >
                    <table className="table table-bordered outerTable">
                      <tbody>
                        <tr
                          style={{
                            backgroundColor: index % 2 === 1 ? "#F8F9FA" : "", // Apply background color for odd index
                          }}
                        >
                          <th>
                            <p className="headerP">Purchase Date</p>
                            <p className="valueP">
                              {moment(data.purchaseDate).format(
                                "DD MMMM, YYYY"
                              )}
                            </p>
                          </th>
                          <th>
                            <p className="headerP">Courses</p>
                            <p className="valueP">{data.coursesMnemonic}</p>
                          </th>
                          <th>
                            <p className="headerP">Amt. Paid</p>
                            <p className="valueP">{data.amtPaid}</p>
                          </th>
                          <th>
                            <p className="headerP">Transaction ID</p>
                            <p className="valueP">{data.transactionId}</p>
                          </th>
                          <th>
                            <p className="headerP">Region</p>
                            <p className="valueP">{data.region}</p>
                          </th>
                          <th>
                            <p className="headerP">Payment Gateway</p>
                            <p className="valueP">{data.paymentGateway}</p>
                          </th>
                          <th>
                            <p className="headerP">Invoice</p>
                            <img
                              style={{ width: "40px" }}
                              src={PDFICon}
                              alt="Download Invoice"
                              onClick={() => props.handleDownloadInvoice(data)}
                            />
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div
                    id={"purchase_" + index}
                    className="accordion-collapse collapse userPurchasesACollapseContainer"
                    data-bs-parent="#purchaseAccordion"
                  >
                    <div className="accordion-body">
                      <table className="table table-bordered innerTable">
                        <thead>
                          <tr>
                            <th>
                              <div className="d-flex gap-3">
                                <input
                                  type="checkbox"
                                  onClick={() => {
                                    props.handleOrderClick(data, index);
                                  }}
                                  checked={data.courseDetails.every((course) =>
                                    props.selectedPurchaseIds.includes(
                                      course._id
                                    )
                                  )}
                                ></input>
                                <span>Title</span>
                              </div>
                            </th>
                            {/* <th className="text-center">Status</th> */}
                            <th className="text-center">Exam Status</th>
                            <th className="text-center">Exam Result</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.courseDetails &&
                            data.courseDetails.length > 0 &&
                            data.courseDetails.map((courseData, index) => (
                              <>
                                <tr key={index}>
                                  <th className="col-3">
                                    <div className="col-4 d-flex gap-3">
                                      <input
                                        type="checkbox"
                                        onClick={() => {
                                          props.handleCourseClick(
                                            courseData,
                                            index
                                          );
                                        }}
                                        checked={props.selectedPurchaseIds?.includes(
                                          courseData._id
                                        )}
                                      ></input>
                                      <img
                                        src={
                                          courseData.thumbnailUrl !== ""
                                            ? courseData.thumbnailUrl
                                            : CourseCardPlaceHolder
                                        }
                                        className="courseCardPlaceHolder"
                                        alt="course"
                                      />
                                      <span
                                        className="col-8"
                                        style={{ marginLeft: "10px" }}
                                      >
                                        {courseData.courseName}
                                      </span>
                                    </div>
                                  </th>
                                  {/* <th className="col-3 statusTh">
                                    {props.setCourseStatusOnUI(
                                      courseData.status,
                                      courseData.remainingDays
                                    )}
                                    <img
                                      onClick={(event) => {
                                        props.openCourseDurationStatusPopup(
                                          event,
                                          data._id,
                                          courseData._id
                                        );
                                      }}
                                      className="dropDownArrowIcon"
                                      src={DropDownArrowIcon}
                                      alt="DropDownArrowIcon"
                                    />
                                  </th> */}
                                  <th className="col-3 examStatusTh">
                                    <span
                                      className={`${
                                        courseData.examStatus === "Passed"
                                          ? "passed"
                                          : ""
                                      }`}
                                    >
                                      {courseData.examStatus}
                                    </span>
                                    <img
                                      onClick={(event) => {
                                        props.openExamStatusPopup(
                                          event,
                                          data._id,
                                          courseData._id
                                        );
                                      }}
                                      className="dropDownArrowIcon"
                                      src={DropDownArrowIcon}
                                      alt="DropDownArrowIcon"
                                    />
                                  </th>

                                  <th className="col-3 examStatusTh">
                                    <span
                                      className={`${
                                        courseData.examStatus === "Passed"
                                          ? "passed"
                                          : ""
                                      }`}
                                    >
                                      {courseData.result
                                        ? `${courseData.percentage}% - ${
                                            courseData.result
                                              .charAt(0)
                                              .toUpperCase() +
                                            courseData.result.slice(1)
                                          }`
                                        : "Not available"}
                                    </span>
                                    {courseData.result && (
                                      <img
                                        onClick={(event) => {
                                          props.handlePopUpOpen(
                                            event,
                                            "examResult",
                                            courseData
                                          );
                                        }}
                                        className="dropDownArrowIcon"
                                        src={EyeIcon}
                                        alt="Result Eye Icon"
                                      />
                                    )}
                                  </th>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </>
        )}

        {/* Start : course duration PopupContainer */}
        {props.is_CourseDurationPopupContainerRef_Visible && (
          <>
            <div
              ref={props.courseDurationPopupContainerRef}
              style={{
                position: "absolute",
                width: "380px",
                top: props.courseDurationPopupContainerRef_Position.top,
                left: props.courseDurationPopupContainerRef_Position.left,
              }}
              className="visibilityPopupContainer"
            >
              <div className="visibilityPopupContainer_inner">
                {props.courseDurationError &&
                  props.courseDurationError !== "" && (
                    <>
                      <div className="alert alert-danger">
                        {props.courseDurationError}
                      </div>
                    </>
                  )}

                <div className="form-check ">
                  <input
                    onChange={(event) => {
                      props.handleCourseDurationRadioBtns(event, "In Progress");
                    }}
                    checked={
                      props.newCourseDuration === "In Progress" ? true : false
                    }
                    className="form-check-input"
                    type="radio"
                    name="courseDurationRadioBtn"
                    id="onGoingRadioBtn"
                  />

                  <div style={{ marginRight: "9px" }} className="d-inline">
                    <input
                      style={{ width: "70px" }}
                      // value={props.courseDurationInDays}
                      type="number"
                      onChange={(event) => {
                        props.handleCourseDurationInDays(event);
                      }}
                      className="inputPercentage"
                    />
                  </div>

                  <label className="form-check-label" htmlFor="onGoingRadioBtn">
                    days
                  </label>
                </div>

                <div className="form-check">
                  <input
                    onChange={(event) => {
                      props.handleCourseDurationRadioBtns(event, "lifetime");
                    }}
                    checked={
                      props.newCourseDuration === "lifetime" ? true : false
                    }
                    className="form-check-input"
                    type="radio"
                    name="courseDurationRadioBtn"
                    id="lifetimeRadioBtn"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="lifetimeRadioBtn"
                  >
                    Lifetime
                  </label>
                </div>

                <div className="form-check">
                  <input
                    onChange={(event) => {
                      props.handleCourseDurationRadioBtns(event, "Disabled");
                    }}
                    checked={
                      props.newCourseDuration === "Disabled" ? true : false
                    }
                    className="form-check-input"
                    type="radio"
                    name="courseDurationRadioBtn"
                    id="disabledRadioBtn"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="disabledRadioBtn"
                  >
                    Disabled
                  </label>
                </div>
              </div>

              <div style={{ float: "right", marginTop: "10px" }}>
                <button
                  onClick={(event) => {
                    props.handleCourseDurationPopupCancleBtnClick(event);
                  }}
                  className="cancleBtn"
                >
                  Cancle
                </button>

                <button
                  disabled={props.disableCourseDurationSaveBtn}
                  style={{
                    cursor: props.disableCourseDurationSaveBtn
                      ? "not-allowed"
                      : "pointer",
                  }}
                  id="courseDurationSaveBtn"
                  onClick={props.handlePurchaseStatus}
                  className="saveBtn"
                >
                  Save
                </button>
              </div>
            </div>
          </>
        )}
        {/* END : course duration PopupContainer */}

        {/* Start : exam status PopupContainer */}
        {props.is_ExamStatusPopupContainerRef_Visible && (
          <>
            <div
              ref={props.examStatusPopupContainerRef}
              style={{
                position: "absolute",
                width: "380px",
                top: props.examStatusPopupContainerRef_Position.top,
                left: props.examStatusPopupContainerRef_Position.left,
              }}
              className="visibilityPopupContainer"
            >
              <div className="visibilityPopupContainer_inner">
                {props.examStatusError && props.examStatusError !== "" && (
                  <>
                    <div className="alert alert-danger">
                      {props.examStatusError}
                    </div>
                  </>
                )}

                <div className="form-check ">
                  <input
                    onChange={(event) => {
                      props.handleExamStatusRadioBtns(event, "Passed");
                    }}
                    checked={
                      props.newExamStatus === "Passed" ||
                      props.newExamStatus === "viewResult" ||
                      props.newExamStatus === "hideResult"
                        ? true
                        : false
                    }
                    className="form-check-input"
                    type="radio"
                    name="examStatusRadioBtn"
                    id="passedRadioBtn"
                  />

                  <label className="form-check-label" htmlFor="passedRadioBtn">
                    Passed
                  </label>

                  <div className="inputPercentageContainer">
                    <input
                      value={props.inputPercentage}
                      type="number"
                      onChange={(event) => {
                        props.handleInputPercentage(event);
                      }}
                      className="inputPercentage"
                    />
                    <span className="inputPercentagSign">%</span>
                  </div>

                  <div className="form-check showResultsContainer">
                    <input
                      onChange={(event) => {
                        props.handleShowResultCheckBox(event);
                      }}
                      className="form-check-input"
                      type="checkbox"
                      checked={props.showResultCheckBox}
                      id="showResultCheckBox"
                      value=""
                    />
                    <label
                      className="form-check-label"
                      htmlFor="showResultCheckBox"
                    >
                      Show reults
                    </label>
                  </div>
                </div>

                <div className="form-check">
                  <input
                    onChange={(event) => {
                      props.handleExamStatusRadioBtns(event, "Failed");
                    }}
                    checked={props.newExamStatus === "Failed" ? true : false}
                    className="form-check-input"
                    type="radio"
                    name="examStatusRadioBtn"
                    id="failedRadioBtn"
                  />
                  <label className="form-check-label" htmlFor="failedRadioBtn">
                    Failed
                  </label>
                </div>

                <div className="form-check">
                  <input
                    onChange={(event) => {
                      props.handleExamStatusRadioBtns(
                        event,
                        "Videos yet to be completed"
                      );
                    }}
                    checked={
                      props.newExamStatus === "locked" ||
                      props.newExamStatus === "Videos yet to be completed"
                        ? true
                        : false
                    }
                    className="form-check-input"
                    type="radio"
                    name="examStatusRadioBtn"
                    id="videosYetToBeCompletedRadioBtn"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="videosYetToBeCompletedRadioBtn"
                  >
                    Videos yet to be completed
                  </label>
                </div>

                <div className="form-check">
                  <input
                    onChange={(event) => {
                      props.handleExamStatusRadioBtns(event, "Unlocked");
                    }}
                    checked={
                      props.newExamStatus === "unlocked" ||
                      props.newExamStatus === "Unlocked"
                        ? true
                        : false
                    }
                    className="form-check-input"
                    type="radio"
                    name="examStatusRadioBtn"
                    id="notAppearedRadioBtn"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="notAppearedRadioBtn"
                  >
                    Unlock
                  </label>
                </div>

                <div className="form-check">
                  <input
                    onChange={(event) => {
                      props.handleExamStatusRadioBtns(event, "No Exam");
                    }}
                    checked={props.newExamStatus === "No Exam" ? true : false}
                    className="form-check-input"
                    type="radio"
                    name="examStatusRadioBtn"
                    id="noExamRadioBtn"
                  />
                  <label className="form-check-label" htmlFor="noExamRadioBtn">
                    No exam
                  </label>
                </div>
              </div>

              <div style={{ float: "right", marginTop: "10px" }}>
                <button
                  onClick={(event) => {
                    props.handleExamStatusPopupCancleBtnClick(event);
                  }}
                  className="cancleBtn"
                >
                  Cancle
                </button>

                <button
                  disabled={props.disableExamStatusSaveBtn}
                  style={{
                    cursor: props.disableExamStatusSaveBtn
                      ? "not-allowed"
                      : "pointer",
                  }}
                  id="saveVisibilityBtn"
                  onClick={props.handleExamStatus}
                  className="saveBtn"
                >
                  Save
                </button>
              </div>
            </div>
          </>
        )}

        {props.popUp === "examResult" && (
          <ReactModal
            isOpen={props.isPopUpVisible}
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              zIndex: 100,
              overlay: { zIndex: 1000 },
            }}
            onRequestClose={props.handlePopUpClose}
            ref={props.resultModalRef}
          >
            <div>
              <button
                onClick={props.handlePopUpClose}
                style={{ float: "right" }}
              >
                X
              </button>
              <ExamResult
                purchaseId={props.purchaseId}
                resultId={props.resultId}
              ></ExamResult>
            </div>
          </ReactModal>
        )}

        {/* End : exam status PopupContainer */}
      </div>
    </>
  );
}

export default PurchasesView;
