import React, { useEffect, useState,useRef } from 'react';
import VerificationView from './view';
import SidebarNavigation from '@components/SidebarNavigation';
import {toggleFullscreen} from '@helpers/common.js';

import { checkLoginAndRole } from '@helpers/Login/';

function Verification() {
	const [tabSelected, setTabSelected] = useState(1);
	const fullscreenElementRef = useRef(null);	

	useEffect(() => {
		checkLoginAndRole('verification')
	}, []);

	const handleTabSelect = (eventKey) => {

		if (eventKey === 'idProof') {
			setTabSelected(1);
		} else if (eventKey === 'doctorProof') {
			setTabSelected(2);
		} else if (eventKey === 'photoProof') {
			setTabSelected(3);
		}else if (eventKey === 'bssProof') {
			setTabSelected(4);
		}
	};

	return (
		<>
			<SidebarNavigation />
			<VerificationView
				tabSelected={tabSelected}
				handleTabSelect={handleTabSelect}
				fullscreenElementRef={fullscreenElementRef}
				toggleFullscreen={()=>toggleFullscreen(fullscreenElementRef)}
			/>
		</>
	);
}

export default Verification;
