import React from 'react';

import DetailedTicketInformationView from './view';

function DetailedTicketInformation(props) {	
	return (
		<>
			<DetailedTicketInformationView 
				{...props}
				/>
		</>
	);
}

export default DetailedTicketInformation;
