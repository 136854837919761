import React, { useEffect, useState } from 'react';

import PopUpContainer from '@components/PopUpContainer';

import ChangeCoursePopUpView from './view';

function ChangeCoursePopUp(props) {
	const [selectedCourse, setSelectedCourse] = useState(null)
	const [searchValue, setSearchValue] = useState('')
	
	const handleCancel=()=>{
		props.handleCancel()
	}
	const handleSave=()=>{
		props.handleSave(selectedCourse)
	}

	const handleSearch=(value)=>{
      setSearchValue(value)
	}

	const handleCourseSelect=(course)=>{
		setSelectedCourse(course)
	}
	
	return (
		<ChangeCoursePopUpView
		   selectedCourse={selectedCourse}
		   allCourses = {props.allCourses}
		   searchValue={searchValue}	
		   handleCourseSelect={handleCourseSelect}
		   handleSearch={handleSearch}
		   handleCancel ={handleCancel}
		   handleSave={handleSave}
			/>
	);
}

export default ChangeCoursePopUp;
