import React from 'react';

import BasicInfoView from './view';

function BasicInfo(props) {
	return (
		<>
			<BasicInfoView 
				{...props}
				/>
		</>
	);
}

export default BasicInfo;
