export const getAllCertificates = async (payload, authToken) => {
    try {
		const url = `${process.env.REACT_APP_BASE_URL}/getAllCertificates`
		return await fetch(url,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
				body: JSON.stringify(payload),
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Certificates : Get all certificates', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateCertificatesStatus = async (payload, authToken) => {
    try {
        return await fetch(`${process.env.REACT_APP_BASE_URL}/updateCertificatesStatus/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authToken,
            },
            body: JSON.stringify(payload),
        })
            .then((res) => res.json())
            .catch((error) => error.message);
    } catch (error) {
        console.error('Coming from Certificates : updateQueriesStatus()', error.message);
        if (error.message === 'Network Error') {
            return error.message;
        }
    }
};

export const getCertificateFoldersCount = async (authToken) => {
	try {
	    const url = `${process.env.REACT_APP_BASE_URL}/getCertificateFoldersCount`
	
		return await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from EXam : Get all getCertificateFoldersCount', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};