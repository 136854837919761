import React, {forwardRef} from 'react';

import PopUp from '@components/PopUp';
import PopUpContainer from '@components/PopUpContainer';

const NoteView= forwardRef((props, ref)=>{
    return(
        <div className='w-100 h-100' style={{cursor:"pointer"}} onDoubleClick={props.handlePopUpOpen}>
            {props.text}

            <PopUp
                visible={props.isPopUpVisible}
                top={30}
                left={0}
                handleOutSideClick={props.handlePopUpClose}>
                <div className='hideImage'>
                <PopUpContainer
                    title={"Enter/edit user notes"}
                    isSearchEnabled={false}
                    searchPlaceholder={""}
                    isButtonsEnabled={true}
                    style={{width:'268px', height:'fit-content'}}
                    handleSearch={props.handleSearch}
                    handleCancel={props.handlePopUpClose}
                    handleSave={props.handlePopUpSave}>
                    <textarea 
                        className='notesTextArea'
                        ref={ref} 
                               value={props.popUpText} 
                              onChange={(event)=>props.setPopUpText(event.target.value)}/>
                </PopUpContainer>
                </div>  
             </PopUp>
        </div>        
    )
});

export default NoteView