import React, { useEffect, useState } from 'react';
import PopUpContainer from '@components/PopUpContainer';
import './style.css';
import CalenderIcon from '@assets/common/calender.png';

function ChangeExamYearPopUpView(props) {

	return (
		<PopUpContainer
           title={"Change Examination Year"}
           isSearchEnabled={false}
           searchPlaceholder={""}
           isButtonsEnabled={true}
           style={{width:'378px', height:'153px'}}
           handleSearch={props.handleSearch}
           handleCancel={props.handleCancel}
           handleSave={props.handleSave}
        >
            <div className='d-flex gap-2 justify-content-center align-items-center flex-grow-1'>
                <div className='filterSelect'>
                    <div className="dateInput w-100">
                        <div className="dateInputTitle bg-white" style={{fontSize: '13px'}}>From</div>
                        <div className='d-flex justify-content-center align-items-center gap-1 w-100' style={{marginTop:'2px'}}>
                            <img src={CalenderIcon} style={{width: '13px'}}/>
                            <input type="number" style={{border:'none', width:'140px'}} value={props.from} 
                            onChange={(event)=>props.handleFrom(event.target.value)}
                            ></input>
                        </div>
                    </div>
                </div>
                
                <div className='filterSelect'>
                <div className="dateInput w-100">
                    <div className="dateInputTitle bg-white" style={{fontSize: '13px'}}>To</div>
                    <div className='d-flex justify-content-center align-items-center gap-1 w-100' style={{marginTop:'2px'}}>
                        <img src={CalenderIcon} style={{width: '13px'}}/>
                        <input type="number" style={{border:'none', width:'140px'}}  
                        value={props.to} onChange={(event)=>props.handleTo(event.target.value)}></input>
                    </div>
                </div>
                </div>
            </div>
        </PopUpContainer>
	)
}

export default ChangeExamYearPopUpView;
