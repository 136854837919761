import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import InstallmentsView from './view';

import * as CoursesServices from '@services/Course';
import { decryptText } from '@library/enc-dec';
import { produce } from 'immer';
import { toDaysHoursAndMinutes } from '@helpers/courses';
import ErrorView from '@components/ErrorView';

function Installments(props) {
	const dataFetchedRef = useRef(null);
	const { id } = useParams();
    const [pricingData, setPricingData] = useState(props.pricingData)

	const [formValuesEmpty, setFormValuesEmpty] = useState(false);
	
	useEffect(()=>{
		setPricingData(props.pricingData)
	 },[props.pricingData])

	 useEffect(()=>{
		props.handlePricingData(pricingData)
	 },[pricingData])

	// Reviews
	const handleAddPaymentOption = (event, index) => {
		var updatedPricingData = {...pricingData}
		  const paymentOption = {
			  _id: `Payment Option ${updatedPricingData.installments.length + 1}`,
			  options: [0,0],
			  isPublic: false
		  };
		if(index!=null)
		{
			updatedPricingData.installments.splice(index+1, 0, paymentOption);
		}
		else{
			updatedPricingData.installments = [...updatedPricingData.installments, paymentOption]
		}
  
		setPricingData(updatedPricingData);
	  };
  
	  const handleDeletePaymentOption = (paymentOptionId) => {
		var updatedPricingData = {...pricingData}
  
		updatedPricingData.installments = updatedPricingData.installments.filter(
			  (_installment) => _installment._id.toString() !== paymentOptionId.toString()
		  );
		  setPricingData(updatedPricingData);
	  };

  
	 const onPaymentOptionDragEnd = (result) => {
		  if (!result.destination) return;
		const updatedPricingData = {...pricingData};
		const [reorderedItem] = updatedPricingData.installments.splice(result.source.index, 1);
		updatedPricingData.installments.splice(result.destination.index, 0, reorderedItem);
		setPricingData(updatedPricingData);
	  };
  
	//  const handleReviewUpdate=(field, value, index)=>{
	// 	var updatedPricingData = {...pricingData}
	// 	updatedPricingData.installments[index][field] =value
	// 	setPricingData(updatedPricingData);
	//  }
	//  const handleSelectedReviewUpdate=(field, value)=>{
	// 	var updatedReview = {...selectedReview}
	// 	updatedReview.review[field] =value
	// 	setSelectedReview(updatedReview)
	//  }

	const handlePaymentVisibility=(isChecked, index)=>{
		var updatedPricingData = {...pricingData};
        updatedPricingData.installments[index].isPublic=isChecked
		setPricingData(updatedPricingData);
	}

	
	const handleAddInstallmentOption = (event, index) => {
		var updatedPricingData = {...pricingData}
		  const paymentOption = {
			  _id: `Payment Option ${updatedPricingData.installments.length + 1}`,
			  options: [0,0],
			  isPublic: false
		  };
		if(index!=null)
		{
			updatedPricingData.installments.splice(index+1, 0, paymentOption);
		}
		else{
			updatedPricingData.installments = [...updatedPricingData.installments, paymentOption]
		}
  
		setPricingData(updatedPricingData);
	  };

	const handleInstallmentChange =(value, index, key)=>{
		var updatedPricingData = {...pricingData}
        updatedPricingData.installments[index].options[key]=value
		setPricingData(updatedPricingData);
	};

	const handleAddInstallment=(event, index, optionIndex)=>{
		var updatedPricingData = {...pricingData}
               // Ensure the installments[index] exists and has options
		if (updatedPricingData.installments[index] && updatedPricingData.installments[index].options) {
			// Insert the new value at the specified optionIndex
			updatedPricingData.installments[index].options.splice(optionIndex+1, 0, 0);
			
			// Update the state with the modified pricingData
			setPricingData(updatedPricingData);
		}

		setPricingData(updatedPricingData);     
	}

	const handleDeleteInstallment = (event, index, optionIndex) => {
		var updatedPricingData = {...pricingData}
  
		updatedPricingData.installments[index].options.splice(optionIndex, 1);
		
		if(updatedPricingData.installments[index].options.length ==0)
		{
			updatedPricingData.installments.splice(index, 1);
		}

		setPricingData(updatedPricingData);
	  };

	const handleApplyInstallments=(event)=>{
		var updatedPricingData = {...pricingData}
        updatedPricingData.installmentsApplied= event.target.checked
		setPricingData(updatedPricingData);
	}

	return (
		<>
			{id ? (
				<InstallmentsView
					pricingData={pricingData}					
					formValuesEmpty={formValuesEmpty}

					handleApplyInstallments={handleApplyInstallments}
					handleAddPaymentOption={handleAddPaymentOption}
					handleDeletePaymentOption={handleDeletePaymentOption}
					handlePaymentVisibility={handlePaymentVisibility}
					onPaymentOptionDragEnd={onPaymentOptionDragEnd}

					handleInstallmentChange ={handleInstallmentChange }
					handleAddInstallmentOption={handleAddInstallmentOption}
					handleAddInstallment={handleAddInstallment}
					handleDeleteInstallment={handleDeleteInstallment}

				/>
			) : (
				<ErrorView text={'Please Select or Add a course before adding installments.'} />
			)}
		</>
	);
}

export default Installments;
