const initialState = {
	email: '',
	password: '',
	access: null
	
};

const LoginReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_EMAIL':
			return {
				...state,
				email: action.payload.email,
			};
		case 'UPDATE_PASSWORD':
			return {
				...state,
				password: action.payload.password,
			};
		case 'UPDATE_ACCESS':
			return {
				...state,
				access: action.payload.access,
			};
		
		case 'RESET_LOGIN_REDUCER':
			return initialState;

		default:
			return state;
	}
};

export default LoginReducer;
