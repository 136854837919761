import React, { useState, useEffect } from "react";
import PopUpContainer from "@components/PopUpContainer";
import { callSource } from "./data1";
import { decryptText } from "@library/enc-dec";
import * as InterestsServices from "@services/Interests";

import * as LeadsServices from "@services/Leads";
import moment from "moment";
import Swal from "sweetalert2";

const LeadCallNotesPopUp = (props) => {
  const [callStatus, setCallStatus] = useState("");
  const [source, setSource] = useState("");
  const [interest, setInterest] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [summary, setSummary] = useState("");
  const [allInterests, setAllInterests] = useState([]);

  useEffect(() => {
    getAllInterests();
  }, []);

  const getAllInterests = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        pageNo: 0,
        limit: 999999,
        isSorted: "true",
        sortBy: "createdAt",
        searchText: "",
        visibility: "active",
      };

      var response = await InterestsServices.getAllInterests(
        payload,
        authToken
      );
      if (response.success) {
        setAllInterests(response.data);
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while getting all Interests", err);
    }
  };

  const handleChange = (event, type) => {
    if (type === "callStatus") {
      setCallStatus(event.target.value);
    } else if (type === "source") {
      setSource(event.target.value);
    } else if (type === "interest") {
      setInterest(event.target.value);
    } else if (type === "summary") {
      setSummary(event.target.value);
    }
  };

  const handleDate = (event) => {
    setDate(event.target.value);
  };
  const handleTime = (event) => {
    setTime(event.target.value);
  };

  const handleSave = async () => {
    if ((callStatus === "connected" && !summary) || callStatus === "") {
      Swal.fire({
        icon: "error",
        title: "Please enter the required fields",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const payload = {
      leadId: props.leadId,
      followUpAt: formatDateTime(date, time),
      source: source,
      interests: interest ? [interest] : [],
      callStatus: callStatus, // connected => didNotConnected
      callSummary: summary,
    };

    const response = await LeadsServices.addCallNotes(payload, authToken);
    if (response.success) {
      Swal.fire({
        icon: "success",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
      props.handleSave();
    } else {
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  function formatDateTime(date, time) {
    const dateTimeString = date + " " + time;
    const momentDateTime = moment(dateTimeString);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formattedString = momentDateTime.tz(timeZone).toISOString();
    return formattedString;
  }

  return (
    <PopUpContainer
      title={"Call notes"}
      isSearchEnabled={false}
      style={{ width: "480px", height: "366px" }}
      isButtonsEnabled={true}
      handleCancel={props.handleCancel}
      handleSave={handleSave}
    >
      <div className="d-flex flex-column gap-2 w-100">
        <select
          value={callStatus}
          onChange={(e) => handleChange(e, "callStatus")}
          className="w-100 select-new-drop-icon"
        >
          <option value="" default>
            Call status
          </option>
          <option value="didNotConnected">Call did not connect</option>
          <option value="connected">Call connected</option>
        </select>
        <select
          value={source}
          onChange={(e) => handleChange(e, "source")}
          className="w-100 select-new-drop-icon"
        >
          <option value="" default>
            Source
          </option>
          {callSource?.map((source) => (
            <option value={source}>{source}</option>
          ))}
        </select>
        <select
          value={interest}
          onChange={(e) => handleChange(e, "interest")}
          className="w-100 select-new-drop-icon"
        >
          <option value="">Add Interest(s)</option>
          {allInterests?.map((interest, index) => {
            return (
              <option key={index} value={interest._id}>
                {interest.title}
              </option>
            );
          })}
        </select>
        <div>
          Next follow-up date-time
          <div className="d-flex gap-2">
            <input
              type="date"
              className="date-input w-100"
              value={date}
              onChange={handleDate}
            />
            <input
              type="time"
              className="time-input w-100"
              value={time}
              onChange={handleTime}
            />
          </div>
        </div>
        <div>
          <div>
            Summary{" "}
            {callStatus === "connected" ? (
              <span className="text-danger">*</span>
            ) : null}
          </div>
          <textarea
            className="w-100"
            style={{ border: "1px solid #ddd" }}
            placeholder="Summarize the call discussion and file it for future reference and record-keeping."
            value={summary}
            onChange={(e) => handleChange(e, "summary")}
          ></textarea>
        </div>
        <div className="hr"></div>
      </div>
    </PopUpContainer>
  );
};

export default LeadCallNotesPopUp;
