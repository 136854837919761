import React from 'react';

import SortIcon from '@assets/common/sort.png';
import ProfileImgPlaceholder from '@assets/comments/profileImgPlaceholder.png';
// import UnFillHeartIcon from '@assets/comments/unFillHeartIcon.png';
import ShareIcon from '@assets/comments/shareIcon.png';
import ReplySection from '@components/ReplySection';

import numeral from 'numeral';
import moment from 'moment';

import './videoComments.css';

function VideoCommentsView(props) {
	return (
		<>
			<div
				className='rightHandRightPageMainContainer'
				style={{ maxWidth: '100%' }}>
				<div className='marginLeftContainer'>
					<h4 className='heading'>Comments</h4>

					{props.noCommentsFound && (
						<p
							className='alert-danger'
							style={{ padding: '5px 10px', width: 'fit-content' }}>
							No comments found
						</p>
					)}
				</div>

				<hr />

				<div className='videoPage_CommentsPage_FilterContainer'>
					<img
						onClick={(event) => {
							props.sort(event);
						}}
						className='sortIcon'
						src={SortIcon}
						alt='SortIcon'
					/>

					{props.sortContainerVisiable && (
						<div ref={props.sortContainerRef} className='sortContainer'>
							<span
								onClick={(event) => {
									props.handleSortingData(event, 'newestFirst');
								}}>
								Newest First
							</span>
							<br />
							<span
								onClick={(event) => {
									props.handleSortingData(event, 'top');
								}}>
								Top
							</span>
						</div>
					)}

					<button
						id='publishedFilter'
						onClick={(event) => {
							props.handleFilter(event, 'published');
						}}
						className={`filterBtns ${props.selectedFilter === 'published' ? 'selectedFilter' : ''
							}`}>
						Published
					</button>

					<button
						id='holdForReviewFilter'
						onClick={(event) => {
							props.handleFilter(event, 'heldForReview');
						}}
						className={`filterBtns ${props.selectedFilter === 'heldForReview' ? 'selectedFilter' : ''
							}`}>
						Held for review
					</button>

					<button
						id='rejectedFilter'
						onClick={(event) => {
							props.handleFilter(event, 'rejected');
						}}
						className={`filterBtns ${props.selectedFilter === 'rejected' ? 'selectedFilter' : ''
							}`}>
						Rejected
					</button>
				</div>

				<hr style={{ marginBottom: '0px' }} />

				{props.commentsData &&
					props.commentsData.success &&
					props.commentsData.data &&
					props.commentsData.data.map((commentData, commentIndex) => (
						<>
							<div className='allCommentsContainer'>
								{/* <div className='row' style={{ margin: '15px 0px 10px 0px' }}> */}
								<div className='row' style={{ padding: '20px' }}>
									<div className='col-profileImgPlaceholder'>
										<img
											src={
												commentData.displayPhoto
													? commentData.displayPhoto
													: ProfileImgPlaceholder
											}
											alt='Profile Placeholder'
											style={{ width: '30px' }}
										/>
									</div>

									<div className='col-commentContainer' style={{ paddingLeft: '10px' }}>
										<p className='commenterName'>
											{commentData.username}
											<span className='commentTime'>
												{moment(commentData.createdAt).fromNow()}
											</span>
										</p>
										<p className='comment'>
											<div dangerouslySetInnerHTML={{ __html: commentData.comment }} />
										</p>

										<div className='commentInteractionContainer'>
											<button
												className='commentReplyBtn'
												data-reply_container_id={commentData._id}
												data-has_reply_container={'has_' + commentData._id}
												onClick={(event) =>
													props.handleCommentReply(event, commentData._id, 'replyOnComment')
												}>
												Reply
											</button>

											{commentData.replies.length > 0 && (
												<button
													className='accordion-button collapsed'
													type='button'
													data-bs-toggle='collapse'
													data-bs-target={'#commentRepliesCollapse_' + commentData._id}>
													{commentData.replies.length}{' '}
													{commentData.replies.length > 1 ? 'replies' : 'reply'}
												</button>
											)}

											<p
												className='commentLikeContainer'
												onClick={() => props.handleLikeComment(commentData)}>
												{commentData.isLiked ? (
													<i className='fa-solid fa-thumbs-up'></i>
												) : (
													<i className='fa-regular fa-thumbs-up'></i>
												)}
												<span>{numeral(commentData.likesCount).format('0.0a')}</span>
											</p>

											<p
												className='commentDislikeContainer'
												onClick={() => props.handleDislikeComment(commentData)}>
												{commentData.isDisliked ? (
													<i className='fa-solid fa-thumbs-down fa-flip-horizontal'></i>
												) : (
													<i className='fa-regular fa-thumbs-down fa-flip-horizontal'></i>
												)}
											</p>

											{/* <img
												className='favIcon'
												src={UnFillHeartIcon}
												alt='UnFillHeartIcon'
											/> */}

											<img className='shareIcon' src={ShareIcon} alt='ShareIcon' />
										</div>

										<ReplySection
											currentCommentId={commentData._id}
											commentRepliesData={commentData.replies}
											handleReplyStatus={props.handleReplyStatus}
											handleReplyLike={(reply) =>
												props.handleReplyLike(reply, commentData.videoDocId)
											}
											handleReplyDislike={(reply) =>
												props.handleReplyDislike(reply, commentData.videoDocId)
											}
											handleCommentReply={props.handleCommentReply}
										/>
									</div>

									<div className='col-commentActionContainer'>
										{(commentData.status === 'published' ||
											commentData.status === 'rejected') && (
												<button
													onClick={(event) => {
														props.commentAction('heldForReview', commentData._id);
													}}
													className='commentActionBtn'>
													Held for review
												</button>
											)}

										{commentData.status === 'heldForReview' && (
											<button
												onClick={(event) => {
													props.commentAction('published', commentData._id);
												}}
												className='commentActionBtn'>
												Approve
											</button>
										)}

										{(commentData.status === 'heldForReview' ||
											commentData.status === 'published') && (
												<button
													onClick={(event) => {
														props.commentAction('rejected', commentData._id);
													}}
													className='commentActionBtn'
													style={{ marginLeft: '15px' }}>
													Reject
												</button>
											)}
									</div>
								</div>
							</div>
						</>
					))}
			</div>
		</>
	);
}

export default VideoCommentsView;
