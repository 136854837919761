import React from "react";

import "./style.css";
import VideoPlayHolder from "@assets/common/videoPlayHolder.png";
import BlackCrossIcon from "@assets/common/blackCrossIcon.png";
import SaveBtn from "@components/SaveBtn";

function AddTestPopUpView(props) {
  return (
    <div className="addTestMain">
      <span>Add Test</span>
      <div className="d-flex gap-1 testSelector">
        <select
          onChange={(event) => {
            props.handleTestChange(event);
          }}
          className="form-select"
        >
          <option value="">Select test</option>
          {props.allTests &&
            props.allTests.map((test, index) => (
              <>
                <option data-testid={test._id} value={test._id} key={test._id}>
                  {test.title}
                </option>
              </>
            ))}
        </select>

        <button
          className="roundBtn blackBtn"
          style={{ marginLeft: "auto" }}
          onClick={(event) => {
            props.handleAddNewTest(event);
          }}
        >
          +
        </button>
      </div>

      {props.error && props.error !== "" && (
        <div className="alert alert-danger w-50">{props.error}</div>
      )}

      <div className="hr" />

      {props.selectedTestsList &&
        props.selectedTestsList.length > 0 &&
        props.selectedTestsList.map((test, index) => (
          <>
            <div className="newTestContainer g-2" key={index}>
              <img
                className="testThumbnail"
                src={test.thumbnailUrl ? test.thumbnailUrl : VideoPlayHolder}
                alt="test"
              />
              <span className="testName">{test.title}</span>
              <img
                onClick={(event) => {
                  props.handleDeleteTest(event, test._id);
                }}
                className="blackCrossIcon"
                src={BlackCrossIcon}
                alt="BlackCrossIcon"
              />
            </div>
          </>
        ))}

      <div className="d-flex justify-content-end gap-1">
        <button onClick={props.handleCloseBtn} className="closeBtn">
          Cancel
        </button>
        {/* <button onClick={props.handleSaveBtn} className="saveBtn">
          Save
        </button> */}
        <SaveBtn
            onClick={props.handleSaveBtn}
            text={"Save"}
            altText={"Saving..."}
            disabled={props.isFormSaving}
            isPopUpBtn={true}
          />
      </div>
    </div>
  );
}

export default AddTestPopUpView;
