import { useEffect, useState, useRef } from "react";
import { useLocation, defer, useParams } from "react-router-dom";

import InnerSidebarNavigation from "@components/InnerSidebarNavigation";
import QueriesDetailsView from "./view";

import { decryptText } from "@library/enc-dec";
import { checkLoginAndRole } from "@helpers/Login/";
import { getEventElementPosition, toggleFullscreen } from "@helpers/common.js";

import * as QueriesServices from "@services/Queries";
import * as UserServices from "@services/Users";

import GoBackArrow from "@assets/sidebarNavigation/goBackArrow.png";
import EditPencilIcon from "@assets/common/editPencilIcon.png";

import Swal from "sweetalert2";

function QueriesDetails() {
  const location = useLocation();
  const { id } = useParams();

  // START : popup stats
  const [popUp, setPopUp] = useState("");
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({});
  // END : popup stats

  // START : states for default button bars
  // const fullscreenElementRef = useRef(null);
  // END : states for default button bars

  // START : states for basic table grid
  // END : states for basic table grid

  const [searchText, setSearchText] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [queryDetails, setQueryDetails] = useState({});
  const [ticketActivityData, setTicketActivityData] = useState([]);
  const [userTagList, setUserTagList] = useState([]);

  const [comment, setComment] = useState("");
  const [taggedUsers, setTaggedUser] = useState([]);
  const [allAdminUsers, setAllAdminUsers] = useState([]);

  let authToken = decryptText(localStorage.getItem("aEmediat"));
  useEffect(() => {
    checkLoginAndRole("quries");
    getUserDetails();
    getAllAdminUsers();
  }, []);

  const getUserDetails = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));

    var deferredResponse = defer({
      res: QueriesServices.getQueryById(id, authToken),
    });
    setQueryDetails(deferredResponse);

    deferredResponse.data.res
      .then((response) => {
        setUserDetails(response.data[0]);
        setTicketActivityData(response.data[0]);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message,
          showConfirmButton: false,
          timer: 2500,
        });
      });
  };

  const getAllAdminUsers = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        pageNo: 0,
        limit: 999999,
        isSorted: "true",
        sortBy: "firstName",
        searchText: "",
      };
      var response = await UserServices.getAllAdmins(payload, authToken);

      if (response.success) {
        response.data = response.data.map((user) => {
          user.name = user.fullName;
          return user;
        });
        setAllAdminUsers(response.data);
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while getting all BSS Forms", err);
    }
  };

  //Failsafe if no data received from navigation
  let navigationData = [
    {
      title: "Back to Queries",
      route: "/customer-care",
      icon: GoBackArrow,
    },
    {
      title: "Details",
      component: "Details",
      icon: EditPencilIcon,
    },
  ];
  if (location.state && location.state.finalNavigationData) {
    navigationData = location.state.finalNavigationData;
  }

  //START : Popup fns
  const handlePopUpOpen = (event, popUpName) => {
    handlePopUpClose();
    setPopUp(popUpName);
    setIsPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };
  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
  };
  const handlePopUpSave = (data) => {
    if (popUp === "changeTicketStatus") {
      updateQueriesStatus(data);
    } else if (popUp === "addComment") {
      addCommentOnQuery();
    }
    handlePopUpClose();
  };
  //END : Popup fns

  const handleChangeComment = (text, userList) => {
    setComment(text);
    setTaggedUser(userList);
  };

  const updateQueriesStatus = async (data) => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const payload = {
      status: data.ticketStatus,
      queryIds: [id],
    };
    const response = await QueriesServices.updateQueriesStatus(
      payload,
      authToken
    );
    if (response.success) {
      Swal.fire({
        icon: "success",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
      getUserDetails();
    } else {
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  const addCommentOnQuery = async (data) => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const payload = {
      queryId: id,
      comment: comment,
      usersTagList: userTagList.map((user) => user._id),
    };

    const response = await QueriesServices.addCommentOnQuery(
      payload,
      authToken
    );
    if (response.success) {
      Swal.fire({
        icon: "success",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
      getUserDetails();
    } else {
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  return (
    <>
      <InnerSidebarNavigation navigationData={navigationData} />
      <QueriesDetailsView
        searchText={searchText}
        // START : states for popup
        popUp={popUp}
        isPopUpVisible={isPopUpVisible}
        popUpPosition={popUpPosition}
        handlePopUpClose={handlePopUpClose}
        handlePopUpOpen={handlePopUpOpen}
        handlePopUpSave={handlePopUpSave}
        // END : states for popup

        // START : states for default button bars
        // fullscreenElementRef={fullscreenElementRef}
        // toggleFullscreen={() => toggleFullscreen(fullscreenElementRef)}
        // END : states for default button bars

        userDetails={userDetails}
        queryDetails={queryDetails}
        ticketActivityData={ticketActivityData}
        allAdminUsers={allAdminUsers}
        handleChangeComment={handleChangeComment}
      />
    </>
  );
}

export default QueriesDetails;
