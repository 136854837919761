import React, { useEffect, useState } from 'react';

import NewFolderPopUpView from './view';

function NewFolderPopUp(props) {
    const[title, setTitle] = useState(props.title);
    const[page,setPage] = useState(props.pageType);

	const handleChange=(value, key)=>{
	   	if(key === "title")
		{
			setTitle(value)
		}else if(key === "page")
		{
			setPage(value)
		}
	}

	const handleCancel=()=>{
		props.handleCancel()
	}
	const handleSave=()=>{
		props.handleSave({title: title, pageType: page})
	}
	
	return (
		<NewFolderPopUpView
		   props = {props}	
           title = {title}
           page = {page}
		   handleChange={handleChange}
		   handleCancel ={handleCancel}
		   handleSave={handleSave}
			/>
	);
}

export default NewFolderPopUp;
