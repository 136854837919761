import React from 'react';

import './login.css';
import HeaderLogo from '@assets/sidebarNavigation/headerLogo.png';

function LoginView(props) {
	return (
		<>
			<nav id="topNavBar">
				<img src={HeaderLogo} alt="HeaderLogo" style={{ width: '44px' }} />
			</nav>
			<div className='mainLoginPageContainer'>
				<center>
					<div className='loginContainer'>
						<img
							src={HeaderLogo}
							alt='HeaderLogo'
							style={{ width: '44px' }}
						/>
						<h4>Login</h4>
						<p style={{ fontWeight: '500' }}>
							Login with your eMediSkill Admin Account
						</p>
                        
						{/**This is the error message */}
						{(props.errorMessage ? 
                          <div className="alert alert-danger w-50 mb-3 text-danger border-danger" role="alert">
						      {props.errorMessage}
					  	  </div> 
						  :
						  <div></div>
						)}
						
						<div style={{ marginBottom: '15px' }}>
							<input
								value={props.email}
								onChange={(event) => {
									props.handleEmail(event);
								}}
								type='text'
								className='form-control'
								placeholder='Username'
							/>
						</div>

						<div style={{ marginBottom: '15px' }}>
							<input
								value={props.password}
								onChange={(event) => {
									props.handlePassword(event);
								}}
								type='password'
								className='form-control'
								placeholder='Password'
							/>
						</div>

						
						<button
							type="button"
							className={`loginBtn ${props.waiting ? 'disabled' : ''}`}
							onClick={props.handleLogin}
							>
							{props.waiting ? (
								<>
								<i className="fa fa-spinner fa-spin" /> Login
								</>
							) : (
								'Login'
							)}
							</button>
					</div>
				</center>
			</div>
		</>
	);
}

export default LoginView;
