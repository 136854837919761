import React from 'react';


const PaymentGatewayDetails = (props) => {

    return (
        <div className="d-flex flex-column border rounded p-2" style={{backgroundColor: '#F3FBFF', width:"49%"}}>
            <div style={{fontSize:'13px'}}>{props.name}</div>
            <div style={{fontSize:'18px', color: '#252525', fontWeight:'bold'}}>{props.value}</div>
            <div style={{fontSize:'13px', marginTop:"10px"}}>Tax: {props.tax}</div>
            <div style={{fontSize:'13px'}}>PG Fee: {props.fee}</div>
        </div>
    )   
}

export default PaymentGatewayDetails;