import React, { useEffect, useState, useRef } from "react";

import CoursePricingView from "./view";
import { useParams, useNavigate } from "react-router-dom";
import * as CoursesServices from "@services/Course";

import { decryptText } from "@library/enc-dec";

import { pricingDefaultData } from "./data.js";
import Swal from "sweetalert2";

function CoursePricing() {
  const { id } = useParams();

  const [priceTabSelected, setPriceTabSelected] = useState(true);
  const [installmentsTabSelected, setInstallmentsTabSelected] = useState(false);
  const [pricingData, setPricingData] = useState(pricingDefaultData);
  const [courseInfo, setCourseInfo] = useState([]);
  const [isFormSaving, setIsFormSaving] = useState(false);

  useEffect(() => {
    getCoursePrices();
    getCourseInfo();
  }, []);

  const getCoursePrices = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await CoursesServices.getPricing(id, authToken);

      if (response.success) {
        setPricingData(response.data);
        return;
      }
      // setPricingData(pricingDefaultData)
    } catch (err) {
      console.log("Error coming while fetching courses prices", err);
    }
  };
  const getCourseInfo = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await CoursesServices.getCourseById(id, authToken);
      if (response.success) {
        setCourseInfo(response.data);
        return;
      }
      // setPricingData(pricingDefaultData)
    } catch (err) {
      console.log("Error coming while fetching courses info", err);
    }
  };

  const handleSave = async () => {
    setIsFormSaving(true);
    if (priceTabSelected) {
      savePricingTab();
    }
    if (installmentsTabSelected) {
      saveCardTab();
    }
  };

  const savePricingTab = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      if (id) {
        pricingData.courseId = id;
        let payload = pricingData;

        const response = await CoursesServices.updatePricing(
          payload,
          authToken
        );
        if (response.success) {
          Swal.fire({
            icon: "success",
            title: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
        }
        setIsFormSaving(false);
        return;
      }
    } catch (err) {}
  };

  const saveCardTab = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      if (id) {
        let payload = {
          courseId: id,
          installmentsApplied: pricingData.installmentsApplied,
          installments: pricingData.installments.map((installment) => {
            const { _id, ...rest } = installment;
            return rest;
          }),
        };

        const response = await CoursesServices.updateInstallments(
          payload,
          authToken
        );
        if (response.success) {
          Swal.fire({
            icon: "success",
            title: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
        }
        setIsFormSaving(false);
        return;
      }
    } catch (err) {}
  };

  const handleTabSelect = (eventKey) => {
    if (eventKey) {
      setPriceTabSelected(false);
      setInstallmentsTabSelected(false);
    }

    if (eventKey === "pricing") {
      setPriceTabSelected(true);
    } else if (eventKey === "installments") {
      setInstallmentsTabSelected(true);
    }
  };

  const handlePricingData = (data) => {
    setPricingData(data);
  };

  return (
    <>
      <CoursePricingView
        courseInfo={courseInfo}
        pricingData={pricingData}
        handlePricingData={handlePricingData}
        priceTabSelected={priceTabSelected}
        installmentsTabSelected={installmentsTabSelected}
        handleTabSelect={handleTabSelect}
        handleSave={handleSave}
        isFormSaving={isFormSaving}
      />
    </>
  );
}

export default CoursePricing;
