import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
// import './style.css';

import Pricing from '../Pricing';
import Installments from '../Installments';

import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { useEffect} from 'react';
import SaveBtn from '@components/SaveBtn'

function CoursePricingView(props) {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const tab = queryParams.get('tab');
    
	const defaultTab = tab ? tab : 'pricing'
    
	useEffect(()=>{
		props.handleTabSelect(tab)
	},[])


	//START : Vars for RichEditor
	const toolbarOptions = [
		{ header: [1, 2, 3, false] },
		{ font: [] },
		// { 'size': ['small', false, 'large', 'huge'] },
		'bold',
		'italic',
		'underline',
		'strike',
		{ script: 'sub' },
		{ script: 'super' },
		{ list: 'bullet' },
	];

	const formats = [
		'header',
		'font',
		'size',
		'bold',
		'italic',
		'underline',
		'strike',
		'script',
		'list',
		'bullet',
		'indent',
	];
	//END : Vars for RichEditor

	// return <div />;
	return (
		<>
			<div
				className='rightHandRightPageMainContainer d-flex flex-grow-1 gap-3'
				style={{ maxWidth: 'none' }}>
				<div className='d-flex flex-column flex-grow-1'>
						<div className='topContainer'>
							<h4 className='heading'>Course Pricing</h4>
							<div>
								<Link
									style={{ textDecoration: 'none' }}
									className='cancelBtn'
									to='/content?tab=courses'>
									Cancel
								</Link>
								<SaveBtn onClick={props.handleSave}
										text={"Save"}
										altText={"Saving..."}
										disabled={props.isFormSaving}
									/>
							</div>
						</div>
						<Tabs
								defaultActiveKey={defaultTab}
								id='myTabs'
								onSelect={props.handleTabSelect}
								style={{paddingLeft: '30px'}}
								>
								<Tab eventKey='pricing' title='Pricing'>
									{props.priceTabSelected && (
										<>
											<Pricing
											  courseInfo={props.courseInfo}
											  pricingData={props.pricingData}
											  handlePricingData={props.handlePricingData}
											/>
										</>
									)}
								</Tab>
								<Tab eventKey='installments' title='Installments'>
									{props.installmentsTabSelected && 
									        <Installments
												pricingData={props.pricingData}
												handlePricingData={props.handlePricingData}
											/>}
								</Tab>
								
						</Tabs>
						{/* START : Error container */}
						{props.formValuesEmpty && (
							<div className='incompleteFieldsError'>
								<span>All input fields are required</span>
							</div>
						)}
						{/* END : Error container */}
					
				</div>
			</div>
		</>
	);
}

export default CoursePricingView;
