import React, { useEffect, useState } from 'react';

import NewInstitutePopUpView from './view';

function NewInstitutePopUp(props) {
    const[institute, setInstitute] = useState(props.instituteInfo);

	const handleChange=(value, key)=>{
	   var updatedInstitute = {...institute};
	   updatedInstitute[key] = value
	   setInstitute(updatedInstitute)
	}

	const handleCancel=()=>{
		props.handleCancel()
	}
	const handleSave=()=>{
		props.handleSave(institute)
	}
	
	return (
		<NewInstitutePopUpView
		   institute = {institute}	
		   handleChange={handleChange}
		   handleCancel ={handleCancel}
		   handleSave={handleSave}
			/>
	);
}

export default NewInstitutePopUp;
