import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FaqDetailsView from "./view";

import * as FAQServices from "@services/FAQ";
import { navigationData } from "./data";
import { decryptText } from "@library/enc-dec";
import Swal from "sweetalert2";

function FaqDetails() {
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  const { id } = useParams();

  let authToken = decryptText(localStorage.getItem("aEmediat"));
  const [title, setFaqSetTitle] = useState("");
  const [questionsSet, setQuestionsSet] = useState([]);
  const [formValuesEmpty, setFormValuesEmpty] = useState(false);
  const [isFormSaving, setIsFormSaving] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    getFaqDetails();
  }, []);

  const getFaqDetails = async () => {
    try {
      if (id !== undefined) {
        const faqDocId = id;

        const response = await FAQServices.getFaq(faqDocId, authToken);

        if (response.success) {
          setFaqSetTitle(response.data.title);
          setQuestionsSet(response.data.questionsSet);
          return;
        }
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      // Reseting the values
      setFaqSetTitle("");
      setQuestionsSet([]);
    } catch (err) {
      console.log("Error coming while fetching faq details", err);
    }
  };

  const handleFaqSetTitle = (event) => {
    setFormValuesEmpty(false);
    setFaqSetTitle(event.target.value);
  };

  // Handling faq questions
  const handleAddFaqQuestion = () => {
    let newQuestion = {
      _id: `question-${questionsSet.length + 1}`,
      question: "",
      answer: "",
    };
    setQuestionsSet([...questionsSet, newQuestion]);
  };

  const handleQuestionDelete = (questionId) => {
    setFormValuesEmpty(false);
    let updatedQuestionsSet = questionsSet.filter(
      (question) => question._id.toString() !== questionId.toString()
    );

    setQuestionsSet(updatedQuestionsSet);
  };

  const handleQuestionUpdate = (questionData) => {
    setFormValuesEmpty(false);
    let copyQuestionsSet = [...questionsSet];
    let updatedQuestionsSet = copyQuestionsSet.map((item) => {
      if (item._id === questionData._id) {
        return questionData;
      }
      return item;
    });
    setQuestionsSet(updatedQuestionsSet);
  };

  //START : Fn for drag & drop functionality
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(questionsSet);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setQuestionsSet(items);
  };
  //END : Fn for drag & drop functionality

  const isValidQuestionSet = () => {
    //false = isses, true = no Issues
    if (questionsSet.length !== 0) {
      for (const question of questionsSet) {
        if (!question.question || !question.answer) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  };

  const handleSave = async () => {
    try {
      if (!title || !isValidQuestionSet()) {
        setFormValuesEmpty(true);
        return;
      }
      setFormValuesEmpty(false);
      setIsFormSaving(true);
      if (id !== undefined) {
        let payload = {
          faqDocId: id,
          title: title,
          questionsSet: questionsSet.map((question) => {
            return { question: question.question, answer: question.answer };
          }),
        };
        const response = await FAQServices.updateFaq(payload, authToken);
        if (response.success) {
          Swal.fire({
            icon: "success",
            title: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
          setIsFormSaving(false);
          navigate(`/faq/${id}`)
          return;
        }
        setFormValuesEmpty(true);
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        setIsFormSaving(false);
        return;
      } else {
        let payload = {
          title: title,
          questionsSet: questionsSet.map((question) => {
            return { question: question.question, answer: question.answer };
          }),
        };
        const response = await FAQServices.addFaq(payload, authToken);
        if (response.success) {
          Swal.fire({
            icon: "success",
            title: response.message,
            showConfirmButton: false,
            timer: 2500,
          });

          setIsFormSaving(false);
          navigate(`/faq/${response.data._id}`, {
            state: { finalNavigationData: navigationData },
          });
          return;
        }
        setFormValuesEmpty(true);
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        setIsFormSaving(false);
        return;
      }
    } catch (err) {
      console.log("Error coming while adding faq", err);
    }
  };

  return (
    <FaqDetailsView
      faqSetTitle={title}
      questionsSet={questionsSet}
      formValuesEmpty={formValuesEmpty}
      handleAddFaqQuestion={handleAddFaqQuestion}
      handleFaqSetTitle={handleFaqSetTitle}
      handleSave={handleSave}
      isFormSaving={isFormSaving}
      //START : Usestate & fn for DnD functionality
      onDragEnd={onDragEnd}
      handleQuestionDelete={handleQuestionDelete}
      handleQuestionUpdate={handleQuestionUpdate}
      //END : Usestate & fn for DnD functionality
    />
  );
}

export default FaqDetails;
