import React, { useState} from 'react';

import AnalyticsView  from './view';
import SidebarNavigation from '@components/SidebarNavigation';

const tabs = [{_id: '1', title: 'Overview', isActive: true},
				{_id: '2', title: 'Leads', isActive: false},
				{_id: '3', title: 'Geography', isActive: false}
				]
function Analytics(props) {
	const [folders, setFolders] = useState(tabs);
    const [activeTab, setActiveTab] = useState({_id: '1', // Provide a unique ID for the new folder
                                                    title: 'Admin users',
                                                    isActive: true,
                                                });

    return(
        <>
            <SidebarNavigation />
            <AnalyticsView
                folders={folders}
                activeTab = {activeTab}
                handleTabClick= {setActiveTab}
            />
        </>
    )
}

export default Analytics