const initialState = {
	allArticles: [],
	numOfArticles: null,
	articlesSet: [],
	selectedArticle: {
		_id: '',
		title: '',
		description: {
			icon: null,
			pointer: '',
		},
		pathUrl: '',
		sections: [
			{
				_id: '0',
				title: '',
				paragraph: '',
				pointers: [],
				imageUrl: null,
			},
			{
				_id: '1',
				title: '',
				paragraph: '',
				pointers: [],
				imageUrl: null,
			},
		],
	},
};

const CategoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_ALL_ARTICLES':
			return {
				...state,
				allArticles: action.payload.allArticles,
			};
		case 'UPDATE_TOTAL_NUM_OF_ARTICLES':
			return {
				...state,
				numOfArticles: action.payload.numOfArticles,
			};
		case 'UPDATE_ARTICLES_SET':
			return {
				...state,
				articlesSet: action.payload.articlesSet,
			};
		case 'RESET_ARTICLES_REDUCER':
			return {
				...state,
				allArticles: [],
			};
		case 'UPDATE_SELECTED_ARTICLE':
			return {
				...state,
				selectedArticle: action.payload.selectedArticle,
			};
		case 'RESET_ARTICLE':
			return {
				...state,
				selectedArticle: {
					_id: '',
					title: '',
					description: {
						icon: null,
						pointer: '',
					},
					pathUrl: '',
					sections: [
						{
							_id: '0',
							title: '',
							paragraph: '',
							pointers: [],
							imageUrl: null,
						},
						{
							_id: '1',
							title: '',
							paragraph: '',
							pointers: [],
							imageUrl: null,
						},
					],
				},
			};

		default:
			return state;
	}
};

export default CategoryReducer;
