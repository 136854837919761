export const getAllInterests = async (payload, authToken) => {
	// Visibility-> active,disabled,"all"
	const {pageNo, limit, isSorted, sortBy, searchText, visibility, tagType} = payload
	const url = `${process.env.REACT_APP_BASE_URL}/getAllInterests?pageNo=${pageNo}&limit=${limit}&visibility=${visibility}&tagType=${tagType}${searchText? '&searchText='+searchText: ''}${sortBy? '&sortBy='+sortBy: ''}${isSorted? '&isSorted='+isSorted: ''}`

	try {
		return await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Tag services : getAllInterests() ', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateInterests = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateInterests`, {
			method: 'PATCH',
			headers: {
                'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Tag services : updateInterests()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateInterestsVisibility = async (payload, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/updateInterestsVisibility`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
				body: JSON.stringify(payload),
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Tags services : updateInterestsVisibility()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};