import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';
import EditPencilIcon from '@assets/common/editPencilIcon.png';
import CommentsIcon from '@assets/common/commentsIcon.png';

import AnalyticsIcon from '@assets/common/analyticsIcon.png';

export const userPurchasedApiData = {
    "success" : true,
    "data" : [
        {
            _id : "1",
            thunbnailUrl : "",
            title : "1_title",
            description : '1_description',
            date : "2023-08-07T23:17:35Z",
        },
        {
            _id : "2",
            thunbnailUrl : "",
            title : "2_title",
            description : '2_description',
            date : "2023-08-07T23:17:35Z",
        },
        {
            _id : "3",
            thunbnailUrl : "",
            title : "3_title",
            description : '3_description',
            date : "2023-08-07T23:17:35Z",
        },
        {
            _id : "4",
            thunbnailUrl : "",
            title : "4_title",
            description : '4_description',
            date : "2023-08-07T23:17:35Z",
        },

    ]
};

//Object to handle naviagtion
export const navigationData = [
    {
        "title" : "Back to User",
        "route" : "/users",
        "default" : false,        
        "icon" : GoBackArrow
    },
    {
        "title" : "Details",
        "component" : "VideoDetails",
        "default" : false,        
        "icon" : EditPencilIcon
    },
    {
        "title" : "Comments",
        "component" : "VideoComments",
        "default" : false,         
        "icon" : CommentsIcon
    },
    {
        "title" : "Analytics",
        "component" : "VideoAnalytics",
        "default" : false,        
        "icon" : AnalyticsIcon
    },
]
