import React, { useEffect, useState } from "react";

import AddTestPopUpView from "./view";
import moment from "moment";

import * as TestsServices from "@services/Tests";
import * as UsersServices from "@services/Users";
import Swal from "sweetalert2";
// import * as CategoryServices from '@services/Category';
// import * as CoursesServices from '@services/Course';

import { decryptText } from "@library/enc-dec";

function AddTestPopUp(props) {
  const [allTests, setAllTests] = useState([]);
  const [selectedTestId, setSelectedTestId] = useState("");
  const [selectedTestsList, setSelectedTestsList] = useState([]);
  const [error, setError] = useState("");
  const [isFormSaving, setIsFormSaving] = useState(false);

  const handleTestChange = (event) => {
    setSelectedTestId(event.target.value);
  };

  const handleAddNewTest = (event) => {
    let selectedTest = {};
    allTests.forEach((test) => {
      if (test._id === selectedTestId) {
        selectedTest = test;
      }
    });

    setSelectedTestsList((prevTestList) => [...prevTestList, selectedTest]);
  };

  const handleDeleteTest = (event, testId) => {
    const updatedTest = selectedTestsList.filter((test) => test._id !== testId);
    setSelectedTestsList(updatedTest);
  };

  const handleCloseBtn = () => {
    props.handleClose();
  };

  const saveNewTest = async () => {
    if (selectedTestsList === "") {
      setError("No Test Selected");
      setTimeout(function () {
        setError("");
      }, 2000);
    } else {
      if (!props.userIds) {
        var isSaved = await props.handleSave(selectedTestsList);
      } else {
        // handleSave()
        var isSaved = true;
        props.handleSave();
      }

      if (isSaved) {
        // Clear the old data
        setSelectedTestId("");
      }
    }
  };

  useEffect(() => {
    getallTests();
  }, [props.visible]);

  const getallTests = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await TestsServices.getAllTestQuestionBanks(
        { pageNo: 0, limit: 10000 },
        authToken
      );

      if (response.success) {
        setAllTests(response.data);
      }
    } catch (err) {
      console.log("Error coming while fetching all test", err);
    }
  };

  const handleSaveBtn = async () => {
    setIsFormSaving(true)
    if (selectedTestsList === "") {
      setError("No Series Selected");
      setIsFormSaving(false)
      setTimeout(function () {
        setError("");
      }, 2000);
    } else {
      if (!props.userIds) {
        props.handleSave(selectedTestsList);
        setIsFormSaving(false)
      } else {
        addUsersTestExams();
      }
    }
  };

  const addUsersTestExams = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));

      const payload = {
        userIds: props.userIds,
        testQuestionbankIds: [
          ...new Set(selectedTestsList.map((item) => item._id)),
        ],
      };

      var response = await UsersServices.addUsersTestExams(payload, authToken);

      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        props.handleSave();
        setIsFormSaving(false)
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        setIsFormSaving(false)
        props.handleClose();
      }
    } catch (err) {
      setIsFormSaving(false)
      console.log("Error coming while adding tests", err);
    }
  };

  return (
    <AddTestPopUpView
      props={props}
      allTests={allTests}
      selectedTestsList={selectedTestsList}
      error={error}
      handleTestChange={handleTestChange}
      handleAddNewTest={handleAddNewTest}
      handleDeleteTest={handleDeleteTest}
      handleCloseBtn={handleCloseBtn}
      handleSaveBtn={handleSaveBtn}
      isFormSaving={isFormSaving}
    />
  );
}

export default AddTestPopUp;
