import React, { useState, useEffect, useRef } from "react";
import NoteView from "./view";
import "./style.css";

import Swal from "sweetalert2";
import { decryptText } from "@library/enc-dec";

import * as UserServices from "@services/Users";
import * as QueryServices from "@services/Queries";

const MIN_TEXTAREA_HEIGHT = 32;
var defaultCellStyle = null;
function Note(props) {
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const textareaRef = React.useRef(null);
  const cellRef = useRef(null);

  const [text, setText] = useState();
  const [popUpText, setPopUpText] = useState();

  useEffect(() => {
    setText(props.value);
    setPopUpText(props.value);
  }, [props.value]);

  React.useLayoutEffect(() => {
    if (textareaRef.current) {
      // Reset height - important to shrink on delete
      textareaRef.current.style.height = "inherit";
      // Set height
      textareaRef.current.style.height = `${Math.max(
        textareaRef.current.scrollHeight,
        MIN_TEXTAREA_HEIGHT
      )}px`;
    }
  }, [text]);

  // To close the popup container
  const handlePopUpOpen = (event) => {
    handlePopUpClose();

    var cell = event.target.parentElement;

    cellRef.current = cell;
    defaultCellStyle = cell.style;
    // cell.style.zIndex = '1';
    cell.style.overflow = "visible";
    cell.style.width = "fit-content";
    cell.style.position = "relative";
    cell.style.backgroundColor = "#ffffff";
    cell.style.minWidth = "100%";
    cell.style.borderRight = "1px solid #e9e9e9";
    cell.backgroundColor = "red";

    setIsPopUpVisible(true);
    event.stopPropagation();
  };

  const handlePopUpClose = () => {
    if (defaultCellStyle && cellRef.current) {
      cellRef.current.style = defaultCellStyle;
    }
    setIsPopUpVisible(false);
  };
  // To handleSave buttons on the popup
  const handlePopUpSave = async (data) => {
    handlePopUpClose();
    updateNote();
  };

  const updateNote = async () => {
    if (props.userId) {
      const payload = {
        userId: props.userId,
        fields: [
          {
            key: "notes",
            value: popUpText,
          },
        ],
      };
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await UserServices.updateUserFields(payload, authToken);
      if (response.success) {
        setText(popUpText);
        props.onChange(popUpText);
        Swal.fire({
          icon: "success",
          title: "Notes updated",
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } else if (props.queryId) {
      const payload = {
        queryId: props.queryId,
        fieldName: "notes",
        fieldValue: popUpText,
      };

      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await QueryServices.updateCustomerCareFields(
        payload,
        authToken
      );
      if (response.success) {
        setText(popUpText);
        props.onChange(popUpText);
        Swal.fire({
          icon: "success",
          title: "Notes updated",
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
  };

  return (
    <NoteView
      props={props}
      text={text}
      popUpText={popUpText}
      setPopUpText={setPopUpText}
      isPopUpVisible={isPopUpVisible}
      handlePopUpOpen={handlePopUpOpen}
      handlePopUpClose={handlePopUpClose}
      handlePopUpSave={handlePopUpSave}
      ref={textareaRef}
    />
  );
}

export default Note;
