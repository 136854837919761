import React from 'react';

import { Link } from 'react-router-dom';

import './videoDetails.css';

import GrayInfoIcon from '@assets/common/grayInfoIcon.png';

import BulletSelector from '@components/BulletSelector';
import ThumbnailPopup from '@components/ThumbnailPopup';
import SaveBtn from '@components/SaveBtn'
//Imports for RichEditor
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import TextInputV2 from '@components/TextInputV2';

function VideoDetailsView(props) {
	//START : Vars for RichEditor
	const toolbarOptions = [
		{ header: [1, 2, 3, false] },
		{ font: [] },
		// { 'size': ['small', false, 'large', 'huge'] },
		'bold',
		'italic',
		'underline',
		'strike',
		{ script: 'sub' },
		{ script: 'super' },
		{ list: 'bullet' },
	];

	const formats = [
		'header',
		'font',
		'size',
		'bold',
		'italic',
		'underline',
		'strike',
		'script',
		'list', 
		'bullet', 
		'indent',
	];
	//END : Vars for RichEditor

	return (
		<>
			<div className='rightHandRightPageMainContainer pt-4 pb-5' style={{width:'800px'}}>
				<div className='marginLeftContainer'>
					<div className='topContainer'>
						<h4 className='heading'>Video Details</h4>
						<div>
							<Link
								style={{ textDecoration: 'none' }}
								className='cancelBtn'
								to='/content'>
								Cancel
							</Link>
							
							<SaveBtn onClick={props.handleSave}
							  text={"Save"}
							  altText={"Saving..."}
							  disabled={props.isFormSaving}
							/>
						</div>
					</div>

					{/* START : Error container */}
					{props.formValuesEmpty && (
						<div className='incompleteFieldsError'>
							<span>All input fields are required</span>
						</div>
					)}
					{/* END : Error container */}

					{/* Video title */}
					<div>
					<TextInputV2
								placeholder='Video title'
								required= {true}
								value={props.videoTitle}
								onChange={(event)=>props.handleTitle(event)}
							/>
					</div>
					<br />

					{/* Video Description */}
					<div>
					<div className="form-floating">
						<textarea className="form-control inputBox" id="floatingTextarea"						
							value={props.videoDescription}
							onChange={(event) => {
								props.handleDescription(event);
							}}
							placeholder='Video Description'
							style={{height:'160px', border: '1px solid #ccc'}}
							></textarea>
						<label for="floatingTextarea">Video Description</label>
						</div>
					</div>
					<br />

					{/* Video link */}
					<div>						
					<TextInputV2
								placeholder='Paste Vimeo Link'
								required= {true}
								value={props.videoLink}
								onChange={(event)=>props.handleVideoLink(event)}
							/>
					</div>
					<br />

					{/* START : Video thumbnail */}
					<h4 className='subHeading'>Thumbnail</h4>
					<ThumbnailPopup
						thumbnailUrl={props.thumbnailUrl}
						onValueChange={props.handleThumbnailUrl}
					/>

					<p className='infoNote'>
						<img src={GrayInfoIcon} alt='GrayInfoIcon' />
						<b>Visibility</b>, if set to <i>“Public”,</i> video can play on link
						share.
					</p>
					{/* END : Video thumbnail */}
				</div>

				<hr />

				<div className='marginLeftContainer'>
					{/* START : Video bullets */}

					<div className='form-check pt-2'>
						<input
							checked={props.additionalInfoCheck}
							onChange={props.handleAdditionalInfoCheck}
							className='form-check-input'
							type='checkbox'
							id='bulletIconCheckbox'
						/>
						<label
							style={{
								fontWeight: '500',
								marginTop: '-3px',
							}}
							className='subHeading'
							htmlFor='bulletIconCheckbox'>
							Information
						</label>
					</div>

					<BulletSelector
						onValueChange={props.handleBulletIconChange}
						icon={props.additionalInfo ? props.additionalInfo.icon : ''}
					/>
<br></br>
					{/* START : Code for RichEditor */}
					<ReactQuill
						ref={props.additionalInfoRef}
						value={props.additionalInfo ? props.additionalInfo.pointer : ''}
						readOnly={!props.additionalInfoCheck}
						onChange={props.handleAdditionalInfo}
						modules={{
							toolbar: toolbarOptions,
						}}
						formats={formats}
					/>
					{/* END : Code for RichEditor */}

					<p className='infoNote'>
						<img src={GrayInfoIcon} alt='GrayInfoIcon' />
						Information is just shown above comments
					</p>
					{/* END : Video bullets */}
				</div>

				<hr style={{ marginBottom: '0px' }} />

				<div className='marginLeftContainer'>
					<div className='row'>
						{/* START : Video Likes */}
						<div
							style={{
								padding: '10px 12px',
								borderRight: '1px solid black',
							}}
							className='col-6'>
							<h4 className='subHeading'>Likes</h4>
							<div style={{ marginBottom: '12px' }} className='form-check'>
								<input
									checked={props.hideDislikes}
									onChange={(event) => {
										props.handleHideDislikes(event);
									}}
									className='form-check-input'
									type='checkbox'
									id='hideDislikes'
								/>
								<label htmlFor='hideDislikes'>Hide Dislikes</label>
							</div>

							<div className='form-check'>
								<input
									checked={props.hideLikes}
									onChange={(event) => {
										props.handleHideLikes(event);
									}}
									className='form-check-input'
									type='checkbox'
									id='hideLikes'
								/>
								<label htmlFor='hideLikes'>Hide Likes</label>
							</div>
						</div>
						{/* END : Video Likes */}

						{/* START : Video Comments */}
						<div
							style={{
								padding: '10px 12px',
							}}
							className='col-6'>
							<h4 className='subHeading'>Comments</h4>
							<div style={{ marginBottom: '12px' }} className='form-check'>
								<input
									checked={props.holdCommentsForReview}
									onChange={(event) => {
										props.handleCommentsStatus(event, 'heldForReview');
									}}
									className='form-check-input'
									type='checkbox'
									id='holdCommentsForReview'
								/>
								<label htmlFor='holdCommentsForReview'>Held Comments for review</label>
							</div>

							<div className='form-check'>
								<input
									checked={props.disableComments}
									onChange={(event) => {
										props.handleCommentsStatus(event, 'disabled');
									}}
									className='form-check-input'
									type='checkbox'
									id='disableComments'
								/>
								<label htmlFor='disableComments'>Disable Comments</label>
							</div>
						</div>
						{/* END : Video Comments */}
					</div>
				</div>

				<hr style={{ marginTop: '0px' }} />

				<div className='marginLeftContainer' style={{paddingBottom:'15%'}}>
					{/* START : Video Visibility */}
					<h4 className='subHeading'>Visibility</h4>

					<div className='form-check'>
						<input
							checked={props.visibility ? false : true}
							onChange={(event) => {
								props.handleVisibility(event, 'purchasedRequired');
							}}
							className='form-check-input'
							type='radio'
							name='visibility'
							id='purchasedRequired'
						/>
						<label htmlFor='purchasedRequired'>Purchased required</label>
					</div>

					<div className='form-check'>
						<input
							checked={props.visibility ? true : false}
							onChange={(event) => {
								props.handleVisibility(event, 'public');
							}}
							className='form-check-input'
							type='radio'
							name='visibility'
							id='public'
						/>
						<label htmlFor='public'>Public</label>
					</div>

					{/* END : Video Visibility */}
				</div>
			</div>
		</>
	);
}

export default VideoDetailsView;
