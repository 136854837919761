import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';
import QuestionMark from '@assets/sidebarNavigation/questionMark.png';
import EditPencilIcon from '@assets/common/editPencilIcon.png';


//Object to handle naviagtion
export const navigationData = [
    {
        "title" : "Back to Tests",
        "route" : "/content?tab=tests",
        "default" : false,        
        "icon" : GoBackArrow
    },
    {
        "title" : "Details",
        "component" : "TestDetails",
        "default" : true,        
        "icon" : EditPencilIcon
    },
    {
        "title" : "Question",
        "component" : "TestQuestions",
        "default" : false,        
        "icon" : QuestionMark
    }
]