const initialState = {
	filterInformation:{page: 0,
		limit:20,
		sortBy: '',
		isSorted: true,
		searchText: ''}
};

const SeriesReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_SERIES_FILTER_INFORMATION':
			return {
				...state,
				filterInformation: action.payload.filterInformation,
			};
		case 'RESET_SERIES_REDUCER':
			return initialState;

		default:
			return state;
	}
};

export default SeriesReducer;
