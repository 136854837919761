import React from 'react';
import moment from 'moment';
import './notification.css';

import ErrorView from '@components/ErrorView';

function NotificationView(props) {
	return (
		<>
			<h1 style={{marginLeft:'240px', marginTop: '40px'}}> Notification </h1>   
            <ErrorView
              text ={"Notification Section is Under Construction!"}
            /> 
		</>
	);
}

export default NotificationView;
