import React from "react";

import DeleteIcon from '@assets/common/deleteIcon.png';
import MoveBarIcon from '@assets/common/moveBarIcon.png';
import './faqQuestion.css';

function FaqQuestionView(props) {
    return (
        <>
            <br />
            <div ref={props.mainQuestionDivRef} className="questionContainer faqQuestionContainer">

                <div className="faqHeadContainer">
                    <p style={{ marginBottom: '7px', color: '#999' }}>
                        Question <span>{props.questionNo}</span>
                    </p>

                    <img
                        onClick={(event) => { props.handleDelete(event, props.questionId) }}
                        className="deleteIcon"
                        src={DeleteIcon}
                        alt="DeleteIcon" />
                </div>


                <div className="questionContainer_1">
                    <div>
                        <img
                            className="MoveBarIcon"
                            src={MoveBarIcon}
                            alt="MoveBarIcon" />
                    </div>

                    <div style={{ width: '100%' }}>

                        {/* START : Question Input */}
                        <textarea
                            style={{fontWeight: '600'}}
                            value={props.question}
                            onChange={(event) => { props.handleQuestion(event) }}
                            className="form-control faqQuestionInput"
                            placeholder="Type a Question"
                            rows="2"></textarea>
                        {/* END : Question Input */}



                        {/* START : Answer Input */}
                        <textarea
                            value={props.answer}
                            onChange={(event) => { props.handleAnswer(event) }}
                            className="form-control faqAnswerInput"
                            placeholder="Write an answer..."
                            rows="3"></textarea>

                        {/* END : Option Input */}

                        <br />
                    </div>

                </div>
            </div>
        </>
    )
}

export default FaqQuestionView;