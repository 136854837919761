import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

import TextInputV2 from "@components/TextInputV2";

import MoveBarIcon from "@assets/common/moveBarIcon.png";
import PlusCircleIcon from "@assets/common/plusCircleIcon.png";
import UploadIcon from "@assets/common/uploadIcon.png";
import DeleteIcon from "@assets/common/deleteIcon.png";

import { DragDropContext, Droppable } from "react-beautiful-dnd";
//DnD Import
import DnD from "@components/DnD";

import PopUp from "@components/PopUp";

import EditPencilIcon from "@assets/common/editPencilIcon.png";
import CommentIcon from "@assets/common/commentsIcon.png";
import ShareIcon2 from "@assets/common/shareIcon.png";
import AnalyticsIcon from "@assets/common/analyticsIcon.png";
import CopyIcon from "@assets/common/copyIcon.png";
import TextAndLinks from "@components/TextAndLinks";
import SaveBtn from "@components/SaveBtn";

function ContentView(props) {
  return (
    <div
      className="rightHandRightPageMainContainer flex-grow-1 gap-3 d-flex flex-column"
      style={{ maxWidth: "none" }}
    >
      <div className="topContainer">
        <h4 className="heading">Series Content</h4>
        <div>
          <Link
            style={{ textDecoration: "none" }}
            className="cancelBtn"
            to="/content?tab=courses"
          >
            Cancel
          </Link>
          <SaveBtn
            onClick={props.handleSave}
            text={"Save"}
            altText={"Saving..."}
            disabled={props.isFormSaving}
          />
        </div>
      </div>

      <div
        className="d-flex gap-3 flex-grow-1"
        style={{
          maxWidth: "none",
          borderTop: "1px solid #e5e5e5",
          overflow: "scroll",
        }}
      >
        <div
          className="d-flex ms-3"
          style={{ width: "100%", maxWidth: "729px", paddingBottom: "10%" }}
        >
          <div className="marginLeftContainer w-100 d-flex gap-3 flex-column mt-5 pt-2">
            {/* START : Error container */}
            {props.formValuesEmpty && (
              <div className="incompleteFieldsError">
                <span>All input fields are required</span>
              </div>
            )}
            {/* END : Error container */}
            {/* START : Deal ending section */}
            <div style={{ paddingBottom: "10%" }}>
              <DragDropContext onDragEnd={props.onSectionDragEnd}>
                <Droppable droppableId="Section">
                  {(provided) => (
                    <div
                      className="d-flex flex-column gap-4"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {props.seriesContent?.map((series, index) => (
                        <DnD
                          key={index}
                          element={series}
                          index={index}
                          newComponent={
                            <div
                              key={index}
                              className="d-flex gap-2 align-items-center"
                              style={{ fontSize: "14px" }}
                            >
                              <div
                                className="review flex-column"
                                style={{
                                  height: "auto",
                                  width: "100%",
                                  flex: "1 1",
                                }}
                              >
                                <div
                                  className="d-flex gap-2 align-items-center w-100"
                                  style={{ flex: "1 1" }}
                                >
                                  <div>
                                    <img
                                      className="moveIcon"
                                      src={MoveBarIcon}
                                      style={{ height: "5px" }}
                                    ></img>
                                  </div>
                                  <div className="reviewContent gap-2">
                                    <div className="d-flex align-items-center seriesEdit gap-2">
                                      <div className="reviewIndex ">
                                        {"Section" + (index + 1)}
                                      </div>
                                      <TextInputV2
                                        placeholder={"Section name"}
                                        value={series.sectionTitle}
                                        required={true}
                                        onChange={(event) => {
                                          props.handleChange(
                                            event.target.value,
                                            "sectionTitle",
                                            index
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <DragDropContext
                                  onDragEnd={(result) =>
                                    props.onVideoDragEnd(result, index)
                                  }
                                >
                                  <Droppable droppableId="Video">
                                    {(provided) => (
                                      <div
                                        className="w-100"
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                      >
                                        {props.seriesContent[index].videos?.map(
                                          (video, indexV) => (
                                            <DnD
                                              key={indexV}
                                              element={video}
                                              index={indexV}
                                              newComponent={
                                                <div
                                                  className="d-flex gap-2 align-items-center w-100 mb-2"
                                                  style={{ flex: "1 1" }}
                                                >
                                                  <div>
                                                    <img
                                                      className="moveIcon"
                                                      src={MoveBarIcon}
                                                      style={{ height: "5px" }}
                                                    ></img>
                                                  </div>
                                                  <div className="reviewContent gap-2">
                                                    <div className="d-flex align-items-center seriesEdit gap-2">
                                                      <div
                                                        className="SeriesThumbnail"
                                                        style={{
                                                          backgroundImage:
                                                            video.thumbnailUrl
                                                              ? `url(${video.thumbnailUrl})`
                                                              : `url(${UploadIcon})`,
                                                        }}
                                                        onClick={(event) =>
                                                          props.handlePopUpOpen(
                                                            event,
                                                            "newVideo",
                                                            indexV
                                                          )
                                                        }
                                                      >
                                                        <img src={UploadIcon} />
                                                      </div>
                                                      <TextAndLinks
                                                        title={video.title}
                                                        subTitle={
                                                          "This is subtitle. Course name will come here"
                                                        }
                                                        images={[
                                                          EditPencilIcon,
                                                          CommentIcon,
                                                          AnalyticsIcon,
                                                          ShareIcon2,
                                                          CopyIcon,
                                                        ]}
                                                        onClick={(
                                                          event,
                                                          index
                                                        ) => {
                                                          props.handleRedirectToVideo(
                                                            index,
                                                            video.videoId
                                                          );
                                                        }}
                                                      />
                                                      <img
                                                        className="addReviewIcon ms-auto"
                                                        src={PlusCircleIcon}
                                                        onClick={(event) => {
                                                          props.handlePopUpOpen(
                                                            event,
                                                            "videoUpload",
                                                            index,
                                                            indexV
                                                          );
                                                        }}
                                                      />
                                                      <img
                                                        className="addReviewIcon"
                                                        src={DeleteIcon}
                                                        onClick={(event) => {
                                                          props.deleteVideo(
                                                            index,
                                                            indexV
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              }
                                            />
                                          )
                                        )}
                                        {props.seriesContent[index].videos
                                          .length === 0 ? (
                                          <img
                                            className="addReviewIcon"
                                            style={{ paddingLeft: "50%" }}
                                            src={PlusCircleIcon}
                                            onClick={(event) => {
                                              props.handlePopUpOpen(
                                                event,
                                                "videoUpload",
                                                index,
                                                -1
                                              );
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                              </div>
                              <img
                                className="addReviewIcon"
                                src={PlusCircleIcon}
                                onClick={(event) => {
                                  props.handleAddSection(index);
                                }}
                              />
                              <img
                                className="addReviewIcon"
                                src={DeleteIcon}
                                onClick={(event) => {
                                  props.handlePopUpOpen(
                                    event,
                                    "sectionDelete",
                                    index
                                  );
                                }}
                              />
                            </div>
                          }
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <center>
                {!props.seriesContent || props.seriesContent.length == 0 ? (
                  <div className="bg-light w-100 p-3 mt-2">
                    <button
                      className="btn btn-dark d-flex gap-2 align-items-center w-50 justify-content-center"
                      style={{ fontSize: "14px" }}
                      onClick={(event) => {
                        props.handleAddSection(-1);
                      }}
                    >
                      <img className="addReviewIcon " src={PlusCircleIcon} />
                      Add Section
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </center>
            </div>
          </div>
        </div>
      </div>

      <PopUp
        visible={props.isPopUpVisible && props.popUp === "videoUpload"}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <div className="full-page-overlay">
          <div className="d-flex gap-2 bg-white p-3 rounded align-items-center flex-column w-25">
            <div className="w-100">
              <TextInputV2
                id={"videoUrl"}
                placeholder={"Paste video link"}
                required={false}
                value={props.newVideoUrl}
                onChange={(event) => props.setNewVideoUrl(event.target.value)}
              />
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <input
                type="button"
                className="cancleBtn"
                value="Cancel"
                onClick={props.handlePopUpClose}
              ></input>
              <input
                type="button"
                className="saveBtn"
                value="Save"
                onClick={props.addNewVideo}
              ></input>
            </div>
          </div>
        </div>
      </PopUp>

      <PopUp
        visible={props.isPopUpVisible && props.popUp === "sectionDelete"}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <div className="full-page-overlay ">
          <div className="d-flex gap-2 bg-white p-3 align-items-center flex-column w-25 rounded">
            <h4 className="text-start w-100">Confirm delete section</h4>
            <div className="w-100 border-bottom pb-3">
              Are you sure you want to delete the whole section?
            </div>
            <div className="d-flex gap-2 justify-content-end ms-auto">
              <input
                type="button"
                className="cancleBtn"
                value="Cancel"
                onClick={props.handlePopUpClose}
              ></input>
              <input
                type="button"
                className="saveBtn"
                value="Confirm"
                onClick={props.deleteSection}
              ></input>
            </div>
          </div>
        </div>
      </PopUp>
    </div>
  );
}

export default ContentView;
