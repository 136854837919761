import React, { useEffect, useState } from 'react';


import SetCourseDurationPopUpView from './view';

function SetCourseDurationPopUp(props) {
	const[courseDuration, setCourseDuration]=useState("1");
	const[isDurationInYear, setIsDurationInYear]=useState(true);

	const handleCheckbox=(isInYears, value)=>{
		setIsDurationInYear(isInYears) 
		setCourseDuration(value)
	}

	const handleCancel=()=>{
		props.handleCancel()
	}
	const handleSave=()=>{
		props.handleSave({courseDuration: courseDuration, isDurationInYear: isDurationInYear})
	}

	
	return (
		<SetCourseDurationPopUpView
		   courseDuration={courseDuration}
		   isDurationInYear={isDurationInYear}
		   props = {props}	
		   handleCheckbox={handleCheckbox}
		   handleCancel ={handleCancel}
		   handleSave={handleSave}
			/>
	);
}

export default SetCourseDurationPopUp;
