import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import TestDetailsView from "./view";

import { produce } from "immer";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { decryptText } from "@library/enc-dec";
import { toHoursAndMinutes } from "@helpers/exam";

import * as TestServices from "@services/Tests";
import * as TestActions from "@redux/actions/Test";
import Swal from "sweetalert2";

function TestDetails(props) {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);
  const { id } = useParams();

  const [isDeclareResultImmediately, setIsDeclareResultImmediately] =
    useState(false);

  const [isHideResult, setIsHideResult] = useState(false);

  const [formValuesEmpty, setFormValuesEmpty] = useState(false);
  const [testThumbnail, setTestThumbnail] = useState(null);
  const [questionsSet, setQuestionsSet] = useState([]);

  const quillInstructionRef = useRef(null);
  const quillPassTestMessageRef = useRef(null);
  const quillFailedTestMessageRef = useRef(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [isFormSaving, setIsFormSaving] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    if (!id) {
      navigate("/test");
      return;
    }
    getQuestionBank();
    return () => {
      setQuestionsSet([]);
      props.resetTestsReducer();
    };
  }, []);

  useEffect(() => {
    setTestThumbnail(props.thumbnailUrl);
  }, [props.thumbnailUrl]);

  const getQuestionBank = async () => {
    try {
      const questionBankId = id;

      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await TestServices.getTestQuestionBankById(
        questionBankId,
        authToken
      );
      if (response.success) {
        if (response.data.declarationResultInDays === 0) {
          setIsDeclareResultImmediately(true);
        }

        if (response.data.hideResultInDays > 0) {
          setIsHideResult(true);
        }
        setQuestionsSet(response.data.questionsSet);
        props.updateTestDetails(response.data);
      }
    } catch (err) {
      console.log("Error coming while geting question bank", err);
    }
  };

  const handleTestTitle = (event) => {
    props.updateTestTitle(event.target.value);
  };

  const handleDisplayQuestions = (event) => {
    props.updateDisplayQuestions(event.target.value);
  };

  const handleIncreaseDisplayQuestions = (event) => {
    let { displayQuestions } = props;
    displayQuestions++;
    props.updateDisplayQuestions(displayQuestions);
  };

  const handleDecreaseDisplayQuestions = (event) => {
    let { displayQuestions } = props;
    if (displayQuestions > 0) {
      displayQuestions--;
      props.updateDisplayQuestions(displayQuestions);
    }
  };

  const handleDuration = (event) => {
    const time = toHoursAndMinutes(Number(event.target.value));

    props.updateTestDuration(time);
  };

  const handlePassPercentage = (event) => {
    props.updateTestPassPercentage(event.target.value);
  };

  const handleIsDeclareResultImmediately = (event, value) => {
    if (value === "immediately") {
      setIsDeclareResultImmediately(true);
      props.updateResultInDays(0);
    } else {
      setIsDeclareResultImmediately(false);
    }
  };

  const handleDeclaredResultInDays = (event) => {
    if (!isDeclareResultImmediately) {
      props.updateResultInDays(event.target.value);
    }
  };

  const handleHideResultInDays = (event) => {
    props.updateHideResultInDays(event.target.value);
  };

  const handleIsHideResult = (event) => {
    if (!event.target.checked) {
      props.updateHideResultInDays(0);
    }
    setIsHideResult(event.target.checked);
  };

  const handleBulletIconChange = (bulletIcon) => {
    let updatedInstructions = produce(props.instructions, (draft) => {
      draft.icon = bulletIcon.icon;
    });

    props.updateTestInstructions(updatedInstructions);
  };

  const handleInstructions = (content) => {
    const editorInstance = quillInstructionRef.current.getEditor();
    const text = editorInstance.getText().trim();

    if (text.length !== 0) {
      let updatedInstructions = produce(props.instructions, (draft) => {
        draft["pointer"] = content;
      });

      props.updateTestInstructions(updatedInstructions);
      return;
    }

    let updatedInstructions = produce(props.instructions, (draft) => {
      draft["pointer"] = "";
    });

    props.updateTestInstructions(updatedInstructions);
  };

  const handlePassMessage = (content) => {
    const editorInstance = quillPassTestMessageRef.current.getEditor();
    const text = editorInstance.getText().trim();
    if (text.length !== 0) {
      props.updateTestPassedMessage(content);
      return;
    }
    props.updateTestPassedMessage("");
  };

  const handleFailedMessage = (content) => {
    const editorInstance = quillFailedTestMessageRef.current.getEditor();
    const text = editorInstance.getText().trim();
    if (text.length !== 0) {
      props.updateTestFailedMessage(content);
      return;
    }
    props.updateTestFailedMessage("");
  };

  const handleIsShuffleQuestions = (event) => {
    props.updateTestQuestionsInSequence(event.target.checked);
  };

  const handleThumbnailChange = (thumbnailObjs) => {
    setTestThumbnail(thumbnailObjs[0]);
  };

  const handleSave = () => {
    const { title, displayQuestions, duration, passPercentage } = props;
    const { failedMessage, passedMessage, instructions } = props;
    if (!title) {
      setFormValuesEmpty(true);
      setErrorMessage("Title field is empty");
      return;
    }
    if (!displayQuestions) {
      setFormValuesEmpty(true);
      setErrorMessage("Display Questions field is empty");
      return;
    }
    if (!duration) {
      setFormValuesEmpty(true);
      setErrorMessage("Duration field is empty");
      return;
    }
    if (!passPercentage) {
      setFormValuesEmpty(true);
      setErrorMessage("Pass Percentage field is empty");
      return;
    }
    if (!instructions) {
      setFormValuesEmpty(true);
      setErrorMessage("Instruction field is empty");
      return;
    }
    if (!instructions.pointer) {
      setFormValuesEmpty(true);
      setErrorMessage("Instruction Pointer is empty");
      return;
    }
    if (!instructions.icon) {
      setFormValuesEmpty(true);
      setErrorMessage("Instruction Icon is empty");
      return;
    }
    if (!passedMessage) {
      setFormValuesEmpty(true);
      setErrorMessage("Passed Message field is empty");
      return;
    }
    if (!failedMessage) {
      setFormValuesEmpty(true);
      setErrorMessage("Failed Message field is empty");
      return;
    }

    setFormValuesEmpty(false);

    if (id) {
      handleOldQuestionBankUpdate(id);
      return;
    }

    handleAddNewQuestionBank();
  };

  const handleOldQuestionBankUpdate = async (questionBankId) => {
    try {
      setIsFormSaving(true);
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        questionBankId: questionBankId,
        title: props.title,
        duration: props.duration,
        passPercentage: props.passPercentage,
        displayQuestions: props.displayQuestions,
        thumbnailUrl: testThumbnail ? testThumbnail : props.thumbnailUrl,
        declarationResultInDays: props.declarationResultInDays,
        hideResultInDays: props.hideResultInDays,
        instructions: props.instructions,
        passedMessage: props.passedMessage,
        failedMessage: props.failedMessage,
        inSequence: props.inSequence,
        questionsSet: questionsSet.map((question) => {
          return {
            question: question.question,
            options: question.options,
            answer: question.answer,
          };
        }),
      };

      const response = await TestServices.updateTestQuestionBank(
        payload,
        authToken
      );
      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
      setIsFormSaving(false);
    } catch (err) {
      setIsFormSaving(false);
      console.log("Error coming while updating question bank", err);
    }
  };

  const handleAddNewQuestionBank = async () => {
    try {
      setIsFormSaving(true);
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        title: props.title,
        duration: props.duration,
        passPercentage: props.passPercentage,
        displayQuestions: props.displayQuestions,
        thumbnailUrl: testThumbnail ? testThumbnail : props.thumbnailUrl,
        declarationResultInDays: props.declarationResultInDays,
        hideResultInDays: props.hideResultInDays,
        instructions: props.instructions,
        passedMessage: props.passedMessage,
        failedMessage: props.failedMessage,
        inSequence: props.inSequence,
        questionsSet: [],
      };

      const response = await TestServices.addTestQuestionBank(
        payload,
        authToken
      );
      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        
        navigate(`/test/${response.data._id}`);
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
      setIsFormSaving(false);
    } catch (err) {
      console.log("Error coming while adding question bank", err);
      setIsFormSaving(false);
    }
  };

  const duration = props.duration.hours * 60 + props.duration.minutes;

  return (
    <>
      <TestDetailsView
        title={props.title}
        displayQuestions={props.displayQuestions}
        duration={duration}
        thumbnailUrl={props.thumbnailUrl}
        passPercentage={props.passPercentage}
        declareResultInDays={props.declarationResultInDays}
        hideResultInDays={props.hideResultInDays}
        instructions={props.instructions}
        passedMessage={props.passedMessage}
        failedMessage={props.failedMessage}
        isShuffleQuestions={props.inSequence}
        quillInstructionRef={quillInstructionRef}
        quillPassTestMessageRef={quillPassTestMessageRef}
        quillFailedTestMessageRef={quillFailedTestMessageRef}
        questionsSet={questionsSet}
        formValuesEmpty={formValuesEmpty}
        // Page handlers
        handleTestTitle={handleTestTitle}
        handleDisplayQuestions={handleDisplayQuestions}
        handleIncreaseDisplayQuestions={handleIncreaseDisplayQuestions}
        handleDecreaseDisplayQuestions={handleDecreaseDisplayQuestions}
        handleDuration={handleDuration}
        handlePassPercentage={handlePassPercentage}
        handleDeclaredResultInDays={handleDeclaredResultInDays}
        handleHideResultInDays={handleHideResultInDays}
        handleInstructions={handleInstructions}
        handlePassMessage={handlePassMessage}
        handleFailedMessage={handleFailedMessage}
        handleIsShuffleQuestions={handleIsShuffleQuestions}
        // helpers flag
        isHideResult={isHideResult}
        isDeclareResultImmediately={isDeclareResultImmediately}
        handleIsHideResult={handleIsHideResult}
        handleIsDeclareResultImmediately={handleIsDeclareResultImmediately}
        handleSave={handleSave}
        handleBulletIconChange={handleBulletIconChange}
        handleThumbnailChange={handleThumbnailChange}
        //START : Usestate & fn for questions

        errorMessage={errorMessage}
        isFormSaving={isFormSaving}
        testThumbnail={testThumbnail}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    allTests: state.test.allTests,
    testsSet: state.test.testsSet,
    title: state.test.title,
    duration: state.test.duration,
    displayQuestions: state.test.displayQuestions,
    passPercentage: state.test.passPercentage,
    thumbnailUrl: state.test.thumbnailUrl,
    declarationResultInDays: state.test.declarationResultInDays,
    hideResultInDays: state.test.hideResultInDays,
    instructions: state.test.instructions,
    passedMessage: state.test.passedMessage,
    failedMessage: state.test.failedMessage,
    inSequence: state.test.inSequence,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAllTests: TestActions.updateAllTests,
      updateTotalNumOfTests: TestActions.updateTotalNumOfTests,
      updateTestsSet: TestActions.updateTestsSet,
      updateTestDetails: TestActions.updateTestDetails,
      updateTestTitle: TestActions.updateTestTitle,
      updateTestDuration: TestActions.updateTestDuration,
      updateTestPassPercentage: TestActions.updateTestPassPercentage,
      updateDisplayQuestions: TestActions.updateDisplayQuestions,
      updateTestThumbnailUrl: TestActions.updateTestThumbnailUrl,
      updateResultInDays: TestActions.updateResultInDays,
      updateHideResultInDays: TestActions.updateHideResultInDays,
      updateTestInstructions: TestActions.updateTestInstructions,
      updateTestPassedMessage: TestActions.updateTestPassedMessage,
      updateTestFailedMessage: TestActions.updateTestFailedMessage,
      updateTestQuestionsInSequence: TestActions.updateTestQuestionsInSequence,
      resetTestsReducer: TestActions.resetTestsReducer,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TestDetails);
