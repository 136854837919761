const initialState = {
	pendingIdProof : [],
	pendingDoctorProof: [],
	pendingPhotoProof: []
};

const VerificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_PENDING_ID_PROOF':
			return {
				...state,
				pendingIdProof : action.payload.pendingIdProof,
			};
		case 'UPDATE_PENDING_DOCTOR_PROOF':
			return {
				...state,
				pendingDoctorProof : action.payload.pendingDoctorProof,
			};
		case 'UPDATE_PENDING_PHOTO_PROOF':
			return {
				...state,
				pendingPhotoProof : action.payload.pendingPhotoProof,
			};
		default:
			return{
				...state
			}
    }
}

export default VerificationReducer;