import React, {useState} from "react";
import './style.css'
import FilterBar from '@components/FilterBar'

import DocVerificationCard from '@components/DocVerificationCard'
import VerificationPopUpCard from '@components/VerificationPopUpCard'
import ExpandIcon from '@assets/userDashboard/expand.png';


function DoctorProofView(props) {
     return (
        <>
           <div className="d-flex flex-column flex-grow-1 overflow-auto">
		   <div className='d-flex align-items-center w-100 justify-content-between'>
			<FilterBar
			  isChecked = {props.isFilterChecked}
			  isMenuVisible = {props.isFilterMenuVisible}

			  handleCheckbox = {()=>props.handleFilterCheckbox()} 
			  handleVerifyButton = {()=>props.handleVerifyAllButton()}
			  handleRejectButton = {()=>props.handleRejectAllButton()}

			  handleMenuVisibility = {(e, option)=>props.handleFilterMenuVisibility(option)}
			/>
			<div className='d-flex gap-2 controlsRight me-3'>
						<button onClick={props.toggleFullscreen}>
							<img src={ExpandIcon}></img>
							<br></br>
							Expand
						</button>
			</div>
		</div>
			<hr/>
			<div div className="cardWrapper flex-grow-1 overflow-scroll pb-3" style={{height:'100px'}}>
				{ props.cardGridData != undefined && props.cardGridData.map((data)=>(
				<DocVerificationCard
					name = {data.fullName}
					images = {[data.marksheetOrDegreeCert]}
					isChecked={ props.selectedCards.includes(data._id) }
					handleCardClick = {()=>{props.handleGridCardClick(data)}}
					handleCheckBoxes = {()=>props.handleGridCheckBox(data._id)}
					/>
				)) }   
			</div>

			<VerificationPopUpCard 
			  img = {props.popUpImg}
			  name = {props.popUpName}
			  contact = {props.popUpContact}
			  whatsapp = {props.popUpWhatsapp}
			  email = {props.popUpEmail}
			  qualification = {props.popUpQualification}
			  courses = {props.popUpCourses}
			  handleVerifyButton = {()=>props.handlePopUpVerifyButton()}
			  handleRejectButton = {()=>props.handlePopUpRejectButton()}
			  handleCheckBox = {()=>props.handlePopUpPopUpCheckBox()}
			  handleBackButton = {()=>props.handlePopUpBackButton()}
			  iscardVisible = {props.isPopUpVisible}
			  isChecked = { props.isPopUpChecked}
			/>
		</div>
        </>
    )
}

export default DoctorProofView;