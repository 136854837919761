import React from 'react';

import './style.css';

import BulletSelector from '@components/BulletSelector';
//Imports for RichEditor
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import TextInputV2 from '@components/TextInputV2';
import SelectInput from '@components/SelectInput';
import PopUp from '@components/PopUp';
import EditFAQPopUp from '@components/Courses/EditFAQPopUp'
import ImageInput from '@components/ImageInput';

import PlusIcon from '@assets/common/plusIconGrey.png';
import MoveBarIcon from '@assets/common/moveBarIcon.png';
import PlusCircleIcon from '@assets/common/plusCircleIcon.png';
import PencilIcon from '@assets/common/pencil.png';
import DeleteIcon from '@assets/common/deleteIcon.png';
import ProfileIcon from '@assets/sidebarNavigation/profilePlaceholder.png';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';
//DnD Import
import DnD from '@components/DnD';
import { ButtonToolbar } from 'react-bootstrap';

//START : Vars for RichEditor
const toolbarOptions = [
    { header: [1, 2, 3, false] },
    { font: [] },
    // { 'size': ['small', false, 'large', 'huge'] },
    'bold',
    'italic',
    'underline',
    'strike',
    { script: 'sub' },
    { script: 'super' },
    { list: 'bullet' },
];

const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'script',
    'list',
    'bullet',
    'indent',
];
//END : Vars for RichEditor

function ProductPageView(props) {
    return(
        <div className="d-flex gap-3">
        <div style={{width:'700px', marginLeft: '25px'}} className="courseDetails mt-4 d-flex gap-3 flex-column">
            <div className="d-flex gap-2 align-items-center">
                
                <input type="checkbox"
                   checked={props.courseDetails?.showInfoParagraph}
                   onClick={(event)=>props.handleChange(event.target.checked, 'showInfoParagraph')}
                ></input>
                <h4 className='subHeading mb-0'>
                    Information Paragraph <span className="text-danger">*</span>
                </h4>
            </div>
           
            {/* START : Card bullets pointers container */}
            <h4
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    marginTop: '16px',
                }}
                className='subHeading'>
                <BulletSelector
                    icon={
                        props.courseDetails?.infoParagraph
                            ? props.courseDetails?.infoParagraph?.icon
                            : ''
                    }
                    onValueChange={(bulletIcon) =>
                        props.handleChange(bulletIcon, 'infoParagraphIcon')
                    }
                />
            </h4>

            {/* START : Code for RichEditor */}
            <ReactQuill
                ref={props.infoPointerEditorRef}
                value={
                    props.courseDetails.infoParagraph
                        ? props.courseDetails.infoParagraph?.pointer
                        : ''
                }
                onChange={props?.handleInfoPointers}
                onKeyDown={props.handleEditorTextEdit}
                
                modules={{
                    toolbar: toolbarOptions,
                }}
                formats={formats}
                style={{ marginBottom: '20px' }}
            />
            {/* END : Code for RichEditor */}
            <div className="htmlRendererDiv mt-3" 
            dangerouslySetInnerHTML={{ __html: props.courseDetails?.infoParagraph?.pointer }} >
            </div>

            <div className="mt-2">
                <h4 className='subHeading  gap-2 d-flex'>
                    <input className="coloredCheckbox" type="checkbox"
                        checked={props.courseDetails?.showCertificate}
                     onClick={(event)=>props.handleChange(event.target.checked, 'showCertificate')}
                    />
                    Sample Certificate
                </h4>
                <div className="imageCarousel">
                    <div className="addCarouselImage" 
                         style={{backgroundImage: props.courseDetails?.certificateThumbnailUrl?
                                            typeof props.courseDetails.certificateThumbnailUrl === 'string' ? 
                                             `url(${props.courseDetails.certificateThumbnailUrl})`:
                                             `url(${URL.createObjectURL(props.courseDetails.certificateThumbnailUrl)})`
                                             : `url(${PlusIcon})`,
                            height:'81px', width: '143px',
                            backgroundSize: props.courseDetails?.certificateThumbnailUrl? 'contain' : '',}}
                        
                        onClick={(event)=>props.handleOpenPopUp(event, 'certificateThumbnailUrl')}
                    >
                </div>
                </div>
            </div>

            <div>
            <h4 className='subHeading  gap-2 d-flex'>
                    Road Map <span className="text-danger">*</span>
            </h4>
            <div className='d-flex flex-column gap-2'>
                <div className='form-check d-flex gap-2'>
                    <input
                        onChange={(event) => props.handleChange(event.target.value, 'roadmapType')}
                        checked={props.courseDetails?.roadmapType==='online'}
                        className='coloredRadio'
                        type='radio'
                        name='roadmapType'
                        value='online'
                        id="roadmap"
                    />
                    <label htmlFor='roadmap'>Show Online Roadmap</label>
                </div>
                <div className='form-check d-flex gap-2'>
                    <input
                        onChange={(event) => props.handleChange(event.target.value, 'roadmapType')}
                        checked={props.courseDetails?.roadmapType==='onlinePractical'}
                        className='coloredRadio'
                        type='radio'
                        name='roadmapType'
                        value='onlinePractical'
                        id="roadmap"
                    />
                    <label htmlFor='roadmap'>Show Online + Practical Training Roadmap</label>
                </div>
                <div className='form-check d-flex gap-2'>
                    <input
                        onChange={(event) => props.handleChange(event.target.value, 'roadmapType')}
                        checked={props.courseDetails?.roadmapType==="none"}
                        className='coloredRadio'
                        type='radio'
                        name='roadmapType'
                        value="none"
                    />
                    <label htmlFor='unlisted'>Don't Show Roadmap</label>
                </div>        
            </div>
            </div>

           
            <h4 className='subHeading  gap-2 d-flex mt-3'>
                    <input className="colorCheckbox" type="checkbox" 
                      checked={props.courseDetails?.showPreviewVideoLink}
                      onClick={(event)=>props.handleChange(event.target.checked, 'showPreviewVideoLink')}
                    />
                    Preview Video Link
            </h4>
            <TextInputV2 id ={"previewVideoLink"} placeholder ={"Paste YouTube video link"}
               value={props.courseDetails.previewVideoLink? props.courseDetails.previewVideoLink : ''}
               onChange={(event)=>props.handleChange(event.target.value, 'previewVideoLink')}
            />

            <SelectInput
                title={'Select FAQ'}
                required={true}
                inputText={props.allFAQS.some(exam => exam._id === props.courseDetails.faqsId) ? 
                    props.allFAQS.find(exam => exam._id === props.courseDetails.faqsId).title 
                    : "Select FAQ Set"}
                onClick={(event)=>props.handleOpenPopUp(event, 'faqsId')}
            />            

            <div className="courseReviews">
                <h4 className='subHeading  gap-2 d-flex mt-3'>
                Reviews ({props.courseDetails?.reviews?.length})
                </h4>
                <DragDropContext onDragEnd={props.onReviewDragEnd}>
                    <Droppable droppableId='dataSet'>
                        {(provided) => (
                            <div className="d-flex flex-column gap-4"
                               ref={provided.innerRef} {...provided.droppableProps}>
                                {props.courseDetails.reviews.map((_review, index) => (
                                    
                                        <DnD
                                            kay={index}
                                            element={_review}
                                            index={index}
                                            newComponent={
                                                <div className="d-flex gap-2 align-items-center">
                                                    <div className="review">
                                                        <div>
                                                            <img className="moveIcon" src={MoveBarIcon}></img>
                                                        </div>
                                                        <div className="reviewContent">
                                                            <div className="d-flex">
                                                                <div className="d-flex flex-column justify-content-around">
                                                                    <div className="reviewIndex">{"Review "+(index+1)}</div>
                                                                    <div className="reviewUser">{_review.name}</div>
                                                                </div>
                                                                <div className="ms-auto d-flex align-items-center">
                                                                    
                                                                     <img className="reviewUserImgEdit" src={PencilIcon}
                                                                      onClick={(event)=>props.handleOpenPopUp(event, 'reviewEdit', {index:index, review: {..._review}})}
                                                                     />
                                                                    <img className="reviewUserImg"
                                                                    src={typeof _review.displayPhoto === 'string' ? 
                                                                            _review.displayPhoto? _review.displayPhoto : ProfileIcon:
                                                                            URL.createObjectURL(_review.displayPhoto) }></img>

                                                                </div>
                                                            </div>
                                                            <div>
                                                                <input type="textArea" 
                                                                       value={_review.reviewText}
                                                                       onChange={(event)=>props.handleReviewUpdate('reviewText',event.target.value, index)}
                                                                       ></input>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                    <img className="addReviewIcon" src={PlusCircleIcon}
                                                        onClick={(event) => {
                                                            props.handleAddReview(event, index);
                                                    }}/>
                                                    <img className="addReviewIcon" src={DeleteIcon} 
                                                        onClick={(event) => {
                                                            props.handleDeleteReview( _review._id);
                                                        }}
                                                    />
                                                    
                                                </div>
                                            }
                                        />
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            
                <center>
                {
                    props.courseDetails.reviews.length==0 ?
                    <img className="addReviewIcon "src={PlusCircleIcon}
                        onClick={(event) => {
                            props.handleAddReview(event, null);
                        }}/>
                    : ''
                }
                
                </center>
            </div>  
        </div>

        <PopUp
            visible={props.isSelectFAQPopUpVisible}
            top={props.popUpPosition.top}
            left={props.popUpPosition.left}
            handleOutSideClick={props.handlePopUpClose}>
            <EditFAQPopUp
                courseId = {props.courseDetails._id}
                searchPlaceholder={'Select FAQ set'}
                showNone={true}
                selectedExam={props.courseDetails.faqsId}
                allExams={props.allFAQS}
                handleClose={props.handlePopUpClose}
                handleSave={(event)=>props.handleChange(event.target.value, 'faqsId')}
            />
        </PopUp>

        <PopUp
            visible={props.isReviewPopUpVisible}
            top={props.popUpPosition.top}
            left={props.popUpPosition.left}
            handleOutSideClick={()=>{}}
            >
             <div className="full-page-overlay">
                <div className='d-flex gap-2 bg-white p-3 rounded-2 align-items-center flex-column'>
                    <TextInputV2 
                        value={props.selectedReview?.review?.name} 
                        placeholder={"Enter User Name"}  
                        onChange={(event)=>props.handleSelectedReviewUpdate('name',event.target.value, props.selectedReview?.index)}                         
                        />
                    <div className="imageCarousel">
                        <div className="addCarouselImage" 
                            style={{backgroundImage: props.selectedReview?.review?.displayPhoto? 
                                                `url(${props.selectedReview?.review?.displayPhoto})`: `url(${PlusIcon})`,
                                height:'81px', width: '143px',
                                backgroundSize: props.selectedReview?.review?.displayPhoto? 'contain' : '',}}
                            onClick={(event)=>props.handleOpenPopUp(event, 'reviewImageEdit')}
                        >
                        </div>
                    </div>
                    <div className="d-flex gap-2">
                        <button className='closeBtn' onClick={props.handlePopUpClose}>
                            Cancel
                        </button>
                        <button onClick={(event)=>props.handleChange(event, 'reviews')} className='saveBtn'>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </PopUp>

        
        <PopUp
            visible={props.isImageUploadPopUpVisible}
            top={props.popUpPosition.top}
            left={props.popUpPosition.left}
            handleOutSideClick={()=>{}}>
            <div className="full-page-overlay">
                <ImageInput
                 handleClose={props.handlePopUpClose}
                 handleAdd={(event, files)=>props.handleChange(files,'imageUpload')} 
                />
            </div>
        </PopUp>

        
        
        </div>
    )
}

export default ProductPageView;