
export const addFormalRequest = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/addFormalRequest`, {
			method: 'POST',
			headers: {
                'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Formal Request services : addFormalRequest()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getAllFormalRequests = async (payload, authToken) => {
	const {pageNo, limit, isSorted, sortBy, searchText, requestType, status, location, startDate, endDate} = payload
	const url = `${process.env.REACT_APP_BASE_URL}/getAllFormalRequests?pageNo=${pageNo}&limit=${limit}&searchText=${searchText}&sortBy=${sortBy}&isSorted=${isSorted}&endDate=${endDate}&startDate=${startDate}&requestType=${requestType}&status=${status}&location=${location}`

	try {
		return await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Formal Request services : getAllFormalRequests() ', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getFormalRequestById = async (requestId, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getFormalRequestById/${requestId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Formal Request : getFormalRequestById()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};


export const updateFormalRequestStatus = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateFormalRequestStatus`, {
			method: 'PATCH',
			headers: {
                'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Formal Request services : updateFormalRequestStatus()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};