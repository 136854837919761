import React,{useEffect, useState} from "react";
import NumberedTextAreaView from './view';

function NumberedTextArea(props){
   const[value, setValue]=useState('');
   const[numberListLength, setNumberListLength]=useState(0);
   
   useEffect(()=>{
      setValue(props.value)
   },[props.value])

   const onChange=(value)=>{
     setValue(value) 
     setNumberListLength(value.split('\n').length)
     props.onChange(value)
   }
   return(
    <NumberedTextAreaView
       value ={value}
       onChange={onChange}
       numberListLength={numberListLength}
    />
   )

}

export default NumberedTextArea;