export const updatePendingIdProof = (value) => {
	return {
		type: 'UPDATE_PENDING_ID_PROOF',
		payload: {
			pendingIdProof: value,
		},
	};
};

export const updatePendingDoctorProof = (value) => {
	return {
		type: 'UPDATE_PENDING_DOCTOR_PROOF',
		payload: {
			pendingDoctorProof: value,
		},
	};
};

export const updatePendingPhotoProof = (value) => {
	return {
		type: 'UPDATE_PENDING_PHOTO_PROOF',
		payload: {
			pendingPhotoProof: value,
		},
	};
};
