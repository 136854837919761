import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';


import TextInputV2 from '@components/TextInputV2';
import PopUp from '@components/PopUp';
import PlusIcon from '@assets/common/plusIconGrey.png';
import ImageInput from '@components/ImageInput';
import SaveBtn from '@components/SaveBtn'

function DetailsView(props) {

    return(
        <div className='rightHandRightPageMainContainer flex-grow-1 gap-3'
			style={{ maxWidth: 'none' }}>
			<div className='topContainer'>
				<h4 className='heading'>Series Details</h4>
				<div>
					<Link
						style={{ textDecoration: 'none' }}
						className='cancelBtn'
						to='/content?tab=series'>
						Cancel
					</Link>
					<SaveBtn onClick={props.handleSave}
							  text={"Save"}
							  altText={"Saving..."}
							  disabled={props.isFormSaving}
						/>
				</div>
			</div>
			<div className='d-flex gap-3'style={{ maxWidth: '100%', borderTop: '1px solid #e5e5e5' }}>
            <div style={{width:'700px', marginLeft: '25px'}} className="courseDetails mt-4 d-flex gap-3 flex-column">
         
                <TextInputV2 id ={"displayName"} placeholder ={"Display Name"} required ={true}
                        value={props.seriesDetails?.title}
                        onChange={(event)=> props.handleChange(event.target.value, 'title')
                        }
                    />
                <TextInputV2 id ={"internalName"} placeholder ={"Internal Name"} required ={true}
                    value={props.seriesDetails?.internalTitle}
                    onChange={(event)=> props.handleChange(event.target.value, 'internalTitle')
                    }
                />
                <TextInputV2 id ={"mnemonic"} placeholder ={"Series Mnemonic"} required ={true}
                    value={props.seriesDetails?.mnemonic}
                    onChange={(event)=> props.handleChange(event.target.value, 'mnemonic')
                    }
                />
                <div className="mt-2">
                    <h4 className='subHeading'>
                        Series Thumbnail
                        <span className="inputSubTitle ms-1">Upload 1:1 aspect ratio only</span>
                    </h4>
                    <div className="imageCarousel">
                        <div className="addCarouselImage" 
                            style={{backgroundColor : 'white',
                                backgroundImage: props.seriesDetails?.thumbnailUrl != null ?
                                                typeof props.seriesDetails.thumbnailUrl === 'string' ? 
                                                `url(${props.seriesDetails.thumbnailUrl})`
                                                :`url(${URL.createObjectURL(props.seriesDetails.thumbnailUrl)})`
                                                :`url(${PlusIcon})`, 
                                height:'81px', width: '81px',
                                backgroundSize: props.seriesDetails?.thumbnailUrl? 'contain' : '',
                            }}
                            onClick={(event)=>props.handlePopUpOpen(event, 'imageUpload')
                            }
                        ></div>
                    </div>
                </div>

                <TextInputV2 id ={"validityDays"} placeholder ={"Series Validity Days"} required ={true}
                    value={props.seriesDetails?.validityDays}
                    onChange={(event)=> props.handleChange(event.target.value, 'validityDays')
                    }
                />
            </div>
            </div>
            <PopUp
                visible={props.isPopUpVisible && props.popUp=='imageUpload'}
                top={props.popUpPosition.top}
                left={props.popUpPosition.left}
                handleOutSideClick={()=>{}}>
                <div className="full-page-overlay">
                    <ImageInput
                        handleClose={props.handlePopUpClose}
                        handleAdd={(event, files)=>props.handleChange(files,'thumbnailUrl')} 
                    />
                </div>
            </PopUp>
        </div>
        )
}

export default DetailsView