import React, { useEffect, useState } from 'react';
import PopUpContainer from '@components/PopUpContainer';
import './style.css';

import TextInputV2 from '@components/TextInputV2';
import ImageInput from '@components/ImageInput';
import PopUp from '@components/PopUp';
import UploadIcon from '@assets/common/uploadIcon.png';

function NewIconPopUpView(props) {

	return (
		<PopUpContainer
           title={""}
           isSearchEnabled={false}
           searchPlaceholder={""}
           isButtonsEnabled={true}
           style={{width:'476px', height:'169px'}}
           handleSearch={props.handleSearch}
           handleCancel={props.handleCancel}
           handleSave={props.handleSave}
        >
            <div className='d-flex flex-grow-1 flex-column'>
            <div className='d-flex flex-row gap-1 justify-content-center align-items-center flex-grow-1'>
                <div className='border border-1 rounded-2 d-flex justify-content-center align-items-center' 
                 style={{height: '80px', width:'80px', border:'1px dashed grey',
                    backgroundImage: props.icon?.assetUrl != null ?
                                     typeof(props.icon.assetUrl) === 'string' ?
                                     `url(${props.icon.assetUrl})`:
                                     `url(${URL.createObjectURL(props.icon.assetUrl)})`:'',
                    backgroundSize: 'contain'  ,
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat'
                }}
                 onDoubleClick={props.handlePopUpOpen}
                 
                 >
                    <img src={UploadIcon} style={{height:"20px"}}/>
                </div>
                <div className='flex-grow-1'>
                <TextInputV2 
                    placeholder={"Icon name"}
                    value={props.icon?.title}
                    required={true}
                    onChange={(event)=>{props.handleChange(event.target.value, 'title')}}
                    size={'small'}
                />  
                </div>
            </div>
                <div>
                    <center>Upload SVG icon only<span style={{color:"red"}}>*</span></center>
                </div>
            </div>
            <PopUp visible={props.isPopUpVisible}
				top={'50px'}
				left={'50px'}
				handleOutSideClick={(props.handlePopUpClose)}> 
				<div className="full-page-overlay">
			    <ImageInput
				  handleClose={props.handlePopUpClose}
				  handleAdd={props.handlePopUpSave}          
                  folderType={"bulletIcons"}
				/>
				</div>
			</PopUp>

        </PopUpContainer>
	)
}

export default NewIconPopUpView;
