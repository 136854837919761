import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ConfirmDIalogBox(props) {
    const [inputValue, setInputValue] = useState('');

    const handleDownload = () => {
        props.download();
        props.onHide();
    }

    return (
        <div className='bg-white p-3 rounded-2'>
            
            <h1>Download Certificate and Address List</h1>
            
            <h4>Confirm Download!</h4>
            <p>
                Are you sure want to download the selected certificates and move it to <span style={{ color: '#157542', fontWeight: 'bold', fontStyle: 'italic' }}>"Delivered"</span> .
            </p>
            <p>
                Please enter <span style={{ color: '#157542', fontWeight: 'bold', fontStyle: 'italic' }}>"Download"</span> below to confirm action.
            </p>
            <input type="text" style={{ width: '80%' }} value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
        
            <div className='d-flex w-100 justify-content-end gap-2 mt-2'>
                <Button variant='secondary' onClick={props.onHide}>Cancel</Button>
                <Button variant='success' onClick={handleDownload} disabled={!(inputValue.toLowerCase() === "download")}>Download</Button>
            </div>
        </div>
    )
}
export default ConfirmDIalogBox;