import React, { useEffect, useState } from 'react';
import { useParams, defer } from 'react-router-dom';
import UserTestView from './view';

import * as TestServices from '@services/Tests';
import { decryptText } from '@library/enc-dec';

import { UserTestApiData } from './data';

import { navigationData } from './data';
import { useNavigate } from 'react-router-dom';
import ClipboardJS from 'clipboard';
import { produce } from 'immer';

import Swal from 'sweetalert2';
function UserTest(props) {	
	const navigate = useNavigate();
	var { id } = useParams();
	const [userData, setUserData] = useState({});
	const [deferredData, setDeferredData] = useState({});

	useEffect(() => {
		getUserTests();
	}, []);

	const getUserTests = async () => {
		try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			
			const payload={
					pageNo : 0,
					limit: 10,
					userId: id
			}

			var deferedResponse = defer({ res: TestServices.getUsersTestExamsByUserId(payload, authToken)})
			setDeferredData(deferedResponse)
			deferedResponse.data.res.then(response => {
				setUserData(response);
			})
			.catch(error => {
				console.log(error.message);
			});

		} catch (err) {
			console.log('Error coming while getUserWatchHistory()', err);
		}
	};

	
	const setDefaultNavigation = (defaultComponent) => {
		return navigationData.map((item) => {
			if (item.component === defaultComponent) {
				return { ...item, default: true };
			}
			return item;
		});
	};

	const handleRedirectToHome = (event, examId) => {
		if (examId === 'none') {
			navigate('/test', { state: { navigationData } });
		} else {
			navigate('/test/' + examId, { state: { navigationData } });
		}
	};

	const handleCopyClick = (textToCopy) => {
		const clipboard = new ClipboardJS('.clipboard', {
			text: () => textToCopy,
		});

		clipboard.on('success', (e) => {
			// setAlertVisible(true);
			// setTimeout(() => {
			// 	setAlertVisible(false);
			// }, 1000);
			e.clearSelection();
			clipboard.destroy();
		});

		clipboard.on('error', (e) => {
			console.error('Copy failed:', e.action);
			clipboard.destroy();
		});
	};

	const updateShowResultStatus =async(testId, updatedShowResult)=>{
		let authToken = decryptText(localStorage.getItem('aEmediat'));
			
		const payload={
			examDocId: testId,
			showResult: updatedShowResult
		}

		var response = await TestServices.updateShowResultStatus(payload, authToken)
		if(response.success)
		{
			let newUserData = produce(userData, draft => {
				draft.data.forEach(obj => {
					if (obj._id === testId) {
						obj.showResult = updatedShowResult; // Assign the new value
					}
				});
			});
		  setUserData(newUserData)
		  var deferredResponse = defer({res: newUserData});
		  setDeferredData(deferredResponse); 
		}else{
			Swal.fire({icon: "error",title: response.message,showConfirmButton: false,timer: 2500});
		}
	}

	return (
		<>
			<UserTestView 
			  userData={userData} 
			  deferredData = {deferredData}
			  updateShowResultStatus={updateShowResultStatus}
			  handleRedirectToHome={handleRedirectToHome}
			  handleCopyClick ={handleCopyClick}
			  />
		</>
	);
}

export default UserTest;
