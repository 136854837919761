import React, { useEffect, useState, useRef } from 'react';

import QuestionView from './view';
import { getEventElementPosition} from '@helpers/common.js';
import * as ExamServices from '@services/Exam';
import { decryptText } from '@library/enc-dec';

function Question(props) {
	
	const [popUpPosition, setPopUpPosition] = useState({});
	const [isPopUpVisible, setIsPopUpVisible] = useState(false);
	const { questionContent, onValueChange, onDeleteRequest } = props;
	const mainQuestionDivRef = useRef(null);

	const [questionId, setQuestionId] = useState(0);
	const [question, setQuestion] = useState('');
	const [thumbnailUrl, setThumbnailUrl] = useState('');
	const [option_1, setOption_1] = useState('');
	const [option_2, setOption_2] = useState('');
	const [option_3, setOption_3] = useState('');
	const [option_4, setOption_4] = useState('');
	const [answer, setAnswer] = useState('');
	// const [thumbnailUrl, setThumbnailUrl] = useState('');
	// const [thumbnailName, setThumbnailName] = useState('');
	const [currentQuestion, setCurrentQuestion] = useState({});

	useEffect(() => {
		init(questionContent);
		setCurrentQuestion(questionContent);
	}, [questionContent]);

	const init = (questionContent) => {
		setQuestionId(questionContent._id);
		setQuestion(questionContent.question);
		setThumbnailUrl(questionContent.thumbnailUrl);
		setOption_1(questionContent.options[0]);
		setOption_2(questionContent.options[1]);
		setOption_3(questionContent.options[2]);
		setOption_4(questionContent.options[3]);
		setAnswer(questionContent.answer);
		// setThumbnailUrl(questionContent.thumbnailUrl);
		// setThumbnailName(questionContent.thumbnailUrl);
	};

	const handleQuestion = (value) => {
		let localCurrentQuestion = {...currentQuestion};
		localCurrentQuestion['question'] = value;
		setCurrentQuestion(localCurrentQuestion);

		setQuestion(value);
		sendValuesOutside(localCurrentQuestion);
	};
	const handleThumbnail = (value) => {
		let localCurrentQuestion = {...currentQuestion};
		localCurrentQuestion.thumbnailUrl = value;
		setCurrentQuestion(localCurrentQuestion);

		setThumbnailUrl(value);

		setTimeout(() => {
			
		sendValuesOutside(localCurrentQuestion);;
		}, 500);
	};

	const handleOption = (event, index) => {
		let localCurrentQuestion = {...currentQuestion};
		localCurrentQuestion.options[index] = event.target.value;
		setCurrentQuestion(localCurrentQuestion);
		switch (index) {
			case 0:
				setOption_1(event.target.value);
				break;
			case 1:
				setOption_2(event.target.value);
				break;
			case 2:
				setOption_3(event.target.value);
				break;
			case 3:
				setOption_4(event.target.value);
				break;
			default:
				break;
		}

		
		sendValuesOutside(localCurrentQuestion);;
	};

	const handleOptionRadioBtn = (event, selectedOption) => {
		let localCurrentQuestion = currentQuestion;
		localCurrentQuestion.answer = selectedOption;
		setCurrentQuestion(localCurrentQuestion);
		setAnswer(selectedOption);

		
		sendValuesOutside(localCurrentQuestion);;
	};


	const convertToBase64 = (event) => {
		let localCurrentQuestion = currentQuestion;

		// setThumbnailUrl(event.target.files[0]);
		// setThumbnailName(event.target.files[0].name);

		// localCurrentQuestion.thumbnailUrl = event.target.files[0];
		// localCurrentQuestion.thumbnailName = event.target.files[0].name;
		setCurrentQuestion(localCurrentQuestion);

		
		sendValuesOutside(localCurrentQuestion);;
	};

	const handleDelete = (event, questionId) => {
		onDeleteRequest(questionId);
	};

	const sendValuesOutside = (question) => {
		onValueChange(question);
	};

	const validateInputs = () => {
		if (
			!currentQuestion.question ||
			currentQuestion.options.length === 0 ||
			!currentQuestion.answer ||
			!currentQuestion.thumbnailUrl
		) {
			return false;
		} else {
			return true;
		}
	};

	// To close the popup container
	const handlePopUpClose = () => {
		setIsPopUpVisible(false);
	};
	// To handleSave buttons on the popup
	const handlePopUpSave = async () => {
		handlePopUpClose();
	};
	// Open popUps
	const handleOpenPopUp = (event, popUp) => {
		handlePopUpClose();
		setIsPopUpVisible(true)
		let position = getEventElementPosition(event);
		setPopUpPosition(position);
		event.stopPropagation();
	};

	const handleImageInput= async(event, files)=>{
		handlePopUpClose();
		if(!files[0]){
			return
		}
		if(typeof files[0] === 'string')
		{
            handleThumbnail(files[0]) 
			return
		}
	   try {
		const payload={
			thumbnailUrl : files[0],
			questionBankType: 'mainQuestionBank'

		}
		let authToken = decryptText(localStorage.getItem('aEmediat'));
		const response = await ExamServices.uploadQuestionThumbnail(payload,authToken);
		if (response.success) {
			handleThumbnail(response.data);
		}
		} catch (err) {
			console.log('Error coming while updating thumbnail', err);
		}
	}

	function isValidURL(url) {
		try {
		  new URL(url);
		  return true;
		} catch (error) {
		  return false;
		}
	  }

	const handleRemoveQuestion=()=>{
		handleThumbnail('')
	}
	return (
		<>
			<QuestionView
				mainQuestionDivRef={mainQuestionDivRef}
				handleQuestion={handleQuestion}
				handleOption={handleOption}
				convertToBase64={convertToBase64}
				handleOptionRadioBtn={handleOptionRadioBtn}
				handleDelete={handleDelete}
				questionId={questionId}
				question={question}
				thumbnailUrl={thumbnailUrl}
				option_1={option_1}
				option_2={option_2}
				option_3={option_3}
				option_4={option_4}
				answer={answer}
				
				
				handlePopUpClose={handlePopUpClose}
				handlePopUpSave={handlePopUpSave}
				handleOpenPopUp={handleOpenPopUp}
				popUpPosition={popUpPosition}
				isPopUpVisible={isPopUpVisible}

				handleImageInput={handleImageInput}
				isValidURL={isValidURL}
				handleRemoveQuestion={handleRemoveQuestion}
			/>
		</>
	);
}

export default Question;
