import React, {useRef, useState, useEffect} from "react";

import DynamicTabsView from './view';

function DynamicTabs(props){
  const containerRef = useRef(null);
  const [showButtons, setShowButtons] = useState(false);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 100; // Adjust scroll amount as needed
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 100; // Adjust scroll amount as needed
    }
  };

  const handleShowButtons=(value)=>{
    if(value){
      setShowButtons(containerRef.current.scrollWidth > containerRef.current.clientWidth)
    }
    else{
      setShowButtons(value)
    }


  }
    return(
        <>
          <DynamicTabsView
            props = {props}
            containerRef={containerRef}
            scrollLeft={scrollLeft}
            scrollRight={scrollRight}
            setShowButtons={handleShowButtons}
            showButtons={showButtons}
           />
        </>
    )
}

export default DynamicTabs;