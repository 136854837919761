const initialState = {
	allComments: [],
};

const CommentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_ALL_COMMENTS':
			return {
				...state,
				allComments: action.payload.allComments,
			};
		default:
			return state;
	}
};

export default CommentsReducer;
