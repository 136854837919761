import React, { useEffect, useState, useRef  } from 'react';

import ExamStatusPopUpView from './view';

import * as UsersServices from '@services/Users';
import * as ExamServices from '@services/Exam';
import { decryptText } from '@library/enc-dec';
import Swal from 'sweetalert2';

function ExamStatusPopUp(props) {
	const[allExams, setAllExams]=useState([]);
	const[selectedExam, setSelectedExam]=useState('');
	const [newExamStatus, setNewExamStatus] = useState(null);
	const [disableSaveBtn, setDisableSaveBtn] = useState(true);
	const [inputPercentage, setInputPercentage] = useState('');
	const [showResultCheckBox, setShowResultCheckBox] = useState(false);
	
	useEffect(() => {
		getAllExams()
	}, []);
	
	const getAllExams = async()=>{
		try{
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const payload={
				pageNo: 0,
				limit: 10000
			}
			const response = await ExamServices.getAllQuestionBanks(payload, authToken);
			setAllExams(response.data)		
		}
		catch(err){
          console.log("Error coming while getting exams", err)
		}
	}

	const handleExamStatusRadioBtns = (event, value) => {
		setNewExamStatus(value);
		setDisableSaveBtn(false);
	};

	// To close the exam status popup container
	const handleCancelBtn = () => {
			setInputPercentage('');
			setNewExamStatus(null);
			setShowResultCheckBox(false);		
			setDisableSaveBtn(true);
			props.handleCancel() 
	};

	// Handle exam status save button
	const handleSaveBtn = async () => {		
		let authToken = decryptText(localStorage.getItem('aEmediat'));
		let payload = {
			purchaseIds: props.purchaseIds,
			examStatus: newExamStatus,
			percentage: inputPercentage,
		};

		const response = await UsersServices.updateExamStatusForUser(payload, authToken);
		
		if(response.success)
		{
			Swal.fire({icon: "success",title: `Exam status updated`,showConfirmButton: false,timer: 2500});	
			
			handleCancelBtn();
			props.handleSave();	
		}
		else{
			Swal.fire({icon: "error",title: response.message,showConfirmButton: false,timer: 2500});
		}
	};

	return (
		<ExamStatusPopUpView
				allExams={allExams}
				handleSelectedExam ={setSelectedExam}
				props = {props} 
				newExamStatus = {newExamStatus}
				disableSaveBtn = {disableSaveBtn}
				handleExamStatusRadioBtns = {handleExamStatusRadioBtns}
				inputPercentage = {inputPercentage}
				handleInputPercentage = {setInputPercentage}
				showResultCheckBox = {showResultCheckBox}
				handleShowResultCheckBox = {setShowResultCheckBox}
				handleCancelBtn = {handleCancelBtn}
				handleSaveBtn  = {handleSaveBtn}
			/>
	);
}

export default ExamStatusPopUp;
