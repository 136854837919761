import React from "react";
import { Link } from "react-router-dom";
import DropDownIcon from "@assets/common/dropDownArrowIcon.png";
import SearchIcon from "@assets/common/search.png";

import "./courseCategoryDetails.css";

import GrayInfoIcon from "@assets/common/grayInfoIcon.png";
import RoundedPlusIcon from "@assets/common/roundedPlusIcon.png";

import BulletSelector from "@components/BulletSelector";
import SaveBtn from "@components/SaveBtn";

function CourseCategoryDetailsView(props) {
  return (
    <>
      <div className="rightHandRightPageMainContainer d-flex flex-column">
        <div className="topContainer" style={{ width: "800px" }}>
          <h4 className="heading">Category Details</h4>
          <div>
            <Link
              style={{ textDecoration: "none" }}
              className="cancelBtn"
              to="/content?tab=courseCategory"
            >
              Cancel
            </Link>

            <SaveBtn
              onClick={props.handleSave}
              text={"Save"}
              altText={"Saving..."}
              disabled={props.isFormSaving}
            />
          </div>
        </div>
        <div
          className="flex-grow-1 w-100  border-top"
          style={{ overflow: "scroll" }}
        >
          <div
            className="marginLeftContainer"
            style={{ width: "800px", marginTop: "15px" }}
          >
            {/* START : Error container */}
            {props.formValuesEmpty && (
              <div className="incompleteFieldsError">
                <span>{props.errorText}</span>
              </div>
            )}
            {/* END : Error container */}

            {/* Category title */}
            <div>
              <input
                onChange={(event) => {
                  props.handleCategoryTitle(event);
                }}
                value={props.categoryTitle}
                type="text"
                className="form-control inputBox"
                placeholder="Category title"
              />
            </div>
            <br />

            {/* Category Description */}
            <div>
              <textarea
                onChange={(event) => {
                  props.handleCategoryDescription(event);
                }}
                value={props.categoryDescription}
                className="form-control inputBox"
                placeholder="Category Description"
                rows="9"
              ></textarea>
            </div>
            <br />

            {/* Category bullets */}
            <h4 className="subHeading">Category bullets</h4>

            <div className="bulletPointsContainer row">
              {props.pointers && props.pointers.length === 0 ? (
                <div
                  className="bulletPointsInputContainer"
                  id="bulletPointsInputContainer"
                >
                  <BulletSelector
                    bulletSelectorId={1}
                    onValueChange={props.handleBulletIconChange}
                  />
                  <input
                    id="bulletPoint_1"
                    type="text"
                    className="form-control inputBox"
                    placeholder="Bullet Point"
                    onChange={props.handleBulletPointerChange}
                  />
                </div>
              ) : (
                <div
                  className="bulletPointsInputContainer"
                  id="bulletPointsInputContainer"
                >
                  {props.pointers.map((item, index) => (
                    <>
                      <BulletSelector
                        key={index + 1}
                        bulletSelectorId={index + 1}
                        onValueChange={props.handleBulletIconChange}
                        icon={item.icon}
                      />
                      <input
                        key={index + 1}
                        id={"bulletPoint_" + (index + 1)}
                        type="text"
                        className="form-control inputBox"
                        placeholder="Bullet Point"
                        value={item.pointer}
                        onChange={(event) =>
                          props.handleBulletPointerChange(event, index)
                        }
                      />
                    </>
                  ))}
                </div>
              )}

              <div
                onClick={(event) => {
                  props.handleAddBulletPoint(event);
                }}
                className="bulletPointsIconContainer"
              >
                <img src={RoundedPlusIcon} alt="RoundedPlusIcon" />
              </div>
            </div>
          </div>

          <hr />

          <div
            className="marginLeftContainer"
            style={{ marginTop: "15px", width: "800px" }}
          >
            <h4 className="subHeading">Preview Video</h4>

            <div>
              <input
                onChange={props.handleCategoryVideoUrl}
                value={props.categoryVideoUrl}
                type="text"
                className="form-control inputBox"
                placeholder="Paste YouTube Video link here"
              />
            </div>

            <p className="infoNote">
              <img src={GrayInfoIcon} alt="GrayInfoIcon" />
              <b>Preview Video</b> for the entire category
            </p>
          </div>

          <hr />
          <div
            className="marginLeftContainer"
            style={{ marginTop: "15px", paddingBottom: "10%", width: "800px" }}
          >
            <h4 className="subHeading">Add Course(s) to Category</h4>

            <p>
              Type specimen book. It has survived not only five centuries, but
              also
            </p>

            <div
              className="dropDown selectCourseContainer w-100"
              style={{ border: "1px solid #ccc" }}
            >
              <span
                onClick={() => {
                  props.handleDropDownVisibility(true);
                }}
                className="w-100"
              >
                {props.listOfSearchedCourses &&
                  props.listOfSearchedCourses
                    .filter((course) =>
                      props.selectedCourses.includes(String(course["_id"]))
                    )
                    .map((course) => course.mnemonic)
                    .join(" + ")}
              </span>
              <img
                img
                alt="DropDownIcon"
                src={DropDownIcon}
                onClick={() => {
                  props.handleDropDownVisibility(true);
                }}
              ></img>
              {/* Hidden Dropdown */}
              <div
                className="selectList absolutePos bg-white border rounded shadow"
                style={
                  props.isDropDownVisible
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div className="inputDiv searchDiv w-100">
                  <input
                    type="text"
                    onChange={(event) => {
                      props.handleSearchText(event.target.value);
                    }}
                  ></input>
                  {props.searchext}
                  <img alt="DropDownIcon" src={SearchIcon} />
                </div>
                <div className="selectionList">
                  {/**Repeat this for multiple options */}
                  {props.listOfSearchedCourses != undefined &&
                    props.searchText != undefined &&
                    props.listOfSearchedCourses.map((course) =>
                      course["title"]
                        .toLowerCase()
                        .includes(props.searchText.toLowerCase()) ? (
                        <div
                          onClick={() => {
                            props.handleApplicableCourses(course["_id"]);
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={props.selectedCourses.includes(
                              String(course["_id"])
                            )}
                          ></input>
                          <span>{course["title"]}</span>
                        </div>
                      ) : null
                    )}
                </div>
                <hr />
                <div
                  className="selectListFooter"
                  onClick={() => {
                    props.handleDropDownVisibility();
                  }}
                >
                  <span>Done</span>
                </div>
              </div>
            </div>

            <div className="visibilityContainer">
              <p>
                Visibility<span style={{ color: "red" }}>*</span>{" "}
              </p>

              <div className="form-check">
                <input
                  onChange={(event) => {
                    props.handleVisibilityRadioBtn(event, "unlisted");
                  }}
                  checked={!props.isPublic}
                  className="form-check-input"
                  type="radio"
                  name="visibility"
                  id="unlisted"
                />
                <label htmlFor="unlisted">Unlisted</label>
              </div>

              <div className="form-check">
                <input
                  onChange={(event) => {
                    props.handleVisibilityRadioBtn(event, "public");
                  }}
                  checked={props.isPublic}
                  className="form-check-input"
                  type="radio"
                  name="visibility"
                  id="public"
                />
                <label htmlFor="public">Public</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CourseCategoryDetailsView;
