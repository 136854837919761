import React, { useEffect, useState, useRef  } from 'react';
import PopUpContainer from '@components/PopUpContainer';

import * as BSSServices from '@services/BSS';
import { decryptText } from '@library/enc-dec';
import Swal from 'sweetalert2';

function CreateBssFormsPopUp(props) {
	const [formalRequestText, setFormalRequestText]=useState('')
	const [allBssCourses, setAllBssCourses] = useState([])
	const [allBssInstitutes, setAllBssInstitutes] = useState([])

	const [selectedBssCourse, setSelectedBssCourse] = useState('')
	const [selectedBssInstitute, setSelectedBssInstitute] = useState('')
	const [toYear, setToYear] = useState('')
	const [fromYear, setFromYear] = useState('')

	useEffect(()=>{
		getAllBssCourses()
		getAllBssInstitute()
	},[])

	const getAllBssInstitute=async()=>{
        try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			let payload = {pageNo : 0,
                            limit : 9999999,
                            isSorted: true,
                            sortBy : 'createdAt',
                            searchText :'',
							visibility: 'active'
                        };

            const response = await BSSServices.getAllInstitutes(payload, authToken);
			if(response.success){
				setAllBssInstitutes(response.data)
				 return
			}
			Swal.fire({icon: "error",title: response.message,showConfirmButton: false,timer: 2500});
			props.handlePopUpClose()
			
		} catch (err) {
			console.log('Error coming while getting all BSS Forms', err);
		}
    }
	const getAllBssCourses=async()=>{
        try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			let payload = {pageNo : 0,
				limit : 9999999,
				isSorted: true,
				sortBy : 'createdAt',
				searchText :'',
				visibility: 'active'
			};
            const response = await BSSServices.getAllBssCourses(payload, authToken);
			if(response.success){
				setAllBssCourses(response.data)
				 return
			}
			Swal.fire({icon: "error",title: response.message,showConfirmButton: false,timer: 2500});
			props.handlePopUpClose()
			
		} catch (err) {
			console.log('Error coming while getting all BSS Forms', err);
		}
    }
	

	const handleSave=()=>{
		if(props.isFormalRequest && formalRequestText.length<100)
		{
		 Swal.fire({icon: "info",title: "Please add request description of minimum 100 characters",showConfirmButton: false,timer: 2500});
		 return   
		}
		 props.handlePopUpSave({selectedBssCourse,selectedBssInstitute,formalRequestText, fromYear, toYear})
	 }

	return (
		<PopUpContainer
						title={"Create BSS course(s)"}
						isSearchEnabled={false}
						searchPlaceholder={""}
						isButtonsEnabled={true}
						style={{width:'431px', height: props.isFormalRequest?'466px':'276px'}}
						handleSearch={()=>{}}
						handleCancel={props.handlePopUpClose}
						handleSave={handleSave}
						>
					<div className='d-flex flex-column gap-2'>
						<select className="w-100 form-control form-control-sm" onChange={(event) => {setSelectedBssInstitute(event.target.value)}}
							value={selectedBssInstitute}>
								<option value={''}>Select BSS Institute<span className='text-danger'>*</span></option>
							{allBssInstitutes && allBssInstitutes.map((obj, index)=>{
										return <option key={index} value={obj._id}> {obj.name}</option>
							})}
							
						</select>
						<select className="w-100 form-control form-control-sm" onChange={(event) => {setSelectedBssCourse(event.target.value)}}
							value={selectedBssCourse} >
								<option value={''}>Select BSS Course<span className='text-danger'>*</span></option>
							{allBssCourses && allBssCourses.map((course, index)=>{
										return <option key={index} value={course._id}> {course.title}</option>
							})}
							
						</select>
						<div className='text-muted'>
						   Enter 'from' and 'to' year only if you want to override current academic year
						</div>
						<div className='d-flex gap-2'>
							<input type='number' className='form-control form-control-sm'
							  value={fromYear} placeholder='From Year'
							  onChange={(e)=>setFromYear(e.target.value)}
							  
							/>
							<input type='number' className='form-control form-control-sm'
							  value={toYear} placeholder='To Year'
							  onChange={(e)=>setToYear(e.target.value)}
							/>
						</div>
						{props.isFormalRequest &&
						<div className='border-top d-flex flex-column gap-2 py-2'>
							<div className='d-flex'>
								Submit formal request
								<span className='ms-auto text-muted'>Minimum 100 characters*</span>
							</div>
							
							<div className="form-floating" >
								<textarea className="form-control" placeholder="" id="cardDesc" style={{height:'158px'}}   
									value={formalRequestText}
									onChange={(e)=>setFormalRequestText(e.target.value)}
							></textarea>
								<label htmlFor="cardDesc">Request will be sent to administrative team for approval<span>*</span></label>
							</div>
						</div>}
						</div>
				</PopUpContainer>
	);
}

export default CreateBssFormsPopUp;
