import React from 'react';
import moment from 'moment';

import './faq.css';

import PlusIcon from '@assets/common/plusIconBlue.png';
import DeleteIcon from '@assets/common/deleteIcon.png';

import EditPencilIcon from '@assets/common/editPencilIcon.png';

import BasicTableGrid from '@components/BasicTableGrid';
import DateAndUser from '@components/DateAndUser';
import Search from '@components/Search';

function FAQView(props) {
	return (
		<>
			<div className='Xcontainer_1 pb-2'>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<button
						onClick={(event) => {
							props.handleRedirectToHome(event, 'none');
						}}
						className='simpleBtn'
						style={{ marginRight: '10px' }}>
						<img src={PlusIcon} alt='PlusIcon' style={{width:'12px', marginRight:'5px'}}/>
						Add FAQ
					</button>

					<button
						onClick={props.handleDeleteBulk}
						style={{ cursor: props.disableBulkActionBtn ? 'not-allowed' : 'pointer' }}
						disabled={props.disableBulkActionBtn}
						className='deleteXBtn'>
						<img src={DeleteIcon} alt='DeleteIcon' />
					</button>
				</div>
			</div>
			<Search
			    value={props.searchText}
				placeholder = {"Search faq"}
				onSearch ={(data)=>props.handleSearch(data)}
			>
			<div>
				No Filters Added
			</div>
			</Search>
			<BasicTableGrid 
				gridId='faqs'
				data={props.deferedData} // Data to be displayed
				initialSize={[35,677,351,332]}
				initialSorting={[{"id": props.sortBy === 'createdAt'? "Date Added":"Date Modified","desc": props.isSorted}]}
				fixedCols={2} // How many columns are fixed and not scrolling
				handleSelect={(selectedRows) => {
					props.handleCheckBoxes(selectedRows);
				}}
				length={20}
				// pagination={props.pagination}
				// setPagination={props.setPagination}
				onSortChange={(data) => props.handleSort(data)
				}
				columnsOptionPopUpRef={props.columnsOptionPopUpRef}
				columns={[
				{header: 'Title',
					cell: (data) => {
					const info = data.row.original;
					return (
						<div className="d-flex gap-2">
							<div className="d-flex flex-column justify-content-center gap-2 ms-2">
								<div 
								// onClick = {(event)=>{props.handleTitleClick(event, info)}}
								>
									{info?.title} 
								</div>
								<div className='hiddenDiv d-flex gap-2'>
									<img
										onClick={(event) => {
											props.handleRedirectToHome(event, info._id, 'CourseDetails');
										}}
										src={EditPencilIcon}
										alt='EditPencilIcon'
									/>
								</div>
							</div>
							
						</div>
					);
					},
				},
				{header: 'Date Added', accessorFn: (row) => {},
					cell: (data) => {
						const info = data.row.original;
						return <DateAndUser
						date = {info.createdAt}
						image = {info.createdUserDetails?.displayPhoto}
						imageText = {info.createdUserDetails?.displayName}/>
					},
			    },
				{header: 'Date Modified', accessorFn: (row) => {}, 
				cell: (data) => {
					const info = data.row.original;
					return (<DateAndUser
						date = {info.updatedAt}
						image = {info.lastModifiedUserDetails?.displayPhoto}
						imageText = {info.lastModifiedUserDetails?.displayName}/>
					) 
				},
			    }
				]}
				/>



		</>
	);
}

export default FAQView;
