import React from 'react';

import './style.css';

function EditMnemonicPopUpView(props) {
	return (
		<div className="editMnemonicDiv">
			<span>Edit mnemonic</span>

            <input type="text" value={props.mnemonic} onChange={props.handleMnemonic}></input>
			
			<div className="hr"/>

            <div className="d-flex justify-content-end gap-1">
			{props.error && props.error !== '' && (
				<div className='alert alert-danger w-50'>
					{props.error}
				</div>
			)}

				<button onClick={props.handleCloseBtn} className='closeBtn'>
					Cancel
				</button>
				<button onClick={props.handleSaveBtn} className='saveBtn'>
					Save
				</button>
            </div>



		</div>
	);
}

export default EditMnemonicPopUpView;
