export const getAllFaqs = async (payload, authToken) => {
	
	const {pageNo, limit, isSorted, sortBy, searchText} = payload
	const url = `${process.env.REACT_APP_BASE_URL}/getAllFaqs?pageNo=${pageNo}&limit=${limit}${searchText? '&searchText='+searchText: ''}${sortBy? '&sortBy='+sortBy: ''}${isSorted? '&isSorted='+isSorted: ''}`

	try {
		return await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from FAQ services : getAllFaqs() ', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getFaq = async (faqDocId, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getFaqById/${faqDocId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from FAQ services: getFaqById() ', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateFaq = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateFaq`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from FAQ services : updateFaq()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const addFaq = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/addFaq`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from FAQ services : addFaq()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const deleteFaqs = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/deleteFaqs`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from FAQ services : deleteFaqs()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
