export const login = async (email, password) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: email,
				password: password,
			}),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Login services :', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getUser = async (authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getUser`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken
			},
			
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Login services :', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

