import React, { useEffect, useState } from 'react';
import PopUpContainer from '@components/PopUpContainer';
import './style.css';

function ChangeCoursePopUpView(props) {
   
	return (
		<PopUpContainer
           title={"Add/Change Course"}
           isSearchEnabled={true}
           searchPlaceholder={"Search course name"}
           isButtonsEnabled={true}
           style={{width:'368px', height:'255px'}}
           handleSearch={props.handleSearch}
           handleCancel={props.handleCancel}
           handleSave={props.handleSave}
        >
            <div className='d-flex flex-column gap-2 flex-grow-1' style={{overflow:'scroll'}}>
                {props.allCourses?.map((course, index)=>{
                   if(course.title?.toLowerCase().includes(props.searchValue?.toLowerCase()))
                   {
                    return (<div key={index} className='d-flex gap-2'>
                                <input type='radio' name="course" 
                                checked={props.selectedCourse?._id === course?._id}
                                onChange={(event)=>props.handleCourseSelect(course)}>
                                </input>{course.title}
                            </div>) 
                    }
                })}
            </div>
        </PopUpContainer>
	)
}

export default ChangeCoursePopUpView;
