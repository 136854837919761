import React, { useEffect, useState, useRef } from "react";

import CourseDetailsView from "./view";
import { useParams, useNavigate } from "react-router-dom";
import * as CoursesServices from "@services/Course";
import * as CategoriesServices from "@services/Category";
import * as FAQServices from "@services/FAQ";
import * as ExamServices from "@services/Exam";
import * as BssServices from "@services/BSS";
import * as QualificationServices from "@services/Qualifications";

import { decryptText } from "@library/enc-dec";

import { navigationData } from "./data";

import { responseData, paymentOptions, qualifications } from "./data";
import Swal from "sweetalert2";

var editorTextEdit = false;

function CourseDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [courseDetails, setCourseDetails] = useState({});
  const [categories, setCategories] = useState([]);
  const [listOfSearchedCategories, setListOfSearchedCategories] = useState({});

  const [paymentGateways, setPaymentGateways] = useState([]);
  const [allQualifications, setAllQualifications] = useState({});

  const [FAQS, setAlFAQS] = useState([]);
  const [questionBanks, setQuestionBanks] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [allBSSCourses, setAllBSSCourses] = useState([]);
  const [allBSSInstitutes, setAllBSSInstitutes] = useState([]);
  const [newCourseAdded, setNewCourseAdded] = useState(false);
  const [isFormSaving, setIsFormSaving] = useState(false);

  useEffect(() => {
    getCourseDetails();
    getCoursesCategories();
    getAllFaqs();
    getAllQuestionBanks();
    getAllBSSCourses();
    getAllBSSInstitutes();
    getPaymentGateways();
    getAllQualifications();
  }, []);

  useEffect(() => {
    if (newCourseAdded) {
      getCourseDetails();
      getCoursesCategories();
      getAllFaqs();
      getAllBSSCourses();
      getAllBSSInstitutes();
      getAllQuestionBanks();
      setNewCourseAdded(false);
    }
  }, [newCourseAdded]);

  const getCourseDetails = async () => {
    try {
      if (id) {
        let authToken = decryptText(localStorage.getItem("aEmediat"));
        let response = await CoursesServices.getCourseById(id, authToken);
        if (response.success) {
          setCourseDetails(response.data);
          setSelectedCategories(response.data.category);
          return;
        }
      }

      // New Course will add
      setCourseDetails(responseData);
    } catch (err) {
      console.log("Error coming while fetching course details", err);
    }
  };

  const getAllFaqs = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = { pageNo: 0, limit: 10000, isSorted: false, sortby: "" };
      let response = await FAQServices.getAllFaqs(payload, authToken);
      if (response.success) {
        setAlFAQS(response.data);
        return;
      }
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (err) {
      console.log("Error coming while fetching course details", err);
    }
  };

  const getAllBSSCourses = async () => {
    try {
      const authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        pageNo: 0,
        limit: 10000,
        visibility: "active",
      };

      const response = await BssServices.getAllBssCourses(payload, authToken);
      if (response.success) {
        setAllBSSCourses(response.data);
        return;
      }
    } catch (err) {
      console.log("Error coming while fetching all bss courses", err);
    }
  };

  const getAllBSSInstitutes = async () => {
    try {
      const authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        pageNo: 0,
        limit: 10000,
        visibility: "active",
      };

      const response = await BssServices.getAllInstitutes(payload, authToken);
      if (response.success) {
        setAllBSSInstitutes(response.data);
        return;
      }
    } catch (err) {
      console.log("Error coming while fetching all institutes", err);
    }
  };

  const getAllQuestionBanks = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        pageNo: 0,
        limit: 10000,
      };
      let response = await ExamServices.getAllQuestionBanks(payload, authToken);
      if (response.success) {
        setQuestionBanks(response.data);
        return;
      }
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (err) {
      console.log("Error coming while fetching course details", err);
    }
  };

  const getPaymentGateways = async () => {
    setPaymentGateways(paymentOptions);
  };
  const getAllQualifications = async () => {
    try {
      const payload = {
        pageNo: 0,
        limit: 10000,
        visibility: "active",
      };
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await QualificationServices.getAllQualifications(
        payload,
        authToken
      );

      if (response.success) {
        var updatedQualifications = {};
        response.data.map((item) => {
          updatedQualifications[item.profession] = item.qualifications;
        });

        setAllQualifications(updatedQualifications);
        return;
      }
      setAllQualifications(qualifications);
    } catch (err) {
      console.log("Error coming while fetching course details", err);
    }
  };

  const getCoursesCategories = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        pageNo: 0,
        limit: 10000,
      };
      let response = await CategoriesServices.getAllCategories(
        payload,
        authToken
      );
      if (response.success) {
        setCategories(response.data);
        setListOfSearchedCategories(response.data);
        return;
      }
    } catch (err) {
      console.log("Error coming while fetching course details");
    }
  };

  const handleSave = async () => {
    setIsFormSaving(true);
    if (detailTabSelected && isValidCourseDetails) {
      saveDetailTab();
    }
    if (cardTabSelected && isValidCourseDetails) {
      saveCardTab();
    }
    if (coverTabSelected && isValidCourseDetails) {
      saveCoverTab();
    }
    if (productPageTabSelected && isValidCourseDetails) {
      saveProductTab();
    }
    if (bssFormTabSelected && isValidCourseDetails) {
      {
        saveBssFormTab();
      }
    }
  };

  const saveDetailTab = async () => {
    try {

      let fieldCheckMessage = false;
        if(courseDetails.showImageCarousel && 
          courseDetails.imageCarousel?.length === 0 
          && !courseDetails.imageCarouselFiles
        ){
          fieldCheckMessage = "Please add image carousel images";
        }

         if(fieldCheckMessage) 
        {
          Swal.fire({
            icon: "warning",
            title: fieldCheckMessage,
            showConfirmButton: false,
            timer: 2500,
          });
          setIsFormSaving(false);
          return
        }

      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        courseId: id ? id : null,
        title: courseDetails.title,
        internalTitle: courseDetails.internalTitle,
        mnemonic: courseDetails.mnemonic,
        visibility: courseDetails.visibility,
        examId: courseDetails.examId,
        paymentGateways: courseDetails.paymentGateways.map((obj) =>
          (({ _id, ...rest }) => rest)(obj)
        ),
        imageCarousel: courseDetails.imageCarousel,
        imageCarouselFiles: courseDetails.imageCarouselFiles,
        seriesWatchedPercentage: courseDetails.seriesWatchedPercentage,
        exemptedDays: courseDetails.exemptedDays,
        tags: courseDetails.tags,
        eligibleExams: courseDetails.eligibleExams,
        eligibleCertificates: courseDetails.eligibleCertificates,
        allowToCart: courseDetails.allowToCart,
        isLegalInfoRequired: courseDetails?.isLegalInfoRequired,
        isIdVerificationRequired: courseDetails?.isIdVerificationRequired,
        isAddressProofRequired: courseDetails?.isAddressProofRequired,
        showImageCarousel:courseDetails?.showImageCarousel? true:false
      };

      const response = await CoursesServices.updateCourseBasicDetails(
        payload,
        authToken
      );
      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        navigate(`/course/${response.data._id.toString()}`, {
          state: { finalNavigationData: navigationData },
        });
        setIsFormSaving(false);
        return;
      }

      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
      setIsFormSaving(false);
      return;
    } catch (err) {}
  };
  const saveCardTab = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      if (id) {
        let payload = {
          courseId: id,
          cardTitle: courseDetails.cardTitle,
          cardDescription: courseDetails.cardDescription,
          cardPointers: courseDetails.cardPointers,
          cardIcons: courseDetails.cardIcons,
          cardBackgroundColor: courseDetails.cardBackgroundColor,
          squareThumbnailUrl: courseDetails.squareThumbnailUrl,
          rectangleThumbnailUrl: courseDetails.rectangleThumbnailUrl,
        };

        const response = await CoursesServices.updateCourseCardDetails(
          payload,
          authToken
        );
        if (response.success) {
          Swal.fire({
            icon: "success",
            title: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
          setIsFormSaving(false);
          return;
        }
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        setIsFormSaving(false);
        return;
      }
    } catch (err) {}
  };
  const saveCoverTab = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      if (id) {
        let payload = {
          courseId: id,
          coverTitle: courseDetails.coverTitle,
          coverDescription: courseDetails.coverDescription,
          coverPointers: courseDetails.coverPointers ? courseDetails.coverPointers : {icon:"", pointer:""}, 
        };

        const response = await CoursesServices.updateCourseCoverDetails(
          payload,
          authToken
        );
        if (response.success) {
          Swal.fire({
            icon: "success",
            title: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
          setIsFormSaving(false);
          return;
        }
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        setIsFormSaving(false);
        return;
      }
    } catch (err) {}
  };

  const saveProductTab = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      if (id) {
        let fieldCheckMessage = false;
        if(courseDetails.showCertificate && !courseDetails.certificateThumbnailUrl){
          fieldCheckMessage = "Please upload sample certificate";
        }else if(courseDetails.showInfoParagraph && 
          (courseDetails.infoParagraph === null || 
            courseDetails.infoParagraph === 'null'||
            !courseDetails.infoParagraph.icon 
            || !courseDetails.infoParagraph.icon ||
            courseDetails.infoParagraph.icon ==='' 
            || courseDetails.infoParagraph.icon ==='' )){
          fieldCheckMessage = "Please add info paragraph icon and pointers";
        }else if(courseDetails.showPreviewVideoLink && !courseDetails.previewVideoLink){
          fieldCheckMessage = "Please add preview video link";
        }
        
         if(fieldCheckMessage) 
        {
          Swal.fire({
            icon: "warning",
            title: fieldCheckMessage,
            showConfirmButton: false,
            timer: 2500,
          });
          setIsFormSaving(false);
          return
        }

        let payload = {
          courseId: id,
          infoParagraph: courseDetails.infoParagraph,
          certificateThumbnailUrl: courseDetails.certificateThumbnailUrl,
          roadmapType: courseDetails.roadmapType,
          previewVideoLink: courseDetails.previewVideoLink,
          faqsId: courseDetails.faqsId,
          reviews: courseDetails.reviews.map(({ _id, ...rest }) => rest),
          showCertificate : courseDetails.showCertificate,
          showInfoParagraph : courseDetails.showInfoParagraph,
          showPreviewVideoLink : courseDetails.showPreviewVideoLink
        };

        const response = await CoursesServices.updateCourseProductDetails(
          payload,
          authToken
        );
        if (response.success) {
          Swal.fire({
            icon: "success",
            title: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
          setIsFormSaving(false);
          return;
        }
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        setIsFormSaving(false);
        return;
      }
    } catch (err) {}
  };

  const isValidCourseDetails = () => {
    if (detailTabSelected) {
      const {
        title,
        internalTitle,
        mnemonic,
        visibility,
        examId,
        paymentGateways,
        imageCarousel,
        seriesWatchedPercentage,
        exemptedDays,
        tags,
        eligibleExams,
        eligibleCertificates,
        allowToCart,
      } = courseDetails;
      if (
        !title ||
        !internalTitle ||
        !mnemonic ||
        !visibility ||
        !examId ||
        !paymentGateways ||
        !tags.main ||
        !eligibleExams ||
        !eligibleCertificates
      ) {
        return true;
      }
    }
    if (cardTabSelected) {
      const {
        cardTitle,
        cardDescription,
        cardPointers,
        cardIcons,
        cardBackgroundColor,
        squareThumbnailUrl,
        rectangleThumbnailUrl,
      } = courseDetails;
      if (
        !cardTitle ||
        !cardDescription ||
        !cardPointers ||
        !cardIcons ||
        !cardBackgroundColor ||
        !squareThumbnailUrl ||
        !rectangleThumbnailUrl
      ) {
        return true;
      }
    }
    if (coverTabSelected) {
      const { coverTitle, coverDescription, coverPointers } = courseDetails;
      if (!coverTitle || !coverDescription || !coverPointers) {
        return true;
      }
    }
    return false;
  };

  const saveBssFormTab = async () => {
    try {
      const authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        courseId: id,
        bssCourseId: courseDetails.bssCourseId,
        bssInstituteId: courseDetails.bssInstituteId,
      };
      const response = await CoursesServices.updateCourseBSSDetails(
        payload,
        authToken
      );
      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        setIsFormSaving(false);
        return;
      }
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
      setIsFormSaving(false);
      return;
    } catch (err) {
      console.log("Error coming while saving BSS Form Tab", err);
    }
  };

  let offerExpiry = 0;

  if (courseDetails.offerExpiry) {
    offerExpiry =
      courseDetails.offerExpiry.days * 1440 +
      courseDetails.offerExpiry.hours * 60 +
      courseDetails.offerExpiry.minutes;
  }

  //

  const [detailTabSelected, setDetailsTabSelected] = useState(true);
  const [cardTabSelected, setCardTabSelected] = useState(false);
  const [coverTabSelected, setCoverTabSelected] = useState(false);
  const [productPageTabSelected, setProductPageTabSelected] = useState(false);
  const [bssFormTabSelected, setBssFormTabSelected] = useState(false);

  const handleTabSelect = (eventKey) => {
    if (eventKey) {
      setDetailsTabSelected(false);
      setCardTabSelected(false);
      setCoverTabSelected(false);
      setProductPageTabSelected(false);
      setBssFormTabSelected(false);
    }

    if (eventKey === "details") {
      setDetailsTabSelected(true);
    } else if (eventKey === "card") {
      setCardTabSelected(true);
    } else if (eventKey === "cover") {
      setCoverTabSelected(true);
    } else if (eventKey === "productPage") {
      setProductPageTabSelected(true);
    } else if (eventKey === "bssFormPage") {
      setBssFormTabSelected(true);
    }
    // removeQueryParameter();
  };

  const handleFormDataChange = (data) => {
    setCourseDetails(data);
  };

  return (
    <>
      <CourseDetailsView
        isFormSaving={isFormSaving}
        detailTabSelected={detailTabSelected}
        cardTabSelected={cardTabSelected}
        coverTabSelected={coverTabSelected}
        productPageTabSelected={productPageTabSelected}
        bssFormTabSelected={bssFormTabSelected}
        handleTabSelect={handleTabSelect}
        handleFormDataChange={handleFormDataChange}
        paymentGateways={paymentGateways}
        allQualifications={allQualifications}
        courseDetails={courseDetails}
        categories={categories}
        FAQS={FAQS}
        allBSSCourses={allBSSCourses}
        allBSSInstitutes={allBSSInstitutes}
        questionBanks={questionBanks}
        listOfSearchedCategories={listOfSearchedCategories}
        selectedCategories={selectedCategories}
        handleSave={handleSave}
      />
    </>
  );
}

export default CourseDetails;
