import React from 'react';
import './style.css';
import DropDownArrowIcon from '@assets/common/dropDownArrowIcon.png';
function SelectInputView({props}) {
    return(<div className="selectInputDiv" style={{overflow:'hidden'}}>
           <div className="selectInputHeader">
            {props.title} 
            {props.required ? <span className="text-danger">*</span> : ''}
           </div>
           <div onClick={props.onClick} className="selectInputInput">
            <div>{props.inputText}</div>
            <img src={DropDownArrowIcon}/>
           </div>
    </div>)
}

export default SelectInputView