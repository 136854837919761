import React, { useEffect, useState } from 'react';
import PopUpContainer from '@components/PopUpContainer';
import './style.css';

import TextInputV2 from '@components/TextInputV2';

function NewInstitutePopUpView(props) {

	return (
		<PopUpContainer
           title={"Add/edit institute"}
           isSearchEnabled={false}
           searchPlaceholder={""}
           isButtonsEnabled={true}
           style={{width:'476px', height:'306px'}}
           handleSearch={props.handleSearch}
           handleCancel={props.handleCancel}
           handleSave={props.handleSave}
        >
            <div className='d-flex flex-column gap-2'>
                <TextInputV2 
                 size={'small'}
                    placeholder={"Name"}
                    value={props.institute?.name}
                    required={true}
                    onChange={(event)=>{props.handleChange(event.target.value, 'name')}}
                />  
                <TextInputV2  size={'small'}
                    placeholder={"BSS Approval Code"}
                    value={props.institute?.approvalCode}
                    required={true}
                    onChange={(event)=>{props.handleChange(event.target.value, 'approvalCode')}}
                />  
                <TextInputV2  
                    placeholder={"Address"}
                    value={props.institute?.address}
                    required={true}
                    onChange={(event)=>{props.handleChange(event.target.value, 'address')}}
                />  
                <div className='d-flex gap-2'>
                    
                    <TextInputV2  size={'small'}
                        placeholder={"District"}
                        value={props.institute?.district}
                        required={true}
                        onChange={(event)=>{props.handleChange(event.target.value, 'district')}}
                    /> 
                    
                    <TextInputV2  size={'small'}
                        placeholder={"State"}
                        value={props.institute?.state}
                        required={true}
                        onChange={(event)=>{props.handleChange(event.target.value, 'state')}}
                    /> 
                    
                    <TextInputV2  size={'small'}
                        placeholder={"Pincode"}
                        value={props.institute?.pincode}
                        required={true}
                        onChange={(event)=>{props.handleChange(event.target.value, 'pincode')}}
                    /> 
                </div>  
            </div>
        </PopUpContainer>
	)
}

export default NewInstitutePopUpView;
