import React from 'react';

import UserHomeView from './view';

import { useLocation } from 'react-router-dom';

import InnerSidebarNavigation from '@components/InnerSidebarNavigation';
import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';

function UserHome() {
	const location = useLocation();

	//Failsafe if no data received from navigation
	let navigationData = [
		{
			title: 'Back to Users',
			route: '/Users',
			icon: GoBackArrow,
		},
	];
	if (location.state && location.state.finalNavigationData) {
		navigationData = location.state.finalNavigationData;
	}

	return (
		<>
			<InnerSidebarNavigation navigationData={navigationData} />
			<UserHomeView />
		</>
	);
}

export default UserHome;
