import './style.css';
import Search from '@components/Search';
import ButtonBars from '@components/ButtonBars';
import PopUp from '@components/PopUp';
import DetailedTicketInformation from '@pages/Users/LeadDetails/Sections/DetailedTicketInformation';
import LeadActivity from '@pages/Users/LeadDetails/Sections/LeadActivity';

import BlueDropDownIcon from '@assets/common/blueDropDown.png';
// import ExpandIcon from '@assets/userDashboard/expand.png';
import SettingIcon from '@assets/userDashboard/setting.png';
import FolderIcon from '@assets/userDashboard/folder.png';
import HideIcon from '@assets/common/hideIcon.png';

import HideLeadPopUp from '@components/Leads/HideLeadPopUp' ;
import LeadCallNotesPopUp from '@components/Leads/LeadCallNotesPopUp' ;
import LeadWaitingPopUp from '@components/Leads/LeadWaitingPopUp' ;
import MoveLeadPopUp from '@components/Leads/MoveLeadPopUp' ;
import TransferLeadPopUp from '@components/Leads/TransferLeadPopUp' ;
import AddLeadCommentPopUp from '@components/Leads/AddLeadCommentPopUp';


function LeadDetailsView(props) {

	return (
		<>
			<div className='contentPageMainContainer queriesDetailsView bg-white'>
               <div style={{borderBottom: '1px solid #ddd'}}>
					<h4 className='mb-3 topHeading'>Detailed Lead Information</h4>
					<div style={{ maxWidth: '1000px' }}
						className='d-flex align-items-center w-100 justify-content-between'>
						<ButtonBars
						buttons={[
							{
								text: 'Release',
								onClick: props.handleReleaseLead
							},
							{
								text: 'Claim',
								onClick: props.handleClaimLead
							},
						{
							text: 'Add Call Log',
							disabled: props.disableCallNotes,
							onClick: (event) => {
								props.handlePopUpOpen(event, "addCallLog");
							},
						},
						{
							text: 'Add comment',
							disabled: props.disableCallNotes,
							onClick: (event) => {
								props.handlePopUpOpen(event, "addComment");
							},
						},
						{
							text: 'Move',
							iconEnd: BlueDropDownIcon,
							disabled: props.disableBulkActionBtn,
							onClick: (event) => {
								props.handlePopUpOpen(event, "moveTo");
							},
						},
						{
							text: 'Add to waiting',
							iconEnd: BlueDropDownIcon,
							disabled: props.disableBulkActionBtn,
							onClick: (event) => {
								props.handlePopUpOpen(event, "addToWaiting");
							},
						},
						{
							text: 'Transfer To',
							iconEnd: BlueDropDownIcon,
							disabled: props.disableBulkActionBtn,
							onClick: (event) => {
								props.handlePopUpOpen(event, "transferTo");
							},
						}
						]}
					/>

					<div className='d-flex gap-2 controlsRight me-3'>
						<button onClick={(event) => props.handlePopUpOpen(event, "hideLeads")} disabled={props.disableBulkActionBtn}>
							<img src={HideIcon}></img>
							<br></br>
							Hide
						</button>
						<button onClick={(event) => props.handlePopUpOpen(event, "setting")}>
							<img src={SettingIcon}></img>
							<br></br>
							Setting
						</button>
						<button onClick={props.moveToArchive}>
							<img src={FolderIcon}></img>
							<br></br>
							Archive
						</button>
					</div>
					</div>
			   </div>
			   <div className=' flex-grow-1 overflow-auto'>
				
				<div className='contentContainer row w-100' style={{borderBottom: '1px solid #ddd'}}>
						<div className='col-12' style={{maxWidth: '100%', overflowX:'scroll'}}>
							<DetailedTicketInformation
								leadDetails={props.deferredLeadDetails}
								userDetails={props.userDetails}
							/>	
						</div>
					</div>
				<div className='contentContainer row w-100'>
						<div className='col-9' style={{maxWidth: '1000px'}}>
							<LeadActivity 
								leadActivityData={props.leadActivityData} 
							/>
						</div>
					</div>
				</div>


				<PopUp visible={props.isPopUpVisible && props.popUp==='setting'}
					top={props.popUpPosition.top}
					left={props.popUpPosition.left -100}
					handleOutSideClick={props.handlePopUpClose}>
					{/* <div style={{height: '200px', overflow:'auto'}}>
                        <div className="settingOption" onClick={props.handleClaimLead}>Claim again</div>
                        <div className="settingOption" onClick={props.showHiddenLeads}>Show hidden leads</div>
                    </div> */}
				</PopUp>
				<PopUp visible={props.isPopUpVisible && props.popUp === 'addCallLog'}
					top={props.popUpPosition.top}
					left={props.popUpPosition.left}
					handleOutSideClick={props.handlePopUpClose}>
					<LeadCallNotesPopUp
					  leadId={props.leadId}
					  handleCancel={props.handlePopUpClose}
					  handleSave={props.handlePopUpSave}
					/>
				</PopUp>
				<PopUp visible={props.isPopUpVisible && props.popUp === 'addToWaiting'}
					top={props.popUpPosition.top}
					left={props.popUpPosition.left}
					handleOutSideClick={props.handlePopUpClose}>
					<LeadWaitingPopUp
					  leadIds = {[props.leadId]}
					  currentFolderName={['myLeads','unClaimedLeads','lost','Archived','successfullyClosed', 'waiting', 'irrelevant'].includes(props.activeTab?._id)? props.activeTab?._id: props.activeTab?.title}
					  handleCancel={props.handlePopUpClose}
					  handleSave={props.handlePopUpSave}/>
				</PopUp>
				<PopUp visible={props.isPopUpVisible && props.popUp === 'moveTo'}
					top={props.popUpPosition.top}
					left={props.popUpPosition.left}
					handleOutSideClick={props.handlePopUpClose}>
					<MoveLeadPopUp
					  leadIds = {[props.leadId]}
					  currentFolderDisplayName = {props.activeTab?.title}
					  currentFolderName={['myLeads','unClaimedLeads','lost','Archived','successfullyClosed', 'waiting', 'irrelevant'].includes(props.activeTab?._id)? props.activeTab?._id: props.activeTab?.title}
					  handleCancel={props.handlePopUpClose}
					  handleSave={props.handlePopUpSave}/>
				</PopUp>
				<PopUp visible={props.isPopUpVisible && props.popUp==='addComment'}
					top={props.popUpPosition.top}
					left={props.popUpPosition.left}
					handleOutSideClick={props.handlePopUpClose}>
					<AddLeadCommentPopUp
					  leadId={props.leadId}
					  handleCancel={props.handlePopUpClose}
					  handleSave={props.handlePopUpSave}
					/>
				</PopUp>
				<PopUp visible={props.isPopUpVisible && props.popUp==='transferTo'}
					top={props.popUpPosition.top}
					left={props.popUpPosition.left}
					handleOutSideClick={props.handlePopUpClose}>
					<TransferLeadPopUp
					  leadIds = {[props.leadId]}
					  handleCancel={props.handlePopUpClose}
					  handleSave={props.handlePopUpSave}
					/>
				</PopUp>
				<PopUp visible={props.isPopUpVisible && props.popUp==='hideLeads'}
					top={props.popUpPosition.top}
					left={props.popUpPosition.left -300}
					handleOutSideClick={props.handlePopUpClose}>
					<HideLeadPopUp
					  leadIds = {[props.leadId]}
					  handleCancel={props.handlePopUpClose}
					  handleSave={props.handlePopUpSave}
					/>
				</PopUp>


			</div>
		</>
	);
}

export default LeadDetailsView;
