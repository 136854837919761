import React from "react";

import VideoAnalyticsView from './view';

function VideoAnalytics(){
    return(
        <>
            <VideoAnalyticsView />
        </>
    )
}

export default VideoAnalytics;