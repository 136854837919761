import React from "react";

import './videosHome.css';

function VideosHomeView(){
    return(
        <>
            {/* <h1 style={{marginLeft:'24px'}}>VideosHomeView</h1> */}
        </>
    )
}

export default VideosHomeView;