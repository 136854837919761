import React from "react";
import './filterBar.css'

import SortIcon from '@assets/common/sort.png';

function fiterBar(props){

    return(
        <>
          <div className="filterDiv">
            <input type="checkbox" onClick={()=>{props.handleCheckbox()}} checked={props.isChecked}></input>
            <input type="button" value="Verify Selected"  className="button green" onClick={()=>{props.handleVerifyButton()}}></input>
            <input type="button" value="Reject Selected"  className="button red" onClick={()=>{props.handleRejectButton()}}></input>
            <div id="filter" onClick={(event)=>{props.handleMenuVisibility(event)}}>
              <img id="sortIcon" src={SortIcon} alt="SortIcon" />
              Filter
            </div>
            <div className="filters" 
                     style={{
                                visibility: props.isMenuVisible ? 'visible' : 'hidden',
                            }}
                    onClick = {(event)=>{props.handleMenuVisibility(event, "main")}}
                    >
                    <button onClick = {(event)=>{props.handleMenuVisibility(event,"today")}}>Today</button> 
                    <button onClick = {(event)=>{props.handleMenuVisibility(event,"month")}}>This Month</button> 
                    <button onClick = {(event)=>{props.handleMenuVisibility(event,"week")}}>This Week</button> 
                    <button onClick = {(event)=>{props.handleMenuVisibility(event,"year")}}>This Year</button>  
                    <button onClick = {(event)=>{props.handleMenuVisibility(event,"all")}}>All Time</button>            
            </div>
          </div>
        </>
    )
}

export default fiterBar;