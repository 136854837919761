import React from "react";

import './videoAnalytics.css'

function VideoAnalyticsView(){
    return(
        <>
            <h1 style={{marginLeft:'260px'}}>Video Analytics</h1>
        </>
    )
}

export default VideoAnalyticsView;