import React from 'react';
import moment from 'moment';
import './style.css';

import DeleteIcon from '@assets/common/deleteIcon.png';

import EditPencilIcon from '@assets/common/editPencilIcon.png';

import ExpandIcon from '@assets/userDashboard/expand.png';
import SettingIcon from '@assets/userDashboard/setting.png';

import BasicTableGrid from '@components/BasicTableGrid';
import PopUp from '@components/PopUp';
import ButtonBars from '@components/ButtonBars';
import Search from '@components/Search';

import DateAndUser from '@components/DateAndUser';
function SeriesView(props) {
	return (
		<div ref={props.fullscreenElementRef} className='bg-white' style={{ maxWidth: '100%', width:'100%', flexGrow: 1,
		backgroundColor: '#ffffff', display:'flex', flexDirection: 'column'
		}}>
		 
			<div className='d-flex align-items-center w-100 justify-content-between pe-5'>
					<ButtonBars buttons={[{type: 'simple',
									text: 'New Series',
									onClick: (event) => {
										props.handleRedirectToHome(event, 'none', 'none');
									}
								}]}/>
					<div className='d-flex gap-2 controlsRight pe-5'>
						<button 
						onClick={props.toggleFullscreen}
						>
							<img src={ExpandIcon}></img>
							<br></br>
							Expand
						</button>
						<button onClick={(event)=>props.handlePopUpOpen(event,'setting')}>
							<img src={SettingIcon}></img>
							<br></br>
							Setting
						</button>
					</div>
			</div>
			<Search
			    value={props.searchText}
				placeholder = {"Search series"}
				onSearch ={(data)=>props.handleSearch(data)}
			>
			<div>
				No Filters Added
			</div>
			</Search>
			<BasicTableGrid 
				gridId='series'
				data={props.deferedData} // Data to be displayed
				fixedCols={2} // How many columns are fixed and not scrolling
				initialSize={[35,526,150,150,221,227,150,150,241]}
				initialSorting={[{"id": props.sortBy === 'createdAt'? "Date Added":"Last modified date","desc": props.isSorted}]}
				handleSelect={(selectedRows) => {
					props.handleCheckBoxes(selectedRows);
				}}
				length={20}
				pagination={props.pagination}
				setPagination={props.setPagination}
				onSortChange={(data) => props.handleSort(data)
				}
				columnsOptionPopUpRef={props.columnsOptionPopUpRef}
				columns={[
				{header: 'Display Name',
					cell: (data) => {
					const info = data.row.original;
					return (
						<div className="d-flex gap-2">
							{props.showThumbnail===true ? <div className="thumbnailContainer" 
								   style={{
									 backgroundImage:`url(${info.thumbnailUrl})`,
									 width:'66px'
									}}
								   >
								</div>
							:
							 ''
							}
							<div className="d-flex flex-column justify-content-center gap-2 ms-2">
								<div onClick = {(event)=>{props.handleTitleClick(event, info)}}>
									{info?.title} 
								</div>
								<div className='hiddenDiv d-flex gap-2'>
									<img
										onClick={(event) => {
											props.handleRedirectToHome(event, info._id, 'CourseDetails');
										}}
										src={EditPencilIcon}
										alt='EditPencilIcon'
									/>
								</div>
							</div>
							
						</div>
					);
					},
				},
				{header: 'Abbreviation', 
				cell : (data)=>{
					return <div 
					// onClick={(event)=>props.handleAbbreviationClick(event,data.row.original)}
					>
						      {data.row.original.mnemonic}
							</div>
				}},
				{header: 'Validity', cell: (data)=>{
					const info = data.row.original
					return <div 
					// onClick={(event)=>props.handleVisibilityClick(event, 'single', info)}
					>
                             {info.validityDays }
					       </div>
				}},
				{header: 'Content', 
				 cell:(data)=>{
					const info = data.row.original;
					return(<div className="w-100 d-flex justify-content-around gap-2"
					        // onClick={(event)=>props.handleSeriesClick(event,info.row.original)}
							>
							<div className="seriesName">
								{info.content?.map((content)=> content+", ")}
								</div>
							<div className="seriesCount">{info.content?.length}</div>
						</div>)
				}},
				{header: 'Date Added', accessorFn: (row) => {},
					cell: (data) => {
						const info = data.row.original;
						return <DateAndUser
									date = {info.createdAt}
									image = {info.createdUserDetails?.displayPhoto}
									imageText = {info.createdUserDetails?.displayName}/>
					},
			    },
				{header: 'Current active users', cell:(data)=>data.row.original.activeUsers},
				{header: 'Users till date', cell:(data)=>data.row.original.usersTillDate},
				{header: 'Last modified date', accessorFn: (row) => {},
				cell: (data) => {
					const info = data.row.original;
					return (<DateAndUser
									date = {info.updatedAt}
									image = {info.lastModifiedUserDetails?.displayPhoto}
									imageText = {info.lastModifiedUserDetails?.displayName}/>
					) 
				},
			    }
				]}
				/>

            {/* All pop ups */}
			<PopUp
				visible={props.isPopUpVisible && props.popUp=='setting'}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left-50}
				handleOutSideClick={props.handlePopUpClose}>
				<div>
                    <div className="settingOption" onClick={props.showThumbnailToggle}>Show thumbnail(s)</div>
                    <div className="settingOption" onClick={props.duplicateSeries}>Duplicate series</div>
				</div>
			</PopUp>
			
		</div>
	);
}

export default SeriesView;
