import "./dynamicHome.css";

import DropDownIcon from "@assets/common/dropDownArrowIcon.png";
import SearchIcon from "@assets/common/search.png";

function DynamicHomeView(props) {
  return (
    <>
      <div className="dynamicHomePageMainContainer">
        <h4>Select any 5 Courses</h4>
        <div className="dropDown">
          <span
            onClick={() => {
              props.handleCourseDropDownVisibility(true);
            }}
          >
            Select 5 courses
          </span>

          {/* Hidden Dropdown */}
          <div
            className="selectListDynamicPage"
            style={
              props.isCourseDropDownVisible
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <div className="dropDownSearch inputDiv searchDiv">
              <input
                type="text"
                onChange={(event) => {
                  props.handleCourseSearchText(event.target.value);
                }}
              ></input>
              <img alt="DropDownIcon" src={SearchIcon} />
            </div>
            <div className="selectionList">
              {/**Repeat this for multiple options */}
              {props.dropDownListCourses.map((course) =>
                course["title"]
                  .toLowerCase()
                  .includes(props.searchTextCourses.toLowerCase()) ? (
                  <div
                    key={course["_id"]}
                    onClick={() => {
                      props.handleSelectedCourses(course["_id"]);
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={props.selectedCourses.includes(
                        String(course["_id"])
                      )}
                    ></input>
                    <span>{course["title"]}</span>
                  </div>
                ) : null
              )}
            </div>
            <hr />
            <div
              className="selectListFooter"
              onClick={() => {
                props.handleCourseDropDownVisibility();
              }}
            >
              <span>Done</span>
            </div>
          </div>
          <img
            img
            alt="DropDownIcon"
            src={DropDownIcon}
            onClick={() => {
              props.handleCourseDropDownVisibility(true);
            }}
          ></img>
        </div>
        <br />
        <div className="d-flex flex-row flex-wrap gap-3">
          {props.selectedCourses.map((selectedCourseId) => {
            const selectedCourse = props.dropDownListCourses.find(
              (course) => course["_id"] === selectedCourseId
            );
            return (
              <div
                key={selectedCourse?._id?.toString()}
                className="course"
                style={{
                  backgroundImage: `url(${selectedCourse?.rectangleThumbnailUrl})`,
                }}
              ></div>
            );
          })}
        </div>
        <br />
        <h4>Select Offers</h4>
        <div className="dropDown">
          <span
            onClick={() => {
              props.handleOfferDropDownVisibility(true);
            }}
          >
            Select 2 offers
          </span>
          {/* Hidden Dropdown */}
          <div
            className="selectListDynamicPage"
            style={
              props.isOfferDropDownVisible
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <div className="dropDownSearch inputDiv searchDiv">
              <input
                type="text"
                onChange={(event) => {
                  props.handleOffersSearchText(event.target.value);
                }}
              ></input>
              <img alt="DropDownIcon" src={SearchIcon} />
            </div>
            <div className="selectionList">
              {/**Repeat this for multiple options */}
              {props.dropDownListOffers &&
                props.dropDownListOffers.map((offer) =>
                  offer["title"]
                    .toLowerCase()
                    .includes(props.searchTextOffers.toLowerCase()) ? (
                    <div
                      key={offer["_id"]}
                      onClick={() => {
                        props.handleSelectedOffers(offer["_id"]);
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={props.selectedOffers.includes(
                          String(offer["_id"])
                        )}
                      ></input>
                      <span>{offer["title"]}</span>
                    </div>
                  ) : null
                )}
            </div>
            <hr />
            <div
              className="selectListFooter"
              onClick={() => {
                props.handleOfferDropDownVisibility(false);
              }}
            >
              <span>Done</span>
            </div>
          </div>
          <img
            img
            alt="DropDownIcon"
            src={DropDownIcon}
            onClick={() => {
              props.handleOfferDropDownVisibility(true);
            }}
          ></img>
        </div>
        <br />
        <div className="d-flex flex-row flex-wrap gap-3">
          {props.selectedOffers.map((selectedOfferId) => {
            const selectedOffer = props.dropDownListOffers.find(
              (offer) => offer["_id"] === selectedOfferId
            );

            return (
              <div
                key={selectedOffer?._id?.toString()}
                className="couponContainer"
              >
                <div
                  className="couponImg"
                  style={{
                    backgroundImage: `url(${selectedOffer?.thumbnailUrl})`,
                  }}
                >
                  <div className="couponCode">
                    <span>{selectedOffer?.couponCode}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <input
          className="inputDiv"
          type="text"
          value={props.offerHeading}
          placeholder="OfferHeading"
          onChange={(event) => {
            props.handleOfferHeading(event.target.value);
          }}
        ></input>
        <textarea
          className="inputDiv large"
          placeholder="Offer Paragraph"
          value={props.offerDescription}
          onChange={(event) => {
            props.handleOfferDescription(event.target.value);
          }}
        ></textarea>
        <br />
        <input
          type="button"
          className="btn btn-dark"
          onClick={props.submit}
          value="Save"
        ></input>
      </div>
    </>
  );
}

export default DynamicHomeView;
