import { configureStore } from '@reduxjs/toolkit';

import LoginReducer from '@redux/reducers/Login';

import VideosReducer from '../reducers/Videos';
import CourseReducer from '../reducers/Course';
import SeriesReducer from '../reducers/Series';
import CategoryReducer from '../reducers/Category';
import FaqReducer from '../reducers/FAQ';
import ExamReducer from '../reducers/Exam';
import TestReducer from '../reducers/Tests';

import AccountsReducer from '../reducers/Accounts';
import VerificationReducer from '../reducers/Verification';
import OfferReducer from '../reducers/Offers';
import CommentsReducer from '../reducers/Comments';
import ArticleReducer from '../reducers/Articles';
import UserReducer from '../reducers/Users';
import AdminUserReducer from '../reducers/AdminUsers';
import TagsReducer from '../reducers/Tags';
import CommonReducer from '../reducers/Common';
import TabReducer from '../reducers/Tabs'
import ApprovalReducer from '../reducers/Approvals'
import CertificateReducer from '../reducers/Certificates'
import { testReset } from '@mui/joy/Tooltip/Tooltip';

const store = configureStore({
	reducer: {
		login: LoginReducer,
		
		videos: VideosReducer,
		category: CategoryReducer,
		course: CourseReducer,
		faq: FaqReducer,
		exam: ExamReducer,
		test: TestReducer,
		series: SeriesReducer,

		accounts: AccountsReducer,
		verification: VerificationReducer,
		offers: OfferReducer,
		comments: CommentsReducer,
		articles: ArticleReducer,
		user: UserReducer,
		tags: TagsReducer,
		common: CommonReducer,
		tabs:TabReducer,
		adminUser:AdminUserReducer,
		approval:ApprovalReducer,
		certificate:CertificateReducer
	},
});

export default store;
