/* 
------------- USER ROLES
role : role code
superadmin : 0
customerCare : 1
accountsModerator : 2
use : 3
designDepartment : 4
productionDepartment : 5
*/

import { userRoles } from "./userRolesConfig";
import { decryptText } from "@library/enc-dec";

export const checkLoginAndRole = (source) => {
  let userMessage = "";
  let routeTo = "";
  let issue = false;
  if (
    window.localStorage &&
    localStorage.getItem("aEmediat") &&
    localStorage.getItem("aEmediatt") &&
    localStorage.getItem("rEmedi") &&
    localStorage.getItem("rcEmedi")
  ) {
    const eMediRole = decryptText(localStorage.getItem("rEmedi"));
    const eMediRoleCode = localStorage.getItem("rcEmedi");
    // if (eMediRoleCode !== '0') {
    //     if (eMediRoleCode in userRoles) {
    //         let allUserRoles = userRoles[eMediRoleCode]
    //         if (!allUserRoles.includes(source)) {
    //             userMessage = 'With your ' + eMediRole + ' login you are not allowed to access ' + source + ' page';
    //             routeTo = '/'
    //             issue = true
    //         }
    //     }
    //     else {
    //         userMessage = 'User Role not found, try relogin or contact admin';
    //         routeTo = '/'
    //         issue = true
    //     }
    // }

    if (eMediRoleCode === 3 || eMediRoleCode === "3") {
      userMessage =
        "With your " +
        eMediRole +
        " login you are not allowed to access " +
        source +
        " page";
      routeTo = "/";
      issue = true;
    } else {
      return [eMediRole, eMediRoleCode]; //SuperAdmin 0 Admin 1 standard 2 User 3
    }
  } else {
    userMessage = "Please login first";
    routeTo = "/login";
    issue = true;
  }
  if (issue) {
    alert(userMessage);
    window.location.href = routeTo;
  }
};
