import React from 'react';

import './style.css';
import { styled } from '@mui/joy';

function TextAndLinksView(p) {
    const {props} =p
    return(
			<div className="d-flex flex-column justify-content-center gap-2 ms-2">
						<div onClick={(event)=>props.onClick(event, 0)} style={{cursor:'pointer'}}>
							{props.title}
						</div>
						<div className='hiddenDiv d-flex gap-2'>
							{/* <div className="hideHover" style={{color: '#929292'}}>
								{props.subTitle}
							</div> */}
							{/* <div className='showHover gap-2'> */}
								{props.images?.map((image, index)=>{
									return  <img
									key={index}
									onClick={(event) => {
										props.onClick(event, index);
									}}
									src={image}
									alt={''}
								/>
								})}
								
							{/* </div> */}
						</div>
					</div>	
    )
}

export default TextAndLinksView;