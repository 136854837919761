import React, { useEffect, useState } from 'react';
import PopUpContainer from '@components/PopUpContainer';
import './style.css';

import TextInputV2 from '@components/TextInputV2';

function NewBSSCoursePopUpView(props) {

	return (
		<PopUpContainer
           title={"Add/edit course"}
           isSearchEnabled={false}
           searchPlaceholder={""}
           isButtonsEnabled={true}
           style={{width:'476px', height:'436px'}}
           handleSearch={props.handleSearch}
           handleCancel={props.handleCancel}
           handleSave={props.handleSave}
        >
            <div className='d-flex flex-column gap-2'>
                <TextInputV2  size={'small'}
                    placeholder={"Name"}
                    value={props.course?.title}
                    required={true}
                    onChange={(event)=>{props.handleChange(event.target.value, 'title')}}
                />  
                <TextInputV2  size={'small'}
                    placeholder={"BSS Course Code"}
                    value={props.course?.bssCourseCode}
                    required={true}
                    onChange={(event)=>{props.handleChange(event.target.value, 'bssCourseCode')}}
                />  
                <div style={{fontSize:'15px', fontWeight:'500', color:'#212529'}}>Course From-To Year:</div>
                <div className='d-flex gap-2'>
                    <TextInputV2  size={'small'}
                        placeholder={"From"}
                        value={props.course?.fromYear}
                        required={true}
                        onChange={(event)=>{props.handleChange(event.target.value, 'fromYear')}}
                    /> 
                    <TextInputV2  size={'small'}
                        placeholder={"To"}
                        value={props.course?.toYear}
                        required={true}
                        onChange={(event)=>{props.handleChange(event.target.value, 'toYear')}}
                    /> 

                </div>
                <div style={{fontSize:'15px', fontWeight:'500', color:'#212529'}}>Set Course Duration To:</div>
                <div className='d-flex justify-content-around flex-grow-1 flex-column'>
                    <div className='d-flex gap-2'>
                            <input type='radio' name="course" 
                                checked={props.course.duration?.includes("months")}
                                onChange={(event)=>props.handleChange("0 months","duration")}/>
                            <input style={{border: '1px solid #cfd8dc', borderRadius: '5px', width: '60px'}}
                                type="text" 
                                value={props.course.duration?.includes("months")? 
                                 props.course.duration.split(" ")[0] 
                                : ''}
                            onChange={(event)=>{props.handleChange(event.target.value+ " months", "duration")}}
                            />
                                Months
                        </div>
                    <div className='d-flex gap-2'>
                            <input type='radio' name="course" 
                                checked={props.course.duration === "One Year" }
                                onChange={(event)=>props.handleChange("One Year", "duration")}/>
                            One Year
                    </div>
                    <div className='d-flex gap-2'>
                            <input type='radio' name="course" 
                                checked={props.course.duration === "Two Year - I Year"}
                                onChange={(event)=>props.handleChange("Two Year - I Year", "duration")}/>
                            Two Year - I Year
                    </div>
                    <div className='d-flex gap-2'>
                            <input type='radio' name="course" 
                                checked={props.course.duration === "Two Year - II Year" }
                                onChange={(event)=>props.handleChange("Two Year - II Year", "duration")}/>
                            Two Year - II Year
                    </div>
                    <div className='d-flex gap-2'>
                            <input type='radio' name="course" 
                                checked={props.course.duration === "Direct II Year" }
                                onChange={(event)=>props.handleChange("Direct II Year", "duration")}/>
                            Direct II Year
                    </div>
                </div> 
            </div>
        </PopUpContainer>
	)
}

export default NewBSSCoursePopUpView;
