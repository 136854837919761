import { useEffect, useState } from "react";

import * as UserServices from '@services/Users';
import * as LeadsServices from '@services/Leads';
import Swal from 'sweetalert2';
import { decryptText } from '@library/enc-dec';
import TextInputWithMentions from '@components/TextInputWithMentions';


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as CommonActions from '@redux/actions/Common';

import SelectionPopUp from '@components/SelectionPopUp';

function TransferLeadPopUp(props){
	const [allAdminUsers, setAllAdminUsers]= useState([]);

    useEffect(()=>{
      if(props.allTaggableUsers.length>0)
      {
        setAllAdminUsers(props.allTaggableUsers)
      }
      else{
        getAllAdminUsers()
      }
      
    },[])

    const getAllAdminUsers=async()=>{
        try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			let payload = {pageNo : 0,
                            limit : 999999,
                            isSorted:'true',
                            sortBy : 'firstName',
                            searchText :''
                        };
            var response = await UserServices.getAllAdmins(payload, authToken);
            if(response.success){
			  response.data= response.data.map((user)=>{
				return {
					_id: user._id,
					name: user.fullName,
					fullName: user.fullName,
					displayPhoto: user.displayPhoto,
				}
			  })	
              setAllAdminUsers(response.data)
              props.updateAllTaggableUsers(response.data)
			}else {
                Swal.fire({icon: "error",title: response.message,showConfirmButton: false,timer: 2500});
			}
			
		} catch (err) {
			console.log('Error coming while getting all BSS Forms', err);
		}
    }
    
    const handleSave=async(data)=>{
		let authToken = decryptText(localStorage.getItem('aEmediat'));
		const payload ={
            "leadIds" :props.leadIds,
            "agentId" : data[0]?._id
		};
  
		const response = await LeadsServices.transferTheLeads(payload, authToken);
        
		if (response.success) {
            var moved = response.data.length - response.data.filter(confirmation=>confirmation.message).length;
			Swal.fire({icon: "success",title: "Leads transferred ("+ moved +"/"+response.data.length+")" ,showConfirmButton: false,timer: 2500});
			props.handleSave()
		}
		else{
			Swal.fire({icon: "error",title: response.message,showConfirmButton: false,timer: 2500});
		}
	}

    return <SelectionPopUp 
                data = {allAdminUsers}
                optionKey= {"fullName"}
                title={""}
                searchPlaceholder={"Search for admins"}
                isSearchEnabled={true}
                height= {"310px"}
                width= {"285px"}
                selectionType={"single"}
                selectedItems={[]}
                handleCancel ={props.handleCancel}
                handleSave={handleSave}/>

}

const mapStateToProps = (state) => {
	return {
	    allTaggableUsers: state.common.allTaggableUsers,
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateAllTaggableUsers: CommonActions.updateAllTaggableUsers,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferLeadPopUp)