import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { decryptText } from '@library/enc-dec';

import SidebarNavigation from '@components/SidebarNavigation';

import NotificationView from './view'

function Notification(props) {
	const navigate = useNavigate();
	const dataFetchedRef = useRef(false);


	return (
		<>
		   <SidebarNavigation />
			<NotificationView/>
		</>
	);
}


export default Notification;
