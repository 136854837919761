import React from 'react';

import './style.css';
import SearchInput from '@components/SearchInput';

function PopUpContainerView(props) {
    const {title, searchPlaceholder, isSearchEnabled, isButtonsEnabled, handleCancel, handleSave, style, children} = props.props
	return (
		<div className="examPopUpDiv" style={style}>
            <span style={{fontWeight:500, fontSize:"16px", fontFamily:'inter'}}>{title}</span>
			{isSearchEnabled?
            <SearchInput
			  value={props.searchValue}
			  placeholder={searchPlaceholder}
			  onChange={(event)=>props.handleSearch(event.target.value)}
			/> : null}
				
			<div className='d-flex flex-grow-1' style={{overflowX: 'scroll'}}>
				{children}
			</div>
			<div className="d-flex justify-content-end gap-1 mt-auto">
			{props.error && props.error !== '' && (
				<div className='alert alert-danger w-50'>
					{props.error}
				</div>
			)}
            {isButtonsEnabled? 
				<><button onClick={handleCancel} className='closeBtn'>
					Cancel
				</button>
				<button onClick={handleSave} className='saveBtn'>
					{props.props.blackButtonText? props.props.blackButtonText:'Save'}
				</button></> : null}
            </div>



		</div>
	);
}

export default PopUpContainerView;
