import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

import MoveBarIcon from "@assets/common/moveBarIcon.png";
import PlusCircleIcon from "@assets/common/plusCircleIcon.png";
import PencilIcon from "@assets/common/pencil.png";
import DeleteIcon from "@assets/common/deleteIcon.png";

import { DragDropContext, Droppable } from "react-beautiful-dnd";
//DnD Import
import DnD from "@components/DnD";

function InstallmentsView(props) {
  return (
    <>
      <div className=" d-flex flex-grow-1 gap-3" style={{ maxWidth: "none" }}>
        <div classs="d-flex" style={{ width: "100%", maxWidth: "729px" }}>
          <div className="marginLeftContainer">
            {/* START : Error container */}
            {props.formValuesEmpty && (
              <div className="incompleteFieldsError">
                <span>All input fields are required</span>
              </div>
            )}
            {/* END : Error container */}
            {/* START : Deal ending section */}
            <h4 style={{ marginTop: "26px" }} className="subHeading">
              <input
                type="checkbox"
                className="colorCheckbox"
                style={{ height: "15px", marginRight: "10px" }}
                checked={props.pricingData.installmentsApplied}
                onChange={props.handleApplyInstallments}
              />
              Enable installments
            </h4>
            <p className="installmentDescription">
              Example, for a 2-part payment option: enter 50% for the initial
              payment and 52% for the final payment. Click on the ‘+’ button to
              increase instalment options. Select ‘make public’ to show the
              payment option on product page.
            </p>

            <div>
              <DragDropContext onDragEnd={props.onPaymentOptionDragEnd}>
                <Droppable droppableId="dataSet">
                  {(provided) => (
                    <div
                      className="d-flex flex-column gap-4"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {props.pricingData.installments.map(
                        (paymentOption, index) => (
                          <DnD
                            kay={index}
                            element={paymentOption}
                            index={index}
                            newComponent={
                              <div
                                className="d-flex gap-2 align-items-center"
                                style={{ fontSize: "14px" }}
                              >
                                <div
                                  className="review"
                                  style={{ height: "auto" }}
                                >
                                  <div>
                                    <img
                                      className="moveIcon"
                                      src={MoveBarIcon}
                                      style={{ height: "5px" }}
                                    ></img>
                                  </div>
                                  <div className="reviewContent">
                                    <div className="d-flex align-items-center mt-1 pb-1">
                                      <span>Payment Option {index + 1}</span>

                                      <h5
                                        className="subHeading  gap-2 d-flex ms-auto align-items-center"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <input
                                          className="colorCheckbox"
                                          type="checkbox"
                                          checked={paymentOption.isPublic}
                                          onClick={(event) => {
                                            props.handlePaymentVisibility(
                                              event.target.checked,
                                              index
                                            );
                                          }}
                                        />
                                        Make Public
                                      </h5>
                                    </div>
                                    <div className="d-flex flex-column gap-2 p-2">
                                      {paymentOption.options.length == 0 ? (
                                        <img
                                          className="addReviewIcon"
                                          src={PlusCircleIcon}
                                          onClick={(event) => {
                                            props.handleAddInstallmentOption(
                                              event,
                                              null
                                            );
                                          }}
                                        />
                                      ) : (
                                        paymentOption.options.map(
                                          (option, optionIndex) => {
                                            return (
                                              <div
                                                key={optionIndex}
                                                className="d-flex gap-3"
                                              >
                                                {optionIndex + 1}
                                                <input
                                                  type="text"
                                                  value={option}
                                                  onChange={(event) =>
                                                    props.handleInstallmentChange(
                                                      event.target.value,
                                                      index,
                                                      optionIndex
                                                    )
                                                  }
                                                />
                                                <img
                                                  className="addReviewIcon"
                                                  src={PlusCircleIcon}
                                                  onClick={(event) => {
                                                    props.handleAddInstallment(
                                                      event,
                                                      index,
                                                      optionIndex
                                                    );
                                                  }}
                                                />
                                                <img
                                                  className="addReviewIcon"
                                                  src={DeleteIcon}
                                                  onClick={(event) => {
                                                    props.handleDeleteInstallment(
                                                      event,
                                                      index,
                                                      optionIndex
                                                    );
                                                  }}
                                                />
                                              </div>
                                            );
                                          }
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <img
                                  className="addReviewIcon"
                                  src={PlusCircleIcon}
                                  onClick={(event) => {
                                    props.handleAddPaymentOption(event, index);
                                  }}
                                />
                                <img
                                  className="addReviewIcon"
                                  src={DeleteIcon}
                                  onClick={(event) => {
                                    props.handleDeletePaymentOption(
                                      paymentOption._id
                                    );
                                  }}
                                />
                              </div>
                            }
                          />
                        )
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <center>
                {props.pricingData.installments.length == 0 ? (
                  <div className="bg-light w-100 p-3 mt-2">
                    <button
                      className="btn btn-dark d-flex gap-2 align-items-center w-50 justify-content-center"
                      style={{ fontSize: "14px" }}
                      onClick={(event) => {
                        props.handleAddPaymentOption(event, null);
                      }}
                    >
                      <img className="addReviewIcon " src={PlusCircleIcon} />
                      Add installment
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </center>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InstallmentsView;
