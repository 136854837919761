export const updateAllFaqs = (value) => {
	return {
		type: 'UPDATE_ALL_FAQS',
		payload: {
			allFaqs: value,
		},
	};
};

export const updateTotalNumOfFaqs = (value) => {
	return {
		type: 'UPDATE_TOTAL_NUMBER_OF_FAQS',
		payload: {
			totalNumberOfFaqs: value,
		},
	};
};

export const updateFaqSet = (value) => {
	return {
		type: 'UPDATE_FAQ_SET',
		payload: {
			faqSet: value,
		},
	};
};

export const updatePageNo = (value) => {
	return {
		type: 'UPDATE_FAQ_PAGE_NUMBER',
		payload: {
			pageNo: value,
		},
	};
};

export const updateFilterInformation = (value) => {
	return {
		type: 'UPDATE_FAQ_FILTER_INFORMATION',
		payload: {
			filterInformation: value,
		},
	};
};
