import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import CourseCategoryDetailsView from "./view";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { decryptText } from "@library/enc-dec";
import { navigationData } from "./data";
import * as CategoryServices from "@services/Category";
import * as CoursesServices from "@services/Course";
import * as CategoryActions from "@redux/actions/Category";
import { produce } from "immer";
import Swal from "sweetalert2";

let FinalLocalListOfSelectedCourse = [];
let bulletPointsObj = {};

bulletPointsObj["bulletPoint_1"] = {
  icon: "",
  bulletPoint: "",
};
function CourseCategoryDetails(props) {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);
  const { id } = useParams();
  let authToken = decryptText(localStorage.getItem("aEmediat"));

  //START: Usestate and fn's for handling search and add course functionality
  const addCourseContainerRef = useRef(null);
  const [addCourseContainerVisiable, setAddCourseContainerVisiable] =
    useState(false);

  const [listOfSearchedCourses, setListOfSearchedCourses] = useState(null);
  const [doneBtnDisable, setDoneBtnDisable] = useState(true); // by default it's false until course are selected
  const [formValuesEmpty, setFormValuesEmpty] = useState(false);
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [errorText, setErrorText] = useState("");
  const [isFormSaving, setIsFormSaving] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event) => {
    if (
      addCourseContainerRef.current &&
      !addCourseContainerRef.current.contains(event.target)
    ) {
      setAddCourseContainerVisiable(false);
      setDoneBtnDisable(true);
      setIsDropDownVisible(false);
    }
  };

  useEffect(() => {
    props.updateSelectedCourses([]);
    getCategoryDetails();
    getAllCourses();
  }, []);

  const getAllCourses = async () => {
    try {
      const payload = { pageNo: 0, limit: 10000 };
      const response = await CoursesServices.getAllCourses(payload, authToken);
      if (response.success) {
        setListOfSearchedCourses(response.data);
      }
    } catch (err) {
      console.log("Error coming while fetching courses in category", err);
    }
  };

  const getCategoryDetails = async () => {
    try {
      if (id === undefined) {
        props.resetCategoryReducer();
        return;
      }

      if (id !== undefined) {
        const categoryDocId = id;
        const response = await CategoryServices.getCategory(
          categoryDocId,
          authToken
        );
        if (response.success) {
          props.updateCategoryDetails(response.data);
          props.updateSelectedCourses(response.data["courseIds"]);
          return;
        }

        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
    } catch (err) {
      console.log("Error coming while fetching catgeory details");
    }
  };

  const handleCategoryTitle = (event) => {
    setFormValuesEmpty(false);
    props.updateCategoryTitle(event.target.value);
  };

  const handleCategoryDescription = (event) => {
    setFormValuesEmpty(false);
    props.updateCategoryDescription(event.target.value);
  };

  const handleCategoryVideoUrl = (event) => {
    setFormValuesEmpty(false);
    props.updateCategoryVideoUrl(event.target.value);
  };

  const openAddCourseContainer = () => {
    setAddCourseContainerVisiable(true);
  };

  const handleBulletIconChange = (bulletIcon) => {
    const { pointers } = props;
    const newPointers = produce(pointers, (draft) => {
      draft.map((pointer, index) => {
        if (index + 1 === Number(bulletIcon.bulletSelectorId)) {
          pointer["icon"] = bulletIcon.icon;
        }
      });
    });

    props.updatePointers(newPointers);
  };

  //START : fn to handle bullet input
  const handleAddBulletPoint = (event) => {
    if (document.getElementById("bulletPointsInputContainer")) {
      const { pointers } = props;
      const updatedPointers = produce(pointers, (draft) => {
        draft.push({
          icon: "",
          pointer: "",
        });
      });

      props.updatePointers(updatedPointers);
    }
  };

  const handleBulletPointerChange = (event, index) => {
    const { pointers } = props;
    const newPointers = produce(pointers, (draft) => {
      draft.map((pointer, pIndex) => {
        if (pIndex === index) {
          pointer["pointer"] = event.target.value;
        }
      });
    });

    props.updatePointers(newPointers);
  };

  const validateBulletPoints = () => {
    // Removing empty bullet points
    const { pointers } = props;

    let updatedPointers = [...pointers];
    let newPointersList = [];
    const pointersPromises = updatedPointers.map((item) => {
      let updatedItem = { ...item };
      if (updatedItem.pointer) {
        newPointersList.push(updatedItem);
      }
    });

    Promise.all(pointersPromises);

    props.updatePointers(newPointersList);
    return newPointersList;
  };
  //END : fn to handle bullet input

  const handleVisibilityRadioBtn = (event, value) => {
    if (value === "unlisted") {
      props.updateCategoryIsPublic(false);
    } else {
      props.updateCategoryIsPublic(true);
    }
  };

  const handleSave = async () => {
    try {
      setIsFormSaving(true);
      const { title, description, videoUrl, isPublic, selectedCourses } = props;
      const pointers = validateBulletPoints();

      var isPointerIconEmpty = false;
      pointers.forEach((pointer) => {
        if (pointer.icon === "") {
          isPointerIconEmpty = true;
        }
      });

      if (!title) {
        setFormValuesEmpty(true);
        setErrorText("Title is empty");
        setIsFormSaving(false);
        return;
      }
      if (!description) {
        setFormValuesEmpty(true);
        setErrorText("Description is empty");
        setIsFormSaving(false);
        return;
      }
      if (!videoUrl) {
        setFormValuesEmpty(true);
        setErrorText("Video Url is empty");
        setIsFormSaving(false);
        return;
      } else {
        const youtubeUrlPattern =
          /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/embed\/|youtu.be\/|youtube\.com\/v\/|youtube\.com\/watch\?v=)([\w-]+)(?:&\S*)?(?:\?\S*)?/g;
        if (!youtubeUrlPattern.test(videoUrl)) {
          setFormValuesEmpty(true);
          setErrorText("Video Url is not valid");
          setIsFormSaving(false);
          return;
        }
      }
      if (pointers.length === 0) {
        setFormValuesEmpty(true);
        setErrorText("Pointer are empty");
        setIsFormSaving(false);
        return;
      }
      if (isPointerIconEmpty) {
        setFormValuesEmpty(true);
        setErrorText("Pointer icon not selected");
        setIsFormSaving(false);
        return;
      }

      setFormValuesEmpty(false);
      if (id !== undefined) {
        const payload = {
          categoryDocId: id,
          title: title,
          description: description,
          videoUrl: videoUrl,
          pointers: pointers,
          isPublic: isPublic,
          courseIds: selectedCourses,
        };
        const response = await CategoryServices.updateCategory(
          payload,
          authToken
        );
        if (response.success) {
          props.updateCategoryDetails(response.data);
          Swal.fire({
            icon: "success",
            title: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
          setIsFormSaving(false);
          return;
        }
        setFormValuesEmpty(true);
      } else {
        const payload = {
          title: title,
          description: description,
          videoUrl: videoUrl,
          pointers: pointers,
          isPublic: isPublic,
          courseIds: FinalLocalListOfSelectedCourse,
        };
        const response = await CategoryServices.addCategory(payload, authToken);
        if (response.success) {
          props.updateCategoryDetails(response.data);
          Swal.fire({
            icon: "success",
            title: response.message,
            showConfirmButton: false,
            timer: 2500,
          });
          setIsFormSaving(false);
          navigate(`/course-category/${response.data._id}`, {
            state: { finalNavigationData: navigationData },
          });
          return;
        }
        setFormValuesEmpty(true);
      }
    } catch (err) {
      console.log("Error coming while adding or updating category", err);
      setIsFormSaving(false);
    }
  };

  const handleApplicableCourses = async (courseId) => {
    const updatedCourses = produce(props.selectedCourses, (newCourses) => {
      if (newCourses.includes(String(courseId))) {
        const index = newCourses.indexOf(String(courseId));
        newCourses.splice(index, 1);
      } else {
        newCourses.push(String(courseId));
      }
    });
    props.updateSelectedCourses(updatedCourses);
  };

  return (
    <>
      <CourseCategoryDetailsView
        categoryTitle={props.title}
        categoryDescription={props.description}
        categoryVideoUrl={props.videoUrl}
        pointers={props.pointers}
        handleCategoryTitle={handleCategoryTitle}
        handleCategoryDescription={handleCategoryDescription}
        handleCategoryVideoUrl={handleCategoryVideoUrl}
        // Handling reach text editor bullet points
        handleBulletIconChange={handleBulletIconChange}
        handleAddBulletPoint={handleAddBulletPoint}
        handleBulletPointerChange={handleBulletPointerChange}
        // Handling visibility
        isPublic={props.isPublic}
        handleVisibilityRadioBtn={handleVisibilityRadioBtn}
        // Handling add course
        addCourseContainerRef={addCourseContainerRef}
        addCourseContainerVisiable={addCourseContainerVisiable}
        openAddCourseContainer={openAddCourseContainer}
        // Handling search
        listOfSearchedCourses={listOfSearchedCourses}
        doneBtnDisable={doneBtnDisable}
        isDropDownVisible={isDropDownVisible}
        searchText={searchText}
        handleApplicableCourses={(id) => handleApplicableCourses(id)}
        selectedCourses={props.selectedCourses}
        handleSearchText={(value) => setSearchText(value)}
        handleDropDownVisibility={(visibility) => {
          setIsDropDownVisible(visibility);
        }}
        // Handling save
        formValuesEmpty={formValuesEmpty}
        handleSave={handleSave}
        errorText={errorText}
        isFormSaving={isFormSaving}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    title: state.category.title,
    description: state.category.description,
    pointers: state.category.pointers,
    videoUrl: state.category.videoUrl,
    courseIds: state.category.courseIds,
    isPublic: state.category.isPublic,
    selectedCourses: state.category.selectedCourses,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateCategoryDetails: CategoryActions.updateCategoryDetails,
      updateCategoryTitle: CategoryActions.updateCategoryTitle,
      updateCategoryDescription: CategoryActions.updateCategoryDescription,
      updatePointers: CategoryActions.updatePointers,
      updateCategoryVideoUrl: CategoryActions.updateCategoryVideoUrl,
      updateCategoryIsPublic: CategoryActions.updateCategoryIsPublic,
      resetCategoryReducer: CategoryActions.resetCategoryReducer,
      updateSelectedCourses: CategoryActions.updateSelectedCourses,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseCategoryDetails);
