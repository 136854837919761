import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';
import EditPencilIcon from '@assets/common/editPencilIcon.png';
import CommentsIcon from '@assets/common/commentsIcon.png';

import AnalyticsIcon from '@assets/common/analyticsIcon.png';


export const apiVideosData = {
    "success": true,
    "message": "All videos fetched successfully",
    "data": [
        {
            "_id": "6489b351d84131a4b610f2c7",
            "title": "Miscellaneous 0",
            "description": "All about new topic",
            "duration": "16 mins 13 sec",
            "videoId": "746055324",
            "thumbnailUrl": "https://i.vimeocdn.com/video/1500448425-a93c8f654eb234721498656ce152cf8fa14261e7ccb2b97db2125ccff39c72f8-d",
            "isPublic": false,
            "viewsCount": 10000,
            "likesCount": 0,
            "dislikesCount": 0,
            "commentsCount": 1000,
            "hideLikes": false,
            "hideDislikes": false,
            "additionalInfo": null,
            "commentsStatus": "enable",
            "createdAt": "2023-12-14T12:32:17.159Z",
            "updatedAt": "2023-06-14T13:31:46.777Z",
            "__v": 0
        },
        {
            "_id": "6489bc1cea4fd0205e764321",
            "title": "AS 4_ Ischio-Anal Fossa 1",
            "description": "All about new topic",
            "duration": "40 mins 15 sec",
            "videoId": "833587774",
            "thumbnailUrl": "https://i.vimeocdn.com/video/1500448425-a93c8f654eb234721498656ce152cf8fa14261e7ccb2b97db2125ccff39c72f8-d",
            "isPublic": true,
            "viewsCount": 0,
            "likesCount": 0,
            "dislikesCount": 0,
            "commentsCount": 1000,
            "hideLikes": false,
            "hideDislikes": false,
            "additionalInfo": null,
            "commentsStatus": "enable",
            "createdAt": "2023-12-11T13:09:48.204Z",
            "updatedAt": "2023-06-14T13:09:48.204Z",
            "__v": 0
        },
        {
            "_id": "6489bc3dea4fd0205e764325",
            "title": "AS 4_ Rectum 2",
            "description": "All about new topic",
            "duration": "58 mins 38 sec",
            "videoId": "833585504",
            "thumbnailUrl": "https://i.vimeocdn.com/video/1500448425-a93c8f654eb234721498656ce152cf8fa14261e7ccb2b97db2125ccff39c72f8-d",
            "isPublic": false,
            "viewsCount": 0,
            "likesCount": 0,
            "dislikesCount": 0,
            "commentsCount": 1000,
            "hideLikes": false,
            "hideDislikes": false,
            "additionalInfo": null,
            "commentsStatus": "enable",
            "createdAt": "2023-11-14T13:10:21.663Z",
            "updatedAt": "2023-06-14T13:10:21.663Z",
            "__v": 0
        },
        {
            "_id": "6489bc8780a8756d4f456b80",
            "title": "AS 4_ Vermiform Appendix 3",
            "description": "All about new topic",
            "duration": "47 mins 21 sec",
            "videoId": "833584665",
            "thumbnailUrl": "https://i.vimeocdn.com/video/1500448425-a93c8f654eb234721498656ce152cf8fa14261e7ccb2b97db2125ccff39c72f8-d",
            "isPublic": false,
            "viewsCount": 0,
            "likesCount": 0,
            "dislikesCount": 0,
            "commentsCount": 1000,
            "hideLikes": false,
            "hideDislikes": false,
            "additionalInfo": null,
            "commentsStatus": "enable",
            "createdAt": "2023-08-14T13:11:35.426Z",
            "updatedAt": "2023-06-14T13:11:35.426Z",
            "__v": 0
        },
        {
            "_id": "6489bcaf80a8756d4f456b8a",
            "title": "AS 4_ Large Intestine 4",
            "description": "All about new topic",
            "duration": "57 mins 5 sec",
            "videoId": "833580478",
            "thumbnailUrl": "https://i.vimeocdn.com/video/1500448425-a93c8f654eb234721498656ce152cf8fa14261e7ccb2b97db2125ccff39c72f8-d",
            "isPublic": true,
            "viewsCount": 0,
            "likesCount": 0,
            "dislikesCount": 0,
            "commentsCount": 1000,
            "hideLikes": false,
            "hideDislikes": false,
            "additionalInfo": null,
            "commentsStatus": "enable",
            "createdAt": "2023-08-10T13:12:15.129Z",
            "updatedAt": "2023-06-14T13:31:46.779Z",
            "__v": 0
        }

        ,
        {
            "_id": "6489bcaf80a8756d4f456b8a",
            "title": "AS 4_ Large Intestine 5",
            "description": "All about new topic",
            "duration": "57 mins 5 sec",
            "videoId": "833580478",
            "thumbnailUrl": "https://i.vimeocdn.com/video/1500448425-a93c8f654eb234721498656ce152cf8fa14261e7ccb2b97db2125ccff39c72f8-d",
            "isPublic": true,
            "viewsCount": 0,
            "likesCount": 0,
            "dislikesCount": 0,
            "commentsCount": 1000,
            "hideLikes": false,
            "hideDislikes": false,
            "additionalInfo": null,
            "commentsStatus": "enable",
            "createdAt": "2023-06-14T13:12:15.129Z",
            "updatedAt": "2023-06-14T13:31:46.779Z",
            "__v": 0
        }
        ,
        {
            "_id": "6489bcaf80a8756d4f456b8s",
            "title": "AS 4_ Large Intestine 6",
            "description": "All about new topic",
            "duration": "57 mins 5 sec",
            "videoId": "833580478",
            "thumbnailUrl": "https://i.vimeocdn.com/video/1500448425-a93c8f654eb234721498656ce152cf8fa14261e7ccb2b97db2125ccff39c72f8-d",
            "isPublic": true,
            "viewsCount": 0,
            "likesCount": 0,
            "dislikesCount": 0,
            "commentsCount": 1000,
            "hideLikes": false,
            "hideDislikes": false,
            "additionalInfo": null,
            "commentsStatus": "enable",
            "createdAt": "2023-06-14T13:12:15.129Z",
            "updatedAt": "2023-06-14T13:31:46.779Z",
            "__v": 0
        }
        ,
        {
            "_id": "6489bcaf80a8756d4f456b8s",
            "title": "AS 4_ Large Intestine 7",
            "description": "All about new topic",
            "duration": "57 mins 5 sec",
            "videoId": "833580478",
            "thumbnailUrl": "https://i.vimeocdn.com/video/1500448425-a93c8f654eb234721498656ce152cf8fa14261e7ccb2b97db2125ccff39c72f8-d",
            "isPublic": true,
            "viewsCount": 0,
            "likesCount": 0,
            "dislikesCount": 0,
            "commentsCount": 1000,
            "hideLikes": false,
            "hideDislikes": false,
            "additionalInfo": null,
            "commentsStatus": "enable",
            "createdAt": "2023-06-14T13:12:15.129Z",
            "updatedAt": "2023-06-14T13:31:46.779Z",
            "__v": 0
        }
        ,
        {
            "_id": "6489bcaf80a8756d4f456b83d",
            "title": "AS 4_ Large Intestine 8",
            "description": "All about new topic",
            "duration": "57 mins 5 sec",
            "videoId": "833580478",
            "thumbnailUrl": "https://i.vimeocdn.com/video/1500448425-a93c8f654eb234721498656ce152cf8fa14261e7ccb2b97db2125ccff39c72f8-d",
            "isPublic": true,
            "viewsCount": 0,
            "likesCount": 0,
            "dislikesCount": 0,
            "commentsCount": 1000,
            "hideLikes": false,
            "hideDislikes": false,
            "additionalInfo": null,
            "commentsStatus": "enable",
            "createdAt": "2023-06-14T13:12:15.129Z",
            "updatedAt": "2023-06-14T13:31:46.779Z",
            "__v": 0
        }
        ,
        {
            "_id": "6489bcaf80a8756d4f456b83f",
            "title": "AS 4_ Large Intestine 9",
            "description": "All about new topic",
            "duration": "57 mins 5 sec",
            "videoId": "833580478",
            "thumbnailUrl": "https://i.vimeocdn.com/video/1500448425-a93c8f654eb234721498656ce152cf8fa14261e7ccb2b97db2125ccff39c72f8-d",
            "isPublic": true,
            "viewsCount": 0,
            "likesCount": 0,
            "dislikesCount": 0,
            "commentsCount": 1000,
            "hideLikes": false,
            "hideDislikes": false,
            "additionalInfo": null,
            "commentsStatus": "enable",
            "createdAt": "2023-06-14T13:12:15.129Z",
            "updatedAt": "2023-06-14T13:31:46.779Z",
            "__v": 0
        }

        ,
        {
            "_id": "6489bcaf80a8756d4f456b83f",
            "title": "AS 4_ Large Intestine 10",
            "description": "All about new topic",
            "duration": "57 mins 5 sec",
            "videoId": "833580478",
            "thumbnailUrl": "https://i.vimeocdn.com/video/1500448425-a93c8f654eb234721498656ce152cf8fa14261e7ccb2b97db2125ccff39c72f8-d",
            "isPublic": false,
            "viewsCount": 100000,
            "likesCount": 0,
            "dislikesCount": 0,
            "commentsCount": 1000,
            "hideLikes": false,
            "hideDislikes": false,
            "additionalInfo": null,
            "commentsStatus": "enable",
            "createdAt": "2023-06-14T13:12:15.129Z",
            "updatedAt": "2023-06-14T13:31:46.779Z",
            "__v": 0
        }
     ]
}


//Object to handle naviagtion
export const navigationData = [
    {
        "title" : "Back to Content",
        "route" : "/content",
        "default" : false,        
        "icon" : GoBackArrow
    },
    {
        "title" : "Details",
        "component" : "VideoDetails",
        "default" : false,        
        "icon" : EditPencilIcon
    },
    {
        "title" : "Comments",
        "component" : "VideoComments",
        "default" : false,         
        "icon" : CommentsIcon
    },
    {
        "title" : "Analytics",
        "component" : "VideoAnalytics",
        "default" : false,        
        "icon" : AnalyticsIcon
    },
]
