import React from 'react';

import './style.css';
import CalenderIcon from '@assets/common/calender.png';

function FilterDate(props){

    const handleIconClick=(event)=>{
     const inputElement = event.target.parentElement.children[1]
     inputElement.showPicker();
    }

    return(
        <div className="dateInput w-100">
             <div className="dateInputTitle">{props.title}</div>
             <div className='d-flex justify-content-center align-items-center gap-1 w-100' style={{marginTop:'2px'}}>
                <img src={CalenderIcon} onClick={handleIconClick}/>
                <input type="date" value={props.value} onChange={props.onChange}></input>
             </div>
        </div>
    )
}

export default FilterDate;
