
import { Link } from 'react-router-dom';

//Imports for RichEditor
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DeleteIcon from '@assets/common/deleteIcon.png';

import TextInputV2 from '@components/TextInputV2';
import SaveBtn from '@components/SaveBtn'


import './style.css'

function MailContentView(props) {
	//START : Vars for RichEditor
	const toolbarOptions = [
		{ header: [1, 2, 3, false] },
		{ font: [] },
		// { 'size': ['small', false, 'large', 'huge'] },
		'bold',
		'italic',
		'underline',
		'strike',
		{ script: 'sub' },
		{ script: 'super' },
		{ list: 'bullet' },
	];

	const formats = [
		'header',
		'font',
		'size',
		'bold',
		'italic',
		'underline',
		'strike',
		'script',
		'list',
		'bullet',
		'indent',
	];
	//END : Vars for RichEditor

	return (
		<>
			<div className='rightHandRightPageMainContainer userPageMainContainer d-flex flex-column'>
				<div className='topContainer flex-column w-100' style={{borderBottom: '1px solid #ddd'}}>
					<div className='d-flex justify-content-between' style={{maxWidth: '800px'}}>
						<h4 className='mb-3 topHeading'>User Details</h4>
						<div>
							<Link
								style={{ textDecoration: 'none' }}
								className='cancelBtn'
								to='/dynamic'>
								Cancel
							</Link>
							<SaveBtn onClick={props.handleSubmit}
							  text={"Save"}
							  altText={"Saving..."}
							  disabled={props.isFormSaving}
						/>
						</div>
					</div>
				</div>
				<div className='row flex-grow-1 overflow-scroll p-2'>
						<div className='col-7 d-flex gap-2 flex-column'>
						<select style={{height: 60}}
						className='w-100 border border-gray rounded-md ps-2'
						  name="selectedEmailType"
						  onChange={(e) => props.setSelectedType(e.target.value)}
						   value={props.selectedType}>
							<option value={''}>Select Email Type</option>
							{Object.keys(props.emailTypes).map((key) => 
								<option key={key} value={String(key)} selected={props.selectedType === key}>{key}
							</option>)}
						</select>
							<TextInputV2
								placeholder='Email Name'
								required= {true}
								value={props.emailName}
								onChange={(event)=>props.handleChange(event.target.value, 'emailName')}
							/>
							<TextInputV2
								required= {true}
								placeholder='Subject'
								value={props.subject}
								onChange={(event)=>props.handleChange(event.target.value, 'subject')}
							/>
							<div className="form-floating">
								<textarea className="form-control" placeholder="Description" id="floatingTextarea"
								style={{height:'120px'}}
								value={props.description}
								onChange={(event)=>props.handleChange(event.target.value, 'description')}
								></textarea>
								<label for="floatingTextarea">Description</label>
							</div>
							<div style={{display: 'flex', gap: 10,
							 flexWrap: 'wrap', padding:20}}>
							{props.emailTypes[props.selectedType]?.map((obj, index) => 
								<button className='mailVariable' key={index} onClick={(e)=>props.addValue(obj.text)}>{obj.name}</button>
							)}
							
							</div>
							<ReactQuill theme="snow" 
							ref={props.emailQuillRef}
							placeholder={''}
							value={props.emailContent} 
							onChange={(value)=>props.handleChange(value, 'emailContent')} 
							modules={{
								toolbar: {
								  container: [
									[{ 'header': [1, 2, 3, false] }],
									['bold', 'italic', 'underline', 'strike', 'blockquote'],
									[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
									['link', 'image'],
									['clean'],
								  ],
								},
							  }}
							// formats={['bold']}
							/>;
						</div>
						<div className='col-5'>

							<div className= 'emailViewer' dangerouslySetInnerHTML={{ __html: props.template }}>
							</div>
						</div>
					</div>
			</div>
		</>
	);
}

export default MailContentView;
