import React from 'react';

import './style.css';
import SearchInput from '@components/SearchInput';

function SelectSeriesPopUpView(props) {
	return (
		<div className="seriesPopUpDiv bg-white">
			<SearchInput
			  value={''}
			  placeholder={props.searchPlaceholder}
			  onChange={(event)=>props.handleSearch(event.target.value)}
			/>
			
			<div className="d-flex flex-column gap-2 option-list" style={{color:'#252525'}}>
			{props.allSeries?.map((series, index)=>{
				if(series.title?.toLowerCase().includes(props.searchText?.toLowerCase()))
				{return(
				<div className="d-flex ms-2 gap-2 align-items-center" key={index}>
					<input type='checkbox' 
					 className="colorCheckbox"
					 checked={props.selectedSeries?.some(_series => _series.seriesId === series._id)}
					 onChange={(event)=>props.handleSeriesSelect(event.target.checked, series)}
					 ></input>
					<span>{series.title}</span>
				</div>)}
			})}
			</div>

            <div className="d-flex justify-content-end gap-1">
			{props.error && props.error !== '' && (
				<div className='alert alert-danger w-50'>
					{props.error}
				</div>
			)}
				<button onClick={props.handleCloseBtn} className='closeBtn'>
					Cancel
				</button>
				<button onClick={props.handleSaveBtn} className='saveBtn'>
					Save
				</button>
            </div>



		</div>
	);
}

export default SelectSeriesPopUpView;
