export const updateAllTests = (value) => {
	return {
		type: 'UPDATE_ALL_TestS',
		payload: {
			allTests: value,
		},
	};
};

export const updateTotalNumOfTests = (value) => {
	return {
		type: 'UPDATE_TOTAL_NUMBER_OF_TestS',
		payload: {
			totalNumberOfTests: value,
		},
	};
};

export const updateTestsSet = (value) => {
	return {
		type: 'UPDATE_Test_SET',
		payload: {
			testsSet: value,
		},
	};
};

export const updateTestDetails = (value) => {
	return {
		type: 'UPDATE_Test_DETAILS',
		payload: {
			testData: value,
		},
	};
};

export const updateTestTitle = (value) => {
	return {
		type: 'UPDATE_Test_TITLE',
		payload: {
			title: value,
		},
	};
};

export const updateTestDuration = (value) => {
	return {
		type: 'UPDATE_Test_DURATION',
		payload: {
			duration: value,
		},
	};
};

export const updateTestPassPercentage = (value) => {
	return {
		type: 'UPDATE_Test_PASS_PERCENTAGE',
		payload: {
			passPercentage: value,
		},
	};
};

export const updateDisplayQuestions = (value) => {
	return {
		type: 'UPDATE_DISPLAY_QUESTIONS',
		payload: {
			displayQuestions: value,
		},
	};
};

export const updateTestThumbnailUrl = (value) => {
	return {
		type: 'UPDATE_Test_THUMBNAIL_URL',
		payload: {
			thumbnailUrl: value,
		},
	};
};

export const updateResultInDays = (value) => {
	return {
		type: 'UPDATE_RESULT_IN_DAYS',
		payload: {
			declarationResultInDays: value,
		},
	};
};

export const updateHideResultInDays = (value) => {
	return {
		type: 'UPDATE_HIDE_RESULT_IN_DAYS',
		payload: {
			hideResultInDays: value,
		},
	};
};

export const updateTestInstructions = (value) => {
	return {
		type: 'UPDATE_Test_INSTRUCTIONS',
		payload: {
			instructions: value,
		},
	};
};

export const updateTestPassedMessage = (value) => {
	return {
		type: 'UPDATE_Test_PASSED_MESSAGE',
		payload: {
			passedMessage: value,
		},
	};
};

export const updateTestFailedMessage = (value) => {
	return {
		type: 'UPDATE_Test_FAILED_MESSAGE',
		payload: {
			failedMessage: value,
		},
	};
};

export const updateTestQuestionsInSequence = (value) => {
	return {
		type: 'UPDATE_Test_QUESTIONS_IN_SEQUENCE',
		payload: {
			inSequence: value,
		},
	};
};

export const updateTestQuestionsSet = (value) => {
	return {
		type: 'UPDATE_Test_QUESTIONS_SET',
		payload: {
			questionsSet: value,
		},
	};
};

export const resetTestsReducer = (value) => {
	return {
		type: 'RESET_TestS_REDUCER',
		payload: {
			questionsSet: value,
		},
	};
};

export const updatePageNo = (value) => {
	return {
		type: 'UPDATE_Test_PAGE_NUMBER',
		payload: {
			pageNo: value,
		},
	};
};

export const updateFilterInformation = (value) => {
	return {
		type: 'UPDATE_TEST_FILTER_INFORMATION',
		payload: {
			filterInformation: value,
		},
	};
};
