import React, { useEffect, useState } from 'react';
import { useParams, defer } from 'react-router-dom';
import UserVideoHistoryView from './view';

import * as VideoServices from '@services/Videos';
import { decryptText } from '@library/enc-dec';

import { navigationData } from './data';
import { useNavigate } from 'react-router-dom';

import ClipboardJS from 'clipboard';

function UserVideoHistory(props) {
	const navigate = useNavigate();
	var { id } = useParams();
	const [userVideoHistory, setUserVideoHistory] = useState({});
	const [deferedVideoHistory, setVideoHistory] = useState({});

	useEffect(() => {
		getUserWatchHistory();
	}, []);

	const getUserWatchHistory = async () => {
		try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			let response = await VideoServices.getHistoryVideos(id, authToken);
			

			var deferedResponse = defer({ res: VideoServices.getHistoryVideos(id, authToken)})

			setVideoHistory(deferedResponse)
			if (response.success) {
				setUserVideoHistory(response);
				return;
			}
			alert(response.message);
		} catch (err) {
			console.log('Error coming while getUserWatchHistory()', err);
		}
	};

	const setDefaultNavigation = (defaultComponent) => {
		return navigationData.map((item) => {
			if (item.component === defaultComponent) {
				return { ...item, default: true };
			}
			return item;
		});
	};

	const handleRedirectToHome = (event, videoDocId, defaultComponent) => {
		//Handle setting default page while navigating
		let finalNavigationData = [];
		finalNavigationData = navigationData;
		if (defaultComponent !== 'none') {
			finalNavigationData = setDefaultNavigation(defaultComponent);
		} else {
			// VAR x ONLY FOR TESTING
			// let x = 'VideoComments';
			// finalNavigationData = setDefaultNavigation(x);
			finalNavigationData = setDefaultNavigation('VideoDetails');
		}

		if (videoDocId === 'none') {
			props.resetVideosReducer();
			navigate('/videos', { state: { finalNavigationData } });
		} else {
			navigate('/videos/' + videoDocId, { state: { finalNavigationData } });
		}
	};

	const handleCopyClick = (textToCopy) => {
		const clipboard = new ClipboardJS('.clipboard', {
			text: () => textToCopy,
		});

		clipboard.on('success', (e) => {
			// setAlertVisible(true);
			// setTimeout(() => {
			// 	setAlertVisible(false);
			// }, 1000);
			e.clearSelection();
			clipboard.destroy();
		});

		clipboard.on('error', (e) => {
			console.error('Copy failed:', e.action);
			clipboard.destroy();
		});
	};

	return (
		<>
			<UserVideoHistoryView 
			  userVideoHistory={userVideoHistory} 
			  deferedVideoHistory = {deferedVideoHistory}
			  handleRedirectToHome={handleRedirectToHome}
			  handleCopyClick ={handleCopyClick}
			  />
		</>
	);
}

export default UserVideoHistory;
