import React, { useState, useEffect } from "react";

import SuperAdminView from "./view";
import SidebarNavigation from "@components/SidebarNavigation";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as TabsActions from "@redux/actions/Tabs";
import { useNavigate } from "react-router-dom";

const tabs = [
  { _id: "1", title: "Admin users", isActive: true },
  { _id: "2", title: "Approval(s)", isActive: false },
  { _id: "3", title: "Icons", isActive: false },
  { _id: "4", title: "Bullets", isActive: false },
  { _id: "5", title: "Tags", isActive: false },
  { _id: "6", title: "CC labels", isActive: false },
  { _id: "7", title: "Institute(s)", isActive: false },
  { _id: "8", title: "BSS Course(s)", isActive: false },
  { _id: "9", title: "External payments", isActive: false },
  { _id: "10", title: "Folder(s)", isActive: false },
  { _id: "11", title: "Interests", isActive: false },
  { _id: "12", title: "BSS UG Degree", isActive: false },
  { _id: "13", title: "Qualifications", isActive: false },
];

function SuperAdmin(props) {
  const navigate = useNavigate();
  const [folders, setFolders] = useState(tabs);
  const [activeTab, setActiveTab] = useState(props.tab);

  useEffect(() => {
    setActiveTab(props.tab);
  }, [props.tab]);

  const handleTabSelect = (tab) => {
    props.updateSuperAdminTab(tab);
    navigate(".", { replace: true });
  };

  return (
    <>
      <SidebarNavigation />
      <SuperAdminView
        folders={folders}
        activeTab={activeTab}
        handleTabClick={handleTabSelect}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    tab: state.tabs.SuperAdminTab,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSuperAdminTab: TabsActions.updateSuperAdminTab,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdmin);
