import React, { useEffect, useState } from 'react';

import EditExamPopUpView from './view';

import * as ExamServices from '@services/Exam';

function EditExamPopUp(props) {
	const [error, setError] = useState('');
    const [searchText, setSearchText] = useState('');
	const [selectedExam, setSelectedExam] = useState(props.selectedExam);

	const handleExamChange=(id)=>{
      setSelectedExam(id)
	}

	const handleCloseBtn = () => {
		props.handleClose()
	};

	const handleSaveBtn = async (event) => {
		event.target.value = selectedExam
		props.handleSave(event)

	};

	return (
		<EditExamPopUpView
				error={error}
				searchText={searchText}
				selectedExam={selectedExam}
				allExams={props.allExams}
				handleCloseBtn={handleCloseBtn}
				handleSaveBtn={handleSaveBtn}
				handleSearch = {setSearchText}
				handleExamChange = {handleExamChange}
			/>
	);
}

export default EditExamPopUp;
