const initialState = {
	allOffers : [],
	totalNumberOfOffers: 0,
	offerSet: [],
	selectedOffer: [{
		"_id": "",
		"title": "",
		"description": "",
		"thumbnailUrl": "",
		"applicableCourses": [],
		"couponCode": "",
		"discount": 0,
		"isActive": false,
		"isPublic": false,
		"isLatest": false,
	}],
	filters:{page: 0,
		limit:10,
		sortBy: 'createdAt',
		isSorted: false,
		searchText: '',
		startDate: '',
		endDate: '',
	}
};


const OfferReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_ALL_OFFERS':
			return {
				...state,
				allOffers: action.payload.allOffers,
			};
		
		case 'UPDATE_TOTAL_NUMBER_OF_OFFERS':
			return {
				...state,
				totalNumberOfOffers: action.payload.totalNumberOfOffers,
			};
		case 'UPDATE_OFFERS_SET':
			return {
				...state,
				offerSet: action.payload.offerSet,
			};
		case 'UPDATE_SELECTED_OFFER':
			return{
				...state,
				selectedOffer: action.payload.selectedOffer,
			};
		case 'UPDATE_OFFER_FILTERS':
			return{
				...state,
				filters: action.payload.filters,
			}
		case 'RESET_SELECTED_OFFER':
			return {
				...state,
                selectedOffer: [{
					"_id": "",
					"title": "",
					"description": "",
					"thumbnailUrl": "",
					"applicableCourses": [],
					"couponCode": "",
					"discount": 0,
					"isActive": false,
					"isPublic": false,
					"isLatest": false,
					"__v": 0
				}],
			};
		default:
			return{
				...state
			}
    }
}

export default OfferReducer;