import { Form, json } from "react-router-dom";

export const getAllCourses = async (payload, authToken) => {
  try {
    const { pageNo, limit, isSorted, sortBy, searchText } = payload;
    const url = `${
      process.env.REACT_APP_BASE_URL
    }/getAllCourses?pageNo=${pageNo}&limit=${limit}${
      searchText ? "&searchText=" + searchText : ""
    }${sortBy ? "&sortBy=" + sortBy : ""}${
      isSorted ? "&isSorted=" + isSorted : ""
    }`;

    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Courses : Get all Courses ", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getCourseById = async (courseId, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/getCourseById/${courseId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Courses : Get Course by Id ", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateCourseVisibility = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateCourseVisibility`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Course services : updateCourseVisibility()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateThumbnailAndTitle = async (payload, authToken) => {
  try {
    let formData = new FormData();
    for (let key in payload) {
      formData.append(key, payload[key]);
    }
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateCourseThumbnailAndTitle`,
      {
        method: "PATCH",
        headers: {
          Authorization: authToken,
        },
        body: formData,
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Course services : updateThumbnailAndTitle()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getCourse = async (courseId, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/getCourse/${courseId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Course services: getCourse() ", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateCourseBasicDetails = async (payload, authToken) => {
  try {
    let formData = new FormData();
    for (let key in payload) {
      if (key === "imageCarouselFiles") {
        for (let i = 0; i < payload[key]?.length; i++) {
          formData.append(key, payload[key][i]);
        }
      } else if (
        key === "imageCarousel" ||
        key === "paymentGateways" ||
        key === "tags" ||
        key === "eligibleExams" ||
        key === "eligibleCertificates"
      ) {
        formData.append(key, JSON.stringify(payload[key]));
      } else {
        formData.append(key, payload[key]);
      }
    }

    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateCourseBasicDetails`,
      {
        method: "POST",
        headers: {
          Authorization: authToken,
        },
        body: formData,
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Video services : updateCourseBasicDetails()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateCourseCardDetails = async (payload, authToken) => {
  try {
    let formData = new FormData();
    for (let key in payload) {
      if (key === "cardPointers" || key === "cardIcons") {
        formData.append(key, JSON.stringify(payload[key]));
      } else {
        formData.append(key, payload[key]);
      }
    }

    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateCourseCardDetails`,
      {
        method: "POST",
        headers: {
          Authorization: authToken,
        },
        body: formData,
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Video services : updateCourseCardDetails()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateCourseCoverDetails = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateCourseCoverDetails`,
      {
        method: "POST",
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Video services : updateCourseCoverDetails()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateCourseProductDetails = async (payload, authToken) => {
  try {
    let formData = new FormData();
    for (let key in payload) {
      if (key === "reviews" || key === "infoParagraph") {
        formData.append(key, JSON.stringify(payload[key]));
      } else {
        formData.append(key, payload[key]);
      }
    }

    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateCourseProductDetails`,
      {
        method: "POST",
        headers: {
          Authorization: authToken,
        },
        body: formData,
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Video services : updateCourseCardDetails()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateCourseBSSDetails = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateCourseBSSDetails`,
      {
        method: "POST",
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Video services : updateCourseBSSDetails()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateReviewsDisplayPhoto = async (payload, authToken) => {
  try {
    let formData = new FormData();
    formData.append("displayPhoto", payload.displayPhoto);

    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateReviewsDisplayPhoto`,
      {
        method: "PATCH",
        headers: {
          Authorization: authToken,
        },
        body: formData,
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Video services : updateReviewsDisplayPhoto()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const addCourse = async (payload, authToken) => {
  try {
    let formData = new FormData();
    for (let key in payload) {
      if (typeof payload[key] === "object" && key !== "thumbnailUrl") {
        formData.append(key, JSON.stringify(payload[key]));
      } else {
        formData.append(key, payload[key]);
      }
    }
    return await fetch(`${process.env.REACT_APP_BASE_URL}/addCourse`, {
      method: "POST",
      headers: {
        Authorization: authToken,
      },
      body: formData,
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Course services : addCourse()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const duplicateCourse = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/duplicateCourse`, {
      method: "POST",
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Course services : duplicateCourse()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const deleteCourses = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/deleteCourses`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Course services : deleteCourses()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getPricing = async (courseId, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/getPricing/${courseId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Course services: getPricing() ", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updatePricing = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/updatePricing`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Course services: updatePricing() ",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateCourseMnemonic = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateCourseMnemonic`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Course services: updateCourseMnemonic() ",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateCourseExamId = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateCourseExamId`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Course services: updateCourseExamId() ",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateCoursesVisibility = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateCoursesVisibility`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Course services: updateCoursesVisibility() ",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateInstallments = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateInstallments`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Course services: updateInstallments() ",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getBlogs = async (courseId, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/getBlog/${courseId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Course services: getBlogs() ", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateBlogThumbnail = async (payload, authToken) => {
  try {
    let formData = new FormData();

    formData.append("thumbnailUrl", payload.thumbnailUrl);

    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateBlogThumbnail`,
      {
        method: "PATCH",
        headers: {
          Authorization: authToken,
        },
        body: formData,
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from course services : updateBlogThumbnail()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateSEODetails = async (payload, authToken) => {
  try {
    let formData = new FormData();
    for (let key in payload) {
      if (typeof payload[key] === "object" && key !== "thumbnailUrl") {
        formData.append(key, JSON.stringify(payload[key]));
      } else {
        formData.append(key, payload[key]);
      }
    }

    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateSEODetails`, {
      method: "PATCH",
      headers: {
        Authorization: authToken,
      },
      body: formData,
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from course services : updateSEODetails()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateBlog = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateBlog`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Course services: updateBlog() ", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getCourseVideo = async (videoDocId, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/getCourseVideo/${videoDocId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Course services: getCourseVideo() ",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateCourseModule = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateCourseModule`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Course services: updateCourseModule() ",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};
