import React, { useEffect, useState, useRef } from "react";
import CoursesView from "./view";
import {
  useNavigate,
  defer,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as CourseServices from "@services/Course";
import * as CoursesActions from "@redux/actions/Course";
import * as SeriesServices from "@services/Series";
import * as ExamServices from "@services/Exam";

import { navigationData } from "./data";
import { decryptText } from "@library/enc-dec";

import { getEventElementPosition, toggleFullscreen } from "@helpers/common.js";
import { produce } from "immer";
import Swal from "sweetalert2";
var listOfCheckedCourses = [];
var visibilityCourseList = [];

function Courses(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const navigate = useNavigate();
  const fullscreenElementRef = useRef(null);
  const [deferedData, setDeferedData] = useState({});
  const [allSeries, setAllSeries] = useState([]);
  const [allExams, setAllExams] = useState([]);

  const [isSorted, setIsSorted] = useState(props.filterInformation.isSorted);
  const [sortBy, setSortBy] = useState(props.filterInformation.sortBy);
  const [showThumbnail, setShowThumbnail] = useState(true);

  // UseState's to control the functionality of setting visibility of courses
  const [internalIsPublic, setInternalIsPublic] = useState(false);
  const [internalIsUnlisted, setInternalIsUnlisted] = useState(false);
  const [disableBulkActionBtn, setDisableBulkActionBtn] = useState(true);
  const [disableSaveBtnOfVisibility, setDisableSaveBtnOfVisibility] =
    useState(false);
  const visibilityPopupContainerRef = useRef(null);
  const [
    is_VisibilityPopupContainerRef_Visible,
    set_Is_VisibilityPopupContainerRef_Visible,
  ] = useState(false);
  const [
    visibilityPopupContainerRef_Position,
    set_VisibilityPopupContainerRef_Position,
  ] = useState({ top: 0, left: 0 });

  // END : UseState's to control the functionality of uploading thumbnail & title of course
  //------------------------------------------------------

  const [isEditTitlePopUpVisible, setIsEditTitlePopUpVisible] = useState(false);
  const [isEditVisibilityPopUpVisible, setIsEditVisibilityPopUpVisible] =
    useState(false);
  const [isEditMnemonicPopUpVisible, setIsEditMnemonicPopUpVisible] =
    useState(false);
  const [isEditSeriesPopUpVisible, setIsEditSeriesPopUpVisible] =
    useState(false);
  const [isEditExamPopUpVisible, setIsEditExamPopUpVisible] = useState(false);
  const [isSettingPopUpVisible, setIsSettingPopUpVisible] = useState(false);

  const [popUpPosition, setPopUpPosition] = useState({});
  const [selectedCourse, setSelectedCourse] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getAllSeries();
    getAllQuestionBanks();
    setSearchParams({
      page: props.filterInformation.page,
      limit: props.filterInformation.limit,
    });
  }, []);

  useEffect(() => {
    props.updateFilterInformation({
      page: Number(queryParams.get("page")),
      limit: Number(queryParams.get("limit")),
      sortBy,
      isSorted,
      searchText: props.filterInformation.searchText,
    });
  }, [
    Number(queryParams.get("limit")),
    Number(queryParams.get("page")),
    sortBy,
    isSorted,
  ]);

  useEffect(() => {
    if(props.filterInformation.limit>0){
      getAllCourses();
    }
  }, [props.filterInformation]);

  const getAllCourses = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        pageNo: props.filterInformation.page,
        limit: props.filterInformation.limit,
        sortBy: props.filterInformation.sortBy,
        isSorted: props.filterInformation.isSorted,
        searchText: props.filterInformation.searchText,
      };

      var deferredResponse = defer({
        res: CourseServices.getAllCourses(payload, authToken),
      });
      setDeferedData(deferredResponse);
      deferredResponse.data.res
        .then((response) => {
          props.updateAllCourses(response);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (err) {
      console.log("Error coming while fetching all courses", err);
    }
  };

  const getAllSeries = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        pageNo: 0,
        limit: 1000,
        isSorted: true,
      };
      const response = await SeriesServices.getAllSeries(payload, authToken);
      if (response.success) {
        setAllSeries(response.data);
        return;
      }
      alert(response.message);
    } catch (err) {
      console.log("Error coming while fetching all series", err);
    }
  };

  const getAllQuestionBanks = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        pageNo: 0,
        limit: 10000,
      };
      let response = await ExamServices.getAllQuestionBanks(payload, authToken);
      if (response.success) {
        setAllExams(response.data);
        return;
      }
      alert(response.message);
    } catch (err) {
      console.log("Error coming while fetching course details", err);
    }
  };

  //Fn to check and uncheck all checkboxes
  const handleCheckBoxes = (selectedRows) => {
    listOfCheckedCourses = selectedRows;
    setDisableBulkActionBtn(selectedRows.length > 0 ? false : true);
  };

  // To sort data as per date
  const handleSort = (data) => {
    const mapping = {
      "Date Added": "createdAt",
      "Last modified date": "updatedAt",
    };
    var id = data[0]?.id ? mapping[data[0].id] : "";
    setSortBy(id);
    setIsSorted(data[0]?.desc);
  };

  const handleDeleteBulk = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let response = await CourseServices.deleteCourses(
        { courseIds: listOfCheckedCourses.map((item) => item._id) },
        authToken
      );
      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        getAllCourses();
        // listOfCheckedCourses = [];
        document.getElementById("bulkCheckbox").checked = false;
        setDisableBulkActionBtn(true);
        return;
      }
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (err) {
      console.log("Error coming while deleting the courses", err);
    }
  };

  //Fn to redirect to course category home page
  const setDefaultNavigation = (defaultComponent) => {
    return navigationData.map((item) => {
      if (item.component === defaultComponent) {
        return { ...item, default: true };
      }
      return item;
    });
  };

  const handleRedirectToHome = (event, courseId, defaultComponent) => {
    //Handle setting default page while navigating
    let finalNavigationData = [];
    finalNavigationData = navigationData;
    if (defaultComponent !== "none") {
      finalNavigationData = setDefaultNavigation(defaultComponent);
    } else {
      // VAR x ONLY FOR TESTING
      // let x = 'VideoComments';
      // finalNavigationData = setDefaultNavigation(x);
      finalNavigationData = setDefaultNavigation("CourseDetails");
    }

    if (courseId === "none") {
      navigate("/course", { state: { finalNavigationData } });
    } else {
      navigate("/course/" + courseId, { state: { finalNavigationData } });
    }
  };

  // All Pop Up related functions
  // To close the popup container
  const handlePopUpClose = () => {
    setIsEditTitlePopUpVisible(false);
    setIsEditExamPopUpVisible(false);
    setIsEditMnemonicPopUpVisible(false);
    setIsEditSeriesPopUpVisible(false);
    setIsEditVisibilityPopUpVisible(false);
    setIsSettingPopUpVisible(false);
  };

  // To handleSave buttons on the popup
  const handlePopUpSave = async (response) => {
    var keysToUpdate = [];
    if (response?.success) {
      let updatedAllCourses = [];
      if (isEditVisibilityPopUpVisible) {
        keysToUpdate = ["visibility"];
        updatedAllCourses = produce(allCourses, (draft) => {
          draft.data = draft.data.map((data) => {
            if (response.courseIds.includes(data._id)) {
              keysToUpdate.map((key) => {
                data[key] = response.data[key];
              });
            }
            return data;
          });
        });
      } else {
        if (isEditTitlePopUpVisible) {
          keysToUpdate = [
            "title",
            "rectangleThumbnailUrl",
            "squareThumbnailUrl",
            "internalTitle",
          ];
        } else if (isEditMnemonicPopUpVisible) {
          keysToUpdate = ["mnemonic"];
        } else if (isEditExamPopUpVisible) {
          keysToUpdate = ["examId"];
        } else if (isEditSeriesPopUpVisible) {
          keysToUpdate = ["content"];
          response.data._id = response.data.courseId;
        }

        updatedAllCourses = produce(allCourses, (draft) => {
          draft.data = draft.data.map((data) => {
            if (response.data._id === data._id) {
              keysToUpdate.map((key) => {
                data[key] = response.data[key];
              });
            }
            return data;
          });
        });
      }
      props.updateAllCourses(updatedAllCourses);
      var deferredResponse = defer({ res: updatedAllCourses });
      setDeferedData(deferredResponse);
    }

    handlePopUpClose();
    // getAllCourses();
    setDisableBulkActionBtn(true);
    listOfCheckedCourses = [];
  };

  // Open popUps
  const handleTitleClick = (event, course) => {
    handlePopUpClose();
    setIsEditTitlePopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    setSelectedCourse(course);
    event.stopPropagation();
  };

  const handleAbbreviationClick = (event, course) => {
    handlePopUpClose();
    setIsEditMnemonicPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    setSelectedCourse(course);
    event.stopPropagation();
  };

  const handleVisibilityClick = (event, type, data) => {
    handlePopUpClose();
    setIsEditVisibilityPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    if (type == "bulk") {
      visibilityCourseList = listOfCheckedCourses.map((item) => item._id);
    } else {
      visibilityCourseList = [];
      visibilityCourseList[0] = data._id;
    }
    event.stopPropagation();
  };

  const handleSeriesClick = (event, course) => {
    handlePopUpClose();
    setIsEditSeriesPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);

    setSelectedCourse(course);
    event.stopPropagation();
  };

  const handleExamClick = (event, course) => {
    handlePopUpClose();
    setIsEditExamPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);

    setSelectedCourse(course);
    event.stopPropagation();
  };

  const handleSettingClick = (event) => {
    handlePopUpClose();
    setIsSettingPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };

  const showThumbnailToggle = () => {
    setShowThumbnail(!showThumbnail);
  };

  const duplicateCourse = async () => {
    if (disableBulkActionBtn) {
      return;
    }
    if (listOfCheckedCourses.length > 1) {
      Swal.fire({
        icon: "info",
        title: "Select only one course to create duplicate",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        courseId: listOfCheckedCourses[0].courseId,
      };
      let response = await CourseServices.duplicateCourse(payload, authToken);
      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 1500,
        });
        getAllCourses();
        // listOfCheckedCourses = [];
        setDisableBulkActionBtn(true);
        isSettingPopUpVisible(false);

        return;
      }
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (err) {
      console.log("Error coming while duplicating the courses", err);
    }
  };

  const handleSearch = async (data) => {
    let updatedFilters = produce(props.filterInformation, (draft) => {
      draft.page = 0;
      draft.searchText = data.text;
    });
    props.updateFilterInformation(updatedFilters);
  };

  const { courseSet, allCourses } = props;

  return (
    <>
      <CoursesView
        deferedData={deferedData}
        handleRedirectToHome={handleRedirectToHome}
        allCourses={allCourses}
        allSeries={allSeries}
        allExams={allExams}
        courseSet={courseSet}
        showThumbnail={showThumbnail}
        listOfCheckedCourses={visibilityCourseList}
        searchText={props.filterInformation.searchText}
        sortBy={sortBy}
        isSorted={isSorted}
        handleSearch={handleSearch}
        handleSort={handleSort}
        // Start: Control Pop Up
        handlePopUpClose={handlePopUpClose}
        handlePopUpSave={handlePopUpSave}
        popUpPosition={popUpPosition}
        isEditTitlePopUpVisible={isEditTitlePopUpVisible}
        isEditExamPopUpVisible={isEditExamPopUpVisible}
        isEditMnemonicPopUpVisible={isEditMnemonicPopUpVisible}
        isEditSeriesPopUpVisible={isEditSeriesPopUpVisible}
        isEditVisibilityPopUpVisible={isEditVisibilityPopUpVisible}
        isSettingPopUpVisible={isSettingPopUpVisible}
        handleTitleClick={handleTitleClick}
        handleAbbreviationClick={handleAbbreviationClick}
        handleExamClick={handleExamClick}
        handleVisibilityClick={handleVisibilityClick}
        handleSeriesClick={handleSeriesClick}
        handleSettingClick={handleSettingClick}
        showThumbnailToggle={showThumbnailToggle}
        duplicateCourse={duplicateCourse}
        selectedCourse={selectedCourse}
        // END

        // Fullscreen
        fullscreenElementRef={fullscreenElementRef}
        toggleFullscreen={() => toggleFullscreen(fullscreenElementRef)}
        //START : UseStates & fn to control the functionality of upating visibility of video
        visibilityPopupContainerRef={visibilityPopupContainerRef}
        is_VisibilityPopupContainerRef_Visible={
          is_VisibilityPopupContainerRef_Visible
        }
        visibilityPopupContainerRef_Position={
          visibilityPopupContainerRef_Position
        }
        internalIsPublic={internalIsPublic}
        internalIsUnlisted={internalIsUnlisted}
        disableSaveBtnOfVisibility={disableSaveBtnOfVisibility}
        disableBulkActionBtn={disableBulkActionBtn}
        handleDeleteBulk={handleDeleteBulk}
        //END : Pagination useStates

        //START : UseStates & fn to control the functionality of checkboxes
        handleCheckBoxes={handleCheckBoxes}
        //END : UseStates & fn to control the functionality of checkboxes
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    allCourses: state.course.allCourses,
    totalNumberOfCourses: state.course.totalNumberOfCourses,
    courseSet: state.course.courseSet,
    isSorted: state.course.isSorted,

    filterInformation: state.course.filterInformation,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAllCourses: CoursesActions.updateAllCourses,
      updateTotalNumOfCourses: CoursesActions.updateTotalNumOfCourses,
      updateCourseSet: CoursesActions.updateCourseSet,
      updateIsSorted: CoursesActions.updateIsSorted,
      updateFilterInformation: CoursesActions.updateFilterInformation,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
