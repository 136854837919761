const initialState = {
	filters:{page: 0,
		limit:10,
		sortBy: 'createdAt',
		isSorted: false,
		searchText: '',
		startDate: '',
		endDate: '',
		adminType: '',
		requestType:'',
		pageType:'',
		requestStatus:'',
	}
};

const ApprovalReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_APPROVAL_FILTERS':
			return{
				...state,
				filters: action.payload.filters,
			}
		case 'RESET_APPROVAL_REDUCER':
			return initialState;
		default:
			return state;
	}
};

export default ApprovalReducer;
