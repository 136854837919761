import React, { useEffect, useState } from 'react';

import LeadActivityView from './view';

function LeadActivity(props) {
	const [activityData, setActivityData]=useState([]);

	useEffect(()=>{
		setActivityData(groupEntriesByDate([...props.leadActivityData].reverse()))
	},[props.leadActivityData])

	function groupEntriesByDate(entries) {
		const groups = {};
	
		// Iterate through entries
		entries.forEach(entry => {
			const date = new Date(entry.createdAt).toLocaleDateString();
			if (!groups[date]) {
				groups[date] = [entry]; // Create new array if date group doesn't exist
			} else {
				groups[date].push(entry); // Add entry to existing date group
			}
		});
	
		// Convert groups object to array of arrays
		const result = Object.values(groups);
		return result;
	}

	return (
		<>
			<LeadActivityView 
				activityData = {activityData}/>
		</>
	);
}

export default LeadActivity;
