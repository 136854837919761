import React, { useState } from 'react';

import CommentReplyInputView from './view';

function CommentReplyInput(props) {
	const { replyContainerId, ProfileImgPlaceholder } = props;
	const [userComment, setUserComment] = useState('');

	const handleUserCommentChange = (event) => {
		setUserComment(event.target.value);
	};

	const handleUserCommentCancleBtn = (event, replyContainerId) => {
		setUserComment('');

		if (document.getElementById('replyContainerId_' + replyContainerId)) {
			let replyContainer = document.getElementById(
				'replyContainerId_' + replyContainerId
			);
			replyContainer.style.display = 'none';
		}
	};

	const handleCommentReplyToDb = (event, docId) => {
		// Here docId is either comment or reply document id
		props.handleAddReply(userComment, docId, props.replyType, props.commentId);
		handleUserCommentCancleBtn(event, replyContainerId);
	};

	return (
		<CommentReplyInputView
			ProfileImgPlaceholder={ProfileImgPlaceholder}
			replyContainerId={replyContainerId}
			handleUserCommentChange={handleUserCommentChange}
			userComment={userComment}
			handleUserCommentCancleBtn={handleUserCommentCancleBtn}
			handleCommentReplyToDb={handleCommentReplyToDb}
		/>
	);
}

export default CommentReplyInput;
