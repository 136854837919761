import React, { useEffect } from 'react';

import './style.css';
import DynamicTabs from '@components/DynamicTabs';

import AnalyticsOverView from '@pages/Analytics/Overview'
import AnalyticsLeads from '@pages/Analytics/Leads'
import AnalyticsGeography from '@pages/Analytics/Geography'

import { checkLoginAndRole } from "@helpers/Login/";
function AnalyticsView(props){

	useEffect(() => {
		checkLoginAndRole('analytics');
	}, []);

    return(
        <div className='BSSPageMainContainer bg-white' ref={props.fullscreenElementRef}>
            <h4 className='mb-3'>Analytics</h4>
            <DynamicTabs
					allTabs={props.folders}
					activeTab={props.activeTab}
					onTabClick={props.handleTabClick}
				/>
			{props.activeTab._id==='1'? <AnalyticsOverView />
			 : props.activeTab._id==='2'? <AnalyticsLeads />
			 : props.activeTab._id==='3'? <AnalyticsGeography />
			 : ''
			}
			
        </div>
    )
}

export default AnalyticsView