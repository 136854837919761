import { useEffect } from 'react';
import HomeView from './view';
import { useNavigate } from 'react-router-dom';
import SidebarNavigation from '@components/SidebarNavigation';
import { decryptText } from '@library/enc-dec';

function Home() {
	const navigate = useNavigate();
	useEffect(() => {
		checkLogin();
	}, []);

	const checkLogin = () => {
		try {
			if (localStorage.getItem('aEmediat') && localStorage.getItem('aEmediatt')) {
				let getaEmediatt = decryptText(localStorage.getItem('aEmediatt'));
				let checkLoginExpiry = Date.now() - getaEmediatt;
				checkLoginExpiry = checkLoginExpiry / (1000 * 60 * 60 * 24);
				if (checkLoginExpiry < 8) {
					navigate('/');
				} else {
					window.localStorage.removeItem('aEmediat');
					window.localStorage.removeItem('aEmediatt');
					navigate('/login');
				}
			} else {
				navigate('/login');
			}
		} catch (e) {
			console.error('Unable to access localStorage');
		}
	};
	return (
		<>
			<SidebarNavigation />
			<HomeView />
		</>
	);
}

export default Home;
