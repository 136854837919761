const initialState = {
	allTests: [],
	testsSet: [],
	title: '',
	duration: {},
	passPercentage: 0,
	thumbnailUrl: null,
	displayQuestions: 0, // Display questions
	declarationResultInDays: 0,
	hideResultInDays: 0,
	instructions: {},
	passedMessage: '',
	failedMessage: '',
	inSequence: false,
	questionsSet: [],
	pageNo: 0,
	filterInformation:{page: 0,
		limit:20,
		sortBy: '',
		isSorted: true,
		searchText: ''}
};

const TestReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_ALL_TestS':
			return {
				...state,
				allTests: action.payload.allTests,
			};
		case 'UPDATE_TOTAL_NUMBER_OF_TestS':
			return {
				...state,
				totalNumberOfTests: action.payload.totalNumberOfTests,
			};

		case 'UPDATE_Test_SET':
			return {
				...state,
				testsSet: action.payload.testsSet,
			};
		case 'UPDATE_Test_DETAILS':
			return {
				...state,
				title: action.payload.testData.title,
				duration: action.payload.testData.duration,
				passPercentage: action.payload.testData.passPercentage,
				displayQuestions: action.payload.testData.displayQuestions,
				thumbnailUrl: action.payload.testData.thumbnailUrl,
				declarationResultInDays: action.payload.testData.declarationResultInDays,
				hideResultInDays: action.payload.testData.hideResultInDays,
				instructions: action.payload.testData.instructions,
				passedMessage: action.payload.testData.passedMessage,
				failedMessage: action.payload.testData.failedMessage,
				inSequence: action.payload.testData.inSequence,
				questionsSet: action.payload.testData.questionsSet,
				appearedUsers: action.payload.testData.appearedUsers,
			};
		case 'UPDATE_Test_TITLE':
			return {
				...state,
				title: action.payload.title,
			};
		case 'UPDATE_Test_DURATION':
			return {
				...state,
				duration: action.payload.duration,
			};
		case 'UPDATE_Test_PASS_PERCENTAGE':
			return {
				...state,
				passPercentage: action.payload.passPercentage,
			};
		case 'UPDATE_DISPLAY_QUESTIONS':
			return {
				...state,
				displayQuestions: action.payload.displayQuestions,
			};
		case 'UPDATE_Test_THUMBNAIL_URL':
			return {
				...state,
				thumbnailUrl: action.payload.thumbnailUrl,
			};

		case 'UPDATE_RESULT_IN_DAYS':
			return {
				...state,
				declarationResultInDays: action.payload.declarationResultInDays,
			};
		case 'UPDATE_HIDE_RESULT_IN_DAYS':
			return {
				...state,
				hideResultInDays: action.payload.hideResultInDays,
			};
		case 'UPDATE_Test_INSTRUCTIONS':
			return {
				...state,
				instructions: action.payload.instructions,
			};
		case 'UPDATE_Test_PASSED_MESSAGE':
			return {
				...state,
				passedMessage: action.payload.passedMessage,
			};
		case 'UPDATE_Test_FAILED_MESSAGE':
			return {
				...state,
				failedMessage: action.payload.failedMessage,
			};
		case 'UPDATE_Test_QUESTIONS_IN_SEQUENCE':
			return {
				...state,
				inSequence: action.payload.inSequence,
			};
		case 'UPDATE_Test_QUESTIONS_SET':
			return {
				...state,
				questionsSet: action.payload.questionsSet,
			};
		case 'UPDATE_Test_PAGE_NUMBER':
			return {
				...state,
				pageNo: action.payload.pageNo,
			};
		case 'UPDATE_TEST_FILTER_INFORMATION':
			return {
				...state,
				filterInformation: action.payload.filterInformation,
			};
		case 'RESET_TestS_REDUCER':
			return initialState;

		default:
			return state;
	}
};

export default TestReducer;
