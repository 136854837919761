import React from 'react';
import { Link } from 'react-router-dom';

import './style.css';

import TextInputV2 from '@components/TextInputV2';

import logo from '@assets/navbar/mobileHeaderLogo.png';
import PopUp from '@components/PopUp';
import PlusIcon from '@assets/common/plusIconGrey.png';
import ImageInput from '@components/ImageInput';
import SaveBtn from '@components/SaveBtn'
import Image from '@components/Image';

function SEOView(props) {
	return (
		<div className='rightHandRightPageMainContainer flex-grow-1 gap-3 d-flex flex-column'
			style={{ maxWidth: 'none' }}>
			<div className='topContainer'>
				<h4 className='heading'>Blogs</h4>
				<div>
					<Link
						style={{ textDecoration: 'none' }}
						className='cancelBtn'
						to='/content?tab=courses'>
						Cancel
					</Link>
					<SaveBtn onClick={props.handleSave}
							  text={"Save"}
							  altText={"Saving..."}
							  disabled={props.isFormSaving}
						/>
				</div>
			</div>
			<div className='d-flex gap-3 pb-3 flex-grow-1'
				style={{ maxWidth: 'none', borderTop: '1px solid #e5e5e5', overflow:'scroll' }}>
				<div className='d-flex ms-3' style={{ width: '100%', maxWidth: '729px', minWidth:'729px' }}>
					<div className='marginLeftContainer w-100 d-flex gap-3 flex-column mt-5 pt-2'>
						{/* START : Error container */}
						{props.formValuesEmpty && (
							<div className='incompleteFieldsError'>
								<span>All input fields are required</span>
							</div>
						)}
						{/* END : Error container */}
						<div className='border d-flex gap-4 p-2 rounded' style={{height: '150px'}}>
							<div className='d-flex overflow-hidden flex-grow-1 flex-column '>
								<div className='d-flex gap-2 align-items-center'>
									<div className='d-inline-flex rounded-circle bg-light border' style={{height: '30px', width:'30px'}}>
										<img src={logo} className='img-fluid m-1'/>
									</div>
									<div>
										<div className='text-success' style={{fontWeight:'500'}}>eMediskill</div>
										<div className='text-success' style={{fontSize:'11px'}}>{props.SEOInfo.seoUrlSlug}</div>
									</div>
								</div>
								<div style={{textOverflow:'ellipsis', textWrap:'nowrap', overflow:'hidden', color:'#1A0DAB', fontWeight:'500'}}>
									{props.SEOInfo.seoTitleTag}</div>
								<div 
								style={{textOverflow:'ellipsis', overflow:'hidden', color:'#4D517C', fontSize:'14px', wordWrap:'break-word'}}>
								{props.SEOInfo.seoMetaDescription}</div>
							</div>
							<div style={{height:'130px', width: '130px', backgroundColor:'#dedede'}} 
							className='rounded ms-auto flex-shrink-0'
							>
								<Image src={props.SEOInfo.seoThumbnailUrl} defaultSrc={''} 
										style={{
											height: '100%',
											width: '100%'
										}}/>
							</div>
						</div>
						
						<div className='d-flex gap-3 mt-5'>
							<div className='d-flex flex-column w-100'>
								<TextInputV2
								placeholder='Url slug'
								required= {true}
								value={props.SEOInfo.seoUrlSlug}
								onChange={(event)=>{props.handleChange(event.target.value, 'seoUrlSlug')}}
								/>
								<span className='ms-auto textColorBlue'>{props.SEOInfo?.seoUrlSlug?.length}/100</span>
							</div>
							<div className='mt-3 textColorBlue'>
								i
							</div>
						</div>
						
						<div className='d-flex gap-3'>
							<div className='d-flex flex-column w-100'>
								<TextInputV2
								placeholder='Title tag'
								required= {true}
								value={props.SEOInfo.seoTitleTag}
								onChange={(event)=>{props.handleChange(event.target.value, 'seoTitleTag')}}
								/>
								<span className='ms-auto textColorBlue'>{props.SEOInfo?.seoTitleTag?.length}/100</span>
							</div>
							<div className='mt-3 textColorBlue'>
								i
							</div>
						</div>
						
						<div className='d-flex gap-3'>
							<div className='d-flex flex-column w-100'>
								<TextInputV2
								placeholder='Meta description'
								required= {true}
								value={props.SEOInfo.seoMetaDescription}
								onChange={(event)=>{props.handleChange(event.target.value, 'seoMetaDescription')}}
								/>
								<span className='ms-auto textColorBlue'>{props.SEOInfo?.seoMetaDescription?.length}/256</span>
							</div>
							<div className='mt-3 textColorBlue'>
								i
							</div>
						</div>

						<div className="mt-2">
							<h4 className='subHeading'>
								Preview thumbnail
								<span className="inputSubTitle ms-1">Upload 1:1 aspect ratio only</span>
							</h4>
							<div className="imageCarousel">
								<div className="addCarouselImage" 
									style={{backgroundColor : 'white',
										backgroundImage: props.SEOInfo?.seoThumbnailUrl != null ?
														typeof props.SEOInfo.seoThumbnailUrl === 'string' ? 
														`url(${props.SEOInfo.seoThumbnailUrl})`
														:`url(${URL.createObjectURL(props.SEOInfo.seoThumbnailUrl)})`
														:`url(${PlusIcon})`, 
										height:'81px', width: '81px',
										backgroundSize: props.SEOInfo?.seoThumbnailUrl? 'contain' : '',
									}}
									onClick={props.handleOpenPopUp}
								></div>
							</div>
						</div>
						
						
						<TextInputV2
							placeholder='og:title'
							value={props.SEOInfo.seoOGTitle}
							onChange={(event)=>{props.handleChange(event.target.value, 'seoOGTitle')}}
						/>
						<div className="form-floating" style={{paddingBottom:'10%'}}>
							<textarea className="form-control" id="seoOGDescription"
							placeholder='og:description'
							value={props.SEOInfo.seoOGDescription}
							onChange={(event)=>{props.handleChange(event.target.value, 'seoOGDescription')}}
							style={{height: '100px'}}
							>
							</textarea>
						    <label for="seoOGDescription">og:description</label>
						</div>

					</div>
				</div>
				<div className='p-2 flex-shrink-1 d-flex justify-content-center flex-column'>
					<h5>HTML</h5>
					<pre className='p-2 bg-dark text-light' style={{textWrap:'wrap'}}>
						{props.parsedHtml}
					</pre>
				</div>
			</div>

			<PopUp
                visible={props.isPopUpVisible}
                top={props.popUpPosition.top}
                left={props.popUpPosition.left}
                handleOutSideClick={()=>{}}>
                <div className="full-page-overlay">
                    <ImageInput
                        handleClose={props.handlePopUpClose}
                        handleAdd={(event, files)=>props.handleChange(files,'seoThumbnailUrl')} 
                    />
                </div>
            </PopUp>	
		</div>

	);
}

export default SEOView;
