export const updateAllExams = (value) => {
	return {
		type: 'UPDATE_ALL_EXAMS',
		payload: {
			allExams: value,
		},
	};
};

export const updateTotalNumOfExams = (value) => {
	return {
		type: 'UPDATE_TOTAL_NUMBER_OF_EXAMS',
		payload: {
			totalNumberOfExams: value,
		},
	};
};

export const updateExamsSet = (value) => {
	return {
		type: 'UPDATE_EXAM_SET',
		payload: {
			examsSet: value,
		},
	};
};

export const updateExamDetails = (value) => {
	return {
		type: 'UPDATE_EXAM_DETAILS',
		payload: {
			examData: value,
		},
	};
};

export const updateExamTitle = (value) => {
	return {
		type: 'UPDATE_EXAM_TITLE',
		payload: {
			title: value,
		},
	};
};

export const updateExamDuration = (value) => {
	return {
		type: 'UPDATE_EXAM_DURATION',
		payload: {
			duration: value,
		},
	};
};

export const updateExamPassPercentage = (value) => {
	return {
		type: 'UPDATE_EXAM_PASS_PERCENTAGE',
		payload: {
			passPercentage: value,
		},
	};
};

export const updateDisplayQuestions = (value) => {
	return {
		type: 'UPDATE_DISPLAY_QUESTIONS',
		payload: {
			displayQuestions: value,
		},
	};
};

export const updateExamThumbnailUrl = (value) => {
	return {
		type: 'UPDATE_EXAM_THUMBNAIL_URL',
		payload: {
			thumbnailUrl: value,
		},
	};
};

export const updateResultInDays = (value) => {
	return {
		type: 'UPDATE_RESULT_IN_DAYS',
		payload: {
			declarationResultInDays: value,
		},
	};
};

export const updateHideResultInDays = (value) => {
	return {
		type: 'UPDATE_HIDE_RESULT_IN_DAYS',
		payload: {
			hideResultInDays: value,
		},
	};
};

export const updateExamInstructions = (value) => {
	return {
		type: 'UPDATE_EXAM_INSTRUCTIONS',
		payload: {
			instructions: value,
		},
	};
};

export const updateExamPassedMessage = (value) => {
	return {
		type: 'UPDATE_EXAM_PASSED_MESSAGE',
		payload: {
			passedMessage: value,
		},
	};
};

export const updateExamFailedMessage = (value) => {
	return {
		type: 'UPDATE_EXAM_FAILED_MESSAGE',
		payload: {
			failedMessage: value,
		},
	};
};

export const updateExamQuestionsInSequence = (value) => {
	return {
		type: 'UPDATE_EXAM_QUESTIONS_IN_SEQUENCE',
		payload: {
			inSequence: value,
		},
	};
};

export const updateExamQuestionsSet = (value) => {
	return {
		type: 'UPDATE_EXAM_QUESTIONS_SET',
		payload: {
			questionsSet: value,
		},
	};
};

export const resetExamsReducer = (value) => {
	return {
		type: 'RESET_EXAMS_REDUCER',
		payload: {
			questionsSet: value,
		},
	};
};

export const updatePageNo = (value) => {
	return {
		type: 'UPDATE_EXAM_PAGE_NUMBER',
		payload: {
			pageNo: value,
		},
	};
};


export const updateFilterInformation = (value) => {
	return {
		type: 'UPDATE_EXAM_FILTER_INFORMATION',
		payload: {
			filterInformation: value,
		},
	};
};
