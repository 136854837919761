import React, { useEffect, useState } from "react";
import { useParams, defer } from "react-router-dom";
import UserSeriesView from "./view";
import { useDispatch, useSelector } from "react-redux";
import * as UserActions from "@redux/actions/Users";

import * as SeriesServices from "@services/Series";
import { decryptText } from "@library/enc-dec";

function UserSeries(props) {
  var { id } = useParams();
  const [userData, setUserData] = useState({});
  const [deferredData, setDeferredData] = useState({});
  const dispatch = useDispatch();

  const selectedPurchaseIds = useSelector(
    (state) => state.user.selectedPurchaseIds
  );

  useEffect(() => {
    getUserSeriesData();
  }, []);

  const getUserSeriesData = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));

      const payload = {
        pageNo: 0,
        limit: 10,
        userId: id,
      };

      var deferedResponse = defer({
        res: SeriesServices.getSeriesByUserId(payload, authToken),
      });

      setDeferredData(deferedResponse);

      deferedResponse.data.res
        .then((response) => {
          setUserData(response.data);
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (err) {
      console.log("Error coming while getUserWatchHistory()", err);
    }
  };

  const handleSelectedSeries = (data) => {
    try {
      const purchaseIds = data.map((item) => item._id);
      dispatch(UserActions.updateSelectedPurchaseIds(purchaseIds));
    } catch (err) {
      console.log("Error coming while handleSelectedSeries()", err);
    }
  };

  return (
    <>
      <UserSeriesView
        userData={userData}
        deferredData={deferredData}
        handleSelectedSeries={handleSelectedSeries}
        handlePopupOpen={props.handlePopUpOpen}
      />
    </>
  );
}

export default UserSeries;
