import React, { useEffect, useState } from 'react';

import EditNumberPopUpView from './view';
import Swal from 'sweetalert2';

import * as UserServices from '@services/Users';
// import * as CoursesServices from '@services/Course';

import { decryptText } from '@library/enc-dec';

function EditNumberPopUp(props) {
	const [error, setError] = useState('');
	const [countryCode , setCountryCode] = useState(props.currentCountryCode);
	const [number , setNumber] = useState(props.currentPhoneNumber);

	useEffect(()=>{
		setCountryCode(props.currentCountryCode)
		setNumber(props.currentPhoneNumber)
	},[props.userId, props.currentPhoneNumber, props.currentCountryCode])

	const handleCloseBtn = () => {
		props.handleClose()
	};

	const handleSaveBtn = async () => {
		if (number.length != 10) {
			setError('Number is incorrect');
			setTimeout(function () {
				setError('');
			}, 2000);
		} else {

			const payload = {
				userId: props.userId,
				fields :[{
					key: props.numberType=='phone'? 'contactNumber':'whatsAppNumber',
					value: number,
				},{
					key: props.numberType=='phone'? 'countryCode':'whatsAppCountryCode',
					value: countryCode,
				}]
			}

			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const response = await UserServices.updateUserFields(payload, authToken,);
			if(response.success){
				Swal.fire({icon: "success",title: "Number updated",showConfirmButton: false,timer: 2500});			
				props.handleSave({countryCode: countryCode, number:number})
			}else{
				Swal.fire({icon: "error",title: response.message,showConfirmButton: false,timer: 2500});
			}
			
		}
	};

	return (
		<EditNumberPopUpView
				error={error}
				countryCode = {countryCode}
				number = {number}
				numberType={props.numberType}
				handleCountryCodeChange = {setCountryCode}
				handleNumberChange = {setNumber}
				handleCloseBtn={handleCloseBtn}
				handleSaveBtn={handleSaveBtn}
			/>
	);
}

export default EditNumberPopUp;
