const initialState = {
	allCourses: [],
	totalNumberOfCourses: 0,
	courseSet: [],
	pageNoCourses: 0,
	isSorted: false,

	filterInformation:{page: 0,
		limit:20,
		sortBy: 'updatedAt',
		isSorted: false,
		searchText: ''}
};

const CourseReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_ALL_COURSES':
			return {
				...state,
				allCourses: action.payload.allCourses,
			};
		case 'UPDATE_TOTAL_NUMBER_OF_COURSES':
			return {
				...state,
				totalNumberOfCourses: action.payload.totalNumberOfCourses,
			};
		case 'UPDATE_COURSE_SET':
			return {
				...state,
				courseSet: action.payload.courseSet,
			};
		case 'UPDATE_COURSE_PAGE_NUMBER':
			return {
				...state,
				pageNoCourses: action.payload.pageNoCourses,
			};
		case 'UPDATE_COURSE_IS_SORTED':
			return {
				...state,
				isSorted: action.payload.isSorted,
			};
		case 'UPDATE_COURSE_FILTER_INFORMATION':
			return {
				...state,
				filterInformation: action.payload.filterInformation,
			};
		case 'RESET_COURSES_REDUCER':
			return initialState;

		default:
			return state;
	}
};

export default CourseReducer;
