import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';
import EditIcon from '@assets/common/editPencilIcon.png';


export const navigationData = [
    {
        "title" : "Back to Super Admin",
        "route" : "/superAdmin",
        "default" : false,        
        "icon" : GoBackArrow
    },
    {
        "title" : "Details",
        "component" : "RequestDetails",
        "default" : true,        
        "icon" : EditIcon
    },
]
