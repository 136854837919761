import GoBackArrow from "@assets/sidebarNavigation/goBackArrow.png";
import EditPencilIcon from "@assets/common/editPencilIcon.png";

//Object to handle navigation
export const navigationData = [
  {
    title: "Back to Content",
    route: "/content?tab=courseCategory",
    default: false,
    icon: GoBackArrow,
  },
  {
    title: "Details",
    component: "CourseCategoryDetails",
    default: true,
    icon: EditPencilIcon,
  },
];
