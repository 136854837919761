export const getAllQueries = async (payload, authToken) => {
  try {
    const {
      pageNo,
      limit,
      isSorted,
      sortBy,
      searchText,
      folderType,
      queryStatus,
      userId,
      startDate,
      endDate,
    } = payload;
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/getAllQueries?queryStatus=${queryStatus}&pageType=customerCare&folderType=${folderType}&pageNo=${pageNo}&limit=${limit}&searchText=${searchText}&sortBy=${sortBy}&isSorted=${isSorted}&userId=${userId}&startDate=${startDate}&endDate=${endDate}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Query : getQueries()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getQueryById = async (id, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/getQueryById/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Query : getQueries()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateQueriesStatus = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateQueriesStatus/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Query : updateQueriesStatus()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateCustomerCareFields = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateCustomerCareFields`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Query : updateCustomerCareFields()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const addCommentOnQuery = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/addCommentOnQuery`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Query : addCommentOnQuery()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getQueriesFoldersCount = async (payload, authToken) => {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}/getQueriesFoldersCount?userId=${payload?.userId}`;

    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from EXam : Get all getQueriesFoldersCount",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};
