import React from "react";

import './faqHome.css';

function FaqHomeView(){
    return(
        <>
            {/* <h1 style={{marginLeft:'240px'}}>FaqHomeView</h1> */}
        </>
    )
}

export default FaqHomeView;