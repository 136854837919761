import React from "react";
import { useState, useEffect, useRef } from "react";

import BasicTableGridView from "./view";

import IndeterminateCheckbox from "../IndeterminateCheckbox";

function BasicTableGrid(props) {
  let { gridId, columns } = props;
  const [updatedColumns, setUpdatedColumns] = React.useState(columns);
  const [isEffectComplete, setIsEffectComplete] = React.useState(false);
  const [sorting, setSorting] = React.useState(props.initialSorting);
  const [columnVisibility, setColumnVisibility] = React.useState({});

  const [tableData, setTableData] = React.useState(null);

  useEffect(() => {
    const storedColSizeList = localStorage.getItem(gridId);

    if (
      props.isCheckBoxVisible === undefined ||
      props.isCheckBoxVisible == true
    ) {
      columns.unshift({
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
              id: row.id,
              isColorEnabled: props.isColorEnabled
                ? props.isColorEnabled
                : false,
            }}
          />
        ),
        size: 35,
        enableResizing: false,
      });
    }

    if (storedColSizeList) {
      const colSizeList = JSON.parse(storedColSizeList);
      var updated = columns.map((column, index) => ({
        ...column,
        ...((index !== 0 || !props.isCheckBoxVisible) &&
          colSizeList[index] && { size: colSizeList[index] }),
      }));

      setUpdatedColumns(updated);
      setIsEffectComplete(true);
    } else {
      const colSizeList = props.initialSize;
      var updated = columns.map((column, index) => ({
        ...column,
        ...((index !== 0 || !props.isCheckBoxVisible) &&
          colSizeList[index] && { size: colSizeList[index] }),
      }));
      setUpdatedColumns(updated);
      setIsEffectComplete(true);
    }
  }, [columns]);

  useEffect(() => {
    props.onSortChange(sorting);
  }, [sorting]);

  return isEffectComplete ? (
    <>
      <BasicTableGridView
        props={props}
        columns={updatedColumns}
        isEffectComplete={isEffectComplete}
        tableData={tableData}
        setTableData={setTableData}
        sorting={sorting}
        setSorting={setSorting}
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
        // colSizeList = {colSizeList}
        // setColSizeList = {setColSizeList}
      />
    </>
  ) : (
    <></>
  );
}

export default BasicTableGrid;
