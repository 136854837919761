import React from "react";

import './courseHome.css';

function CourseHomeView(){
    return(
        <>
            {/* <h1 style={{marginLeft:'240px'}}>CourseHomeView</h1> */}
        </>
    )
}

export default CourseHomeView;