import React, { useEffect, useState, useRef  } from 'react';
import PopUpContainer from '@components/PopUpContainer';


import * as UsersServices from '@services/Users';
import * as ExamServices from '@services/Exam';
import { decryptText } from '@library/enc-dec';
import Swal from 'sweetalert2';

function ConfirmArchivePopUp(props) {
	const [formalRequestText, setFormalRequestText]=useState('')

	return (
		<PopUpContainer
						title={"Confirm archive"}
						isSearchEnabled={false}
						searchPlaceholder={""}
						isButtonsEnabled={true}
						style={{width:'431px', height:'auto'}}
						handleSearch={()=>{}}
						handleCancel={props.handlePopUpClose}
						handleSave={(e)=>props.handlePopUpSave(formalRequestText)}
						>
					<div className='flex-column gap-2'>
						Users moved to archive are not visible in search results. 
						{props.isFormalRequest &&
						<>Submit a proposal to administrative team for this action.
						<div className='border-top d-flex flex-column gap-2 py-2'>
							<div className='d-flex'>
								Submit formal request
								<span className='ms-auto'>Minimum 100 characters*</span>
							</div>
							
							<div className="form-floating" >
								<textarea className="form-control" placeholder="" id="cardDesc" style={{height:'158px'}}   
									value={formalRequestText}
									onChange={(e)=>setFormalRequestText(e.target.value)}
							></textarea>
								<label htmlFor="cardDesc" className='w-100'>This request will be sent to administrative team</label>
							</div>
						</div></>}
						</div>
				</PopUpContainer>
	);
}

export default ConfirmArchivePopUp;
