import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';
import EditPencilIcon from '@assets/common/editPencilIcon.png';

export const UserTestApiData = {
    "success" : true,
    "data" : [
        {
            _id : "1",
            thumbnailUrl : "https://static-cse.canva.com/blob/1287846/1600w-wK95f3XNRaM.jpg",
            title : "1_title",
            description : '1_description',
            status : "Expired on 10th",
            resultStatus: "show"
        },
        {
            _id : "2",
            thumbnailUrl : "https://static-cse.canva.com/blob/1287846/1600w-wK95f3XNRaM.jpg",
            title : "2_title",
            description : '2_description',
            status : "Expired",
            resultStatus: "show"
        },
        {
            _id : "3",
            thumbnailUrl : "https://static-cse.canva.com/blob/1287846/1600w-wK95f3XNRaM.jpg",
            title : "3_title",
            description : '3_description',
            status : "Active",
            resultStatus: "hide"
        }
    ]
};

export const navigationData = [
    {
        "title" : "Back to Users",
        "route" : "/users?tab=tests",
        "default" : false,        
        "icon" : GoBackArrow
    },
    {
        "title" : "Details",
        "component" : "ExamDetails",
        "default" : true,        
        "icon" : EditPencilIcon
    }
]