import React, { forwardRef } from 'react';

import './style.css';
import TextInputV2 from '@components/TextInputV2';


const ExamStatusPopUpView = forwardRef((props, ref) =>{
	
	const {newExamStatus, inputPercentage, showResultCheckBox, disableSaveBtn} = props
	const {handleExamStatusRadioBtns, handleInputPercentage, handleShowResultCheckBox,
		handleCancelBtn, handleSaveBtn, selectedExam, handleSelectedExam, allExams	 } = props

	return (
		<div>
			<div className='visibilityPopupContainer_inner d-flex flex-column gap-1 newFont'>
				<span className='popUpHeading'>Change Exam Status</span>
				<select className="seriesSelect" onChange={(event) => {
						handleSelectedExam(event.target.value);
					}}
					value={selectedExam}
					>
						<option value={''}>Select exams</option>
					{allExams.map((exam, index)=>{
								return <option key={index} value={exam._id}> {exam.title}</option>
					})}
					
				</select>
				<hr className='my-2'/>
				<span className='popUpHeading'>Set Exam Status to</span>

				<div className='form-check mt-2 d-flex gap-2 align-items-center'>
					<input
						onChange={(event) => {
							handleExamStatusRadioBtns(event, 'passed');
						}}
						checked={newExamStatus === 'passed' ? true : false}
						className='form-check-input'
						type='radio'
						name='examStatusRadioBtn'
						id='passedRadioBtn'
					/>

					<label className='form-check-label' htmlFor='passedRadioBtn'>
						Passed
					</label>

					<div className='d-flex align-items-center ms-auto gap-2'>
						Set % to <TextInputV2
							placeholder=''
							disabled={newExamStatus!=='passed'}
							value={newExamStatus==='passed'?inputPercentage:''}
							onChange={(event)=>handleInputPercentage(event.target.value)}
							size={'small'}
						/>
					</div>
				</div>

				<div className='form-check d-flex align-items-center gap-2'>
					<input
						onChange={(event) => {
							handleExamStatusRadioBtns(event, 'failed');
						}}
						checked={newExamStatus === 'failed' ? true : false}
						className='form-check-input'
						type='radio'
						name='examStatusRadioBtn'
						id='failedRadioBtn'
					/>
					<label className='form-check-label' htmlFor='failedRadioBtn'>
						Failed
					</label>
					<div className='d-flex align-items-center ms-auto gap-2'>
						Set % to <TextInputV2
							placeholder=''
							disabled={newExamStatus!=='failed'}
							value={newExamStatus==='failed'?inputPercentage:''}
							onChange={(event)=>handleInputPercentage(event.target.value)}
							size={'small'}
						/>
					</div>
				</div>

				<div className='form-check'>
					<input
						onChange={(event) => {
							handleExamStatusRadioBtns(event, 'unlocked');
						}}
						checked={newExamStatus === 'unlocked' ? true : false}
						className='form-check-input'
						type='radio'
						name='examStatusRadioBtn'
						id='unlockExamRadioBtn'
					/>
					<label className='form-check-label' htmlFor='unlockExamRadioBtn'>
						Unlock Exam
					</label>
				</div>

				<div className='form-check'>
					<input
						onChange={(event) => {
							handleExamStatusRadioBtns(event, 'locked');
						}}
						checked={newExamStatus === 'locked' ? true : false}
						className='form-check-input'
						type='radio'
						name='examStatusRadioBtn'
						id='noExamRadioBtn'
					/>
					<label className='form-check-label' htmlFor='noExamRadioBtn'>
						Lock Exam
					</label>
				</div>
			<hr/>
			<div style={{ float: 'right', marginTop: '10px', marginLeft: 'auto' }}>
				<button
					onClick={handleCancelBtn}
					className='cancleBtn'>
					Close
				</button>

				<button
					disabled={disableSaveBtn}
					style={{
						cursor: disableSaveBtn ? 'not-allowed' : 'pointer',
					}}
					id='saveVisibilityBtn'
					onClick={handleSaveBtn}
					className='saveBtn'>
					Save
				</button>
			</div>
			</div>

		</div>
	);
})

export default ExamStatusPopUpView;
