import React from 'react';
import LeftIcon from '@assets/common/tableNext.png'
import RightIcon from '@assets/common/tablePrevious.png'
import './style.css';

function DynamicTabsView(props) {
    const { allTabs, activeTab, onTabClick } = props.props;
    
    return (
        <div className='tabsContainer'
        onMouseEnter={() => props.setShowButtons(true)}
        onMouseLeave={() => props.setShowButtons(false)}
        >
        {props.showButtons?<img src={RightIcon} className="scroll-button left" onClick={props.scrollLeft}/> 
        : null}
        <div className="tabs" ref={props.containerRef}>
            {allTabs?.map((tab) => (
                <div
                    key={tab._id}
                    className={tab._id === activeTab._id ? "selected" : "none"}
                    onClick={() => onTabClick(tab)}
                    style={{textOverflow:'nowrap'}}
                >
                    {tab.title}
                    {tab.titleTag1 &&
                        <span
                            style={{ backgroundColor: tab.titleTag1.bgColor, color: tab.titleTag1.fontColor }}
                            className='titleTag1'>
                            {tab.titleTag1.text}
                        </span>
                    }
                </div>
            ))}
        </div>
        {props.showButtons?<img src={LeftIcon} className="scroll-button right" onClick={props.scrollRight}/>
        :null}
        </div>
    )
}

export default DynamicTabsView;