import React, { useState, useEffect, useRef } from 'react';
import { useNavigate,defer, useLocation, useSearchParams  } from 'react-router-dom';
import ExamView from './view';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ExamServices from '@services/Exam';
import * as ExamActions from '@redux/actions/Exam';

import { navigationData } from './data';
import { decryptText } from '@library/enc-dec';
import { toggleFullscreen} from '@helpers/common.js';
import Swal from 'sweetalert2';
import { produce } from 'immer';

var listOfCheckedExams = [];

function Exam(props) {
	const fullscreenElementRef = useRef(null);
	const navigate = useNavigate();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [deferedData, setDeferedData] = useState({});

	const [isSorted, setIsSorted] = useState(props.filterInformation.isSorted);
	const [sortBy, setSortBy] = useState(props.filterInformation.sortBy);
	
	const [disableBulkActionBtn, setDisableBulkActionBtn] = useState(true);

	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
        setSearchParams({
			page: props.filterInformation.page,
			limit: props.filterInformation.limit,
		  })
	}, []);

	useEffect(()=>{
		if(!Number(queryParams.get('limit')) && Number(queryParams.get('page')))
		{
			return
		}
		props.updateFilterInformation({
			page:Number(queryParams.get('page')), 
			limit:Number(queryParams.get('limit')), 
			sortBy, 
			isSorted, 
			searchText:props.filterInformation.searchText
		})
		
     },[Number(queryParams.get('limit')),Number(queryParams.get('page')), sortBy, isSorted])

	 useEffect(() => {
		if(props.filterInformation.limit>0){
			getAllQuestionBanks();
		}
			
	}, [props.filterInformation]);

	const getAllQuestionBanks = async () => {
		try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const payload = {
				pageNo: props.filterInformation.page, 
				limit: props.filterInformation.limit, 
				sortBy: props.filterInformation.sortBy, 
				isSorted: props.filterInformation.isSorted, 
				searchText: props.filterInformation.searchText
			}
			
			var deferedResponse = defer({
				res: ExamServices.getAllQuestionBanks(payload, authToken)
			});
			setDeferedData(deferedResponse);
		} catch (err) {
			console.log('Error coming from: getallQuestion()', err);
		}
	};

	const handleDelete = async () => {
		try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const payload = {
				questionBankIds : listOfCheckedExams.map(exam=>exam._id)
			}
			var response = await ExamServices.deleteQuestionBanks(payload, authToken)
			if(response.success)
			{
				getAllQuestionBanks()
				Swal.fire({icon: "success",title: response.message,showConfirmButton: false,timer: 2500});
            }else{
				Swal.fire({icon: "error",title: response.message,showConfirmButton: false,timer: 2500});
			}
		} catch (err) {
			console.log('Error coming from: getallQuestion()', err);
		}
	};

	//Fn to check and uncheck all checkboxes
	const handleCheckBoxes = (selectedRows) => {
		listOfCheckedExams = selectedRows;
		setDisableBulkActionBtn( selectedRows.length>0 ? false : true)	
	};

	// To sort data as per date
	const handleSort = (data) => {
		const mapping = {'Date Added': 'createdAt', 'Date Modified': 'updatedAt'}
		var id = data[0]?.id ? mapping[data[0].id] : ''
		setSortBy(id)
		setIsSorted(data[0]?.desc ? data[0].desc : false)
	};

	//Fn to redirct to course category home page
	const handleRedirectToHome = (event, examId, defaultComponent) => {
		

		if (examId === 'none') {
			navigate('/exam', { state: { navigationData } });
		} else {
			navigate('/exam/' + examId, { state: { navigationData } });
		}
	};

	const handleSearch = (data) => {
		let updatedFilters = produce(props.filterInformation, (draft) => {
			draft.page=0;
			draft.searchText= data.text;
		});
		props.updateFilterInformation(updatedFilters);
	};

	return (
		<>
			<ExamView
				handleRedirectToHome={handleRedirectToHome}
				deferedData={deferedData}
				isSorted={isSorted}
				sortBy={sortBy}
				handleSort={handleSort}
				
				searchText={props.filterInformation.searchText}
				handleSearch={handleSearch}
				
				disableBulkActionBtn={disableBulkActionBtn}
				//END : UseStates & fn to control the functionality of upating thumbnail of video
				//START : Pagination useStates
				handleCheckBoxes={handleCheckBoxes}
				// Fullscreen
				fullscreenElementRef={fullscreenElementRef}
				toggleFullscreen={()=>toggleFullscreen(fullscreenElementRef)}
				handleDelete={handleDelete}
				
				/>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		allExams: state.exam.allExams,
		totalNumberOfExams: state.exam.totalNumberOfExams,
		examsSet: state.exam.examsSet,
		pageNo: state.exam.pageNo,
		filterInformation: state.exam.filterInformation
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateAllExams: ExamActions.updateAllExams,
			updateTotalNumOfExams: ExamActions.updateTotalNumOfExams,
			updateExamsSet: ExamActions.updateExamsSet,
			updatePageNo: ExamActions.updatePageNo,
			updateFilterInformation: ExamActions.updateFilterInformation
			
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Exam);
