import React, { useEffect } from "react";

import PlusCircleIcon from "@assets/common/plusCircleIcon.png";
import DeleteIcon from "@assets/common/deleteIcon.png";
import SearchIcon from "@assets/common/search.png";

import { Link } from "react-router-dom";
import "./offerDetails.css";

import TextInputV2 from "@components/TextInputV2";
import PopUp from "@components/PopUp";
import PlusIcon from "@assets/common/plusIconGrey.png";
import SelectInput from "@components/SelectInput";
import ImageInput from "@components/ImageInput";
import SaveBtn from "@components/SaveBtn";

function OfferDetails(props) {
  return (
    <div
      className="rightHandRightPageMainContainer flex-grow-1 gap-3 d-flex flex-column"
      style={{ maxWidth: "none" }}
    >
      <div className="topContainer ">
        <h4 className="heading">Offer Details</h4>
        <div>
          <Link
            style={{ textDecoration: "none" }}
            className="cancelBtn"
            to="/offers"
          >
            Cancel
          </Link>
          <SaveBtn
            onClick={props.handleSave}
            text={"Save"}
            altText={"Saving..."}
            disabled={props.isFormSaving}
          />
        </div>
      </div>
      <div
        className="d-flex gap-3 flex-grow-1 overflow-scroll"
        style={{
          maxWidth: "100%",
          borderTop: "1px solid #e5e5e5",
        }}
      >
        <div
          style={{ width: "700px", marginLeft: "25px" }}
          className="courseDetails mt-4 d-flex gap-3 flex-column"
        >
          <TextInputV2
            id={"displayName"}
            placeholder={"Offer Display Name"}
            required={true}
            value={props.offer?.title}
            onChange={(event) =>
              props.handleChange(event.target.value, "title")
            }
          />
          <TextInputV2
            id={"internalName"}
            placeholder={"Offer Internal Name"}
            required={true}
            value={props.offer?.internalTitle}
            onChange={(event) =>
              props.handleChange(event.target.value, "internalTitle")
            }
          />
          <TextInputV2
            id={"description"}
            placeholder={"Offer card description"}
            required={true}
            value={props.offer?.description}
            onChange={(event) =>
              props.handleChange(event.target.value, "description")
            }
          />
          <TextInputV2
            id={"couponCode"}
            placeholder={"Coupon Code"}
            required={true}
            value={props.offer?.couponCode}
            onChange={(event) =>
              props.handleChange(event.target.value, "couponCode")
            }
          />
          <div className="visibilityContainer">
            <p style={{ fontWeight: "700" }}>
              Visibility<span style={{ color: "red" }}>*</span>{" "}
            </p>
            <div className="form-check">
              <input
                onChange={(event) => props.handleChange("public", "visibility")}
                checked={props.offer?.visibility === "public"}
                value="public"
                className="form-check-input"
                type="radio"
                id="public"
              />
              <label htmlFor="public">Public</label>
            </div>
            <div className="form-check">
              <input
                onChange={(event) =>
                  props.handleChange("unlisted", "visibility")
                }
                checked={props.offer?.visibility === "unlisted"}
                value="unlisted"
                className="form-check-input"
                type="radio"
                id="unlisted"
              />
              <label htmlFor="unlisted">Unlisted</label>
            </div>
            <div className="form-check">
              <input
                onChange={(event) =>
                  props.handleChange("disabled", "visibility")
                }
                checked={props.offer?.visibility === "disabled"}
                value="private"
                className="form-check-input"
                type="radio"
                id="private"
              />
              <label htmlFor="private">Disabled</label>
            </div>
          </div>
          <div style={{ width: "100%" }}>
          <SelectInput
            title={"Select Category for which offer is valid"}
            required={true}
            inputText={
              props.offer?.categories != [] && props.offer?.categories
                ? props.offer?.categories.map((catId) => {
                    return props.allCategories?.map((category) => {
                      return category._id === catId
                        ? category.title + ", "
                        : "";
                    });
                  })
                : "Select category"
            }
            onClick={(event) => props.handlePopUpOpen(event, "categorySelect")}
          />
          </div>

          <div className="mt-2">
            <h4 className="subHeading">
              Discount as per number of cart items
              <span className="text-danger">*</span>
              <span className="inputSubTitle ms-1">
                Minimum 1 bundle discount to be defined
              </span>
            </h4>
            {props.offer?.discounts?.map((discount, index) => {
              return (
                <div className="d-flex w-100 gap-2 g-2 align-items-center mb-2">
                  <TextInputV2
                    id={"numberOfCartItems"}
                    placeholder={"No. of items in cart"}
                    required={true}
                    value={discount.numberOfCartItems}
                    onChange={(event) =>
                      props.handleChange(
                        event.target.value,
                        "discountItems",
                        index
                      )
                    }
                  />
                  <TextInputV2
                    id={"discount"}
                    placeholder={"Discount in %"}
                    required={true}
                    value={discount?.discount}
                    onChange={(event) =>
                      props.handleChange(
                        event.target.value,
                        "discountValue",
                        index
                      )
                    }
                  />
                  <img
                    className="addReviewIcon"
                    src={PlusCircleIcon}
                    onClick={(event) => {
                      props.handleChange("Add", "discounts", index + 1);
                    }}
                  />
                  {index !== 0 ? (
                    <img
                      className="addReviewIcon"
                      src={DeleteIcon}
                      onClick={(event) => {
                        props.handleChange("Delete", "discounts", index);
                      }}
                    />
                  ) : null}
                </div>
              );
            })}
          </div>
          <div
            className="mt-2"
            style={{
              paddingBottom: "10%",
            }}
          >
            <h4 className="subHeading">
              Offer image
              <span className="inputSubTitle ms-1"></span>
            </h4>
            <div className="imageCarousel">
              <div
                className="addCarouselImage"
                style={{
                  backgroundColor: "white",
                  backgroundImage:
                    props.offer?.thumbnailUrl != null
                      ? typeof props.offer.thumbnailUrl === "string"
                        ? `url(${props.offer.thumbnailUrl})`
                        : `url(${URL.createObjectURL(
                            props.offer.thumbnailUrl
                          )})`
                      : `url(${PlusIcon})`,
                  height: "81px",
                  width: "81px",
                  backgroundSize: props.offer?.thumbnailUrl ? "contain" : "",
                }}
                onClick={(event) => props.handlePopUpOpen(event, "imageUpload")}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <PopUp
        visible={props.isPopUpVisible && props.popUp == "imageUpload"}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={() => {}}
      >
        <div className="full-page-overlay">
          <ImageInput
            handleClose={props.handlePopUpClose}
            handleAdd={(event, files) =>
              props.handleChange(files[0], "thumbnailUrl")
            }
          />
        </div>
      </PopUp>

      <PopUp
        visible={props.isPopUpVisible && props.popUp == "categorySelect"}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <div className="selectList">
          <div className="inputDiv searchDiv">
            <input
              type="text"
              value={props.searchText}
              onChange={(event) => {
                props.handleSearchText(event.target.value);
              }}
            />
            <img alt="DropDownIcon" src={SearchIcon} />
          </div>
          <div className="selectionList">
            {props.allCategories.map((course) =>
              course["title"]
                ?.toLowerCase()
                .includes(props.searchText.toLowerCase()) ? (
                <div>
                  <input
                    type="checkbox"
                    checked={props.offer?.categories?.includes(
                      String(course["_id"])
                    )}
                    onChange={(event) => {
                      props.handleChange(
                        course["_id"],
                        "categories",
                        event.target.checked
                      );
                    }}
                  ></input>
                  <span>{course["title"]}</span>
                </div>
              ) : null
            )}
          </div>
          <hr />
          <div className="selectListFooter" onClick={props.handlePopUpClose}>
            <span>Done</span>
          </div>
        </div>
      </PopUp>
    </div>
  );
}

export default OfferDetails;
