import React from 'react';
import './style.css';
import CopyIcon from '@assets/common/copyIcon2.png';

function NumberedTextAreaView(props) {
    return(
        <div className="CSVDisplay pb-3 me-3">
                CSV <img src={CopyIcon}></img>
                <div className="CSVDiv d-flex gap-3 overflow-scroll pt-2" style={{height: '250px'}}>
                    <div className="numbers" style={{paddingTop: '2px', color: '#aaa'}}>
                        {props.value.split('\n').map((value, index)=><div>{index+1}</div>)}
                    </div>
                    <textarea
                        className="CSVTextArea w-100"
                            value={props.value}
                            onChange={(event)=>props.onChange(event.target.value)}
                            style = {{height: props.value.split('\n').length *40+ 'px' }}
                            >
                    </textarea>
                </div>
        </div>
    )
}

export default NumberedTextAreaView;