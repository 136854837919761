import { useState, useEffect } from 'react';

import PlusIcon from '@assets/common/plusIcon.png';

import DeleteIcon from '@assets/common/deleteIcon.png';

import EditPencilIcon from '@assets/common/editPencilIcon.png';
import ShareIcon from '@assets/common/shareIcon.png';
import CopyIcon from '@assets/common/copyIcon.png';

import PreviousBtn from '@assets/common/previousBtn.svg';
import NextBtn from '@assets/common/nextBtn.png';
import UpArrowIcon from '@assets/common/ascSortArrowIcon.png';
import DownArrowIcon from '@assets/common/descSortArrowIcon.png';


import moment from 'moment';
import BasicTableGrid from '@components/BasicTableGrid';
import TextAndLinks from '@components/TextAndLinks';
import DateAndUser from '@components/DateAndUser';
import DynamicTabs from '@components/DynamicTabs';
import ButtonBars from '@components/ButtonBars';
import PhoneNumber from '@components/PhoneNumber';
import PopUp from '@components/PopUp';
import Search from '@components/Search';

import './dynamicArticles.css';

function DynamicArticlesView(props) {
	return (
		<>
			<div className='Xcontainer_1 mt-2 pb-2'>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<button
						onClick={(event) => {
							props.handleRedirectToHome('none');
						}}
						className='createXBtn'
						style={{ marginRight: '10px' }}>
						<img src={PlusIcon} alt='PlusIcon' />
						Create Article
					</button>
					<button
						onClick={() => {
							props.handleDeleteBulk();
						}}
						style={{ cursor: props.isBulkSelected ? 'not-allowed': 'pointer'}}
						disabled={props.isBulkSelected}
						className='deleteXBtn'>
						<img src={DeleteIcon} alt='DeleteIcon' />
					</button>
					{props.alertVisible && <p className='alert'>Copied</p>}
				</div>
			</div>

			<Search
					value={props.searchText}
					placeholder = {"Search Article"}
					onSearch={props.handleSearch}
					></Search>

			<BasicTableGrid 
				gridId='Articles'
				data={props.deferredData} // Data to be displayed
				fixedCols={2} // How many columns are fixed and not scrolling
				initialSize={[35,387,467]}
				initialSorting={[{"id": "Date Added","desc": false}]}
				handleSelect={(selectedRows) => {
					props.handleCheckBoxes(selectedRows);
				}}
				length={20}
				onSortChange={props.handleSort}
				columnsOptionPopUpRef={props.columnsOptionPopUpRef}
				columns={[
                    {header: 'Name on marksheet',
					cell: (data) => {
					const info = data.row.original;
					return <TextAndLinks
						title= {info?.title}
						subTitle= {info?.pathUrl}
						images = {[EditPencilIcon, ShareIcon, CopyIcon]}
						onClick={(event, index)=>{props.handleLinksClick(index, info)}}
						/>
				}},
				{header: 'Date created', accessorFn: (row) => {return row.createdAt},
                 cell: (data) => {
					const info = data.row.original;
						return <DateAndUser
						date = {info.createdAt}/>
					},},
				]}
				/>
				
			
		</>
	);
}

export default DynamicArticlesView;
