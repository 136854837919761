const initialState = {
  allOrders: [],
  numOfOrders: null,
  ordersSet: null,
  netReceived: null,
  grossReceived: null,
  totalGST: null,
  totalGatewayFee: null,
  filters: {
    page: 0,
    limit: 20,
    sortBy: "createdAt",
    isSorted: false,
    searchText: "",
    startDate: "",
    endDate: "",
    expenseType: null,
    courseIds: [],
    categories: [],
    yearTag: "",
    monthTag: "",
    mainTag: "",
    tagStatus: "",
    subTag: "",
    manualTag: "",
  },
};

const AccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_ALL_ORDERS":
      return {
        ...state,
        allOrders: action.payload.allOrders,
      };
    case "UPDATE_TOTAL_NUM_OF_ORDERS":
      return {
        ...state,
        numOfOrders: action.payload.numOfOrders,
      };
    case "UPDATE_ORDERS_SET":
      return {
        ...state,
        ordersSet: action.payload.ordersSet,
      };
    case "UPDATE_NET_RECEIVED":
      return {
        ...state,
        netReceived: action.payload.netReceived,
      };
    case "UPDATE_GROSS_RECEIVED":
      return {
        ...state,
        grossReceived: action.payload.grossReceived,
      };
    case "UPDATE_TOTAL_GST":
      return {
        ...state,
        totalGST: action.payload.totalGST,
      };
    case "UPDATE_TOTAL_GATEWAY_FEE":
      return {
        ...state,
        totalGatewayFee: action.payload.totalGatewayFee,
      };
    case "UPDATE_ACCOUNT_FILTERS":
      return {
        ...state,
        filters: action.payload.filters,
      };
    default:
      return {
        ...state,
      };
  }
};

export default AccountsReducer;
