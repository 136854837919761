import React from 'react';

import './style.css';
import TextInputV2 from '@components/TextInputV2';
import BulletSelector from '@components/BulletSelector';
//Imports for RichEditor
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

//START : Vars for RichEditor
const toolbarOptions = [
    { header: [1, 2, 3, false] },
    { font: [] },
    // { 'size': ['small', false, 'large', 'huge'] },
    'bold',
    'italic',
    'underline',
    'strike',
    { script: 'sub' },
    { script: 'super' },
    { list: 'bullet' },
];

const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'script',
    'list',
    'bullet',
    'indent',
];
//END : Vars for RichEditor

function CoverView(props) {
    return(
        <div className="d-flex gap-3">
        <div style={{width:'700px', marginLeft: '25px'}} className="courseDetails mt-4 d-flex gap-3 flex-column">
            
            <TextInputV2 id ={"coverTitle"} placeholder ={"Cover Title"} required ={true}
                value={props.courseDetails?.coverTitle? props.courseDetails.coverTitle : ''}
                onChange = {(event)=>props.handleChange(event.target.value, 'coverTitle')}
            />
            <div className="form-floating" >
                <textarea className="form-control" placeholder="" id="coverDesc" style={{height:'158px'}}
                value={props.courseDetails?.coverDescription? props.courseDetails.coverDescription : ''}
                onChange = {(event)=>props.handleChange(event.target.value, 'coverDescription')}
                ></textarea>
                <label htmlFor="coverDesc">Cover Description <span>*</span></label>
            </div>


            <h4
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    marginTop: '16px',
                }}
                className='subHeading'>
                <span style={{ marginRight: '10px' }}>Cover Pointers</span>
                <BulletSelector
                    icon={
                        props.courseDetails?.coverPointers
                            ? props.courseDetails?.coverPointers?.icon
                            : ''
                    }
                    onValueChange={(bulletIcon) =>
                        props.handleChange(bulletIcon, 'coverPointers')
                    }
                />
            </h4>
             {/* START : Code for RichEditor */}
             <ReactQuill
                ref={props.coverPointerEditorRef}
                value={
                    props.courseDetails.coverPointers
                        ? props.courseDetails.coverPointers?.pointer
                        : ''
                }
                onChange={props?.handleCoverPointers}
                onKeyDown={props.handleEditorTextEdit}
                modules={{
                    toolbar: toolbarOptions,
                }}
                formats={formats}
                style={{ marginBottom: '20px' }}
            />
            {/* END : Code for RichEditor */}
        </div>
        </div>
    )
}

export default CoverView;