import "./style.css";

import DropDownArrowIcon from "@assets/common/dropDownArrowIcon.png";
import DeleteIcon from "@assets/common/deleteIcon.png";

import RedEye from "@assets/common/redEyeIcon.png";
import GreenEye from "@assets/common/greenEyeIcon.png";

import EditPencilIcon from "@assets/common/editPencilIcon.png";
import AnalyticsIcon from "@assets/common/analyticsIcon.png";

import UploadIcon from "@assets/common/uploadIcon.png";
import BasicTableGrid from "@components/BasicTableGrid";
import ButtonBars from "@components/ButtonBars";
import DateAndUser from "@components/DateAndUser";
import Search from "@components/Search";
import ActiveSearchFilters from "@components/ActiveSearchFilters";

function OffersView(props) {
  return (
    <>
      <div className="offersPageMainContainer">
        <div className="d-flex gap-3 ms-4 mb-0">
          <h4 className="mb-0"> Offers </h4>
          <ActiveSearchFilters
            filters={[
              { icon: "search", value: props.filters.searchText },
              {
                icon: "calender",
                value:
                  props.filters.startDate || props.filters.endDate
                    ? `${props.filters.startDate} - ${props.filters.endDate}`
                    : "",
              },
            ]}
            handleClick={props.handleClearFilter}
          />
        </div>
        <div className="Xcontainer_1 m-0">
          <div style={{ display: "flex", alignItems: "center" }}>
            <ButtonBars
              buttons={[
                {
                  type: "simple",
                  text: "Create Offer",
                  onClick: (event) => {
                    props.handleRedirectToHome(event, "none", "OfferDetails");
                  },
                },
                {
                  type: "dropdownOnClick",
                  text: "Visibility",
                  onClick: (event) => {
                    props.handleVisibilityBtnClick(event, "bulk", "");
                  },
                  disabled: props.disableBulkActionBtn,
                },
                // {
                // 	type: 'dropdownOnClick',
                // 	text: 'Status',
                // 	onClick: (event) => {
                // 		props.handleStatusBtnClick(event, 'bulk');
                // 	},
                // 	disabled: props.disableBulkActionBtn,
                // 			}
              ]}
            />

            <button
              onClick={props.handleDeleteBulk}
              style={{
                cursor: props.disableBulkActionBtn ? "not-allowed" : "pointer",
              }}
              disabled={props.disableBulkActionBtn}
              className="deleteXBtn"
            >
              <img src={DeleteIcon} alt="DeleteIcon" />
            </button>
          </div>
        </div>
        <Search
          value={props.filters.searchText}
          startDate={props.filters.startDate}
          endDate={props.filters.endDate}
          placeholder={"Search offers"}
          isFilterEnabled={true}
          isTagFilterEnabled={false}
          onSearch={(data) => props.handleSearch(data)}
        ></Search>

        <BasicTableGrid
          gridId="offers"
          data={props.deferedData} // Data to be displayed
          fixedCols={3} // How many columns are fixed and not scrolling
          initialSize={[35, 385, 189, 160, 288, 167, 283]}
          initialSorting={[{ id: "Date Added", desc: false }]}
          handleSelect={(selectedRows) => {
            props.handleCheckBoxes(selectedRows);
          }}
          length={20}
          // pagination={props.pagination}
          // setPagination={props.setPagination}
          onSortChange={(data) => props.handleSort(data)}
          columnsOptionPopUpRef={props.columnsOptionPopUpRef}
          columns={[
            {
              header: "Offer",
              cell: (data) => {
                const offer = data.row.original;
                return (
                  <div className="d-flex gap-2">
                    {props.showThumbnail === true ? (
                      <div
                        className="thumbnailContainer"
                        style={{
                          //  backgroundColor: offer.cardBackgroundColor,
                          backgroundImage: `url(${offer.thumbnailUrl})`,
                          width: "65px",
                        }}
                      ></div>
                    ) : (
                      ""
                    )}
                    <div className="d-flex flex-column justify-content-center gap-2 ms-2">
                      <div
                        onDoubleClick={(event) => {
                          props.handleDoubleClick(
                            event,
                            offer._id,
                            offer.title,
                            offer.internalTitle
                          );
                        }}
                      >
                        {offer?.title}
                      </div>

                      <div className="hiddenDiv d-flex gap-2">
                        <img
                          onClick={(event) => {
                            props.handleRedirectToHome(
                              event,
                              offer._id,
                              "OfferDetails"
                            );
                          }}
                          src={EditPencilIcon}
                          alt="EditPencilIcon"
                        />
                        <img
                          onClick={(event) => {
                            props.handleRedirectToHome(
                              event,
                              offer._id,
                              "CourseAnalytics"
                            );
                          }}
                          src={AnalyticsIcon}
                          alt="AnalyticsIcon"
                        />
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              header: "Visibility",
              cell: (data) => {
                return (
                  <p
                    className="visibility"
                    onClick={(event) => {
                      props.handleVisibilityBtnClick(
                        event,
                        data.row.original._id,
                        data.row.original
                      );
                    }}
                  >
                    <img
                      src={
                        data.row.original.visibility === "public"
                          ? GreenEye
                          : RedEye
                      }
                      alt="Unlisted"
                      style={{
                        marginLeft: "5px",
                        width: "22px",
                        marginRight: "5px",
                      }}
                    />
                    {data.row.original.visibility === "public"
                      ? "Public"
                      : data.row.original.visibility === "disabled"
                      ? "Disabled"
                      : "Unlisted"}
                    <img
                      style={{ marginLeft: "5px", width: "12px" }}
                      src={DropDownArrowIcon}
                      alt="DropDownArrowIcon"
                    />
                  </p>
                );
              },
            },
            {
              header: "Coupon Code",
              cell: (data) => {
                return data.row.original.couponCode;
              },
            },
            {
              header: "Date Added",
              accessorFn: (row) => {},
              cell: (data) => {
                const info = data.row.original;
                return (
                  <DateAndUser
                    date={info.createdAt}
                    image={info.createdUserDetails?.displayPhoto}
                    imageText={info.createdUserDetails?.displayName}
                  />
                );
              },
            },
            {
              header: "Times Used",
              cell: (data) => {
                const info = data.row.original;
                return info.timeUsed;
              },
            },
            {
              header: "Last modified date",
              accessorFn: (row) => {},
              cell: (data) => {
                const info = data.row.original;
                return (
                  <DateAndUser
                    date={info.updatedAt}
                    image={info.lastModifiedUserDetails?.displayPhoto}
                    imageText={info.lastModifiedUserDetails?.displayName}
                  />
                );
              },
            },
          ]}
        />

        {/* Start : visibilityPopupContainer for Making offer public or private */}
        {props.is_VisibilityPopupContainerRef_Visible && (
          <>
            <div
              ref={props.visibilityPopupContainerRef}
              style={{
                position: "absolute",
                top: props.visibilityPopupContainerRef_Position.top,
                left: props.visibilityPopupContainerRef_Position.left,
              }}
              className="visibilityPopupContainer"
            >
              <div className="visibilityPopupContainer_inner p-2 m-2">
                <p>Save Visibility As:</p>

                <div className="form-check">
                  <input
                    onChange={(event) => {
                      props.handleVisibilityRadioBtns(event, "unlisted");
                    }}
                    className="form-check-input"
                    type="radio"
                    name="visibilityRadioBtn"
                    id="visibilityRadioBtn"
                    checked={props.internalVisibility === "unlisted"}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="visibilityRadioBtn"
                  >
                    Unlisted
                  </label>
                </div>

                <div className="form-check">
                  <input
                    onChange={(event) => {
                      props.handleVisibilityRadioBtns(event, "public");
                    }}
                    className="form-check-input"
                    type="radio"
                    name="visibilityRadioBtn"
                    id="unlistedRadioBtn"
                    checked={props.internalVisibility === "public"}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="unlistedRadioBtn"
                  >
                    Public
                  </label>
                </div>

                <div className="form-check">
                  <input
                    onChange={(event) => {
                      props.handleVisibilityRadioBtns(event, "disabled");
                    }}
                    className="form-check-input"
                    type="radio"
                    name="visibilityRadioBtn"
                    id="unlistedRadioBtn"
                    checked={props.internalVisibility === "disabled"}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="unlistedRadioBtn"
                  >
                    Disable
                  </label>
                </div>
              </div>

              <div style={{ float: "right", marginTop: "10px" }}>
                <button
                  onClick={(event) => {
                    props.handleVisibilityCancleBtnClick(event);
                  }}
                  className="cancleBtn"
                >
                  Cancle
                </button>

                <button
                  disabled={props.disableSaveBtnOfVisibility}
                  style={{
                    cursor: props.disableSaveBtnOfVisibility
                      ? "not-allowed"
                      : "pointer",
                  }}
                  id="saveVisibilityBtn"
                  onClick={(event) => {
                    props.handleSaveBtnVisibility(event);
                  }}
                  className="saveBtn"
                >
                  Save as
                </button>
              </div>
            </div>
          </>
        )}
        {/* End : visibilityPopupContainer */}

        {/* Start : thubmnailPopupContainer */}
        {props.is_ThubmnailPopupContainerRef_Visible && (
          <div
            ref={props.thubmnailPopupContainerRef}
            style={{
              position: "absolute",
              top: props.thubmnailPopupContainerRef_Position.top,
              left: props.thubmnailPopupContainerRef_Position.left,
            }}
            className="thubmnailPopupContainer"
          >
            <div className="thubmnailPopupContainer_inner">
              <img
                src={UploadIcon}
                id="coverImage_uploadBtn"
                onClick={(event) => props.handleFileUpload(event)}
                alt="UploadIcon"
              />

              <p id="coverImage_name">Upload cover picture</p>

              <input
                type="file"
                className="d-none"
                name="proof"
                accept="image/jpeg,image/jpg,image/png"
                id="coverImage"
                onChange={(event) => props.convertToBase64(event)}
              />
            </div>
            {props.offerUpdateTilteError && (
              <div className="alert-danger">
                <span>Any one title or cover img is required*</span>
              </div>
            )}

            <div className="form-floating">
              <input
                value={props.updatedOfferTitle}
                onChange={(event) => {
                  props.handleUpdateCourseTitle(event);
                }}
                type="text"
                className="form-control"
                id="floatingInputInvalid"
                placeholder="Offer Title"
              />

              <label htmlFor="floatingInputInvalid">
                Offer Title (required)
              </label>
            </div>

            <div className="form-floating mt-2">
              <input
                value={props.updatedInternalTitle}
                onChange={(event) => {
                  props.handleUpdateInternalTitle(event);
                }}
                type="text"
                className="form-control"
                id="floatingInputInvalid"
                placeholder="Offer Internal Title"
              />

              <label htmlFor="floatingInputInvalid">
                Offer Internal Title (required)
              </label>
            </div>

            <div style={{ float: "right", marginTop: "10px" }}>
              <button
                onClick={(event) => {
                  props.handleThumbnailCancleBtnClick(event);
                }}
                className="cancleBtn"
              >
                Cancle
              </button>

              <button
                id="saveThumbnailBtn"
                data-course_id=""
                onClick={(event) => {
                  props.handleSaveThumbnail_Title(event);
                }}
                className="saveBtn"
              >
                Save
              </button>
            </div>
          </div>
        )}
        {/* End : thubmnailPopupContainer */}
      </div>
    </>
  );
}

export default OffersView;
