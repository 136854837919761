import React from 'react';
import { Page, Document, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

import InvoiceFooter from './InvoiceFooter';
import InvoiceHeader from './InvoiceHeader';
import InvoiceLeftBar from './InvoiceLeftBar';
import InvoiceBilling from './InvoiceBilling';
import InvoiceTable from './InvoiceTable';

import InterRegular from '@assets/invoice/Inter-Regular.ttf';
import InterMedium from '@assets/invoice/Inter-Medium.ttf';
import InterSemiBold from '@assets/invoice/Inter-SemiBold.ttf';
import InterBold from '@assets/invoice/Inter-Bold.ttf';

Font.register({ family: 'InterRegular', src: InterRegular });
Font.register({ family: 'InterMedium', src: InterMedium });
Font.register({ family: 'InterSemiBold', src: InterSemiBold });
Font.register({ family: 'InterBold', src: InterBold });


const styles = StyleSheet.create({
	page: {
		fontSize: 9.5,
		paddingTop: 30,
		paddingLeft: 60,
		paddingRight: 60,
		lineHeight: 1.5,
		flexDirection: 'column',
		fontFamily: 'InterMedium'
	},
});

const Invoice = ({ invoice }) => (
	<Document>
		<Page size='A4' style={styles.page}>

			<InvoiceLeftBar />

			<InvoiceHeader invoice={invoice}/>

			<InvoiceBilling invoice={invoice}/>

			<InvoiceTable invoice={invoice}/> 

			<InvoiceFooter invoice={invoice}/>

		</Page>
	</Document>
);

export default Invoice;
