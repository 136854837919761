import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';
import EditPencilIcon from '@assets/common/editPencilIcon.png';


//Object to handle naviagtion
export const navigationData = [
    {
        "title" : "Back to Content",
        "route" : "/customer-care",
        "default" : false,        
        "icon" : GoBackArrow
    },
    {
        "title" : "Details",
        "component" : "CustomerCare",
        "default" : false,        
        "icon" : EditPencilIcon
    }
]
