import React, { useState, useEffect, useRef } from 'react';

import './bulletSelector.css';
import BarIcon from '@assets/common/barIcon.png';
import * as StaticAssetsServices from "@services/StaticAssets";
import Swal from "sweetalert2";
import { decryptText } from "@library/enc-dec";

function BulletSelector(props) {
	const { onValueChange, bulletSelectorId } = props;
	const [bulletIconContainerIsVisiable, setBulletIconContainerIsVisiable] =
		useState(false);
	const [selectedBulletBoxIcon, setSelectedBulletBoxIcon] = useState(null);
	const bulletIconContainerRef = useRef(null);
	const [bulletIconContainerRef_Position, set_bulletIconContainerRef_Position] =
		useState({ top: 0, left: 0 });
    const [bulletIcons, setBulletIcons] = useState([]);

	useEffect(() => {
		getBulletIcons()
		document.addEventListener('mousedown', handleOutsideClick);
		setSelectedBulletBoxIcon(props.icon);

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, []);

	const getBulletIcons = async () => {
		try {
		  let authToken = decryptText(localStorage.getItem("aEmediat"));
		  let payload = {
			pageNo: "0",
			limit: "9999999",
			isSorted: false,
			sortBy: "createdAt",
			searchText: "",
			visibility: "active",
			assetType: "bullet",
		  };
	
		  var response = await StaticAssetsServices.getAllStaticAssets(
			payload,
			authToken
		  );
		  if (response.success) {
			setBulletIcons(response.data.map((iconData,index)=>{
				return iconData.assetUrl
			}));
		  } else {
			Swal.fire({
			  icon: "error",
			  title: response.message,
			  showConfirmButton: false,
			  timer: 2500,
			});
		  }
		} catch (err) {
		  console.log("Error coming while getting all bullets", err);
		}
	  };

	const handleOutsideClick = (event) => {
		if (
			bulletIconContainerRef.current &&
			!bulletIconContainerRef.current.contains(event.target)
		) {
			setBulletIconContainerIsVisiable(false);
		}
	};

	const handleButtonClick = (event) => {
		setSelectedBulletBoxIcon(null);
		setBulletIconContainerIsVisiable(true);
		let currentEle = event.target;
		let left = currentEle.getBoundingClientRect().left + 20;
		let top = window.scrollY + currentEle.getBoundingClientRect().bottom - 20;

		set_bulletIconContainerRef_Position({ left: left, top: top });
	};

	const handleSelectedBulletIcon = (event, selectedBulletIcon) => {
		let response = {};
		setBulletIconContainerIsVisiable(false);
		setSelectedBulletBoxIcon(selectedBulletIcon);
		response['icon'] = selectedBulletIcon;
		if (bulletSelectorId !== undefined) {
			response['bulletSelectorId'] = bulletSelectorId;
		}

		onValueChange(response);
	};

	// const bulletIcons = [
	// 	// `${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/rectBulletBoxIcon.svg`,
	// 	// `${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/roundBulletBoxIcon.svg`,
	// 	`${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/errorBullrtBoxIcon.svg`,
	// 	`${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/redWrongBulletBoxIcon.svg`,
	// 	// `${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/roundedInfoBulletBoxIcon.svg`,
	// 	`${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/rectRightBulletBoxIcon.svg`,
	// 	// `${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/roundedRightBulletBoxIcon.svg`,
	// 	`${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/greenDiamond.svg`,
	// 	`${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/yellowDiamond.svg`,
	// 	`${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/blackRightArrow.svg`,
	// 	`${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/blackStar.svg`,
	// 	`${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/blueDoubleTick.svg`,
	// 	`${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/blueTick.svg`,
	// 	`${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/doubleGreenTick.svg`,
	// 	`${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/fire.svg`,
	// 	`${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/grayBullet.svg`,
	// 	`${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/orangeDanger.svg`,
	// 	`${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/redDoubleTick.svg`,
	// 	`${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/redTick.svg`,
	// 	`${process.env.REACT_APP_AWS_BULLETS_BASE_URL}/bulletIcons/yellowStar.svg`,
	// ];

	let bulletIcon = props.icon ? props.icon : selectedBulletBoxIcon;

	return (
		<>
			<div className='bulletIconMainContainer'>
				<img
					onClick={(event) => {
						handleButtonClick(event);
					}}
					className='barIcon'
					src={BarIcon}
					alt='BarIcon'
				/>

				{bulletIconContainerIsVisiable && (
					<div
						style={{
							maxWidth : '330px',
							top: bulletIconContainerRef_Position.top,
							left: bulletIconContainerRef_Position.left,
						}}
						ref={bulletIconContainerRef}
						className='bulletIconContainer'>
						{bulletIcons.map((bulletIcon, index) => (
							<img
									key={index}
									onClick={(event) => {
										handleSelectedBulletIcon(event, bulletIcon);
									}}									
									src={bulletIcon}
									alt='BulletBoxIcon'
						    />
						))}
					</div>
				)}

				{bulletIcon && (
					<img
						className='selectedBulletBoxIcon'
						src={bulletIcon}
						alt='selectedBulletBoxIcon'
					/>
				)}
			</div>
		</>
	);
}

export default BulletSelector;
