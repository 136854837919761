export const updateAllArticles = (value) => {
	return {
		type: 'UPDATE_ALL_ARTICLES',
		payload: {
			allArticles: value,
		},
	};
};

export const updateTotalNumOfArticles = (value) => {
	return {
		type: 'UPDATE_TOTAL_NUM_OF_ARTICLES',
		payload: {
			numOfArticles: value,
		},
	};
};

export const updateArticlesSet = (value) => {
	return {
		type: 'UPDATE_ARTICLES_SET',
		payload: {
			articlesSet: value,
		},
	};
};

export const updateSelectedArticle = (value) => {
	return {
		type: 'UPDATE_SELECTED_ARTICLE',
		payload: {
			selectedArticle: value,
		},
	};
};



export const resetAllArticles = (value) => {
	return {
		type: 'RESET_ARTICLES_REDUCER',
		payload: {
		},
	};
};

export const resetArticle = (value) => {
	return {
		type: 'RESET_ARTICLE',
		payload: {
		},
	};
};