const initialState = {
	allVideos: [],
	totalNumberOfVideos: 0,
	videoSet: [],
	title: '',
	description: '',
	videoLink: '',
	thumbnailUrl: '',
	additionalInfo: {
		pointer: '',
		icon: '',
	},
	hideLikes: false,
	hideDislikes: true,
	commentsStatus: 'heldForReview',
	isPublic: false,
	pageNo: 0,
	isSorted: false,
	searchText: '',


	filterInformation:{page: 0,
						limit:20,
						sortBy: '',
						isSorted: true,
						searchText: ''}
};

const VideosReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_ALL_VIDEOS':
			return {
				...state,
				allVideos: action.payload.allVideos,
			};
		case 'UPDATE_TOTAL_NUMBER_OF_VIDEOS':
			return {
				...state,
				totalNumberOfVideos: action.payload.totalNumberOfVideos,
			};
		case 'UPDATE_VIDEO_SET':
			return {
				...state,
				videoSet: action.payload.videoSet,
			};
		case 'UPDATE_VIDEO_DETAILS':
			return {
				...state,
				title: action.payload.video.title,
				description: action.payload.video.description,
				videoLink: action.payload.video.videoId,
				thumbnailUrl: action.payload.video.thumbnailUrl,
				additionalInfo: action.payload.video.additionalInfo,
				hideLikes: action.payload.video.hideLikes,
				hideDislikes: action.payload.video.hideDislikes,
				commentsStatus: action.payload.video.commentsStatus,
				isPublic: action.payload.video.isPublic,
			};
		case 'UPDATE_VIDEO_TITLE':
			return {
				...state,
				title: action.payload.title,
			};
		case 'UPDATE_VIDEO_DESCRIPTION':
			return {
				...state,
				description: action.payload.description,
			};
		case 'UPDATE_VIDEO_LINK':
			return {
				...state,
				videoLink: action.payload.videoLink,
			};
		case 'UPDATE_THUMBNAIL_URL':
			return {
				...state,
				thumbnailUrl: action.payload.thumbnailUrl,
			};
		case 'UPDATE_ADDITIONAL_INFO':
			return {
				...state,
				additionalInfo: action.payload.additionalInfo,
			};
		case 'UPDATE_HIDE_LIKES':
			return {
				...state,
				hideLikes: action.payload.hideLikes,
			};
		case 'UPDATE_HIDE_DISLIKES':
			return {
				...state,
				hideDislikes: action.payload.hideDislikes,
			};
		case 'UPDATE_COMMENTS_STATUS':
			return {
				...state,
				commentsStatus: action.payload.commentsStatus,
			};
		case 'UPDATE_IS_PUBLIC':
			return {
				...state,
				isPublic: action.payload.isPublic,
			};
		case 'UPDATE_PAGE_NUMBER':
			return {
				...state,
				pageNo: action.payload.pageNo,
			};
		case 'UPDATE_VIDEO_IS_SORTED':
			return {
				...state,
				isSorted: action.payload.isSorted,
			};
		case 'UPDATE_VIDEO_SEARCH_TEXT':
			return {
				...state,
				searchText: action.payload.searchText,
			};
		case 'UPDATE_FILTER_INFORMATION':
			return {
				...state,
				filterInformation: action.payload.filterInformation,
			};
		case 'RESET_VIDEOS_REDUCER':
			return {
				...state,
				title: '',
				description: '',
				videoLink: '',
				thumbnailUrl: '',
				additionalInfo: {
					pointer: '',
					icon: '',
				},
				hideLikes: false,
				hideDislikes: true,
				commentsStatus: 'heldForReview',
				isPublic: false,
				pageNo: 0,
				isSorted: false,
				searchText: ''
			};

		default:
			return state;
	}
};

export default VideosReducer;
