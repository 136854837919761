import React from "react";
import TextInputView from './view';

function TextInput(props){
   
   return(
    <TextInputView
    props = {props}
    />
   )

}

export default TextInput;