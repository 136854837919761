const initialState = {
  allCategories: [],
  totalNumberOfCategories: 0,
  categorySet: [],
  title: "",
  description: "",
  pointers: [
    {
      icon: "",
      pointer: "",
    },
  ],
  videoUrl: "",
  courseIds: [],
  isPublic: false,
  selectedCourses: [],
  pageNo: 0,

  filterInformation: {
    page: 0,
    limit: 20,
    sortBy: "",
    isSorted: true,
    searchText: "",
  },
};

const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_ALL_CATEGORIES":
      return {
        ...state,
        allCategories: action.payload.allCategories,
      };
    case "UPDATE_TOTAL_NUMBER_OF_CATEGORIES":
      return {
        ...state,
        totalNumberOfCategories: action.payload.totalNumberOfCategories,
      };
    case "UPDATE_CATEGORY_SET":
      return {
        ...state,
        categorySet: action.payload.categorySet,
      };
    case "UPDATE_CATEGORY_DETAILS":
      return {
        ...state,
        title: action.payload.category.title,
        description: action.payload.category.description,
        pointers: action.payload.category.pointers,
        videoUrl: action.payload.category.videoUrl,
        courseIds: action.payload.category.courseIds,
        isPublic: action.payload.category.isPublic,
      };
    case "UPDATE_CATEGORY_TITLE":
      return {
        ...state,
        title: action.payload.title,
      };
    case "UPDATE_CATEGORY_DESCRIPTION":
      return {
        ...state,
        description: action.payload.description,
      };

    case "UPDATE_CATEGORY_POINTERS":
      return {
        ...state,
        pointers: action.payload.pointers,
      };
    case "UPDATE_CATEGORY_VIDEO_URL":
      return {
        ...state,
        videoUrl: action.payload.videoUrl,
      };
    case "UPDATE_CATEGORY_IS_PUBLIC":
      return {
        ...state,
        isPublic: action.payload.isPublic,
      };
    case "UPDATE_CATEGORY_PAGE_NUMBER":
      return {
        ...state,
        pageNo: action.payload.pageNo,
      };

    case "UPDATE_SELECTED_COURSES":
      return {
        ...state,
        selectedCourses: action.payload.selectedCourses,
      };

    case "UPDATE_CATEGORY_FILTER_INFORMATION":
      return {
        ...state,
        filterInformation: action.payload.filterInformation,
      };
    case "RESET_CATEGORY_REDUCER":
      return initialState;
    default:
      return state;
  }
};

export default CategoryReducer;
