export const updateEmail = (value) => {
	return {
		type: 'UPDATE_EMAIL',
		payload: {
			email: value,
		},
	};
};


export const updatePassword = (value) => {
	return {
		type: 'UPDATE_PASSWORD',
		payload: {
			password: value,
		},
	};
};


export const updateAccess = (value) => {
	return {
		type: 'UPDATE_ACCESS',
		payload: {
			access: value,
		},
	};
};




