import React, { useEffect, useState } from 'react';

import SelectSeriesPopUpView from './view';
import * as CourseContentServices from '@services/CourseContent';
import { decryptText } from '@library/enc-dec';

function SelectSeriesPopUp(props) {
	const [error, setError] = useState('');
    const [searchText, setSearchText] = useState('');
	const [selectedSeries, setSelectedSeries] =useState(props.selectedSeries)

	useEffect(()=>{
		if(props.courseId)
		{
			getCourseContent(props.courseId)
		}
	},[props.courseId])

	const getCourseContent = async(id)=>{
		try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const response = await CourseContentServices.getCourseContent(id, authToken);
			if (response.success) {
				setSelectedSeries(response.data.content)
			}
		} catch (err) {
			console.log('Error coming while fetching courses prices', err);
		}
	}

	const handleSeriesSelect=(checked, _series)=>{
	  var newSeries ={
		seriesId : _series._id,
		thumbnailUrl : _series.thumbnailUrl,
		title : _series.title,
	  } 	
	  var updatedSelectedSeries= selectedSeries? [...selectedSeries] : []
	  
	  if(checked)
	  { //Add
		updatedSelectedSeries.push(newSeries)
	  }
	  else{ //remove
        updatedSelectedSeries = updatedSelectedSeries.filter(series => series.seriesId !== newSeries.seriesId);
	  }
	  setSelectedSeries(updatedSelectedSeries)
	}

	const handleCloseBtn = () => {
		props.handleClose()
	};

	const handleSaveBtn = async (event) => {

		if(props.courseId)
		{
			try {
				let authToken = decryptText(localStorage.getItem('aEmediat'));
				const courseContentWithoutId = selectedSeries.map(({ _id, ...rest }) => rest);
				const payload = {
					courseId: props.courseId,
					content : courseContentWithoutId
				}
				const response = await CourseContentServices.updateCourseContent(payload, authToken);

				if(response.success)
				{
				props.handleSave(response)
				}
				else{
					alert(response.message);
				}
				
			} catch (err) {
				console.log('Error coming while fetching courses prices', err);
			}
		}
		props.handleSave(selectedSeries)

	};

	return (
		<SelectSeriesPopUpView
				error={error}
				searchText={searchText}
				allSeries={props.allSeries}
				selectedSeries={selectedSeries}
				searchPlaceholder= {props.searchPlaceholder}
				handleCloseBtn={handleCloseBtn}
				handleSaveBtn={handleSaveBtn}
				handleSearch = {setSearchText}
				handleSeriesSelect = {handleSeriesSelect}
			/>
	);
}

export default SelectSeriesPopUp;
