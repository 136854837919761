const initialState = {
    certificateDetails:{},
	filters:{page: 0,
			limit:10,
			sortBy: 'createdAt',
			isSorted: false,
			searchText: '',
			startDate: '',
			endDate: '',
			examStatus: null,
			courseIds: [],
			categories: [],
			yearTag:"",
			monthTag: "",
			mainTag: "",
			tagStatus:"",
			subTag:"",
		    manualTag:"",
            isAllActiveCourseChecked:'false'
		}
};

const CertificateReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_CERTIFICATE_FILTERS':
			return{
				...state,
				filters: action.payload.filters,
			}
		default:
			return state;
	}
};

export default CertificateReducer;
