import React from 'react';

import './style.css';

function BasicInfoView(props) {
	return (
		<>
			<div className='basicInfoView'>
				<div className="accordion" id="basicInfoAccordion">
					<div className="accordion-item">
						<h2 className="accordion-header">
							<button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#basicInfoCollapseOne">
								Basic Info
							</button>
						</h2>
						<div id="basicInfoCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#basicInfoAccordion">
							<div className="accordion-body">
								<div className='userDetialsTable'>

									<div className='row rowX rowBorderX'>
										<div className='col-3 userDetialsTableRightContainer'>
											Username
										</div>
										<div className='col-9 userDetialsTableLeftContainer'>
										{props.userDetails?.username}
										</div>
									</div>

									<div className='row rowX rowBorderX'>
										<div className='col-3 userDetialsTableRightContainer'>
											Display Name
										</div>
										<div className='col-9 userDetialsTableLeftContainer'>
										{props.userDetails?.firstName +" "}
											{props.userDetails?.lastName}
										</div>
									</div>

									<div className='row rowX rowBorderX'>
										<div className='col-3 userDetialsTableRightContainer'>
											Last Name
										</div>
										<div className='col-9 userDetialsTableLeftContainer'>
										{props.userDetails?.lastName}
										</div>
									</div>

									<div className='row rowX rowBorderX'>
										<div className='col-3 userDetialsTableRightContainer'>
											Email ID
										</div>
										<div className='col-9 userDetialsTableLeftContainer'>
											{props.userDetails?.email}
										</div>
									</div>

									<div className='row rowX rowBorderX'>
										<div className='col-3 userDetialsTableRightContainer'>
											Contact Number
										</div>
										<div className='col-9 userDetialsTableLeftContainer'>
											{props.userDetails?.countryCode}{' '}{props.userDetails?.contactNumber}
										</div>
									</div>

									<div className='row rowX rowBorderX'>
										<div className='col-3 userDetialsTableRightContainer'>
											WhatsApp Number
										</div>
										<div className='col-9 userDetialsTableLeftContainer'>
											{props.userDetails?.whatsAppCountryCode}{' '}{props.userDetails?.whatsAppNumber}
										</div>
									</div>

									<div className='row rowX'>
										<div className='col-3 userDetialsTableRightContainer'>
											Notes
										</div>
										<div className='col-9 userDetialsTableLeftContainer'>
											{props.userDetails?.notes}
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>


			</div>
		</>
	);
}

export default BasicInfoView;
