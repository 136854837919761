const initialState = {
	allTaggableUsers: [],
};

const CommonReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_ALL_TAGGABLE_USERS':
			return {
				...state,
				allTaggableUsers: action.payload.allTaggableUsers,
			};
		default:
			return{
				...state
			}
    }
}

export default CommonReducer;