import React, { useState } from 'react';


import SetCourseDurationPopUpView from './view';

function SetCourseDurationPopUp(props) {
	const [ticketStatus, setTicketStatus] = useState('');
	const [error, setError] = useState('');

	const handleTicketStatus = (value) => {
		setError('');
		setTicketStatus(value)
	}

	const handleCancel = () => {
		props.handleCancel()
	}
	const handleSave = () => {
		if(ticketStatus !==""){
			props.handleSave({ ticketStatus: ticketStatus })
		}else{
			setError('Input required')
		}
	}


	return (
		<SetCourseDurationPopUpView
			handleCancel={handleCancel}
			handleSave={handleSave}

			ticketStatus={ticketStatus}
			handleTicketStatus={handleTicketStatus}
			error={error}
		/>
	);
}

export default SetCourseDurationPopUp;
