export const updateTags = (value) => {
	return {
		type: 'UPDATE_TAGS',
		payload: {
			allTags: value,
		},
	};
};




