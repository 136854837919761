import CryptoJS from 'crypto-js';
const sCode = process.env.REACT_APP_LOGIN_SECRET_CODE

// Encrypting text
export const encryptText = (text) => {
	text = text.toString();
	// const prefix = 'Bearer ';
	// if (text.startsWith(prefix)) {
	// 	text = text.slice(prefix.length);
	// 	//   return token.slice(prefix.length);
	// }

	const ciphertext = CryptoJS.AES.encrypt(text, sCode).toString();
	return ciphertext;
};

// Decrypting text
export const decryptText = (ciphertext) => {
	const bytes = CryptoJS.AES.decrypt(ciphertext, sCode);
	const plaintext = bytes.toString(CryptoJS.enc.Utf8);
	return plaintext;
};

// export { encryptText, decryptText };
