export const getAllStaticAssets = async (payload, authToken) => {
	try {
		const {pageNo, limit, isSorted, sortBy, searchText, assetType, visibility} =payload
		const url = `${process.env.REACT_APP_BASE_URL}/getAllStaticAssets?assetType=${assetType}&pageNo=${pageNo}&limit=${limit}&visibility=${visibility}${searchText?'&searchText='+searchText: ''}${sortBy? '&sortBy='+sortBy: ''}${isSorted? '&isSorted='+isSorted: ''}`
		return await fetch(url,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from StaticAssets : Get all getAllStaticAssets() ', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateStaticAsset = async (payload, authToken) => {
	try {
		let formData = new FormData();
		for (let key in payload) {
			formData.append(key, payload[key]);
		}
		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateStaticAsset`, {
			method: 'POST',
			headers: {
                // 'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: formData,
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from StaticAsset services : updateStaticAsset()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateStaticAssetsVisibility = async (payload, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/updateStaticAssetsVisibility`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
				body: JSON.stringify(payload),
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Static Assets services : updateStaticAssetsVisibility()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const deleteStaticAssets = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/deleteStaticAssets`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from StaticAssets services : deleteStaticAssets()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
