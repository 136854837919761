import React from "react";

import CourseAnalyticsView from './view';

function CourseAnalytics(){
    return(
        <>
            <CourseAnalyticsView />
        </>
    )
}

export default CourseAnalytics;