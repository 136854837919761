import React from 'react';

import './style.css';
import VideoPlayHolder from '@assets/common/videoPlayHolder.png';
import BlackCrossIcon from '@assets/common/blackCrossIcon.png';
import SaveBtn from "@components/SaveBtn";

function AddSeriesPopUpView(props) {
	return (
		<div className="addSeriesMain">
			<span>Add Series</span>
			<div className="d-flex gap-1 seriesSelector">
				<select
					onChange={(event) => {
						props.handleSeriesChange(event);
					}}
					className='form-select'>
					<option value=''>Select series</option>
					{props.allSeries &&
						props.allSeries.map((series, index) => (
							<>
								<option data-seriesid={series._id} value={series._id} key={series._id}>
									{series.title}
								</option>
							</>
						))}
				</select>
				
				<button className="roundBtn blackBtn"
				   style={{marginLeft:'auto'}}
					onClick={(event) => {
						props.handleAddNewSeries(event);
					}}>
					+
				</button>
			</div>
			

			{props.error && props.error !== '' && (
				<div className='alert alert-danger w-50'>
					{props.error}
				</div>
			)}

			<div className="hr"/>
      
				
			{props.selectedSeriesList && props.selectedSeriesList.length > 0 &&
				props.selectedSeriesList.map((series, index) => (
					<>
						<div className='newSeriesContainer g-2' key={index}>
							<img
								className='seriesThumbnail'
								src={series.thumbnailUrl ? series.thumbnailUrl : VideoPlayHolder}
								alt='series'
							/>
							<span className='seriesName'>{series.title}</span>
							<img
								onClick={(event) => {
									props.handleDeleteSeries(event, series._id);
								}}
								className='blackCrossIcon'
								src={BlackCrossIcon}
								alt='BlackCrossIcon'
							/>
						</div>
					</>
				))}

            <div className="d-flex justify-content-end gap-1">
				<button onClick={props.handleCloseBtn} className='closeBtn'>
				Cancel
				</button>
				{/* <button onClick={props.handleSaveBtn} className='saveBtn'>
					Save
				</button> */}
				<SaveBtn
					onClick={props.handleSaveBtn}
					text={"Save"}
					altText={"Saving..."}
					disabled={props.isFormSaving}
					isPopUpBtn={true}
				/>
            </div>



		</div>
	);
}

export default AddSeriesPopUpView;
