import "./style.css";
import Search from "@components/Search";
import ButtonBars from "@components/ButtonBars";
import PopUp from "@components/PopUp";
import UserProfile from "@pages/CustomerCare/Details/Sections/UserProfile";
import BasicInfo from "@pages/CustomerCare/Details/Sections/BasicInfo";
import DetailedTicketInformation from "@pages/CustomerCare/Details/Sections/DetailedTicketInformation";
import TicketActivity from "@pages/CustomerCare/Details/Sections/TicketActivity";
import ChangeTicketStatus from "@components/CustomerCarePopups/ChangeTicketStatus";
import TextInputWithMentions from "@components/TextInputWithMentions";

import BlueDropDownIcon from "@assets/common/blueDropDown.png";
// import ExpandIcon from '@assets/userDashboard/expand.png';
import SettingIcon from "@assets/userDashboard/setting.png";
import FolderIcon from "@assets/userDashboard/folder.png";

function QueriesDetailsView(props) {
  return (
    <>
      <div className="contentPageMainContainer queriesDetailsView bg-white">
        <div style={{ borderBottom: "1px solid #ddd" }}>
          <h4 className="mb-3 topHeading">Report Details</h4>
          <div
            style={{ maxWidth: "1000px" }}
            className="d-flex align-items-center w-100 justify-content-between"
          >
            <ButtonBars
              buttons={[
                {
                  text: "Change Status",
                  iconEnd: BlueDropDownIcon,
                  // disabled: props.disableBulkActionBtn,
                  onClick: (event) => {
                    props.handlePopUpOpen(event, "changeTicketStatus");
                  },
                },
                {
                  text: "Add Comment",
                  // iconEnd: BlueDropDownIcon,
                  // disabled: props.disableBulkActionBtn,
                  onClick: (event) => {
                    props.handlePopUpOpen(event, "addComment");
                  },
                },
              ]}
            />

            <div className="d-flex gap-2 controlsRight me-3">
              {/* <button onClick={props.toggleFullscreen}>
								<img src={ExpandIcon}></img>
								<br></br>
								Expand
							</button> */}
              <button
                onClick={(event) => props.handlePopUpOpen(event, "setting")}
              >
                <img src={SettingIcon}></img>
                <br></br>
                Setting
              </button>
              <button onClick={props.moveToArchive}>
                <img src={FolderIcon}></img>
                <br></br>
                Archive
              </button>
            </div>
          </div>
        </div>
        <div className=" flex-grow-1 overflow-auto">
          <div
            className="contentContainer row w-100"
            style={{ borderBottom: "1px solid #ddd" }}
          >
            <div className="col-9" style={{ maxWidth: "1000px" }}>
              <BasicInfo userDetails={props.userDetails} />
            </div>
            <div className="col-2 pt-5">
              <UserProfile userDetails={props.userDetails} />
            </div>
          </div>

          <div
            className="contentContainer row w-100"
            style={{ borderBottom: "1px solid #ddd" }}
          >
            <div className="col-9" style={{ maxWidth: "1000px" }}>
              <DetailedTicketInformation
                queryDetails={props.queryDetails}
                userDetails={props.userDetails}
              />
            </div>
          </div>
          <div className="contentContainer row w-100">
            <div className="col-9" style={{ maxWidth: "1000px" }}>
              <TicketActivity ticketActivityData={props.ticketActivityData} />
            </div>
          </div>
        </div>

        <PopUp
          visible={props.isPopUpVisible && props.popUp === "changeTicketStatus"}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          <ChangeTicketStatus
            handleCancel={props.handlePopUpClose}
            handleSave={(ticketStatus) => {
              props.handlePopUpSave(ticketStatus);
            }}
          />
        </PopUp>

        <PopUp
          visible={props.isPopUpVisible && props.popUp === "setting"}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          setting content
        </PopUp>

        <PopUp
          visible={props.isPopUpVisible && props.popUp === "addComment"}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          <div className="queryCommentContainer">
            {/* <input placeholder='Comment' className='commentInput' onChange={(event)=>props.handleChangeComment(event)} type="text" /> */}
            <TextInputWithMentions
              userList={props.allAdminUsers} // List of tagable users
              value={""}
              onChange={props.handleChangeComment}
            />
            <div className="btnContainer">
              <button
                className="cancleBtn"
                onClick={() => props.handlePopUpClose()}
              >
                Cancel
              </button>
              <button
                className="saveBtn"
                onClick={() => props.handlePopUpSave()}
              >
                Save
              </button>
            </div>
          </div>
        </PopUp>
      </div>
    </>
  );
}

export default QueriesDetailsView;
