export const getAllEmailTemplates = async (payload,authToken) => {
	try {
		const {pageNo, limit, isSorted, sortBy, searchText} =payload
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getAllEmailTemplates?pageNo=${pageNo}&limit=${limit}${searchText?'&searchText='+searchText: ''}${sortBy? '&sortBy='+sortBy: ''}${isSorted? '&isSorted='+isSorted: ''}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Courses : Get All Email Templates', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getEmailTemplate = async (id, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getEmailTemplateById/${id}`, {
			method: 'GET',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Email template : Get Email Template', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateEmailTemplate = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateEmailTemplate`, {
			method: 'POST',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Email Template : updateEmailTemplate ', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateEmailTemplateStatus = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateEmailTemplateStatus`, {
			method: 'PATCH',
			headers: {
				Authorization: authToken,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Email Template : Update Email Template ', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const deleteEmailTemplate = async (templateList, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/deleteEmailTemplates`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify({
				templateIds: templateList,
			}),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Email template :Delete templates', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
