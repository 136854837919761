import React from "react";

import "./style.css";
import SearchInput from "@components/SearchInput";

function EditExamPopUpView(props) {
  return (
    <div className="examPopUpDiv">
      <SearchInput
        value={""}
        placeholder={"Search for faqs"}
        onChange={(event) => props.handleSearch(event.target.value)}
      />

      <div className="d-flex flex-column gap-2" style={{ color: "#252525" }}>
        {props.allExams?.map((exam, index) => {
          if (
            exam.title?.toLowerCase().includes(props.searchText?.toLowerCase())
          ) {
            return (
              <div className="d-flex ms-2 gap-2 align-items-center" key={index}>
                <input
                  type="radio"
                  checked={props.selectedExam === exam._id}
                  onChange={(event) => props.handleExamChange(exam._id)}
                ></input>
                <span>{exam.title}</span>
              </div>
            );
          }
        })}
      </div>

      <div className="d-flex justify-content-end gap-1">
        {props.error && props.error !== "" && (
          <div className="alert alert-danger w-50">{props.error}</div>
        )}
        <button onClick={props.handleCloseBtn} className="closeBtn">
          Cancel
        </button>
        <button onClick={props.handleSaveBtn} className="saveBtn">
          Save
        </button>
      </div>
    </div>
  );
}

export default EditExamPopUpView;
