import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import OfferDetailsView from "./view";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { decryptText } from "@library/enc-dec";

import { useNavigate } from "react-router-dom";

import * as CategoryServices from "@services/Category";
import * as OfferServices from "@services/Offers";
import * as OfferActions from "@redux/actions/Offers";

import Swal from "sweetalert2";
import { getEventElementPosition } from "@helpers/common.js";
import { navigationData } from "./data";

function OfferDetails(props) {
  const navigate = useNavigate();
  let { id } = useParams();
  let authToken = decryptText(localStorage.getItem("aEmediat"));

  const [allCategories, setAllCategories] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [offer, setOffer] = useState({
    discounts: [{ numberOfCartItems: "0", discount: "0" }],
  });
  const [popUp, setPopUp] = useState("");
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({});
  const [isFormSaving, setIsFormSaving] = useState(false);

  useEffect(() => {
    getOfferDetails();
    getAllCategories();
  }, []);

  useEffect(() => {
    // Find the element with the id 'innerSideNavbar'
    const innerSideNavbarElement = document.getElementById("innerSideNavbar");

    // Create a new div element for the InnerComponent
    const newDiv = document.createElement("div");

    // Set an id for the new div (optional)
    newDiv.id = "innerComponentThumbnailImage";
    // newDiv.textContent = 'Hello, this is the new content!';
    newDiv.style.height = "200px";
    newDiv.style.width = "200px";
    newDiv.style.backgroundColor = "#eee";
    newDiv.style.margin = "30px -18px";
    newDiv.style.marginBottom = "-30px";
    newDiv.style.borderRadius = "10px";
    newDiv.style.backgroundPosition = "center center";
    newDiv.style.backgroundSize = "contain";
    newDiv.style.backgroundRepeat = "no-repeat";
    newDiv.style.backgroundImage =
      offer?.thumbnailUrl != null
        ? typeof offer.thumbnailUrl === "string"
          ? `url(${offer.thumbnailUrl})`
          : `url(${URL.createObjectURL(offer.thumbnailUrl)})`
        : `url('')`;
    // Append the new div under the 'innerSideNavbar' div
    innerSideNavbarElement.appendChild(newDiv);
    innerSideNavbarElement.insertBefore(
      newDiv,
      innerSideNavbarElement.firstChild
    );

    // Cleanup (optional): Remove the new div when the component unmounts
    return () => {
      innerSideNavbarElement.removeChild(newDiv);
    };
  }, [offer]);

  // Get offer detail if exist otherwise clear the offer reducer
  const getOfferDetails = async () => {
    try {
      if (id !== undefined) {
        const offerDocId = id;
        const response = await OfferServices.getOfferById(
          offerDocId,
          authToken
        );
        if (response.success) {
          setOffer(response.data);
          return;
        }
        alert(response.message);
      } else {
        props.resetOffersReducer();
      }
    } catch (err) {
      console.log("Error coming while fetching offer details");
    }
  };

  // Get All Categories to be shown in the drop-down
  const getAllCategories = async () => {
    try {
      const payload = {
        pageNo: 0,
        limit: 10000,
      };
      let response = await CategoryServices.getAllCategories(
        payload,
        authToken
      );
      if (response.success) {
        setAllCategories(response.data);
      }
    } catch (err) {
      console.log("Error coming while fetching all Categories", err);
    }
  };

  const handleSave = async () => {
    try {
      if (
        !offer.title ||
        !offer.internalTitle ||
        !offer.description ||
        !offer.couponCode ||
        !offer.visibility ||
        !offer.categories ||
        !offer.discounts ||
        !offer.thumbnailUrl
      ) {
        Swal.fire({
          icon: "error",
          title: "Fields are empty",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      setIsFormSaving(true);
      const payload = {
        offerId: id ? id : null,
        title: offer.title,
        internalTitle: offer.internalTitle,
        description: offer.description,
        couponCode: offer.couponCode,
        discounts: offer.discounts,
        thumbnailUrl: offer.thumbnailUrl,
        categories: offer.categories,
        visibility: offer.visibility,
      };
      const response = await OfferServices.updateOfferDetails(
        payload,
        authToken
      );
      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        navigate(`/offers/${response.data._id}`, {
          state: {
            finalNavigationData: navigationData,
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }

      setIsFormSaving(false);
    } catch (err) {
      console.log("Error coming while updating offer details");
    }
  };

  const handleChange = (value, field, data) => {
    var updatedOffer = { ...offer };
    if (field === "categories") {
      data
        ? updatedOffer.categories
          ? (value = [...updatedOffer.categories, value])
          : (value = [value])
        : (value = updatedOffer.categories.filter(
            (category) => category !== value
          ));
    } else if (field === "thumbnailUrl") {
      handlePopUpClose();
    } else if (field === "discounts") {
      if (value === "Add") {
        value = updatedOffer.discounts;
        value.splice(data, 0, { numberOfCartItems: "0", discount: "0" });
      } else if (value === "Delete") {
        value = updatedOffer.discounts;
        value.splice(data, 1);
      }
    } else if (field === "discountItems") {
      var temp = value;
      value = updatedOffer.discounts;
      value[data].numberOfCartItems = temp;
      field = "discounts";
    } else if (field === "discountValue") {
      var temp = value;
      value = updatedOffer.discounts;
      value[data].discount = temp;
      field = "discounts";
    }
    updatedOffer[field] = value;
    setOffer(updatedOffer);
  };

  const handlePopUpOpen = (event, popUpName) => {
    handlePopUpClose();
    setPopUp(popUpName);
    setIsPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };
  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
    setPopUp("");
  };
  const handlePopUpSave = async () => {
    handlePopUpClose();
  };

  return (
    <>
      <OfferDetailsView
        offer={offer}
        searchText={searchText}
        allCategories={allCategories}
        handleSearchText={setSearchText}
        handleChange={handleChange}
        handleSave={handleSave}
        popUp={popUp}
        popUpPosition={popUpPosition}
        isPopUpVisible={isPopUpVisible}
        handlePopUpClose={handlePopUpClose}
        handlePopUpOpen={handlePopUpOpen}
        handlePopUpSave={handlePopUpSave}
        isFormSaving={isFormSaving}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    allOffers: state.offers.allOffers,
    selectedOffer: state.offers.selectedOffer,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAllOffers: OfferActions.updateAllOffers,
      updateSelectedOffer: OfferActions.updateSelectedOffer,
      resetOffersReducer: OfferActions.resetOffersReducer,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetails);
