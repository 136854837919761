import React from "react";

import { useLocation } from 'react-router-dom';

import CourseHomeView from './view';
import InnerSidebarNavigation from '@components/InnerSidebarNavigation'
import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';
import EditPencilIcon from '@assets/common/editPencilIcon.png';
import AnalyticsIcon from '@assets/common/analyticsIcon.png';
import ModulesIcon from '@assets/common/coursePageTabsIcon.png';
import PricingIcon from '@assets/common/coursePageTagIcon.png';
import BlogIcon from '@assets/common/searchBox.png';
import SeoIcon from '@assets/common/infoBox.png';
function CourseHome() {
    const location = useLocation();
    
    //Failsafe if no data received from navigation
    let navigationData = [{
        "title" : "Back to Content",
        "route" : "/content?tab=courses",
        "default" : false,        
        "icon" : GoBackArrow
    },
    {
        "title" : "Details",
        "component" : "CourseDetails",
        "default" : true,        
        "icon" : EditPencilIcon
    },
    {
        "title" : "Content",
        "component" : "CourseContent",
        "default" : false,         
        "icon" : ModulesIcon
    },
    {
        "title" : "Pricing",
        "component" : "CoursePricing",
        "default" : false,         
        "icon" : PricingIcon
    },
    {
        "title" : "Blogs",
        "component" : "CourseBlogs",
        "default" : false,        
        "icon" : BlogIcon
    },
    {
        "title" : "SEO",
        "component" : "CourseSEO",
        "default" : false,        
        "icon" : SeoIcon
    },
    {
        "title" : "Analytics",
        "component" : "CourseAnalytics",
        "default" : false,        
        "icon" : AnalyticsIcon
    },
];
    if (location.state && location.state.finalNavigationData) {
        navigationData = location.state.finalNavigationData;
    }
    
    return (
        <>
            <InnerSidebarNavigation
                navigationData={navigationData} />
            <CourseHomeView />
        </>
    )
}

export default CourseHome;