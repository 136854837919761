import React, { useState, useEffect, useRef } from 'react';
import FAQView from './view';

import { useNavigate, defer, useLocation, useSearchParams} from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as FAQServices from '@services/FAQ';
import * as FAQActions from '@redux/actions/FAQ';

import { navigationData } from './data';
import { decryptText } from '@library/enc-dec';
import Swal from 'sweetalert2';
import { produce } from 'immer';

var listOfCheckedFaqs = [];

function FAQS(props) {
	const navigate = useNavigate();
	
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [deferedData, setDeferedData] = useState({});

	const [isSorted, setIsSorted] = useState(props.filterInformation.isSorted);
	const [sortBy, setSortBy] = useState(props.filterInformation.sortBy);

	//------------------------------------------------------
	// START : UseState's to control the functionality disableBulkActionBtn
	const [disableBulkActionBtn, setDisableBulkActionBtn] = useState(true);
	// END : UseState's to control the functionality disableBulkActionBtn

	const [searchParams, setSearchParams] = useSearchParams();
	
	useEffect(() => {
        setSearchParams({
			page: props.filterInformation.page,
			limit: props.filterInformation.limit,
		  })
	}, []);

	useEffect(()=>{
		if(!Number(queryParams.get('limit')) && Number(queryParams.get('page')))
		{
			return
		}
		props.updateFilterInformation({
			page:Number(queryParams.get('page')), 
			limit:Number(queryParams.get('limit')), 
			sortBy, 
			isSorted, 
			searchText:props.filterInformation.searchText
		})
     },[Number(queryParams.get('limit')),Number(queryParams.get('page')), sortBy, isSorted])

	 useEffect(() => {
		
		if(props.filterInformation.limit>0){
		getAllFaqs();
		}
		}, [props.filterInformation]);

	const getAllFaqs = async () => {
		let authToken = decryptText(localStorage.getItem('aEmediat'));
		const payload={
			pageNo: props.filterInformation.page, 
				limit: props.filterInformation.limit, 
				sortBy: props.filterInformation.sortBy, 
				isSorted: props.filterInformation.isSorted, 
				searchText: props.filterInformation.searchText,
		}

		var deferedResponse = defer({
			res: FAQServices.getAllFaqs(payload, authToken)
		});
		setDeferedData(deferedResponse);

	};

	//Fn to check and uncheck all checkboxes
	const handleCheckBoxes = (selectedRows) => {
		listOfCheckedFaqs = selectedRows;
		setDisableBulkActionBtn( selectedRows.length>0 ? false : true)	
	};

	const handleDeleteBulk = async () => {
		try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			let response = await FAQServices.deleteFaqs(
				{ faqDocIds: listOfCheckedFaqs.map(item => item._id)},
				authToken
			);
			if (response.success) {
				getAllFaqs();
				listOfCheckedFaqs = [];
				// document.getElementById('bulkCheckbox').checked = false;
				setDisableBulkActionBtn(true);
				Swal.fire({icon: "success", title: response.message, showConfirmButton: false,timer: 2500});
				return;
			}
			Swal.fire({icon: "error", title: response.message, showConfirmButton: false,timer: 2500});
		} catch (err) {
			console.log('Error coming while deleting the faqs', err);
		}
	};

	const handleRedirectToHome = (event, faqId) => {
		if (faqId === 'none') {
			navigate('/faq', { state: { navigationData } });
		} else {
			navigate('/faq/' + faqId, { state: { navigationData } });
		}
	};

	// To sort data as per date
	const handleSort = (data) => {
		const mapping = {'Date Added': 'createdAt', 'Date Modified': 'updatedAt'}
		var id = data[0]?.id ? mapping[data[0].id] : ''
		setSortBy(id)
		setIsSorted(data[0]?.desc ? data[0].desc : false)
	};

	const handleSearch = (data) => {
		let updatedFilters = produce(props.filterInformation, (draft) => {
			draft.page=0;
			draft.searchText= data.text;
		});
		props.updateFilterInformation(updatedFilters);
	};
	
	return (
		<>
			<FAQView
      			deferedData={deferedData}
				handleRedirectToHome={handleRedirectToHome}

				//START : UseStates & fn for the functionality of bulk actions
				disableBulkActionBtn={disableBulkActionBtn}
				handleDeleteBulk={handleDeleteBulk}
				//START : UseStates & fn to control the functionality of checkboxes
				handleCheckBoxes={handleCheckBoxes}
				handleSort = {handleSort}

				isSorted={isSorted}
				sortBy={sortBy}
				searchText={props.filterInformation.searchText}
				handleSearch={handleSearch}
			/>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		allFaqs: state.faq.allFaqs,
		totalNumberOfFaqs: state.faq.totalNumberOfFaqs,
		pageNo: state.faq.pageNo,
		filterInformation: state.faq.filterInformation
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateAllFaqs: FAQActions.updateAllFaqs,
			updateTotalNumOfFaqs: FAQActions.updateTotalNumOfFaqs,
			updateFaqSet: FAQActions.updateFaqSet,
			updatePageNo: FAQActions.updatePageNo,
			updateFilterInformation: FAQActions.updateFilterInformation
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQS);
