import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import InvoicePDFView from './view';

function InvoicePDF(props) {
	const dataFetchedRef = useRef(false);
	const location = useLocation();
	const invoice = location.state?.invoice;

	useEffect(() => {
		window.scroll(0, 0);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
	});

	return (
		<>
			<InvoicePDFView invoice={invoice} />
		</>
	);
}

export default InvoicePDF;
