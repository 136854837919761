
export const resetApprovalReducer = () => {
	return {
		type: 'RESET_APPROVAL_REDUCER',
	};
};

export const updateFilters = (value) => {
	return {
		type: 'UPDATE_APPROVAL_FILTERS',
		payload: {
			filters: value,
		},
	};
};