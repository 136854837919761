import { useState, useEffect } from 'react';
import DynamicHomeView from './view';
import { decryptText } from '@library/enc-dec';

import * as DynamicPageServices from '@services/DynamicPages';

function DynamicHome(props) {
	const [allCourses, setAllCourses] = useState([]);
	const [allOffers, setAllOffers] = useState([]);
	const [searchTextCourses, setSearchTextCourses] = useState('');
	const [searchTextOffers, setSearchTextOffers] = useState('');
	const [isCourseDropDownVisible, setIsCourseDropDownVisible] = useState(false);
	const [isOffersDropDownVisible, setIsOffersDropDownVisible] = useState(false);
	const [selectedOffers, setSelectedOffers] = useState([]);
	const [selectedCourses, setSelectedCourses] = useState([]);

	const [offerHeading, setOfferHeading] = useState('');
	const [offerDescription, setOfferDescription] = useState('');

	useEffect(() => {
		getAllCourses();
		getAllOffers();
		getLatestProducts();
	}, []);

	// Get All Courses to be shown in the drop-down
	const getAllCourses = async () => {
		try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));

			let response = await DynamicPageServices.getAllPublicCourses('', authToken);
			if (response.success) {
				setAllCourses(response.data);
			}
		} catch (err) {
			console.log('Error coming while fetching all courses', err);
		}
	};

	// Get All Offers to be shown in the drop-down
	const getAllOffers = async () => {
		try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			let response = await DynamicPageServices.getAllPublicOffers(authToken);
			if (response.success) {
				setAllOffers(response.data);
				if (response.data.length > 0) {
					setOfferHeading(response.data[0].homeOfferNote.title);
					setOfferDescription(response.data[0].homeOfferNote.description);
				}
			}
		} catch (err) {
			console.log('Error coming while fetching all offers', err);
		}
	};

	const getLatestProducts = async () => {
		try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			let response = await DynamicPageServices.getLatestProducts(authToken);
			if (response.success) {
				setSelectedOffers(response.data[0].offers);
				setSelectedCourses(response.data[0].courses);
				if (response.data.length > 0) {
					setOfferHeading(response.data[0].homeOfferNote.title);
					setOfferDescription(response.data[0].homeOfferNote.description);
				}
			}
		} catch (err) {
			console.log('Error coming while fetching Selected Products', err);
		}
	};

	const handleSelectedCourses = async (courseId) => {
		if (selectedCourses.includes(String(courseId))) {
			let courseList = [...selectedCourses];

			courseList = courseList.filter((id) => {
				return !(id===courseId);
			});

			setSelectedCourses(courseList);
		} else if (selectedCourses.length < 5) {
			setSelectedCourses([...selectedCourses, courseId]);
		}

	};
	const handleSelectedOffers = async (offerId) => {
		if (selectedOffers.includes(String(offerId))) {
			let offerList = [...selectedOffers];

			offerList = offerList.filter((id) => {
				return !(id===offerId);
			});
			setSelectedOffers(offerList);
		} else if (selectedOffers.length < 2) {
			setSelectedOffers([...selectedOffers, offerId]);
		}

	};

	const saveData = async () => {
		try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			let payload = {
				courseIds: selectedCourses,
				offerIds: selectedOffers,
				offerNote: {
					title: offerHeading,
					description: offerDescription,
				},
			};

			let response = await DynamicPageServices.updateHomePage(payload, authToken);

			alert(response.message);
		} catch (err) {
			alert(err);
		}
	};

	return (
		<>
			<DynamicHomeView
				// START: To handle courses dropdown
				dropDownListCourses={allCourses}
				selectedCourses={selectedCourses}
				handleSelectedCourses={(courseId) => {
					handleSelectedCourses(courseId);
				}}
				searchTextCourses={searchTextCourses}
				handleSearchTextCourses={(text) => {
					setSearchTextCourses(text);
				}}
				isCourseDropDownVisible={isCourseDropDownVisible}
				handleCourseDropDownVisibility={(value) => {
					setIsCourseDropDownVisible(value);
				}}
				// START: To handle courses dropdown
				dropDownListOffers={allOffers}
				selectedOffers={selectedOffers}
				handleSelectedOffers={(offerId) => {
					handleSelectedOffers(offerId);
				}}
				searchTextOffers={searchTextOffers}
				handleSearchTextOffers={(text) => {
					setSearchTextOffers(text);
				}}
				isOfferDropDownVisible={isOffersDropDownVisible}
				handleOfferDropDownVisibility={(value) => {
					setIsOffersDropDownVisible(value);
				}}
				// START: To handle offer heading and description
				offerHeading={offerHeading}
				offerDescription={offerDescription}
				handleOfferHeading={(text) => setOfferHeading(text)}
				handleOfferDescription={(text) => setOfferDescription(text)}
				// START: To handle form submit
				submit={() => saveData()}
			/>
		</>
	);
}

export default DynamicHome;
