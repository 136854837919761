export const getAllCategories = async (payload, authToken) => {
	try {
		const {pageNo, limit, isSorted, sortBy, searchText} = payload
	    const url = `${process.env.REACT_APP_BASE_URL}/getAllCategories?pageNo=${pageNo}&limit=${limit}${searchText? '&searchText='+searchText: ''}${sortBy? '&sortBy='+sortBy: ''}${isSorted? '&isSorted='+isSorted: ''}`
	
		return await fetch(url,{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Category : getAllCategories()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateCategoryVisibility = async (payload, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/updateCategoryVisibility`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
				body: JSON.stringify(payload),
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from course category services : updateCategoryVisibility()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getCategory = async (categoryDocId, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getCategory/${categoryDocId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Category services: getCategory() ', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateCategory = async (category, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateCategory`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(category),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from course category services : updateCategory()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const addCategory = async (category, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/addCategory`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(category),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from course category services : addCategory()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const deleteCategories = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/deleteCategory`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Course category services : deleteCategory()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
