import { Form, json } from "react-router-dom";

export const getCourseContent = async (courseId, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getCourseContent/${courseId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				}
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Course content : getCourseContent ', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
export const updateCourseContent = async (payload, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/updateCourseContent`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
				body: JSON.stringify(payload)
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Course content : updateCourseContent ', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateSeriesThumbnail = async (payload, authToken) => {
	try {
		let formData = new FormData();
		formData.append('thumbnailUrl', payload.thumbnailUrl);

		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateSeriesThumbnail`, {
			method: 'PATCH',
			headers: {
				Authorization: authToken,
			},
			body: formData,
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Video services : updateSeriesThumbnail()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};