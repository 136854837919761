import React, { useState, useEffect, useRef } from "react";
import TagsView from "./view";
import "./style.css";

import Swal from "sweetalert2";
import { decryptText } from "@library/enc-dec";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserServices from "@services/Users";
import * as QueryServices from "@services/Queries";

var defaultCellStyle = null;

function Tags(props) {
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const cellRef = useRef(null);

  const [manualTags, setManualTags] = useState(props.manualTags);

  useEffect(() => {
    if (props.allTags.length > 0) {
      return;
    }
  }, []);

  useEffect(() => {
    setManualTags(props.manualTags);
  }, [props.manualTags]);

  // To close the popup container
  const handlePopUpOpen = (event) => {
    handlePopUpClose();

    var cell = event.target.parentElement.parentElement.parentElement;
    cellRef.current = cell;
    defaultCellStyle = cell.style;

    // cell.style.zIndex = '1';
    cell.style.overflow = "visible";
    cell.style.width = "fit-content";
    cell.style.position = "relative";
    cell.style.backgroundColor = "#ffffff";
    cell.style.minWidth = "100%";
    cell.style.borderRight = "1px solid #e9e9e9";
    cell.backgroundColor = "red";

    setIsPopUpVisible(true);
    event.stopPropagation();
  };
  const handlePopUpClose = () => {
    if (defaultCellStyle && cellRef.current) {
      cellRef.current.style = defaultCellStyle;
    }
    setIsPopUpVisible(false);
  };
  // To handleSave buttons on the popup
  const handlePopUpSave = async (data) => {
    handlePopUpClose();
    updateTags(data);
  };

  const updateTags = async (data) => {
    if (props.userId) {
      const payload = {
        userId: props.userId,
        fields: [
          {
            key: "userManualTags",
            value: data.map((tag) => tag._id),
          },
        ],
      };

      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await UserServices.updateUserFields(payload, authToken);

      if (response.success) {
        setManualTags(data);
        props.onChange(data);
        Swal.fire({
          icon: "success",
          title: "Tags updated",
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } else if (props.queryId) {
      const payload = {
        queryId: props.queryId,
        fieldName: "customerCareTags",
        fieldValue: data.map((tag) => tag._id),
      };

      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await QueryServices.updateCustomerCareFields(
        payload,
        authToken
      );

      if (response.success) {
        setManualTags(data);
        props.onChange(data);
        Swal.fire({
          icon: "success",
          title: "Tags updated",
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    }
  };

  return (
    <TagsView
      props={props}
      isPopUpVisible={isPopUpVisible}
      allTags={props.allTags}
      manualTags={manualTags}
      handlePopUpOpen={handlePopUpOpen}
      handlePopUpClose={handlePopUpClose}
      handlePopUpSave={handlePopUpSave}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    allTags: state.tags.allTags,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
