import GoBackArrow from "@assets/sidebarNavigation/goBackArrow.png";
import EditPencilIcon from "@assets/common/editPencilIcon.png";

export const faqQuestionSetFromApi = {
  success: true,
  message: "successfull",
  data: [
    {
      questionId: 1,
      question: "1",
      answer: "answer 1",
      dataCompleted: true,
    },
    {
      questionId: 2,
      question: "2",
      answer: "answer 2",
      dataCompleted: true,
    },
    {
      questionId: 3,
      question: "3",
      answer: "answer 3",
      dataCompleted: true,
    },
  ],
};

//Object to handle naviagtion
export const navigationData = [
  {
    title: "Back to Content",
    route: "/content?tab=faq",
    default: false,
    icon: GoBackArrow,
  },
  {
    title: "Details",
    component: "FaqDetails",
    default: true,
    icon: EditPencilIcon,
  },
];
