import React from 'react';

import { useLocation } from 'react-router-dom';

import ExamHomeView from './view';
import InnerSidebarNavigation from '@components/InnerSidebarNavigation';
import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';
import QuestionMark from '@assets/sidebarNavigation/questionMark.png';
import EditPencilIcon from '@assets/common/editPencilIcon.png';


function ExamHome() {
	const location = useLocation();

	//Failsafe if no data received from navigation
	let navigationData = [
		{
			title: 'Back to Exam',
			route: '/content?tab=exams',
			icon: GoBackArrow,
		},
		{
			title: 'Details',
			"component" : "ExamDetails",
			icon: EditPencilIcon,
		},
		{
			title: 'Question',
			"component" : "ExamQuestions",
			icon: QuestionMark,
		},
	];
	
	if (location.state && location.state.navigationData) {
		navigationData = location.state.navigationData;
	}
	return (
		<>
			<InnerSidebarNavigation navigationData={navigationData} />
			<ExamHomeView />
		</>
	);
}

export default ExamHome;
