import React from 'react';

import DeleteIcon from '@assets/common/deleteBlack.png';
import PictureIcon from '@assets/common/pictureIcon.png';
import MoveBarIcon from '@assets/common/moveBarIcon.png';
import ImageInput from '@components/ImageInput';
import PopUp from '@components/PopUp';

import './question.css';

function QuestionView(props) {
	return (
		<>
			<div ref={props.mainQuestionDivRef} className='questionContainer'>
				
				<div className='questionContainer_1'>
					<div>
						<img className='MoveBarIcon' src={MoveBarIcon} alt='MoveBarIcon' />
					</div>
					<div style={{ width: '100%' }}>
						{/* START : Question Container */}
						<div className='questionInnerContainer'>
							<div className='questionHeaderContainer d-flex'>
								<p>
									Question <span>{props.questionNo}</span>
								</p>
								<img style={{width: '18px', marginLeft:'auto'}}
									onClick={props.handleOpenPopUp}
									src={PictureIcon}
									alt='PictureIcon'
								/><img
								onClick={props.handleRemoveQuestion}
								style={{width: '15px'}}
								src={DeleteIcon}
								alt='DeleteIcon'
							/>
			
							</div>

							<textarea
								value={props.question}
								onChange={(event) => {
									props.handleQuestion(event.target.value);
								}}
								className='w-100 text-muted'
								placeholder='Write a Question'
								rows='3'></textarea>
							
							{props.thumbnailUrl && <img src={props.thumbnailUrl} className='w-100'/>}
						</div>
						{/* END : Question Container */}

						{/* START : Options Container */}
						<div className='questionInnerContainer'>
							<div className='optionConatiner '>
								<div className='form-check optionInnerContainer '>
									<input
										checked={props.answer === props.option_1 ? true : false}
										onChange={(event) => {
											props.handleOptionRadioBtn(event, props.option_1);
										}}
										className='form-check-input'
										type='radio'
										name={'optionRadioBtnOfQuestion_' + props.questionId}
									/>
									<input
										value={props.option_1}
										onChange={(event) => {
											props.handleOption(event, 0);
										}}
										type='text'
										className=' inputBox'
										placeholder='Option 1'
									/>
								</div>

								<div className='form-check optionInnerContainer'>
									<input
										checked={props.answer === props.option_2 ? true : false}
										onChange={(event) => {
											props.handleOptionRadioBtn(event, props.option_2);
										}}
										className='form-check-input'
										type='radio'
										name={'optionRadioBtnOfQuestion_' + props.questionId}
									/>
									<input
										value={props.option_2}
										onChange={(event) => {
											props.handleOption(event, 1);
										}}
										type='text'
										className=' inputBox'
										placeholder='Option 2'
									/>
								</div>

								<div className='form-check optionInnerContainer'>
									<input
										checked={props.answer === props.option_3 ? true : false}
										onChange={(event) => {
											props.handleOptionRadioBtn(event, props.option_3);
										}}
										className='form-check-input'
										type='radio'
										name={'optionRadioBtnOfQuestion_' + props.questionId}
									/>
									<input
										value={props.option_3}
										onChange={(event) => {
											props.handleOption(event, 2);
										}}
										type='text'
										className=' inputBox'
										placeholder='Option 3'
									/>
								</div>

								<div className='form-check optionInnerContainer'>
									<input
										checked={props.answer === props.option_4 ? true : false}
										onChange={(event) => {
											props.handleOptionRadioBtn(event, props.option_4);
										}}
										className='form-check-input'
										type='radio'
										name={'optionRadioBtnOfQuestion_' + props.questionId}
									/>
									<input
										value={props.option_4}
										onChange={(event) => {
											props.handleOption(event, 3);
										}}
										type='text'
										className=' inputBox'
										placeholder='Option 4'
									/>
								</div>
							</div>
						</div>
						{/* END : Option Container */}
					</div>
					
				</div>
				
				<PopUp
                visible={props.isPopUpVisible}
                top={props.popUpPosition.top}
                left={props.popUpPosition.left}
                handleOutSideClick={props.handlePopUpClose}>
					<div className="full-page-overlay">
						<ImageInput
						handleClose={props.handlePopUpClose}
						handleAdd={props.handleImageInput} 
						folderName={"mainQuestion"}
						/>
					</div>
						
				</PopUp>
			</div>
		</>
	);
}

export default QuestionView;
