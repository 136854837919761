import React, { useEffect, useState } from 'react';

import EditVisibilityPopUpView from './view';


import * as CoursesServices from '@services/Course';

import { decryptText } from '@library/enc-dec';

function EditVisibilityPopUp(props) {
	const [error, setError] = useState('');
	const [visibility, setVisibility] = useState(false)

	useEffect(()=>{
		
	},[])

	const handleCloseBtn = () => {
		props.handleClose()
	};

	const handleSaveBtn = async () => {
		if (false) {
			setError('Number is incorrect');
			setTimeout(function () {
				setError('');
			}, 2000);
		} else {

			if(visibility)
			{
				const payload = {
					courseIds : props.courses,
					visibility : visibility
				}
	
				let authToken = decryptText(localStorage.getItem('aEmediat'));
				// var isSaved = await props.handleSave(selectedSeriesList);
				const response = await CoursesServices.updateCoursesVisibility(payload, authToken);
				
				if (response.success) {
					response.courseIds = props.courses
					response.data.visibility = visibility
					props.handleSave(response)
				}
				alert(response.message);	
			}
			
		}
	};

	return (
		<EditVisibilityPopUpView
				error={error}
				visibility={visibility}
				setVisibility={setVisibility}
				handleCloseBtn={handleCloseBtn}
				handleSaveBtn={handleSaveBtn}
			/>
	);
}

export default EditVisibilityPopUp;
