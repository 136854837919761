export const updateContentTab = (value) => {
	return {
		type: 'UPDATE_CONTENT_TAB',
		payload: {
			tab: value,
		},
	};
};

export const updateSuperAdminTab = (value) => {
	return {
		type: 'UPDATE_SUPER_ADMIN_TAB',
		payload: {
			tab: value,
		},
	};
};

export const updateUserTab = (value) => {
	return {
		type: 'UPDATE_USER_TAB',
		payload: {
			tab: value,
		},
	};
};

export const updateCustomerCareTab = (value) => {
	return {
		type: 'UPDATE_CUSTOMER_CARE_TAB',
		payload: {
			tab: value,
		},
	};
};

export const updateLeadsTab = (value) => {
	return {
		type: 'UPDATE_LEADS_TAB',
		payload: {
			tab: value,
		},
	};
};


export const updateBSSTab = (value) => {
	return {
		type: 'UPDATE_BSS_TAB',
		payload: {
			tab: value,
		},
	};
};




