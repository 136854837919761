import React from 'react';
import ReplySectionView from './view';

function ReplySection(props) {
	return (
		<>
			<ReplySectionView
				currentCommentId={props.currentCommentId}
				commentRepliesData={props.commentRepliesData}
				handleCommentReply={props.handleCommentReply}
				commentAction={props.handleReplyStatus}
				handleReplyLike={props.handleReplyLike}
				handleReplyDislike={props.handleReplyDislike}
			/>
		</>
	);
}

export default ReplySection;
