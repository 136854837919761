import React from 'react';
import { Link } from 'react-router-dom';

import './style.css';

import ClockIcon from '@assets/common/clockIcon.png';
import CopyIcon from '@assets/common/copyIcon2.png';
import OfferCard from '@components/OfferCard';



function CoursePricingView(props) {
	return (
		<>
			<div
				className=' d-flex flex-grow-1 gap-3'
				style={{ maxWidth: 'none' }}>
				<div classs='d-flex' style={{ width: '100%', maxWidth: '799px' }}>
					<div className='marginLeftContainer'>
						{/* START : Error container */}
						{props.formValuesEmpty && (
							<div className='incompleteFieldsError'>
								<span>All input fields are required</span>
							</div>
						)}
						{/* END : Error container */}
						{/* START : Deal ending section */}
						<h5 style={{ marginTop: '16px' }} className='subHeading'>
						 <input type='checkbox' 
						 className='colorCheckbox' 
						 style={{marginRight: '10px'}}
						 checked={props.isOfferExpiryChecked}
						 onChange={(event)=>props.handleChange(event.target.checked, "offerExpiryCheck")}
						 />
						 Offer price ending in
						</h5>
					<div className='d-flex gap-2'>
						<input
							style={{ width: '130px' }}
							type='number'
							className='form-control d-inline-block coursePricingInputs'
							placeholder='Days'
							value={props.allPrices?.offerExpiry?.days}
							onChange={props.allPrices.courseType === 'paid' ? (event)=>props.handleChange(event.target.value, "days") : null}
						/>
						<input
							style={{ width: '130px' }}
							type='number'
							className='form-control d-inline-block coursePricingInputs'
							placeholder='Hours'
							value={props.allPrices?.offerExpiry?.hours}
							onChange={props.allPrices.courseType === 'paid' ? (event)=>props.handleChange(event.target.value, "hours") : null}
						/>
						<input
							style={{ width: '130px' }}
							type='number'
							className='form-control d-inline-block coursePricingInputs'
							placeholder='Min'
							value={props.allPrices?.offerExpiry?.minutes}
							onChange={props.allPrices.courseType === 'paid' ? (event)=>props.handleChange(event.target.value, "minutes") : null}
						/>
						<img
							style={{ height: '43px'}}
							src={ClockIcon}
							alt='ClockIcon'
						/>
					</div>
						{/* END : Deal ending section */}
						<br />
						{/* START : Course free section */}
						
						<div className='pricingPageReginPara'>
								Course Fee <span className='text-danger'>*</span></div>
						<div className='form-check'>
							<input
								checked={props.allPrices?.courseType === 'free'}
								onChange={(event) => {
									props.handleChange('free', 'courseType');
								}}
								className='form-check-input'
								type='radio'
								id='courseIsFreeCheckbox'
								name='courseType'
							/>
							<label
								style={{
									marginTop: '-3px',
								}}
								htmlFor='courseIsFreeCheckbox'>
								Course is Free
							</label>
						</div>
						{/* END : Course free section */}
						{/* START : course Coming Soon section */}
						<div className='form-check'>
							<input
								checked={props.allPrices.courseType === 'comingSoon'}
								onChange={(event) => {
									props.handleChange('comingSoon', 'courseType');
								}}
								className='form-check-input'
								type='radio'
								id='courseIsComingSoonCheckbox'
								name='courseType'
							/>
							<label
								style={{
									marginTop: '-3px',
								}}
								htmlFor='courseIsComingSoonCheckbox'>
								Course is Coming Soon
							</label>
						</div>
						{/* END : course Coming Soon section */}
						{/* START : course is paid section */}

						<div className='form-check'>
							<input
								checked={props.allPrices.courseType === 'paid'}
								onChange={(event) => {
									props.handleChange('paid', 'courseType');
								}}
								className='form-check-input'
								type='radio'
								id='courseIsPaid'
								name='courseType'
							/>
							<label
								style={{
									marginTop: '-3px',
								}}
								htmlFor='courseIsPaid'>
								Course is Paid
							</label>
						</div>
						{/* END : course is paid section */}
						
						
						<div
							className={
								props.allPrices.courseType === 'paid' ? '' : 'disablePricingIfCourseIsFree'
							}>
							<p className='pricingPageReginPara'>(Developing) ASIA</p>
							<p>
								India, Sri Lanka, Bangladesh, Bhutan, Nepal, Myanmar, Laos, Thailand,
								Vietnam, Cambodia, Pakistan, Afghanistan, Tajikistan, Iran,
								Turkmenistan, Uzbekistan, Kyrgyzstan, Kazakhstan, Yemen, Syria, Iraq,
								Lebanon, Jordan
							</p>

							{/* START : Common contries */}
							
						{
							props.continents.map((continent, key)=>{
								return <div key={key}>
									{continent!='DEVELOPING_ASIA' ? <p className='pricingPageReginPara'>{continent}</p> :''}
									<div className='row justify-content-between'>
										<div className='col-6'>
											<input
												type='number'
												className='form-control inputBox coursePricingInputs'
												placeholder='Crossed price in INR'
												value={
													props.allPrices?.prices?.[continent]
														? props.allPrices.prices[continent]['crossedPrice']
														: ''
												}
												onChange={(event) =>
													props.handlePrice(event, continent, 'crossedPrice')
												}
											/>
										</div>
										<div className='col-6'>
											<input
												type='number'
												className='form-control inputBox coursePricingInputs'
												placeholder='Offer price in INR'
												value={
													props.allPrices?.prices?.[continent]
														? props.allPrices.prices[continent]['offerPrice']
														: ''
												}
												onChange={(event) =>
													props.handlePrice(event, continent, 'offerPrice')
												}
											/>
										</div>
									</div>
								</div>
							})
						}
							

						</div>

						<div className="CSVDisplay">
							CSV <img src={CopyIcon}></img>

							{/* <ol contenteditable="true" spellcheck="false">
								{
								  props.continents.map((continent, index)=>{
									var offerPrice= props.allPrices?.prices?.[continent]['offerPrice']
									var crossPrice = props.allPrices?.prices?.[continent]['crossedPrice']
									return <li>
												{continent}, {crossPrice}, {offerPrice} 
										     </li>
										  
								  })
								}
							</ol>  */}
							<div className="CSVDiv">
								<div className="numbers">
								</div>
								<textarea
								    className="CSVTextArea"
								     value={props.editedCsv}
      								 onChange={props.handleCsvChange}>
								</textarea>
							</div>
						</div>
					</div>
				</div>
				<div className='d-flex justify-content-center p-4'>
					<OfferCard
					    color={props.courseDetails.cardBackgroundColor}
						courseType={props.allPrices.courseType}
						thumbnailUrl={props.courseDetails.rectangleThumbnailUrl}
						validityText={props.courseDetails.validityText}
						cardTitle={props.courseDetails.cardTitle}
						pointers={props.courseDetails.cardPointers}
						offerExpiry={props.allPrices.offerExpiry}
						price={
							props.allPrices?.prices?.DEVELOPING_ASIA
								? props.allPrices?.prices?.DEVELOPING_ASIA?.offerPrice
								: ''
						}
						oldPrice={
							props.allPrices?.prices?.DEVELOPING_ASIA
								? props.allPrices?.prices?.DEVELOPING_ASIA?.crossedPrice
								: ''
						}
						cardIcons={props.courseDetails.cardIcons}
					/>
				</div>
			</div>
		</>
	);
}

export default CoursePricingView;
