import React from 'react';

import UserProfileView from './view';

function UserProfile(props) {
	return (
		<>
			<UserProfileView 
				{...props}
				/>
		</>
	);
}

export default UserProfile;
