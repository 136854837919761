import React from 'react';

import SearchIcon from '@assets/common/search.png';
import FilterIcon from '@assets/common/filter.png';
import ForwardIcon from '@assets/common/forward.png';
import BarsIcon from '@assets/common/bars.png';
import DownIcon from '@assets/common/dropDown.png';
import './style.css';
import FilterDate  from '@components/FilterDate';
import PopUp from '@components/PopUp';
import SelectionPopUp from '@components/SelectionPopUp';

const tagYearsList = [{_id:'2022', name:'2022'},
                        {_id:'2023', name:'2023'},
                        {_id:'2024', name:'2024'},
                        {_id:'2025', name:'2025'},
                        ]
const tagMonthsList=[{_id: '01', name: 'January '},  
                    {_id: '02', name: 'February '},
                    {_id: '03', name: 'March    '},
                    {_id: '04', name: 'April    '},
                    {_id: '05', name: 'May      '},
                    {_id: '06', name: 'June     '},
                    {_id: '07', name: 'July     '},
                    {_id: '08', name: 'August   '},
                    {_id: '09', name: 'September'},
                    {_id: '10', name: 'October  '},
                    {_id: '11', name: 'November '},
                    {_id: '12', name: 'December '}
                ];
const statusList=[{_id: "enrolled", name:'Enrolled'},
                    {_id: "active", name:'Active'},
                    {_id: "paused", name:'Paused'},
                    {_id: "expired", name:'Expired'},
                    {_id: "completed", name:'Completed'},
                                ]

function SearchView(props) {
     var filters = props.props.filters
     var arr = props.props.isTagFilterEnabled ? [0,1,2]:[1,2,3]
    return(
    <div style={{zIndex:'400'}}>    
        {props.isFiltersVisible ? <div className="full-page-overlay" onClick={()=>props.onFilterClick(!props.isFiltersVisible)}> </div> : ""}
        <div className="searchTop" style={{zIndex: props.isFiltersVisible ? 999999999: 100}}>
            <div className="searchMain">
                <img src={SearchIcon} onClick={props.onSearchClick}></img>
                <input type='text' placeholder={props.props.placeholder} value={props.text}
                onChange={props.handleText} 
                onKeyDown={props.handleText}
                />
                {props.isFiltersEnabled && <img src={FilterIcon} onClick={()=>props.onFilterClick(!props.isFiltersVisible)}></img>}
            </div>
            {props.isFiltersVisible ? 
                    <div className="searchFilters gap-4">
                        {props.isTagFilterVisible?
                            <>
                            <div className='d-flex flex-column gap-3 justify-space-around'>
                               <div className='d-flex' style={{fontSize:'14px'}}> 
                                    <span onClick={(e)=>props.handleTagFilterVisibility(false)} style={{cursor:'pointer'}}>
                                      <img src={ForwardIcon} style={{rotate:'180deg', paddingRight:'5px', height:'10px'}}/> 
                                       &nbsp; Filter Automatic tag(s)
                                    </span>
                                   <button className='ms-auto bg-dark text-light rounded px-4 py-0' onClick={(e)=>props.handleTagFilterVisibility(false, "save")}>Save</button>
                                </div>
                            </div>
                           <div className='d-flex gap-4 mt-3'>
                               <div className='filterSelect' onClick={(e)=>props.handlePopUpOpen(e, 'yearTagPopUp')}>{props.selectedTagFilter?.yearTag? props.selectedTagFilter.yearTag : 'Select year Tag'} <img src={DownIcon}/></div>
                               <div className='filterSelect' onClick={(e)=>props.handlePopUpOpen(e, 'mainTagPopUp')}>{props.selectedTagFilter?.mainTag? props.selectedTagFilter.mainTag : 'Select main Tag'} <img src={DownIcon}/></div>
                               <div className='filterSelect' onClick={(e)=>props.handlePopUpOpen(e, 'subTagPopUp')}>{props.selectedTagFilter?.subTag? props.selectedTagFilter.subTag : 'Select sub Tag'} <img src={DownIcon}/></div>
                           </div> 
                           <div className='d-flex gap-4 mt-3'>
                               <div className='filterSelect' onClick={(e)=>props.handlePopUpOpen(e, 'monthTagPopUp')}>{props.selectedTagFilter?.monthTag? tagMonthsList.filter(obj=> obj._id ===props.selectedTagFilter.monthTag)[0].name  : 'Select month Tag'} <img src={DownIcon}/></div>
                               <div className='filterSelect' onClick={(e)=>props.handlePopUpOpen(e, 'statusTagPopUp')}>{props.selectedTagFilter?.tagStatus? props.selectedTagFilter.tagStatus : 'Select status Tag'} <img src={DownIcon}/></div>
                               <div style={{flex:0.33}}></div>
                           </div> 
                           
                           <div className='mt-2' style={{fontSize:'14px', color:'#999'}}>
                            Filter manual tags
                            <div className='filterSelect justify-content-between px-2' onClick={(e)=>props.handlePopUpOpen(e, 'manualTagPopUp')}>
                            {props.selectedTagFilter?.manualTag? 
                             props.allTagsData.userManualTags.filter(item => props.selectedTagFilter.manualTag ===item._id)[0].title
                            : 'Select manual Tag'}
                                <img src={DownIcon}/></div>
                            
                            </div>
                           </>
                        :
                    <div className='d-flex flex-column gap-3 justify-space-around'>
                        <div className='d-flex' style={{fontSize:'14px', color:'#999'}}>
                            <span>
                                <img src={BarsIcon} onClick={(e)=>props.handlePopUpOpen(e, 'dateSelectPopUp')}/> Apply filters</span>

                            <button className='ms-auto bg-dark text-light rounded px-4 py-0' onClick={props.onSearchClick}>Save</button>
                        </div>   
                         <div className='d-flex gap-4'>
                            <div className='filterSelect'><FilterDate title="Start Date" value={props.startDate} onChange={(e)=>props.handleStartDate(e.target.value)}/></div>
                            <div className='filterSelect'><FilterDate title="End Date" value={props.endDate} onChange={(e)=>props.handleEndDate(e.target.value)}/></div>
                            {props.props.isTagFilterEnabled? 
                                <div className='filterSelect' onClick={(e)=>props.handleTagFilterVisibility(true)}>
                                    {props.tagCount? `(${props.tagCount}) tags selected`: 
                                    'Select tags'
                                    }
                                    <img src={ForwardIcon}/></div>
                                : 
                                filters && filters[0]? <div className='filterSelect' onClick={(e)=>props.handleFilter(e,0)}>{filters[0].value?filters[0].value:filters[0].placeholder}<img src={filters[0].icon}/></div> : ''
                           }
                            
                         </div>
                         <div className='d-flex gap-4'>
                          {
                          filters && arr.map(index=>filters[index]? 
                                 <div className='filterSelect' onClick={(e)=>props.handleFilter(e,index)} key={index}>
                                    {filters[index].value ? filters[index].value : filters[index].placeholder}
                                    <img src={filters[index].icon}/>
                                </div>
                                :  <div style={{flex:0.33}}></div> )}
                          </div>
                        </div>
                         
                          }
                    </div>
            : null}
            <div style={{zIndex:'99999999999'}}>
            {props.popUp === "dateSelectPopUp"  &&<PopUp visible={props.isPopUpVisible}
				top={70}
				left={10}
				handleOutSideClick={props.handlePopUpClose}>
				<div style={{height: 'auto', overflow:'hidden'}}>
					{props.mainDateOption.map((option, index) => (
						<div
							key={index}
							onClick={(e) => option !=='Select' ?props.handlePopUpSave(e, option):props.handlePopUpOpen(e, 'dateYearPopUp')}
							className='settingOption d-flex'>
							{option}
							<img className='ms-auto' src={option.img} style={{height:'12px'}}/>
						</div>
					))}
				</div>
			</PopUp>}
            {props.popUp === "dateYearPopUp"  &&<PopUp visible={props.isPopUpVisible}
				top={70}
				left={10}
				handleOutSideClick={props.handlePopUpClose}>
				<div style={{height: 'auto', overflow:'hidden'}}>
					{props.years.map((option, index) => (
						<div
							key={index}
							onClick={(e) => props.handlePopUpOpen(e, 'dateMonthPopUp', option)}
							className='settingOption d-flex'>
							{option}
							<img className='ms-auto' src={option.img} style={{height:'12px'}}/>
						</div>
					))}
				</div>
			</PopUp>}
            {props.popUp === "dateMonthPopUp"  &&<PopUp visible={props.isPopUpVisible}
				top={70}
				left={10}
				handleOutSideClick={props.handlePopUpClose}>
				<div style={{height: 'auto', overflow:'hidden'}}>
					{props.months.map((option, index) => (
						<div
							key={index}
							onClick={(e) => props.handlePopUpSave(e, index)}
							className='settingOption d-flex'>
							{option}
							<img className='ms-auto' src={option.img} style={{height:'12px'}}/>
						</div>
					))}
				</div>
			</PopUp>}

            {props.popUp === "yearTagPopUp"  &&<PopUp visible={props.isPopUpVisible}
				top={120}
				left={20}
				handleOutSideClick={props.handlePopUpClose}>
				<SelectionPopUp 
				  data = {tagYearsList}
				  optionKey= {"name"}
				  title={"Select year tag"}
				  searchPlaceholder={""}
				  isSearchEnabled={false}
				  height= {"auto"}
				  width= {"auto"}
				  selectedItems={tagYearsList.filter(item => props.selectedTagFilter.yearTag ===item._id)}
				  dataKey={"_id"}
				  selectedItemsKey={"_id"}
				  selectionType={"single"}
				  handleCancel ={props.handlePopUpClose}
				  handleSave={props.handlePopUpSave}/>
			</PopUp>}
            {props.popUp === "monthTagPopUp"  &&<PopUp visible={props.isPopUpVisible}
				top={170}
				left={20}
				handleOutSideClick={props.handlePopUpClose}>
				<SelectionPopUp 
				  data = {tagMonthsList}
				  optionKey= {"name"}
				  title={"Select month tag"}
				  searchPlaceholder={""}
				  isSearchEnabled={false}
				  height= {"auto"}
				  width= {"auto"}
				  selectedItems={tagMonthsList.filter(item => props.selectedTagFilter.monthTag ===item._id)}
				  dataKey={"_id"}
				  selectedItemsKey={"_id"}
				  selectionType={"single"}
				  handleCancel ={props.handlePopUpClose}
				  handleSave={props.handlePopUpSave}/>
			</PopUp>}
            {props.popUp === "statusTagPopUp"  &&<PopUp visible={props.isPopUpVisible}
				top={170}
				left={205}
				handleOutSideClick={props.handlePopUpClose}>
				<SelectionPopUp 
				  data = {statusList}
				  optionKey= {"name"}
				  title={"Select status tag"}
				  searchPlaceholder={""}
				  isSearchEnabled={false}
				  height= {"auto"}
				  width= {"auto"}
				  selectedItems={statusList.filter(item => props.selectedTagFilter.tagStatus ===item._id)}
				  dataKey={"_id"}
				  selectedItemsKey={"_id"}
				  selectionType={"single"}
				  handleCancel ={props.handlePopUpClose}
				  handleSave={props.handlePopUpSave}/>
			</PopUp>}
            {props.popUp === "mainTagPopUp"  &&<PopUp visible={props.isPopUpVisible}
				top={120}
				left={205}
				handleOutSideClick={props.handlePopUpClose}>
				<SelectionPopUp 
				  data = {props.allTagsData ? props.allTagsData.mainTags.map(tag=>{ return{_id:tag, title:tag}}): []}
				  optionKey= {"title"}
				  title={"Select main tag"}
				  searchPlaceholder={""}
				  isSearchEnabled={false}
				  height= {"250px"}
				  width= {"auto"}
				  selectedItems={props.allTagsData.mainTags.map(tag=>{ return{_id:tag, title:tag}}).filter(item=>item.title===props.selectedTagFilter.mainTag)}
				  dataKey={"_id"}
				  selectedItemsKey={"_id"}
				  selectionType={"single"}
				  handleCancel ={props.handlePopUpClose}
				  handleSave={props.handlePopUpSave}/>
			</PopUp>}
            {props.popUp === "subTagPopUp"  &&<PopUp visible={props.isPopUpVisible}
				top={120}
				left={390}
				handleOutSideClick={props.handlePopUpClose}>
				<SelectionPopUp 
				  data = {props.allTagsData ? props.allTagsData.subTags.map(tag=>{ return{_id:tag, title:tag}}): []}
				  optionKey= {"title"}
				  title={"Select sub tag"}
				  searchPlaceholder={""}
				  isSearchEnabled={false}
				  height= {"250px"}
				  width= {"auto"}
                  selectedItems={props.allTagsData.subTags.map(tag=>{ return{_id:tag, title:tag}}).filter(item => props.selectedTagFilter.subTag ===item.title)}
				  dataKey={"_id"}
				  selectedItemsKey={"_id"}
				  selectionType={"single"}
				  handleCancel ={props.handlePopUpClose}
				  handleSave={props.handlePopUpSave}/>
			</PopUp>}
            {props.popUp === "manualTagPopUp"  &&<PopUp visible={props.isPopUpVisible}
				top={230}
				left={100}
				handleOutSideClick={props.handlePopUpClose}>
				<SelectionPopUp 
				  data = {props.allTagsData ? props.allTagsData.userManualTags: []}
				  optionKey= {"title"}
				  title={"Select manual tag"}
				  searchPlaceholder={""}
				  isSearchEnabled={false}
				  height= {"170px"}
				  width= {"400px"}
                  selectedItems={props.allTagsData.userManualTags.filter(item => props.selectedTagFilter.manualTag ===item._id)}
				  dataKey={"_id"}
				  selectedItemsKey={"_id"}
				  selectionType={"single"}
				  handleCancel ={props.handlePopUpClose}
				  handleSave={props.handlePopUpSave}/>
			</PopUp>}
            </div>
            
        </div>
        
      </div>
    )
}

export default SearchView;