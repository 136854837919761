import React, { useEffect, useState } from 'react';

import NewBSSCoursePopUpView from './view';

function NewBSSCoursePopUp(props) {
    const[course, setCourse] = useState(props.course);

	const handleChange=(value, key)=>{
	   var updatedCourse = {...course};
	   updatedCourse[key] = value
	   setCourse(updatedCourse)
	}

	const handleCancel=()=>{
		props.handleCancel()
	}
	const handleSave=()=>{
		props.handleSave(course)
	}
	
	return (
		<NewBSSCoursePopUpView
		   course = {course}	
		   handleChange={handleChange}
		   handleCancel ={handleCancel}
		   handleSave={handleSave}
			/>
	);
}

export default NewBSSCoursePopUp;
