import React, { useEffect, useState, useRef } from 'react';

import MoveToPopUpView from './view';

function MoveToPopUp(props) {
	const [disableSaveBtn, setDisableSaveBtn] =	useState(true);
	const [allFolders, setAllFolders] = useState([]);
	const [searchFolder , setSearchFolder] =useState('');
	const [isAllSelected, setIsAllSelected] = useState(false);
	const [isFormSaving, setIsFormSaving] = useState(false)


	useEffect(()=>{
		var newFolders = props.folders.slice(1,props.folders.length).map(folder=> ({...folder, checked: false }))
		setAllFolders(newFolders)
	},[props.folders, props.visible])

	// To close the exam status popup container
	const handleCancelBtn = () => {
		setAllFolders('');	
		setDisableSaveBtn(true);
		props.handleCancel() 
	};

	// Handle exam status save button
	const handleSaveBtn = async (event) => {
		setIsFormSaving(true)
		var foldersIds = allFolders.filter(folder => folder.checked).map(folder => folder._id)
		await props.handleSave(event, foldersIds);
		handleCancelBtn();
		setIsFormSaving(false)
	};


	const handleCheckBox = async (event, folder)=>{
		const updatedFolders = allFolders.map((f) => {
			if (f._id === folder._id) {
			  return { ...f, checked: true };
			}
			return { ...f, checked: false };;
		  });
		  setAllFolders(updatedFolders);
	}

	const handleAllSelectCheckbox= ()=>{
      setIsAllSelected(!isAllSelected)
	
	  const updatedFolders = allFolders.map((f) => {
		if(f._id === "All"){
			return f;
		  }
		  return { ...f, checked: !isAllSelected};
	  });

	  setAllFolders(updatedFolders);
	}

	return (
			<MoveToPopUpView
			  props = {props} 
			  searchFolder ={searchFolder}
			  isAllSelected = {isAllSelected}
			  handleAllSelectCheckbox = {handleAllSelectCheckbox}
			  handleSearchInput = {setSearchFolder}
			  handleCancelBtn = {handleCancelBtn}
			  handleSaveBtn  = {handleSaveBtn}
			  disableSaveBtn={disableSaveBtn}
			  handleCheckBox={handleCheckBox}

			  allFolders = {allFolders}
			  isFormSaving={isFormSaving}
				
			/>
	);
}

export default MoveToPopUp;
