import React from 'react';

import './style.css';

function SaveBtn(props) {
    return(<button className="savingBtn" type="button" onClick={props.onClick} disabled = {props.disabled}>
        {props.disabled? 
          <><span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>{props.altText}</> 
        :
         props.text
        }
    </button>)
}

export default SaveBtn