import React, { useEffect, useState, useRef } from 'react';

import PopUpView from './view';

function PopUp(props) {
	const popUpRef = useRef();

    useEffect(() => {
		const handleClickOutside = (event) => {
		  if (popUpRef.current && !popUpRef.current.contains(event.target)) {
			props.handleOutSideClick();
		  }
		};
	  
		document.addEventListener('click', handleClickOutside);
	  
		return () => {
		  document.removeEventListener('click', handleClickOutside);
		};
	  }, []);


	return (
		<PopUpView
		      id = {props.id}
			  ref = {popUpRef}	
			  visible = {props.visible}	
			  top = {props.top}	
			  left = {props.left}	
			  children = {props.children}	
			/>
	);
}

export default PopUp;
