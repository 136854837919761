import React, { useEffect, useState } from "react";
import './verificationPopUpCard.css'
import BackIcon from '@assets/common/backIcon.png';
import SelectInput from "@components/SelectInput";
import SelectionPopUp from '@components/SelectionPopUp';
import TextInputV2 from '@components/TextInputV2';
import PopUp from '@components/PopUp';
import {getEventElementPosition} from '@helpers/common.js';

var rejectionOption = [ {_id:'1', title: 'fullName', name: 'Full Name'},
                        {_id:'2', title: 'guardianName', name: 'Guardian Name'},
                        {_id:'3', title: 'underGraduateDegree', name: 'Degree'},
                        {_id:'4', title: 'registrationNumber', name: 'Registration number'},
                        {_id:'5', title: 'passingYear', name: 'Passing year'},
                        {_id:'6', title: 'underGraduateInstituteName', name: 'Institute'},
                        {_id:'7', title: 'underGraduateInstituteAddress', name: 'Institute address'},
                        {_id:'8', title: 'passportPhoto', name: 'Passport photo'},
                        {_id:'9', title: 'aadhaarCard', name: 'Aadhaar'},
                        {_id:'10', title: 'panCard', name: 'PAN'},
                        {_id:'11', title: 'signature', name: 'Sign'},
                        {_id:'12', title: 'otherDocs', name: 'Other doc'},
                        {_id:'13', title: 'placeOfSignature', name: 'Place of sign'}]

function VerificationPopUpCard(props){
    const [zoom, setZoom]= useState(100)
    const [rejectionList, setRejectionList]= useState([])
    const [isPopUpVisible, setIsPopUpVisible]= useState(false)
	const [popUpPosition, setPopUpPosition] = useState({});


    		// To close the popup container
    const handlePopUpOpen = (event, popUpName) => {
        handlePopUpClose()
        setIsPopUpVisible(true)
        let position = getEventElementPosition(event);
        setPopUpPosition(position);
        event.stopPropagation();
    };
    const handlePopUpClose = () => {
        setIsPopUpVisible(false)
    };
    // To handleSave buttons on the popup
    const handlePopUpSave = async (data) => {
        setRejectionList(data)
        handlePopUpClose();
    };


    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 27) { // 27 is the keycode for the escape key
                props.handleBackButton();
            }
        };

        // Add event listener when component mounts
        document.addEventListener('keydown', handleKeyDown);

        // Clean up event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [props]); // Make sure to include props in the dependency array if they are used inside the event handler

    
    return(
        <>
        
        <div className="bgDiv" style={{
                        visibility: props.iscardVisible ? 'visible' : 'hidden',
                        zIndex: '999'
                    }}></div>
          <div className="verificationCard" 
               style={{
                        visibility: props.iscardVisible ? 'visible' : 'hidden',zIndex: '1000'
                    }}>   
            <img src={BackIcon} className="backIcon" onClick={props.handleBackButton}></img>
            <div className="d-flex gap-3 flex-grow-1 mt-1">
                <div className="imageHolder"> 
                    <input type="checkbox" checked={props.isChecked}
                            onClick={()=>{props.handleCheckBox()}}></input>
                    <br></br>
                    
                    <div className="cardBody">
                        {props.img?
                        <div className="cardImages"  style={{display: zoom<150? 'flex': 'block'}}>
                            <img src={props.img} 
                                 style={{
                                    width: Number(zoom)+'%',
                                    marginBottom: '10px'
                                }}/>
                        </div>
                        :
                        <div className="pdfContainer"
                            style={{
                                transform: `scale(${zoom/110}) translate(0,0)`,
                                transformOrigin: 'top left',
                                width:`${zoom * 0.7 +"%"}`,
                                height:`${zoom *0.7 +"%"}`,
                                padding: '20px',
                                display: zoom<100 ? 'flex': 'block',
                                justifyContent: 'center',
                                alignItems: 'center',
                                }}
                                >
                            {props.children}
                        </div>
                        }


                        <div className="zoomControls" style={{left: 'auto',right: '50%', top:'10%'}}>
                            <div onClick={(event)=> zoom > 100 ? setZoom(parseInt(zoom-50)): ''}>-</div>
                            <input type="text" value={zoom}/>
                            <div  onClick={(event)=>zoom< 350? setZoom(parseInt(zoom+50)): ''}>+</div>
                        </div>
                    </div>
                    
                </div>
                <div className="d-flex flex-column gap-3"> 
                   {props.img? 
                    <div className="d-flex gap-2 flex-grow-1 justify-content-around">
                        <div className="d-flex flex-column justify-content-around">
                            <span>Name On Marksheet/ Degree Certificate</span>
                            <span>Contact Number</span>
                            <span>Whatsapp Number</span>
                            <span>Qualification</span>
                            <span>Courses</span>
                            <span>E-mail</span>
                        </div>
                        <div className="d-flex flex-column justify-content-around">
                            <span>{props.name}</span>
                            <span>{props.contact}</span>
                            <span>{props.whatsapp}</span>
                            <span>{props.qualification}</span>
                            <span>{props.courses?.join(', ')}</span>
                            <span>{props.email}</span>
                        </div>
                    </div> : 
                    <div className="d-flex gap-1 flex-grow-1 flex-column w-100 overflow-auto">
                        {props.data?
                        [["fullName","Full name"],["guardianName","Guardian name"],["bloodGroup","Blood group"],
                        ["underGraduateDegree", "Under Graduate Degree"], ["registrationNumber", "Registration no."],
                        ["passingYear", "Passing year"],["underGraduateInstituteName", "Institute"], 
                        ["underGraduateInstituteAddress","Institute Address"]].map((arr, index)=>{
                            return <div className="d-flex">
                                     <div className="d-flex w-50 p-1">{arr[1]}</div>
                                     <div className="d-flex w-50 p-1">{props.data[arr[0]]}</div>
                                   </div>
                        }):''}

                        <SelectInput
                          title={'Select reason of rejection '}
                          inputText={rejectionList.length > 0 ? 
                            rejectionList.map((reason)=> reason.name+", ") 
                            : "Select pages viewable to user"}
                          onClick={(event)=>{handlePopUpOpen(event, 'pageRights')}}
                        />
                    </div>
                    }
                    <div className='d-flex justify-content-end gap-3 text-danger'>
                        {props.errorText ? props.errorText : null}
                        <input type="button" value="Verify"  className="button green" onClick={()=>{props.handleVerifyButton(rejectionList)}}></input>
                        <input type="button" value="Reject"  className="button red" onClick={()=>{props.handleRejectButton(rejectionList)}}></input>
                    </div>
                </div>
            </div>

            <PopUp visible={isPopUpVisible}
				top={popUpPosition?.top-300}
				left={popUpPosition?.left-300}
				handleOutSideClick={handlePopUpClose}>
				<SelectionPopUp 
				  data = {rejectionOption}
				  optionKey= {"name"}
				  title={""}
				  searchPlaceholder={"Search for rejection reason"}
				  isSearchEnabled={true}
				  height= {"210"}
				  width= {"485px"}
				  selectedItems={rejectionList}
				  dataKey={"_id"}
				  selectedItemsKey={"_id"}
				  selectionType={"multiple"}
				  handleCancel ={handlePopUpClose}
				  handleSave={handlePopUpSave}/>
			</PopUp> 
                
          </div>
        </>
    )
}

export default VerificationPopUpCard;