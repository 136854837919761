import React, { useRef } from "react";
import DragDropFileInputView from "./view";

function DragDropFileInput(props) {
  const fileInputEle = useRef(null);
  const handleUploadClick = (event) => {
    // let fileInput = event.target.parentElement.children[3];
    if (fileInputEle.current) {
      let fileInput = fileInputEle.current;
      fileInput.addEventListener("change", function (fileEvent) {
        if (fileEvent.target.files) {
          props.onFileSubmit(fileEvent.target.files);
        }
      });
      fileInput.click();
    }
  };

  const dropHandler = (event) => {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();
    var FileList = {};
    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...event.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === "file") {
          const file = item.getAsFile();
          if (
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png"
          ) {
            FileList[i] = file;
          }
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...event.dataTransfer.files].forEach((file, i) => {
        if (
          file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/png"
        ) {
          FileList[i] = file;
        }
      });
    }
    props.onFileSubmit(FileList);
  };
  const dragOverHandler = (event) => {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();
  };

  return (
    <DragDropFileInputView
      handleUploadClick={handleUploadClick}
      fileInputEle={fileInputEle}
      dropHandler={dropHandler}
      dragOverHandler={dragOverHandler}
    />
  );
}

export default DragDropFileInput;
