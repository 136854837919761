export const getAllOffers = async (payload, authToken) => {
  try {
    const { pageNo, limit, isSorted, sortBy, searchText } = payload;
    const url = `${
      process.env.REACT_APP_BASE_URL
    }/getAllOffers?pageNo=${pageNo}&limit=${limit}${
      searchText ? "&searchText=" + searchText : ""
    }${sortBy ? "&sortBy=" + sortBy : ""}${
      isSorted ? "&isSorted=" + isSorted : ""
    }`;

    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Offers Services : getAllOffers() ",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const deleteOffers = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/deleteOffers`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Offer services : deletOffers()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getOfferById = async (offerId, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/getOfferById/${offerId}`,
      {
        method: "GET",
        headers: {
          Authorization: authToken,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Offer services : getOffer()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const addOffer = async (offer, authToken) => {
  try {
    // Create forData object as file type objects(img, video) has to be sent as formData
    let formData = new FormData();
    for (var key in offer) {
      formData.append(key, offer[key]);
    }

    return await fetch(`${process.env.REACT_APP_BASE_URL}/addCoupon`, {
      method: "POST",
      headers: {
        Authorization: authToken,
      },
      body: formData,
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Offer services : addOffer()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateOfferDetails = async (payload, authToken) => {
  try {
    // Create forData object as file type objects(img, video) has to be sent as formData
    let formData = new FormData();
    for (var key in payload) {
      if (key === "categories") {
        formData.append(key, JSON.stringify(payload[key]));
      } else if (key === "discounts") {
        formData.append(key, JSON.stringify(payload[key]));
      } else {
        formData.append(key, payload[key]);
      }
    }

    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateOfferDetails`, {
      method: "POST",
      headers: {
        Authorization: authToken,
      },
      body: formData,
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Offer services : updateOfferDetails()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateOfferThumbnail = async (payload, authToken) => {
  try {
    let formData = new FormData();
    for (var key in payload) {
      formData.append(key, payload[key]);
    }

    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateOfferThumbnail`,
      {
        method: "PATCH",
        headers: {
          Authorization: authToken,
        },
        body: formData,
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Offer services : updateOfferThumbnail()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateOffersVisibility = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateOffersVisibility`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Offer services : updateOffer()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};
