import './dynamicDetails.css';

import BulletSelector from '@components/BulletSelector';

import roundedPlusIcon from '@assets/common/roundedPlusIcon.png';
import uploadIcon from '@assets/common/uploadIcon.png';
import DnD from '@components/DnD';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

//Imports for RichEditor
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DeleteIcon from '@assets/common/deleteIcon.png';

function DynamicDetailsView(props) {
	//START : Vars for RichEditor
	const toolbarOptions = [
		{ header: [1, 2, 3, false] },
		{ font: [] },
		// { 'size': ['small', false, 'large', 'huge'] },
		'bold',
		'italic',
		'underline',
		'strike',
		{ script: 'sub' },
		{ script: 'super' },
		{ list: 'bullet' },
	];

	const formats = [
		'header',
		'font',
		'size',
		'bold',
		'italic',
		'underline',
		'strike',
		'script',
		'list',
		'bullet',
		'indent',
	];
	//END : Vars for RichEditor

	return (
		<>
			{props.article ? (
				<div className='rightHandRightPageMainContainer p-2 pt-0' style={{overflow:"scroll"}}>
					<div className='d-flex w-100 bg-white pt-2' style={{position:'sticky', top:0, zIndex:100}}>
						<h4 style={{ marginBottom: '20px', marginLeft: '20px' }}> Article </h4>
						<div className='d-flex gap-2' style={{ marginLeft: 'auto' }}>
							<input
								type='button'
								value='Cancel'
								className='buttonLight'
								onClick={() => {
									props.handleCancle();
								}}></input>
							<input
								type='button'
								value='Save'
								className='buttonDark'
								onClick={() => {
									props.handleSubmit();
								}}></input>
						</div>
					</div>
					<div style={{width:'75%'}}>
						
					<div className='articleDetails'>
						<input
							className='inputDiv w-100'
							type='text'
							placeholder='Article Title*'
							value={props.article.title}
							onChange={(event) => {
								props.updateArticleField('title', event.target.value);
							}}></input>

						{/* START : Card bullets pointers container */}
						<h4
							style={{
								alignItems: 'center',
								display: 'flex',
								marginTop: '16px',
							}}
							className='subHeading'>
							<BulletSelector
								icon={props.article.description?.icon}
								onValueChange={(bulletIcon) => {
									props.handleBulletChange('description', bulletIcon);
								}}
							/>
						</h4>
						{/* START : Code for RichEditor */}
						<ReactQuill
							ref={props.quillRef}
							value={props.article.description?.pointer}
							onChange={(value) => {
								props.updateArticleField('description', value);
							}}
							modules={{
								toolbar: toolbarOptions,
							}}
							formats={formats}
						/>
						{/* END : Code for RichEditor */}
						<div className='d-flex align-items-center gap-3'>
							emediskill.com/articles/{' '}
							<input
								className='inputDiv'
								style={{width:'70%'}}
								type='text'
								placeholder='Type Page Names Separated by hyphens (-)'
								value={props.article.pathUrl}
								onChange={(event) => {
									props.updateArticleField('pathUrl', event.target.value);
								}}></input>
						</div>
						<div
							style={{ backgroundColor: '#E8F9FF', padding: '10px', width: '90%' }}>
							Example: emediskill.com/articles/article-one
						</div>
					</div>

					<DragDropContext onDragEnd={props.onSectionDragEnd}>
						<Droppable droppableId='sectionID'>
							{(provided) => (
								<div
									className='mt-5 pt-3'
									style={{ marginLeft: '18px', marginTop: '48px' }}
									ref={provided.innerRef}
									{...provided.droppableProps}>
									{props.article &&
										props.article['sections'].length > 0 &&
										props.article['sections'].map((section, sectionIndex) => (
											<DnD
												element={section}
												index={sectionIndex}
												newComponent={
													<div className='section p-4 border rounded d-flex flex-column gap-4 mb-4 mt-4 shadow bg-white'>
														<div className='d-flex gap-4 align-items-center'>
															<span>Section {sectionIndex + 1}</span>
															<input
																className='inputDiv flex-grow-1'
																value={props.article.sections[sectionIndex].title}
																onChange={(event) => {
																	props.updateSectionFields(
																		'title',
																		event.target.value,
																		sectionIndex
																	);
																}}></input>
															{/* <img
																src={DeleteIcon}
																alt='Delete Image'
																className='deleteIcon'
															/> */}
														</div>

														{/* START : Code for RichEditor */}
														<ReactQuill
															ref={props.quillRef}
															value={props.article?.sections[sectionIndex].paragraph}
															onChange={(value) => {
																props.updateSectionFields('paragraph', value, sectionIndex);
															}}
															onBlur={() => {
																props.addSectionText(sectionIndex);
															}}
															modules={{
																toolbar: toolbarOptions,
															}}
															formats={formats}
														/>
														{/* END : Code for RichEditor */}
														<h3>Pointer</h3>

														{/**For displaying the Pointers section */}
														<DragDropContext
															onDragEnd={(result) =>
																props.onPointerDragEnd(result, sectionIndex)
															}>
															<Droppable droppableId='pointerID'>
																{(provided) => (
																	<div
																		className='mt-1 pt-3 gap-1'
																		style={{ marginLeft: '18px', marginTop: '48px' }}
																		ref={provided.innerRef}
																		{...provided.droppableProps}>
																		{section['pointers'].length > 0 &&
																			section['pointers'].map((pointer, pointerIndex) => (
																				<DnD
																					element={pointer}
																					index={pointerIndex}
																					newComponent={
																						<div className='d-flex gap-4 align-items-center bg-white mt-2'>
																							<img src={pointer?.icon} style={{width:'20px'}}></img>
																							<div className='flex-grow-1 border rounded p-3'>
																								{pointer?.pointer}
																							</div>
																							<img
																								src={DeleteIcon}
																								alt='Delete Icon'
																								className='deleteIcon'
																								onClick={() => {
																									props.deletePointer(sectionIndex, pointerIndex);
																								}}
																							/>
																						</div>
																					}
																				/>
																			))}
																	</div>
																)}
															</Droppable>
														</DragDropContext>

														{/**End displaying the Pointers section */}

														{/* START : Card bullets pointers container */}
														<h4
															style={{
																alignItems: 'center',
																display: 'flex',
																marginTop: '16px',
															}}
															className='subHeading'>
															<BulletSelector
																onValueChange={(bulletIcon) => {
																	props.handleBulletChange('section' + sectionIndex, bulletIcon);
																}}
															/>
														</h4>

														<div className='row'>
															<div className='col-11'>
																{/* START : Code for RichEditor */}
																<ReactQuill
																	ref={props.quillRef}
																	value={props.newPointers[sectionIndex]}
																	onChange={(value) => props.setNewPointers(sectionIndex, value)}
																	modules={{
																		toolbar: toolbarOptions,
																	}}
																	formats={formats}
																/>
															</div>
															<div className='col-1 d-flex justify-content-center align-items-center'>
																<img
																	src={roundedPlusIcon}
																	alt='PlusIcon'
																	className='deleteIcon'
																	onClick={() => {
																		props.addNewPointer(sectionIndex);
																	}}
																/>
															</div>
														</div>
														<div
															className='inputDiv w-100 d-flex flex-row align-items-center selectAnImage'
															id={'fileInput' + sectionIndex + '_button'}
															placeholder='Select An image'
															onClick={(event) => props.handleFileUpload(event)}>
															<span className='flex-1'>{ props.article.sections[sectionIndex].imageUrl ? props.article.sections[sectionIndex].imageUrl : props.sectionImageUrl[sectionIndex]}</span>
															<img
															    id={'fileInput' + sectionIndex + '_button'}
																src={uploadIcon}
																style={{ width: '30px', marginLeft: 'auto' }}
																></img>
														</div>
														<input
															id={'fileInput' + sectionIndex}
															type='file'
															className='d-none'
															name='proof'
															accept='image/jpeg,image/jpg,image/png'
															onChange={(event) => {
																props.convertToBase64(event, sectionIndex);
															}}
														/>
													</div>
												}
											/>
										))}

									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}

export default DynamicDetailsView;
