import React, { useState, useRef, useEffect } from 'react';
import './style.css';

import ColorPickerIcon from '@assets/common/picker.png'

function ColorPicker(props){
   const [color, setColor]=useState(props.color);    
   const colorPickerRef = useRef(null);
   
   useEffect(()=>{
    setColor(props.color)
   },[props.color])

   useEffect(() => {
    const handleClickOutside = (event) => {
      if (colorPickerRef.current && colorPickerRef.current.contains(event.target)) {
        // Click outside the colorPickerRef, hide color input
        var colorPicker =document.getElementById(props.id)
        colorPicker.click();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [colorPickerRef]);

   const handleChange=(color)=>{
    setColor(color)
    props.onChange(color)
   }
    return(
        <div className='colorPicker border border-1 rounded-2' ref= {colorPickerRef}
            style={{backgroundColor :color}}>
            <img src={ColorPickerIcon} style={{height:'20px'}}/>
            <input type="color" 
                   style={{visibility:"hidden", width:'0px'}}
                   id={props.id}
                    onChange={(event)=>{ 
                        handleChange(event.target.value)
                    }}>
            </input>
        </div>
        )
}

export default ColorPicker