import React, { useEffect, useState } from 'react';

import NewInterestsPopUpView from './view';

function NewInterestsPopUp(props) {
    const[link, setLink] = useState(props.link);

	const handleChange=(value, key)=>{
	   	if(key === "link")
		{
			setLink(value)
		}
	}

	const handleCancel=()=>{
		props.handleCancel()
	}
	const handleSave=()=>{
		props.handleSave(link)
	}
	
	return (
		<NewInterestsPopUpView
		   props = {props}	
           link = {link}
		   handleChange={handleChange}
		   handleCancel ={handleCancel}
		   handleSave={handleSave}
			/>
	);
}

export default NewInterestsPopUp;
