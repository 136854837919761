import React, { useEffect, useState, useRef } from 'react';

import ThumbnailPopupView from './view';
import { getEventElementPosition} from '@helpers/common.js';

function ThumbnailPopup(props) {
	const[thumbnailUrl, setThumbnailUrl] =useState('')
	const [popUpPosition, setPopUpPosition] = useState({});
	const [isPopUpVisible, setIsPopUpVisible] = useState(false);

	useEffect(()=>{
		setThumbnailUrl(props.thumbnailUrl)
	},[props.thumbnailUrl])

	// To close the popup container
	const handlePopUpClose = () => {
		setIsPopUpVisible(false);
	};
	// To handleSave buttons on the popup
	const handlePopUpSave = async () => {
		handlePopUpClose();
	};
	// Open popUps
	const handleOpenPopUp = (event, popUp) => {
		handlePopUpClose();
		setIsPopUpVisible(true)
		let position = getEventElementPosition(event);
		setPopUpPosition(position);
		event.stopPropagation();
	};

	const handleImageInput=(event, files)=>{
		handlePopUpClose();
      props.onValueChange(files)
	}

	return (
		<>
			<ThumbnailPopupView
			thumbnailUrl={thumbnailUrl}
			
			handlePopUpClose={handlePopUpClose}
			handlePopUpSave={handlePopUpSave}
			handleOpenPopUp={handleOpenPopUp}
			popUpPosition={popUpPosition}
			isPopUpVisible={isPopUpVisible}

			handleImageInput={handleImageInput}
			/>
		</>
	);
}

export default ThumbnailPopup;
