import {useEffect, useState} from 'react';
import PopUpContainer from '@components/PopUpContainer';
import moment from 'moment';
import Swal from 'sweetalert2';

const HideLeadPopUp=(props)=>{
    const[date, setDate]=useState('');
    const[time, setTime]=useState('');

    const handleDate=(event)=>{
        setDate(event.target.value)
      }
    const handleTime=(event)=>{
        setTime(event.target.value)
    }


    const handleSave= async()=>{
        if(date==='' || time==='')
        {
            Swal.fire({icon: "error",title: "Please add Date/Time.",showConfirmButton: false,timer: 2500});
           return
        }
        const hiddenLeads = props.leadIds.map(leadId=> {
            return {'_id': leadId, 'time': formatDateTime(date, time)}
        })
        localStorage.setItem('hiddenLeads', JSON.stringify(hiddenLeads))

        props.handleSave()
		Swal.fire({icon: "success",title: "Leads are hidden!",showConfirmButton: false,timer: 2500});
        
    }

    function formatDateTime(date, time) {
        const dateTimeString = date + ' ' + time;
        const momentDateTime = moment(dateTimeString);
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const formattedString = momentDateTime.tz(timeZone).toISOString();
        return formattedString;
    }

    return <PopUpContainer
                title={'Hide till'}
                isSearchEnabled={false}
                style={{width:'387px', height:'236px'}}
                isButtonsEnabled={true}
                handleCancel ={props.handleCancel}
                handleSave={handleSave}>
                 <div className='d-flex flex-column gap-2 w-100'>
                        <div className='d-flex gap-2'>
                            <input type="date" className='date-input w-100' value={date} onChange={handleDate}/>
                            <input type="time" className='time-input w-100' value={time} onChange={handleTime}/>
                        </div>
            </div>
            </PopUpContainer> 
}

export default HideLeadPopUp