import React, { useRef, useState } from "react";

import ButtonBarsView from "./view";
import PlusIcon from "@assets/common/plusRoundBlue.png";
function ButtonBars(props) {
  const containerRef = useRef(null);
  const [showButtons, setShowButtons] = useState(false);

  const renderButton = (button, index) => {
    const { type, text, onClick, iconEnd, iconStart, disabled } = button;

    switch (type) {
      case "round":
        return (
          <button
            className="roundBtn"
            onClick={onClick}
            style={{ backgroundImage: `url(${PlusIcon})` }}
          ></button>
        );
      default:
        return (
          <button className="simpleBtn" onClick={onClick} disabled={disabled}>
            {iconStart ? <img src={iconStart} className="startIcon" /> : null}
            {text}
            {iconEnd ? <img src={iconEnd} className="endIcon" /> : null}
          </button>
        );
    }
  };

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 100; // Adjust scroll amount as needed
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 100; // Adjust scroll amount as needed
    }
  };

  const handleShowButtons = (value) => {
    if (value) {
      setShowButtons(
        containerRef.current.scrollWidth > containerRef.current.clientWidth
      );
    } else {
      setShowButtons(value);
    }
  };

  return (
    <>
      <ButtonBarsView
        props={props}
        renderButton={renderButton}
        containerRef={containerRef}
        scrollLeft={scrollLeft}
        scrollRight={scrollRight}
        setShowButtons={handleShowButtons}
        showButtons={showButtons}
      />
    </>
  );
}

export default ButtonBars;
