import React from "react";

import './offerAnalytics.css';

function OfferAnalyticsView(){
    return(
        <>
            <h1 style={{marginLeft:'240px'}}>Offer Analytics</h1>
        </>
    )
}

export default OfferAnalyticsView;