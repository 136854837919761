const initialState = {
	allExams: [],
	examsSet: [],
	title: '',
	duration: {},
	passPercentage: 0,
	thumbnailUrl: null,
	displayQuestions: 0, // Display questions
	declarationResultInDays: 0,
	hideResultInDays: 0,
	instructions: {},
	passedMessage: '',
	failedMessage: '',
	inSequence: false,
	questionsSet: [],
	pageNo: 0,
	filterInformation:{page: 0,
		limit:20,
		sortBy: '',
		isSorted: true,
		searchText: ''}
};

const ExamReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_ALL_EXAMS':
			return {
				...state,
				allExams: action.payload.allExams,
			};
		case 'UPDATE_TOTAL_NUMBER_OF_EXAMS':
			return {
				...state,
				totalNumberOfExams: action.payload.totalNumberOfExams,
			};

		case 'UPDATE_EXAM_SET':
			return {
				...state,
				examsSet: action.payload.examsSet,
			};
		case 'UPDATE_EXAM_DETAILS':
			return {
				...state,
				title: action.payload.examData.title,
				duration: action.payload.examData.duration,
				passPercentage: action.payload.examData.passPercentage,
				displayQuestions: action.payload.examData.displayQuestions,
				thumbnailUrl: action.payload.examData.thumbnailUrl,
				declarationResultInDays: action.payload.examData.declarationResultInDays,
				hideResultInDays: action.payload.examData.hideResultInDays,
				instructions: action.payload.examData.instructions,
				passedMessage: action.payload.examData.passedMessage,
				failedMessage: action.payload.examData.failedMessage,
				inSequence: action.payload.examData.inSequence,
				questionsSet: action.payload.examData.questionsSet,
				appearedUsers: action.payload.examData.appearedUsers,
			};
		case 'UPDATE_EXAM_TITLE':
			return {
				...state,
				title: action.payload.title,
			};
		case 'UPDATE_EXAM_DURATION':
			return {
				...state,
				duration: action.payload.duration,
			};
		case 'UPDATE_EXAM_PASS_PERCENTAGE':
			return {
				...state,
				passPercentage: action.payload.passPercentage,
			};
		case 'UPDATE_DISPLAY_QUESTIONS':
			return {
				...state,
				displayQuestions: action.payload.displayQuestions,
			};
		case 'UPDATE_EXAM_THUMBNAIL_URL':
			return {
				...state,
				thumbnailUrl: action.payload.thumbnailUrl,
			};

		case 'UPDATE_RESULT_IN_DAYS':
			return {
				...state,
				declarationResultInDays: action.payload.declarationResultInDays,
			};
		case 'UPDATE_HIDE_RESULT_IN_DAYS':
			return {
				...state,
				hideResultInDays: action.payload.hideResultInDays,
			};
		case 'UPDATE_EXAM_INSTRUCTIONS':
			return {
				...state,
				instructions: action.payload.instructions,
			};
		case 'UPDATE_EXAM_PASSED_MESSAGE':
			return {
				...state,
				passedMessage: action.payload.passedMessage,
			};
		case 'UPDATE_EXAM_FAILED_MESSAGE':
			return {
				...state,
				failedMessage: action.payload.failedMessage,
			};
		case 'UPDATE_EXAM_QUESTIONS_IN_SEQUENCE':
			return {
				...state,
				inSequence: action.payload.inSequence,
			};
		case 'UPDATE_EXAM_QUESTIONS_SET':
			return {
				...state,
				questionsSet: action.payload.questionsSet,
			};
		case 'UPDATE_EXAM_PAGE_NUMBER':
			return {
				...state,
				pageNo: action.payload.pageNo,
			};
		case 'UPDATE_EXAM_FILTER_INFORMATION':
			return {
				...state,
				filterInformation: action.payload.filterInformation,
			};
		case 'RESET_EXAMS_REDUCER':
			return initialState;

		default:
			return state;
	}
};

export default ExamReducer;
