import React, { useEffect, useState } from 'react';

import EditMnemonicPopUpView from './view';

import * as CoursesServices from '@services/Course';

import { decryptText } from '@library/enc-dec';

function EditMnemonicPopUp(props) {
	const [error, setError] = useState('');
	const [mnemonic, setMnemonic] = useState(props.mnemonic);

	useEffect(()=>{
		
	},[])

	const handleMnemonic=(event)=>{
         setMnemonic(event.target.value)
	}
	const handleCloseBtn = () => {
		props.handleClose()
	};

	const handleSaveBtn = async () => {
		if (false) {
			setError('Number is incorrect');
			setTimeout(function () {
				setError('');
			}, 2000);
		} else {

			const payload = {
				courseId : props.courseId,
				mnemonic : mnemonic
			}

			let authToken = decryptText(localStorage.getItem('aEmediat'));
			// var isSaved = await props.handleSave(selectedSeriesList);
			const response = await CoursesServices.updateCourseMnemonic(payload, authToken);
			
			if (response.success) {
				props.handleSave(response)
			}else{
				alert(response.message);
			}
		}
	};

	return (
		<EditMnemonicPopUpView
				error={error}
				mnemonic={mnemonic}
				handleMnemonic ={handleMnemonic}
				handleCloseBtn={handleCloseBtn}
				handleSaveBtn={handleSaveBtn}
			/>
	);
}

export default EditMnemonicPopUp;
