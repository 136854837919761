import React from 'react';

import PlusIcon from '@assets/common/plusIcon.png';
// import UploadIcon from '@assets/common/uploadIcon.png';

import './thumbnailPopup.css';
import ImageInput from '@components/ImageInput';
import PopUp from '@components/PopUp';

function ThumbnailPopupView(props) {
	return (
		<>
			<div className="imageCarousel">
				<div className="addCarouselImage" 
					style={{backgroundColor : 'white',
						backgroundImage: props.thumbnailUrl?
										typeof props.thumbnailUrl === 'string' ? 
										`url(${props.thumbnailUrl})`:
										`url(${URL.createObjectURL(props.thumbnailUrl)})`
										: `url(${PlusIcon})`,
						height:'81px', width: '143px',
						backgroundSize: props.thumbnailUrl? 'contain' : '',}}
					
					onClick={props.handleOpenPopUp}
				>
				</div>
			</div>

			<PopUp
                visible={props.isPopUpVisible}
                top={props.popUpPosition.top}
                left={props.popUpPosition.left}
                handleOutSideClick={props.handlePopUpClose}>
                <div className="full-page-overlay">
                    <ImageInput
                    handleClose={props.handlePopUpClose}
                    handleAdd={props.handleImageInput} 
					
                    />
                </div>
                    
            </PopUp>
		</>
	);
}

export default ThumbnailPopupView;
