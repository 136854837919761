import React, { memo } from "react";


function Image({src, defaultSrc, style}){

    return <img src={src != null ?
            typeof src === 'string' ? `${src}` :`${URL.createObjectURL(src)}`
            : defaultSrc}
            style={style}
            />
}

export default memo(Image)