import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import "./courseCategory.css";

import PlusIcon from "@assets/common/plusIcon.png";
import DropDownArrowIcon from "@assets/common/dropDownArrowIcon.png";
import DeleteIcon from "@assets/common/deleteIcon.png";

import RedEye from "@assets/common/redEyeIcon.png";
import GreenEye from "@assets/common/greenEyeIcon.png";

import EditPencilIcon from "@assets/common/editPencilIcon.png";
import ExpandIcon from "@assets/userDashboard/expand.png";

import BasicTableGrid from "@components/BasicTableGrid";
import TextAndLinks from "@components/TextAndLinks";
import ButtonBars from "@components/ButtonBars";

import DateAndUser from "@components/DateAndUser";
import Search from "@components/Search";

function CourseCategoryView(props) {
  return (
    <div
      ref={props.fullscreenElementRef}
      style={{
        maxWidth: "100%",
        width: "100%",
        flexGrow: 1,
        backgroundColor: "#ffffff",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="d-flex align-items-center w-100 justify-content-between">
        <ButtonBars
          buttons={[
            {
              type: "simple",
              text: "New Category",
              onClick: (event) => {
                props.handleRedirectToHome(0, "none", "none");
              },
              disabled: false,
            },
            {
              type: "dropdownOnClick",
              text: "Visibility",
              onClick: (event) => {
                props.handleVisibilityBtnClick(event, "bulk", "none");
              },
              disabled: props.disableBulkActionBtn,
            },
          ]}
        />
        <div className="d-flex gap-2 controlsRight">
          <button onClick={props.toggleFullscreen}>
            <img src={ExpandIcon}></img>
            <br></br>
            Expand
          </button>
          <button
            onClick={props.handleDeleteBulk}
            disabled={props.disableBulkActionBtn}
          >
            <img src={DeleteIcon}></img>
            <br></br>
            Delete
          </button>
          {/* <button onClick={props.handleSettingClick}>
							<img src={SettingIcon}></img>
							<br></br>
							Setting
						</button> */}
          {/* <button onClick={props.handleColumnBtnClick}>
							<img src={ColumnsIcon}></img>
							<br></br>
							Columns
						</button> */}
        </div>
      </div>
      <Search
        value={props.searchText}
        placeholder={"Search course categories"}
        onSearch={(data) => props.handleSearch(data)}
      >
        <div>No Filters Added</div>
      </Search>
      <BasicTableGrid
        gridId="CourseCategories"
        data={props.deferredData} // Data to be displayed
        fixedCols={2} // How many columns are fixed and not scrolling
        initialSize={[35, 321, 231, 364, 150, 150]}
        initialSorting={[
          {
            id: props.sortBy === "createdAt" ? "Date Added" : "Date Modified",
            desc: props.isSorted,
          },
        ]}
        handleSelect={(selectedRows) => {
          props.handleCheckBoxes(selectedRows);
        }}
        length={20}
        onSortChange={(data) => props.handleSort(data)}
        columnsOptionPopUpRef={props.columnsOptionPopUpRef}
        columns={[
          {
            header: "Title",
            cell: (data) => {
              const info = data.row.original;
              return (
                <div className="d-flex gap-2">
                  <TextAndLinks
                    title={info?.title}
                    subTitle={"Category Subtitle"}
                    images={[EditPencilIcon]}
                    onClick={(event, index) => {
                      props.handleRedirectToHome(index, info._id);
                    }}
                  />
                </div>
              );
            },
          },
          {
            header: "Visibility",
            cell: (data) => {
              const info = data.row.original;
              return (
                <p
                  className="visibility"
                  onClick={(event) => {
                    props.handleVisibilityBtnClick(
                      event,
                      info._id,
                      info.isPublic
                    );
                  }}
                >
                  <img
                    className="icon-small pe-2"
                    src={info.isPublic ? GreenEye : RedEye}
                    alt="Visibility"
                  />
                  {info.isPublic ? "Public" : "Unlisted"}
                  <img
                    style={{ marginLeft: "5px", width: "12px" }}
                    src={DropDownArrowIcon}
                    alt="ArrowIcon"
                  />
                </p>
              );
            },
          },
          {
            header: "Date Added",
            accessorFn: (row) => {},
            cell: (data) => {
              const info = data.row.original;
              return (
                <DateAndUser
                  date={info.createdAt}
                  image={info.createdUserDetails?.displayPhoto}
                  imageText={info.createdUserDetails?.displayName}
                />
              );
            },
          },
          {
            header: "Date Modified",
            accessorFn: (row) => {},
            cell: (data) => {
              const info = data.row.original;
              return (
                <DateAndUser
                  date={info.updatedAt}
                  image={info.lastModifiedUserDetails?.displayPhoto}
                  imageText={info.lastModifiedUserDetails?.displayName}
                />
              );
            },
          },
        ]}
      />

      {/* Start : visibilityPopupContainer */}
      {props.is_VisibilityPopupContainerRef_Visible && (
        <>
          <div
            ref={props.visibilityPopupContainerRef}
            style={{
              position: "absolute",
              top: props.visibilityPopupContainerRef_Position.top,
              left: props.visibilityPopupContainerRef_Position.left,
            }}
            className="visibilityPopupContainer p-2"
          >
            <div className="visibilityPopupContainer_inner p-2 m-2">
              <p>Save category as:</p>

              <div className="form-check">
                <input
                  onChange={(event) => {
                    props.handleVisibilityRadioBtns(event, "unlisted");
                  }}
                  checked={props.internalIsUnlisted}
                  className="form-check-input"
                  type="radio"
                  name="visibilityRadioBtn"
                  id="visibilityRadioBtn"
                />
                <label
                  className="form-check-label"
                  htmlFor="visibilityRadioBtn"
                >
                  Unlisted
                </label>
              </div>

              <div className="form-check">
                <input
                  onChange={(event) => {
                    props.handleVisibilityRadioBtns(event, "public");
                  }}
                  checked={props.internalIsPublic}
                  className="form-check-input"
                  type="radio"
                  name="visibilityRadioBtn"
                  id="unlistedRadioBtn"
                />
                <label className="form-check-label" htmlFor="unlistedRadioBtn">
                  Public
                </label>
              </div>
            </div>

            <div style={{ float: "right", marginTop: "10px" }}>
              <button
                onClick={(event) => {
                  props.handleVisibilityCancleBtnClick(event);
                }}
                className="cancleBtn"
              >
                Cancel
              </button>

              <button
                disabled={props.disableSaveBtnOfVisibility}
                style={{
                  cursor: props.disableSaveBtnOfVisibility
                    ? "not-allowed"
                    : "pointer",
                }}
                id="saveVisibilityBtn"
                onClick={(event) => {
                  props.handleSaveBtnVisibility(event);
                }}
                className="saveBtn"
              >
                Save
              </button>
            </div>
          </div>
        </>
      )}
      {/* End : visibilityPopupContainer */}
    </div>
  );
}

export default CourseCategoryView;
