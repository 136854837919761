export const getAllUGDegrees = async (payload, authToken) => {
  // Visibility-> active,disabled,"all"
  const { pageNo, limit, isSorted, sortBy, searchText, visibility, tagType } =
    payload;
  const url = `${
    process.env.REACT_APP_BASE_URL
  }/getAllUGDegrees?pageNo=${pageNo}&limit=${limit}&visibility=${visibility}&tagType=${tagType}${
    searchText ? "&searchText=" + searchText : ""
  }${sortBy ? "&sortBy=" + sortBy : ""}${
    isSorted ? "&isSorted=" + isSorted : ""
  }`;

  try {
    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Tag services : getAllUGDegrees() ",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateUGDegree = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateUGDegree`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from UG services : updateUGDegree()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateUGDegreeVisibility = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateUGDegreesVisibility`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Ug services : updateUGDegreeVisibility()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};
