import React from 'react';
import PopUpContainer from '@components/PopUpContainer';
import './style.css';

function SetCourseDurationPopUpView(props) {

    return (
        <PopUpContainer
            title={"Change Ticket Status"}
            isButtonsEnabled={true}
            style={{ width: '368px', height: '171px' }}
            handleSearch={props.handleSearch}
            handleCancel={props.handleCancel}
            handleSave={props.handleSave}
        >
            <div className='d-flex justify-content-around flex-grow-1 flex-column'>
                <span className='error'>{props.error}</span>
                <div className='d-flex gap-2'>
                    <input type='radio' name="ticketStatus" id="newIssue"
                        checked={props.ticketStatus && props.ticketStatus === "new"}
                        onChange={(event) => props.handleTicketStatus("new")} />
                    <label className="form-check-label" for="newIssue">
                        New Issue
                    </label>
                </div>
                <div className='d-flex gap-2'>
                    <input type='radio' name="ticketStatus" id="underProgress"
                        checked={props.ticketStatus && props.ticketStatus === "underProgress"}
                        onChange={(event) => props.handleTicketStatus("underProgress")} />
                    <label className="form-check-label" for="underProgress">
                        Under progress
                    </label>
                </div>
                <div className='d-flex gap-2'>
                    <input type='radio' name="ticketStatus" id="solved"
                        checked={props.ticketStatus && props.ticketStatus === "solved"}
                        onChange={(event) => props.handleTicketStatus("solved")} />
                    <label className="form-check-label" for="solved">
                        Solved
                    </label>
                </div>
            </div>
        </PopUpContainer>
    )
}

export default SetCourseDurationPopUpView;
