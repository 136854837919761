import React from 'react';
import './verification.css';
import { Tabs, Tab } from 'react-bootstrap';

import IdProofTab from '@pages/VerificationPages/IdProof';
import DoctorProofTab from '@pages/VerificationPages/DoctorProof';
import PhotoProofTab from '@pages/VerificationPages/PhotoProof';
import BssProofTab from '@pages/VerificationPages/BssProof';

function VerificationView(props) {
	return (
		<>
			<div className='contentPageMainContainer' ref={props.fullscreenElementRef}>
				<h4 style={{ marginBottom: '20px', marginLeft: '20px' }}> Verification </h4>

				<Tabs
					defaultActiveKey='idProof'
					id='myTabs'
					onSelect={props.handleTabSelect}>
					<Tab eventKey='idProof' title='ID Proof'>
						{props.tabSelected===1 && (
							<>
								<IdProofTab allUserVerificationData={props.allUserVerificationData} 
								toggleFullscreen={props.toggleFullscreen}/>
							</>
						)}
					</Tab>

					<Tab eventKey='doctorProof' title='Doctor Proof'>
						{props.tabSelected===2 && (
							<DoctorProofTab
								allUserVerificationData={props.allUserVerificationData }
								toggleFullscreen={props.toggleFullscreen}
							/>
						)}
					</Tab>

					<Tab eventKey='photoProof' title='Passport Size Photo'>
						{props.tabSelected===3 && (
							<PhotoProofTab allUserVerificationData={props.allUserVerificationData} 
							toggleFullscreen={props.toggleFullscreen}/>
						)}
					</Tab>
					<Tab eventKey='bssProof' title='BSS Form'>
						{props.tabSelected===4 && (
							<BssProofTab allUserVerificationData={props.allUserVerificationData} 
							toggleFullscreen={props.toggleFullscreen}/>
						)}
					</Tab>
				</Tabs>
			</div>
		</>
	);
}

export default VerificationView;
