import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';
import EditPencilIcon from '@assets/common/editPencilIcon.png';

import AnalyticsIcon from '@assets/common/analyticsIcon.png';


//Object to handle naviagtion
export const navigationData = [
    {
        "title" : "Back to Mails",
        "route" : "/dynamic?tab=mails",
        "default" : false,        
        "icon" : GoBackArrow
    },
    {
        "title" : "Mails",
        "component" : "Mail Details",
        "default" : true,        
        "icon" : EditPencilIcon
    }
]
