import React from "react";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import "./style.css";

import Details from "../Details";
import Card from "../Card";
import Cover from "../Cover";
import ProductPage from "../ProductPage";
import BssFrom from "../BssForm";

//Imports for RichEditor
import "react-quill/dist/quill.snow.css";

import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { useEffect } from "react";
import SaveBtn from "@components/SaveBtn";

function CourseDetailsView(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get("tab");

  const defaultTab = tab ? tab : "details";

  useEffect(() => {
    props.handleTabSelect(tab);
  }, []);

  //START : Vars for RichEditor
  const toolbarOptions = [
    { header: [1, 2, 3, false] },
    { font: [] },
    // { 'size': ['small', false, 'large', 'huge'] },
    "bold",
    "italic",
    "underline",
    "strike",
    { script: "sub" },
    { script: "super" },
    { list: "bullet" },
  ];

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "list",
    "bullet",
    "indent",
  ];
  //END : Vars for RichEditor

  // return <div />;
  return (
    <>
      <div
        className="rightHandRightPageMainContainer d-flex flex-grow-1 gap-3"
        style={{ maxWidth: "none" }}
      >
        <div className="d-flex flex-column flex-grow-1">
          <div className="topContainer">
            <h4 className="heading">Course Details</h4>
            <div>
              <Link
                style={{ textDecoration: "none" }}
                className="cancelBtn"
                to="/content?tab=courses"
              >
                Cancel
              </Link>
              <SaveBtn
                onClick={props.handleSave}
                text={"Save"}
                altText={"Saving..."}
                disabled={props.isFormSaving}
              />
            </div>
          </div>
          <Tabs
            defaultActiveKey={defaultTab}
            id="myTabs"
            onSelect={props.handleTabSelect}
            style={{ paddingLeft: "30px" }}
          >
            <Tab eventKey="details" title="Details">
              {props.detailTabSelected && (
                <>
                  <Details
                    courseDetails={props.courseDetails}
                    allExams={props.questionBanks}
                    paymentGateways={props.paymentGateways}
                    allQualifications={props.allQualifications}
                    handleFormDataChange={props.handleFormDataChange}
                  />
                </>
              )}
            </Tab>
            <Tab eventKey="card" title="Card">
              {props.cardTabSelected && (
                <Card
                  courseDetails={props.courseDetails}
                  handleFormDataChange={props.handleFormDataChange}
                />
              )}
            </Tab>
            <Tab eventKey="cover" title="Cover">
              {props.coverTabSelected && (
                <Cover
                  courseDetails={props.courseDetails}
                  handleFormDataChange={props.handleFormDataChange}
                />
              )}
            </Tab>
            <Tab eventKey="productPage" title="ProductPage">
              {props.productPageTabSelected && (
                <ProductPage
                  courseDetails={props.courseDetails}
                  handleFormDataChange={props.handleFormDataChange}
                  allFAQS={props.FAQS}
                />
              )}
            </Tab>
            <Tab eventKey="bssFormPage" title="Bss Form">
              {props.bssFormTabSelected && (
                <BssFrom
                  courseDetails={props.courseDetails}
                  handleFormDataChange={props.handleFormDataChange}
                  allBSSCourses={props.allBSSCourses}
                  allBSSInstitutes={props.allBSSInstitutes}
                />
              )}
            </Tab>
          </Tabs>
          {/* START : Error container */}
          {props.formValuesEmpty && (
            <div className="incompleteFieldsError">
              <span>All input fields are required</span>
            </div>
          )}
          {/* END : Error container */}
        </div>
      </div>
    </>
  );
}

export default CourseDetailsView;
