import React from 'react';

import './style.css';
import BasicTableGrid from '@components/BasicTableGrid';
import ButtonBars from '@components/ButtonBars';
import PopUp from '@components/PopUp';
import Search from '@components/Search';

import ExpandIcon from '@assets/userDashboard/expand.png';
import SettingIcon from '@assets/userDashboard/setting.png';
import ColumnsIcon from '@assets/userDashboard/columns.png';
import ExternalLinkPopUp from '@components/SuperAdmin/ExternalLink/ExternalLinkPopUp';

function ExternaPaymentsView(props){

    return(
        <div className='AdminPageMainContainer bg-white' ref={props.fullscreenElementRef}>
            <Search
				value={props.searchText}
				placeholder = {"Search External Links"}
				onSearch={(event)=>{props.handleSearch(event)}}
				/>
            <div className='d-flex align-items-center w-100 justify-content-between'>
					<ButtonBars
						buttons={[{type: 'round',
                                    text: '+',
                                    onClick: (event) => {
                                        props.handlePopUpOpen(event, "createNew");
                                    },
                                },
						]}
					/>
					<div className='d-flex gap-2 controlsRight me-3'>
						<button onClick={props.toggleFullscreen}>
							<img src={ExpandIcon}></img>
							<br></br>
							Expand
						</button>
						{/* <button onClick={(event)=>props.handlePopUpOpen(event, "setting")}>
							<img src={SettingIcon}></img>
							<br></br>
							Setting
						</button> */}
						<button onClick={props.handleColumnBtnClick}>
							<img src={ColumnsIcon}></img>
							<br></br>
							Columns
						</button>
					</div>
            </div>

            <BasicTableGrid 
				gridId='ExternaPayments'
				data={props.deferredData} // Data to be displayed
				fixedCols={2} // How many columns are fixed and not scrolling
				initialSize={[35,192,710]}
				initialSorting={[{"id": "Date/Time","desc": true}]}
				handleSelect={(selectedRows) => {props.handleCheckBoxes(selectedRows);}}
				length={20}
				// pagination={props.pagination}
				// setPagination={props.setPagination}
				onSortChange={(data) => {}
                    // props.handleSort(data)
				}
				columnsOptionPopUpRef={props.columnsOptionPopUpRef}
				columns={[
					{header: 'Name', cell : (data)=>{return data.row.original.firstName ? 
							data.row.original.firstName
							: data.row.original.fullName ? data.row.original.fullName
							: data.row.original.username ? data.row.original.username :
							data.row.originalcontactNumber

					}},
					{header: 'Spreadsheet link', cell : (data)=>{
						return <div style={{width:'100%', height:'100%', cursor:'pointer'}}
						onClick={(e)=>{props.handlePopUpOpen(e, "editSheet", data.row.original)}}
						>{data.row.original.externalLink}</div>}},
				]}
				/>

			<PopUp
				visible={props.isPopUpVisible && props.popUp==='editSheet'}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left}
				handleOutSideClick={props.handlePopUpClose}>
				{/* <NewInterestPopUp */}
				<ExternalLinkPopUp
				  link = {props.selectedRow? props.selectedRow.externalLink : ''}
				  handleCancel={props.handlePopUpClose}
				  handleSave={(newLink)=>{props.handlePopUpSave(newLink)}}
				/>
			</PopUp>
        </div>
    )
}

export default ExternaPaymentsView