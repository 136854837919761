import React, { forwardRef } from 'react';

import './style.css';
import TextInputV2 from '@components/TextInputV2';


const TestStatusPopUpView = forwardRef((props, ref) =>{
	
	const {newTestStatus, inputPercentage, showResultCheckBox, disableSaveBtn} = props
	const {handleTestStatusRadioBtns, handleInputPercentage, handleShowResultCheckBox,
		handleCancelBtn, handleSaveBtn, selectedTest, handleSelectedTest, allTests	 } = props

	return (
		<div>
			<div className='visibilityPopupContainer_inner d-flex flex-column gap-1 newFont'>
				<span className='popUpHeading'>Change Test Status</span>
				<select className="seriesSelect" onChange={(event) => {
						handleSelectedTest(event.target.value);
					}}
					value={selectedTest}
					>
						<option value={''}>Select test</option>
					{allTests.map((test, index)=>{
								return <option key={index} value={test._id}> {test.title}</option>
					})}
					
				</select>
				<hr className='my-2'/>
				<span className='popUpHeading'>Set Test Status to</span>

				<div className='form-check mt-2 d-flex gap-2 align-items-center'>
					<input
						onChange={(event) => {
							handleTestStatusRadioBtns(event, 'passed');
						}}
						checked={newTestStatus === 'passed' ? true : false}
						className='form-check-input'
						type='radio'
						name='testStatusRadioBtn'
						id='passedRadioBtn'
					/>

					<label className='form-check-label' htmlFor='passedRadioBtn'>
						Passed
					</label>

					<div className='d-flex align-items-center ms-auto gap-2'>
						Set % to <TextInputV2
							placeholder=''
							disabled={newTestStatus!=='passed'}
							value={newTestStatus==='passed'?inputPercentage:''}
							onChange={(event)=>handleInputPercentage(event.target.value)}
							size={'small'}
						/>
					</div>
				</div>

				<div className='form-check d-flex align-items-center gap-2'>
					<input
						onChange={(event) => {
							handleTestStatusRadioBtns(event, 'failed');
						}}
						checked={newTestStatus === 'failed' ? true : false}
						className='form-check-input'
						type='radio'
						name='testStatusRadioBtn'
						id='failedRadioBtn'
					/>
					<label className='form-check-label' htmlFor='failedRadioBtn'>
						Failed
					</label>
					<div className='d-flex align-items-center ms-auto gap-2'>
						Set % to <TextInputV2
							placeholder=''
							disabled={newTestStatus!=='failed'}
							value={newTestStatus==='failed'?inputPercentage:''}
							onChange={(event)=>handleInputPercentage(event.target.value)}
							size={'small'}
						/>
					</div>
				</div>

				<div className='form-check'>
					<input
						onChange={(event) => {
							handleTestStatusRadioBtns(event, 'unlock');
						}}
						checked={newTestStatus === 'unlock' ? true : false}
						className='form-check-input'
						type='radio'
						name='testStatusRadioBtn'
						id='unlockTestRadioBtn'
					/>
					<label className='form-check-label' htmlFor='unlockTestRadioBtn'>
						Unlock Test
					</label>
				</div>

				<div className='form-check'>
					<input
						onChange={(event) => {
							handleTestStatusRadioBtns(event, 'No Test');
						}}
						checked={newTestStatus === 'No Test' ? true : false}
						className='form-check-input'
						type='radio'
						name='testStatusRadioBtn'
						id='noTestRadioBtn'
					/>
					<label className='form-check-label' htmlFor='noTestRadioBtn'>
						Lock Test
					</label>
				</div>
			<hr/>
			<div style={{ float: 'right', marginTop: '10px', marginLeft: 'auto' }}>
				<button
					onClick={handleCancelBtn}
					className='cancleBtn'>
					Cancel
				</button>

				<button
					disabled={disableSaveBtn}
					style={{
						cursor: disableSaveBtn ? 'not-allowed' : 'pointer',
					}}
					id='saveVisibilityBtn'
					onClick={handleSaveBtn}
					className='saveBtn'>
					Save
				</button>
			</div>
			</div>

		</div>
	);
})

export default TestStatusPopUpView;
