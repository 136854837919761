import React from "react";
import { Link } from "react-router-dom";

import "./innerSidebarNavigation.css";

import HeaderLogo from "@assets/sidebarNavigation/headerLogo.png";
import Search from "@components/Search";

function SidebarNavigationView(props) {
  return (
    <>
      <nav id="topNavBar" className="" style={{display:'flex'}}>
        <img src={HeaderLogo} alt="HeaderLogo" style={{ width: "44px" }} />
        <div style={{marginLeft:'20px', marginRight:'auto', fontWeight:'600'}}>eMediskill Admin Dashboard</div>
				
        {/* <Search
					value={""}
					placeholder = {"Search "}
				>
				<div>No Filters Added</div></Search> */}
      </nav>

      <div id="innerSideNavbar">
        <ul className="navbar-nav">
          {props.navigationData &&
            props.navigationData.map((value, key) => (
              <li key={key}>
                {value.route && (
                  <Link to={value.route}>
                    <img src={value.icon} alt={value.title} />
                    <span>{value.title}</span>
                  </Link>
                )}

                {value.component && (
                  <button
                    onClick={(event) => {
                      props.changeComponent(event, value.component);
                    }}
                    className="componentBtn"
                  >
                    <img src={value.icon} alt={value.title} />
                    {value.title}
                  </button>
                )}
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}

export default SidebarNavigationView;
