
export var data = {
    "userDetails": {
        "username": "rocksVishu",
        "displayName": "Vishal Gaddam",
        "email": "vishalgaddam008@gmail.com",
        "countryCode": "+91",
        "contactNumber": "7710997181", // required

        "notes": "He is one of our user",
        "dob": "2024-03-15T08:10:52.169Z",
        "displayPhoto": "https://displayphoto.png",
        "whatsAppCountryCode": "+91",
        "whatsAppNumber": "9323046867",
        "isEmailVerified": true,
        "isContactNumberVerified": true,
        "isPersonalInfoCompleted": true,
        "firstName": "Vishal",
        "middleName": "Ganesh",
        "lastName": "Gaddam",
        "isLegalInfoCompleted": true,
        "flatHouseNumber": "C2/403",
        "buildingName": "HDIl Residency Park Phase 1",
        "colonyName": "Near yazoo park",
        "areaName": "Narangi bypass Road",
        "landMark": "Opposite to Star Bazar",
        "country": "India",
        "state": "Maharastra",
        "city": "Virar",
        "pincode": 401303,
        "addressMode": "edit",
        "isAddressInfoCompleted": true,
        "isUnderGraduate": true,
        "underGraduateDegree": "BHMS",
        "qualification": "BHMS",
        "marksheetOrDegreeCert": "http://marksheet.png",
        "fullName": "Vishal Ganesh Gaddam",
        "addharOrIdProof": "http://idproof.png",
        "passportPhoto": "http://passportPhoto.png",
        "identityAcknowledged": true,
        "isOTPRequired": true,
    },
    "bssDetails": {
        "guardianName": "Ganesh Gaddam",
        "bloodGroup": "B+",
        "underGraduateDegree": "MBBS",
        "underGraduateInstituteName": "Mahatam Gandhi Institute of Science and Technology",
        "underGraduateInstituteAddress": "C5/4004, Savarkar Road, Near New Bombay Hotel, Fort - 400001",
        "registrationNumber": "A234MD40",
        "passingYear": "2019",
        "aadhaarCard": "http://aadhaarCard.png",  // 
        "panCard": "https://pancard.png",  //
        "otherDocs": "https://oyherDocs.png", 
        "signature": "http://signature.png", //
        "placeOfSignature": "Mumbai",
        "dateOfSignature": "2024-03-15T08:10:52.169Z",
        "bssAcknowledged": true,



        "guardianNameStatus": "unVerified", 
        "underGraduateDegreeStatus": "unVerified",
        "underGraduateInstituteNameStatus": "unVerified",
        "underGraduateInstituteAddressStatus": "unVerified",
        "registrationNumberStatus": "unVerified",
        "passingYearStatus": "unVerified",
        "signatureVerificationStatus": "unVerified",
        "aadhaarVerificationStatus": "unVerified",
        "panVerificationStatus": "unVerified",
        "otherDocsVerificationStatus": "unVerified",
        "placeOfSignatureStatus": "unVerified",
        "bssVerificationStatus": "unVerified",
        "isBssInfoCompleted": true
    }
}

export const defaultBssDetails =[{
    "guardianName": "",
    "bloodGroup": "",
    "underGraduateDegree": "",
    "underGraduateInstituteName": "",
    "underGraduateInstituteAddress": "",
    "registrationNumber": "",
    "passingYear": "",
    "aadhaarCard": "",  // 
    "panCard": "",  //
    "otherDocs": "", 
    "signature": "", //
    "placeOfSignature": "",
    "dateOfSignature": "",
    "bssAcknowledged": false,



    "guardianNameStatus": "unVerified",
    "underGraduateDegreeStatus": "unVerified",
    "underGraduateInstituteNameStatus": "unVerified",
    "underGraduateInstituteAddressStatus": "unVerified",
    "registrationNumberStatus": "unVerified",
    "passingYearStatus": "unVerified",
    "signatureVerificationStatus": "unVerified",
    "aadhaarVerificationStatus": "unVerified",
    "panVerificationStatus": "unVerified",
    "otherDocsVerificationStatus": "unVerified",
    "placeOfSignatureStatus": "unVerified",
    "bssVerificationStatus": "unVerified",
    "isBssInfoCompleted": false
}]

export const verificationStatus=[
    {name: 'Select status', value: ''},
    {name: 'In Progress', value: 'inProgress'},
    {name: 'Verified', value: 'Verified'},
    {name: 'Rejected', value: 'Rejected'},
]

export const underGraduateDegree=[
    {name: '', value: ''},
    {name: 'MBBS', value: 'MBBS'},
    {name: 'MD', value: 'MD'},
]
export const qualification=[
    {name: 'Select qualification', value: ''},
    {name: 'Graduation', value: 'Graduation'},
    {name: 'Post-Graduation', value: 'Post-Graduation'},
    {name: 'P.hd', value: 'P.hd'},
]

export const bloodGroup=[
    {name: 'A+', value: 'A+'},
    {name: 'A-', value: 'A-'},
    {name: 'AB+', value: 'AB+'},
    {name: 'AB-', value: 'AB-'},
    {name: 'B+', value: 'B+'},
    {name: 'B-', value: 'B-'},
    {name: 'O+', value: 'O+'},
    {name: 'O-', value: 'O-'},
]