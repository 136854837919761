import React from "react";
import TextAndLinksView from './view';

function TextAndLinks(props){
   
   return(
    <TextAndLinksView
    props = {props}
    />
   )

}

export default TextAndLinks;