import React from "react";

function SeriesHomeView(){
    return(
        <>
            {/* <h1 style={{marginLeft:'240px'}}>SeriesHomeView</h1> */}
        </>
    )
}

export default SeriesHomeView;