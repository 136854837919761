import React, { useEffect, useState, useRef } from 'react';

import ThumbnailPopupView from './view';

function ThumbnailPopup(props) {
	const { onValueChange } = props;
	//------------------------------------------------------
	// START : UseState's to control the functionality of uploading thumbnail & title of course
	const [thumbnailFileObj, setThumbnailFileObj] = useState('');
	const [thumbnailFileName, setThumbnailFileName] = useState('');
	const [displayThumbnailFileName, setDisplayThumbnailFileName] = useState('');
	const [thumbnailError, setThumbnailError] = useState(false);

	const thubmnailPopupContainerRef = useRef(null);
	const [
		is_ThubmnailPopupContainerRef_Visible,
		set_Is_ThubmnailPopupContainerRef_Visible,
	] = useState(false);
	const [
		thubmnailPopupContainerRef_Position,
		set_ThubmnailPopupContainerRef_Position,
	] = useState({ top: 0, left: 0 });
	// END : UseState's to control the functionality of uploading thumbnail & title of course
	//------------------------------------------------------

	useEffect(() => {
		document.addEventListener('mousedown', handleOutsideClick);

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, []);

	useEffect(() => {
		if (props.thumbnailUrl) {
			setDisplayThumbnailFileName(props.thumbnailUrl);
		}
	}, [props.thumbnailUrl]);

	const resetThumbnailValues = () => {
		set_Is_ThubmnailPopupContainerRef_Visible(false);
		setThumbnailError(false);
		// setThumbnailFileName('');
		// setThumbnailFileObj('');
	};

	//Fn to handle hiding of popup's when clicked outside the respective container
	const handleOutsideClick = (event) => {
		if (
			thubmnailPopupContainerRef.current &&
			!thubmnailPopupContainerRef.current.contains(event.target)
		) {
			resetThumbnailValues();
		}
	};

	// ###############################
	//------------------------------------------------------
	//START : Logic for the functionality updating thumbnail & title for course

	//Fn to open thumbnail update popup
	const handleDoubleClick = (event) => {
		let currentEle = event.target;
		set_Is_ThubmnailPopupContainerRef_Visible(true);

		let left = currentEle.getBoundingClientRect().left;
		let top = window.scrollY + currentEle.getBoundingClientRect().bottom;

		set_ThubmnailPopupContainerRef_Position({ left: left, top: top });
	};

	//Fn to close the visibility popup container
	const handleThumbnailCancleBtnClick = (event) => {
		resetThumbnailValues();
	};

	//Fn to update db thumbnail & title for course
	const handleSaveThumbnail = (event) => {
		if (thumbnailFileObj !== '') {
			setThumbnailFileName(thumbnailFileObj.name);
			setDisplayThumbnailFileName(thumbnailFileObj.name);

			setThumbnailError(false);

			set_Is_ThubmnailPopupContainerRef_Visible(false);

			let sendData = {
				thumbnail: thumbnailFileObj,
			};
			onValueChange(sendData);
		} else {
			setThumbnailError(true);
		}
	};
	//END : Logic for the functionality updating thumbnail & title for course
	//------------------------------------------------------
	// ###############################

	const handleFileUpload = (event) => {
		let fileInptId = event.target.id.split('_')[0];
		document.getElementById(fileInptId).click();
		document
			.getElementById(fileInptId)
			.addEventListener('change', function (event) {
				let fileName = this.value.replace(/C:\\fakepath\\/i, '');
				setThumbnailFileName(fileName);
			});
	};

	const convertToBase64 = (event) => {
		setThumbnailFileObj(event.target.files[0]);
	};

	return (
		<>
			<ThumbnailPopupView
				//START : UseStates & fn to control the functionality of upating thumbnail of video
				handleDoubleClick={handleDoubleClick}
				thumbnailFileName={thumbnailFileName}
				handleThumbnailCancleBtnClick={handleThumbnailCancleBtnClick}
				thubmnailPopupContainerRef={thubmnailPopupContainerRef}
				is_ThubmnailPopupContainerRef_Visible={
					is_ThubmnailPopupContainerRef_Visible
				}
				thubmnailPopupContainerRef_Position={thubmnailPopupContainerRef_Position}
				thumbnailError={thumbnailError}
				handleSaveThumbnail={handleSaveThumbnail}
				handleFileUpload={handleFileUpload}
				convertToBase64={convertToBase64}
				displayThumbnailFileName={displayThumbnailFileName}
				thumbnailFileObj={thumbnailFileObj}
				//END : UseStates & fn to control the functionality of upating thumbnail of video
			/>
		</>
	);
}

export default ThumbnailPopup;
