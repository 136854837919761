import React, {useState} from 'react';

import './style.css'
import SearchIcon from '@assets/common/search.png';
import ClearIcon from '@assets/common/blackCrossIcon.png';
function SearchInput(props)
{
   const [value, setValue] =useState('')

   const handleInput=(event)=>{
     setValue(event.target.value)
     props.onChange(event)
   }

   const handleClear=(event)=>{
    setValue('')
    event.target.value=''
    props.onChange(event)
   }

    return(
        <div className="searchInputDiv">
           <input type="text" 
                  value={value} 
                  placeholder={props.placeholder}
                  onChange = {handleInput}
                  ></input>

           {value ?  
           <img src={ClearIcon} onClick={handleClear} value=''/>:
           <img src={SearchIcon} /> }
           
        </div>
    )
}
export default SearchInput