import React, { useEffect, useState} from 'react';
import ContentView from './view';
import SidebarNavigation from '@components/SidebarNavigation';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as TabsActions from '@redux/actions/Tabs';

import { checkLoginAndRole } from '@helpers/Login/';
import { useNavigate } from 'react-router-dom';

function Content(props) {
	const navigate=useNavigate()
	const [selectedTab, setSelectedTab] = useState(props.tab)

	useEffect(() => {
		checkLoginAndRole('content')
	}, []);

	useEffect(()=>{
		setSelectedTab(props.tab)
	},[props.tab])


	const handleTabSelect = (eventKey) => {
        props.updateContentTab(eventKey)
		navigate('.', { replace: true });
	};


	return (
		<>
			<SidebarNavigation />
			<ContentView
				selectedTab={selectedTab}
				handleTabSelect={handleTabSelect}
			/>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		tab: state.tabs.ContentTab,
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateContentTab: TabsActions.updateContentTab,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Content);
