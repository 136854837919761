import React, { useEffect, useState } from 'react';
import PopUpContainer from '@components/PopUpContainer';
import './style.css';

function SetCourseDurationPopUpView(props) {

	return (
		<PopUpContainer
           title={"Set course duration to"}
           isSearchEnabled={false}
           searchPlaceholder={"Search course name"}
           isButtonsEnabled={true}
           style={{width:'201px', height:'256px'}}
           handleSearch={props.handleSearch}
           handleCancel={props.handleCancel}
           handleSave={props.handleSave}
        >
           <div className='d-flex justify-content-around flex-grow-1 flex-column'>
            <div className='d-flex gap-2'>
                    <input type='radio' name="course" 
                        checked={!props.isDurationInYear}
                        onChange={(event)=>props.handleCheckbox(false, "0")}/>
                    <input style={{border: '1px solid #cfd8dc', borderRadius: '5px', width: '60px'}}
                       type="text" value={!props.isDurationInYear? props.courseDuration: ''}
                       onChange={(event)=>{props.handleCheckbox(false, event.target.value)}}
                    />
                        Months
                </div>
            <div className='d-flex gap-2'>
                    <input type='radio' name="course" 
                        checked={props.isDurationInYear && props.courseDuration === "One Year" }
                        onChange={(event)=>props.handleCheckbox(true, "One Year")}/>
                    One Year
            </div>
            <div className='d-flex gap-2'>
                    <input type='radio' name="course" 
                        checked={props.isDurationInYear && props.courseDuration === "Two Year - I Year"}
                        onChange={(event)=>props.handleCheckbox(true, "Two Year - I Year")}/>
                    Two Year - I Year
            </div>
            <div className='d-flex gap-2'>
                    <input type='radio' name="course" 
                        checked={props.isDurationInYear && props.courseDuration === "Two Year - II Year" }
                        onChange={(event)=>props.handleCheckbox(true, "Two Year - II Year")}/>
                    Two Year - II Year
            </div>
            <div className='d-flex gap-2'>
                    <input type='radio' name="course" 
                        checked={props.isDurationInYear && props.courseDuration === "Direct II Year" }
                        onChange={(event)=>props.handleCheckbox(true, "Direct II Year")}/>
                    Direct II Year
            </div>
           </div>
        </PopUpContainer>
	)
}

export default SetCourseDurationPopUpView;
