// Import the AWS SDK
const AWS = require('aws-sdk');

export const getImagesFromAwsBucket = async (folderName = null, searchValue = '') => {
    try {
        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            region: process.env.REACT_APP_AWS_REGION
        });

        const s3 = new AWS.S3();
        let params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME
        };
        if (folderName !== null) {
            params['Prefix'] = folderName + '/'
        }

        const data = await s3.listObjects(params).promise();
        let listOfImages = [];
        if (searchValue !== '') {
            const matchingObjects = data.Contents.filter(obj => obj.Key.includes(searchValue));
            listOfImages = matchingObjects.map(obj => obj.Key);
        }
        else {
            listOfImages = data.Contents.map(obj => obj.Key);
        }
        return {
            success: true,
            listOfImages: listOfImages
        };
    } catch (error) {
        console.error('Error fetching images from AWS S3:', error);
        return {
            success: false,
            message: error
        }
    }
};
