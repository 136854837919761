import React from 'react';

import './style.css';
import VideoPlayHolder from '@assets/common/videoPlayHolder.png';
import BlackCrossIcon from '@assets/common/blackCrossIcon.png';

const countries = require('@assets/countryCode.json');

function EditNumberPopUpView(props) {
	return (
		<div className="addNumberMain">
			<span>
				{props.numberType === 'phone'? "Edit phone number" :"Edit whatsapp number"}
				</span>
			<div className="d-flex gap-1 numberSelector">
				<select
					onChange={(event) => {
						props.handleCountryCodeChange(event.target.value);
					}}
					className='form-select'>
					<option value=''>{props.countryCode}</option>
					{countries.map((country, index) => (
							<option  value={country.countryCode} key={index}>
									{country.countryName +" "+ country.countryCode}
								</option>
						))}
				</select>
				
				<input type="number" value={props.number} 
				 onChange={(event)=>{props.handleNumberChange(event.target.value)}}></input>
			</div>
			

			{props.error && props.error !== '' && (
				<div className='alert alert-danger w-50'>
					{props.error}
				</div>
			)}

			<div className="hr"/>

            <div className="d-flex justify-content-end gap-1">
				<button onClick={props.handleCloseBtn} className='closeBtn'>
				Cancel
				</button>
				<button onClick={props.handleSaveBtn} className='saveBtn'>
					Save
				</button>
            </div>



		</div>
	);
}

export default EditNumberPopUpView;
