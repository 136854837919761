
import SearchIcon from '@assets/common/search.png';
import CalenderIcon from '@assets/common/calender.png';
import CloseIcon from '@assets/common/close.png';

const icons = {
    'search': SearchIcon,
    'calender': CalenderIcon,
    '':''
}


function ActiveSearchFilters(props){
    return <div className="d-flex gap-2">
           {props.filters.map((filter, index)=>{
              if(filter.value)
              {
                return <div className="d-flex gap-2 rounded-pill px-2 align-items-center " 
                            style={{backgroundColor:"#f5f5f5"}} key={index}
                            >
                          <img src={icons[filter.icon]} style={{height:"12px"}}/>
                          {filter.value}
                          <img src={CloseIcon} style={{height:"12px", cursor:'pointer'}}
                          onClick={()=>props.handleClick(index)}
                          />
                       </div>}
                       else{return<div key={index}></div>}
           })}
    </div>
}


export default ActiveSearchFilters