import React, { useState, useEffect, useRef } from "react";

import { defer, useLocation } from "react-router-dom";
import AllCommentsView from "./view";

import { handleCommentReply } from "@helpers/ReplyInputbox";
import {
  handleDateSorted_fn,
  handleRangeOfDate_fn,
  convertData,
  generateDate,
} from "./fns.js";
import { decryptText } from "@library/enc-dec";
import SidebarNavigation from "@components/SidebarNavigation";
import * as CommentsService from "@services/Comments";
import { produce } from "immer";
import { checkLoginAndRole } from "@helpers/Login/";

import {
  updateCommentLikeCount,
  updateCommentDislikeCount,
  updateCommentStatusChange,
  updateReplyLikeCount,
  updateReplyDislikeCount,
  updateReplyStatusChange,
  updateCommentReply,
} from "@helpers/comments";

import { navigationData } from "./data";
import { useNavigate } from "react-router-dom";
import ClipboardJS from "clipboard";

var user = null;

function AllComments(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [apiCommentData, setApiCommentData] = useState({});
  const [deferedData, setDeferedData] = useState({});

  const limit = queryParams.get("limit");
  const page = queryParams.get("page");

  //START : UseState for published, holdForReview, rejected
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [selectedSort, setSelectedSort] = useState({
    type: "",
    timePeriod: "",
    fromData: "",
    toData: "",
  });
  const [commentsData, setCommentData] = useState({});
  const [alertVisible, setAlertVisible] = useState(false);
  //END : END for published, holdForReview, rejected

  //START : Filter by date

  const allCommentsSortContainerRef = useRef(null);
  const [
    allCommentsSortContainerVisiable,
    setAllCommentsSortContainerVisiable,
  ] = useState(false); // In init the container is not visiable

  const [sortErrorMessage, setSortErrorMessage] = useState("");

  const currentDate = new Date().toISOString().slice(0, 10);
  const [fromInputDate, setFromInputDate] = useState("");
  const [toInputDate, setToInputDate] = useState(currentDate);

  const [reload, setReload] = useState(false);

  useEffect(() => {
    checkLoginAndRole("allComments");
    // Getting Admin details
    getUserDetails();
    getComments();

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (limit > 0) {
      getComments();
    }
  }, [page, limit, selectedFilter, reload]);

  const getUserDetails = async () => {
    try {
      // Getting admin details
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await CommentsService.getUserDetails(authToken);
      if (response.success) {
        user = response.data;
      }
    } catch (err) {
      console.log("Error coming from handling getUserDetails()", err);
    }
  };

  const getComments = async () => {
    try {
      // Getting all the comments
      let authToken = decryptText(localStorage.getItem("aEmediat"));

      const payload = {
        page: page,
        limit: limit>0? limit : 10,
        commentStatus: selectedFilter,
      };

      const response = await CommentsService.getAllComments(payload, authToken);

      var deferedResponse = defer({
        res: CommentsService.getAllComments(payload, authToken),
      });
      setDeferedData(deferedResponse);

      if (response.success) {
        setApiCommentData(response);
        setCommentData(response);
      }
    } catch (err) {
      console.log("Error coming while getting comments", err);
    }
  };

  // Filter : published, heldForReview, rejected
  const filterComments = (filterOption, comments) => {
    if (comments && comments.data && comments.data.length > 0) {
      if (filterOption !== "none") {
        let filteredComments = produce(comments, (draft) => {
          draft.data = draft.data.filter(
            (comment) => comment.status === filterOption
          );
        });

        if (filteredComments.data.length > 0) {
          return {
            success: true,
            data: filteredComments,
          };
        } else {
          return {
            success: false,
            message: "after_filter_no_data_found",
          };
        }
      } else {
        return {
          success: true,
          data: comments,
        };
      }
    } else {
      closeAllOpenUIContainers();
      return {
        success: false,
        message: "no_data_to_filter",
      };
    }
  };

  const closeAllOpenUIContainers = () => {
    let userCommentContainer_ele = document.getElementsByClassName(
      "userCommentContainer"
    );
    for (let i = 0; i < userCommentContainer_ele.length; i++) {
      userCommentContainer_ele[i].style.display = "none";
    }
    let accordions = document.getElementsByClassName("accordion");
    for (let i = 0; i < accordions.length; i++) {
      let collapseElement = accordions[i].querySelector(".collapse");
      if (collapseElement.classList.contains("show")) {
        collapseElement.classList.remove("show");
      }
    }
  };

  const handleFromInputDate = (event) => {
    setFromInputDate(event.target.value);
    let lCommentData = {};
    if (commentsData.data && commentsData.data.length > 0) {
      lCommentData = commentsData;
    } else {
      lCommentData = apiCommentData;
    }
    runSortedFn(event.target.value, toInputDate, lCommentData);
  };

  const handleToInputDate = (event) => {
    setToInputDate(event.target.value);
    let lCommentData = {};
    if (commentsData.data && commentsData.data.length > 0) {
      lCommentData = commentsData;
    } else {
      lCommentData = apiCommentData;
    }
    runSortedFn(fromInputDate, event.target.value, lCommentData);
  };

  const runSortedFn = (fId, tId, cd) => {
    setSortErrorMessage("");

    setSelectedSort({
      type: "dateRange",
      timePeriod: "",
      fromData: fId,
      toData: tId,
    });

    let lCommentData = {};
    let checkFilterStatus = checkFilterStatus_fn();
    if (checkFilterStatus.success) {
      lCommentData = checkFilterStatus.data;
    } else {
      lCommentData = apiCommentData;
    }

    let handleDateSorted = handleDateSorted_fn(fId, tId, lCommentData);

    if (handleDateSorted.success) {
      setCommentData(handleDateSorted.sortedData);
    } else {
      setCommentData(lCommentData);
      let e = handleDateSorted.message;
      if (selectedFilter !== "none") {
        e = e + ", showing data for all : " + selectedFilter + " comments";
      }
      setSortErrorMessage(e);
    }
  };

  const handleSortingByDate = (event, timePeriod) => {
    setSortErrorMessage("");
    setDateToInputFileds(timePeriod);
    setSelectedSort({
      type: "timePeriod",
      timePeriod: timePeriod,
      fromData: "",
      toData: "",
    });
    setAllCommentsSortContainerVisiable(false);

    let lCommentData = {};
    let checkFilterStatus = checkFilterStatus_fn();
    if (checkFilterStatus.success) {
      lCommentData = checkFilterStatus.data;
    } else {
      lCommentData = apiCommentData;
    }

    let handleRangeOfDate = handleRangeOfDate_fn(timePeriod, lCommentData);
    if (handleRangeOfDate.success) {
      setCommentData(handleRangeOfDate.sortedData);
    } else {
      let e = handleRangeOfDate.message;
      if (selectedFilter !== "none") {
        e = e + ", showing data for all : " + selectedFilter + " comments";
      }
      setSortErrorMessage(e);
      setCommentData(lCommentData);
    }
  };

  const handleSortContainerVisibility = (event) => {
    setAllCommentsSortContainerVisiable(true);
  };

  const setDateToInputFileds = (timePeriod) => {
    let gDate = generateDate(timePeriod);
    setFromInputDate(convertData(gDate.fromDate));
    setToInputDate(convertData(gDate.toDate));
  };

  // fn to check filter status
  const checkFilterStatus_fn = () => {
    if (selectedFilter !== "none") {
      let filterComments_var = filterComments(selectedFilter, apiCommentData);
      if (filterComments_var.success) {
        return {
          success: true,
          data: filterComments_var.data,
        };
      } else {
        return {
          success: false,
          message: filterComments_var.message,
        };
      }
    } else {
      return {
        success: false,
        message: "no_filter",
      };
    }
  };
  //END : Filter by date

  //Fn to handle hiding of popup's when clicked outside the respective container
  const handleOutsideClick = (event) => {
    if (
      allCommentsSortContainerRef.current &&
      !allCommentsSortContainerRef.current.contains(event.target)
    ) {
      setAllCommentsSortContainerVisiable(false);
    }
  };

  const handleFilter = (event, filter) => {
    setSortErrorMessage("");
    setSelectedFilter(filter);
  };

  // Fn to check if any sort is there
  const checkSortStatus_fn = () => {
    if (selectedSort && selectedSort.type !== "") {
      if (selectedSort.type === "timePeriod") {
        let handleRangeOfDate = handleRangeOfDate_fn(
          selectedSort.timePeriod,
          apiCommentData
        );
        if (handleRangeOfDate.success) {
          return {
            success: true,
            data: handleRangeOfDate.sortedData,
          };
        } else {
          setSelectedSort({
            type: "",
            timePeriod: "",
            fromData: "",
            toData: "",
          });
          return {
            success: false,
            message: "after_sorting_no_data_found",
          };
        }
      } else if (selectedSort.type === "dateRange") {
        let handleDateSorted = handleDateSorted_fn(
          selectedSort.fromData,
          selectedSort.toData,
          apiCommentData
        );
        if (handleDateSorted.success) {
          return {
            success: true,
            data: handleDateSorted.sortedData,
          };
        } else {
          setSelectedSort({
            type: "",
            timePeriod: "",
            fromData: "",
            toData: "",
          });
          return {
            success: false,
            message: "after_sorting_no_data_found",
          };
        }
      } else {
        return {
          success: false,
          message: "fail safe",
        };
      }
    } else {
      return {
        success: false,
        message: "sorting_is_not_set",
      };
    }
  };

  //Fn to update filters
  const updateFilterComments = (data) => {
    if (data.success) {
      setCommentData(data.data);
    } else {
      setCommentData(apiCommentData);
      resetFiltersAndSort();
      setSortErrorMessage(
        "No comment for selected sort and filter, change date or filter, showing all comments"
      );
    }
  };

  const resetFiltersAndSort = () => {
    setTimeout(function () {
      setSelectedFilter("none");
      setSelectedSort({
        type: "",
        timePeriod: "",
        fromData: "",
        toData: "",
      });
    }, 200);
    setFromInputDate(currentDate);
  };

  // END : fn for filter : published, holdForReview, rejected
  const handleLikeComment = async (userComment) => {
    try {
      let payload = {
        commentId: userComment._id,
        videoDocId: userComment.videoDocId,
      };
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await CommentsService.likeComment(payload, authToken);

      if (response.success) {
        setReload((reload) => !reload);
      }
    } catch (err) {
      console.log("Error coming while handling comment", err);
    }
  };

  const handleDislikeComment = async (userComment) => {
    try {
      let payload = {
        commentId: userComment._id,
        videoDocId: userComment.videoDocId,
      };
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await CommentsService.dislikeComment(payload, authToken);

      if (response.success) {
        setReload((reload) => !reload);
      }
    } catch (err) {
      console.log("Error coming while handling comment", err);
    }
  };

  const handleCommentStatus = async (status, commentId) => {
    try {
      let payload = {
        commentId: commentId,
        status: status,
      };
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await CommentsService.updateCommentStatus(
        payload,
        authToken
      );
      if (response.success) {
        setReload((reload) => !reload);
      }
    } catch (err) {
      console.log("Error coming while handling comments status update", err);
    }
  };

  const handleReplyLike = async (userReply, videoDocId) => {
    try {
      let payload = {
        replyId: userReply._id,
        commentId: userReply.commentId,
        videoDocId: videoDocId,
      };
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await CommentsService.likeReply(payload, authToken);
      if (response.success) {
        setReload((reload) => !reload);
      }
    } catch (err) {
      console.log("Error coming while handling reply like", err);
    }
  };

  const handleReplyDislike = async (userReply, videoDocId) => {
    try {
      let payload = {
        replyId: userReply._id,
        commentId: userReply.commentId,
        videoDocId: videoDocId,
      };
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await CommentsService.dislikeReply(payload, authToken);
      if (response.success) {
        // Status tells that increase dislikes count

        setReload((reload) => !reload);
      }
    } catch (err) {
      console.log("Error coming while handling reply dislike", err);
    }
  };

  const handleReplyStatus = async (status, replyId) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        replyId: replyId,
        status: status,
      };
      const response = await CommentsService.updateReplyStatus(
        payload,
        authToken
      );

      if (response.success) {
        setReload((reload) => !reload);
      }
    } catch (err) {
      console.log("Error coming while handling reply status", err);
    }
  };

  const handleAddingCommentReply = async (reply, docId) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        commentId: docId,
        reply: reply,
      };
      const response = await CommentsService.addReply(payload, authToken);
      if (response.success) {
        setReload((reload) => !reload);
      }
    } catch (err) {
      console.log("Error coming while adding reply", err);
    }
  };

  const handleAddingReplyOfReply = async (reply, docId, commentId) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        replyOnId: docId,
        commentId: commentId,
        reply: reply,
      };
      const response = await CommentsService.addReplyOfReply(
        payload,
        authToken
      );

      if (response.success) {
        setReload((reload) => !reload);
      }
    } catch (err) {
      console.log("Error coming while adding reply", err);
    }
  };

  const handleAddReply = (reply, docId, replyType, commentId) => {
    if (replyType === "replyOnComment") {
      handleAddingCommentReply(reply, docId);
    } else {
      handleAddingReplyOfReply(reply, docId, commentId);
    }
  };

  const setDefaultNavigation = (defaultComponent) => {
    return navigationData.map((item) => {
      if (item.component === defaultComponent) {
        return { ...item, default: true };
      }
      return item;
    });
  };

  const handleRedirectToVideo = (index, videoDocId) => {
    var defaultComponent = "";
    if (index === 0) {
      defaultComponent = "VideoDetails";
    } else if (index === 1) {
      defaultComponent = "VideoComments";
    } else if (index === 2) {
      defaultComponent = "VideoAnalytics";
    } else if (index === 3) {
      var x =
        `${process.env.REACT_APP_E_MEDISKILL_WEB_APP}` +
        "/video/v/" +
        videoDocId;
      window.open(x, "_blank");
      return;
    } else if (index === 4) {
      var x =
        `${process.env.REACT_APP_E_MEDISKILL_WEB_APP}` +
        "/video/v/" +
        videoDocId;
      handleCopyClick(x);
      return;
    }
    //Handle setting default page while navigating
    let finalNavigationData = [];
    finalNavigationData = navigationData;
    if (defaultComponent !== "none") {
      finalNavigationData = setDefaultNavigation(defaultComponent);
    } else {
      // VAR x ONLY FOR TESTING
      // let x = 'VideoComments';
      // finalNavigationData = setDefaultNavigation(x);
      finalNavigationData = setDefaultNavigation("VideoDetails");
    }

    if (videoDocId === "none") {
      navigate("/videos", { state: { finalNavigationData } });
    } else {
      navigate("/videos/" + videoDocId, { state: { finalNavigationData } });
    }
  };

  const handleCopyClick = (textToCopy) => {
    const clipboard = new ClipboardJS(".clipboard", {
      text: () => textToCopy,
    });

    clipboard.on("success", (e) => {
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 1000);
      e.clearSelection();
      clipboard.destroy();
    });

    clipboard.on("error", (e) => {
      console.error("Copy failed:", e.action);
      clipboard.destroy();
    });
  };

  return (
    <>
      <SidebarNavigation />

      <AllCommentsView
        commentsData={commentsData}
        deferedData={deferedData}
        //START : UseState * fn for published, holdForReview, rejected
        selectedFilter={selectedFilter}
        commentAction={handleCommentStatus}
        handleFilter={handleFilter}
        //END : UseState * fn for published, holdForReview, rejected

        //START : UseState & fn for checking if to treat this page as for all comments or comments for a given video
        fromInputDate={fromInputDate}
        handleFromInputDate={handleFromInputDate}
        toInputDate={toInputDate}
        handleToInputDate={handleToInputDate}
        sortErrorMessage={sortErrorMessage}
        allCommentsSortContainerRef={allCommentsSortContainerRef}
        handleSortingByDate={handleSortingByDate}
        allCommentsSortContainerVisiable={allCommentsSortContainerVisiable}
        handleSortContainerVisibility={handleSortContainerVisibility}
        handleLikeComment={handleLikeComment}
        handleDislikeComment={handleDislikeComment}
        // Handling Replies
        handleReplyLike={handleReplyLike}
        handleReplyDislike={handleReplyDislike}
        handleReplyStatus={handleReplyStatus}
        handleCommentReply={(event, commentId, replyType) =>
          handleCommentReply(event, commentId, replyType, user, handleAddReply)
        }
        //END : UseState & fn for checking if to treat this page as for all comments or comments for a given video
        handleRedirectToVideo={handleRedirectToVideo}
      />
    </>
  );
}

export default AllComments;
