const initialState = {
	ContentTab: 'videos',
	SuperAdminTab: {_id: '1',
					title: 'Admin users',
					isActive: true},
	UserTab: {_id: 'All', 
				title: 'All',
				isActive: true},
	CustomerCareTab: {"_id": "new",
						"title": "New Tickets",
						"isActive": true},
    LeadsTab:{"_id": "myLeads",
				"title": "My Leads",
				"isActive": true},
	BSSTab:{_id: 'All', 
				title: 'All',
				isActive: true},
	DynamiPagTab: "home"
};

const TabReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_CONTENT_TAB':
			return {
				...state,
				ContentTab: action.payload.tab,
			};
		case 'UPDATE_SUPER_ADMIN_TAB':
			return {
				...state,
				SuperAdminTab: action.payload.tab,
			};
		case 'UPDATE_USER_TAB':
			return {
				...state,
				UserTab: action.payload.tab,
			};
		case 'UPDATE_CUSTOMER_CARE_TAB':
			return {
				...state,
				CustomerCareTab: action.payload.tab,
			};
		case 'UPDATE_LEADS_TAB':
			return {
				...state,
				LeadsTab: action.payload.tab,
			};
		case 'UPDATE_BSS_TAB':
			return {
				...state,
				BSSTab: action.payload.tab,
			};
		case 'UPDATE_DYNAMIC_TAB':
			return {
				...state,
				DynamiPagTab: action.payload.tab,
			};
		case 'RESET_TAB_REDUCER':
			return initialState;

		default:
			return state;
	}
};

export default TabReducer;
