import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';
import EditPencilIcon from '@assets/common/editPencilIcon.png';
import ProfileIcon from '@assets/common/profile.png';
import AnalyticsIcon from '@assets/common/analyticsIcon.png';
import LeadsIcon from '@assets/sidebarNavigation/leads.png';
import CustomerCareIcon from '@assets/sidebarNavigation/customerCare.png';
import BssIcon from '@assets/sidebarNavigation/bss.png';


//Object to handle naviagtion
export const navigationData = [
    {
        "title" : "Back to Leads",
        "route" : "/leads",
        "default" : false,        
        "icon" : GoBackArrow
    },
    {
        "title" : "Profile",
        "component" : "UserProfile",
        "default" : false,        
        "icon" : ProfileIcon
    },
    {
        "title" : "Activity",
        "component" : "UserActivity",
        "default" : false,        
        "icon" : AnalyticsIcon
    },
    {
        "title" : "Lead Details",
        "component" : "UserLead",
        "default" : true,        
        "icon" : LeadsIcon
    },
    {
        "title" : "Customer care",
        "component" : "UserCustomerCare",
        "default" : false,        
        "icon" : CustomerCareIcon
    },
    {
        "title" : "BSS Forms",
        "component" : "UserBSSForm",
        "default" : false,        
        "icon" : BssIcon
    },
]
