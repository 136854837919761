import React, { forwardRef } from 'react';

import './style.css';

const PopUpView = forwardRef((props, ref) =>{
    
	return (
		< >
		{props.visible &&(
			<div
			ref = {ref}
			id={props.id}
			style={{
				position: 'absolute',
				top: props.top,
				left: props.left,
				padding: 0
			}}
			className='visibilityPopupContainer p-0'>
				{props.visible && props.children}

		</div>
		)}
		</>
	);
})

export default PopUpView;
