import React, { useEffect, useState, useRef  } from 'react';
import PopUpContainer from '@components/PopUpContainer';


import * as CourseServices from '@services/Course';
import { decryptText } from '@library/enc-dec';
import Swal from 'sweetalert2';

function ReplaceCoursePopUp(props) {
	const [formalRequestText, setFormalRequestText]=useState('')
	const [allCourses, setAllCourses] = useState([])
	const [newCourse, setNewCourse] = useState('')

	useEffect(()=>{
		getAllCourses()
	},[])

	const getAllCourses=async()=>{
		try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const payload = {
				pageNo: 0, 
				limit: 99999, 
				sortBy: 'createdAt', 
				isSorted: true, 
				searchText: ''}

			const response = await CourseServices.getAllCourses(payload, authToken);
			if(response.success){
                 setAllCourses(response.data)
				 return
			}
			Swal.fire({icon: "error",title: response.message,showConfirmButton: false,timer: 2500});
			props.handlePopUpClose()
			
		} catch (err) {
			console.log('Error coming while fetching all courses', err);
		}
	}

	const handleSave=()=>{
		if(props.isFormalRequest && formalRequestText.length<100)
		{
		 Swal.fire({icon: "info",title: "Please add request description of minimum 100 characters",showConfirmButton: false,timer: 2500});
		 return   
		}
		 props.handlePopUpSave({newCourse,formalRequestText})
	 }

	return (
		<PopUpContainer
						title={"Replace courses"}
						isSearchEnabled={false}
						searchPlaceholder={""}
						isButtonsEnabled={true}
						style={{width:'431px', height: props.isFormalRequest?'406px':'206px'}}
						handleSearch={()=>{}}
						handleCancel={props.handlePopUpClose}
						handleSave={handleSave}
						>
					<div className='d-flex flex-column gap-2'>
						<div>{props.selectedCount} users selected</div>
						<select className="w-100 form-control form-control-sm" onChange={(event) => {setNewCourse(event.target.value)}}
							value={newCourse} >
								<option value={''}>Replace with</option>
							{allCourses && allCourses.map((course, index)=>{
										return <option key={index} value={course._id}> {course.title}</option>
							})}
							
						</select>
						{props.isFormalRequest &&
						<div className='border-top d-flex flex-column gap-2 py-2'>
							<div className='d-flex'>
								Submit formal request
								<span className='ms-auto text-muted'>Minimum 100 characters*</span>
							</div>
							
							<div className="form-floating" >
								<textarea className="form-control" placeholder="" id="cardDesc" style={{height:'158px'}}   
									value={formalRequestText}
									onChange={(e)=>setFormalRequestText(e.target.value)}
							></textarea>
								<label htmlFor="cardDesc">Request will be sent to administrative team for approval<span>*</span></label>
							</div>
						</div>}
						</div>
				</PopUpContainer>
	);
}

export default ReplaceCoursePopUp;
