import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';
import QuestionMark from '@assets/sidebarNavigation/questionMark.png';
import EditPencilIcon from '@assets/common/editPencilIcon.png';


export const apiExamData = {
    "success": true,
    "message": "All question banks fetched successfully",
    "data": [
        {
            "_id": "648b498a3580232d0f0c315a",
            "title": "CGO Question Bank Set-A",
            "displayQuestions": 3,
            "duration": {
                "hours": 1,
                "minutes": 10,
                "_id": "649fd44b950578d68056e8f2"
            },
            "appearedUsers": 10000,
            "passPercentage": 43,
            "declarationResultInDays": 3,
            "hideResultInDays": 1,
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "instructions": "A type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lore Ipsum passages.",
            "passedMessage": "You passed exam successfully!",
            "failedMessage": "Better luck next time!",
            "inSequence": false,
            "questionsSet": [
                {
                    "question": "Question 1",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "648b498a3580232d0f0c315b"
                },
                {
                    "question": "Question 2",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Second Option",
                    "_id": "648b498a3580232d0f0c315c"
                },
                {
                    "question": "Question 3",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Third Option",
                    "_id": "648b498a3580232d0f0c315d"
                },
                {
                    "question": "Question 4",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Fourth Option",
                    "_id": "648b498a3580232d0f0c315e"
                },
                {
                    "question": "Question 5",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "648b498a3580232d0f0c315f"
                }
            ],
            "createdAt": "2023-06-30T17:25:30.376Z",
            "updatedAt": "2023-06-15T17:27:21.466Z",
            "__v": 0
        },
        {
            "_id": "648b65bcddac8b0e2a1a660w",
            "title": "CHH Question Bank Set-A",
            "displayQuestions": 50,
            "duration": {
                "hours": 0,
                "minutes": 2,
                "_id": "649fd44b950578d68056e8f8"
            },
            "appearedUsers": 10000,
            "passPercentage": 43,
            "declarationResultInDays": 2,
            "hideResultInDays": 1,
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/diabetes.png",
            "instructions": "A type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lore Ipsum passages.",
            "passedMessage": "You passed exam successfully!",
            "failedMessage": "Better luck next time!",
            "inSequence": false,
            "questionsSet": [
                {
                    "question": "Question 1",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "648b65bcddac8b0e2a1a660a"
                },
                {
                    "question": "Question 2",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Second Option",
                    "_id": "648b65bcddac8b0e2a1a660b"
                },
                {
                    "question": "Question 3",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Third Option",
                    "_id": "648b65bcddac8b0e2a1a660c"
                },
                {
                    "question": "Question 4",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Fourth Option",
                    "_id": "648b65bcddac8b0e2a1a660d"
                },
                {
                    "question": "Question 5",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "648b65bcddac8b0e2a1a660e"
                }
            ],
            "createdAt": "2023-06-15T19:25:48.022Z",
            "updatedAt": "2023-06-15T19:25:48.022Z",
            "__v": 0
        },
        {
            "_id": "648b668bddac8b0e2a1a662e",
            "title": "Advance Diploma Course Question Bank Set-A",
            "displayQuestions": 50,
            "duration": {
                "hours": 0,
                "minutes": 1,
                "_id": "649afa62949f1b58bbe5d81b"
            },
            "appearedUsers": 10000,
            "passPercentage": 43,
            "declarationResultInDays": 2,
            "hideResultInDays": 1,
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/diabetes.png",
            "instructions": "A type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lore Ipsum passages.",
            "passedMessage": "You passed exam successfully!",
            "failedMessage": "Better luck next time!",
            "inSequence": false,
            "questionsSet": [
                {
                    "question": "Question 1",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "649afa62949f1b58bbe5d81c"
                },
                {
                    "question": "Question 2",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Second Option",
                    "_id": "649afa62949f1b58bbe5d81d"
                },
                {
                    "question": "Question 3",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Third Option",
                    "_id": "649afa62949f1b58bbe5d81e"
                },
                {
                    "question": "Question 4",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Fourth Option",
                    "_id": "649afa62949f1b58bbe5d81f"
                },
                {
                    "question": "Question 5",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "649afa62949f1b58bbe5d820"
                }
            ],
            "createdAt": "2023-06-15T19:29:15.212Z",
            "updatedAt": "2023-06-27T15:04:02.714Z",
            "__v": 0
        },
        {
            "_id": "648b668bddac8b0e2a1a662r",
            "title": "Advance Diploma Course Question Bank Set-A",
            "displayQuestions": 50,
            "duration": {
                "hours": 0,
                "minutes": 1,
                "_id": "649afa62949f1b58bbe5d81b"
            },
            "appearedUsers": 10000,
            "passPercentage": 43,
            "declarationResultInDays": 2,
            "hideResultInDays": 1,
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/diabetes.png",
            "instructions": "A type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lore Ipsum passages.",
            "passedMessage": "You passed exam successfully!",
            "failedMessage": "Better luck next time!",
            "inSequence": false,
            "questionsSet": [
                {
                    "question": "Question 1",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "649afa62949f1b58bbe5d81c"
                },
                {
                    "question": "Question 2",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Second Option",
                    "_id": "649afa62949f1b58bbe5d81d"
                },
                {
                    "question": "Question 3",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Third Option",
                    "_id": "649afa62949f1b58bbe5d81e"
                },
                {
                    "question": "Question 4",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Fourth Option",
                    "_id": "649afa62949f1b58bbe5d81f"
                },
                {
                    "question": "Question 5",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "649afa62949f1b58bbe5d820"
                }
            ],
            "createdAt": "2023-06-15T19:29:15.212Z",
            "updatedAt": "2023-06-27T15:04:02.714Z",
            "__v": 0
        },
        {
            "_id": "648b668bddac8b0e2a1a662t",
            "title": "Advance Diploma Course Question Bank Set-A",
            "displayQuestions": 50,
            "duration": {
                "hours": 0,
                "minutes": 1,
                "_id": "649afa62949f1b58bbe5d81b"
            },
            "appearedUsers": 10000,
            "passPercentage": 43,
            "declarationResultInDays": 2,
            "hideResultInDays": 1,
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/diabetes.png",
            "instructions": "A type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lore Ipsum passages.",
            "passedMessage": "You passed exam successfully!",
            "failedMessage": "Better luck next time!",
            "inSequence": false,
            "questionsSet": [
                {
                    "question": "Question 1",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "649afa62949f1b58bbe5d81c"
                },
                {
                    "question": "Question 2",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Second Option",
                    "_id": "649afa62949f1b58bbe5d81d"
                },
                {
                    "question": "Question 3",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Third Option",
                    "_id": "649afa62949f1b58bbe5d81e"
                },
                {
                    "question": "Question 4",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Fourth Option",
                    "_id": "649afa62949f1b58bbe5d81f"
                },
                {
                    "question": "Question 5",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "649afa62949f1b58bbe5d820"
                }
            ],
            "createdAt": "2023-06-15T19:29:15.212Z",
            "updatedAt": "2023-06-27T15:04:02.714Z",
            "__v": 0
        },
        {
            "_id": "648b668bddac8b0e2a1a662y",
            "title": "Advance Diploma Course Question Bank Set-A",
            "displayQuestions": 50,
            "duration": {
                "hours": 0,
                "minutes": 1,
                "_id": "649afa62949f1b58bbe5d81b"
            },
            "appearedUsers": 10000,
            "passPercentage": 43,
            "declarationResultInDays": 2,
            "hideResultInDays": 1,
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/diabetes.png",
            "instructions": "A type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lore Ipsum passages.",
            "passedMessage": "You passed exam successfully!",
            "failedMessage": "Better luck next time!",
            "inSequence": false,
            "questionsSet": [
                {
                    "question": "Question 1",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "649afa62949f1b58bbe5d81c"
                },
                {
                    "question": "Question 2",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Second Option",
                    "_id": "649afa62949f1b58bbe5d81d"
                },
                {
                    "question": "Question 3",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Third Option",
                    "_id": "649afa62949f1b58bbe5d81e"
                },
                {
                    "question": "Question 4",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Fourth Option",
                    "_id": "649afa62949f1b58bbe5d81f"
                },
                {
                    "question": "Question 5",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "649afa62949f1b58bbe5d820"
                }
            ],
            "createdAt": "2023-06-15T19:29:15.212Z",
            "updatedAt": "2023-06-27T15:04:02.714Z",
            "__v": 0
        },
        {
            "_id": "648b668bddac8b0e2a1a662dx",
            "title": "Advance Diploma Course Question Bank Set-A",
            "displayQuestions": 50,
            "duration": {
                "hours": 0,
                "minutes": 1,
                "_id": "649afa62949f1b58bbe5d81b"
            },
            "appearedUsers": 10000,
            "passPercentage": 43,
            "declarationResultInDays": 2,
            "hideResultInDays": 1,
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/diabetes.png",
            "instructions": "A type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lore Ipsum passages.",
            "passedMessage": "You passed exam successfully!",
            "failedMessage": "Better luck next time!",
            "inSequence": false,
            "questionsSet": [
                {
                    "question": "Question 1",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "649afa62949f1b58bbe5d81c"
                },
                {
                    "question": "Question 2",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Second Option",
                    "_id": "649afa62949f1b58bbe5d81d"
                },
                {
                    "question": "Question 3",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Third Option",
                    "_id": "649afa62949f1b58bbe5d81e"
                },
                {
                    "question": "Question 4",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Fourth Option",
                    "_id": "649afa62949f1b58bbe5d81f"
                },
                {
                    "question": "Question 5",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "649afa62949f1b58bbe5d820"
                }
            ],
            "createdAt": "2023-06-15T19:29:15.212Z",
            "updatedAt": "2023-06-27T15:04:02.714Z",
            "__v": 0
        },
        {
            "_id": "648b668bddac8b0e2a1a662c",
            "title": "Advance Diploma Course Question Bank Set-A",
            "displayQuestions": 50,
            "duration": {
                "hours": 0,
                "minutes": 1,
                "_id": "649afa62949f1b58bbe5d81b"
            },
            "appearedUsers": 10000,
            "passPercentage": 43,
            "declarationResultInDays": 2,
            "hideResultInDays": 1,
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/diabetes.png",
            "instructions": "A type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lore Ipsum passages.",
            "passedMessage": "You passed exam successfully!",
            "failedMessage": "Better luck next time!",
            "inSequence": false,
            "questionsSet": [
                {
                    "question": "Question 1",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "649afa62949f1b58bbe5d81c"
                },
                {
                    "question": "Question 2",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Second Option",
                    "_id": "649afa62949f1b58bbe5d81d"
                },
                {
                    "question": "Question 3",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Third Option",
                    "_id": "649afa62949f1b58bbe5d81e"
                },
                {
                    "question": "Question 4",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Fourth Option",
                    "_id": "649afa62949f1b58bbe5d81f"
                },
                {
                    "question": "Question 5",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "649afa62949f1b58bbe5d820"
                }
            ],
            "createdAt": "2023-06-15T19:29:15.212Z",
            "updatedAt": "2023-06-27T15:04:02.714Z",
            "__v": 0
        },
        {
            "_id": "648b668bddac8b0e2a1a662v",
            "title": "Advance Diploma Course Question Bank Set-A",
            "displayQuestions": 50,
            "duration": {
                "hours": 0,
                "minutes": 1,
                "_id": "649afa62949f1b58bbe5d81b"
            },
            "appearedUsers": 10000,
            "passPercentage": 43,
            "declarationResultInDays": 2,
            "hideResultInDays": 1,
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/diabetes.png",
            "instructions": "A type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lore Ipsum passages.",
            "passedMessage": "You passed exam successfully!",
            "failedMessage": "Better luck next time!",
            "inSequence": false,
            "questionsSet": [
                {
                    "question": "Question 1",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "649afa62949f1b58bbe5d81c"
                },
                {
                    "question": "Question 2",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Second Option",
                    "_id": "649afa62949f1b58bbe5d81d"
                },
                {
                    "question": "Question 3",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Third Option",
                    "_id": "649afa62949f1b58bbe5d81e"
                },
                {
                    "question": "Question 4",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Fourth Option",
                    "_id": "649afa62949f1b58bbe5d81f"
                },
                {
                    "question": "Question 5",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "649afa62949f1b58bbe5d820"
                }
            ],
            "createdAt": "2023-06-15T19:29:15.212Z",
            "updatedAt": "2023-06-27T15:04:02.714Z",
            "__v": 0
        },
        {
            "_id": "648b668bddac8b0e2a1a662d",
            "title": "Advance Diploma Course Question Bank Set-A",
            "displayQuestions": 50,
            "duration": {
                "hours": 0,
                "minutes": 1,
                "_id": "649afa62949f1b58bbe5d81b"
            },
            "appearedUsers": 10000,
            "passPercentage": 43,
            "declarationResultInDays": 2,
            "hideResultInDays": 1,
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/diabetes.png",
            "instructions": "A type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lore Ipsum passages.",
            "passedMessage": "You passed exam successfully!",
            "failedMessage": "Better luck next time!",
            "inSequence": false,
            "questionsSet": [
                {
                    "question": "Question 1",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "649afa62949f1b58bbe5d81c"
                },
                {
                    "question": "Question 2",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Second Option",
                    "_id": "649afa62949f1b58bbe5d81d"
                },
                {
                    "question": "Question 3",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Third Option",
                    "_id": "649afa62949f1b58bbe5d81e"
                },
                {
                    "question": "Question 4",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "Fourth Option",
                    "_id": "649afa62949f1b58bbe5d81f"
                },
                {
                    "question": "Question 5",
                    "options": [
                        "First Option",
                        "Second Option",
                        "Third Option",
                        "Fourth Option"
                    ],
                    "answer": "First Option",
                    "_id": "649afa62949f1b58bbe5d820"
                }
            ],
            "createdAt": "2023-06-15T19:29:15.212Z",
            "updatedAt": "2023-06-27T15:04:02.714Z",
            "__v": 0
        }
    ]
}


//Object to handle naviagtion
export const navigationData = [
    {
        "title" : "Back to Exams",
        "route" : "/content?tab=exams",
        "default" : false,        
        "icon" : GoBackArrow,
    },
    {
        "title" : "Details",
        "component" : "ExamDetails",
        "default" : true,        
        "icon" : EditPencilIcon,
    },
    {
        "title" : "Question",
        "component" : "ExamQuestions",
        "default" : false,        
        "icon" : QuestionMark,
    }
]