//Ref : https://code.pieces.app/blog/implement-react-beautiful-dnd

import React from 'react';

import DnDView from './view';

function DnD(props) {
	const { element, index, newComponent } = props;

	return <DnDView element={element} index={index} newComponent={newComponent} />;
}

export default DnD;
