import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import DynamicDetailsView from './view';
import { useLocation } from 'react-router-dom';

import InnerSidebarNavigation from '@components/InnerSidebarNavigation';
import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';

import * as ArticleServices from '@services/Articles';
import * as ArticleActions from '@redux/actions/Articles';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { decryptText } from '@library/enc-dec';
import { current, produce } from 'immer';
import { useNavigate } from 'react-router-dom';

function DynamicDetails(props) {
	const navigate = useNavigate();
	const location = useLocation();
	let { id } = useParams();

	//Failsafe if no data received from navigation
	let navigationData = [
		{
			title: 'Back to Articles',
			route: '/dynamic',
			icon: GoBackArrow,
		},
	];
	if (location.state && location.state.finalNavigationData) {
		navigationData = location.state.finalNavigationData;
	}

	const [newPointer1, setNewPointer1] = useState('');
	const [newPointer2, setNewPointer2] = useState('');
	const [section1Text, setSection1Text] = useState('');
	const [section2Text, setSection2Text] = useState('');

	//--------------//
	const [sectionImageUrl, setSectionImageUrl] = useState(['', '']);
	const [sectionOneImage, setSectionOneImage] = useState(null);
	const [sectionTwoImage, setSectionTwoImage] = useState(null);
	const [sectionPointerIcon, setSectionPointerIcon] = useState(null);
	const [isArticleLoaded, setArticleLoaded] = useState(false);

	useEffect(() => {
		getArticleDetails();
	}, []);

	const getArticleDetails = async () => {
		try {
			if (id !== undefined) {
				const articleId = id;
				const response = await ArticleServices.getArticle(articleId);
				if (response.success) {
					props.updateSelectedArticle(response.data);
					setSection1Text(response.data['sections'][0]['paragraph']);
					setSection2Text(response.data['sections'][1]['paragraph']);
					setArticleLoaded(true)
					return;
				}
			} else {
				props.resetArticle();
			}
		} catch (err) {
			console.log('Error coming while fetching article details');
		}
	};

	// Updating the article all fields
	const updateArticleField = (field, value) => {
		var updatedArticle = produce(props.selectedArticle, (draft) => {
			if (field === 'description') {
				draft[field].pointer = value;
			} 
			else {
				draft[field] = value;
			}
		});
		if(id && isArticleLoaded ){
			props.updateSelectedArticle(updatedArticle);
		}else if(!id){
			props.updateSelectedArticle(updatedArticle);
		}
	};

	const handleBulletChange = (field, bulletIcon) => {
		if (field === 'description') {
			var updatedArticle = produce(props.selectedArticle, (draft) => {
				draft.description.icon = bulletIcon.icon;
			});
			props.updateSelectedArticle(updatedArticle);
		} else if (field === 'section0' || field === 'section1') {
			setSectionPointerIcon(bulletIcon.icon);
		}
	};

	// Updating the section fields like paragarph
	const updateSectionFields = (field, value, index) => {
		var updatedArticle = produce(props.selectedArticle, (draft) => {
			draft['sections'][index][field] = value;
		});
		props.updateSelectedArticle(updatedArticle);
	};

	const addNewPointer = (index) => {
		var pointer = index===0 ? newPointer1 : newPointer2;
		var updatedArticle = produce(props.selectedArticle, (newAtricle) => {
			newAtricle['sections'][index]['pointers'].push({
				pointer: pointer,
				icon: sectionPointerIcon,
			});
		});

		props.updateSelectedArticle(updatedArticle);
		setNewPointer1('');
		setNewPointer2('');
	};

	const setNewPointers = (index, value) => {
		index===0 ? setNewPointer1(value) : setNewPointer2(value);
	};

	const deletePointer = (sectionIndex, pointerIndex) => {
		var updatedArticle = produce(props.selectedArticle, (newAtricle) => {
			newAtricle['sections'][sectionIndex]['pointers'].splice(pointerIndex, 1);
		});

		props.updateSelectedArticle(updatedArticle);
	};

	const addSectionText = (index) => {
		// var paragraph = index===0 ? section1Text : section2Text;
		// var updatedArticle = produce(props.selectedArticle, (newAtricle) => {
		// 	newAtricle['sections'][index]['paragraph'] = paragraph;
		// });

		// props.updateSelectedArticle(updatedArticle);
	};

	const handleSubmit = async () => {
		try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			var updatedArticle = produce(props.selectedArticle, (newAtricle) => {
				delete newAtricle['_id'];
				newAtricle.sections.map((section) => {
					delete section['_id'];
				});

				newAtricle.sections = newAtricle.sections.filter(section=> section.title)

			});

			let payload = {
				sectionOneImage: sectionOneImage,
				sectionTwoImage: sectionTwoImage,
				...updatedArticle,
			};

			if (id) {
				payload['articleId'] = id;
				var response = await ArticleServices.updateArticle(payload, authToken);
				if (response.success) {
					alert(response.message);
					// navigate('/dynamic');
					// props.resetArticle();
					navigate(`/DynamicArticle/${response.data._id}`, {
						state: {
						  finalNavigationData: navigationData,
						},
					  });
					return;
				}
				alert(response.message);
			} else {
				const response = await ArticleServices.addArticle(payload, authToken);
				if (response.success) {
					alert(response.message);
					// navigate('/DynamicArticles?tab=articles');
					navigate(`/DynamicArticle/${response.data._id.toString()}`, {
						state: { finalNavigationData: navigationData },
					  });
					return;
				}
				alert(response.message);
			}
		} catch (err) {
			alert(err, 'Error in adding the article');
		}
	};

	const handleCancle = () => {
		navigate('/dynamic?tab=articles');
	};

	const onSectionDragEnd = (result) => {
		var updatedArticles = produce(props.selectedArticle, (articles) => {
			const articleToMove = articles.sections.splice(
				result['source']['index'],
				1
			)[0];
			articles.sections.splice(result['destination']['index'], 0, articleToMove);
		});
		props.updateSelectedArticle(updatedArticles);
	};

	const onPointerDragEnd = (result, sectionIndex) => {
		var updatedArticles = produce(props.selectedArticle, (articles) => {
			const articleToMove = articles.sections[
				Number(sectionIndex)
			].pointers.splice(result['source']['index'], 1)[0];
			articles.sections[Number(sectionIndex)].pointers.splice(
				result['destination']['index'],
				0,
				articleToMove
			);
		});
		props.updateSelectedArticle(updatedArticles);
	};

	const handleFileUpload = (event) => {
		let fileInputId = event.target.id.split('_')[0];
		document.getElementById(fileInputId).click();
	};

	const convertToBase64Img = async (event, sectionIndex) => {
		var imageUrl = event.target.files[0];
		if (sectionIndex === 0) {
			setSectionOneImage(event.target.files[0]);
			setSectionImageUrl([imageUrl.name, sectionImageUrl[1]]);
			var updatedArticle = produce(props.selectedArticle, (article) => {
				article.sections[Number(sectionIndex)].imageUrl = null;
			});
			props.updateSelectedArticle(updatedArticle);
		} else {
			setSectionTwoImage(event.target.files[0]);
			setSectionImageUrl([sectionImageUrl[0], imageUrl.name]);
			var updatedArticle = produce(props.selectedArticle, (article) => {
				article.sections[Number(sectionIndex)].imageUrl = null;
			});

			props.updateSelectedArticle(updatedArticle);
		}
	};
	return (
		<>
			<InnerSidebarNavigation navigationData={navigationData} />
			<DynamicDetailsView
				article={props.selectedArticle}
				updateArticleField={updateArticleField}
				handleBulletChange={handleBulletChange}
				newPointers={[newPointer1, newPointer2]}
				sectionImageUrl={sectionImageUrl}
				setNewPointers={(index, value) => setNewPointers(index, value)}
				addNewPointer={(sectionIndex) => {
					addNewPointer(sectionIndex);
				}}
				deletePointer={(sectionIndex, pointerIndex) => {
					deletePointer(sectionIndex, pointerIndex);
				}}
				updateSectionFields={updateSectionFields}
				addSectionText={(sectionIndex) => {
					addSectionText(sectionIndex);
				}}
				handleCancle={handleCancle}
				handleSubmit={handleSubmit}
				onSectionDragEnd={onSectionDragEnd}
				onPointerDragEnd={onPointerDragEnd}
				handleFileUpload={(event) => {
					handleFileUpload(event);
				}}
				convertToBase64={convertToBase64Img}
			/>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		selectedArticle: state.articles.selectedArticle,
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateSelectedArticle: ArticleActions.updateSelectedArticle,
			resetArticle: ArticleActions.resetArticle,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(DynamicDetails);
