import React from 'react';

import './style.css';
import SideArrow from '@assets/common/sideArrow.png'
import PopUp from '@components/PopUp';

function EditQualificationPopUpView(props) {
	return (
		<div className="qualificationPopUpDiv rounded">
			{  
			 Object.entries(props.allQualifications)
			         .map(([profession, qualifications], index)=>{
				
				var selectedCount = props.currentQualifications.filter(qualification => 
					                                 qualifications.includes(qualification)).length;
				var professionChecked = selectedCount > 0 ? true:false;

				return(<div className="d-flex align-items-center p-2 hover justify-content-around gap-4 px-4" 
				            key={index}
							onClick={(event)=>props.handlePopUpOpen(event, profession, index)}
							>
						<input type='checkbox' className="colorCheckbox"
							checked={ professionChecked}
							onChange={(event)=>props.handleProfessionCheck(qualifications, !professionChecked)}
							></input>
						{profession}
						<span className="ms-auto"
						      style={{backgroundColor: selectedCount ? '#EBB142':'#b4b4b4'}}
						>{selectedCount}/{qualifications.length} </span>
						<img src={SideArrow}/>
						
				</div>)
			})
			}

		<PopUp visible={props.isPopUpVisible}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left}
				handleOutSideClick={props.handlePopUpClose}>
					<div className="p-3 rounded-3" style={{minWidth: '158px'}}>
						{props.selectedProfessions.map((profession, index)=>{

							var checked = props.currentQualifications.includes(profession)
							return <div className="d-flex gap-3 rounded-3 mb-1">
								<input type='checkbox' className="colorCheckbox"
								       checked={checked}
									   onClick={(event)=>props.handleQualificationCheck(profession, !checked)}
									   ></input>
								{profession}
							</div>
						})}
					</div>
		</PopUp>

		<div className="d-flex justify-content-end gap-1 pe-4 mt-2">
				<button onClick={props.handleCloseBtn} className='closeBtn'>
					Cancel
				</button>
				<button onClick={props.handleSaveBtn} className='saveBtn'>
					Save
				</button>
            </div>

		</div>
	);
}

export default EditQualificationPopUpView;
