export const getAllQuestionBanks = async (payload, authToken) => {
  try {
    const { pageNo, limit, isSorted, sortBy, searchText } = payload;
    const url = `${
      process.env.REACT_APP_BASE_URL
    }/getAllQuestionBanks?pageNo=${pageNo}&limit=${limit}${
      searchText ? "&searchText=" + searchText : ""
    }${sortBy ? "&sortBy=" + sortBy : ""}&isSorted=${isSorted}`;

    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from EXam : Get all Exam questions ", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateQuestionBankThumbnail = async (payload, authToken) => {
  try {
    let formData = new FormData();
    for (let key in payload) {
      formData.append(key, payload[key]);
    }
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateQuestionBankThumbnail`,
      {
        method: "PATCH",
        headers: {
          Authorization: authToken,
        },
        body: formData,
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Exam services : updateQuestionBankThumbnail()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const deleteQuestionBanks = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/deleteQuestionBanks`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Exam services : updateQuestionBankThumbnail()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getQuestionBank = async (questionBankId, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/getQuestionBankById/${questionBankId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from exam services :getQuestionBank()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateQuestionBank = async (payload, authToken) => {
  try {
    let formData = new FormData();
    for (let key in payload) {
      if (
        key === "questionsSet" ||
        key === "duration" ||
        key === "instructions"
      ) {
        formData.append(key, JSON.stringify(payload[key]));
      } else {
        formData.append(key, payload[key]);
      }
    }

    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateQuestionBank`, {
      method: "PATCH",
      headers: {
        Authorization: authToken,
      },
      body: formData,
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Exam services : updateQuestionBank()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const addQuestionBank = async (payload, authToken) => {
  try {
    let formData = new FormData();
    for (let key in payload) {
      if (
        key === "questionsSet" ||
        key === "duration" ||
        key === "instructions"
      ) {
        formData.append(key, JSON.stringify(payload[key]));
      } else {
        formData.append(key, payload[key]);
      }
    }

    return await fetch(`${process.env.REACT_APP_BASE_URL}/addQuestionBank`, {
      method: "POST",
      headers: {
        Authorization: authToken,
      },
      body: formData,
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Exam services : addQuestionBank()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

// New API to be created
export const getResult = async (purchaseId, resultId, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/getResult?purchaseId=${purchaseId}&resultId=${resultId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Exam services : getResult()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const uploadQuestionThumbnail = async (payload, authToken) => {
  try {
    let formData = new FormData();
    for (let key in payload) {
      formData.append(key, payload[key]);
    }
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/uploadQuestionThumbnail`,
      {
        method: "PATCH",
        headers: {
          Authorization: authToken,
        },
        body: formData,
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Exam services : uploadQuestionThumbnail()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};
