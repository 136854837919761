import React from 'react';
import './style.css';
import { Tabs, Tab } from 'react-bootstrap';

import Courses from '@pages/Content/Courses/Main';
import Series from '@pages/Content/Series/Main';
import Videos from '@pages/Content/Videos/Main';
import CourseCategory from '@pages/Content/CourseCategories/Main';
import Exam from '@pages/Content/Exams/Main';
import Tests from '@pages/Content/Tests/Main';
import Faq from '@pages/Content/FAQ/Main';


function ContentView(props) {
	return (
		<>
			<div className='contentPageMainContainer'>
				<h4 style={{ marginBottom: '20px', marginLeft: '20px' }}> Content </h4>

				<Tabs
					defaultActiveKey={props.selectedTab}
					id='myTabs'
					onSelect={props.handleTabSelect}>
					<Tab eventKey='videos' title='Videos'>
						{props.selectedTab === 'videos' && <Videos />}
					</Tab>
					<Tab eventKey='courses' title='Courses'>
						{props.selectedTab === 'courses' && <Courses />}
					</Tab>
					<Tab eventKey='series' title='Series'>
						{props.selectedTab === 'series' && <Series />}
					</Tab>
					<Tab eventKey='courseCategory' title='Course Category'>
						{props.selectedTab === 'courseCategory' && <CourseCategory />}
					</Tab>
					<Tab eventKey='exams' title='Exams'>
						{props.selectedTab === 'exams' && 
						<Exam />}
					</Tab>
					<Tab eventKey='tests' title='Tests'>
					{props.selectedTab === 'tests' && 
						<Tests />}
					</Tab>
					<Tab eventKey='faq' title='FAQs'>
					{props.selectedTab === 'faq' && 
						<Faq />}
					</Tab>
				</Tabs>
			</div>
		</>
	);
}

export default ContentView;
