import React from 'react';
import './style.css';

import PlusIcon from '@assets/common/plusIconBlue.png';

import EditPencilIcon from '@assets/common/editPencilIcon.png';

import DeleteIcon from '@assets/common/deleteIcon.png';
import ExpandIcon from '@assets/userDashboard/expand.png';

import BasicTableGrid from '@components/BasicTableGrid';
import DateAndUser from '@components/DateAndUser';
import Search from '@components/Search';


function TestView(props) {
	return (
		<div ref={props.fullscreenElementRef} style={{ maxWidth: '100%', 
		backgroundColor: '#ffffff', display: 'flex', flexDirection: 'column', flexGrow:'1'
		}}>
			<div className='Xcontainer_1 pb-2' >
				<div className='d-flex align-items-center w-100 justify-content-between'>
					<button
						onClick={(event) => {
							props.handleRedirectToHome(event, 'none', 'TestDetails');
						}}
						className='simpleBtn'
						style={{ marginRight: '10px' }}>
						<img src={PlusIcon} alt='PlusIcon' style={{width:'12px', marginRight:'5px'}}/>
						Add Test
					</button>
					<div className='d-flex gap-2 controlsRight'>
						<button onClick={props.toggleFullscreen}>
							<img src={ExpandIcon}></img>
							<br></br>
							Expand
						</button>
						<button onClick={props.handleDelete} disabled={props.disableBulkActionBtn}>
							<img src={DeleteIcon}></img>
							<br></br>
							Delete
						</button>
					</div>
				</div>
			</div>
			<Search
			    value={props.searchText}
				placeholder = {"Search tests"}
				onSearch ={(data)=>props.handleSearch(data)}
			>
			<div>
				No Filters Added
			</div>
			</Search>
			<BasicTableGrid 
				gridId='Tests'
				data={props.deferedData} // Data to be displayed
				fixedCols={2} // How many columns are fixed and not scrolling
				initialSize={[35,534,740,150,294]}
				initialSorting={[{"id": props.sortBy === 'createdAt'? "Date Added":"Date Modified","desc": props.isSorted}]}
				handleSelect={(selectedRows) => {
					props.handleCheckBoxes(selectedRows);
				}}
				length={20}
				// pagination={props.pagination}
				// setPagination={props.setPagination}
				onSortChange={(data) =>  props.handleSort(data)
				}
				columnsOptionPopUpRef={props.columnsOptionPopUpRef}
				columns={[
				{header: 'Title',
					cell: (data) => {
					const info = data.row.original;
					return (
						
						<div className="d-flex gap-2">
							{true ? <div className="thumbnailContainer" 
								   style={{
									 backgroundImage:`url(${info.thumbnailUrl})`
									}}
								   >
								</div>
							:
							 ''
							}
							<div className="d-flex flex-column justify-content-center gap-2 ms-2">
								<div 
								// onClick = {(event)=>{props.handleTitleClick(event, info)}}
								>
									{info?.title} 
								</div>
								<div className='hiddenDiv d-flex gap-2'>
									<img
										onClick={(event) => {
											props.handleRedirectToHome(event, info._id, 'TestDetails');
										}}
										src={EditPencilIcon}
										alt='EditPencilIcon'
									/>
								</div>
							</div>
							
						</div>
					);
					},
				},
				{header: 'Date Added', accessorFn: (row) => {},
					cell: (data) => {
						const info = data.row.original;
						return <DateAndUser
									date = {info.createdAt}
									image = {info.createdUserDetails?.displayPhoto}
									imageText = {info.createdUserDetails?.displayName}/>
					},
			    },
				{header: 'Appeared users', 
					cell: (data) => {
						const info = data.row.original;
						return info.appearedUsers
					},
			    },
				{header: 'Date Modified', accessorFn: (row) => {},
				cell: (data) => {
					const info = data.row.original;
					return (<DateAndUser
									date = {info.updatedAt}
									image = {info.lastModifiedUserDetails?.displayPhoto}
									imageText = {info.lastModifiedUserDetails?.displayName}/>
					) 
				},
			    }
				]}
				/>

		</div>
	);
}

export default TestView;
