import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';
import EditPencilIcon from '@assets/common/editPencilIcon.png';
import CommentsIcon from '@assets/common/commentsIcon.png';

import AnalyticsIcon from '@assets/common/analyticsIcon.png';


export const tempApiCommentData = {
    "success": true,
    "message": "All Comments Data Feteched",
    "totalNumberOfComments": 876,
    "data": [
        {
            "_id": "1",
            "commenterName": "Mahdi",
            "commentDate":"2023-07-05T13:31:46.777Z",
            "commenterProfileImage": "",
            "comment": "Comment 1",
            "likesCount": 100,
            "dislikesCount": 0,
            "status" : "published",
            "replies": [
                // {
                //     "_id": "1_1",
                //     "commenterName": "Mahdi",
                //     "commenterProfileImage": "",
                //     "commentDate":"2023-07-07T13:31:46.777Z",
                //     "comment": "comment 1_1_holdForReview",
                //     "likesCount": 2000,
                //     "dislikesCount": 0,
                //     "status" : "holdForReview",
                // },
                // {
                //     "_id": "1_2",
                //     "commenterName": "Mahdi",
                //     "commenterProfileImage": "",
                //     "commentDate":"2023-06-08T13:31:46.777Z",
                //     "comment": "comment 1_2_rejected",
                //     "likesCount": 10000,
                //     "dislikesCount": 0,
                //     "status" : "rejected",
                // },
                // {
                //     "_id": "1_3",
                //     "commenterName": "Mahdi",
                //     "commenterProfileImage": "",
                //     "commentDate":"2023-12-14T13:31:46.777Z",
                //     "comment": "comment 1_3_published",
                //     "likesCount": 200,
                //     "dislikesCount": 0,
                //     "status" : "published",                    
                // },
            ]
        },

        {
            "_id": "2",
            "commenterName": "Mahdi",
            "commentDate":"2023-03-29T13:31:46.777Z",
            "commenterProfileImage": "",
            "comment": "comment 2",
            "likesCount": 1400,
            "dislikesCount": 0,
            "status" : "holdForReview",
            "replies": [
                {
                    "_id": "2_1",
                    "commenterName": "Mahdi",
                    "commenterProfileImage": "",
                    "commentDate":"2022-12-08T13:31:46.777Z",
                    "comment": "comment 2_1",
                    "likesCount": 900,
                    "dislikesCount": 0,
                    "status" : "rejected",
                },
            ]
        },

        {
            "_id": "3",
            "commenterName": "Mahdi",
            "commentDate":"2023-01-08T13:31:46.777Z",
            "commenterProfileImage": "",
            "comment": "comment 3",
            "likesCount": 89,
            "dislikesCount": 0,
            "status" : "rejected",
            "replies": [  ]
        },

        {
            "_id": "4",
            "commenterName": "Mahdi",
            "commentDate":"2023-01-27T13:31:46.777Z",
            "commenterProfileImage": "",
            "comment": "Comment 4",
            "likesCount": 1500,
            "dislikesCount": 0,
            "status" : "published",
            "replies": [
                {
                    "_id": "4_1",
                    "commenterName": "Mahdi",
                    "commenterProfileImage": "",
                    "commentDate":"2022-01-14T13:31:46.777Z",
                    "comment": "comment 4_1",
                    "likesCount": 20000,
                    "dislikesCount": 0,
                    "status" : "holdForReview",
                },
                {
                    "_id": "4_2",
                    "commenterName": "Mahdi",
                    "commenterProfileImage": "",
                    "commentDate":"2022-01-01T13:31:46.777Z",
                    "comment": "comment 4_2",
                    "likesCount": 1230,
                    "dislikesCount": 0,
                    "status" : "rejected",
                },
                {
                    "_id": "4_3",
                    "commenterName": "Mahdi",
                    "commenterProfileImage": "",
                    "commentDate":"2021-12-14T13:31:46.777Z",
                    "comment": "comment 4_3",
                    "likesCount": 12,
                    "dislikesCount": 0,
                    "status" : "published",                    
                },
            ]
        },

        {
            "_id": "5",
            "commenterName": "Mahdi",
            "commentDate":"2023-07-10T13:31:46.777Z",
            "commenterProfileImage": "",
            "comment": "Comment 5",
            "likesCount": 393000,
            "dislikesCount": 0,
            "status" : "published",
            "replies": [
            ]
        },

        {
            "_id": "6",
            "commenterName": "Mahdi",            
            "commentDate":"2023-07-10T13:31:46.777Z",
            "commenterProfileImage": "",
            "comment": "Comment 6",
            "likesCount": 10000,
            "dislikesCount": 0,
            "status" : "published",
            "replies": [
                {
                    "_id": "6_1",
                    "commenterName": "Mahdi",
                    "commenterProfileImage": "",
                    "commentDate":"2021-11-14T13:31:46.777Z",
                    "comment": "comment 6_1",
                    "likesCount": 32,
                    "dislikesCount": 0,
                    "status" : "holdForReview",
                },
            ]
        },

        {
            "_id": "7",
            "commenterName": "Mahdi",
            "commentDate":"2023-01-14T13:31:46.777Z",
            "commenterProfileImage": "",
            "comment": "comment 7",
            "likesCount": 100,
            "dislikesCount": 0,
            "status" : "holdForReview",
            "replies": [
                {
                    "_id": "2_1",
                    "commenterName": "Mahdi",
                    "commenterProfileImage": "",
                    "commentDate":"2023-01-08T13:31:46.777Z",
                    "comment": "comment 7_1",
                    "likesCount": 900,
                    "dislikesCount": 0,
                    "status" : "rejected",
                },
            ]
        },

    ]
}

//Object to handle naviagtion
export const navigationData = [
    {
        "title" : "Back to Comments",
        "route" : "/comments",
        "default" : false,        
        "icon" : GoBackArrow
    },
    {
        "title" : "Details",
        "component" : "VideoDetails",
        "default" : false,        
        "icon" : EditPencilIcon
    },
    {
        "title" : "Comments",
        "component" : "VideoComments",
        "default" : false,         
        "icon" : CommentsIcon
    },
    {
        "title" : "Analytics",
        "component" : "VideoAnalytics",
        "default" : false,        
        "icon" : AnalyticsIcon
    },
]
