import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';
import EditPencilIcon from '@assets/common/editPencilIcon.png';
import AnalyticsIcon from '@assets/common/analyticsIcon.png';
import ModulesIcon from '@assets/common/coursePageTabsIcon.png';
import PricingIcon from '@assets/common/coursePageTagIcon.png';
import BlogIcon from '@assets/common/searchBox.png';
import SeoIcon from '@assets/common/infoBox.png';

export const apiCoursesData = {
    "success": true,
    "message": "All courses fetched successfully",
    "data": [
        {
            "_id": "6491deb4b376d888af26df44",
            "title": "Certificate Course in Child Heallth 1",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CCH",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cch.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b14e2df1e520006ad0527",
            "examId": "648b65bcddac8b0e2a1a6609",
            "isPublic": false,
            "courseType": "paid",
            "offerExpiry": {
                "days": 0,
                "hours": 0,
                "minutes": 2,
                "_id": "6496b4791a6ec4047e3a1738"
            },
            "prices": [
                {
                    "crossedPrice": 6000,
                    "offerPrice": 3512,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491deb4b376d888af26df45"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491deb4b376d888af26df46"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491deb4b376d888af26df47"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491deb4b376d888af26df48"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491deb4b376d888af26df49"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491deb4b376d888af26df4a"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491deb4b376d888af26df4b"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491deb4b376d888af26df4c"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491deb4b376d888af26df4d"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491deb4b376d888af26df4e"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491deb4b376d888af26df4f"
                }
            ],
            "createdAt": "2023-12-11T17:15:32.476Z",
            "updatedAt": "2023-06-20T17:15:32.476Z",
            "__v": 0
        },
        {
            "_id": "6491deebb376d888af26df52",
            "title": "Certificate Course in Child Heallth V2 2",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CCH",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cch.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2000432,
            "faqsId": "648b14e2df1e520006ad0527",
            "examId": null,
            "isPublic": true,
            "courseType": "free",
            "offerExpiry": null,
            "prices": [
                {
                    "crossedPrice": 6000,
                    "offerPrice": 3512,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491deebb376d888af26df53"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491deebb376d888af26df54"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491deebb376d888af26df55"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491deebb376d888af26df56"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491deebb376d888af26df57"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491deebb376d888af26df58"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491deebb376d888af26df59"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491deebb376d888af26df5a"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491deebb376d888af26df5b"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491deebb376d888af26df5c"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491deebb376d888af26df5d"
                }
            ],
            "createdAt": "2023-11-20T17:16:27.058Z",
            "updatedAt": "2023-06-20T17:16:27.058Z",
            "__v": 0
        },
        {
            "_id": "6491df47b376d888af26df60",
            "title": "Advance Diploma Course in Critical Care 3",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "ADVCC",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/critical-care.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0c77c9f690b6a88a45df"
            ],
            "isLatest": true,
            "activeUsers": 24320,
            "faqsId": "648b14e2df1e520006ad0527",
            "examId": "648b65bcddac8b0e2a1a6609",
            "isPublic": true,
            "courseType": "paid",
            "offerExpiry": {
                "days": 4,
                "hours": 0,
                "minutes": 0,
                "_id": "6496b4791a6ec4047e3a174f"
            },
            "prices": [
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491df47b376d888af26df61"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491df47b376d888af26df62"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491df47b376d888af26df63"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491df47b376d888af26df64"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491df47b376d888af26df65"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491df47b376d888af26df66"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491df47b376d888af26df67"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491df47b376d888af26df68"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491df47b376d888af26df69"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491df47b376d888af26df6a"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491df47b376d888af26df6b"
                }
            ],
            "createdAt": "2023-10-01T17:17:59.290Z",
            "updatedAt": "2023-06-20T17:17:59.290Z",
            "__v": 0
        },
        {
            "_id": "6491df97b376d888af26df6e",
            "title": "Advance Diploma Course in Critical Care V2 4",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "ADVCC",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/critical-care.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 120,
            "validityText": "4 months access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0c77c9f690b6a88a45df"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b14e2df1e520006ad0527",
            "examId": "648b65bcddac8b0e2a1a6609",
            "isPublic": false,
            "courseType": "comingSoon",
            "offerExpiry": null,
            "prices": [
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491df97b376d888af26df6f"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491df97b376d888af26df70"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491df97b376d888af26df71"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491df97b376d888af26df72"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491df97b376d888af26df73"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491df97b376d888af26df74"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491df97b376d888af26df75"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491df97b376d888af26df76"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491df97b376d888af26df77"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491df97b376d888af26df78"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491df97b376d888af26df79"
                }
            ],
            "createdAt": "2023-10-01T17:19:19.664Z",
            "updatedAt": "2023-06-20T17:19:19.664Z",
            "__v": 0
        },
        {
            "_id": "6491e51f011561f27965190b",
            "title": "Certificate Course in Gynocology and Obsetrics 5",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CGO",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b37cd39dd860f67d5e703",
            "examId": "648b498a3580232d0f0c315a",
            "isPublic": true,
            "courseType": "paid",
            "offerExpiry": {
                "days": 5,
                "hours": 3,
                "minutes": 30,
                "_id": "6491e51f011561f27965190c"
            },
            "prices": [
                {
                    "crossedPrice": 5000,
                    "offerPrice": 1499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491e51f011561f27965190d"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491e51f011561f27965190e"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491e51f011561f27965190f"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491e51f011561f279651910"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491e51f011561f279651911"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491e51f011561f279651912"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491e51f011561f279651913"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491e51f011561f279651914"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491e51f011561f279651915"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491e51f011561f279651916"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491e51f011561f279651917"
                }
            ],
            "createdAt": "2023-09-30T17:42:55.622Z",
            "updatedAt": "2023-06-20T17:42:55.622Z",
            "__v": 0
        },
        {
            "_id": "6491e51f011561f27965190c",
            "title": "Certificate Course in Gynocology and Obsetrics 6",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CGO",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b37cd39dd860f67d5e703",
            "examId": "648b498a3580232d0f0c315a",
            "isPublic": true,
            "courseType": "paid",
            "offerExpiry": {
                "days": 5,
                "hours": 3,
                "minutes": 30,
                "_id": "6491e51f011561f27965190c"
            },
            "prices": [
                {
                    "crossedPrice": 5000,
                    "offerPrice": 1499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491e51f011561f27965190d"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491e51f011561f27965190e"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491e51f011561f27965190f"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491e51f011561f279651910"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491e51f011561f279651911"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491e51f011561f279651912"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491e51f011561f279651913"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491e51f011561f279651914"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491e51f011561f279651915"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491e51f011561f279651916"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491e51f011561f279651917"
                }
            ],
            "createdAt": "2023-09-25T17:42:55.622Z",
            "updatedAt": "2023-06-20T17:42:55.622Z",
            "__v": 0
        },
        {
            "_id": "6491e51f011561f27965190a",
            "title": "Certificate Course in Gynocology and Obsetrics 7",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CGO",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b37cd39dd860f67d5e703",
            "examId": "648b498a3580232d0f0c315a",
            "isPublic": true,
            "courseType": "paid",
            "offerExpiry": {
                "days": 5,
                "hours": 3,
                "minutes": 30,
                "_id": "6491e51f011561f27965190c"
            },
            "prices": [
                {
                    "crossedPrice": 5000,
                    "offerPrice": 1499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491e51f011561f27965190d"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491e51f011561f27965190e"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491e51f011561f27965190f"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491e51f011561f279651910"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491e51f011561f279651911"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491e51f011561f279651912"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491e51f011561f279651913"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491e51f011561f279651914"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491e51f011561f279651915"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491e51f011561f279651916"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491e51f011561f279651917"
                }
            ],
            "createdAt": "2023-09-20T17:42:55.622Z",
            "updatedAt": "2023-06-20T17:42:55.622Z",
            "__v": 0
        },
        {
            "_id": "6491e51f011561f27965190e",
            "title": "Certificate Course in Gynocology and Obsetrics 8",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CGO",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b37cd39dd860f67d5e703",
            "examId": "648b498a3580232d0f0c315a",
            "isPublic": true,
            "courseType": "paid",
            "offerExpiry": {
                "days": 5,
                "hours": 3,
                "minutes": 30,
                "_id": "6491e51f011561f27965190c"
            },
            "prices": [
                {
                    "crossedPrice": 5000,
                    "offerPrice": 1499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491e51f011561f27965190d"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491e51f011561f27965190e"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491e51f011561f27965190f"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491e51f011561f279651910"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491e51f011561f279651911"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491e51f011561f279651912"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491e51f011561f279651913"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491e51f011561f279651914"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491e51f011561f279651915"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491e51f011561f279651916"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491e51f011561f279651917"
                }
            ],
            "createdAt": "2023-06-20T17:42:55.622Z",
            "updatedAt": "2023-06-20T17:42:55.622Z",
            "__v": 0
        },
        {
            "_id": "6491e51f011561f27965190k",
            "title": "Certificate Course in Gynocology and Obsetrics 9",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CGO",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b37cd39dd860f67d5e703",
            "examId": "648b498a3580232d0f0c315a",
            "isPublic": true,
            "courseType": "paid",
            "offerExpiry": {
                "days": 5,
                "hours": 3,
                "minutes": 30,
                "_id": "6491e51f011561f27965190c"
            },
            "prices": [
                {
                    "crossedPrice": 5000,
                    "offerPrice": 1499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491e51f011561f27965190d"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491e51f011561f27965190e"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491e51f011561f27965190f"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491e51f011561f279651910"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491e51f011561f279651911"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491e51f011561f279651912"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491e51f011561f279651913"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491e51f011561f279651914"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491e51f011561f279651915"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491e51f011561f279651916"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491e51f011561f279651917"
                }
            ],
            "createdAt": "2023-06-20T17:42:55.622Z",
            "updatedAt": "2023-06-20T17:42:55.622Z",
            "__v": 0
        },
        {
            "_id": "6491e51f011561f27965190j",
            "title": "Certificate Course in Gynocology and Obsetrics 10",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CGO",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b37cd39dd860f67d5e703",
            "examId": "648b498a3580232d0f0c315a",
            "isPublic": true,
            "courseType": "paid",
            "offerExpiry": {
                "days": 5,
                "hours": 3,
                "minutes": 30,
                "_id": "6491e51f011561f27965190c"
            },
            "prices": [
                {
                    "crossedPrice": 5000,
                    "offerPrice": 1499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491e51f011561f27965190d"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491e51f011561f27965190e"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491e51f011561f27965190f"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491e51f011561f279651910"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491e51f011561f279651911"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491e51f011561f279651912"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491e51f011561f279651913"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491e51f011561f279651914"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491e51f011561f279651915"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491e51f011561f279651916"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491e51f011561f279651917"
                }
            ],
            "createdAt": "2023-06-20T17:42:55.622Z",
            "updatedAt": "2023-06-20T17:42:55.622Z",
            "__v": 0
        },
        {
            "_id": "6491e51f011561f279651901",
            "title": "Certificate Course in Gynocology and Obsetrics 11",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CGO",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b37cd39dd860f67d5e703",
            "examId": "648b498a3580232d0f0c315a",
            "isPublic": true,
            "courseType": "paid",
            "offerExpiry": {
                "days": 5,
                "hours": 3,
                "minutes": 30,
                "_id": "6491e51f011561f27965190c"
            },
            "prices": [
                {
                    "crossedPrice": 5000,
                    "offerPrice": 1499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491e51f011561f27965190d"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491e51f011561f27965190e"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491e51f011561f27965190f"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491e51f011561f279651910"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491e51f011561f279651911"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491e51f011561f279651912"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491e51f011561f279651913"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491e51f011561f279651914"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491e51f011561f279651915"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491e51f011561f279651916"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491e51f011561f279651917"
                }
            ],
            "createdAt": "2023-12-20T17:42:55.622Z",
            "updatedAt": "2023-06-20T17:42:55.622Z",
            "__v": 0
        },
        {
            "_id": "6491e51f011561f279651902",
            "title": "Certificate Course in Gynocology and Obsetrics 12",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CGO",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b37cd39dd860f67d5e703",
            "examId": "648b498a3580232d0f0c315a",
            "isPublic": true,
            "courseType": "paid",
            "offerExpiry": {
                "days": 5,
                "hours": 3,
                "minutes": 30,
                "_id": "6491e51f011561f27965190c"
            },
            "prices": [
                {
                    "crossedPrice": 5000,
                    "offerPrice": 1499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491e51f011561f27965190d"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491e51f011561f27965190e"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491e51f011561f27965190f"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491e51f011561f279651910"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491e51f011561f279651911"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491e51f011561f279651912"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491e51f011561f279651913"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491e51f011561f279651914"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491e51f011561f279651915"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491e51f011561f279651916"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491e51f011561f279651917"
                }
            ],
            "createdAt": "2023-11-20T17:42:55.622Z",
            "updatedAt": "2023-06-20T17:42:55.622Z",
            "__v": 0
        },
        {
            "_id": "6491e51f011561f279651913",
            "title": "Certificate Course in Gynocology and Obsetrics 15",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CGO",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b37cd39dd860f67d5e703",
            "examId": "648b498a3580232d0f0c315a",
            "isPublic": true,
            "courseType": "paid",
            "offerExpiry": {
                "days": 5,
                "hours": 3,
                "minutes": 30,
                "_id": "6491e51f011561f27965190c"
            },
            "prices": [
                {
                    "crossedPrice": 5000,
                    "offerPrice": 1499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491e51f011561f27965190d"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491e51f011561f27965190e"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491e51f011561f27965190f"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491e51f011561f279651910"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491e51f011561f279651911"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491e51f011561f279651912"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491e51f011561f279651913"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491e51f011561f279651914"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491e51f011561f279651915"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491e51f011561f279651916"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491e51f011561f279651917"
                }
            ],
            "createdAt": "2023-10-20T17:42:55.622Z",
            "updatedAt": "2023-06-20T17:42:55.622Z",
            "__v": 0
        }


        ,
        {
            "_id": "6491e51f011561f279651923",
            "title": "Certificate Course in Gynocology and Obsetrics 16",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CGO",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b37cd39dd860f67d5e703",
            "examId": "648b498a3580232d0f0c315a",
            "isPublic": true,
            "courseType": "paid",
            "offerExpiry": {
                "days": 5,
                "hours": 3,
                "minutes": 30,
                "_id": "6491e51f011561f27965190c"
            },
            "prices": [
                {
                    "crossedPrice": 5000,
                    "offerPrice": 1499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491e51f011561f27965190d"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491e51f011561f27965190e"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491e51f011561f27965190f"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491e51f011561f279651910"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491e51f011561f279651911"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491e51f011561f279651912"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491e51f011561f279651913"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491e51f011561f279651914"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491e51f011561f279651915"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491e51f011561f279651916"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491e51f011561f279651917"
                }
            ],
            "createdAt": "2023-09-20T17:42:55.622Z",
            "updatedAt": "2023-06-20T17:42:55.622Z",
            "__v": 0
        },
        {
            "_id": "6491e51f011561f279651933",
            "title": "Certificate Course in Gynocology and Obsetrics 17",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CGO",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b37cd39dd860f67d5e703",
            "examId": "648b498a3580232d0f0c315a",
            "isPublic": true,
            "courseType": "paid",
            "offerExpiry": {
                "days": 5,
                "hours": 3,
                "minutes": 30,
                "_id": "6491e51f011561f27965190c"
            },
            "prices": [
                {
                    "crossedPrice": 5000,
                    "offerPrice": 1499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491e51f011561f27965190d"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491e51f011561f27965190e"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491e51f011561f27965190f"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491e51f011561f279651910"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491e51f011561f279651911"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491e51f011561f279651912"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491e51f011561f279651913"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491e51f011561f279651914"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491e51f011561f279651915"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491e51f011561f279651916"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491e51f011561f279651917"
                }
            ],
            "createdAt": "2023-06-20T17:42:55.622Z",
            "updatedAt": "2023-06-20T17:42:55.622Z",
            "__v": 0
        },
        {
            "_id": "6491e51f011561f279651943",
            "title": "Certificate Course in Gynocology and Obsetrics 18",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CGO",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b37cd39dd860f67d5e703",
            "examId": "648b498a3580232d0f0c315a",
            "isPublic": true,
            "courseType": "paid",
            "offerExpiry": {
                "days": 5,
                "hours": 3,
                "minutes": 30,
                "_id": "6491e51f011561f27965190c"
            },
            "prices": [
                {
                    "crossedPrice": 5000,
                    "offerPrice": 1499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491e51f011561f27965190d"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491e51f011561f27965190e"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491e51f011561f27965190f"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491e51f011561f279651910"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491e51f011561f279651911"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491e51f011561f279651912"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491e51f011561f279651913"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491e51f011561f279651914"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491e51f011561f279651915"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491e51f011561f279651916"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491e51f011561f279651917"
                }
            ],
            "createdAt": "2023-06-20T17:42:55.622Z",
            "updatedAt": "2023-06-20T17:42:55.622Z",
            "__v": 0
        },
        {
            "_id": "6491e51f011561f279651953",
            "title": "Certificate Course in Gynocology and Obsetrics 19",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CGO",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b37cd39dd860f67d5e703",
            "examId": "648b498a3580232d0f0c315a",
            "isPublic": true,
            "courseType": "paid",
            "offerExpiry": {
                "days": 5,
                "hours": 3,
                "minutes": 30,
                "_id": "6491e51f011561f27965190c"
            },
            "prices": [
                {
                    "crossedPrice": 5000,
                    "offerPrice": 1499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491e51f011561f27965190d"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491e51f011561f27965190e"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491e51f011561f27965190f"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491e51f011561f279651910"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491e51f011561f279651911"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491e51f011561f279651912"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491e51f011561f279651913"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491e51f011561f279651914"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491e51f011561f279651915"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491e51f011561f279651916"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491e51f011561f279651917"
                }
            ],
            "createdAt": "2023-06-20T17:42:55.622Z",
            "updatedAt": "2023-06-20T17:42:55.622Z",
            "__v": 0
        },
        {
            "_id": "6491e51f011561f279651963",
            "title": "Certificate Course in Gynocology and Obsetrics 20",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CGO",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b37cd39dd860f67d5e703",
            "examId": "648b498a3580232d0f0c315a",
            "isPublic": true,
            "courseType": "paid",
            "offerExpiry": {
                "days": 5,
                "hours": 3,
                "minutes": 30,
                "_id": "6491e51f011561f27965190c"
            },
            "prices": [
                {
                    "crossedPrice": 5000,
                    "offerPrice": 1499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491e51f011561f27965190d"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491e51f011561f27965190e"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491e51f011561f27965190f"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491e51f011561f279651910"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491e51f011561f279651911"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491e51f011561f279651912"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491e51f011561f279651913"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491e51f011561f279651914"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491e51f011561f279651915"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491e51f011561f279651916"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491e51f011561f279651917"
                }
            ],
            "createdAt": "2023-06-20T17:42:55.622Z",
            "updatedAt": "2023-06-20T17:42:55.622Z",
            "__v": 0
        },
        {
            "_id": "6491e51f011561f279651973",
            "title": "Certificate Course in Gynocology and Obsetrics 21",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CGO",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b37cd39dd860f67d5e703",
            "examId": "648b498a3580232d0f0c315a",
            "isPublic": false,
            "courseType": "paid",
            "offerExpiry": {
                "days": 5,
                "hours": 3,
                "minutes": 30,
                "_id": "6491e51f011561f27965190c"
            },
            "prices": [
                {
                    "crossedPrice": 5000,
                    "offerPrice": 1499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491e51f011561f27965190d"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491e51f011561f27965190e"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491e51f011561f27965190f"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491e51f011561f279651910"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491e51f011561f279651911"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491e51f011561f279651912"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491e51f011561f279651913"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491e51f011561f279651914"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491e51f011561f279651915"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491e51f011561f279651916"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491e51f011561f279651917"
                }
            ],
            "createdAt": "2023-06-20T17:42:55.622Z",
            "updatedAt": "2023-06-20T17:42:55.622Z",
            "__v": 0
        },
        {
            "_id": "6491e51f011561f279651983",
            "title": "Certificate Course in Gynocology and Obsetrics 22",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CGO",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b37cd39dd860f67d5e703",
            "examId": "648b498a3580232d0f0c315a",
            "isPublic": true,
            "courseType": "paid",
            "offerExpiry": {
                "days": 5,
                "hours": 3,
                "minutes": 30,
                "_id": "6491e51f011561f27965190c"
            },
            "prices": [
                {
                    "crossedPrice": 5000,
                    "offerPrice": 1499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491e51f011561f27965190d"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491e51f011561f27965190e"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491e51f011561f27965190f"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491e51f011561f279651910"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491e51f011561f279651911"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491e51f011561f279651912"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491e51f011561f279651913"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491e51f011561f279651914"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491e51f011561f279651915"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491e51f011561f279651916"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491e51f011561f279651917"
                }
            ],
            "createdAt": "2023-06-20T17:42:55.622Z",
            "updatedAt": "2023-06-20T17:42:55.622Z",
            "__v": 0
        },
        {
            "_id": "6491e51f011561f279651993",
            "title": "Certificate Course in Gynocology and Obsetrics 23",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CGO",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b37cd39dd860f67d5e703",
            "examId": "648b498a3580232d0f0c315a",
            "isPublic": true,
            "courseType": "paid",
            "offerExpiry": {
                "days": 5,
                "hours": 3,
                "minutes": 30,
                "_id": "6491e51f011561f27965190c"
            },
            "prices": [
                {
                    "crossedPrice": 5000,
                    "offerPrice": 1499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491e51f011561f27965190d"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491e51f011561f27965190e"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491e51f011561f27965190f"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491e51f011561f279651910"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491e51f011561f279651911"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491e51f011561f279651912"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491e51f011561f279651913"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491e51f011561f279651914"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491e51f011561f279651915"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491e51f011561f279651916"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491e51f011561f279651917"
                }
            ],
            "createdAt": "2023-06-20T17:42:55.622Z",
            "updatedAt": "2023-06-20T17:42:55.622Z",
            "__v": 0
        },
        {
            "_id": "6491e51f011561f279651103",
            "title": "Certificate Course in Gynocology and Obsetrics 24",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CGO",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b37cd39dd860f67d5e703",
            "examId": "648b498a3580232d0f0c315a",
            "isPublic": true,
            "courseType": "paid",
            "offerExpiry": {
                "days": 5,
                "hours": 3,
                "minutes": 30,
                "_id": "6491e51f011561f27965190c"
            },
            "prices": [
                {
                    "crossedPrice": 5000,
                    "offerPrice": 1499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491e51f011561f27965190d"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491e51f011561f27965190e"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491e51f011561f27965190f"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491e51f011561f279651910"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491e51f011561f279651911"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491e51f011561f279651912"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491e51f011561f279651913"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491e51f011561f279651914"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491e51f011561f279651915"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491e51f011561f279651916"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491e51f011561f279651917"
                }
            ],
            "createdAt": "2023-06-20T17:42:55.622Z",
            "updatedAt": "2023-06-20T17:42:55.622Z",
            "__v": 0
        },
        {
            "_id": "6491e51f011561f279651903",
            "title": "Certificate Course in Gynocology and Obsetrics 25",
            "description": "Produce a competent skill developed doctor who recognizes the",
            "memonic": "CGO",
            "thumbnailUrl": "https://raw.githubusercontent.com/e-MediSkill/eMediSkill-assets/main/cgo.png",
            "coursePointers": [
                "24/7 Lecture Access",
                "High-quality education",
                "Women's health expertise",
                "Self Paced Learning"
            ],
            "courseContentDescription": "<p>This is my course content description</p>",
            "validityDays": 65,
            "validityText": "2 months 5 days access",
            "cardDescription": "Online recorded video lectures will be provided",
            "cardPointers": [
                "Online MCQ examination",
                "Online MCQ examination"
            ],
            "cardIcons": [
                "certificate",
                "popular",
                "homeDelivery",
                "practicalTraining"
            ],
            "category": [
                "648b0cd7c9f690b6a88a45e2"
            ],
            "isLatest": true,
            "activeUsers": 2432,
            "faqsId": "648b37cd39dd860f67d5e703",
            "examId": "648b498a3580232d0f0c315a",
            "isPublic": false,
            "courseType": "paid",
            "offerExpiry": {
                "days": 5,
                "hours": 3,
                "minutes": 30,
                "_id": "6491e51f011561f27965190c"
            },
            "prices": [
                {
                    "crossedPrice": 5000,
                    "offerPrice": 1499,
                    "continent": "DEVELOPING_ASIA",
                    "_id": "6491e51f011561f27965190d"
                },
                {
                    "crossedPrice": 8000,
                    "offerPrice": 6250,
                    "continent": "REST_OF_ASIA",
                    "_id": "6491e51f011561f27965190e"
                },
                {
                    "crossedPrice": 4000,
                    "offerPrice": 3500,
                    "continent": "AFRICA",
                    "_id": "6491e51f011561f27965190f"
                },
                {
                    "crossedPrice": 12000,
                    "offerPrice": 7400,
                    "continent": "RUSSIA",
                    "_id": "6491e51f011561f279651910"
                },
                {
                    "crossedPrice": 15600,
                    "offerPrice": 12566,
                    "continent": "EUROPE",
                    "_id": "6491e51f011561f279651911"
                },
                {
                    "crossedPrice": 13000,
                    "offerPrice": 11000,
                    "continent": "NORTH_AMERICA",
                    "_id": "6491e51f011561f279651912"
                },
                {
                    "crossedPrice": 9800,
                    "offerPrice": 8555,
                    "continent": "SOUTH_AMERICA",
                    "_id": "6491e51f011561f279651913"
                },
                {
                    "crossedPrice": 13250,
                    "offerPrice": 10050,
                    "continent": "AUSTRALIA",
                    "_id": "6491e51f011561f279651914"
                }
            ],
            "courseModules": [
                {
                    "moduleTitle": "Introduction",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc1cea4fd0205e764321",
                        "6489bc3dea4fd0205e764325"
                    ],
                    "_id": "6491e51f011561f279651915"
                },
                {
                    "moduleTitle": "Human Body",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80"
                    ],
                    "_id": "6491e51f011561f279651916"
                },
                {
                    "moduleTitle": "Human Brain",
                    "videoDocIds": [
                        "6489b351d84131a4b610f2c7",
                        "6489bc3dea4fd0205e764325",
                        "6489bc8780a8756d4f456b80",
                        "6489bc1cea4fd0205e764321"
                    ],
                    "_id": "6491e51f011561f279651917"
                }
            ],
            "createdAt": "2023-06-20T17:42:55.622Z",
            "updatedAt": "2023-06-20T17:42:55.622Z",
            "__v": 0
        }
    ]
}


//Object to handle navigation
export const navigationData = [
    {
        "title" : "Back to Content",
        "route" : "/content?tab=courses",
        "default" : false,        
        "icon" : GoBackArrow
    },
    {
        "title" : "Details",
        "component" : "CourseDetails",
        "default" : false,        
        "icon" : EditPencilIcon
    },
    {
        "title" : "Content",
        "component" : "CourseContent",
        "default" : false,         
        "icon" : ModulesIcon
    },
    {
        "title" : "Pricing",
        "component" : "CoursePricing",
        "default" : false,         
        "icon" : PricingIcon
    },
    {
        "title" : "Blogs",
        "component" : "CourseBlogs",
        "default" : false,        
        "icon" : BlogIcon
    },
    {
        "title" : "SEO",
        "component" : "CourseSEO",
        "default" : false,        
        "icon" : SeoIcon
    },
    {
        "title" : "Analytics",
        "component" : "CourseAnalytics",
        "default" : false,        
        "icon" : AnalyticsIcon
    },
]
