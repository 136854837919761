import React, { useState } from "react";

import EditPaymentGatewayPopUpView from "./view";

function EditPaymentGatewayPopUp(props) {
  const [error, setError] = useState("");
  const [searchText, setSearchText] = useState("");
  const [paymentGateways, setPaymentGateways] = useState(props.paymentGateways);

  const handleGatewayCheck = (gateway, checked) => {
    if (checked) {
      //add
      gateway.discount = null;
      var updatedPaymentGateways = [...paymentGateways];
      updatedPaymentGateways.push(gateway);
      setPaymentGateways(updatedPaymentGateways);
    } else {
      //remove
      var updatedPaymentGateways = [...paymentGateways];
      updatedPaymentGateways = updatedPaymentGateways.filter(
        (pgateway) => pgateway.paymentGateway !== gateway.paymentGateway
      );

      setPaymentGateways(updatedPaymentGateways);
    }
  };

  const handleDiscountCheck = (gateway, checked) => {
    var newDiscountValue = null;
    if (checked) {
      newDiscountValue = 0;
    }

    var updatedPaymentGateways = [...paymentGateways];
    const index = updatedPaymentGateways.findIndex(
      (item) => item.paymentGateway === gateway.paymentGateway
    );
    if (index !== -1) {
      updatedPaymentGateways[index].discount = newDiscountValue;
    }
    setPaymentGateways(updatedPaymentGateways);
  };

  const handleDiscount = (event, gateway) => {
    if (event.target.value <= 100 && event.target.value >= 0) {
      var updatedPaymentGateways = [...paymentGateways];
      const index = updatedPaymentGateways.findIndex(
        (item) => item.paymentGateway === gateway.paymentGateway
      );
      if (index !== -1) {
        updatedPaymentGateways[index].discount = event.target.value;
      }
      setPaymentGateways(updatedPaymentGateways);
    } else {
      setError("Discount should be 0-100%");
      setTimeout(function () {
        setError("");
      }, 2000);
    }
  };

  const handleCloseBtn = () => {
    props.handleClose();
  };

  const handleSaveBtn = async (event) => {
    props.handleSave(paymentGateways);
  };

  return (
    <EditPaymentGatewayPopUpView
      error={error}
      searchText={searchText}
      paymentGateways={paymentGateways}
      allPaymentGateways={props.allPaymentGateways}
      handleCloseBtn={handleCloseBtn}
      handleSaveBtn={handleSaveBtn}
      handleSearch={setSearchText}
      handleGatewayCheck={handleGatewayCheck}
      handleDiscountCheck={handleDiscountCheck}
      handleDiscount={handleDiscount}
    />
  );
}

export default EditPaymentGatewayPopUp;
