import React from 'react';

import BasicTableGrid from '@components/BasicTableGrid';
import moment from 'moment';
import './style.css';

function DetailedTicketInformationView(props) {
	return (
		<>
			<div className='detailedTicketInformationView'>
				<div className="accordion" id="detailedTicketInformationAccordion">
					<div className="accordion-item">
						<h2 className="accordion-header">
							<button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#detailedTicketInformationCollapseOne">
								Detailed Ticket Information
							</button>
						</h2>
						<div id="detailedTicketInformationCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#detailedTicketInformationAccordion">
							<div className="accordion-body">

								<BasicTableGrid
									gridId='queryMain'
									// gridId='customerCareMain'
									data={props.queryDetails} // Data to be displayed
									fixedCols={0} // How many columns are fixed and not scrolling
									initialSize={[35,162,150,150,150,135,166]}
									// initialSorting={[{ "id": "Date Added", "desc": false }]}
									handleSelect={() => { }}
									length={20}

									onSortChange={(data) => { }
										// props.handleSort(data)
									}
									// columnsOptionPopUpRef={props.columnsOptionPopUpRef}
									columns={[
										{
											header: 'Agents touched',
											cell: (data) => {
												const info = data.row.original;
												return (
													<div className='agentsTouchedContainer'>
														{info.agentDetaiils && info.agentDetaiils.length > 0 && info.agentDetaiils.map((agent, index) => (
															<img src={agent.displayPhoto} alt='profileIcon' />
														))}
													</div>
												);
											}
										},
										{
											header: 'Status',
											cell: (data) => {
												const info = data.row.original;
												return (
													info.status
												);
											}
										},
										{
											header: 'Where?',
											cell: (data) => {
												const info = data.row.original;
												return (
													'https://emediskill.com/video?'+info.videoDocId
												);
											}
										},
										{
											header: 'Timestamp',
											cell: (data) => {
												const info = data.row.original;
												return (
													moment(info.timestamp).format('HH:mm')
												);
											}
										},
										{
											header: 'Type',
											cell: (data) => {
												const info = data.row.original;
												return (
													info.location
												);
											}
										},
										{
											header: 'Created By',
											cell: (data) => {
												const info = data.row.original;
												return (
													<div className='agentsTouchedContainer'>
													{info.createdBy? 
														<img src={info.createdBy.displayPhoto} alt='profileIcon' />: '_'
													}
												</div>
												);
											}
										},
									]
									}
								/>



								{/* <table class="table table-bordered">
									<thead>
										<tr>
											<th>Agents touched</th>
											<th>Status</th>
											<th>Where?</th>
											<th>Timestamp</th>
											<th>Type</th>
											<th>Created by</th>
										</tr>
									</thead>
								</table> */}
							</div>
						</div>
					</div>
				</div>


			</div>
		</>
	);
}

export default DetailedTicketInformationView;
