import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import ExamDetailsView from "./view";

import { produce } from "immer";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { decryptText } from "@library/enc-dec";
import { toHoursAndMinutes } from "@helpers/exam";

import * as ExamServices from "@services/Exam";
import * as ExamActions from "@redux/actions/Exam";
import Swal from "sweetalert2";

function ExamDetails(props) {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);
  const { id } = useParams();

  const [isDeclareResultImmediately, setIsDeclareResultImmediately] =
    useState(false);

  const [isHideResult, setIsHideResult] = useState(false);

  const [formValuesEmpty, setFormValuesEmpty] = useState(false);
  const [examThumbnail, setExamThumbnail] = useState(null);
  const [questionsSet, setQuestionsSet] = useState([]);

  const quillInstructionRef = useRef(null);
  const quillPassExamMessageRef = useRef(null);
  const quillFailedExamMessageRef = useRef(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [isFormSaving, setIsFormSaving] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    if (!id) {
      navigate("/exam");
      return;
    }
    getQuestionBank();
    return () => {
      setQuestionsSet([]);
      props.resetExamsReducer();
    };
  }, []);

  useEffect(() => {
    setExamThumbnail(props.thumbnailUrl);
  }, [props.thumbnailUrl]);

  const getQuestionBank = async () => {
    try {
      const questionBankId = id;

      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await ExamServices.getQuestionBank(
        questionBankId,
        authToken
      );
      if (response.success) {
        if (response.data.declarationResultInDays === 0) {
          setIsDeclareResultImmediately(true);
        }

        if (response.data.hideResultInDays > 0) {
          setIsHideResult(true);
        }
        setQuestionsSet(response.data.questionsSet);
        props.updateExamDetails(response.data);
      }
    } catch (err) {
      console.log("Error coming while geting question bank", err);
    }
  };

  const handleExamTitle = (event) => {
    props.updateExamTitle(event.target.value);
  };

  const handleDisplayQuestions = (event) => {
    props.updateDisplayQuestions(event.target.value);
  };

  const handleIncreaseDisplayQuestions = (event) => {
    let { displayQuestions } = props;
    displayQuestions++;
    props.updateDisplayQuestions(displayQuestions);
  };

  const handleDecreaseDisplayQuestions = (event) => {
    let { displayQuestions } = props;
    if (displayQuestions > 0) {
      displayQuestions--;
      props.updateDisplayQuestions(displayQuestions);
    }
  };

  const handleDuration = (event) => {
    const time = toHoursAndMinutes(Number(event.target.value));

    props.updateExamDuration(time);
  };

  const handlePassPercentage = (event) => {
    props.updateExamPassPercentage(event.target.value);
  };

  const handleIsDeclareResultImmediately = (event, value) => {
    if (value === "immediately") {
      setIsDeclareResultImmediately(true);
      props.updateResultInDays(0);
    } else {
      setIsDeclareResultImmediately(false);
    }
  };

  const handleDeclaredResultInDays = (event) => {
    if (!isDeclareResultImmediately) {
      props.updateResultInDays(event.target.value);
    }
  };

  const handleHideResultInDays = (event) => {
    props.updateHideResultInDays(event.target.value);
  };

  const handleIsHideResult = (event) => {
    if (!event.target.checked) {
      props.updateHideResultInDays(0);
    }
    setIsHideResult(event.target.checked);
  };

  const handleBulletIconChange = (bulletIcon) => {
    let updatedInstructions = produce(props.instructions, (draft) => {
      draft.icon = bulletIcon.icon;
    });

    props.updateExamInstructions(updatedInstructions);
  };

  const handleInstructions = (content) => {
    const editorInstance = quillInstructionRef.current.getEditor();
    const text = editorInstance.getText().trim();

    if (text.length !== 0) {
      let updatedInstructions = produce(props.instructions, (draft) => {
        draft["pointer"] = content;
      });

      props.updateExamInstructions(updatedInstructions);
      return;
    }

    let updatedInstructions = produce(props.instructions, (draft) => {
      draft["pointer"] = "";
    });

    props.updateExamInstructions(updatedInstructions);
  };

  const handlePassMessage = (content) => {
    const editorInstance = quillPassExamMessageRef.current.getEditor();
    const text = editorInstance.getText().trim();
    if (text.length !== 0) {
      props.updateExamPassedMessage(content);
      return;
    }
    props.updateExamPassedMessage("");
  };

  const handleFailedMessage = (content) => {
    const editorInstance = quillFailedExamMessageRef.current.getEditor();
    const text = editorInstance.getText().trim();
    if (text.length !== 0) {
      props.updateExamFailedMessage(content);
      return;
    }
    props.updateExamFailedMessage("");
  };

  const handleIsShuffleQuestions = (event) => {
    props.updateExamQuestionsInSequence(event.target.checked);
  };

  const handleThumbnailChange = (thumbnailObjs) => {
    setExamThumbnail(thumbnailObjs[0]);
  };

  const handleSave = () => {
    const { title, displayQuestions, duration, passPercentage } = props;
    const { failedMessage, passedMessage, instructions } = props;
    if (!title) {
      setFormValuesEmpty(true);
      setErrorMessage("Title field is empty");
      return;
    }
    if (!displayQuestions) {
      setFormValuesEmpty(true);
      setErrorMessage("Display Questions field is empty");
      return;
    }
    if (!duration) {
      setFormValuesEmpty(true);
      setErrorMessage("Duration field is empty");
      return;
    }
    if (!passPercentage) {
      setFormValuesEmpty(true);
      setErrorMessage("Pass Percentage field is empty");
      return;
    }
    if (!instructions) {
      setFormValuesEmpty(true);
      setErrorMessage("Instruction field is empty");
      return;
    }
    if (!instructions.pointer) {
      setFormValuesEmpty(true);
      setErrorMessage("Instruction Pointer is empty");
      return;
    }
    if (!instructions.icon) {
      setFormValuesEmpty(true);
      setErrorMessage("Instruction Icon is empty");
      return;
    }
    if (!passedMessage) {
      setFormValuesEmpty(true);
      setErrorMessage("Passed Message field is empty");
      return;
    }
    if (!failedMessage) {
      setFormValuesEmpty(true);
      setErrorMessage("Failed Message field is empty");
      return;
    }

    setFormValuesEmpty(false);

    if (id) {
      handleOldQuestionBankUpdate(id);
      return;
    }

    handleAddNewQuestionBank();
  };

  const handleOldQuestionBankUpdate = async (questionBankId) => {
    try {
      setIsFormSaving(true);
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        questionBankId: questionBankId,
        title: props.title,
        duration: props.duration,
        passPercentage: props.passPercentage,
        displayQuestions: props.displayQuestions,
        thumbnailUrl: examThumbnail ? examThumbnail : props.thumbnailUrl,
        declarationResultInDays: props.declarationResultInDays,
        hideResultInDays: props.hideResultInDays,
        instructions: props.instructions,
        passedMessage: props.passedMessage,
        failedMessage: props.failedMessage,
        inSequence: props.inSequence,
        questionsSet: questionsSet.map((question) => {
          return {
            question: question.question,
            options: question.options,
            answer: question.answer,
          };
        }),
      };

      const response = await ExamServices.updateQuestionBank(
        payload,
        authToken
      );
      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
      setIsFormSaving(false);
    } catch (err) {
      setIsFormSaving(false);
      console.log("Error coming while updating question bank", err);
    }
  };

  const handleAddNewQuestionBank = async () => {
    try {
      setIsFormSaving(true);
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        title: props.title,
        duration: props.duration,
        passPercentage: props.passPercentage,
        displayQuestions: props.displayQuestions,
        thumbnailUrl: examThumbnail ? examThumbnail : props.thumbnailUrl,
        declarationResultInDays: props.declarationResultInDays,
        hideResultInDays: props.hideResultInDays,
        instructions: props.instructions,
        passedMessage: props.passedMessage,
        failedMessage: props.failedMessage,
        inSequence: props.inSequence,
        questionsSet: [],
      };

      const response = await ExamServices.addQuestionBank(payload, authToken);
      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        navigate(`/exams/${response.exam._id}`);
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
      setIsFormSaving(false);
    } catch (err) {
      console.log("Error coming while adding question bank", err);
      setIsFormSaving(false);
    }
  };

  const duration = props.duration.hours * 60 + props.duration.minutes;

  return (
    <>
      <ExamDetailsView
        title={props.title}
        displayQuestions={props.displayQuestions}
        duration={duration}
        thumbnailUrl={props.thumbnailUrl}
        passPercentage={props.passPercentage}
        declareResultInDays={props.declarationResultInDays}
        hideResultInDays={props.hideResultInDays}
        instructions={props.instructions}
        passedMessage={props.passedMessage}
        failedMessage={props.failedMessage}
        isShuffleQuestions={props.inSequence}
        quillInstructionRef={quillInstructionRef}
        quillPassExamMessageRef={quillPassExamMessageRef}
        quillFailedExamMessageRef={quillFailedExamMessageRef}
        questionsSet={questionsSet}
        formValuesEmpty={formValuesEmpty}
        // Page handlers
        handleExamTitle={handleExamTitle}
        handleDisplayQuestions={handleDisplayQuestions}
        handleIncreaseDisplayQuestions={handleIncreaseDisplayQuestions}
        handleDecreaseDisplayQuestions={handleDecreaseDisplayQuestions}
        handleDuration={handleDuration}
        handlePassPercentage={handlePassPercentage}
        handleDeclaredResultInDays={handleDeclaredResultInDays}
        handleHideResultInDays={handleHideResultInDays}
        handleInstructions={handleInstructions}
        handlePassMessage={handlePassMessage}
        handleFailedMessage={handleFailedMessage}
        handleIsShuffleQuestions={handleIsShuffleQuestions}
        // helpers flag
        isHideResult={isHideResult}
        isDeclareResultImmediately={isDeclareResultImmediately}
        handleIsHideResult={handleIsHideResult}
        handleIsDeclareResultImmediately={handleIsDeclareResultImmediately}
        handleSave={handleSave}
        handleBulletIconChange={handleBulletIconChange}
        handleThumbnailChange={handleThumbnailChange}
        //START : Usestate & fn for questions

        errorMessage={errorMessage}
        isFormSaving={isFormSaving}
        examThumbnail={examThumbnail}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    allExams: state.exam.allExams,
    examsSet: state.exam.examsSet,
    title: state.exam.title,
    duration: state.exam.duration,
    displayQuestions: state.exam.displayQuestions,
    passPercentage: state.exam.passPercentage,
    thumbnailUrl: state.exam.thumbnailUrl,
    declarationResultInDays: state.exam.declarationResultInDays,
    hideResultInDays: state.exam.hideResultInDays,
    instructions: state.exam.instructions,
    passedMessage: state.exam.passedMessage,
    failedMessage: state.exam.failedMessage,
    inSequence: state.exam.inSequence,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateAllExams: ExamActions.updateAllExams,
      updateTotalNumOfExams: ExamActions.updateTotalNumOfExams,
      updateExamsSet: ExamActions.updateExamsSet,
      updateExamDetails: ExamActions.updateExamDetails,
      updateExamTitle: ExamActions.updateExamTitle,
      updateExamDuration: ExamActions.updateExamDuration,
      updateExamPassPercentage: ExamActions.updateExamPassPercentage,
      updateDisplayQuestions: ExamActions.updateDisplayQuestions,
      updateExamThumbnailUrl: ExamActions.updateExamThumbnailUrl,
      updateResultInDays: ExamActions.updateResultInDays,
      updateHideResultInDays: ExamActions.updateHideResultInDays,
      updateExamInstructions: ExamActions.updateExamInstructions,
      updateExamPassedMessage: ExamActions.updateExamPassedMessage,
      updateExamFailedMessage: ExamActions.updateExamFailedMessage,
      updateExamQuestionsInSequence: ExamActions.updateExamQuestionsInSequence,
      resetExamsReducer: ExamActions.resetExamsReducer,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExamDetails);
