import React from "react";

import { useLocation } from 'react-router-dom';

import CourseCategoryHomeView from './view';
import InnerSidebarNavigation from '@components/InnerSidebarNavigation'
import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';

function CourseCategoryHome() {
    const location = useLocation();

    //Failsafe if no data received from navigation
    let navigationData = [{
        "title": "Back to Content",
        "route": "/content",
        "icon": GoBackArrow
    }];
    if (location.state && location.state.navigationData) {
        navigationData = location.state.navigationData;
    }
    
    return (
        <>
            <InnerSidebarNavigation
                navigationData={navigationData} />
            <CourseCategoryHomeView />
        </>
    )
}

export default CourseCategoryHome;