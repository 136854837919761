import React from 'react';
import { Link } from 'react-router-dom';

import './examDetails.css';

import RoundedWhitePlusIcon from '@assets/common/roundedWhitePlusIcon.png';

import Question from '@components/Question';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import DeleteIcon from '@assets/common/deleteIcon.png';
import PlusCircleIcon from '@assets/common/plusCircleIcon.png';

//DnD Import
import DnD from '@components/DnD';

//Imports for RichEditor
import SaveBtn from '@components/SaveBtn'
import NumberedTextArea from '@components/NumberedTextArea';

function ExamQuestionsView(props) {
	return (
		<>
			<div className='rightHandRightPageMainContainer d-flex flex-column'>
				<div className='marginLeftContainer border-bottom ms-0'>
					<div className='topContainer'>
						<h4 className='heading'>Exam Details</h4>
						<div>
							<Link
								style={{ textDecoration: 'none' }}
								className='cancelBtn'
								to='/content?tab=exams'>
								Cancel
							</Link>

							<SaveBtn onClick={props.handleSave}
							  text={"Save"}
							  altText={"Saving..."}
							  disabled={props.isFormSaving}
							/>
						</div>
					</div>

					{/* START : Error container */}
					{props.formValuesEmpty && (
						<div className='incompleteFieldsError'>
							<span>{props.errorMessage}</span>
						</div>
					)}
					{/* END : Error container */}

				</div>

				{/* START : Questions */}

				<div className='marginLeftContainer flex-grow-1 overflow-scroll py-3 me-0'>
					<h4 className='subHeading'>
						Questions {props.questionsSet && '(' + props.questionsSet.length + ')'}
					</h4>

					<div className="CSVDisplay pb-3 me-3">
							<NumberedTextArea 
									value={props.csv}
									onChange={props.handleCsvChange}
								/>
					</div>

					<DragDropContext onDragEnd={props.onDragEnd}>
						<Droppable droppableId='dataSet'>
							{(provided) => (
								<div ref={provided.innerRef} {...provided.droppableProps}>
									{props.questionsSet.map((question, index) => (
										<>
											<DnD
												element={question}
												index={index}
												newComponent={
													<div className='d-flex gap-2 align-items-center'>
														<Question
															questionContent={question}
															onValueChange={(question) =>
																props.handleQuestionUpdate(question, index)
															}
														/>
														<img onClick={() => props.handleQuestionDelete(index)}
															style={{width: '20px', height:'20px', cursor:'pointer'}}
															src={DeleteIcon}
															alt='DeleteIcon'
														/>
														<img onClick={(event) => {props.handleAddQuestion(event, index+1)}}

															style={{width: '20px', height:'20px', cursor:'pointer'}}
															src={PlusCircleIcon}
															alt='PlusCircleIcon'
														/>
													</div>
												}
											/>
										</>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>

					<center style={{marginBottom:'10%'}}>
						<button
							onClick={(event) => {
								props.handleAddQuestion(event, props.questionsSet.length);
							}}
							className='addQuestionBtn'>
							<img
								className='roundedWhitePlusIcon'
								src={RoundedWhitePlusIcon}
								alt='RoundedWhitePlusIcon'
							/>
							Add Question
						</button>
					</center>
				</div>

				{/* END : Questions */}
			</div>
		</>
	);
}

export default ExamQuestionsView;
