export const updateAllVideos = (value) => {
	return {
		type: 'UPDATE_ALL_VIDEOS',
		payload: {
			allVideos: value,
		},
	};
};

export const updateTotalNumOfVideos = (value) => {
	return {
		type: 'UPDATE_TOTAL_NUMBER_OF_VIDEOS',
		payload: {
			totalNumberOfVideos: value,
		},
	};
};

export const updateVideoSet = (value) => {
	return {
		type: 'UPDATE_VIDEO_SET',
		payload: {
			videoSet: value,
		},
	};
};

export const updateVideoDetails = (value) => {
	return {
		type: 'UPDATE_VIDEO_DETAILS',
		payload: {
			video: value,
		},
	};
};

export const updateVideoTitle = (value) => {
	return {
		type: 'UPDATE_VIDEO_TITLE',
		payload: {
			title: value,
		},
	};
};

export const updateVideoDescription = (value) => {
	return {
		type: 'UPDATE_VIDEO_DESCRIPTION',
		payload: {
			description: value,
		},
	};
};

export const updateVideoLink = (value) => {
	return {
		type: 'UPDATE_VIDEO_LINK',
		payload: {
			videoLink: value,
		},
	};
};

export const updateThumbnailUrl = (value) => {
	return {
		type: 'UPDATE_THUMBNAIL_URL',
		payload: {
			thumbnailUrl: value,
		},
	};
};

export const updateAdditionalInfo = (value) => {
	return {
		type: 'UPDATE_ADDITIONAL_INFO',
		payload: {
			additionalInfo: value,
		},
	};
};

export const updateHideLikes = (value) => {
	return {
		type: 'UPDATE_HIDE_LIKES',
		payload: {
			hideLikes: value,
		},
	};
};

export const updateHideDislikes = (value) => {
	return {
		type: 'UPDATE_HIDE_DISLIKES',
		payload: {
			hideDislikes: value,
		},
	};
};

export const updateCommentsStatus = (value) => {
	return {
		type: 'UPDATE_COMMENTS_STATUS',
		payload: {
			commentsStatus: value,
		},
	};
};

export const updateIsPublic = (value) => {
	return {
		type: 'UPDATE_IS_PUBLIC',
		payload: {
			isPublic: value,
		},
	};
};

export const resetVideosReducer = (value) => {
	return {
		type: 'RESET_VIDEOS_REDUCER',
	};
};

export const updatePageNo = (value) => {
	return {
		type: 'UPDATE_PAGE_NUMBER',
		payload: {
			pageNo: value,
		},
	};
};

export const updateIsSorted = (value) => {
	return {
		type: 'UPDATE_VIDEO_IS_SORTED',
		payload: {
			isSorted: value,
		},
	};
};

export const updateSearchText = (value) => {
	return {
		type: 'UPDATE_VIDEO_SEARCH_TEXT',
		payload: {
			searchText: value,
		},
	};
};

export const updateFilterInformation = (value) => {
	return {
		type: 'UPDATE_FILTER_INFORMATION',
		payload: {
			filterInformation: value,
		},
	};
};
