
export const resetAdminUsersReducer = () => {
	return {
		type: 'RESET_ADMIN_USERS_REDUCER',
	};
};

export const updateFilters = (value) => {
	return {
		type: 'UPDATE_ADMIN_USER_FILTERS',
		payload: {
			filters: value,
		},
	};
};