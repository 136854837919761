import React, { useEffect, useState } from 'react';

import EditExamPopUpView from './view';

import * as CourseServices from '@services/Course';
import { decryptText } from '@library/enc-dec';

function EditExamPopUp(props) {
	const [error, setError] = useState('');
    const [searchText, setSearchText] = useState('');
	const [selectedExam, setSelectedExam] = useState(props.selectedExam);

	const handleExamChange=(id)=>{
      setSelectedExam(id)
	}

	const handleCloseBtn = () => {
		props.handleClose()
	};

	const handleSaveBtn = async (event) => {
		if(props.courseId)
		{
			try {
				let authToken = decryptText(localStorage.getItem('aEmediat'));
				
				const payload = {
					courseId: props.courseId,
					examId : selectedExam
				}
				const response = await CourseServices.updateCourseExamId(payload, authToken);
					
				if(response.success)
				{
					props.handleSave(event)
				}
				else{
				alert(response.message);
				}
			} catch (err) {
				console.log('Error coming while updating course exam', err);
			}

		}
		event.target.value = selectedExam 
		props.handleSave(event)
	};

	return (
		<EditExamPopUpView
				error={error}
				searchText={searchText}
				selectedExam={selectedExam}
				allExams={props.allExams}
				handleCloseBtn={handleCloseBtn}
				handleSaveBtn={handleSaveBtn}
				handleSearch = {setSearchText}
				handleExamChange = {handleExamChange}
			/>
	);
}

export default EditExamPopUp;
