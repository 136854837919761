import React from 'react';

import './style.css';
import BasicTableGrid from '@components/BasicTableGrid';
import DateAndUser from '@components/DateAndUser';
import ButtonBars from '@components/ButtonBars';
import PopUp from '@components/PopUp';
import Search from '@components/Search';

import ExpandIcon from '@assets/userDashboard/expand.png';
import SettingIcon from '@assets/userDashboard/setting.png';
import ColumnsIcon from '@assets/userDashboard/columns.png';
import DownIcon from '@assets/common/dropDown.png';

import SelectionPopUp from '@components/SelectionPopUp';
import ActiveSearchFilters from '@components/ActiveSearchFilters';

const roleList = [{_id:'standard', name:'Standard'},{_id:'admin', name:'Admin'}]
const requestList = [{_id:'moveToArchive',name:'Move to archive'},
					{_id:'disableAccount',name:'Disable account'},
					{_id:'enableCourse',name:'Enable Courses'},
					{_id:'deleteCourse',name:'Delete course'},
					{_id:'Replace Course',name:'Replace Course'},
					{_id:'createBSSForm',name:'Create BSS form'}]

const pageList = [{_id:'users',name:'Users'}]
const statusList = [{_id:'approved',name:'Approved'},{_id:'rejected',name:'Rejected'},{_id:'pending',name:'Pending'}]

function Approvals(props){
    return(
        <div className='AdminPageMainContainer bg-white' ref={props.fullscreenElementRef}>
			<div style={{position:'absolute', marginTop: '-72px', marginLeft:'170px'}}>
				<ActiveSearchFilters
					 filters = { [ 
						{'icon': 'search', value: props.filters.searchText},
						{'icon': 'calender',value: (props.filters.startDate || props.filters.endDate)? `${props.filters.startDate} - ${props.filters.endDate}` :''},
						{'icon': '', value: props.filters.adminType? `Admin: (${props.filters.adminType})`:''},
						{'icon': '', value: props.filters.requestType?`Request: (${props.filters.requestType})`:''},
						{'icon': '', value: props.filters.pageType? `Where?: (${props.filters.pageType})`:''},
						{'icon': '', value: props.filters.requestStatus?`Status: (${props.filters.requestStatus})`:''},
					 ]}
					 handleClick={props.handleClearFilter}
					/>
					</div>
            <Search
					value={props.filters.searchText}
					startDate={props.filters.startDate}
					endDate={props.filters.endDate}
					placeholder = {"Search approvals"}
					isFilterEnabled={true}

					filters = {[{placeholder:'Select Admin',value:props.searchFilters.adminType? roleList.filter(obj=> obj._id===props.searchFilters.adminType)[0]?.name:'', icon: DownIcon},
								{placeholder:'Request Type',value:props.searchFilters.requestType?requestList.filter(obj=> obj._id===props.searchFilters.requestType)[0]?.name:'', icon: DownIcon},
								{placeholder:'Where',value:props.searchFilters.pageType?pageList.filter(obj=> obj._id===props.searchFilters.pageType)[0]?.name:'', icon: DownIcon},
								{placeholder:'Status',value:props.searchFilters.requestStatus?statusList.filter(obj=> obj._id===props.searchFilters.requestStatus)[0]?.name:'', icon: DownIcon}]}
					handleFilter={props.handleFilterPopUp}
					isTagFilterEnabled={false}
					onSearch ={(data)=>props.handleSearch(data)}
					/>
            <div className='d-flex align-items-center w-100 justify-content-between'>
					<ButtonBars
						buttons={[{type: 'round',
                                    text: '+',
                                    onClick: (event) => {
                                        props.handlePopUpOpen(event, "createNew");
                                    },
                                },
						]}
					/>
					<div className='d-flex gap-2 controlsRight me-3'>
						<button onClick={props.toggleFullscreen}>
							<img src={ExpandIcon}></img>
							<br></br>
							Expand
						</button>
						<button onClick={(event)=>props.handlePopUpOpen(event, "setting")}>
							<img src={SettingIcon}></img>
							<br></br>
							Setting
						</button>
						<button onClick={props.handleColumnBtnClick}>
							<img src={ColumnsIcon}></img>
							<br></br>
							Columns
						</button>
					</div>
            </div>

            <BasicTableGrid 
				gridId='Approvals'
				data={props.deferredData} // Data to be displayed
				fixedCols={3} // How many columns are fixed and not scrolling
				initialSize={[35,152,172,138,202,150,150,362,150]}
				initialSorting={[{"id": "Date/Time","desc": props.filters.isSorted}]}
				handleSelect={(selectedRows) => {props.handleCheckBoxes(selectedRows);}}
				length={20}
				onSortChange={(data) =>{props.handleSort(data)}}
				columnsOptionPopUpRef={props.columnsOptionPopUpRef}
				columns={[
                    {header: 'Admin Name',
					cell: (data) => {
					const info = data.row.original;
					return (<div className="d-flex gap-2 w-100" style={{cursor:'pointer'}} onClick={(e)=>props.handleRedirectToRequestPage(e, info._id)}>
                                {false ? <div className="thumbnailContainer" style={{backgroundImage:`url(${info.agentDisplayPhoto})`}}>
                                    </div>
                                : null}
                                {info.agentName}</div>);}
				},
				{header: 'Username', cell : (data)=>{return data.row.original.agentUsername}},
				{header: 'Request Type', cell : (data)=>{return data.row.original.requestType}},
				{header: 'Date/Time', accessorFn: (row) => {},
				cell: (data) => {
					   const info = data.row.original;
					   return <DateAndUser date = {info.createdAt} image = {''} imageText = {''}/>},
			   },
				{header: 'Status', 
				 cell : (data)=>{
					 let status = data.row.original.status
					 return <div className='rounded text-white p-1'
					             style={status==='approved'?{backgroundColor:'#23B100'}:status==='rejected'?{backgroundColor:'#E30000'}:{backgroundColor:'#FFCF56', cursor:'pointer'}}  
					           onClick={(event)=>props.handleStatusClick(event, status, data.row.original)}
							>{status?.charAt(0).toUpperCase() + status?.slice(1)}</div>
					}},
                {header: 'Where?', cell : (data)=>{return data.row.original.location}},
                {header: 'Description', cell : (data)=>{return data.row.original.description}},
                {header: 'Users effected', cell : (data)=>{
					let userCount =data.row.original?.users?.length
					return <div className={userCount>1?'rounded-circle px-2 py-1 bg-danger text-white':''}>{userCount}</div>
				}},
					]}
				/>

				{props.popUp === "requestStatus"  && <PopUp visible={props.isPopUpVisible}
						top={props.popUpPosition.top}
						left={props.popUpPosition.left}
						handleOutSideClick={props.handlePopUpClose}
						>
						<SelectionPopUp 
						data = {[{_id : 'Approve', name: 'Approve'},
						      {_id : 'Reject', name: 'Reject'}
						]}
						optionKey= {"name"}
						title={""}
						searchPlaceholder={""}
						isSearchEnabled={false}
						height= {"auto"}
						width= {"auto"}
						selectedItems={[]}
						dataKey={"_id"}
						selectedItemsKey={"_id"}
						selectionType={"single"}
						handleCancel ={props.handlePopUpClose}
						handleSave={props.handlePopUpSave}/>
					</PopUp>}

				<div  style={{zIndex:'9999999999999999999999'}}>
				{props.popUp === "filterAdmin"  && <PopUp visible={props.isPopUpVisible}
					top={props.popUpPosition.top}
					left={props.popUpPosition.left}
					handleOutSideClick={props.handlePopUpClose}
					>
					<SelectionPopUp 
					data = {roleList}
					optionKey= {"name"}
					title={""}
					searchPlaceholder={""}
					isSearchEnabled={false}
					height= {"auto"}
					width= {"auto"}
					selectedItems={[]}
					dataKey={"_id"}
					selectedItemsKey={"_id"}
					selectionType={"single"}
					handleCancel ={props.handlePopUpClose}
					handleSave={props.handlePopUpSave}/>
				</PopUp>}					
				{props.popUp === "filterRequestType"  && <PopUp visible={props.isPopUpVisible}
						top={props.popUpPosition.top}
						left={props.popUpPosition.left}
						handleOutSideClick={props.handlePopUpClose}
						>
						<SelectionPopUp 
						data = {requestList}
						optionKey= {"name"}
						title={""}
						searchPlaceholder={""}
						isSearchEnabled={false}
						height= {"auto"}
						width= {"auto"}
						selectedItems={[]}
						dataKey={"_id"}
						selectedItemsKey={"_id"}
						selectionType={"single"}
						handleCancel ={props.handlePopUpClose}
						handleSave={props.handlePopUpSave}/>
					</PopUp>}					
				{props.popUp === "filterPageType"  && <PopUp visible={props.isPopUpVisible}
						top={props.popUpPosition.top}
						left={props.popUpPosition.left}
						handleOutSideClick={props.handlePopUpClose}
						>
						<SelectionPopUp 
						data = {pageList}
						optionKey= {"name"}
						title={""}
						searchPlaceholder={""}
						isSearchEnabled={false}
						height= {"auto"}
						width= {"auto"}
						selectedItems={[]}
						dataKey={"_id"}
						selectedItemsKey={"_id"}
						selectionType={"single"}
						handleCancel ={props.handlePopUpClose}
						handleSave={props.handlePopUpSave}/>
					</PopUp>}					
				{props.popUp === "filterStatus"  && <PopUp visible={props.isPopUpVisible}
						top={props.popUpPosition.top}
						left={props.popUpPosition.left}
						handleOutSideClick={props.handlePopUpClose}
						>
						<SelectionPopUp 
						data = {statusList}
						optionKey= {"name"}
						title={""}
						searchPlaceholder={""}
						isSearchEnabled={false}
						height= {"auto"}
						width= {"auto"}
						selectedItems={[]}
						dataKey={"_id"}
						selectedItemsKey={"_id"}
						selectionType={"single"}
						handleCancel ={props.handlePopUpClose}
						handleSave={props.handlePopUpSave}/>
					</PopUp>}
				</div>
        </div>
    )
}

export default Approvals