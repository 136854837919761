export const pricingDefaultData = {
  "courseId": "",
  "courseType": "paid",
  "offerExpiry": {
      "days": 0,
      "hours": 0,
      "minutes": 0
  },
  "prices": {
      "DEVELOPING_ASIA": {
          "crossedPrice": 0,
          "offerPrice": 0,
          "continent": "DEVELOPING_ASIA"
      },
      "REST_OF_ASIA": {
          "crossedPrice": 0,
          "offerPrice": 0,
          "continent": "REST_OF_ASIA"
      },
      "AFRICA": {
          "crossedPrice": 0,
          "offerPrice": 0,
          "continent": "AFRICA"
      },
      "RUSSIA": {
          "crossedPrice": 0,
          "offerPrice": 0,
          "continent": "RUSSIA"
      },
      "EUROPE": {
          "crossedPrice": 0,
          "offerPrice": 0,
          "continent": "EUROPE"
      },
      "NORTH_AMERICA": {
          "crossedPrice": 0,
          "offerPrice": 0,
          "continent": "NORTH_AMERICA"
      },
      "SOUTH_AMERICA": {
          "crossedPrice": 0,
          "offerPrice": 0,
          "continent": "SOUTH_AMERICA"
      },
      "AUSTRALIA": {
          "crossedPrice": 0,
          "offerPrice": 0,
          "continent": "AUSTRALIA"
      }
  },
  "installmentsApplied": false,
  "installments": []
}