import React, { useEffect, useState } from 'react';

import PopUpContainer from '@components/PopUpContainer';

import ChangeInstitutePopUpView from './view';

function ChangeInstitutePopUp(props) {
	const [selectedInstitute, setSelectedInstitute] = useState(null)
	const [searchValue, setSearchValue] = useState('')
	const handleCancel=()=>{
		props.handleCancel()
	}
	const handleSave=()=>{
		props.handleSave(selectedInstitute)
	}

	const handleSearch=(value)=>{
      setSearchValue(value)
	}

	const handleInstituteSelect=(Institute)=>{
		setSelectedInstitute(Institute)
	}
	return (
		<ChangeInstitutePopUpView
		selectedInstitute={selectedInstitute}
		allInstitute = {props.allInstitute}
		searchValue={searchValue}	
		handleInstituteSelect={handleInstituteSelect}
		handleSearch={handleSearch}
		handleCancel ={handleCancel}
		handleSave={handleSave}
			/>
	);
}

export default ChangeInstitutePopUp;
