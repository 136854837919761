const initialState = {
	allFaqs: [],
	totalNumberOfFaqs: 0,
	faqSet: [],
	pageNo: 0,
	filterInformation:{page: 0,
		limit:20,
		sortBy: '',
		isSorted: true,
		searchText: ''}
};

const FaqReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_ALL_FAQS':
			return {
				...state,
				allFaqs: action.payload.allFaqs,
			};
		case 'UPDATE_TOTAL_NUMBER_OF_FAQS':
			return {
				...state,
				totalNumberOfFaqs: action.payload.totalNumberOfVideos,
			};
		case 'UPDATE_FAQ_SET':
			return {
				...state,
				faqSet: action.payload.faqSet,
			};
		case 'UPDATE_FAQ_PAGE_NUMBER':
			return {
				...state,
				pageNo: action.payload.pageNo,
			};
		case 'UPDATE_FAQ_FILTER_INFORMATION':
			return {
				...state,
				filterInformation: action.payload.filterInformation,
			};
		default:
			return state;
	}
};

export default FaqReducer;
