import React from "react";
import ProductPageView from './view';
import { getEventElementPosition} from '@helpers/common.js';
import { useState, useEffect, useRef } from 'react';
import { decryptText } from '@library/enc-dec';
import * as CoursesServices from '@services/Course';

var editorTextEdit = false;
function ProductPage(props){

   const [courseDetails, setCourseDetails] = useState(props.courseDetails);
	const infoPointerEditorRef = useRef(null);
   
   const [popUpType, setPopUpType] = useState(null);
   const [popUpPosition, setPopUpPosition] = useState({top:0, left:0});
	const [isSelectFAQPopUpVisible, setIsSelectFAQPopUpVisible] = useState(false);
	const [isImageUploadPopUpVisible, setIsImageUploadPopUpVisible] = useState(false);
	const [isReviewPopUpVisible, setIsReviewPopUpVisible] = useState(false);

   const [selectedReview, setSelectedReview]= useState(null)
   
   useEffect(()=>{
      setCourseDetails(props.courseDetails)
   },[props.courseDetails])

   useEffect(()=>{
      props.handleFormDataChange(courseDetails)
   },[courseDetails])

   const handleChange= async(value, field)=>{
      var updatedCourseDetails = {...courseDetails}
      if(field==='infoParagraphIcon')
      {
         if(updatedCourseDetails.infoParagraph===null || typeof(updatedCourseDetails.infoParagraph)==='string'){
            updatedCourseDetails.infoParagraph= {pointer: "", icon: ""}
         }
            updatedCourseDetails.infoParagraph.icon = value.icon
      }
      else if(field=='infoParagraphCheck'){
         value? 
         updatedCourseDetails.infoParagraph={"icon": "","pointer": ""}: 
         updatedCourseDetails.infoParagraph=null
      }
      else if(field=='imageUpload')
      {
         if(popUpType==='certificateThumbnailUrl')
         {
            updatedCourseDetails.showCertificate = true
            updatedCourseDetails.certificateThumbnailUrl = value[0]
            setIsImageUploadPopUpVisible(false)
         }
         if(popUpType==='reviewImageEdit')
         {
            var reviewImageUrl = await uploadReviewImage(value[0])
               handleSelectedReviewUpdate('displayPhoto', reviewImageUrl)
               setIsReviewPopUpVisible(true)
               setIsImageUploadPopUpVisible(false)
            
         }
      }
      else if(field=='previewVideoLinkCheck'){
         value?  
         updatedCourseDetails.previewVideoLink = '':
         updatedCourseDetails.previewVideoLink = null;
      }
      else if(field=='previewVideoLink'){
         if(value=="")
         {
            value=null
         }  
         updatedCourseDetails[field] = value
      }
      else if(field=='faqsId'){
         updatedCourseDetails[field] = value
         handlePopUpClose()
      }
      else if(field=='reviews')
      {
         updatedCourseDetails.reviews[selectedReview.index] = selectedReview.review
         handlePopUpClose()
      }
      else{
         updatedCourseDetails[field] = value
      }

      setCourseDetails(updatedCourseDetails)
    }

    const handleInfoPointers = (content) => {
      var updatedCourseDetails = {...courseDetails}
      if(updatedCourseDetails.infoParagraph && editorTextEdit)
      {
         const editorInstance = infoPointerEditorRef.current.getEditor();
         const text = editorInstance.getText().trim();
         
         if(typeof(updatedCourseDetails.infoParagraph) === 'string' || updatedCourseDetails.infoParagraph===null)
         {

            updatedCourseDetails.infoParagraph = {pointer: content, icon: ""}
         }
         if (text.length !== 0) {
            updatedCourseDetails.infoParagraph.pointer = content;
            setCourseDetails(updatedCourseDetails);
            editorTextEdit = false;
         }
         
        if(text?.length === 0){
         updatedCourseDetails.infoParagraph.pointer = "";
         setCourseDetails(updatedCourseDetails);
         editorTextEdit = false;
       }
      }

      if(updatedCourseDetails.infoParagraph === null)
         {
            updatedCourseDetails.infoParagraph = {pointer: "", icon: ""}
            setCourseDetails(updatedCourseDetails);
         editorTextEdit = false;
         }
    };

    const handleEditorTextEdit = (keyType) => {
      if (!editorTextEdit) {
        editorTextEdit = true;
      }
    };

   const handlePopUpClose = () => {
		setIsImageUploadPopUpVisible(false);
      setIsSelectFAQPopUpVisible(false);
      setIsReviewPopUpVisible(false)
	};
	// To handleSave buttons on the popup
	const handlePopUpSave = async () => {
		handlePopUpClose();
	};
	// Open popUps
	const handleOpenPopUp = (event, popUp, data) => {
		handlePopUpClose();
      setPopUpType(popUp)
      switch(popUp){
       case 'certificateThumbnailUrl':
         if(courseDetails.infoParagraph!=null) 
           setIsImageUploadPopUpVisible(true)
         break
       case 'faqsId': 
           setIsSelectFAQPopUpVisible(true)
         break
       case 'reviewEdit':
         setIsReviewPopUpVisible(true)
         setSelectedReview(data)
         break;
       case 'reviewImageEdit':
         setIsImageUploadPopUpVisible(true)
         break;
      }
      let position = getEventElementPosition(event);
		setPopUpPosition(position);
		event.stopPropagation();
    }
   // Reviews
   const handleAddReview = (event, index) => {
      var updatedCourseDetails = {...courseDetails}
		const reviewContent = {
			_id: `Review ${updatedCourseDetails.reviews.length + 1}`,
			name: 'User Name',
			displayPhoto: '',
         reviewText: '' 
		};
      if(index!=null)
      {
         updatedCourseDetails.reviews.splice(index+1, 0, reviewContent);
      }
      else{
      updatedCourseDetails.reviews = [...updatedCourseDetails.reviews, reviewContent]
      }

		setCourseDetails(updatedCourseDetails);
	};

	const handleDeleteReview = (reviewId) => {
      var updatedCourseDetails = {...courseDetails}

		updatedCourseDetails.reviews = updatedCourseDetails.reviews.filter(
			(_review) => _review._id.toString() !== reviewId.toString()
		);
		setCourseDetails(updatedCourseDetails);
	};

   const onReviewDragEnd = (result) => {
		if (!result.destination) return;
      const updatedCourseDetails = {...courseDetails};
      const [reorderedItem] = updatedCourseDetails.reviews.splice(result.source.index, 1);
      updatedCourseDetails.reviews.splice(result.destination.index, 0, reorderedItem);
      setCourseDetails(updatedCourseDetails);
	};

   const handleReviewUpdate=(field, value, index)=>{
      var updatedCourseDetails = {...courseDetails}
      updatedCourseDetails.reviews[index][field] =value
      setCourseDetails(updatedCourseDetails)
   }
   const handleSelectedReviewUpdate=(field, value)=>{
      var updatedReview = {...selectedReview}
      updatedReview.review[field] =value
      setSelectedReview(updatedReview)
   }

   const uploadReviewImage =async(image)=>{
      try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
         const payload={
            'displayPhoto':image
         }
			let response = await CoursesServices.updateReviewsDisplayPhoto(payload, authToken);
			if (response.success) {
				return response.data.displayPhoto;
			}
			alert(response.message);
		} catch (err) {
			console.log('Error coming while fetching course details', err);
		}
   }
   const debounce = (func, delay) => {
      let timerId;
      return (...args) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          func(...args);
        }, delay);
      };
    };
  
    const debounceHandleInfoPointers = debounce(handleInfoPointers, 1000);
  
    
   return(
    <ProductPageView 
      courseDetails= {courseDetails}
      handleChange={handleChange}

      infoPointerEditorRef={infoPointerEditorRef}
      handleInfoPointers={debounceHandleInfoPointers}
      handleEditorTextEdit={handleEditorTextEdit}
      allFAQS={props.allFAQS}

      popUpPosition={popUpPosition}
      isSelectFAQPopUpVisible={isSelectFAQPopUpVisible}
      isImageUploadPopUpVisible={isImageUploadPopUpVisible}
      isReviewPopUpVisible={isReviewPopUpVisible}
      handlePopUpClose={handlePopUpClose}
      handlePopUpSave={handlePopUpSave}
      handleOpenPopUp={handleOpenPopUp}

      //Review
      handleAddReview={handleAddReview}
      handleDeleteReview={handleDeleteReview}
      onReviewDragEnd={onReviewDragEnd}
      handleReviewUpdate={handleReviewUpdate}
      selectedReview={selectedReview}
      handleSelectedReviewUpdate={handleSelectedReviewUpdate}
      />
   )

}

export default ProductPage;