import React from 'react';
import './style.css';
import DragDropFileInput from '@components/DragDropFileInput';
import SearchIcon from '@assets/common/search.png'
import CloseIcon from '@assets/common/blackCrossIcon.png'

function ImageInputView(props) {

    return (
        <div className="imageInputDiv">
            <div className='imageInputHeader'>
                <div>Image Library</div>
                <img src={CloseIcon} onClick={props.handleClose}></img>
            </div>
            <div className='imageInputBody'>
                <div className="search">
                    <input
                        onKeyDown={(event) => props.handleSearchChange(event, 'onKeyDown')}
                        onChange={(event) => props.handleSearchChange(event, 'onChange')}
                        type="text" placeholder='Search for an image'></input>
                    <img src={SearchIcon}></img>
                </div>

                <div className="drag-area" style={{ position: 'relative', minHeight: '300px' }}
                    onDragOver={props.handleDragOver}
                // onMouseEnter={props.handleDragOver}
                >
                    <div className="d-flex flex-wrap thumbnailDiv">
                        {props.fileList.length > 0 ?
                            (
                                <>
                                    {props.fileList.map((file, index) => {
                                        return (
                                            <div className="thumbnail" style={{ backgroundImage: `url(${URL.createObjectURL(file)})` }}>
                                                <img src={CloseIcon}
                                                    className="closeIcon"
                                                    onClick={(event) => props.handleRemoveImage(event, index)}></img>
                                            </div>
                                        )
                                    })}
                                </>
                            ) : (
                                <>
                                    {props.isLoading ?
                                        (
                                            <div className='centerDiv'>
                                                <img src='https://i.gifer.com/ZKZg.gif' alt='loader' /> loading images...
                                            </div>
                                        ) : (
                                            <>
                                                {props.awsListOfImages && props.awsListOfImages.length > 0 ?
                                                    (
                                                        <>
                                                            {props.awsListOfImages.map((image, index) => (
                                                                <>
                                                                    {index >= props.paginationStartIndex && index < props.paginationEndIndex &&
                                                                        <img
                                                                            key={index}
                                                                            onClick={() => props.handleAwsImageSelected(index)}
                                                                            className={`thumbnail ${props.selectedAwsImgIndex.indexOf(index) !== -1 ? 'selectedAwsImg' : ''}`}
                                                                            src={`https://emediskill.s3.ap-south-1.amazonaws.com/` + image}
                                                                            alt='awsImage' />
                                                                    }
                                                                </>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className='centerDiv'>
                                                                No Images Found
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                </>
                            )
                        }



                        {/* {props.fileList.map((file, index) => {
                            return (
                            <div className="thumbnail" style={{ backgroundImage: `url(${URL.createObjectURL(file)})` }}>
                                <img src={CloseIcon}
                                    className="closeIcon"
                                    onClick={(event) => props.handleRemoveImage(event, index)}></img>
                            </div>
                            )
                        })} */}


                    </div>

                    {!props.isLoading && props.fileList.length === 0 && props.awsListOfImages.length > 0 &&
                        <div className='paginationContainer'>
                            <span className='paginationTotalText'> Total images : {props.awsListOfImages.length} </span>
                            <button
                                disabled={props.paginationStartIndex === 0}
                                onClick={() => props.handlePagination('prev')}> {'<'} </button>
                            <span className='paginationNumbersText'>
                                {props.paginationStartIndex + 1} - {' '}
                                {props.paginationEndIndex > props.awsListOfImages.length - 1 ? props.awsListOfImages.length : props.paginationEndIndex}
                            </span>
                            <button
                                disabled={props.paginationEndIndex > props.awsListOfImages.length - 1}
                                onClick={() => props.handlePagination('next')}> {'>'} </button>
                        </div>
                    }

                    {/* {props.isHovered ?
                        <div className="drag-container"
                            onDragLeave={props.handleDragLeave}
                            onMouseLeave={props.handleDragLeave}
                            onDrop={props.handleDragLeave}
                        >
                            <DragDropFileInput
                                visible={props.fileList.length}
                                onFileSubmit={props.onFileSubmit}
                            />
                        </div> : ''} */}

                </div>
            </div>
            <div className="imageInputFooter">

                <DragDropFileInput
                    visible={props.fileList.length}
                    onFileSubmit={props.onFileSubmit}
                />

                {props.fileList.length > 0 ?
                    (
                        <>
                            <input
                                type="button"
                                onClick={props.handleAdd}
                                value="Add the selected image"></input>
                        </>
                    ) : (
                        <>
                            <input
                                type="button"
                                disabled={props.selectedAwsImgIndex.length === 0}
                                onClick={(e)=>props.handleAdd(e,props.selectedAwsImgIndex)}
                                value="Choose the selected image"></input>

                        </>
                    )
                }
            </div>
        </div>
    )
}

export default ImageInputView