import XLSX from 'sheetjs-style';
import moment from 'moment';

export const handleExcelExportFn = (data, listOfCheckedUsers) => {
	let completeData = data.data;
	let exportData = [];
	let excelExportData = [];
	if (listOfCheckedUsers.length > 0) {
		//export selected
		completeData.forEach((order) => {
			listOfCheckedUsers.forEach((id) => {
				if (order._id === id || order.purchaseId === id) {
					exportData.push(order);
				}
			});
		});
	} else {
		//export All
		exportData = completeData;
	}

	// Data prepration
	exportData.forEach((data) => {
		let row = {
			// InvoiceId: data.invoiceId,
			Name: data.firstName + ' ' + data.lastName,
			// "Category": "",
			Course: data?.menmonic ? data?.menmonic : 'No purchase',
			'Contact Number': data.contactNumber,
			'WhatsApp Number': data.whatsAppNumber,
			'Acc Created': data?.createdAt
				? moment(data.createdAt).format('MM/DD/YYYY')
				: '',
			'Date Of Purchase': data?.purchaseAt
				? moment(data?.purchaseAt).format('MM/DD/YYYY')
				: 'NA',
			'Exam Status': data?.examStatus ? data?.examStatus : 'NA',
			Result: data?.result ? data?.result : 'NA',
			'Pass Percentage': data?.resultDetails?.percentage
				? data?.resultDetails?.percentage
				: 'NA',
		};
		excelExportData.push(row);
	});

	//Exporting to excel
	const worksheet = XLSX.utils.json_to_sheet(excelExportData);
	const workbook = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
	XLSX.writeFile(workbook, 'UserData.xlsx');
};

export const handleCFilterFn = (event, filterBy, userData, defaultUserData) => {
	if (event.target.value !== '' && filterBy !== '') {
		let dataSetToBeFilters = defaultUserData.data;
		if (Object.keys(dataSetToBeFilters).length !== 0) {
			let filterValue = event.target.value;
			const filteredData = dataSetToBeFilters.filter(
				(item) => item[filterBy] === filterValue
			);
			return {
				success: true,
				filteredData: filteredData,
			};
		}
	}
};

export const handleCSearchFn = (event, userData, defaultUserData) => {
	let nameFilterInput = event.target.value;

	const filterValue = nameFilterInput.toLowerCase();
	const filteredUsers = defaultUserData.data.filter((item) =>
		item.name.toLowerCase().includes(filterValue)
	);
	return {
		success: true,
		filteredNames: filteredUsers,
	};
};

export const handleExcelImport = (event, callback) => {
	const file = event.target.files[0];
	if (file) {
		const reader = new FileReader();
		reader.onload = function (e) {
			const data = new Uint8Array(e.target.result);
			const workbook = XLSX.read(data, { type: 'array' });
			const sheetName = workbook.SheetNames[0]; // Assuming you want data from the first sheet
			const sheet = workbook.Sheets[sheetName];
			const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

			callback(excelData); // Output the imported data
		};
		reader.readAsArrayBuffer(file);
	}
};
