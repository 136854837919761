export const getAllUsers = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/getAllUsers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Users : getUsers()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getUserDetails = async (userId, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/getUserDetails/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Query : getUserDetails()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getUserById = async (userId, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/getUserById/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Query : getUserById()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const createAndUpdateUser = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/createAndUpdateUser`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Query : createAndUpdateUser()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const createUser = async (payload, authToken) => {
  try {
    let formData = new FormData();
    for (let key in payload) {
      formData.append(key, payload[key]);
    }
    return await fetch(`${process.env.REACT_APP_BASE_URL}/createUser`, {
      method: "POST",
      headers: {
        // 'Content-Type': 'multipart/form-data',
        Authorization: authToken,
      },
      body: formData,
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Query : getQueries()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateBulkExamStatus = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateBulkExamStatus`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from UserPages services : updateBulkExamStatus()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getAllAdmins = async (payload, authToken) => {
  try {
    const { pageNo, limit, isSorted, sortBy, searchText } = payload;
    return await fetch(
      `${
        process.env.REACT_APP_BASE_URL
      }/getAllAdmins?pageNo=${pageNo}&limit=${limit}&searchText=${searchText}${
        sortBy ? "&sortBy=" + sortBy : ""
      }${isSorted ? "&isSorted=" + isSorted : ""}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Users : getAllAdmins()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateUserRights = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateUserRights`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from UserPages services : updateUserRights()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateUserRole = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateUserRole`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from UserPages services : updateUserRole()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const addAndUpdateUser = async (payload, authToken) => {
  try {
    let formData = new FormData();
    for (let key in payload) {
      if (
        [
          "pageRights",
          "userRights",
          "bssRights",
          "leadsRights",
          "customerCareRights"
        ].includes(key) 
      ) {
        formData.append(key, JSON.stringify(payload[key]));
      } else {
        formData.append(key, payload[key]);
      }
    }

    return await fetch(`${process.env.REACT_APP_BASE_URL}/addAndUpdateUser`, {
      method: "POST",
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: authToken,
      },
      body: formData,
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : addAndUpdateUser()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateUserFields = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateUserFields`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : updateUserFields()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const addSeriesForUser = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/addSeriesForUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : addSeriesForUser()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const addUsersTestExams = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/addUsersTestExams`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : addUsersTestExams()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const uploadUserDocuments = async (payload, authToken) => {
  try {
    let formData = new FormData();
    for (let key in payload) {
      formData.append(key, payload[key]);
    }
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/uploadUserDocuments`,
      {
        method: "POST",
        headers: {
          Authorization: authToken,
        },
        body: formData,
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Users : uploadUserDocuments()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateUserPassword = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/updateUserPassword`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : updateUserPassword()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateSeriesStatusForUser = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateSeriesStatusForUser`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : updateSeriesStatusForUser()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateExamStatusForUser = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateExamStatusForUser`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : updateExamStatusForUser()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateTestExamStatusForUser = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateTestExamStatusForUser`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : updateTestExamStatusForUser()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const disableUserAccount = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/disableUserAccount`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : disableUserAccount()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const deleteCourseForUsers = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/deleteCourseForUsers`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : deleteCourseForUsers()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const replaceCourseForUsers = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/replaceCourseForUsers`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : replaceCourseForUsers()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const removeTheUsers = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/removeTheUsers`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : removeTheUsers()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const createBSSFormsForUsers = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/createBSSFormsForUsers`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : createBSSFormsForUsers()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateExamResultStatus = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateExamResultStatus`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : updateExamResultStatus()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateTestExamResultStatus = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateTestExamResultStatus`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : updateTestExamResultStatus()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const createUserSpecificOffer = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/createUserSpecificOffer`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : createUserSpecificOffer()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const addCourseForUsers = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/addCourseForUsers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : addCourseForUsers()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateCourseEnableDate = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateCourseEnableDate`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : updateCourseEnableDate()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateUsersDashboardFields = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateUsersDashboardFields`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : updateUsersDashboardFields()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getCourseTags = async (authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/getCourseTags`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Users : getCourseTags()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getAllExternalPayments  = async (payload, authToken) => {
  try {
    const { pageNo, limit, isSorted, sortBy, searchText } = payload;
    return await fetch(
      `${
        process.env.REACT_APP_BASE_URL
      }/getAllExternalPayments?pageNo=${pageNo}&limit=${limit}&searchText=${searchText}${
        sortBy ? "&sortBy=" + sortBy : ""
      }${isSorted ? "&isSorted=" + isSorted : ""}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Users : getAllExternalPayments()", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};


export const updateExternalLink = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateExternalLink`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from User services : updateExternalLink()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};