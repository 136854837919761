import React, { useEffect, useState } from 'react';
import PopUpContainer from '@components/PopUpContainer';
import './style.css';

function ChangeInstitutePopUpView(props) {

	return (
		<PopUpContainer
           title={"Add/Change Institute"}
           isSearchEnabled={true}
           searchPlaceholder={"Search institute name"}
           isButtonsEnabled={true}
           style={{width:'368px', height:'255px'}}
           handleSearch={props.handleSearch}
           handleCancel={props.handleCancel}
           handleSave={props.handleSave}
        >
           <div className='d-flex flex-column gap-2 flex-grow-1' style={{overflow:'scroll'}}>
                {props.allInstitute?.map((institute, index)=>{
                   if(institute.name?.toLowerCase().includes(props.searchValue?.toLowerCase()))
                   {
                    return (<div key={index} className='d-flex gap-2'>
                                <input type='radio' name="institute" 
                                checked={props.selectedInstitute?._id === institute?._id}
                                onChange={(event)=>props.handleInstituteSelect(institute)}>
                                </input>{institute.name}
                            </div>) 
                    }
                })}
            </div>
        </PopUpContainer>
	)
}

export default ChangeInstitutePopUpView;
