import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PricingView from './view';
import ErrorView from '@components/ErrorView';
const continents = ["DEVELOPING_ASIA",
					"REST_OF_ASIA",
					"AFRICA",
					"RUSSIA",
					"EUROPE",
					"NORTH_AMERICA",
					"SOUTH_AMERICA",
					"AUSTRALIA"]
function Pricing(props) {
	const dataFetchedRef = useRef(null);
	const { id } = useParams();

	const [courseType, setCourseType] = useState('');
	const [allPrices, setAllPrices] = useState(props.pricingData);
	const [offerExpiry, setOfferExpiry] = useState({});
	const [formValuesEmpty, setFormValuesEmpty] = useState(false);
	const [courseDetails, setCourseDetails] = useState(props.courseInfo);
	
	const [editedCsv, setEditedCsv] = useState('')

	useEffect(()=>{
		props.handlePricingData(allPrices)
	 },[allPrices])

	// Parse CSV to object array
	useEffect(() => {
		const csvToObject = (csvString) => {
			const rows = csvString.split("\n");
			const result = {};
		  
			for (let i = 0; i < rows.length; i++) {
			  const row = rows[i].split(",");
			  if (!["DEVELOPING_ASIA",
					"REST_OF_ASIA",
					"AFRICA",
					"RUSSIA",
					"EUROPE",
					"NORTH_AMERICA",
					"SOUTH_AMERICA",
					"AUSTRALIA"].includes(row[0])) {
					return false;
				}
			  result[row[0]] = {
				continent: row[0],
				crossedPrice: row[1],
				offerPrice: row[2],
			  };
			}		  
			return result;
		  };
	
		var updatedPrices = {...allPrices}
		updatedPrices.prices = csvToObject(editedCsv)
	    if(updatedPrices.prices){
			setAllPrices(updatedPrices);
		}
	  }, [editedCsv])
	
	  // Format object array to CSV
	  useEffect(() => {
		const objectToCsv = (data) => {
			const csvRows = [
			  ...Object.entries(data).map(([continent, { crossedPrice, offerPrice }]) => {
				return `${continent},${crossedPrice},${offerPrice}`;
			  }),
			];
		  
			return csvRows.join("\n");
		  };
	
		if(allPrices.prices)
		{
		 setEditedCsv(objectToCsv(allPrices.prices));
		}
	  }, [allPrices.prices]);
	
	  // Textarea onChange event handler
	  const handleCsvChange = (event) => { 
		for (const continent of continents) {
			if (!event.target.value.includes(continent)) {
				return false;
			}
		}
		setEditedCsv(event.target.value);
	  };
	

	useEffect(()=>{
		setAllPrices(props.pricingData);
		setCourseDetails(props.courseInfo);
	},[props])

	

	const hideShowSingleEle = (id, value) => {
		if (document.getElementById(id)) {
			let ele = document.getElementById(id);
			ele.style.display = value;
		}
	};

	const disableTextAndNumberInputs = (status) => {
		const inputElements = document.querySelectorAll('input');
		inputElements.forEach((inputElement) => {
			const inputType = inputElement.getAttribute('type');
			if (
				(inputType === 'text' || inputType === 'number') &&
				inputElement.classList.contains('coursePricingInputs')
			) {
				inputElement.disabled = status;
			}
		});
	};

	const handlePrice = (event, continent, priceType) => {
		setFormValuesEmpty(false);
		let updatedPrices = { ...allPrices };
		updatedPrices.prices[continent][priceType] = event.target.value;
		setAllPrices(updatedPrices);
	};

	const handleChange=(value, field, data)=>{
		var updatedPrices = {...allPrices}
		if(field==='offerExpiryCheck'){
			updatedPrices.offerExpiry = value ? 
										{days:1,hours:0,minutes:0} : 
										{days:0,hours:0,minutes:0} 
		}
		else if(field==='days'){
			updatedPrices.offerExpiry.days = value 
		}
		else if(field==='hours'){
			updatedPrices.offerExpiry.hours = value 
		}
		else if(field==='minutes'){
			updatedPrices.offerExpiry.minutes = value 
		}
		else if(field==='courseType'){
			updatedPrices.courseType = value 
			if (courseType === 'paid') 
			{
				hideShowSingleEle('courseFreeOptionsContainer', 'none');
				disableTextAndNumberInputs(false);
			}
			else if (courseType === 'comingSoon' || courseType === 'free') {
				hideShowSingleEle('courseFreeOptionsContainer', 'none');
				disableTextAndNumberInputs(true);
				updatedPrices.offerExpiry = {days:0,hours:0,minutes:0}
			}
		}
	
	 setAllPrices(updatedPrices)
	}


	var isOfferExpiryChecked = allPrices?.offerExpiry?.days >0 || 
	                            allPrices?.offerExpiry?.hours >0 ||
								allPrices?.offerExpiry?.minutes >0

	return (
		<>
			{id ? (
				<PricingView
					allPrices={allPrices}
					courseDetails={courseDetails}
					handlePrice={handlePrice}					
					formValuesEmpty={formValuesEmpty}

					isOfferExpiryChecked={isOfferExpiryChecked}
					continents={continents}
					editedCsv={editedCsv}
					handleCsvChange={handleCsvChange}
					handleChange={handleChange}
				/>
			) : (
				<ErrorView text={'Please Select or Add a course before adding prices.'} />
			)}
		</>
	);
}

export default Pricing;
