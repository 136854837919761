import React from "react";

import OfferAnalyticsView from './view';

function OfferAnalytics(){
    return(
        <>
            <OfferAnalyticsView />
        </>
    )
}

export default OfferAnalytics;