import { useParams } from "react-router-dom";

import CustomerCare from "@pages/CustomerCare/Main";
import ErrorView from "@components/ErrorView";

function UserCustomerCare() {
  const { id } = useParams();

  return (
    <>
      {id ? (
        <CustomerCare userId={id} />
      ) : (
        <ErrorView text={"Please Select/Create User "} />
      )}
    </>
  );
}

export default UserCustomerCare;
