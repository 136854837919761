import React,{useState, useRef, useEffect, useMemo} from 'react';

import SearchView from './view';
import { getEventElementPosition} from '@helpers/common.js';
import moment from 'moment';
import * as UserServices from '@services/Users';
import { decryptText } from '@library/enc-dec';
import { produce } from 'immer';

const mainDateOption=['Today','Yesterday','This Month','This Week', 'This Year', 'Last 7 days','Last 30 days','Last 180 days','Last 360 days', 'This financial year','All time','Select']
const years = ['2022','2023','2024']
const months= ['January','February','March','April','May','June','July','August','September','October','November','December']
var tagCount = 0

function Search(props) {
    const [isFiltersVisible, setIsFiltersVisible] =useState(false)

    const [popUp, setPopUp] =useState('');
	  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
	  const [popUpPosition, setPopUpPosition] = useState({});
    const [selectedYear, setSelectedYear] = useState(null)

    const [text, setText]=useState(props.value)
    const [startDate, setStartDate] = useState(props.startDate);
    const [endDate, setEndDate] = useState(props.endDate);
    const [yearTag, setYearTag] = useState(props.yearTag);
    const [monthTag, setMonthTag] = useState(props.monthTag);
    const [mainTag, setMainTag] = useState(props.mainTag);
    const [tagStatus, setTagStatus] = useState(props.tagStatus);
    const [subTag, setSubTag] = useState(props.subTag);
    const [manualTag, setManualTag] = useState(props.manualTag);

    useEffect(()=>{
        setText(props.value)
        setStartDate(props.startDate)
        setEndDate(props.endDate)
        setYearTag(props.yearTag)
        setMonthTag(props.monthTag)
        setMainTag(props.mainTag)
        setTagStatus(props.tagStatus)
        setSubTag(props.subTag)
        setManualTag(props.manualTag)

    },[props.value,props.startDate,props.endDate,props.yearTag,props.monthTag,props.mainTag,props.tagStatus,props.subTag,props.manualTag])



    const [isTagFilterVisible, setIsTagFilterVisible]=useState(false);
    const [allTagsData, setAllTagsData] = useState(null);

    const [selectedTagFilter, setSelectedTagFilter] = useState({});

    useMemo(()=>{
       tagCount = Boolean(mainTag) + Boolean(tagStatus) + Boolean(subTag) + Boolean(manualTag) + Boolean(yearTag)+ Boolean(monthTag)
    },[mainTag, tagStatus, yearTag, monthTag, subTag, manualTag])
    const handleFilter=(e, index)=>{
      props.handleFilter(e,index)
    }

    const handlePopUpOpen = (event, popUpName, data) => {
		handlePopUpClose()
		setPopUp(popUpName)
		setIsPopUpVisible(true)
		let position = getEventElementPosition(event);
		setPopUpPosition(position);
		event.stopPropagation();

    if(popUpName==='dateMonthPopUp')
    {
        setSelectedYear(data)
    }
	};
	const handlePopUpClose = () => {
		setIsPopUpVisible(false)
	};
	// To handleSave buttons on the popup
	const handlePopUpSave = async (event, option) => {
		handlePopUpClose();	
    if(popUp==='dateMonthPopUp'){
         let selectedMonth = option
         const startDate = moment([selectedYear, selectedMonth]); // Month is zero-indexed
         const endDate = startDate.clone().endOf('month');
         const formattedStartDate = startDate.format('YYYY-MM-DD');
         const formattedEndDate = endDate.format('YYYY-MM-DD');
         setStartDate(formattedStartDate)
         setEndDate(formattedEndDate)
    }else if(mainDateOption.includes(option))
    {
        const {formattedStartDate, formattedEndDate} = calculateDates(option)
        setStartDate(formattedStartDate)
         setEndDate(formattedEndDate)
    }else if(popUp==="yearTagPopUp"){
        updateSelectedTagFilter('yearTag',event[0]._id)
	}else if(popUp==="monthTagPopUp"){
        updateSelectedTagFilter('monthTag',event[0]._id)
	}else if(popUp==="statusTagPopUp"){
        updateSelectedTagFilter('tagStatus',event[0]._id)
	}else if(popUp==="mainTagPopUp"){
        updateSelectedTagFilter('mainTag',event[0]._id)
	}else if(popUp==="subTagPopUp"){
        updateSelectedTagFilter('subTag',event[0]._id)
	}else if(popUp==="manualTagPopUp"){
        updateSelectedTagFilter('manualTag',event[0]._id)
	}
  }

  const handleStartDate=(date)=>{
    setStartDate(date)
  }
  const handleEndDate=(date)=>{
    setEndDate(date)
  }

  const moment = require('moment');

// Function to calculate start and end dates based on the selected option
  function calculateDates(selectedOption) {
      const today = moment().startOf('day');
      let startDate, endDate;

      switch (selectedOption) {
          case 'Today':
              startDate = today.clone().startOf('day');
              endDate = today.clone().endOf('day');
              break;
          case 'Yesterday':
              startDate = today.clone().subtract(1, 'day').startOf('day');
              endDate = today.clone().subtract(1, 'day').endOf('day');
              break;
          case 'This Month':
              startDate = today.clone().startOf('month');
              endDate = today.clone().endOf('month');
              break;
          case 'This Week':
              startDate = today.clone().startOf('isoWeek');
              endDate = today.clone().endOf('isoWeek');
              break;
          case 'This Year':
              startDate = today.clone().startOf('year');
              endDate = today.clone().endOf('year');
              break;
          case 'Last 7 days':
              startDate = today.clone().subtract(6, 'days').startOf('day');
              endDate = today.clone().endOf('day');
              break;
          case 'Last 30 days':
              startDate = today.clone().subtract(29, 'days').startOf('day');
              endDate = today.clone().endOf('day');
              break;
          case 'Last 180 days':
              startDate = today.clone().subtract(179, 'days').startOf('day');
              endDate = today.clone().endOf('day');
              break;
          case 'Last 360 days':
              startDate = today.clone().subtract(359, 'days').startOf('day');
              endDate = today.clone().endOf('day');
              break;
          case 'This financial year':
              let date = getCurrentFinancialYear();
              startDate = date.startDate;
              endDate= date.endDate;
              break;
          case 'All time':
              startDate = moment(0); // Unix epoch
              endDate = today.clone().endOf('day');
              break;
          default:
              // Handle 'Select' or unknown option
              startDate = null;
              endDate = null;
              break;
      }
      const formattedStartDate = startDate.format('YYYY-MM-DD');
      const formattedEndDate = endDate.format('YYYY-MM-DD');
      return { formattedStartDate, formattedEndDate };
  }

  function getCurrentFinancialYear() {
      const today = moment();
      const currentMonth = today.month();
      const currentYear = today.year();

      let startYear, endYear;

      // If current month is April or later, financial year starts in the current year
      if (currentMonth >= 3) { // April is the fourth month (zero-based index)
          startYear = currentYear;
          endYear = currentYear + 1;
      } else { // Otherwise, financial year started in the previous year
          startYear = currentYear - 1;
          endYear = currentYear;
      }

      const startDate = moment(`${startYear}-04-01`).startOf('day');
      const endDate = moment(`${endYear}-03-31`).endOf('day');

      return { startDate, endDate };
  }

  const onFilterClick=(data)=>{
    if(props.isFilterEnabled)
    {
    setIsFiltersVisible(data)
    }
  }

  const handleTagFilterVisibility=(data, save)=>{
     setIsTagFilterVisible(data)
     if(!allTagsData){
     getCourseTags()}
     if(data===true && save!=='save')
     {
        setSelectedTagFilter({yearTag, monthTag, mainTag, subTag, tagStatus, manualTag})
     }
     if(save==='save')
     {
        setYearTag(selectedTagFilter.yearTag)
        setMonthTag(selectedTagFilter.monthTag)
        setMainTag(selectedTagFilter.mainTag)
        setSubTag(selectedTagFilter.subTag)
        setTagStatus(selectedTagFilter.tagStatus)
        setManualTag(selectedTagFilter.manualTag)
     }
  }
  const handleTagFilter=()=>{}
  
  const handleText=(event)=>{
     setText(event.target.value)
     if(event.type==='keydown' && event.key==="Enter")
     {
      onSearchClick()
     }
     if(event.target.value==='' && event.type!=='keydown')
     {
        props.onSearch({text, startDate, endDate, yearTag, monthTag, mainTag, tagStatus, subTag})
     }
  }

  const onSearchClick=()=>{
    setIsFiltersVisible(false)
     props.onSearch({text, startDate,endDate, yearTag, monthTag, mainTag, tagStatus, subTag, manualTag})
  }

  const getCourseTags =async()=>{
    let authToken = decryptText(localStorage.getItem('aEmediat'));

    const response = await UserServices.getCourseTags( authToken);
    if(response.success)
    {
        setAllTagsData(response.data)
    }
  }

  const updateSelectedTagFilter =(key , data)=>{
    let updatedFilters = produce(selectedTagFilter, (draft) => {
        draft[key] = data;
    });
    setSelectedTagFilter(updatedFilters)
}

    return(
        <SearchView
         props={props}
         isFiltersVisible={isFiltersVisible}
         onFilterClick={onFilterClick}
         handleFilter={handleFilter}
         onSearchClick={onSearchClick}

         handleTagFilter={handleTagFilter}
         handleTagFilterVisibility={handleTagFilterVisibility}

         mainDateOption={mainDateOption}
         years={years}
         months={months}

         popUp={popUp}
         isPopUpVisible={isPopUpVisible}
         popUpPosition={popUpPosition}
         handlePopUpClose={handlePopUpClose}
         handlePopUpOpen={handlePopUpOpen}
         handlePopUpSave={handlePopUpSave}

         startDate={startDate}
         endDate={endDate}
         handleStartDate={handleStartDate}
         handleEndDate={handleEndDate}

         isTagFilterVisible={isTagFilterVisible}
         text={text}
         handleText={handleText}

         allTagsData={allTagsData}
         selectedTagFilter={selectedTagFilter}
         tagCount={tagCount}

         isFiltersEnabled={props.isFilterEnabled}
        />
        )
}

export default Search;