export const updateAllOffers = (value) => {
	return {
		type: 'UPDATE_ALL_OFFERS',
		payload: {
			allOffers: value,
		},
	};
};


export const updateTotalNumOfOffers = (value) => {
	return {
		type: 'UPDATE_TOTAL_NUMBER_OF_OFFERS',
		payload: {
			totalNumberOfOffers: value,
		},
	};
};

export const updateOfferSet = (value) => {
	return {
		type: 'UPDATE_OFFERS_SET',
		payload: {
			offerSet: value,
		},
	};
};

export const updateSelectedOffer = (value) => {
	return {
		type: 'UPDATE_SELECTED_OFFER',
		payload: {
			selectedOffer: value,
		},
	};
};


export const updateFilters = (value) => {
	return {
		type: 'UPDATE_OFFER_FILTERS',
		payload: {
			filters: value,
		},
	};
};


export const resetOffersReducer = (value) => {
	return {
		type: 'RESET_SELECTED_OFFER',
	};
};

export const getOffer = (value) =>{
	return{
		type: 'GET_OFFER',
	};
 };
