export const getPendingIdProof = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getPendingIDProof?
		&fromInputDate=${payload.fromInputDate}
		&toInputDate=${payload.toInputDate}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Verification services : getPendingIdProof() ',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getPendingDoctorProof = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getPendingDoctorProof?
															&fromInputDate=${payload.fromInputDate}
															&toInputDate=${payload.toInputDate}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Verification services : getPendingDoctorProof() ',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getPendingPhotoProof = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getPendingPhotoProof?
		&fromInputDate=${payload.fromInputDate}
		&toInputDate=${payload.toInputDate}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Verification services : getPendingPhotoProof() ',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateDocumentStatus = async (authToken, userId, verificationType, status) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateDocumentStatus`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify({
				userId: userId,
				verificationType: verificationType,
				status: status
			})
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Verification services : updateDocumentStatus() ',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateMultipleDocumentStatus = async (authToken, userIdList, verificationType, status) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateMultipleDocumentStatus`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify({
				userIdList: userIdList,
				verificationType: verificationType,
				status: status
			})
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Verification services : verifyMultipleIdProof() ',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const verifyBssForm = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/verifyBssForm`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload)
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Verification services : verifyBssForm() ',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};