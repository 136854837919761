import React from "react";
import LeftIcon from "@assets/common/tableNext.png";
import RightIcon from "@assets/common/tablePrevious.png";

import "./style.css";

function ButtonBarsView(props) {
  const { buttons } = props.props;

  return (
    <div
      className="button-bar-container"
      onMouseEnter={() => props.setShowButtons(true)}
      onMouseLeave={() => props.setShowButtons(false)}
    >
      {props.showButtons ? (
        <img
          src={RightIcon}
          className="scroll-button left"
          onClick={props.scrollLeft}
        />
      ) : null}
      <div className="button-bar" ref={props.containerRef}>
        {buttons.map((button, index) =>
          button ? (
            <div key={index}>{props.renderButton(button, index)}</div>
          ) : (
            ""
          )
        )}
      </div>

      {props.showButtons ? (
        <img
          src={LeftIcon}
          className="scroll-button right"
          onClick={props.scrollRight}
        />
      ) : null}
    </div>
  );
}

export default ButtonBarsView;
