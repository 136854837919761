import React from 'react';

import './style.css';

import UploadIcon from '@assets/common/uploadIcon.png';
import ImageInput from '@components/ImageInput';
import PopUp from '@components/PopUp';

function EditTitlePopUpView(props) {
	return (
		<div className="editTitleMain">
			<div className="d-flex flex-grow-1 gap-2">
				<div className='uploadDiv' 
				ref={props.squareThumbDiv} 
				onClick={(event) => props.handleUploadClick(event, 'square')}
				style={{backgroundImage: `url(${props.squareThumbnail})`}}
				>
					{props.squareThumbnail?'':
					<>
					<img src={UploadIcon} alt='UploadIcon'/>
					<div id='coverImage_name'>Upload square thumbnail</div>
					<input
						type='file'
						className='d-none'
						accept='image/jpeg,image/jpg,image/png'
						id='coverImage'
					/>
					</>}
				</div>	
				<div className='uploadDiv' 
				ref={props.rectThumbDiv} 
				onClick={(event) => props.handleUploadClick(event, 'rect')}
				style={{backgroundImage: `url(${props.rectThumbnail})`}}>
					{props.rectThumbnail? '' : <>
					<img src={UploadIcon} alt='UploadIcon'/>
					<div id='coverImage_name'>Upload rectangle thumbnail</div>
					<input
						type='file'
						className='d-none'
						accept='image/jpeg,image/jpg,image/png'
						id='coverImage'
					/></>}
				</div>
			</div>
			<div className="fw-normal">Edit Display Name</div>
			<input type="text" 
			   onChange={(event)=>props.handleDisplayNameChange(event.target.value)} 
			   value={props.displayName}></input>
			<div className="fw-normal">Edit Internal Name</div>
			<input type="text" 
				onChange={(event)=>props.handleInternalNameChange(event.target.value)} 
				value={props.internalName}></input>
			<div className="hr"/>

            <div className="d-flex justify-content-end gap-1">
				
			{props.error && props.error !== '' && (
				<div className='alert alert-danger w-50 justify-content-center d-flex'>
					{props.error}
				</div>
			)}
				<button onClick={props.handleCloseBtn} className='closeBtn'>
					Cancel
				</button>
				<button onClick={props.handleSaveBtn} className='saveBtn'>
					Save
				</button>
            </div>

			<PopUp visible={props.isPopUpVisible}
				top={'50px'}
				left={'50px'}
				handleOutSideClick={props.handlePopUpClose}> 
				<div className="full-page-overlay">
					
			    <ImageInput
				  handleClose={props.handlePopUpClose}
				  handleAdd={props.handleAddImages} 
				  folderName={props.uploadType==='rect'? "courseRectangleThumbnails" : "courseSquareThumbnails"}
				/>
				</div>
			</PopUp>
		</div>
	);
}

export default EditTitlePopUpView;
