import React, { useEffect, useState } from 'react';

import EditQualificationPopUpView from './view';

function EditQualificationPopUp(props) {
	const [currentQualifications, setCurrentQualifications] = useState(props.currentQualifications);
	const [selectedProfessions, setSelectedProfession] =useState([]);
	const [popUpPosition, setPopUpPosition] = useState({});
	const [isPopUpVisible, setIsPopUpVisible] = useState(false);

	const handleCloseBtn = () => {
		props.handleClose()
	};

	const handleSaveBtn = async (event) => {
		setIsPopUpVisible(false)
		props.handleSave(currentQualifications)
	};

	const handlePopUpOpen =(event,profession, index)=>{
		setSelectedProfession(props.allQualifications[profession])

		setIsPopUpVisible(true)
		let position = {top:10+ index*40, left:700};
		setPopUpPosition(position);
		event.stopPropagation();
	}
	const handlePopUpClose =()=>{
		setIsPopUpVisible(false)
	}
	

	const handleQualificationCheck =(qualification, checked)=>{
		var updatedQualifications = [...currentQualifications]
        if(checked)
		{
           updatedQualifications.push(qualification)
		}
		else{
			updatedQualifications = updatedQualifications.filter(item => item !== qualification)
		}
		setCurrentQualifications(updatedQualifications)
	}
	const handleProfessionCheck =(qualifications, checked)=>{
		var updatedQualifications = [...currentQualifications]
        if(checked)
		{
           updatedQualifications.push(...qualifications)
		}
		else{
			updatedQualifications = updatedQualifications.filter(item => !qualifications.includes(item));
		}
		setCurrentQualifications(updatedQualifications)
		setIsPopUpVisible(false)
	}

	return (
		<EditQualificationPopUpView
		        currentQualifications={currentQualifications}
				allQualifications={props.allQualifications}
				
				isPopUpVisible={isPopUpVisible}
				popUpPosition={popUpPosition}
				selectedProfessions={selectedProfessions}
				handlePopUpOpen={handlePopUpOpen}
				handlePopUpClose={handlePopUpClose}
				handleQualificationCheck={handleQualificationCheck}
				
				handleProfessionCheck ={handleProfessionCheck}

				handleCloseBtn={handleCloseBtn}
				handleSaveBtn={handleSaveBtn}
			/>
	);
}

export default EditQualificationPopUp;
