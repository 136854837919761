export const getAllTestQuestionBanks = async (payload, authToken) => {
	try {
		
		const {pageNo, limit, isSorted, sortBy, searchText} = payload
	    const url = `${process.env.REACT_APP_BASE_URL}/getAllTestQuestionBanks?pageNo=${pageNo}&limit=${limit}${searchText? '&searchText='+searchText: ''}${sortBy? '&sortBy='+sortBy: ''}&isSorted=${isSorted}`
	
		return await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from EXam : Get all Test questions ', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateTestQuestionBankThumbnail = async (payload, authToken) => {
	try {
		let formData = new FormData();
		for (let key in payload) {
			formData.append(key, payload[key]);
		}
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/updateTestQuestionBankThumbnail`,
			{
				method: 'PATCH',
				headers: {
					Authorization: authToken,
				},
				body: formData,
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Exam services : updateTestQuestionBankThumbnail()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const deleteTestQuestionBanks = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/deleteTestQuestionBanks`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Exam services : deleteTestQuestionBanks()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const getTestQuestionBankById = async (questionBankId, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getTestQuestionBankById/${questionBankId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from exam services :getTestQuestionBankById()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateTestQuestionBank = async (payload, authToken) => {
	try {
		let formData = new FormData();
		for (let key in payload) {
			if (key === 'questionsSet' || key === 'duration' || key === 'instructions') {
				formData.append(key, JSON.stringify(payload[key]));
			} else {
				formData.append(key, payload[key]);
			}
		}

		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateTestQuestionBank`, {
			method: 'PATCH',
			headers: {
				Authorization: authToken,
			},
			body: formData,
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Exam services : updateTestQuestionBank()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const addTestQuestionBank = async (payload, authToken) => {
	try {
		let formData = new FormData();
		for (let key in payload) {
			if (key === 'questionsSet' || key === 'duration' || key === 'instructions') {
				formData.append(key, JSON.stringify(payload[key]));
			} else {
				formData.append(key, payload[key]);
			}
		}

		return await fetch(`${process.env.REACT_APP_BASE_URL}/addTestQuestionBank`, {
			method: 'POST',
			headers: {
				Authorization: authToken,
			},
			body: formData,
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Exam services : addTestQuestionBank()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

// New API to be created
export const getResult = async (purchaseId, resultId, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getResult?purchaseId=${purchaseId}&resultId=${resultId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Exam services : getResult()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const uploadQuestionThumbnail = async (payload, authToken) => {
	try {
		let formData = new FormData();
		for (let key in payload) {
			formData.append(key, payload[key]);
		}
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/uploadQuestionThumbnail`,
			{
				method: 'PATCH',
				headers: {
					Authorization: authToken,
				},
				body: formData,
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Exam services : uploadQuestionThumbnail()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};


export const getUsersTestExamsByUserId = async (payload, authToken) => {
	try {
		const {pageNo, limit, userId} = payload
		const url = `${process.env.REACT_APP_BASE_URL}/getUsersTestExamsByUserId?pageNo=${pageNo}&limit=${limit}&userId=${userId}`
		
		return await fetch(url,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from test : getUsersTestExamsByUserId ', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};

export const updateShowResultStatus = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateShowResultStatus`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error(
			'Coming from Tests services : updateShowResultStatus()',
			error.message
		);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
