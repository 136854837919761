import React from 'react';

import './style.css';
import UploadImgPlaceHolder from '@assets/common/uploadImgPlaceHolder.png';

function UserProfileView(props) {
	return (
		<>
			<div className='userProfileView'>
				<div className='userProfileImgMainContainer'>
					<img
						id='profileImage_uploadBtn'
						src={
							props.userDetails?.displayPhoto
								? props.userDetails.displayPhoto
								: UploadImgPlaceHolder
						}
						className='uploadIcon'
						alt='UploadImgPlaceHolder'
					/>					
				</div>
				<div className='text-center'>
					<span style={{ color: '#898989' }}>Display Picture</span>
				</div>
			</div>
		</>
	);
}

export default UserProfileView;
