import React, { useEffect, useState } from "react";

import TicketActivityView from "./view";

function TicketActivity(props) {
  const [activityData, setActivityData] = useState([]);

  useEffect(() => {
    if (
      props.ticketActivityData.length === 0 ||
      !props.ticketActivityData.queryActivity
    ) {
      return;
    }
    var data = [...props.ticketActivityData.queryActivity];
    data.unshift({
      _id: props.ticketActivityData._id,
      userId: props.ticketActivityData.userId,
      commentType: "normalComment",
      queryId: props.ticketActivityData.queryId,
      comment: props.ticketActivityData.description,
      status: props.ticketActivityData.status,
      createdAt: props.ticketActivityData.createdAt,
      lastSeenDetails: props.ticketActivityData.lastSeenDetails,
      commentDetails: {
        displayPhoto: props.ticketActivityData.displayPhoto,
        fullName: props.ticketActivityData.fullName,
      },
    });

    setActivityData(groupEntriesByDate(data));
  }, [props.ticketActivityData]);

  function groupEntriesByDate(entries) {
    const groups = {};

    // Iterate through entries
    entries.forEach((entry) => {
      const date = new Date(entry.createdAt).toLocaleDateString();
      if (!groups[date]) {
        groups[date] = [entry]; // Create new array if date group doesn't exist
      } else {
        groups[date].push(entry); // Add entry to existing date group
      }
    });

    // Convert groups object to array of arrays
    const result = Object.values(groups);
    return result;
  }

  return (
    <>
      <TicketActivityView activityData={activityData} />
    </>
  );
}

export default TicketActivity;
