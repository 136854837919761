import React,{useState} from 'react';

import PopUpContainerView from './view';

function PopUpContainer(props) {
    const[searchValue , setSearchValue]=useState('')

    const handleSearch=(value)=>{
        setSearchValue(value)
        props.handleSearch(value)
    }

	return (
		<PopUpContainerView
           props={props}
           searchValue={searchValue}
           handleSearch={handleSearch}
        />
	);
}

export default PopUpContainer;
