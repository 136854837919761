import React, { useState, useEffect } from 'react';

import FaqQuestionView from './view';

function FaqQuestion(props) {
	const { questionContent, onValueChange, onDeleteRequest } = props;

	const [questionId, setQuestionId] = useState(0);
	const [question, setQuestion] = useState('');
	const [answer, setAnswer] = useState('');
	const [currentQuestion, setCurrentQuestion] = useState({});

	useEffect(() => {
		setCurrentQuestion(questionContent);
		setCurrentQuestionSet_fn(questionContent);
	}, []);

	const setCurrentQuestionSet_fn = (questionContent) => {
		setQuestionId(questionContent._id);
		setQuestion(questionContent.question);
		setAnswer(questionContent.answer);
	};

	const handleQuestion = (event) => {
		let localCurrentQuestion = currentQuestion;
		localCurrentQuestion['question'] = event.target.value;
		setCurrentQuestion(localCurrentQuestion);

		setQuestion(event.target.value);
		sendValuesOutside();
	};

	const handleAnswer = (event) => {
		let localCurrentQuestion = currentQuestion;
		localCurrentQuestion['answer'] = event.target.value;
		setCurrentQuestion(localCurrentQuestion);

		setAnswer(event.target.value);
		sendValuesOutside();
	};

	const validateInputs = () => {
		if (currentQuestion.question === '' || currentQuestion.answer === '') {
			return false;
		} else {
			return true;
		}
	};

	const sendValuesOutside = () => {
		let localCurrentQuestion = currentQuestion;

		// localCurrentQuestion.dataCompleted = validateInputs();
		setCurrentQuestion(localCurrentQuestion);

		onValueChange(localCurrentQuestion);
	};

	const handleDelete = (event, questionId) => {
		onDeleteRequest(questionId);
	};

	return (
		<>
			<FaqQuestionView
				questionId={questionId}
				question={question}
				answer={answer}
				handleQuestion={handleQuestion}
				handleAnswer={handleAnswer}
				handleDelete={handleDelete}
			/>
		</>
	);
}

export default FaqQuestion;
