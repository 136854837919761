//Components for course page
import CourseCategoryDetails from '@pages/Content/CourseCategories/CourseCategory/Details';

//Components for video page
import VideoDetails from '@pages/Content/Videos/Video/Details';
import VideoComments from '@pages/Content/Videos/Video/Comments';
import VideoAnalytics from '@pages/Content/Videos/Video/Analytics';

//Components for exam page
import ExamDetails from '@pages/Content/Exams/Exam/Details';
import ExamQuestions from '@pages/Content/Exams/Exam/Questions';

//Components for test page
import TestDetails from '@pages/Content/Tests/Test/Details';
import TestQuestions from '@pages/Content/Tests/Test/Questions';

//Components for faq page
import FaqDetails from '@pages/Content/FAQ/FAQ/Details';

//Components for Course page
import CourseAnalytics from '@pages/Content/Courses/Course/Analytics';
import CourseDetails from '@pages/Content/Courses/Course/Details/Main';
import CourseContent from '@pages/Content/Courses/Course/Content';
import CoursePricing from '@pages/Content/Courses/Course/Pricing/Main';
import CourseBlogs from '@pages/Content/Courses/Course/Blogs';
import CourseSEO from '@pages/Content/Courses/Course/SEO';


//Components for Course page
import SeriesContent from '@pages/Content/Series/Series/Content';
import SeriesDetails from '@pages/Content/Series/Series/Details';

//Component from offer page
import OfferAnalytics from '@pages/Offers/Offer/Analytics';
import OfferDetails from '@pages/Offers/Offer/Details';

//Component from User page
import UserActivity from '@pages/Users/Activity/Main';
import UserProfile from '@pages/Users/Profile';
import UserLead from '@pages/Users/LeadDetails/Main';
import UserCustomerCare from '@pages/Users/CustomerCare/Main';
import UserBSSForm from '@pages/Users/BSS/Main';

import CustomerCare from '@pages/CustomerCare/Main';

//Component from DynamicDetails page
import DynamicDetails from '@pages/Dynamic/Articles/Article';
import DynamicMail from '@pages/Dynamic/Mails/MailContent';

//Components from SuperAdmin
import AdminUserProfile from '@pages/SuperAdmin/AdminUsers/AdminUser/Profile';

export const allComponents = {
    'CourseCategoryDetails' : CourseCategoryDetails,
    
    //Components for video page    
    'VideoDetails' : VideoDetails,
    'VideoComments' : VideoComments,
    'VideoAnalytics' : VideoAnalytics,

    //Components for exam page
    'ExamDetails' : ExamDetails,
    'ExamQuestions' : ExamQuestions,

    //Components for test page
    'TestDetails' : TestDetails,
    'TestQuestions' : TestQuestions,

    //Components for faq page
    'FaqDetails' : FaqDetails,

    //Components for course page
    'CourseAnalytics' : CourseAnalytics,
    'CourseDetails' : CourseDetails,
    'CourseContent' : CourseContent,
    'CoursePricing' : CoursePricing,  
    'CourseBlogs': CourseBlogs,
    'CourseSEO': CourseSEO,

    //Components for course page
    'SeriesDetails' : SeriesDetails,
    'SeriesContent' : SeriesContent,

    //Components from offer page
    'OfferAnalytics' : OfferAnalytics,
    'OfferDetails' : OfferDetails,

    //Component from User page
    'UserActivity' : UserActivity,
    'UserProfile' : UserProfile, 
    'UserLead': UserLead,
    'UserCustomerCare': UserCustomerCare,
    'UserBSSForm': UserBSSForm,
    
    //Component for Customer Care page
    'CustomerCare' : CustomerCare, 

    //Component for Dynamic details page    
    'DynamicDetails' : DynamicDetails,
    'DynamicMail':DynamicMail,

    //Component for Super admin
    'AdminUserProfile' : AdminUserProfile 
}

