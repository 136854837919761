import { Form, json } from "react-router-dom";

export const getAllSeries = async (payload, authToken) => {
  try {
    const { pageNo, limit, isSorted, sortBy, searchText } = payload;
    const url = `${
      process.env.REACT_APP_BASE_URL
    }/getAllSeries?pageNo=${pageNo}&limit=${limit}${
      searchText ? "&searchText=" + searchText : ""
    }${sortBy ? "&sortBy=" + sortBy : ""}${
      isSorted ? "&isSorted=" + isSorted : ""
    }`;

    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Series : Get all series ", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getSeriesById = async (seriesId, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/getSeriesById/${seriesId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Courses : Get Course by Id ", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getSeriesSections = async (seriesId, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/getSeriesSections/${seriesId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Series : getSeriesSections", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const updateSeriesDetails = async (payload, authToken) => {
  try {
    let formData = new FormData();
    for (let key in payload) {
      formData.append(key, payload[key]);
    }

    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateSeriesDetails`,
      {
        method: "POST",
        headers: {
          Authorization: authToken,
        },
        body: formData,
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Video services : updateSeriesDetails()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};
export const updateSeriesSections = async (payload, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateSeriesSections`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Video services : updateSeriesDetails()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getVideoById = async (videoDocId, authToken) => {
  try {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}/getVideoById/${videoDocId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    )
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Series services: getVideoById() ",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const duplicateSeries = async (payload, authToken) => {
  try {
    return await fetch(`${process.env.REACT_APP_BASE_URL}/duplicateSeries`, {
      method: "POST",
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error(
      "Coming from Series services : duplicateSeries()",
      error.message
    );
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};

export const getSeriesByUserId = async (payload, authToken) => {
  try {
    const { pageNo, limit, userId } = payload;
    const url = `${process.env.REACT_APP_BASE_URL}/getSeriesByUserId?pageNo=${pageNo}&limit=${limit}&userId=${userId}`;

    return await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    })
      .then((res) => res.json())
      .catch((error) => error.message);
  } catch (error) {
    console.error("Coming from Series : Get all series ", error.message);
    if (error.message === "Network Error") {
      return error.message;
    }
  }
};
