export const updateAllOrders = (value) => {
  return {
    type: "UPDATE_ALL_ORDERS",
    payload: {
      allOrders: value,
    },
  };
};

export const updateTotalNumOfOrders = (value) => {
  return {
    type: "UPDATE_TOTAL_NUM_OF_ORDERS",
    payload: {
      numOfOrders: value,
    },
  };
};

export const updateOrdersSet = (value) => {
  return {
    type: "UPDATE_ORDERS_SET",
    payload: {
      ordersSet: value,
    },
  };
};

export const updateNetReceived = (value) => {
  return {
    type: "UPDATE_NET_RECEIVED",
    payload: {
      netReceived: value,
    },
  };
};

export const updateGrossReceived = (value) => {
  return {
    type: "UPDATE_GROSS_RECEIVED",
    payload: {
      grossReceived: value,
    },
  };
};
export const updateTotalGST = (value) => {
  return {
    type: "UPDATE_TOTAL_GST",
    payload: {
      totalGST: value,
    },
  };
};
export const updateTotalGatewayFee = (value) => {
  return {
    type: "UPDATE_TOTAL_GATEWAY_FEE",
    payload: {
      totalGatewayFee: value,
    },
  };
};

export const updateAccountFilters = (value) => {
  return {
    type: "UPDATE_ACCOUNT_FILTERS",
    payload: {
      filters: value,
    },
  };
};
