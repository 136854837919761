import React from "react";
import BSSFormPageView from "./view";
import { getEventElementPosition } from "@helpers/common.js";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";

function BSSFormPage(props) {
  const [courseDetails, setCourseDetails] = useState(props.courseDetails);

  const [popUpPosition, setPopUpPosition] = useState({ top: 0, left: 0 });

  const [isSelectBSSCoursePopUpVisible, setIsSelectBSSCoursePopUpVisible] =
    useState(false);
  const [
    isSelectBSSInstitutePopUpVisible,
    setIsSelectBSSInstitutePopUpVisible,
  ] = useState(false);

  useEffect(() => {
    setCourseDetails(props.courseDetails);
  }, [props.courseDetails]);

  useEffect(() => {
    props.handleFormDataChange(courseDetails);
  }, [courseDetails]);

  const handleChange = async (value, field) => {
    var updatedCourseDetails = { ...courseDetails };
    if (field == "bssFormCheck") {
      if (!value) {
        updatedCourseDetails.bssCourseId = null;
        updatedCourseDetails.bssInstituteId = null;
        updatedCourseDetails.isBSSFormRequired = false;
      } else {
        if(props.allBSSCourses.length ===0)
        {
          Swal.fire({
            icon: "error",
            title: "No BSS course found",
            showConfirmButton: false,
            timer: 2500,
          });
          return
        }
        updatedCourseDetails.bssCourseId = props.allBSSCourses[0]._id;
        updatedCourseDetails.bssInstituteId = props.allBSSInstitutes[0]._id;
      }
    }
    setCourseDetails(updatedCourseDetails);
  };

  const handlePopUpClose = () => {
    setIsSelectBSSCoursePopUpVisible(false);
    setIsSelectBSSInstitutePopUpVisible(false);
  };
  // To handleSave buttons on the popup
  const handlePopUpSave = async (value, popUpType) => {
    if (popUpType === "bssInstituteId") {
      setCourseDetails({ ...courseDetails, bssInstituteId: value[0]._id });
    }
    if (popUpType === "bssCourseId") {
      setCourseDetails({ ...courseDetails, bssCourseId: value[0]._id });
    }

    handlePopUpClose();
  };
  // Open popUps
  const handleOpenPopUp = (event, popUp, data) => {
    handlePopUpClose();

    switch (popUp) {
      case "bssCourseId":
        setIsSelectBSSCoursePopUpVisible(true);
        break;
      case "bssInstituteId":
        setIsSelectBSSInstitutePopUpVisible(true);
        break;
    }
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };

  return (
    <BSSFormPageView
      courseDetails={courseDetails}
      handleChange={handleChange}
      allBSSCourses={props.allBSSCourses}
      allBSSInstitutes={props.allBSSInstitutes}
      popUpPosition={popUpPosition}
      isSelectBSSCoursePopUpVisible={isSelectBSSCoursePopUpVisible}
      isSelectBSSInstitutePopUpVisible={isSelectBSSInstitutePopUpVisible}
      handlePopUpClose={handlePopUpClose}
      handlePopUpSave={handlePopUpSave}
      handleOpenPopUp={handleOpenPopUp}
    />
  );
}

export default BSSFormPage;
