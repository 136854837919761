import React, { useEffect, useState } from 'react';
import PopUpContainer from '@components/PopUpContainer';
import './style.css';

import TextInputV2 from '@components/TextInputV2';

function NewInterestsPopUpView(props) {

	return (
		<PopUpContainer
           title={"Update External Link"}
           isSearchEnabled={false}
           searchPlaceholder={""}
           isButtonsEnabled={true}
           style={{width:'378px', height:'183px'}}
           handleSearch={props.handleSearch}
           handleCancel={props.handleCancel}
           handleSave={props.handleSave}
        >
            <div className='d-flex flex-column gap-1 w-100'>
                <TextInputV2 
                    placeholder={"Link"}
                    value={props.link}
                    required={true}
                    onChange={(event)=>{props.handleChange(event.target.value, 'link')}}
                />         
            </div>
        </PopUpContainer>
	)
}

export default NewInterestsPopUpView;
