import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LoginView from "./view";
import { decryptText } from "@library/enc-dec";

import * as LoginActions from "@redux/actions/Login";
import * as LoginServices from "@services/Login";
import { encryptText } from "@library/enc-dec";

function Login(props) {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [waiting, setWaiting] = useState(false);
  const { email, password } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const handleEmail = (event) => {
    setErrorMessage("");
    props.updateEmail(event.target.value);
  };

  const handlePassword = (event) => {
    setErrorMessage("");
    props.updatePassword(event.target.value);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setWaiting(true);

    const data = await LoginServices.login(email, encryptText(password));
    if (data.success === true) {
      window.localStorage.setItem("aEmediat", encryptText(data.data.token));
      window.localStorage.setItem("aEmediatt", encryptText(Date.now()));
      window.localStorage.setItem("rEmedi", encryptText(data.data.role));
      window.localStorage.setItem("rcEmedi", data.data.roleCode);
      window.localStorage.setItem("_id", data.data._id);
      navigate("/");
    } else {
      setErrorMessage(data.message);
    }

    setWaiting(false);
  };

  return (
    <>
      <LoginView
        handleEmail={handleEmail}
        handlePassword={handlePassword}
        handleLogin={handleLogin}
        errorMessage={errorMessage}
        waiting={waiting}
      />
    </>
  );
}

function mapStateToProps(state) {
  return {
    email: state.login.email,
    password: state.login.password,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateEmail: LoginActions.updateEmail,
      updatePassword: LoginActions.updatePassword,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
