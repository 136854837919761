import React, { useEffect, useState } from "react";

import AddSeriesPopUpView from "./view";
import Swal from "sweetalert2";

import * as SeriesServices from "@services/Series";
import * as UsersServices from "@services/Users";

import { decryptText } from "@library/enc-dec";

function AddSeriesPopUp(props) {
  const [allSeries, setAllSeries] = useState([]);
  const [selectedSeriesId, setSelectedSeriesId] = useState("");
  const [selectedSeriesList, setSelectedSeriesList] = useState([]);
  const [error, setError] = useState("");
  const [isFormSaving, setIsFormSaving] = useState(false)

  const handleSeriesChange = (event) => {
    setSelectedSeriesId(event.target.value);
  };

  const handleAddNewSeries = (event) => {
    let selectedSeries = {};
    allSeries.forEach((series) => {
      if (series._id === selectedSeriesId) {
        selectedSeries = series;
      }
    });

    setSelectedSeriesList((prevSeriesList) => [
      ...prevSeriesList,
      selectedSeries,
    ]);
  };

  const handleDeleteSeries = (event, seriesId) => {
    const updatedSeries = selectedSeriesList.filter(
      (series) => series._id !== seriesId
    );
    setSelectedSeriesList(updatedSeries);
    event.stopPropagation();
  };

  const handleCloseBtn = () => {
    props.handleClose();
  };

  const handleSaveBtn = async () => {
    setIsFormSaving(true)
    if (selectedSeriesList === "") {
      setError("No Series Selected");
      setIsFormSaving(false)
      setTimeout(function () {
        setError("");
      }, 2000);
    } else {
      if (!props.purchaseIds) {
        props.handleSave(selectedSeriesList);
        setIsFormSaving(false)
      } else {
        addSeriesForUser();
      }
    }
  };

  const addSeriesForUser = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));

      const payload = {
        purchaseIds: props.purchaseIds,
        seriesIds: [...new Set(selectedSeriesList.map((item) => item._id))],
      };

      var response = await UsersServices.addSeriesForUser(payload, authToken);

      if (response.success) {
        Swal.fire({
          icon: "success",
          title: `Series added to ${response.data.modifiedCount} out of ${props.purchaseIds.length} users`,
          showConfirmButton: false,
          timer: 2500,
        });
        props.handleSave();
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
        props.handleClose();
      }
      
      setIsFormSaving(false)
    } catch (err) {
      
      setIsFormSaving(false)
      console.log("Error coming while adding series", err);
    }
  };

  useEffect(() => {
    getAllSeries();
  }, [props.visible]);

  const getAllSeries = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        pageNo: 0,
        limit: 1000,
        isSorted: true,
      };
      const response = await SeriesServices.getAllSeries(payload, authToken);
      if (response.success) {
        setAllSeries(response.data);
      }
    } catch (err) {
      console.log("Error coming while fetching all series", err);
    }
  };

  return (
    <AddSeriesPopUpView
      allSeries={allSeries}
      selectedSeriesList={selectedSeriesList}
      error={error}
      handleSeriesChange={handleSeriesChange}
      handleAddNewSeries={handleAddNewSeries}
      handleDeleteSeries={handleDeleteSeries}
      handleCloseBtn={handleCloseBtn}
      handleSaveBtn={handleSaveBtn}
      isFormSaving={isFormSaving}
    />
  );
}

export default AddSeriesPopUp;
