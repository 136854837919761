import React from "react";

import './examHome.css';

function ExamHomeView(){
    return(
        <>
            {/* <h1 style={{marginLeft:'240px'}}>ExamHomeView</h1> */}
        </>
    )
}

export default ExamHomeView;