import React, { useEffect, useState } from 'react';
import PopUpContainer from '@components/PopUpContainer';
import './style.css';

import TextInputV2 from '@components/TextInputV2';

function NewDegreePopUpView(props) {

	return (
		<PopUpContainer
           title={"Create/Update Undergraduate Degree"}
           isSearchEnabled={false}
           searchPlaceholder={""}
           isButtonsEnabled={true}
           style={{width:'378px', height:'183px'}}
           handleSearch={props.handleSearch}
           handleCancel={props.handleCancel}
           handleSave={props.handleSave}
        >
            <div className='d-flex flex-column gap-1 w-100'>
                <TextInputV2 
                    placeholder={"UG degree name"}
                    value={props.title}
                    required={true}
                    onChange={(event)=>{props.handleChange(event.target.value, 'title')}}
                />         
            </div>
        </PopUpContainer>
	)
}

export default NewDegreePopUpView;
