import React, { useEffect, useState, useRef } from "react";

import ExamResultView from "./view";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { decryptText } from "@library/enc-dec";
import * as ExamServices from "@services/Exam";

function ExamResult(props) {
  const dataFetchedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  // Below purchase id is required to run this page ( Replace with dynamic purchase id )
  const purchaseId = props.purchaseId;
  const resultId = props.resultId;

  const [resultDetails, setResultDetails] = useState({});
  const [totalNumberOfQuestions, setTotalNumberOfQuestions] = useState(0);

  const [allQuestions, setAllQuestions] = useState([]);
  const [questionSet, setQuestionSet] = useState([]);

  const [minQuestionNo, setMinQuestionNo] = useState(0);
  const [maxQuestionNo, setMaxQuestionNo] = useState(0);

  const [isNextBtnVisible, setIsNextBtnVisible] = useState(true);
  const [isPreviousBtnVisible, setIsPreviousBtnVisible] = useState(true);

  useEffect(() => {
    window.scroll(0, 0);
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    handleResult();
  }, []);

  const handleResult = async () => {
    try {
      setIsLoading(true);
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const result = await ExamServices.getResult(
        purchaseId,
        resultId,
        authToken
      );
      // const result = {
      // 	"success": true,
      // 	"message": "Succesfully fetched the result.",
      // 	"data": {
      // 		"_id": "65ac55ba6caba8c1ff59cbc6",
      // 		"title": "Certificate Course in Gynocology",
      // 		"purchaseId": "6504add46cdae2176a538ac1",
      // 		"orderId": "order_free_649fd400950578d68056e8ed_6491deebb376d888af26df52_gq1pnsvi71",
      // 		"userId": "649fd400950578d68056e8ed",
      // 		"courseId": "6491deebb376d888af26df52",
      // 		"examId": "648b498a3580232d0f0c315a",
      // 		"examTitle": "Certificate Course in Gynaecology and Obstetrics (CGO) Exam Set 11",
      // 		"courseType": "free",
      // 		"attempts": [
      // 			{
      // 				"question": "How many ANC visits does WHO recommend?",
      // 				"options": [
      // 					"a) Six",
      // 					"b) Ten",
      // 					"c) Four",
      // 					"d) Twelve"
      // 				],
      // 				"answer": "c) Four",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28093",
      // 				"selectedOption": "a) Six"
      // 			},
      // 			{
      // 				"question": "For how many days after birth is the vaginal defence in a new born considered to be good?",
      // 				"options": [
      // 					"a) 11 to 20 days",
      // 					"b) 10 to 12 days",
      // 					"c) 5 to 12 days",
      // 					"d) 10 to 15 days"
      // 				],
      // 				"answer": "b) 10 to 12 days",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28083",
      // 				"selectedOption": "a) 11 to 20 days"
      // 			},
      // 			{
      // 				"question": "Hormonal replacement therapy is indicated in menopausal women for which of the\nfollowing?",
      // 				"options": [
      // 					"a) Hot flushes",
      // 					"b) Ca Breast",
      // 					"c) Endometriosis",
      // 					"d) Uterine bleeding"
      // 				],
      // 				"answer": "a) Hot flushes",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28072",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "All of the following are true about physiological anemia, except?",
      // 				"options": [
      // 					"a) Maternal plasma volume increases by about 40–50%.",
      // 					"b) RBC volume increases by 20%.",
      // 					"c) There is relative increase in the level of hemoglobin and hematocrit during pregnancy.",
      // 					"d) All these values return to normal by 6 weeks postpartum."
      // 				],
      // 				"answer": "c) There is relative increase in the level of hemoglobin and hematocrit during pregnancy.",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f280a2",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "Cryptomenorrhea occurs in:",
      // 				"options": [
      // 					"a) Imperforate hymen",
      // 					"b) PCOS",
      // 					"c) Fibroids",
      // 					"d) All of the above"
      // 				],
      // 				"answer": "a) Imperforate hymen",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28078",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "Common growth abnormality seen in fetuses of GDM mothers, include which of the following?",
      // 				"options": [
      // 					"a) Macrosomia",
      // 					"b) IUGR",
      // 					"c) Both a and b",
      // 					"d) None of the above"
      // 				],
      // 				"answer": "c) Both a and b",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f280a4",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "The causative organisms in PID are known to:",
      // 				"options": [
      // 					"a) Ascend upwards towards the fallopian tubes and contagious structures",
      // 					"b) Descend downwards towards the vagina",
      // 					"c) Both a and b",
      // 					"d) None of the above"
      // 				],
      // 				"answer": "a) Ascend upwards towards the fallopian tubes and contagious structures",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28081",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "Which of the following best defines anemia?",
      // 				"options": [
      // 					"a) Decreased oxygen capacity of blood",
      // 					"b) Reduced hemoglobin in blood",
      // 					"c) Decrease in red blood cells",
      // 					"d) All of the above"
      // 				],
      // 				"answer": "d) All of the above",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f280a0",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "What is monitored in a patient of preeclampsia?",
      // 				"options": [
      // 					"a) Uric acid",
      // 					"b) LFT",
      // 					"c) Platelet count",
      // 					"d) All of the above"
      // 				],
      // 				"answer": "d) All of the above",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f2809b",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "Which of the following is the correct sequence of ‘stages of development’ during puberty?",
      // 				"options": [
      // 					"a) Pubarche—thelarche—menarche---adrenarche",
      // 					"b) Adrenarche ---thelarche ---pubarche—menarche",
      // 					"c) Menarche --- thelarche ---adrenarche--- pubarche",
      // 					"d) Thelarche---menarche –adrenarche ---pubarche"
      // 				],
      // 				"answer": "b) Adrenarche ---thelarche ---pubarche—menarche",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28085",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "Below what levels of beta hCG levels can expectant management of ectopic pregnancy can be done?",
      // 				"options": [
      // 					"a) 10,000IU/L",
      // 					"b) 2500IU/L",
      // 					"c) 1000IU/L",
      // 					"d) 5000IU/L"
      // 				],
      // 				"answer": "d) 5000IU/L",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f2808b",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "Modified biophysical profile is:",
      // 				"options": [
      // 					"a) NST + Fetal tone",
      // 					"b) NST + AFI",
      // 					"c) NST + Fetal tone + AFI",
      // 					"d) AFI + NST"
      // 				],
      // 				"answer": "d) AFI + NST",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28090",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "Female with 41 weeks gestation confirmed by radiological investigation, very sure of here LMP, no uterine contractions, no effacement and no dilatation. What should not be done:",
      // 				"options": [
      // 					"a) Intracervical foley’s",
      // 					"b) PGE1 tablet",
      // 					"c) PGF2 alpha",
      // 					"d) PGE2 gel"
      // 				],
      // 				"answer": "c) PGF2 alpha",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28098",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "Which of the following SERM used in AUB?",
      // 				"options": [
      // 					"a) Clomiphene",
      // 					"b) Raloxifene",
      // 					"c) Ormeloxifene",
      // 					"d) Mifepristone"
      // 				],
      // 				"answer": "c) Ormeloxifene",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f2806d",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "Menopause is defined as",
      // 				"options": [
      // 					"a) Presence of hot flushes",
      // 					"b) Cessation of menses for 6 months",
      // 					"c) Cessation of menses for 1 year",
      // 					"d) Cessation of menses for 2 years"
      // 				],
      // 				"answer": "c) Cessation of menses for 1 year",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28071",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "While taking the details of ‘menstrual history’, all of the following should be asked, except?",
      // 				"options": [
      // 					"a) Duration of menses",
      // 					"b) Regularity of cycles",
      // 					"c) Length of bleeding",
      // 					"d) Last day of last menstrual period"
      // 				],
      // 				"answer": "d) Last day of last menstrual period",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28087",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "Pearl necklace appearance is characteristic of:",
      // 				"options": [
      // 					"a) Ectopic pregnancy",
      // 					"b) Endometriosis",
      // 					"c) PCOS",
      // 					"d) Hydatidiform mole"
      // 				],
      // 				"answer": "c) PCOS",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28073",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "Which of the following determine the underlying pathology of PCOS?",
      // 				"options": [
      // 					"a) HPO axis dysfunction",
      // 					"b) Androgen excess",
      // 					"c) Anovulation",
      // 					"d) All of the above"
      // 				],
      // 				"answer": "d) All of the above",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28075",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "Regarding progesterone only pill, which of the following is incorrect?",
      // 				"options": [
      // 					"Ovulation is stopped completely",
      // 					"Ovulation can occur sometime",
      // 					"Make cervical mucus thick",
      // 					"Interfere with implantation"
      // 				],
      // 				"answer": "Ovulation is stopped completely",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28080",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "All are components of active management of the third stage of labor except:",
      // 				"options": [
      // 					"a) Uterotonic agent within 1 minute of birth",
      // 					"b) Massage of uterus before control cord traction",
      // 					"c) Control cord traction",
      // 					"d) None of the above"
      // 				],
      // 				"answer": "b) Massage of uterus before control cord traction",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28096",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "All of the following are true about GDM in pregnancy, except?",
      // 				"options": [
      // 					"a) There is increased insulin sensitivity in first trimester",
      // 					"b) There is increased insulin resistance in second and third trimesters",
      // 					"c) There is a state of fasting hypoglycemia especially in first trimester",
      // 					"d) GDM is more common in first trimester"
      // 				],
      // 				"answer": "d) GDM is more common in first trimester",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f280a3",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "Amniocentesis for fetal karyotyping is done between:",
      // 				"options": [
      // 					"a) 8 to 10 weeks",
      // 					"b) 10 to 12 weeks",
      // 					"c) 15 to 18 weeks",
      // 					"d) 19 to 22 weeks"
      // 				],
      // 				"answer": "c) 15 to 18 weeks",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f2808e",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "What is not used in PCOS:",
      // 				"options": [
      // 					"a) OC pills",
      // 					"b) Cyclical progesterone",
      // 					"c) Myoinositol",
      // 					"d) Danazol"
      // 				],
      // 				"answer": "d) Danazol",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28074",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "Features of non – severe /mild preeclampsia are all except?",
      // 				"options": [
      // 					"a) Diastolic BP <100mmHg",
      // 					"b) Systolic BP <160mmHg",
      // 					"c) Mild IUGR",
      // 					"d) No premonitory symptoms"
      // 				],
      // 				"answer": "c) Mild IUGR",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f2809a",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "Which of the following are important while taking the ‘personal history’ of gynaecological patient?",
      // 				"options": [
      // 					"a) Sexual history (sexual dysfunction/dyspareunia)",
      // 					"b) Pap smear screening",
      // 					"c) History of vaccination (HPV)",
      // 					"d) All of the above"
      // 				],
      // 				"answer": "d) All of the above",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28088",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "‘Lightening’ is a feeling felt by a pregnant woman during last trimester. This indicates:",
      // 				"options": [
      // 					"a) Aggravation of pressure symptoms",
      // 					"b) Relief of pressure symptoms",
      // 					"c) Engagement of presenting part",
      // 					"d) Both a and c"
      // 				],
      // 				"answer": "d) Both a and c",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28097",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "Average duration of first stage of labor in primigravida is:",
      // 				"options": [
      // 					"a) 6 hours",
      // 					"b) 12 hours",
      // 					"c) 20 hours",
      // 					"d) 18 hours"
      // 				],
      // 				"answer": "b) 12 hours",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28095",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "Important aspects of gynaecological examination are:",
      // 				"options": [
      // 					"a) Breast, abdominal and respiratory examination",
      // 					"b) Breast, cardiovascular and pelvic examination",
      // 					"c) Abdominal, breast and pelvic examination",
      // 					"d) Pelvic, neurological and breast examination"
      // 				],
      // 				"answer": "c) Abdominal, breast and pelvic examination",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28089",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "The ‘First ANC visit’ according to WHO should be during which week of pregnancy?",
      // 				"options": [
      // 					"a) 6 weeks",
      // 					"b) 16 weeks",
      // 					"c) 10 weeks",
      // 					"d) 24 weeks"
      // 				],
      // 				"answer": "b) 16 weeks",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f28094",
      // 				"selectedOption": null
      // 			},
      // 			{
      // 				"question": "A 32-year-old woman is presented with complaints of heavy menstrual bleeding for 6\nmonths which is not controlled by non-hormonal treatment. TVS and per rectal\nexamination were normal. What is the next appropriate step in management?",
      // 				"options": [
      // 					"a) Endometrial sampling",
      // 					"b) Hormonal treatment",
      // 					"c) Hysterectomy",
      // 					"d) Hormonal treatment"
      // 				],
      // 				"answer": "d) Hormonal treatment",
      // 				"thumbnailUrl": null,
      // 				"_id": "64eb32dcc9cb7a1a14f2806f",
      // 				"selectedOption": null
      // 			}
      // 		],
      // 		"outOff": 30,
      // 		"totalAttempts": 2,
      // 		"correctAttempts": 0,
      // 		"inCorrectAttempts": 30,
      // 		"percentage": 0,
      // 		"result": "failed",
      // 		"message": "<p>Sorry you have failed the exam. Please contact +91 9822081133 for re-access.</p>",
      // 		"createdAt": "2024-01-20T23:22:34.148Z",
      // 		"updatedAt": "2024-01-20T23:22:34.148Z",
      // 		"__v": 0
      // 	}
      // }
      if (result.success) {
        setResultDetails(result);
        setIsLoading(false);
        return;
      }
    } catch (err) {
      console.log("Error coming from: handleResult()", err);
    }
  };

  useEffect(() => {
    setFirstSetOfQuestions();
  }, [resultDetails]);

  const setFirstSetOfQuestions = () => {
    if (
      resultDetails &&
      resultDetails.success &&
      resultDetails.data.attempts.length > 0
    ) {
      let dbQuestionSet = resultDetails.data.attempts;

      let questionSetFromDb = dbQuestionSet;
      setTotalNumberOfQuestions(questionSetFromDb.length);
      setAllQuestions(questionSetFromDb);
      let min;
      let max;
      let slicedQuestionsArray;

      if (questionSetFromDb.length > 10) {
        min = 0;
        max = 10;
        setMinQuestionNo(min);
        setMaxQuestionNo(max);

        setIsPreviousBtnVisible(false);
      } else {
        min = 0;
        max = questionSetFromDb.length;
        setMinQuestionNo(min);
        setMaxQuestionNo(max);

        setIsPreviousBtnVisible(false);
        setIsNextBtnVisible(false);
      }

      slicedQuestionsArray = resultDetails.data["attempts"].slice(min, max);
      setQuestionSet(slicedQuestionsArray);
    }
  };

  const handlePreviousBtn = () => {
    let min = minQuestionNo;
    let max = maxQuestionNo;
    let slicedQuestionsArray;
    if (min - 10 > 0) {
      max = min;
      min = min - 10;

      setMinQuestionNo(min);
      setMaxQuestionNo(max);

      setIsNextBtnVisible(true);
    } else {
      max = min;
      min = 0;

      setMinQuestionNo(min);
      setMaxQuestionNo(max);

      setIsPreviousBtnVisible(false);
      setIsNextBtnVisible(true);
    }

    slicedQuestionsArray = allQuestions.slice(min, max);
    setQuestionSet(slicedQuestionsArray);

    window.scroll(0, 0);
  };

  const handleNextBtn = () => {
    let min = minQuestionNo;
    let max = maxQuestionNo;
    let slicedQuestionsArray;
    if (totalNumberOfQuestions > max + 10) {
      min = max;
      max = max + 10;

      setMinQuestionNo(min);
      setMaxQuestionNo(max);

      setIsPreviousBtnVisible(true);
    } else {
      min = max;
      max = totalNumberOfQuestions;

      setMinQuestionNo(min);
      setMaxQuestionNo(max);

      setIsNextBtnVisible(false);
      setIsPreviousBtnVisible(true);
    }

    slicedQuestionsArray = allQuestions.slice(min, max);
    setQuestionSet(slicedQuestionsArray);

    window.scroll(0, 0);
  };

  return (
    <>
      <ExamResultView
        handleNextBtn={handleNextBtn}
        handlePreviousBtn={handlePreviousBtn}
        isNextBtnVisible={isNextBtnVisible}
        isPreviousBtnVisible={isPreviousBtnVisible}
        questionSet={questionSet}
        isLoading={isLoading}
        resultDetails={resultDetails}
      />
    </>
  );
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ExamResult);

// export default ExamResult;
