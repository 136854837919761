import React, { useEffect, useState, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import SEOView from "./view";
import * as CourseServices from "@services/Course";
import { decryptText } from "@library/enc-dec";

import ErrorView from "@components/ErrorView";
import { getEventElementPosition } from "@helpers/common.js";
import Swal from "sweetalert2";
import parse from "html-react-parser";

// Define custom CSS styles for different HTML tags
const tagStyles = {
  html: {
    color: "blue",
  },
  head: {
    color: "green",
  },
  title: {
    color: "green",
  },
  meta: {
    color: "red",
  },
};

function SEO(props) {
  const { id } = useParams();

  const [SEOInfo, setSEOInfo] = useState({});

  const [formValuesEmpty, setFormValuesEmpty] = useState(false);
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({});
  const [isFormSaving, setIsFormSaving] = useState(false);
  const [parsedHtml, setParsedHtml] = useState("");

  useEffect(() => {
    getSEOInfo();
  }, []);

  useEffect(() => {
    let htmlString = `<html>
  <head>
    <title>${SEOInfo.seoTitleTag}</title>
      <meta name="description" content="${SEOInfo.seoMetaDescription}">
  </head>            
</html>`;
    setParsedHtml(htmlString);
  }, [SEOInfo]);

  const replaceTag = (node, index) => {
    if (node.type === "tag") {
      const { name, children } = node;
      const style = tagStyles[name] || {}; // Apply custom styles
      return React.createElement(
        name,
        { key: index, style },
        children && children.map((child, idx) => replaceTag(child, idx))
      );
    }
    return node.data; // Return non-tag elements as is
  };

  const getSEOInfo = async () => {
    try {
      if (id) {
        let authToken = decryptText(localStorage.getItem("aEmediat"));
        let response = await CourseServices.getCourseById(id, authToken);
        if (response.success) {
          setSEOInfo(response.data);

          return;
        }
      }
    } catch (err) {
      console.log("Error coming while fetching course details", err);
    }
  };

  const handleSave = async () => {
    try {
      setIsFormSaving(true);
      let authToken = decryptText(localStorage.getItem("aEmediat"));

      const payload = {
        courseId: id,
        seoUrlSlug: SEOInfo.seoUrlSlug,
        seoTitleTag: SEOInfo.seoTitleTag,
        seoOGTitle: SEOInfo.seoOGTitle,
        seoOGDescription: SEOInfo.seoOGDescription,
        seoMetaDescription: SEOInfo.seoMetaDescription,
        thumbnailUrl: SEOInfo.seoThumbnailUrl,
      };
      const response = await CourseServices.updateSEODetails(
        payload,
        authToken
      );
      if (response.success) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
      setIsFormSaving(false);
    } catch (err) {
      console.log("Error coming while updating SEO", err);
      setIsFormSaving(false);
    }
  };

  const handleChange = async (value, field) => {
    if (
      (field === "seoUrlSlug" || field === "seoTitleTag") &&
      value.length > 100
    ) {
      return;
    }
    if (field === "seoMetaDescription" && value.length > 256) {
      return;
    }
    if (field === "seoThumbnailUrl") {
      value = value[0];
      handlePopUpClose();
    }
    var newInfo = { ...SEOInfo };
    newInfo[field] = value;
    setSEOInfo(newInfo);
  };

  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
  };
  // Open popUps
  const handleOpenPopUp = (event, index) => {
    setIsPopUpVisible(true);
    event.stopPropagation();
  };

  const updateBlogThumbnail = async (image) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        thumbnailUrl: image,
      };
      let response = await CourseServices.updateBlogThumbnail(
        payload,
        authToken
      );
      if (response.success) {
        return response.data.thumbnailUrl;
      }
      alert(response.message);
    } catch (err) {
      console.log("Error coming while uploading blog thumbnail", err);
    }
  };

  return (
    <>
      {id ? (
        <SEOView
          SEOInfo={SEOInfo}
          formValuesEmpty={formValuesEmpty}
          isFormSaving={isFormSaving}
          handleChange={handleChange}
          handleSave={handleSave}
          parsedHtml={parsedHtml}
          isPopUpVisible={isPopUpVisible}
          popUpPosition={popUpPosition}
          handlePopUpClose={handlePopUpClose}
          handleOpenPopUp={handleOpenPopUp}
        />
      ) : (
        <ErrorView
          text={"Please Select or Add a course before adding SEO information."}
        />
      )}
    </>
  );
}

export default SEO;
