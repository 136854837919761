import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention';


import './style.css';
import mentionsStyles from './MentionsStyles.module.css';

function Entry(props) {
  const { mention, theme, ...parentProps } = props;
  return (
    <div {...parentProps}>
      <div className={theme?.mentionSuggestionsEntryContainer}>
        <div className={theme?.mentionSuggestionsEntryContainerLeft}>
          <img
            src={mention.displayPhoto}
            className={theme?.mentionSuggestionsEntryAvatar}
            role="presentation"
          />
        </div>

        <div className={theme?.mentionSuggestionsEntryContainerRight}>
          <div className={theme?.mentionSuggestionsEntryText}>
            {mention.fullName}
          </div>

          <div className={theme?.mentionSuggestionsEntryTitle}>
            {mention.email}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function CustomMentionEditor(props) {
  const ref = useRef(null);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(props.userList);

  useEffect(()=>{
       setSuggestions(props.userList)
  },[props.userList])

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: mentionsStyles,
      mentionPrefix: '@',
      supportWhitespace: true,
    });
    const MentionSuggestions = mentionPlugin.MentionSuggestions;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onChange = useCallback((editorState) => {
    setEditorState(editorState);
  }, []);
  
  const onOpenChange = useCallback((open) => {
    setOpen(open);
  }, []);

  const onSearchChange = useCallback(({ value }) => {
    setSuggestions(defaultSuggestionsFilter(value, props.userList));
  }, []);

  useEffect(()=>{
    const contentState = editorState.getCurrentContent();
    const raw = convertToRaw(contentState);
    const text = raw.blocks.map((line, index)=>{ return line.text}).join(' ')

    let mentionedUsers = [];
    for (let key in raw.entityMap) {
      const ent = raw.entityMap[key];
      if (ent.type === "mention") {
        mentionedUsers.push(ent.data.mention);
      }
    }
    
    props.onChange(text, mentionedUsers)
  },[editorState])


  return (
    <div className='editor'
      onClick={() => { ref.current.focus();}} >
      <Editor
        editorKey={'editor'}
        editorState={editorState}
        onChange={onChange}
        plugins={plugins}
        ref={ref}
      />
      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
        onAddMention={() => {
          // get the mention object selected
        }}
        entryComponent={Entry}
        popoverContainer={({ children }) => <div>{children}</div>}
      />
    </div>
  );
}