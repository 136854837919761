import React, { useEffect, useState, useRef  } from 'react';
import PopUpContainer from '@components/PopUpContainer';

import Swal from 'sweetalert2';

function DeleteCoursePopUp(props) {
	const [formalRequestText, setFormalRequestText]=useState('')

	const handleSave=()=>{
       if(props.isFormalRequest && formalRequestText.length<100)
	   {
		Swal.fire({icon: "info",title: "Please add request description of minimum 100 characters",showConfirmButton: false,timer: 2500});
	    return   
	   }
		props.handlePopUpSave(formalRequestText)
	}
	return (
		<PopUpContainer
						title={"Delete courses"}
						isSearchEnabled={false}
						searchPlaceholder={""}
						isButtonsEnabled={true}
						style={{width:'451px', height: props.isFormalRequest?'356px':'206px'}}
						handleSearch={()=>{}}
						handleCancel={props.handlePopUpClose}
						handleSave={handleSave}
						>
					<div className='d-flex flex-column gap-2 w-100'>
						<div><b>{props.selectedCount}</b> user(s) selected</div>
						Do you want to continue with course deletion?
						{props.isFormalRequest &&
						<div className='border-top d-flex flex-column gap-2 py-2'>
							<div className='d-flex'>
								Submit formal request
								<span className='ms-auto text-muted'>Minimum 100 characters*</span>
							</div>
							
							<div className="form-floating" >
								<textarea className="form-control" placeholder="" id="cardDesc" style={{height:'158px'}}   
									value={formalRequestText}
									onChange={(e)=>setFormalRequestText(e.target.value)}
							></textarea>
								<label htmlFor="cardDesc">Request will be sent to administrative team for approval<span>*</span></label>
							</div>
						</div>}
						</div>
				</PopUpContainer>
	);
}

export default DeleteCoursePopUp;
