import React, { useEffect, useState } from 'react';

import NewBulletPopUpView from './view';
import {getEventElementPosition, toggleFullscreen} from '@helpers/common.js';
import Swal from 'sweetalert2';

function NewBulletPopUp(props) {
    const[icon, setIcon] = useState(props.icon);
	const[isPopUpVisible, setIsPopUpVisible]= useState(false);
	const[popUpPosition, setPopUpPosition]=useState({});

	const handleChange=(value, key)=>{
	   var updatedIcon = {...icon};
	   updatedIcon[key] = value
	   setIcon(updatedIcon)
	}

	const handleCancel=()=>{
		props.handleCancel()
	}
	const handleSave=()=>{
		props.handleSave(icon)
	}

	// To close the popup container
	const handlePopUpOpen = (event) => {
		handlePopUpClose()
		setIsPopUpVisible(true)
		let position = getEventElementPosition(event);
		setPopUpPosition(position);
		event.stopPropagation();
	};
	const handlePopUpClose = () => {
		setIsPopUpVisible(false)
	};
	
	const handlePopUpSave = async (event, files) => {
		event.stopPropagation();
		if(files[0].type === 'image/svg+xml')
		{
			handleChange(files[0], 'assetUrl')
		}else{
			Swal.fire({icon: "error",title: "Please select SVG file only",showConfirmButton: false,timer: 2500});
		}
		handlePopUpClose();
	};
	
	return (
		<NewBulletPopUpView
		   icon = {icon}	
		   handleChange={handleChange}
		   handleCancel ={handleCancel}
		   handleSave={handleSave}
		   isPopUpVisible={isPopUpVisible}
			handlePopUpClose = {handlePopUpClose} 
			handlePopUpOpen = {handlePopUpOpen} 
			handlePopUpSave= {handlePopUpSave}
			/>
	);
}

export default NewBulletPopUp;
