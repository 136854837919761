import React, { useEffect, useState, useRef } from "react";
import { decryptText } from "@library/enc-dec";

import * as CourseServices from "@services/Course";

import EditTitlePopUpView from "./view";

function EditTitlePopUp(props) {
  const [error, setError] = useState("");

  const [displayName, setDisplayName] = useState(props.displayName);
  const [internalName, setInternalName] = useState(props.internalName);
  const [squareThumbnail, setSquareThumbnail] = useState(
    props.squareThumbnailUrl
  );
  const [rectThumbnail, setRectThumbnail] = useState(
    props.rectangleThumbnailUrl
  );

  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [uploadType, setUploadType] = useState(false);

  const squareThumbDiv = useRef(null);
  const rectThumbDiv = useRef(null);

  const handleUploadClick = (event, type) => {
    setUploadType(type);
    setIsPopUpVisible(true);
    event.stopPropagation();
  };

  const handleCloseBtn = () => {
    props.handleClose();
  };

  const handleSaveBtn = async () => {
    try {
      if (
        displayName !== "" ||
        internalName !== "" ||
        squareThumbnail !== "" ||
        rectThumbnail !== ""
      ) {
        const paylaod = {
          courseId: props.courseId,
          title: displayName,
          rectangleThumbnailUrl: rectThumbnail,
          squareThumbnailUrl: squareThumbnail,
          internalTitle: internalName,
        };
        let authToken = decryptText(localStorage.getItem("aEmediat"));
        let response = await CourseServices.updateThumbnailAndTitle(
          paylaod,
          authToken
        );

        if (response.success) {
          props.handleSave(response);
        } else {
          alert(response.message);
          props.handleSave(response);
        }
      } else {
        setError("All Fields Required!");
        setTimeout(function () {
          setError("");
        }, 2000);
      }
    } catch (err) {
      console.log("Error coming while saving course title and thubnail", err);
    }
  };

  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
  };

  // To handleSave buttons on the popup

  const handleAddImages = (event, files) => {
    let file = files[0];
    
    if(typeof file !== 'string'){
      var fileUrl = `url(${URL.createObjectURL(file)})`;
    }

    if (uploadType === "rect") {
      setRectThumbnail(file);
      rectThumbDiv.current.style.backgroundImage = fileUrl
    } else {
      setSquareThumbnail(file);
      squareThumbDiv.current.style.backgroundImage = fileUrl
    }

    setIsPopUpVisible(false);
    event.stopPropagation();
  };

  return (
    <EditTitlePopUpView
      error={error}
      displayName={displayName}
      internalName={internalName}
      isPopUpVisible={isPopUpVisible}
      squareThumbDiv={squareThumbDiv}
      rectThumbDiv={rectThumbDiv}
      squareThumbnail={squareThumbnail}
      rectThumbnail={rectThumbnail}
      uploadType={uploadType}
      handleDisplayNameChange={setDisplayName}
      handleInternalNameChange={setInternalName}
      handleCloseBtn={handleCloseBtn}
      handleSaveBtn={handleSaveBtn}
      handleUploadClick={handleUploadClick}
      handlePopUpClose={handlePopUpClose}
      handleAddImages={handleAddImages}
    />
  );
}

export default EditTitlePopUp;
