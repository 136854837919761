const initialState = {
  selectedPurchaseIds: [],
  userDetails: {
    _id: "",
    role: "",
    roleCode: "",
    firstName: "",
    lastName: "",
    fullName: "",
    displayName: "",
    displayPhoto: "",
    contactNumber: "",
    country: "",
    countryCode: "",
    username: "",
    email: "",
    password: "",
    emailAuthToken: "",
    authToken: "",
    dob: "",
    gender: "",
    whatsAppNumber: "",
    whatsAppCountryCode: "",
    whatsAppCountry: "",
    flatHouseNumber: "",
    buildingName: "",
    colonyName: "",
    areaName: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
    addharOrIdProof: "",
    marksheetOrDegreeCert: "",
    qualification: "",
    passportPhoto: "",
    isEmailVerified: false,
    fullNameStatus: "UnVerified",
    addharStatus: "UnVerified",
    marksheetStatus: "UnVerified",
    qualificationStatus: "UnVerified",
    passportPhotoStatus: "UnVerified",
    isPersonalInfoCompleted: false,
    isAddressInfoCompleted: false,
    isIdentityInfoCompleted: false,
    sendNotification: false,
    createdAt: "",
    updatedAt: "",
    profession: "",
    middleName: "",
    legalInfoAcknowledged: false,
    identityAcknowledged: false,
    isUnderGraduate: false,
    underGraduateDegree: null,
    isLegalInfoCompleted: false,
    verificationSubmittedAt: null,
    verifiedAt: null,
    lastActiveAt: null,
    pageRights: [],
    userRights: [],
    bssRights: [],
    leadsRights: [],
    customerCareRights: [],
    notes: "",
    userManualTags: [],
    addressMode: "",
    lastAddressUpdatedAt: null,
    assignedTestExams: [],
    isBasicDetailsRequired: true,
    isIdentityVerificationRequired: true,
    isLegalInfoRequired: true,
    isDeliveryAddressRequired: true,
    isBSSDetailsRequired: true,
    isOTPRequired: true,
    userId: "",
    isContactNumberVerified: false,
    bssDetails: [
      {
        guardianName: "",
        bloodGroup: "",
        underGraduateDegree: "",
        underGraduateInstituteName: "",
        underGraduateInstituteAddress: "",
        registrationNumber: "",
        passingYear: "",
        aadhaarCard: "",
        panCard: "",
        otherDocs: "",
        signature: "",
        placeOfSignature: "",
        dateOfSignature: "",
        bssAcknowledged: false,
        guardianNameStatus: "unVerified",
        underGraduateDegreeStatus: "unVerified",
        underGraduateInstituteNameStatus: "unVerified",
        underGraduateInstituteAddressStatus: "unVerified",
        registrationNumberStatus: "unVerified",
        passingYearStatus: "unVerified",
        signatureVerificationStatus: "unVerified",
        aadhaarVerificationStatus: "unVerified",
        panVerificationStatus: "unVerified",
        otherDocsVerificationStatus: "unVerified",
        placeOfSignatureStatus: "unVerified",
        bssVerificationStatus: "unVerified",
        isBssInfoCompleted: false,
      },
    ],
  },
  filters: {
    page: 0,
    limit: 20,
    sortBy: "createdAt",
    isSorted: false,
    searchText: "",
    startDate: "",
    endDate: "",
    examStatus: null,
    courseIds: [],
    categories: [],
    yearTag: "",
    monthTag: "",
    mainTag: "",
    tagStatus: "",
    subTag: "",
    manualTag: "",
  },
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_USER_DETAILS":
      return {
        ...state,
        userDetails: action.payload.userDetails,
      };
    case "UPDATE_USER_FILTERS":
      return {
        ...state,
        filters: action.payload.filters,
      };
    case "UPDATE_SELECTED_PURCHASE_IDS":
      return {
        ...state,
        selectedPurchaseIds: action.payload.selectedPurchaseIds,
      };
    case "RESET_USERS_REDUCER":
      return {
        ...state,
        userDetails: initialState.userDetails,
      };
    default:
      return state;
  }
};

export default UserReducer;
