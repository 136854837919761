const initialState = {
	filters:{page: 0,
			limit:20,
			sortBy: 'createdAt',
			isSorted: false,
			searchText: '',
		}
};

const AdminUserReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_ADMIN_USER_FILTERS':
			return{
				...state,
				filters: action.payload.filters,
			}
		case 'RESET_ADMIN_USERS_REDUCER':
			return initialState;
		default:
			return state;
	}
};

export default AdminUserReducer;
