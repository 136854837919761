import GoBackArrow from "@assets/sidebarNavigation/goBackArrow.png";
import EditPencilIcon from "@assets/common/editPencilIcon.png";
import CommentsIcon from "@assets/common/commentsIcon.png";

import AnalyticsIcon from "@assets/common/analyticsIcon.png";

export const navigationData = [
  {
    title: "Back to Series",
    route: "/series",
    default: false,
    icon: GoBackArrow,
  },
  {
    title: "Details",
    component: "VideoDetails",
    default: false,
    icon: EditPencilIcon,
  },
  {
    title: "Comments",
    component: "VideoComments",
    default: false,
    icon: CommentsIcon,
  },
  // {
  //   title: "Analytics",
  //   component: "VideoAnalytics",
  //   default: false,
  //   icon: AnalyticsIcon,
  // },
];
