import React from "react";

import { Draggable } from "react-beautiful-dnd";

import "./dnd.css";

function DnDView(props) {
  const { element, newComponent, index } = props;
  return (
    <>
      <Draggable
        key={element._id}
        draggableId={element._id}
        index={index}
        isDragDisabled={false}
      >
        {(provided) => (
          <div
            data-local_index={index}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            {/* IF want to send additional props : */}
            {/* {React.cloneElement(data.newComponent, { index: index })} */}
            {newComponent}
          </div>
        )}
      </Draggable>
    </>
  );
}

export default DnDView;
