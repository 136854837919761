import { produce } from 'immer';

// Handling comment like count updation
export const updateCommentLikeCount = (comments, commentId, status) => {
	let updatedComments = produce(comments, (draft) => {
		draft.data.map((comment) => {
			if (comment._id.toString() === commentId.toString()) {
				if (status) {
					comment.likesCount++;
					comment.isLiked = true;
				} else {
					comment.likesCount--;
					comment.isLiked = false;
				}

				if (comment.isDisliked) {
					comment.isDisliked = false;
					comment.dislikesCount--;
				}
			}
		});
	});

	return updatedComments;
};

// Handling comment dislike count updation
export const updateCommentDislikeCount = (comments, commentId, status) => {
	let updatedComments = produce(comments, (draft) => {
		draft.data.map((comment) => {
			if (comment._id.toString() === commentId.toString()) {
				if (status) {
					comment.dislikesCount++;
					comment.isDisliked = true;
				} else {
					comment.dislikesCount--;
					comment.isDisliked = false;
				}

				if (comment.isLiked) {
					comment.isLiked = false;
					comment.likesCount--;
				}
			}
		});
	});

	return updatedComments;
};

// Handling comment status change updation
export const updateCommentStatusChange = (comments, commentId, status) => {
	let updatedComments = produce(comments, (draft) => {
		draft.data.map((comment) => {
			if (comment._id.toString() === commentId.toString()) {
				comment.status = status;
			}
		});
	});

	return updatedComments;
};

// Handling reply like count updation
export const updateReplyLikeCount = (comments, commentId, replyId, status) => {
	let updatedComments = produce(comments, (draft) => {
		draft.data.map((comment) => {
			if (comment._id.toString() === commentId.toString()) {
				comment.replies.map((reply) => {
					if (reply._id.toString() === replyId.toString()) {
						if (status) {
							reply.likesCount++;
							reply.isLiked = true;
						} else {
							reply.likesCount--;
							reply.isLiked = false;
						}

						if (reply.isDisliked) {
							reply.isDisliked = false;
							reply.dislikesCount--;
						}
					}
				});
			}
		});
	});

	return updatedComments;
};

// Handling reply dislike count updation
export const updateReplyDislikeCount = (
	comments,
	commentId,
	replyId,
	status
) => {
	let updatedComments = produce(comments, (draft) => {
		draft.data.map((comment) => {
			if (comment._id.toString() === commentId.toString()) {
				comment.replies.map((reply) => {
					if (reply._id.toString() === replyId.toString()) {
						if (status) {
							reply.dislikesCount++;
							reply.isDisliked = true;
						} else {
							reply.dislikesCount--;
							reply.isDisliked = false;
						}

						if (reply.isLiked) {
							reply.isLiked = false;
							reply.likesCount--;
						}
					}
				});
			}
		});
	});

	return updatedComments;
};

// Handling reply status change updation
export const updateReplyStatusChange = (
	comments,
	commentId,
	replyId,
	status
) => {
	let updatedComments = produce(comments, (draft) => {
		draft.data.map((comment) => {
			if (comment._id.toString() === commentId.toString()) {
				comment.replies.map((reply) => {
					if (reply._id.toString() === replyId.toString()) {
						reply.status = status;
					}
				});
			}
		});
	});

	return updatedComments;
};

export const updateCommentReply = (comments, commentId, reply) => {
	const updatedComments = produce(comments, (draft) => {
		draft.data.map((comment) => {
			if (comment._id.toString() === commentId.toString()) {
				comment.replies = [...comment.replies, reply];
			}
		});
	});

	return updatedComments;
};
