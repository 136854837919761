import { useState, useEffect, useRef } from 'react';
import { useNavigate, defer, useLocation} from 'react-router-dom';
import DynamicArticlesView from './view';
import { navigationData } from './data';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {getEventElementPosition, toggleFullscreen} from '@helpers/common.js';

import { decryptText } from '@library/enc-dec';
import * as ArticleServices from '@services/Articles';
import * as ArticleActions from '@redux/actions/Articles';

import ClipboardJS from 'clipboard';

import { produce } from 'immer';
var listOfCheckedArticles=[]

function DynamicArticles(props) {
	const fullscreenElementRef = useRef(null);
	const columnsOptionPopUpRef = useRef(null);
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const limit = Number(queryParams.get('limit'));
	const page = Number(queryParams.get('page'));
	const [deferredData, setDeferredData] = useState({});
	const [isSorted, setIsSorted] = useState(true);
	const [sortBy, setSortBy] = useState('createdAt');

	const [isDateAssending, setIsDateAssending] = useState(false);
	const [selectedArticles, setSelectedArticles] = useState([]);
	const [isBulkSelected, setIsBulkSelected] = useState(false);
	
	const [alertVisible, setAlertVisible] = useState(false);
	const [allArticles, setAllArticles] = useState([]);
	const [searchText, setSearchText] = useState('');

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				columnsOptionPopUpRef.current &&
				!columnsOptionPopUpRef.current.contains(event.target)
			) {
				columnsOptionPopUpRef.current.style.display = 'none';
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if(limit>0)
		{
		getAllArticles();
	}
	}, [page, limit, isSorted, sortBy]);

	// Get All Courses to be shown in the drop-down
	const getAllArticles = async () => {
		try {
        	let authToken = decryptText(localStorage.getItem('aEmediat'));

			const payload ={
				pageNo: page, 
				limit: limit, 
				sortBy: sortBy, 
				isSorted: isSorted, 
				searchText: searchText}

			var deferredResponse = defer({res: ArticleServices.getAllArticle(payload, authToken)});

			setDeferredData(deferredResponse);

			deferredResponse.data.res.then(response => {
				setAllArticles(response);
			})
			.catch(error => {
				alert(error.message);
			});

		} catch (err) {
			console.log('Error coming while fetching all articles', err);
		}
	};

	const setDefaultNavigation = (defaultComponent) => {
		return navigationData.map((item) => {
			if (item.component === defaultComponent) {
				return { ...item, default: true };
			}
			return item;
		});
	};

	const handleLinksClick = (index, row) => {
		if(index==0)
		{
			handleRedirectToHome(row._id)
		}else if(index ==1)
		{
			window.open("https://emediskill.com/terms-and-conditions/"+row.pathUrl)
		}else if(index==2){
			handleCopyClick("https://emediskill.com/terms-and-conditions/"+row.pathUrl)
		}
		
	};
	const handleRedirectToHome = (docId) => {
		//Handle setting default page while navigating
		let finalNavigationData = [];
		finalNavigationData = navigationData;
		// if (defaultComponent !== 'none') {
		// 	finalNavigationData = setDefaultNavigation(defaultComponent);
		// } else {
		// 	// VAR x ONLY FOR TESTING
		// 	// let x = 'VideoComments';
		// 	// finalNavigationData = setDefaultNavigation(x);
		// 	finalNavigationData = setDefaultNavigation('DynamicArticle');
		// }

		if (docId === 'none') {
			navigate('/DynamicArticle', { state: { finalNavigationData } });
			props.resetArticle();
		} else {
			navigate('/DynamicArticle/' + docId, { state: { finalNavigationData } });
			props.resetArticle();
		}
	};

	const handleSort = (data) => {
		const mapping = {'Date created': 'createdAt'}
		var id = data[0]?.id ? mapping[data[0].id] : ''
		setSortBy(id)
		setIsSorted(data[0]?.desc ? data[0].desc : false)
	};

	//Fn to check and uncheck all checkboxes
	const handleCheckBoxes = (selectedRows) => {
		listOfCheckedArticles = selectedRows;
		setIsBulkSelected( selectedRows.length>0 ? false : true)
	};

	const deleteArticles = async () => {
		try {
			
  			let authToken = decryptText(localStorage.getItem('aEmediat'));
			let response = await ArticleServices.deleteArticles(
				authToken,
				listOfCheckedArticles.map(row=>row._id)
			);
			if (response.success) {
				alert('Article Deleted!');
				setAllArticles([]);
				getAllArticles();
			}
		} catch (err) {
			console.log('Error in deleting the articles.', err);
		}
	};

	const handleCopyClick = (textToCopy) => {
		const clipboard = new ClipboardJS('.clipboard', {
			text: () => textToCopy,
		});

		clipboard.on('success', (e) => {
			setAlertVisible(true);
			setTimeout(() => {
				setAlertVisible(false);
			}, 1000);
			e.clearSelection();
			clipboard.destroy();
			alert("copied"+textToCopy)
			
		});

		clipboard.on('error', (e) => {
			console.error('Copy failed:', e.action);
			clipboard.destroy();
		});
	};
    const handleColumnBtnClick = (event) => {
		// handlePopUpClose();
		columnsOptionPopUpRef.current.style.display = 'block';
		var position = getEventElementPosition(event);
		columnsOptionPopUpRef.current.style.top = position.top + 'px';
		columnsOptionPopUpRef.current.style.left = position.left - 160 + 'px';
		event.stopPropagation();
	};

	const handleSearch = (event) => {
		setSearchText(event.target.value);
		if (event.code === 'Enter' || event.key === 'Enter') {
			getAllArticles();
		}
	};
	const handleSearchInput = (event) => {
		setSearchText(event.target.value);
		if(event.target.value === ''){
			getAllArticles();
		}
	};

	return (
		<>
			<DynamicArticlesView
			    deferredData={deferredData}
				allArticles={allArticles}
				handleSort={handleSort}
				isDateAssending={isDateAssending}
				selectedArticles={selectedArticles}
				isBulkSelected={isBulkSelected}
				handleCheckBoxes={(id, type) => handleCheckBoxes(id, type)}
				handleDeleteBulk={() => deleteArticles()}
				handleRedirectToHome={handleRedirectToHome}
				handleCopyClick={(url) => handleCopyClick(url)}
				alertVisible={alertVisible}

				
				// Fullscreen
				fullscreenElementRef={fullscreenElementRef}
				toggleFullscreen={()=>toggleFullscreen(fullscreenElementRef)}
				//ColumnButton and popup
				columnsOptionPopUpRef={columnsOptionPopUpRef}
				handleColumnBtnClick={handleColumnBtnClick}
				handleLinksClick={handleLinksClick}

				handleSearch={handleSearch}
				handleSearchInput={handleSearchInput}
			/>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		allArticles: state.articles.allArticles,
		articlesSet: state.accounts.ordersSet,
		numOfArticles: state.accounts.numOfArticles,
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateAllArticles: ArticleActions.updateAllArticles,
			updateTotalNumOfArticles: ArticleActions.updateTotalNumOfArticles,
			updateArticlesSet: ArticleActions.updateArticlesSet,
			resetArticle: ArticleActions.resetArticle,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(DynamicArticles);
