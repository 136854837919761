import React from "react";

import "./style.css";
import DynamicTabs from "@components/DynamicTabs";

import AdminUsers from "@pages/SuperAdmin/AdminUsers/Main";
import Approvals from "@pages/SuperAdmin/Approvals/Main";
import Icons from "@pages/SuperAdmin/Icons";
import Bullets from "@pages/SuperAdmin/Bullets";
import Tags from "@pages/SuperAdmin/Tags";
import CClabels from "@pages/SuperAdmin/CCLabels";
import Institutes from "@pages/SuperAdmin/Institutes";
import BSSCourses from "@pages/SuperAdmin/BSSCourses";
import ExternalPayments from "@pages/SuperAdmin/ExternalPayments";
import Folders from "@pages/SuperAdmin/Folders";
import Interests from "@pages/SuperAdmin/Interests";
import UndergraduateDegree from "@pages/SuperAdmin/UndergraduateDegree";
import Qualifications from "@pages/SuperAdmin/Qualifications";

function SuperAdminView(props) {
  return (
    <div
      className="BSSPageMainContainer bg-white"
      ref={props.fullscreenElementRef}
    >
      <h4 className="mb-3">Superadmin</h4>
      <DynamicTabs
        allTabs={props.folders}
        activeTab={props.activeTab}
        onTabClick={props.handleTabClick}
      />
      {props.activeTab._id === "1" ? (
        <AdminUsers />
      ) : props.activeTab._id === "2" ? (
        <Approvals />
      ) : props.activeTab._id === "3" ? (
        <Icons />
      ) : props.activeTab._id === "4" ? (
        <Bullets />
      ) : props.activeTab._id === "5" ? (
        <Tags />
      ) : props.activeTab._id === "6" ? (
        <CClabels />
      ) : props.activeTab._id === "7" ? (
        <Institutes />
      ) : props.activeTab._id === "8" ? (
        <BSSCourses />
      ) : props.activeTab._id === "9" ? (
        <ExternalPayments />
      ) : props.activeTab._id === "10" ? (
        <Folders />
      ) : props.activeTab._id === "11" ? (
        <Interests />
      ) : props.activeTab._id === "12" ? (
        <UndergraduateDegree />
      ) : props.activeTab._id === "13" ? (
        <Qualifications />
      ) : (
        ""
      )}
    </div>
  );
}

export default SuperAdminView;
