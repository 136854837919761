import React, { forwardRef } from 'react';
import './style.css';

import folderImg from '@assets/common/folder.png'
import searchImg from '@assets/common/search.png'
import SaveBtn from "@components/SaveBtn";

const MoveToPopUpView = forwardRef((props, ref) =>{
	const{ disableCourseDurationSaveBtn, allFolders, searchFolder, isAllSelected} = props
	const {handleCancelBtn, handleSaveBtn, handleSearchInput,handleAllSelectCheckbox} = props

	return (
		<div className ="moveToDiv">
			<div className="searchDiv">
				{/* <input type="checkbox" checked={isAllSelected} onClick={()=>handleAllSelectCheckbox()}></input> */}
				<div className="searchInput">
				<input type="text" placeholder='Search for a folder' 
				         value={searchFolder} onChange={(event)=>handleSearchInput(event.target.value)}></input>
				<img src={searchImg} ></img>
				</div>
			</div>

			{props.courseDurationError && props.courseDurationError !== '' && (
				<>
					<div className='alert alert-danger'>{props.courseDurationError}</div>
				</>
			)}
			
				{allFolders && allFolders.map((folder, index)=>{

						if(folder._id != "All" &&(searchFolder=='' || folder.title.toLowerCase().includes(searchFolder.toLowerCase())) ){
							return(<div className='folderOption' key={index}>
										<input
											onChange={(event) => { 
														props.handleCheckBox(event, folder);
														}}
											checked={folder.checked}
											className='form-check-input'
											type='radio'
										/>
										<img src={folderImg}></img>
										<label className='form-check-label' htmlFor='lifetimeRadioBtn'>
										{folder.title}
										</label>
									</div>)
					}
				})}
				<div style={{ float: 'right', marginTop: '10px', marginLeft:'auto', paddingBottom:'10px', paddingRight:'10px' }}>
					<button
						onClick={(event) => {
							handleCancelBtn(event);
						}}
						className='cancleBtn'>
						Cancel
					</button>

					{/* <button
						disabled={disableCourseDurationSaveBtn}
						style={{
							cursor: props.disableCourseDurationSaveBtn
								? 'not-allowed'
								: 'pointer',
						}}
						id='courseDurationSaveBtn'
						onClick={handleSaveBtn}
						className='saveBtn'>
						Save
					</button> */}
					<SaveBtn
						onClick={handleSaveBtn}
						text={"Save"}
						altText={"Saving..."}
						disabled={disableCourseDurationSaveBtn || props.isFormSaving}
						isPopUpBtn={true}
					/>
				</div>	
			
	    </div>
	);
})

export default MoveToPopUpView;
