export const getUserDetails = async (authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getUserDetails`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((err) => err.message);
	} catch (err) {
		console.error('Coming from Comments: getUserDetails()', err.message);
		if (err.message === 'Network Error') {
			return err.message;
		}
	}
};

export const getAllComments = async (payload, authToken) => {
	const {page, limit, commentStatus} =payload
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/getAllComments/?pageNo=${page}&limit=${limit}&commentStatus=${commentStatus}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
		})
			.then((res) => res.json())
			.catch((err) => err.message);
	} catch (err) {
		console.error('Coming from Comments: getAllComments()', err.message);
		if (err.message === 'Network Error') {
			return err.message;
		}
	}
};

export const getAllVideoComments = async (videoDocId, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getAllVideoComments/${videoDocId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((err) => err.message);
	} catch (err) {
		console.error('Coming from Comments: getAllVideoComments()', err.message);
		if (err.message === 'Network Error') {
			return err.message;
		}
	}
};

export const likeComment = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/likeComment`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((err) => err.message);
	} catch (err) {
		console.error('Coming from Comments: likeComment()', err.message);
		if (err.message === 'Network Error') {
			return err.message;
		}
	}
};

export const dislikeComment = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/dislikeComment`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((err) => err.message);
	} catch (err) {
		console.error('Coming from Comments: dislikeComment()', err.message);
		if (err.message === 'Network Error') {
			return err.message;
		}
	}
};

export const updateCommentStatus = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateCommentStatus`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((err) => err.message);
	} catch (err) {
		console.error('Coming from Comments: updateCommentStatus()', err.message);
		if (err.message === 'Network Error') {
			return err.message;
		}
	}
};

export const likeReply = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/likeReply`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((err) => err.message);
	} catch (err) {
		console.error('Coming from Comments: likeReply()', err.message);
		if (err.message === 'Network Error') {
			return err.message;
		}
	}
};

export const dislikeReply = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/dislikeReply`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((err) => err.message);
	} catch (err) {
		console.error('Coming from Comments: dislikeReply()', err.message);
		if (err.message === 'Network Error') {
			return err.message;
		}
	}
};

export const updateReplyStatus = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/updateReplyStatus`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((err) => err.message);
	} catch (err) {
		console.error('Coming from Comments: updateReplyStatus()', err.message);
		if (err.message === 'Network Error') {
			return err.message;
		}
	}
};

export const addReply = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/addReply`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((err) => err.message);
	} catch (err) {
		console.error('Coming from Comments: addReply()', err.message);
		if (err.message === 'Network Error') {
			return err.message;
		}
	}
};

export const addReplyOfReply = async (payload, authToken) => {
	try {
		return await fetch(`${process.env.REACT_APP_BASE_URL}/addReplyOfReply`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: authToken,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => res.json())
			.catch((err) => err.message);
	} catch (err) {
		console.error('Coming from Comments: addReplyOfReply()', err.message);
		if (err.message === 'Network Error') {
			return err.message;
		}
	}
};

export const getUserAllComments = async (userId, authToken) => {
	try {
		return await fetch(
			`${process.env.REACT_APP_BASE_URL}/getUserAllComments/${userId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: authToken,
				},
			}
		)
			.then((res) => res.json())
			.catch((error) => error.message);
	} catch (error) {
		console.error('Coming from Query : getQueries()', error.message);
		if (error.message === 'Network Error') {
			return error.message;
		}
	}
};
