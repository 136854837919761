import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';
import ProfileIcon from '@assets/common/profile.png';

export const pages = [{"_id":1, "page" : 'home', "name": 'Home'},
                        // {"_id":2, "page" : 'superAdmin', "name": 'Super Admin'},
                        {"_id":3, "page" : 'analytics', "name": 'Analytics'},
                        {"_id":4, "page" : 'content', "name": 'Content'},
                        {"_id":5, "page" : 'users', "name": 'Users'},
                        {"_id":6, "page" : 'accounts', "name": 'Accounts'},
                        {"_id":7, "page" : 'offers', "name": 'Offers'},
                        {"_id":8, "page" : 'comments', "name": 'Comments'},
                        {"_id":9, "page" : 'notification', "name": 'Notifications'},
                        {"_id":10, "page" : 'certificate', "name": 'Certificates'},
                        {"_id":11, "page" : 'customerCare', "name": 'Customer Care'},
                        {"_id":12, "page" : 'dynamicPages', "name": 'Dynamic Pages'},
                        {"_id":13, "page" : 'leads', "name": 'Leads'},
                        {"_id":14, "page" : 'verification', "name": 'Verification'},
                        {"_id":15, "page" : 'bss', "name": 'BSS'}]
//Object to handle naviagtion
export const navigationData = [
    {
        "title" : "Back to Super Admin",
        "route" : "/superAdmin",
        "default" : false,        
        "icon" : GoBackArrow
    },
    {
        "title" : "Profile",
        "component" : "AdminUserProfile",
        "default" : false,        
        "icon" : ProfileIcon
    },
]
