import React from "react";
import VideoPlayHolder from "@assets/common/videoPlayHolder.png";
import "./style.css";

import BasicTableGrid from "@components/BasicTableGrid";

import EditPencilIcon from "@assets/common/editPencilIcon.png";
import ShareIcon from "@assets/common/shareIcon.png";
import CopyIcon from "@assets/common/copyIcon.png";
import AnalyticsIcon from "@assets/common/analyticsIcon.png";
import CommentsIcon from "@assets/common/commentsIcon.png";

import DropDownArrowIcon from "@assets/common/dropDownArrowIcon.png";

function UserSeriesView(props) {
  return (
    <>
      <BasicTableGrid
        gridId="userSeries" // this must be unique for each grid
        data={props.deferredData} // Data to be displayed
        fixedCols={3} // How many columns are fixed and not scrolling
        // handleSelect = {(selectedRows)=>{props.handleCheckBoxes(selectedRows)}}
        initialSize={[35, 782, 497]}
        length={20}
        handleSelect={(data) => props.handleSelectedSeries(data)}
        pagination={props.pagination}
        setPagination={props.setPagination}
        onSortChange={(data) => console.log(data)}
        // Columns to be extracted from data
        columns={[
          {
            header: "Title",
            cell: (data) => {
              const seriesData = data.row.original;
              return (
                <div className="d-flex gap-2">
                  <img
                    style={{ width: "140px" }}
                    src={seriesData?.content?.thumbnailUrl}
                    alt="VideoThumbnail"
                  />
                  <div className="d-flex flex-column justify-content-center">
                    <span>{seriesData?.content?.title} </span>
                    <span>{seriesData?.content?.course} </span>
                    <div className="hidden">
                      <div className="hiddenDiv">
                        <div className="d-flex gap-2">
                          <img
                            onClick={(event) => {
                              props.handleRedirectToHome(
                                event,
                                seriesData?.content?._id,
                                "VideoDetails"
                              );
                            }}
                            src={EditPencilIcon}
                            alt="EditPencilIcon"
                          />

                          <img
                            onClick={(event) => {
                              props.handleRedirectToHome(
                                event,
                                seriesData._id,
                                "VideoComments"
                              );
                            }}
                            src={CommentsIcon}
                            alt="CommentsIcon"
                          />

                          <img
                            onClick={(event) => {
                              props.handleRedirectToHome(
                                event,
                                seriesData._id,
                                "VideoAnalytics"
                              );
                            }}
                            src={AnalyticsIcon}
                            alt="AnalyticsIcon"
                          />

                          <a
                            href={
                              `${process.env.REACT_APP_E_MEDISKILL_WEB_APP}` +
                              "/video/v/" +
                              seriesData._id
                            }
                            target="_blank"
                          >
                            <img src={ShareIcon} alt="ShareIcon" />
                          </a>

                          <img
                            src={CopyIcon}
                            alt="CopyIcon"
                            className="clipboard"
                            onClick={() => {
                              props.handleCopyClick(
                                `${process.env.REACT_APP_E_MEDISKILL_WEB_APP}` +
                                  "/video/v/" +
                                  seriesData._id
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            header: "Status",
            cell: (data) => {
              const seriesData = data.row.original;
              return (
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={(event) => {
                    props.handlePopupOpen(event, "seriesStatus", seriesData);
                  }}
                >
                  {seriesData?.content?.seriesStatus}{" "}

                  <img src={DropDownArrowIcon} alt="DropDownArrowIcon" style={{height:'auto', width:'12px'}}/>
                </span>
              );
            },
          },
        ]}
      />
    </>
  );
}

export default UserSeriesView;
